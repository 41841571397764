import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Card, Alert, Container } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Auth from "../../ServerCalls/Auth";
import Swal from "sweetalert2";
import { campaignNameData, add_Votingdata, getReportSummary } from "../Admin/ProductServiceAPI";
import ProductURL from "../../ServerCalls/ProductServer";
import { FcInfo } from "react-icons/fc";
import { FaStreetView, FaVoteYea } from "react-icons/fa";
import { MdHowToVote } from "react-icons/md";
import Loader from "../../common/Loader";
import NotFound from "../../common/NotFound";
import { Col, Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Chart from 'react-apexcharts';
import { Rating } from 'react-simple-star-rating';
import axios from "../../ServerCalls/AxiosInstance";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import getYouTubeID from 'get-youtube-id';
import { makeStyles } from "@material-ui/core/styles";
import './voting.css';





const PhotoPath = ProductURL.photoURL + "ProductImages/";

export default function Voting() {
  const location = useLocation();
  console.log(location);
  if(location.state){
    var { campaignId , pollingCampaignEndDate, pollingCampaignShortName, pollingquestion,categoryid
      , } = location.state.data;
  }


  const auth = Auth.getUserId();
  const navigate = useNavigate();


  const [listByNames, setlistByNames] = useState([]);
  const [alreadyvote, setAlreadyVote] = useState([]);
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [ProductName, setProductName] = useState([]);
  const [TotalVotes, setTotalVotes] = useState([]);
  const [PiechartProducts, setPieChartProducts] = useState([]);
  const [PiechartCounts, setPieChartCounts] = useState([]);
  const [TopProducts, setTopProducts] = useState([]);
  const [view, setView] = useState(false);
  const [Add, setAdd] = useState([]);
  const [Length, setLength] = useState([]);


  const advertisePhoto = "https://media.topbrandsranking.com/CampaignAdvertiseImages/"



  const useStyles = makeStyles({

    table: {
      "& .MuiTableCell-root": {
        border: "1px solid rgba(0, 0, 0, 0.3)"
      }
    }
  });


  const classes = useStyles();

  const openAdvertiseModal = () => {
    setView(true)
  }

  const handleClose = () => {
    setShowReport(false)
  };

  const handleClose2 = () => {
    setView(false);
  };

  useEffect(() => {
    // Campaigngetbyid(id)
    axios.get(`https://api.topbrandsranking.com/ProductManagement/PollingCampaignGetById/${campaignId}`)
      .then((response) => {
        const data = response.data._response.data.getcampaign
        setAdd(data)

      })

  }, [])


  useEffect(() => {
    getReport()
  }, [])


  const getReport = ()=> {

    getReportSummary(campaignId, categoryid)
    .then((response) => {


      const products = response.data._response.data.campaignproductcount
      const TotalCount = response.data._response.data.summarytotalcount
      // const PiechartProduct = response.data._response.data.campaignproductcount

      let namedata = [];
      let votingdata = [];
      products.map((a) => {
        namedata.push(a.productname);
        votingdata.push(a.count)
      });


      setPieChartProducts(namedata)
      setPieChartCounts(votingdata)
      setProductName(products)
      setTotalVotes(TotalCount)

    })

  }


  useEffect(() => {
    ParticipatedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ParticipatedProducts = () => {
    setLoading(true);

    let campaignid = campaignId;
    let citizenid = Number(auth);

    campaignNameData(citizenid, campaignid)
      .then((res) => {
        if (res.data._response.status === 1) {
          let data = res.data._response.data.productparticipationgetcampaignid;
          setlistByNames(data);
          setLoading(false);


          let alreadyvotes = [];
          data &&
            data.map((f) => {
              if (f.isVoted === 0) {
                alreadyvotes.push(f);
              }
            });
          setAlreadyVote(alreadyvotes);
        }
      })
      .catch((err) => {
        console.log("err in get", err);
        setLoading(false);
      });
  };

  const handleVote = (votedata) => {
    Swal.fire({
      title:
        "You Are Voting to <br><h4 style='color:#3085d6'>" +
        votedata.productName +
        "</h4>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Confirm it!",
    })
      .then((result) => {
        if (result.isConfirmed === true) {
          const data = {
            campaignId: votedata.campaignid,
            votingDate: new Date(),
            memberid: Number(auth),
            citizenVotingId: votedata.citizenvotingid,
            productId: votedata.productid,
          };
          add_Votingdata(data)
            .then((res) => {
              if (res.data._response.status === 1) {
                Swal.fire({
                  icon: "success",
                  title:
                    "Voted <h4 style='color:#5364da'>" +
                    votedata.productName +
                    "</h4> <h5 style='color:#94726f'>" +
                    votedata.productName +
                    "</h5",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "ok",
                });
                ParticipatedProducts();
                openAdvertiseModal();
                getReport();
              } else if (res.data._response.status === 2) {
                Swal.fire({
                  icon: "warning",
                  title: res.data._response.message,
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "ok",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in product voting ");
      });
  };


  const OpenReport = () => {

    setShowReport(true)

  }

  useEffect(() => {
    getTopproductReport()
  }, [])

  const getTopproductReport = () => {
    const campaignid = campaignId
    axios.get(`https://api.topbrandsranking.com/ProductManagement/TopProductGetByCampaignId?campaignid=${campaignid}`)
      .then((res) => {
        const data = res.data._response.data.topproductgetbyproductid2
        const prolength = res.data._response.data.topproductgetbyproductid2.length
        setLength(prolength)
        setTopProducts(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <br />
            <br />
            <div className="row mb-2">
              <div className="col-sm-12">
                <h1 className="m-0 text-center mb-3">{pollingCampaignShortName}</h1>
              </div>
              <br />
              <br />
              <Row>
                <Col sm={12}>
                  <div className="d-flex justify-content-center mt-3">
                    <Button variant="contained" color="success" onClick={OpenReport} title="Click to show Report">Report</Button>
                  </div>

                </Col>
              </Row>
            </div>
          </div>
        </div>


        <section className="section-2">
          <div className="container">
            <div className="row">
              {new Date(pollingCampaignEndDate) >= new Date() ? null : (
                <Alert
                  variant="warning"
                  show={show}
                  onClose={() => setShow(false)}
                  dismissible
                >
                  <Alert.Heading>Campaign Closed!!!</Alert.Heading>
                  <strong>You cannot vote for this campaign.</strong>
                </Alert>
              )}
              {alreadyvote.length > 0 ? (
                <Alert
                  variant="warning"
                  show={show}
                  onClose={() => setShow(false)}
                  dismissible
                >
                  <Alert.Heading>
                    You have already voted for this campaign!!!
                  </Alert.Heading>
                  <strong>You cannot vote this campaign again.</strong>
                </Alert>
              ) : null}

              <Alert variant="primary">
                {/* <Alert.Heading>QUESTION: </Alert.Heading> */}
                <h4
                  style={{
                    fontWeight: "600",
                    fontSize: "1.8rem",
                    textTransform: "uppercase",
                  }}
                >
                  {pollingquestion}?
                </h4>
              </Alert>
              <card-group className="d-flex" style={{ marginTop: "36px" }}>
                <Col md={12} className="mb-2">
                  <Row >
                    {loading ? (
                      <Loader />
                    ) : listByNames.length !== 0 ? (
                      listByNames.map((list, id) => {
                        return (
                          <Col md={3}>
                            <Card
                              className="gallerycard"
                              style={{
                                height: "350px",

                                marginTop: "15px",
                              }}
                            >

                              <Card.Img
                                className="zoom"
                                width="100%"
                                height="200px"
                                variant="top"
                                src={PhotoPath + list.productimage}

                              />
                              <Card.Body>
                                <Card.Title className='voting-title' title={list.brandname} >{list.brandname}</Card.Title>

                                <Card.Text title={list.productName} className="votingsub-title">
                                  {list.productName}
                                </Card.Text>
                                {list.isVoted === 0 ? (
                                  <button
                                    className={
                                      list.isVoted === 0
                                        ? " cursor-not-allowed"
                                        : "myclass"
                                    }
                                    title="Already Voted"
                                    style={{
                                      border: "none",
                                      marginRight: "20px",
                                    }}
                                    disabled="true"
                                    onClick={() => handleVote(list)}
                                    type="submit"
                                  >
                                    <FaVoteYea fontSize="35px" color="green" />
                                  </button>
                                ) : alreadyvote.length === 0 ? (
                                  <button
                                    className={
                                      new Date(pollingCampaignEndDate) <= new Date()
                                        ? " cursor-not-allowed"
                                        : "myclass"
                                    }
                                    disabled={new Date(pollingCampaignEndDate) <= new Date()}
                                    title="vote now"
                                    style={{
                                      border: "none",
                                      marginTop: "10px",
                                      marginRight: "20px",
                                    }}
                                    onClick={() => handleVote(list)}
                                    type="submit"
                                  >
                                    <MdHowToVote
                                      fontSize="35px"
                                      color="orangered"
                                    />
                                  </button>
                                ) : null}

                                <Link
                                  to={`/product-details/${list.productid}`}
                                  title="more info"
                                >
                                  <FcInfo fontSize="35px" />
                                </Link>
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      })
                    ) : (
                      <NotFound />
                    )}
                  </Row>
                </Col>
              </card-group>
            </div>
          </div>
        </section>
        <div className="button_style m-0 text-center pt-4">
          <Button

            type="button"
            variant="contained"
            color="error"
            onClick={() => navigate("/campaignList")}
          >
            Back
          </Button>
        </div>
        <br />
        <br />


        <Container>
          <Row>
            <Col>
              <Modal show={showReport} className="mt-3 product-modal" onHide={handleClose} size="lg" backdrop="static" centered style={{ padding: '30px' }}>
                <Modal.Header className="headerBlue" closeButton >
                  <Modal.Title className=''>
                    <p className="text-center mt-2 pl-2 mx-auto"> Report Summary </p>
                  </Modal.Title>


                </Modal.Header>
                <h4 className="mx-auto">{pollingCampaignShortName}</h4>
                <Modal.Body>
                  <div className="row">

                    <Container>
                      <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report">
                        <TableContainer >
                          <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                            <TableHead >

                              <TableRow className="backgroundrow">
                                <TableCell width="10%" className="head1">Product Name</TableCell>

                                <TableCell align="center" width="10%" rowSpan={2} className="head1">Total Vote</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {ProductName.map((list, id) => (
                                <TableRow>

                                  <TableCell className="headerbgcolor" >{list.productname}</TableCell>


                                  <TableCell className="headerbgcolor" align="center">{list.count}</TableCell>

                                </TableRow>
                              ))}

                            </TableBody>
                          </Table>

                          <Row>
                            <Col>


                              <Chart

                                type="pie"
                                width={850}
                                height={250}
                                series={PiechartCounts}

                                options={{
                                  title: { text: "Voting Report" },
                                  noData: { text: "Empty Data" },
                                  labels: PiechartProducts,
                                  responsive: [{
                                    breakpoint: 1024,
                                    options: {
                                      chart: {
                                        width: 700
                                      },
                                      legend: {
                                        position: 'right'
                                      }

                                    },
                                    breakpoint: 480,
                                    options: {
                                      chart: {
                                        width: 250
                                      },
                                      legend: {
                                        position: 'bottom'
                                      },

                                    },
                                    breakpoint: 768,
                                    options: {
                                      chart: {
                                        width: 400
                                      },
                                      legend: {
                                        position: 'bottom'
                                      },

                                    }

                                  }]

                                }}



                              />
                            </Col>
                          </Row>


                        </TableContainer>

                      </Paper>
                    </Container>
                  </div>



                </Modal.Body>
              </Modal>



              <Modal
                show={view}
                onHide={handleClose2}
                size="md"
                backdrop="static"
                centered
              >
                <Modal.Header className="headerBlue" closeButton>
                  <Modal.Title className="">
                    <h5 className="text-center">Sponsored</h5>
                  </Modal.Title>
                </Modal.Header>

                {Add.map((list) => (
                  <Modal.Body>
                    <div className="container newscard">
                      <article className="card shadow">
                        {
                          list.advertisetypelabel == "Upload_Link" ? (

                            <iframe className='video'
                              title='Youtube player'
                              allowfullscreen
                              width="640" height="360"
                              sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                              src={`https://youtube.com/embed/${getYouTubeID(list.advertisesource)}?autoplay=0`}>
                            </iframe>

                          ) : list.advertisetypelabel == "Upload_Video" ? (
                            <div className="row">
                              <video width="100%" controls>
                                <source src={advertisePhoto + list.advertisesource} type="video/mp4" />
                              </video>
                              <strong>
                                <p className="text-center mt-2 mx-auto">
                                  {/* {list.newsheader} */}
                                </p>
                              </strong>
                            </div>

                          ) :

                            <div className="row">
                              <img
                                className="img-fluid"
                                src={advertisePhoto + list.advertisesource}
                                alt="news"
                              />
                              <strong>
                                <p className="text-center mt-2 mx-auto">
                                  {/* {list.newsheader} */}
                                </p>
                              </strong>
                            </div>
                        }
                        <div>
                          {/* <p className="ml-5 mt-2">{list.newsdescription}</p> */}
                        </div>
                      </article>
                    </div>
                  </Modal.Body>
                ))}
              </Modal>





            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
