import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MaterialTable from "material-table";

import { viewPollingdetails } from '../ProductServiceAPI';


export default function ViewPollingDetails() {

    const location = useLocation();
    const candidateid = location.state;
    const navigate = useNavigate();
    const [data, setdata] = useState([]);

    const columns = [

        { title: "Citizen Name", field: "fullName", align: "center" },

    ];

    useEffect(() => {

        viewdetails();

    }, [])


    const viewdetails = () => {

        viewPollingdetails(candidateid.productid).then((res) => {

            if(res.data._response.status === 1){
                
                setdata(res.data._response.data.citizenpollinggetbycitizenid)

            }

        })
    }

    const backtoform = () => {
        navigate("/pollingReport");
    }

    return (


        <div>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <h1 className="m-0 text-center">Polling Details</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content  consti-section">
                        <div className="container-fluid">
                            <div className="row">
                                <MaterialTable
                                    title="Voting Details"
                                    data={data}
                                    columns={columns}
                                    options={{
                                        search: false,
                                        pageSize: 5,
                                        showFirstLastPageButtons: false,
                                        actionsColumnIndex: -1,
                                        addRowPosition: "first",
                                    }}
                                />
                            </div>
                            <button
                                type="button"
                                className="btn btn-pos2 m-4"
                                onClick={backtoform}
                            >
                                <i className="fa-solid fa-circle-left"></i>&nbsp;Back
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
