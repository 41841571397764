import React, { useState, useEffect, useRef } from 'react'
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import DatePicker from 'react-datepicker'
import DefaultImg from '../../../assets/img/default.jpg'
import { Allbrands, addRegistration, BrandPhotos, updateBrandDetails, deleteData } from '../Admin/ProductServiceAPI'
import ProductURL from '../../ServerCalls/ProductServer';
import axios from "axios";
import Auth from '../../ServerCalls/Auth';
import logo1 from "../../../assets/img/logo1.png";
import Button from '@mui/material/Button';
import Header from '../../common/header/Header';
import "cleave.js/dist/addons/cleave-phone.ke";
import { useNavigate, Link } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import FacebookLogin from 'react-facebook-login';
const PhotoPath = ProductURL.photoURL + "BrandImages/";


const BrandRegistration = () => {

    const navigate = useNavigate();

    const [brand, setBrand] = useState('');
    const [brandData, setbrandData] = useState([])
    const [personName, setpersonName] = useState('');
    const [contact, setcontact] = useState("");
    const [email, setemail] = useState('');
    const [userName, setuserName] = useState('');
    const [password, setpassword] = useState('')
    const [brandID, setbrandID] = useState('')
    const [update, setupdate] = useState(false);
    const [establishDate, setestablishDate] = useState(new Date())
    const [image11, setimage11] = useState('');
    const [image12, setimage12] = useState('');
    const [image11path, setImage11path] = useState('')
    const [image12path, setImage12path] = useState('')
    const [brandDetails, setbrandDetails] = useState('');
    const [cpassword, setcpassword] = useState('')
    const [Showlist, setShowlist] = useState(false)
    const token = Auth.getToken();
    const [googleLogin, setGoogleLogin] = useState('');
    const [loading, setLoading] = useState(false);

    const [formError, setFormError] = useState([])


    const handleimage12 = (e) => {
        setimage12(e.target.files[0])
        setImage12path(URL.createObjectURL(e.target.files[0]))
    }

    const handleimage11 = (e) => {
        setimage11(e.target.files[0])
        setImage11path(URL.createObjectURL(e.target.files[0]))
    }


    const inputRefrence1 = useRef(null);
    const inputRefrence2 = useRef(null);

    const handleInput = (e) => {
        setBrand(e.target.value)

        setFormError({ ...formError, BrandName: "" })

    }

    const handleConcern = (e) => {

        setpersonName(e.target.value);
        setFormError({ ...formError, personName: "" })
    }

    const handleEmail = (e) => {

        setemail(e.target.value)
        setFormError({ ...formError, email: "" })
    }

    const handleusername = (e) => {

        setuserName(e.target.value)
        setFormError({ ...formError, userName: "" })
    }

    const handleContact = (e) => {

        setcontact(e.target.value)
        setFormError({ ...formError, contact: "" })
    }

    const handlePassword = (e) => {

        setpassword(e.target.value)
        setFormError({ ...formError, password: "" })
    }

    const handlecPassword = (e) => {

        setcpassword(e.target.value)
        setFormError({ ...formError, cpassword: "" })
    }

    const handleBrandDetails = (e) => {

        setbrandDetails(e.target.value)
        setFormError({ ...formError, brandDetails: "" })
    }

    // useEffect(() => {
    //     getBrandList();
    // }, []);

    // const getBrandList = () => {
    //     Allbrands()
    //         .then((res) => {
    //             const data = res.data._response.data.getbrand
    //             setbrandData(data)
    //         }).catch((err) => {
    //             console.log(err, "err in get  brand");
    //         })

    // }

    const validate = () => {

        let regex = /^[A-Za-z0-9 ]+$/;
        let emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let passregex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
        let contactregex = /^[6-9]\d{9}$/gi;


        let formError = {}
        let isValid = true;
        if (brand === '') {
            isValid = false;
            formError["BrandName"] = "please Enter Brand Name"
        }
        else if (regex.test(brand) === false) {
            formError = "Please enter alphabetic characters and no spaces";
        }

        if (personName === '') {
            isValid = false;
            formError["personName"] = "please Enter Your Name"

        } else if (personName.length > 20) {
            formError = "Name cannot exceed 20 characters";
        }
        if (contact === "") {
            isValid = false;
            formError["contact"] = "please Enter Contact No."

        }
        else if (contact.length > 20) {
            isValid = false;
            formError["contact"] = 'Please enter valid phone number';
        }

        if (email === "") {
            isValid = false;
            formError["email"] = "please Enter Email"

        } else if (!emailregex.test(email)) {
            isValid = false;
            formError["email"] = "Please Enter a Valid Email";

        }

        if (userName === "") {
            isValid = false;
            formError["userName"] = "please Enter Username"

        }

        if (password === "") {
            isValid = false;
            formError["password"] = "please Enter Password"

        } else if (!passregex.test(password)) {
            isValid = false;
            formError["password"] = "Password must combination of Number,Capital letter, special character and min length is 8 !"
        }

        if (cpassword === "" && update === false) {
            isValid = false;
            formError["cpassword"] = "please Enter Password"

        } else if (cpassword !== password && update === false) {
            isValid = false;
            formError["cpassword"] = "Passwords do not match";
        }

        if (brandDetails === '') {
            isValid = false;
            formError["brandDetails"] = "please Enter Brand Details"
        }

        setFormError(formError);

        return isValid;

    }

    const saveData = async (e) => {

        const isValid = validate();
        if (isValid) {
            const data = {
                brandDetails: brandDetails,
                brandName: brand,
                contactEmail: email,
                contactPersonContactNumber: contact,
                contactPersonName: personName,
                // image1: image1.length === 0 ? 'images.jpeg' : image1,
                // image2: image2,
                image1: image11.name,
                image2: image12.name,
                password: password,
                userName: userName,
                yearOfEstablishment: new Date(establishDate),
                Role: 3
            }

            let formdata = new FormData();

            formdata.append("file", image11);
            formdata.append("file", image12);
            formdata.getAll("file")

            await image_upload(formdata);

            axios.post("https://api.topbrandsranking.com/Authorization/AddUserData", data)
                //   axios.post("https://localhost:5001/api/Authorization/AddUserData", data)
                .then((res) => {
                    if (res.data._response.status === 1) {
                        Swal.fire({
                            icon: "success",
                            title: "Brand Registration is Successfully Done.",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        cancel();
                        navigate('/login')
                    }
                    else if (res.data._response.status === 2) {
                        Swal.fire({
                            icon: "warning",
                            title: res.data._response.message,
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err, "err in add brand data");
                });


        }

    }

    const image_upload = async (data) => {

        await BrandPhotos(data)
            .then((res) => {
                console.log("photo res", res)
            }).catch((err) => {
                console.log(err, "err in photo submit")
            })
    }

    const cancel = () => {
        setBrand('')
        setuserName('')
        setpassword('')
        setbrandDetails('')
        setpersonName('')
        setcontact("")
        setcontact("")
        setemail('')
        setestablishDate(new Date())
        setupdate(false)
        inputRefrence1.current.value = null;
        inputRefrence2.current.value = null;
        setImage11path('');
        setImage12path('');
        setFormError("")
        setcpassword('');

    }

    const googleLoginData = (data) => {
        var decoded = jwt_decode(data.credential);
        let emailData = decoded.email
        let username = decoded.name
        // let ProfileImg = decoded.picture

        let body = {
            email: emailData,
            RegistrationMethod:1
        };
        let NonUserbody = {
            ContactEmail: emailData,
            RegistrationMethod: 1,
            Role: 3,
            UserName: username,
            // PhotoFileName:ProfileImg,
        };

        let headers = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        setLoading(true);
        axios.post(`https://api.topbrandsranking.com/Authorization/AddUserData`, NonUserbody, headers)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        setLoading(true);
                        axios.post(`https://api.topbrandsranking.com/Authorization/GoogleFbauthenticate`, body, headers)
                            .then((res) => {

                                if (res.status === 200) {
                                    if (res.data._response.status === 1) {
                                        setLoading(false);
                                        let newUserLoginDetails = res.data._response
                                        let Newdetails = {
                                            email: newUserLoginDetails.email,
                                            token: newUserLoginDetails.token,
                                            id: newUserLoginDetails.id,
                                            firstName: newUserLoginDetails.firstName,
                                            memberIdBrandId: newUserLoginDetails.memberIdBrandId,
                                            images: newUserLoginDetails.images,
                                            lastName: newUserLoginDetails.lastName,
                                            role: newUserLoginDetails.role,
                                        };
                                        const loginInfo = JSON.stringify(Newdetails);
                                        localStorage.setItem("UserDetails", loginInfo);
                                        if (Newdetails.role === 3) {
                                            window.location.href = "/BrandDetails";
                                        } else if (Newdetails.role === 2) {
                                            // if (key === null) {
                                            window.location.href = "/UserDetails";
                                            // }
                                            // else {
                                            //   if (key.page === "Dashboard") {
                                            //     window.location.href = "/UserDetails";
                                            //   } else if (key.page === "Voting") {
                                            //     window.location.href = `/productVoting/${key.from}/${key.date}/${key.name}/${key.question}/${key.categoryid}`
                                            //   }
                                            //   else if (key.page === "CelebrityVoting") {
                                            //     window.location.href = `/CelebrityVoting/${key.from}/${key.date}/${key.name}/${key.question}/${key.category}`
                                            //   } else {
                                            //     window.location.href = "/UserDetails";
                                            //   }
                                            // }
                                        } else if (Newdetails.role === 1) {
                                            window.location.href = "/admindashboard";
                                        }

                                    } else {
                                        setLoading(false);
                                        Swal.fire({
                                            icon: "warning",
                                            title: res.data._response.message,
                                            showConfirmButton: true,
                                            confirmButtonColor: "#3085d6",
                                            // cancelButtonColor: '#d33',
                                            confirmButtonText: "ok",
                                        });
                                    }
                                } else {
                                    setLoading(false);
                                    Swal.fire({
                                        icon: "warning",
                                        title: res.data._response.message,
                                        showConfirmButton: true,
                                        confirmButtonColor: "#3085d6",
                                        // cancelButtonColor: '#d33',
                                        confirmButtonText: "ok",
                                    });

                                }

                            }).catch((err) => {
                                setLoading(false);
                                Swal.fire({
                                    icon: "error",
                                    title: "Something went wrong",
                                    showConfirmButton: true,
                                    confirmButtonColor: "#3085d6",
                                    // cancelButtonColor: '#d33',
                                    confirmButtonText: "ok",
                                });
                            })
                    } else {
                        setLoading(false);
                        Swal.fire({
                            icon: "warning",
                            title: res.data._response.message,
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                    }
                }
            }).catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Something went wrong",
                    showConfirmButton: true,
                    confirmButtonColor: "#3085d6",
                    // cancelButtonColor: '#d33',
                    confirmButtonText: "ok",
                });
            })
    }


    const responseFacebook = (res) => {

        let body = {
            email: res.email,
            RegistrationMethod:2
        };

        let facebookBody = {
            ContactEmail: res.email,
            RegistrationMethod: 2,
            Role: 3,
            UserName: res.name,
            // PhotoFileName:ProfileImg,
        };

        let headers = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        setLoading(true);
        axios.post(`https://api.topbrandsranking.com/Authorization/AddUserData`, facebookBody, headers)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data._response.status === 1) {
                        setLoading(true)
                        axios.post(`https://api.topbrandsranking.com/Authorization/GoogleFbauthenticate`, body, headers)
                            .then((res) => {


                                if (res.status === 200) {

                                    if (res.data._response.status === 1) {

                                        setLoading(false);
                                        let newUserLoginDetails = res.data._response
                                        let Newdetails = {
                                            email: newUserLoginDetails.email,
                                            token: newUserLoginDetails.token,
                                            id: newUserLoginDetails.id,
                                            firstName: newUserLoginDetails.firstName,
                                            memberIdBrandId: newUserLoginDetails.memberIdBrandId,
                                            images: newUserLoginDetails.images,
                                            lastName: newUserLoginDetails.lastName,
                                            // signInTypeId: logindata.signInTypeId,
                                            role: newUserLoginDetails.role,
                                        };
                                        const loginInfo = JSON.stringify(Newdetails);
                                        localStorage.setItem("UserDetails", loginInfo);
                                        if (Newdetails.role === 3) {
                                            window.location.href = "/BrandDetails";
                                        } else if (Newdetails.role === 2) {
                                            // if (key === null) {
                                            window.location.href = "/UserDetails";
                                            // }
                                            // else {
                                            //   if (key.page === "Dashboard") {
                                            //     window.location.href = "/UserDetails";
                                            //   } else if (key.page === "Voting") {
                                            //     window.location.href = `/productVoting/${key.from}/${key.date}/${key.name}/${key.question}/${key.categoryid}`
                                            //   }
                                            //   else if (key.page === "CelebrityVoting") {
                                            //     window.location.href = `/CelebrityVoting/${key.from}/${key.date}/${key.name}/${key.question}/${key.category}`
                                            //   } else {
                                            //     window.location.href = "/UserDetails";
                                            //   }
                                            // }
                                        } else if (Newdetails.role === 1) {
                                            window.location.href = "/admindashboard";
                                        }

                                    } else {
                                        setLoading(false);
                                        Swal.fire({
                                            icon: "warning",
                                            title: res.data._response.message,
                                            showConfirmButton: true,
                                            confirmButtonColor: "#3085d6",
                                            // cancelButtonColor: '#d33',
                                            confirmButtonText: "ok",
                                        });

                                    }
                                }

                            }).catch((err) => {
                                setLoading(false);
                                Swal.fire({
                                    icon: "error",
                                    title: "Something went wrong",
                                    showConfirmButton: true,
                                    confirmButtonColor: "#3085d6",
                                    // cancelButtonColor: '#d33',
                                    confirmButtonText: "ok",
                                });

                            })
                    } else {
                        setLoading(false);
                        Swal.fire({
                            icon: "warning",
                            title: "Invalid Login Details",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });

                    }
                }
            }).catch((err) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Something went wrong",
                    showConfirmButton: true,
                    confirmButtonColor: "#3085d6",
                    // cancelButtonColor: '#d33',
                    confirmButtonText: "ok",
                });

            })
    }

    const componentClicked = (res) => {
        console.log(res);
    }
    return (
        <>
            {
                !token ?
                    <Header />
                    :
                    null

            }
            <div className="regiform">
                <div>
                    {/* <div className="content-wrapper"> */}
                    {/* <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2 justify-content-center">
                                    <div className="col-sm-12"></div>
                                </div>
                            </div>
                        </div> */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row ">
                                <Col md={12} className="">
                                    <Card className="register-card">
                                        <div className=" m-0 text-center">
                                            <img
                                                className="img-fluid "
                                                src={logo1}
                                                alt="logo-topbrands"
                                                height="80px"
                                                width="120px"
                                            />
                                            <h1 className="m-0 text-center register-heading">
                                                Brand Registration!
                                            </h1>
                                        </div>
                                        <div>
                                            <Form.Group
                                                as={Row}
                                                className="mb-3 mt-4 justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >
                                                <Col sm={12}>
                                                    <Form.Label className="w-100">
                                                        Brand Name
                                                    </Form.Label>

                                                    <Form.Control
                                                        className="w-100"
                                                        type="text"
                                                        name="BrandName"
                                                        value={brand}
                                                        onChange={handleInput}
                                                    />
                                                    {
                                                        formError ?
                                                            <div style={{ color: "red" }}>{formError.BrandName}</div>
                                                            : null

                                                    }
                                                </Col>
                                            </Form.Group>
                                            <Form.Group
                                                as={Row}
                                                className="mb-3 mt-4 justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >

                                                <Col sm={12}>
                                                    <Form.Label className="w-100">Concern Person Name:</Form.Label>

                                                    <Form.Control
                                                        className="w-100"
                                                        type="text"
                                                        name="personName"
                                                        value={personName}
                                                        onChange={handleConcern}
                                                    />
                                                    {
                                                        formError ?
                                                            <div style={{ color: "red" }}>{formError.personName}</div>
                                                            : null

                                                    }

                                                </Col>

                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3 justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >

                                                <Col sm={6}>
                                                    <Form.Label className="w-100">
                                                        Year of Establishment
                                                    </Form.Label>

                                                    <DatePicker
                                                        className="w-100 form-control"
                                                        selected={establishDate}
                                                        onChange={(date) => { setestablishDate(date) }}
                                                        // dateFormat="dd/MM/yyyy"
                                                        showYearPicker
                                                        dateFormat="yyyy"
                                                        maxDate={new Date()}
                                                    />

                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Label className="w-100">Contact:</Form.Label>


                                                    <input className="w-100 form-control" type="number" placeholder='Contact' value={contact} name='contact' onChange={handleContact} />
                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 14, color: "red" }}>{formError.contact}</div>
                                                            : null

                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3 justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >

                                                <Col sm={6}>
                                                    <Form.Label className="w-100">Username:</Form.Label>

                                                    <Form.Control
                                                        className="w-100"
                                                        type="text"
                                                        name="userName"
                                                        value={userName}
                                                        onChange={handleusername}
                                                    />
                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 14, color: "red" }}>{formError.userName}</div>
                                                            : null

                                                    }
                                                </Col>
                                                <Col sm={6}>
                                                    <Form.Label className="w-100">Email:</Form.Label>

                                                    <Form.Control
                                                        className="w-100"
                                                        type="text"
                                                        value={email}
                                                        name="email"
                                                        onChange={handleEmail}
                                                    />
                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 14, color: "red" }}>{formError.email}</div>
                                                            : null

                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3 justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >

                                                <Col sm={6}>
                                                    <Form.Label className="w-100">Password</Form.Label>

                                                    <Form.Control
                                                        className="w-100"
                                                        type="password"
                                                        name="password"
                                                        value={password}
                                                        onChange={handlePassword}
                                                    />
                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 14, color: "red" }}>{formError.password}</div>
                                                            : null

                                                    }
                                                </Col>
                                                <Col sm={6}>
                                                    <Form.Label className="w-100">
                                                        Confirm Password:
                                                    </Form.Label>

                                                    <Form.Control
                                                        className="w-100"
                                                        type="password"
                                                        name="password"
                                                        value={cpassword}
                                                        onChange={handlecPassword}
                                                    />
                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 14, color: "red" }}>{formError.cpassword}</div>
                                                            : null

                                                    }
                                                </Col>
                                            </Form.Group>
                                            <Form.Group
                                                as={Row}
                                                className="mb-3 justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >
                                                <Col sm={12}>
                                                    <Form.Label className="w-100">
                                                        Brand Details
                                                    </Form.Label>

                                                    <Form.Control
                                                        className="w-100"
                                                        as="textarea"
                                                        rows="3"
                                                        name="brandDetails"
                                                        value={brandDetails}
                                                        onChange={handleBrandDetails}
                                                    />
                                                    <div style={{ color: "red" }}>{formError.brandDetails}</div>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group
                                                as={Row}
                                                className="mb-3 justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >

                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100"> Image 1</Form.Label>
                                                    <FormControl className="m-2" type="file" name='file' controlId={image11} ref={inputRefrence1} onChange={handleimage11} />
                                                    <div style={{ color: "red" }}>{formError.productImage1}</div>
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100"> Image 2</Form.Label>
                                                    <FormControl className="m-2" type="file" name='file' controlId={image12} ref={inputRefrence2} onChange={handleimage12} />
                                                    <div style={{ color: "red" }}>{formError.productImage1}</div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="imagepreview">
                                                        <img alt=" " width="80" height="60" src={update === false && image11.name === undefined  ? DefaultImg : update ? PhotoPath + image11 : image11path} />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="imagepreview">
                                                        <img alt=" " width="80" height="60"src={update === false && image12.name === undefined  ? DefaultImg : update ? PhotoPath + image12 : image12path}/>
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                            <div className="button_style m-0 text-center">
                                                <Button
                                                    type="submit"
                                                    variant="contained"

                                                    onClick={() => saveData()}
                                                >
                                                    Submit
                                                </Button>&nbsp;
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => cancel()}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <br />
                                            <div className="regi-a row justify-content-center mb-4">
                                                <Col sm={6}>
                                                    <p>
                                                        Already have an Account?{" "}
                                                        <Link to='/login'> Login Here !</Link>
                                                    </p>
                                                </Col>
                                            </div>
                                            <div className="row text-center">
                                                {
                                                    loading === true ?
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>


                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className='regi-a row'>
                                                <Col sm={6}>

                                                    <GoogleLogin
                                                        text='signup_with'
                                                        onSuccess={credentialResponse => {
                                                            googleLoginData(credentialResponse)
                                                            setGoogleLogin(credentialResponse);
                                                        }}
                                                        onError={() => {
                                                            console.log('Login Failed');
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm={6}>
                                                    <FacebookLogin
                                                        appId="1131064557801831"
                                                        fields="name,email,picture"
                                                        onClick={componentClicked}
                                                        callback={responseFacebook}
                                                        size='small'
                                                        cssClass="btnFacebook"
                                                        icon={<i className="fa fa-facebook" style={{ marginLeft: '5px' }}></i>}
                                                        textButton='&nbsp;&nbsp;sign up with Facebook'
                                                    />
                                                </Col>
                                            </div>

                                        </div>
                                    </Card>
                                </Col>
                            </div>
                        </div>
                    </section>
                    {/* </div> */}
                </div>
                {/* <Footer /> */}
            </div>


        </>
    )
}

export default BrandRegistration;