import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from "material-table";
import Select from "react-select";
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import Button from '@mui/material/Button';
import DatePicker from "react-datepicker";
import { addMagzineData, pdfData,trashMagzine,editMagzine } from './ProductServiceAPI';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import ProductURL from '../../ServerCalls/ProductServer';

const PhotoPath = ProductURL.photoURL + "MagazinePdf/";



export default function AddMagzines() {


    const [formError, setFormError] = useState([]);
    const [Showlist, setShowlist] = useState(false);
    const [update, setUpdate] = useState(false);
    const [magzineDate, setMagzineDate] = useState(new Date());
    const [magzineType, setMagzineType] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [magzinelist, setMagzinelist] = useState([]);
    const [pdf, setPdf] = useState('');
    const [pdfPath, setPdfPath] = useState('');
    const [magzineID, setMagzineID] = useState(0);
    const jumptoBack = useLocation();

    const columns = [
        {
            title: "Magzine Type",
            field: "magazinetypename",
            align: "center",
        },
        {
            title: "PDF",
            field: "pdffile",
            align: "center",
        },
        {
            title: "Publish Year",
            field: "publishyear",
            align: "center",
            type: "date"
        },

    ];

    const inputRefrence = useRef(null);


    const handleMagzine = (selected) => {         

        setMagzineType(selected);
        setFormError({ ...formError, magzineType: "" });

    }

    const handlefile = (e) => {

        let selectedfile = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(selectedfile);

        // reader.onloadend = (e)=>{
        //     setPdf(e.target.result);
        //     console.log(e.target.result);
        // }

        setPdf(selectedfile);
        setPdfPath(URL.createObjectURL(e.target.files[0]))
        setFormError({ ...formError, pdf: "" });

    }

    useEffect(() => {
        setShowlist(false);
        getlist();
        gettypes();

    }, [jumptoBack.key])


    const getlist = () => {

        axios.get(`https://api.topbrandsranking.com/ProductManagement/GetMagazine`)
        // axios.get(`https://localhost:5002/api/ProductManagement/GetMagazine`)
            .then((res) => {
             
                if (res.data._response.status === 1) {
                    setMagzinelist(res.data._response.data.getmagazine)

                }

            })
            .catch((err)=>{
                console.log(err,'err in get magzinelist');
            })

    }

    const gettypes = () => {
        axios.get(`https://api.topbrandsranking.com/ProductManagement/GetMagazineType`)
    
            .then((res) => {
                if (res.data._response.status === 1) {

                    let data = res.data._response.data.getmagazinetype;
                    let options = data.map((a) => ({
                        value: a.magazineTypeId,
                        label: a.magazineTypeName,

                    }))
                    setTypeList(options)
                }

            })
            .catch((err)=>{
                console.log(err,"err in get types");
            })

    }

    const validate = () => {


        let formError = {}
        let isValid = true;

        if (magzineType.length < 1) {
            isValid = false;
            formError["magzineType"] = "please Select Magzine type "
        }

        if (pdf.length < 1) {
            isValid = false;
            formError["pdf"] = "please select pdf"

        }

        setFormError(formError);
        return isValid;

    }

    const savedata = async () => {

        const isValid = validate();

        if (isValid) {

            const data = {
                magazineType: magzineType.value,
                publishYear: magzineDate,
                pdfFile: pdf.name
            }

            let formdata = new FormData();

            formdata.append("file", pdf);

            await image_upload(formdata);
            addMagzineData(data).then((res) => {

                if (res.data._response.status === 1) {

                    Swal.fire({
                        icon: "success",
                        title: res.data._response.message,
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });
                    getlist();
                    cancel()
                }
            })
                .catch((err) => {
                    console.log(err, "err in post magzine");

                })
        }
    }

    const image_upload = async (data) => {

        await pdfData(data)
            .then((res) => {
                console.log("photo res", res)
            }).catch((err) => {
                console.log(err, "err in photo submit")
            })
    }

    const getdata = (id) => {
        setShowlist(false);
        setUpdate(true);
        setMagzineID(id.magazineid)
        setMagzineType({ value: id.magazinetype, label: id.magazinetypename })
        setPdf(id.pdffile)
        setMagzineDate(new Date(id.publishyear))
    }

    const updateData = async() => {
        setShowlist(false);
        const isValid = validate();

        if (isValid) {

            const data = {
                magazineType: magzineType.value,
                publishYear: magzineDate,
                pdfFile: pdf.name
            }

            let formdata = new FormData();

            formdata.append("file", pdf);

            await image_upload(formdata);

            editMagzine(data).then((res)=>{

                if(res.data._response.status === 1){
                    Swal.fire({
                        icon: "success",
                        title: "Magzine Updated Sucessfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });
                    setShowlist(false);
                    cancel();
                    getlist();
                }
            })
        }
    }
 
    const trashData = (id)=>{

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((res) => {

            if (res.isConfirmed) {
                trashMagzine(id).then((res) => {
                    if (res.data._response.status === 1) {

                        Swal.fire({
                            icon: "success",
                            title: res.data._response.message,
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        getlist();
                    }
                    else if (res.data._response.status === 2) {

                        Swal.fire({
                            icon: "warning",
                            title: res.data._response.message,
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                    }
                })
            }
        })
        .catch((err) => {
                console.log(err, "err in trash brand");
        })
    }


    const cancel = () => {

        setMagzineDate(new Date());
        setMagzineType([])
        setFormError([])
        setPdfPath('');
        setUpdate(false);
        inputRefrence.current.value = null;
    }



    return (



        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <br /><br />
                        <div className="row">

                            <div className="col-sm-12">
                                {
                                    !update ? (

                                        <h1 className="m-0 text-center"> ADD MAGAZINE </h1>
                                    ) :
                                        <h1 className="m-0 text-center"> EDIT MAGAZINE </h1>

                                }
                            </div>
                        </div>

                        <Col md={12} >
                            <div className='d-flex justify-content-end'>
                                {!Showlist ?
                                    <button
                                        type="button"
                                        className="btn btn-pos3 "
                                        onClick={() => {
                                            setShowlist(true);
                                            cancel();
                                        }
                                        }
                                    >
                                        <i className="fa-solid fa-list"></i> List
                                    </button>
                                    :
                                    <button
                                        type="button"
                                        className="btn btn-pos4 "
                                        onClick={() => setShowlist(false)}
                                    >
                                        <i className="fa fa-arrow-circle-left"></i> back
                                    </button>
                                }
                            </div>
                        </Col>
                    </div>
                </div>
                <section className="content  consti-section">
                    <div className="container">
                        <div className="row">
                            <Col
                                md={12}
                            >
                                <Card
                                    style={{
                                        padding: "20px"
                                    }}
                                >
                                    {
                                        !Showlist ? (
                                            <div>
                                        <Form.Group
                                            as={Row}
                                            className="mb-3 justify-content-center"
                                            controlId="formHorizontalEmail"
                                        >
                                            <Col sm={12}>
                                                <Form.Label className="w-100"> Magazine Type </Form.Label>
                                                <Select
                                                    className="w-100"
                                                    options={typeList}
                                                    onChange={handleMagzine}
                                                    value={magzineType}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                />
                                                <br/>
                                                <div style={{ color: "red" }}>
                                                    {formError.magzineType}
                                                </div>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group
                                            as={Row}
                                            className="mb-3 justify-content-center"
                                            controlId="formHorizontalEmail"
                                        >
                                            <Col
                                                sm={6}
                                                style={{
                                                    position: "relative",
                                                    zIndex: "999",
                                                }}
                                            >
                                                <Form.Label className="w-100"> Publish Date </Form.Label>
                                                <DatePicker
                                                    className="w-100 form-control"
                                                    selected={magzineDate}
                                                    name="magzineDate"
                                                    value={magzineDate}
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={(date) => {
                                                        setMagzineDate(date);
                                                    }}
                                                    popperProps={{ strategy: 'fixed' }}
                                                />
                                                <div style={{ color: "red" }}>
                                                    {formError.magzineDate}
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Label className="w-100">
                                                    Magazine PDF
                                                </Form.Label>
                                                <FormControl
                                                    className="w-100 form-control"
                                                    type="file"
                                                    name="pdf"
                                                    onChange={handlefile}
                                                    controlId={pdf}
                                                    ref={inputRefrence}
                                                    accept={update === false ? pdfPath : pdf.name === undefined ? PhotoPath + pdf : pdfPath}
                                                />
                                                <div style={{ color: "red" }}>
                                                    {formError.pdf}
                                                </div>
                                            </Col>
                                            {/* <Col md={6}>
                                                        <div className="imagepreview">
                                                            <img alt=" " width="80" height="60" src={update === false ? pdfPath : pdf.name === undefined ? PhotoPath + pdf : pdfPath} />
                                                        </div>
                                                    </Col> */}
                                        </Form.Group>
                                        <div className="button_style m-0 text-center">
                                            {update === false ? 
                                                <Button
                                                    type="submit"
                                                    variant='contained'
                                                    onClick={()=>savedata()}
                                                >
                                                    Submit
                                                </Button>

                                             : 
                                                <Button
                                                    type="button"
                                                    variant='contained'
                                                    color='success'
                                                   onClick={() => updateData()}
                                                >
                                                    Update
                                                </Button>
                                                    }{" "}
                                            <Button
                                                type="button"
                                                variant='contained'
                                                color='error'
                                                onClick={() => cancel()}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                            </div>
                                      ) : (
                                            <div>
                                    <MaterialTable
                                        title={false}
                                        data={magzinelist}
                                        columns={columns}
                                        actions={[
                                            // {
                                            //     icon: "edit",
                                            //     tooltip: "Edit",
                                            //     onClick: (event, rowData) => {
                                            //         getdata(rowData);
                                            //     },
                                            // },

                                            {
                                                icon: "delete",
                                                tooltip: "Delete",
                                                onClick: (event, rowData) => {
                                                  trashData(rowData.magazineid);
                                                },
                                            },
                                        ]}
                                        options={{
                                            search: true,
                                            pageSize: 10,
                                            showFirstLastPageButtons: false,
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                            pageSizeOptions: [10, 15, 20, 50],
                                        }}
                                    />
                                            </div>
                                        )
                                    }
                                </Card>
                            </Col>
                        </div>
                    </div>
                </section>
            </div>

        </div>

    )
}
