import React, { useState, useEffect } from 'react'
import { getCountyData, addCounty, editCounty, trashCounty,NocountyList } from '../Admin/ProductServiceAPI';
import MaterialTable from "material-table";
import Button from '@mui/material/Button';

import { Form, Row, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";

export default function County() {

    const [county, setcounty] = useState('');
    const [data, setdata] = useState([]);
    const [countyID, setcountyID] = useState('');
    const [update, setupdate] = useState(false)

    const [CountyError, setCountyError] = useState('');

    const columns = [{ title: "County", field: "countyName", align: "center" }];



    const handleCounty = (e) => {
        setcounty(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setCountyError('')
    }

    useEffect(() => {

        getlist();
    }, []);


    const getlist = () => {

        NocountyList()
            .then((res) => {
                if (res.data._response.status === 1) {
                    let countydata = res.data._response.data.getcountywithnocounty
                    setdata(countydata)
                }
            })
            .catch((err) => {
                console.log(err, "myerr");
            })
    }


    const FormValidation = () => {

        let regex = /^[A-Za-z0-9 ]+$/;
        let CountyError = '';

        if (county === '') {
            CountyError = "Please fill the Details";
        } else if (regex.test(county) === false) {
            CountyError = 'Please do not use special Character'

        }else if(county.length > 50){
            CountyError = "Maximum 50 characters are allowed";
        }


        if (CountyError) {

            setCountyError(CountyError);

            return false;

        }

        return true;

    }

    const saveData = () => {
        let isvalid = FormValidation();

        if (isvalid) {

            const alldata = {
                countyName: county
            }
            addCounty(alldata).then((res) => {

                if (res.data._response.status === 1) {
                    Swal.fire({
                        icon: "success",
                        title: "County Added Sucessfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "ok",
                    })
                getlist()
                clearData()
                    .catch(()=>{
                        Swal.fire({
                            icon: "error",
                            title: "County Not Added",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "ok",
                          });
                    })
                }

            })
                .catch((err) => {
                    console.log(err, "err in add county ");
                })

        }
    }


    const getdata = (id) => {

        setcountyID(id.countyId);
        setcounty(id.countyName);
        setupdate(true);

    }

    const updateData = () => {
        const isValid = FormValidation();

        if (isValid) {
            const alldata = {
                countyName: county,
                countyId: countyID
            }

            editCounty(alldata).then((res) => {

                if (res.data._response.status === 1) {
                    Swal.fire({
                        icon: "success",
                        title: "County Updated Sucessfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "ok",
                    });
                    getlist();
                    clearData();
                }
            })
                .catch((err) => {
                    console.log(err, "err in edit county");
                })

        }
    }

    const trashData = (id) => {
       
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
       

            if (result.isConfirmed) {
                trashCounty(id.countyId).then((res) => {

                    if (res.data._response.status === 1) {
                        Swal.fire({
                            icon: "success",
                            title: "County Deleted Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "ok",
                        });
                        getlist();
                    }else if(res.data._response.status === 2){
                        Swal.fire({
                            icon: "warning",
                            title: res.data._response.message,
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "ok",
                        });
            
            
                    }

                })
            }
        })

    }


    const clearData = () => {

        setupdate(false);
        setcounty('');
        setCountyError('');
    }

    return (

        <div>

            <div>
                <div className="content-wrapper consti-section">
                    <div className="content-header">
                        <div className="container-fluid">
                        <br/><br/>
                            <div className="row">
                                <div className="col-sm-12">
                                    {!update?(
                                    <h1 className="m-0 text-center">COUNTY INFORMATION</h1>
                                    ):
                                    <h1 className="m-0 text-center">EDIT COUNTY INFORMATION</h1>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container">
                            <div className="row">
                                <Col md={12}>
                                    <Card style={{ padding: "20px"}}>
                                        <Form.Group
                                            as={Row}
                                            className="mb-3 justify-content-center"
                                            controlId="formHorizontalEmail"
                                        >
                                            <Col sm={12}>
                                                <Form.Label className="w-100">County Name</Form.Label>
                                                <input
                                                    type="text"
                                                    value={county}
                                                    onChange={handleCounty}
                                                    className="w-100 form-control"
                                                // style={{"text-transform": "capitalize"}}
                                                />
                                                <div style={{ color: "red",fontSize: 13 }}>{CountyError}</div>
                                            </Col>
                                        </Form.Group>
                                        <div className="button_style m-0 text-center">
                                            {
                                                update === false ?
                                                    <Button
                                                        type="submit"
                                                        variant='contained'
                                                        onClick={() => saveData()}
                                                    >
                                                        Submit
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="button"
                                                        variant='contained'
                                                        color='success'
                                                        onClick={() => updateData()}
                                                    >
                                                        Update
                                                    </Button>

                                            }


                                         {" "}{" "}

                                            <Button
                                                type="button"
                                                variant='contained'
                                                color='error'
                                                onClick={() => clearData()}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Card>
                                </Col>
                                <Col className='mt-4' md={12} >
                                 
                                    <MaterialTable
                                        title=''
                                        data={data}
                                        columns={columns}
                                        actions={[
                                            {
                                                icon: "edit",
                                                tooltip: "Edit",
                                                onClick: (event, rowData) => {
                                                    getdata(rowData);
                                                },
                                            },
                                            {
                                                icon: "delete",
                                                tooltip: "Delete",
                                                onClick: (event, rowData) => {

                                                    trashData(rowData);
                                                },
                                            },
                                        ]}
                                        options={{
                                            search: true,
                                            pageSize: 10,
                                            showFirstLastPageButtons: false,
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                            pageSizeOptions: [10, 15, 20, 50],
                                        }}
                                       
                                    />
                                </Col>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>
    )
}
