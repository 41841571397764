import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import MaterialTable, { MTableEditField } from "material-table";
import {
  Addproduct,
  productList,
  editProduct,
  deleteProduct,
  Allbrands,
  Categorywithnoparent,
  ProductPhotos,
  Allparameters,
  trashProductparameters
} from "./ProductServiceAPI";
import ProductURL from "../../ServerCalls/ProductServer";
import axios from "../../ServerCalls/AxiosInstance";
import Button from '@mui/material/Button';


const Product = () => {
  const [productname, setProductName] = useState("");
  const [brandselected, setbrandselected] = useState([]);
  const [productid, setproductid] = useState(0);
  const [categoryselected, setcategoryselected] = useState("");
  const [productImage1, setProductImage1] = useState("");
  const [productImage2, setProductImage2] = useState("");
  const [productImage3, setProductImage3] = useState("");
  const [productImage4, setProductImage4] = useState("");
  const [productImage5, setProductImage5] = useState("");
  const [selectOption, setSelectOption] = useState([]);
  const [categoryselectoption, setcategoryselectoption] = useState([]);
  const [formError, setformError] = useState([]);
  const [update, setupdate] = useState(false);
  const [productlist, setProductlist] = useState([]);
  const [image1Path, setimage1Path] = useState([]);
  const [image2Path, setimage2Path] = useState([]);
  const [image3Path, setimage3Path] = useState([]);
  const [image4Path, setimage4Path] = useState([]);
  const [image5Path, setimage5Path] = useState([]);
  const [Showlist, setShowlist] = useState(false)
  const [dataList, setdataList] = useState([]);
  const [parameters, setParameters] = useState([])
  const PhotoPath = ProductURL.photoURL + "ProductImages/";
  const jumptoBack = useLocation();

  const columns = [
    { title: "Product Name", field: "productname", align: "center" },
    { title: "Product Image", field: "productimage1", align: "center" },
    { title: "Brand Name", field: "brandname", align: "center" },
    { title: "Category", field: "categoryname", align: "center" },
  ];


  const columns1 = [

    { title: "Parameter Name", field: "parametername", align: "center", editable: "never" },
    {
 title: "Parameter Value", field: "parametervalue", align: "center", editComponent: ({ ...props }) => {

        return <MTableEditField {...props} />;
      },
    }

];
  const inputRefrence1 = useRef(null);
  const inputRefrence2 = useRef(null);
  const inputRefrence3 = useRef(null);
  const inputRefrence4 = useRef(null);
  const inputRefrence5 = useRef(null);


  useEffect(() => {
    setShowlist(false)
    getProductList();
    getBrand();
    getCategory();
  }, [jumptoBack.key]);

  useEffect(() => {
    GetParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryselected, productid])

  const getProductList = (a, b) => {

    productList()
      .then((response) => {
        if (response.data._response.status === 1) {

          setProductlist(response.data._response.data.getproduct);

        }
      })
      .catch((err) => {
        console.log(err, "err in get product list");
      });
  };

  const GetParameters = () => {
    Allparameters(productid, categoryselected.value).then((res) => {

      let parametersData = res.data._response.data.productparametersgetid
      let data = [];
      parametersData && parametersData.map((x) => {

        if (x.categoryid === categoryselected.value) {
          data.push(parametersData);
        }

      })
      setdataList(parametersData);

    }).catch((err) => {
      console.log(err, "err in get")
    })
}



const handleRowupdate = (newData, resolve, reject) => {
  if (newData) {
      let mapdata = [];
      let array = [...dataList]
      newData && Object.entries(newData).map(([k, v]) => {

        mapdata.push(v.newData);
        array[v.newData.tableData.id].parametervalue = v.newData.parametervalue
      })
      setParameters(mapdata)
      setdataList(array)
      resolve();
  } else {
      Swal.fire({
        icon: "warning",
        title: "Enter valid Parameter Value",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        // cancelButtonColor: '#d33',
        confirmButtonText: "ok",
      });
      reject();
    }

  }


  const handleDelete = (oldData, resolve, reject) => {
    if (oldData.productparameterid != undefined && oldData.productparameterid != 0 ) {
    trashProductparameters(oldData.productparameterid)
        .then((res) => {
          GetParameters();
          resolve();       
        })
        .catch((err) => {
            console.log(err);
            reject();
        })
      }else {
        const dataDelete = [...parameters];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setParameters([...dataDelete]);
        const dataRemove = [...dataList];
        dataRemove[index].parametervalue = "";
        setdataList(dataRemove)
        resolve();     
      }
}

  const validates = () => {
    let formError = {};
    let isValid = true;
    let regex = /^[A-Za-z0-9 ]+$/;

    if (productname === "") {
      isValid = false;
      formError["productName"] = "please Enter product Name";
    } else if (productname.length < 3 ) {
      
      isValid = false;
      formError["productName"] = "Please Enter Minimun 3 Characters.";

    }else if(productname.length > 250 ){
      isValid = false;
      formError["productName"] =  "Maximum 250 characters are allowed";
    }

    if (brandselected.length < 1) {
      isValid = false;
      formError["brandselected"] = "Please select Brand ";
    }
    if (categoryselected === "") {
      isValid = false;
      formError["categoryselected"] = "Please select category ";
    }

    if (productImage1.length < 1) {
      isValid = false;
      formError["productImage1"] = "Please select Image"

    }
    if (productImage2.length < 1) {
      isValid = false;
      formError["productImage2"] = "Please select Image"

    }
    if (productImage3.length < 1) {
      isValid = false;
      formError["productImage3"] = "Please select Image"

    }
    if (productImage4.length < 1) {
      isValid = false;
      formError["productImage4"] = "Please select Image"

    }
    if (productImage5.length < 1) {
      isValid = false;
      formError["productImage5"] = "Please select Image"

    }


    setformError(formError);
    return isValid;
  };


  const handleBrand = (selected) => {
    setbrandselected(selected);
    setformError({ ...formError, brandselected: "" });
  };


  const handleCategory = (selected) => {
    setcategoryselected(selected);
    setformError({ ...formError, categoryselected: "" });
  };


  const handleInput = (e) => {
    setProductName(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''));
    setformError({ ...formError, productName: "" });
  };


  const getBrand = () => {
    Allbrands()
      .then((res) => {
        if (res.data._response.status === 1) {
          let data1 = res.data._response.data.getbrand;
          let options = data1.map((d) => ({
            value: d.brandId,
            label: d.brandName,
          }));

          setSelectOption(options);
        }
      })
      .catch((err) => {
        console.log(err, "err in brand get");
      });
  };


  const getCategory = () => {
    Categorywithnoparent()
      .then((res) => {
        if (res.data._response.status === 1) {
          let data = res.data._response.data.getprocategory;
          let options = data.map((a) => ({
            value: a.categoryid,
            label: a.categoryName,
          }));
          setcategoryselectoption(options);
        }
      })
      .catch((err) => {
        console.log(err, "err in category get");
      });
  };

  const handleimage1 = (e) => {
    setProductImage1(e.target.files[0]);

    setimage1Path(URL.createObjectURL(e.target.files[0]));
    setformError({ ...formError, productImage1: "" });
  };

  const handleimage2 = (e) => {
    setProductImage2(e.target.files[0]);
    setimage2Path(URL.createObjectURL(e.target.files[0]));
    setformError({ ...formError, productImage2: "" });
  };

  const handleimage3 = (e) => {
    setProductImage3(e.target.files[0]);
    setimage3Path(URL.createObjectURL(e.target.files[0]));
    setformError({ ...formError, productImage3: "" });
  };

  const handleimage4 = (e) => {
    setProductImage4(e.target.files[0]);
    setimage4Path(URL.createObjectURL(e.target.files[0]));
    setformError({ ...formError, productImage4: "" });
  };

  const handleimage5 = (e) => {
    setProductImage5(e.target.files[0]);
    setimage5Path(URL.createObjectURL(e.target.files[0]));
    setformError({ ...formError, productImage5: "" });
  };

  const handleSubmit = async () => {
    const isvalid = validates();
    if (isvalid) {
      const data = {
        productName: productname,
        ProductImage1: productImage1.name,
        productImage2: productImage2.name,
        productImage3: productImage3.name,
        productImage4: productImage4.name,
        productImage5: productImage5.name,
        brandId: brandselected.value,
        categoryId: categoryselected.value,
        productparameters:parameters

      };

      let formdata = new FormData();
      formdata.append("file", productImage1);
      formdata.append("file", productImage2);
      formdata.append("file", productImage3);
      formdata.append("file", productImage4);
      formdata.append("file", productImage5);
      formdata.getAll("file")
      await image_upload(formdata);

      Addproduct(data)
        .then((response) => {
          if (response.data._response.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Product Added Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            getProductList();
            handleclear();
          } else {
            Swal.fire({
              icon: "Product Not Added",
              title: response.data._response.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in submit");
        });
    }
  };

  const image_upload = async (data) => {

    await ProductPhotos(data)
      .then((res) => {
        console.log("photo res", res);
      })
      .catch((err) => {
        console.log(err, "err in photo submit");
      });
  };



  const EditProduct = (a) => {
    setShowlist(false);
    setupdate(true);
    setproductid(a.productid);
    setProductName(a.productname);
    setProductImage1(a.productimage1);
    setProductImage2(a.productimage2);
    setProductImage3(a.productimage3);
    setProductImage4(a.productimage4);
    setProductImage5(a.productimage5);
    setbrandselected({ value: a.brandid, label: a.brandname });
    setcategoryselected({ value: a.categoryid, label: a.categoryname });

  };

  const handleUpdate = async () => {
    const data = {
      productId: productid,
      productName: productname,
      productImage1: productImage1.name === undefined ? productImage1 : productImage1.name,
      productImage2: productImage2.name === undefined ? productImage2 : productImage2.name,
      productImage3: productImage3.name === undefined ? productImage3 : productImage3.name,
      productImage4: productImage4.name === undefined ? productImage4 : productImage4.name,
      productImage5: productImage5.name === undefined ? productImage5 : productImage5.name,
      brandId: brandselected.value,
      categoryId: categoryselected.value,
      productparameters: parameters
    };
    let formdata = new FormData();
    formdata.append("file", productImage1);
    formdata.append("file", productImage2);
    formdata.append("file", productImage3);
    formdata.append("file", productImage4);
    formdata.append("file", productImage5);

    if (productImage1.name !== undefined) 
    if (productImage2.name !== undefined) 
    if (productImage3.name !== undefined) 
    if (productImage4.name !== undefined) 
    if (productImage5.name !== undefined) 

    formdata.getAll("file")
            await image_upload(formdata);



    editProduct(data)
      .then((response) => {
        if (response.data._response.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Product Updated Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
          handleclear();
          getProductList();
        } else {
          Swal.fire({
            icon: "error",
            title: "Product Not Updated",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        console.log(`err in update:${err}`);
      });
  };

  const DeleteProduct = (productId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
           deleteProduct(productId)
            .then((res) => {
              if (res.data._response.status === 1) {
              
              Swal.fire({
                icon: "success",
                title: "Product Deleted Successfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              getProductList();
            }else if(res.data._response.status === 2){
          
              Swal.fire({
                icon: "warning",
                title: res.data._response.message,
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
            });


        }

    })
    .catch((err)=>{
      console.log(err,'err in deleteproduct');
    })
   
}
})
      .catch((err) => {
        console.log(err, "err in trash product");
      });
  };

  const handleclear = () => {
    setProductName("");
    setbrandselected("");
    setcategoryselected("");
    setupdate(false);
    inputRefrence1.current.value = null;
    inputRefrence2.current.value = null;
    inputRefrence3.current.value = null;
    inputRefrence4.current.value = null;
    inputRefrence5.current.value = null;
    setformError({
      productName: "",
      brandId: "",
      categoryId: "",
    });
    setimage1Path("");
    setimage2Path("");
    setimage3Path("");
    setimage4Path("");
    setimage5Path("");
    setParameters([]);
    setdataList([]);
    setproductid(0)
    setProductImage1("");
    setProductImage2("");
    setProductImage3("");
    setProductImage4("");
    setProductImage5("");
  };
  return (
    <>
      <div>
        <form>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container">
                <br /><br />
                <div className="row mb-2">
                  {!Showlist ? (
                    <div className="col-sm-12">
                       {!update ? (
                      <h1 className="m-0 text-center"> ADD PRODUCT </h1>
                       ):
                      <h1 className="m-0 text-center"> EDIT PRODUCT </h1>
                       }
                    </div>
                  ) :
                    <div className="col-sm-12">
                      <h1 className="m-0 text-center"> PRODUCT LIST </h1>
                    </div>
                  }
                </div>

                <Col md={12} >
                  <div className='d-flex justify-content-end'>
                    {!Showlist ?
                      <button
                        type="button"
                        className="btn btn-pos3 "
                        onClick={() => 
                                {
                            setShowlist(true);
                            handleclear();
                          }
                        }
                      >
                        <i className="fa-solid fa-list"></i> List
                      </button>
                      :
                      <button
                        type="button"
                        className="btn btn-pos4 "
                        onClick={() => setShowlist(false)}
                      >
                        <i className="fa fa-arrow-circle-left"></i> Back
                      </button>
                    }
                  </div>
                </Col>
              </div>
            </div>

            <section className="content consti-section">
              <div className="container">
                {!Showlist ? (
                  <div className="row">
                   

                      <>
                        <Card style={{ padding: "20px" }}>

                          {/* {!isEditable ? ( */}
                     <Row>
                          <Form.Group
                            className="mb-3 col-sm-6"
                            controlId="formHorizontalEmail"
                          >
                           
                              <Form.Label className="w-100">
                                Product Name
                              </Form.Label>

                              <input
                                className="w-100 form-control"
                                type="text"
                                name="productname"
                                value={productname}
                                onChange={handleInput}
                              />

                              <div style={{ color: "red",fontSize: 13}}>
                                {formError.productName}
                              </div>
                            
                          </Form.Group>
                          <Form.Group
                            
                            className="mb-3 col-sm-6"
                            controlId="formHorizontalEmail"
                          >
                            
                              <Form.Label className="w-100">Brand</Form.Label>

                              <Select
                                className="w-100"
                                options={selectOption}
                                onChange={handleBrand}
                                value={brandselected}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                              <br/>
                              <div style={{ color: "red",fontSize: 13 }}>
                                {formError.brandselected}
                              </div>
                            
                          </Form.Group>
                          <Form.Group
                           
                            className="mb-3 col-sm-6"
                            controlId="formHorizontalEmail"
                          >
                            <Col sm={12}>
                              <Form.Label className="w-100">
                                Category
                              </Form.Label>

                              <Select
                                className="w-100"
                                options={categoryselectoption}
                                onChange={handleCategory}
                                value={categoryselected}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                              <br/>
                              <div style={{ color: "red",fontSize: 13 }}>
                                {formError.categoryselected}
                              </div>
                            </Col>
                           

                          </Form.Group>
                          <Col className='mt-4 mb-4' md={12}>

<MaterialTable
  title="Parameters"
  columns={columns1}
  data={dataList}
  options={{
    search: false
  }}
  editable={{
    onBulkUpdate: newData =>
      new Promise((resolve, reject) => {
        handleRowupdate(newData, resolve, reject);
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {

           handleDelete(oldData, resolve, reject)
      }),
  }}
/>
</Col>
                          </Row>
                          <Row>
                         
                          </Row>
                          <div>
                          <Form.Group
                            as={Row}
                            className="mb-3 justify-content-center"
                            // controlId="formHorizontalEmail"

                          >
                            <Col sm={6} className="mb-4">
                              <Form.Label className="w-100"> Image 1</Form.Label>
                              <FormControl className="m-2" type="file" name='file' controlId={productImage1} ref={inputRefrence1} onChange={handleimage1} />
                              <br/>
                              <div style={{ color: "red" ,fontSize: 13}}>{formError.productImage1}</div>
                            </Col>
                            <Col sm={6} className="mb-4">
                              <Form.Label className="w-100"> Image 2</Form.Label>
                              <FormControl className="m-2" type="file" name='file' controlId={productImage2} ref={inputRefrence2} onChange={handleimage2} />
                              <br/>
                              <div style={{ color: "red",fontSize: 13 }}>{formError.productImage2}</div>
                            </Col>

                            <Col md={6}>
                              <div className="imagepreview">
                                <img alt=" "className="fixed_img" src={update === false ? image1Path : productImage1.name === undefined ? PhotoPath + productImage1 : image1Path} />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="imagepreview">
                                <img alt=" " className="fixed_img " src={update === false ? image2Path : productImage2.name === undefined ? PhotoPath + productImage2 : image2Path} />
                              </div>
                            </Col>

                            <Col sm={6} className="mb-4">
                              <Form.Label className="w-100"> Image 3</Form.Label>
                              <FormControl className="m-2" type="file" name='file' controlId={productImage3} ref={inputRefrence3} onChange={handleimage3} />
                              <br/>
                              <div style={{ color: "red",fontSize: 13 }}>{formError.productImage3}</div>
                            </Col>
                            <Col sm={6} className="mb-4">
                              <Form.Label className="w-100"> Image 4</Form.Label>
                              <FormControl className="m-2" type="file" name='file' controlId={productImage4} ref={inputRefrence4} onChange={handleimage4} />
                              <br/>
                              <div style={{ color: "red",fontSize: 13 }}>{formError.productImage4}</div>
                            </Col>
                            <Col md={6}>
                              <div className="imagepreview">
                                <img alt=" " className="fixed_img " src={update === false ? image3Path : productImage3.name === undefined ? PhotoPath + productImage3 : image3Path} />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="imagepreview">
                                <img alt=" "className="fixed_img " src={update === false ? image4Path : productImage4.name === undefined ? PhotoPath + productImage4 : image4Path} />
                              </div>
                            </Col>
                            <Col sm={6} className="mb-4">
                              <Form.Label className="w-100"> Image 5</Form.Label>
                              <FormControl className="m-2" type="file" name='file' controlId={productImage5} ref={inputRefrence5} onChange={handleimage5} />
                              <br/>
                              <div style={{ color: "red",fontSize: 13}}>{formError.productImage5}</div>
                            </Col>
                            <Col sm={6} className="mb-4"></Col>
                            <Col md={6}>
                              <div className="imagepreview">
                                <img alt=" " className="fixed_img " src={update === false ? image5Path : productImage5.name === undefined ? PhotoPath + productImage5 : image5Path} />
                              </div>
                            </Col>
                            <Col md={6}></Col>
                          </Form.Group>
                          </div>
                          <div className="button_style m-0 text-center">
                            {update === false ? (
                              <Button
                                type="button"
                                variant='contained'
                                onClick={() => handleSubmit()}
                              >
                                Submit
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                variant='contained'
                                color='success'
                                onClick={() => handleUpdate()}
                              >
                                Update
                              </Button>
                            )} {" "}
                            <Button
                              type="button"
                              variant='contained'
                              color='error'
                              onClick={() => handleclear()}
                            >
                              Cancel
                            </Button>
                          </div>
                          {/* )} */}
                        </Card>
                      </>
                    
                  </div>
                ) :

                  <Col className='mt-0' md={12} >
                    <MaterialTable
                      title={false}
                      data={productlist}
                      columns={columns}
                      actions={[
                        {
                          icon: "edit",
                          tooltip: "Edit",
                          onClick: (event, rowData) => {
                            EditProduct(rowData);
                          },
                        },

                        {
                          icon: "delete",
                          tooltip: "Delete",
                          onClick: (event, rowData) => {
                            DeleteProduct(rowData.productid);
                          },
                        },
                      ]}
                      options={{
                        search: true,
                        pageSize: 10,
                        showFirstLastPageButtons: false,
                        actionsColumnIndex: -1,
                        addRowPosition: "first",
                        pageSizeOptions: [10, 15, 20, 50],
                      }}
                    />
                  </Col>
                }
              </div>
            </section>
          </div>
        </form>
      </div>
    </>
  );
};

export default Product;
