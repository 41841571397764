import React, { useEffect, useState } from 'react'
import MaterialTable from "material-table";
import { Row, Col, Card, Form } from 'react-bootstrap';
import Swal from "sweetalert2";
import { parameterValueTypeList, paramterValue, OneparameterValue, updateData, trashData } from './ProductServiceAPI';
import Button from '@mui/material/Button';



const ParameterValuetype = () => {


    const [TypeName, setTypeName] = useState('');
    const [TypeData, setTypeData] = useState([]);
    const [typeID, settypeID] = useState(null);
    const [update, setupdate] = useState(false)

    const [typenameError, settypenameError] = useState('');


    const columns = [
        { title: "Paramter Type", field: "parameterValueTypeName", align: "center" },
    ];

    const handleName = (e) => {

        setTypeName(e.target.value);

        settypenameError('')
    }

    useEffect(() => {
        getList();
    }, [])

    const getList = () => {

        parameterValueTypeList()
            .then((res) => {

                const data = res.data._response.data.getparametervaluetype

                setTypeData(data)
            })
            .catch((err) => {
                console.log(err, "err in getlist paramtervaluetype");
            })

    }

    const formValidation = () => {

        let typenameError = '';
        let regex = /^[A-Za-z0-9 ]+$/;

        if (TypeName === '') {
            typenameError = "Please fill the Details";
        } else if (regex.test(TypeName) === false) {
            typenameError = 'Please do not use special Character'
        }

        if (typenameError) {
            settypenameError(typenameError)
            return false;
        }

        return true;

    }

    const saveData = () => {

        const isValid = formValidation();

        if (isValid) {

            const ParamterData = { parameterValueTypeName: TypeName }

            paramterValue(ParamterData)
                .then((res) => {
                    if (res.data._response.status === 1) {
                        Swal.fire({
                            icon: "success",
                            title: "Parameter Value Type Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });

                        getList();
                        cancelUser();
                    }
                    else if (res.data._response.status === 2) {

                        Swal.fire({
                            icon: " Value Type Not Added",
                            title: res.data._response.message,
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                    }

                })
                .catch((err) => {

                    console.log(err, "err in add parametervalue type");

                })
        }
    }

    const getData = (id) => {

        OneparameterValue(id)
            .then((res) => {
                setupdate(true);

                let data = res.data._response.data.parametervaluetypegetid[0];

                let typeName = data.parameterValueTypeName;
                let typeID = data.parameterValueTypeId;

                setTypeName(typeName);
                settypeID(typeID);

            }).catch((error) => {

                Swal.fire({
                    icon: "error",
                    title: "Something went wrong",
                    showConfirmButton: true,
                    confirmButtonColor: "#3085d6",
                    // cancelButtonColor: '#d33',
                    confirmButtonText: "ok",
                });
            })
    }

    const editUser = () => {

        const isValid = formValidation();

        if (isValid) {


            const ParamterData = {
                parameterValueTypeName: TypeName,
                parameterValueTypeId: typeID

            }

            updateData(ParamterData)
                .then((res) => {
                    if (res.data._response.status === 1) {

                        Swal.fire({
                            icon: "success",
                            title: "Parameter Value Type Updated Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        getList();
                        cancelUser();
                        settypeID(null)

                    }

                })
                .catch((err) => {
                    Swal.fire({
                        icon: "error",
                        title: "Parameter Value Type Not Updated",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });

                })
        }
    }

    const deleteData = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {

            if (result.isConfirmed) {

                trashData(id)
                    .then((res) => {

                        if (res.status === 200) {
                            Swal.fire({
                                icon: "success",
                                title: "Parameter value Type Deleted Sucessfully",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });

                            getList();
                        }

                    })
                    .catch((err) => {

                        Swal.fire({
                            icon: "error",
                            title: "Parameter value Type Not Deleted",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });

                    })

            }
        })
            .catch((err) => {
                console.log(err, "err in trash parametervaluetype");
            })




    }

    const cancelUser = () => {

        setTypeName('');

        settypenameError('');
        setupdate(false);

    }

    return (
        <>
            <div>
                <div>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                                <br/><br/>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h1 className="m-0 text-center">Parameter Value Type</h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="content consti-section">
                            <div className="container">
                                <div className="row jusrify-content-center">
                                    <Col md={12}>
                                        <Card className='mt-4' style={{ padding: "20px" }}>
                                            <Form.Group as={Row} className="justify-content-center" controlId="formHorizontalEmail">
                                                <Col sm={10} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">Parameter Value Type</Form.Label>
                                                    <input className="w-100 form-control" type="text" placeholder='Parameter Value Type' value={TypeName} onChange={handleName} />
                                                    <div style={{ fontSize: 14, color: "red" }}>{typenameError}</div>
                                                </Col>
                                            </Form.Group>
                                            <div className="button_style m-0 text-center">
                                                {
                                                    update === false ?
                                                        <Button
                                                            type="submit"
                                                            variant='contained'
                                                            onClick={saveData}
                                                        >
                                                            Submit
                                                        </Button>
                                                        :
                                                        <Button
                                                            type="submit"
                                                            variant='contained'
                                                            color='success'
                                                            onClick={() => { editUser(); }}
                                                        >
                                                            Update
                                                        </Button>


                                                } {" "}
                                                <Button
                                                    type="submit"
                                                    variant='contained'
                                                    color='error'
                                                    onClick={cancelUser}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col className='mt-4' md={12}>

                                    <MaterialTable
                                        title={false}
                                        data={TypeData}
                                        columns={columns}
                                        actions={[
                                            {
                                                icon: "edit",
                                                tooltip: "Edit",
                                                onClick: (event, rowData) => {
                                                    getData(rowData.parameterValueTypeId);
                                                },
                                            },
                                            {
                                                icon: "delete",
                                                tooltip: "Delete",
                                                onClick: (event, rowData) => {
                                                    deleteData(rowData.parameterValueTypeId);
                                                },
                                            },
                                        ]}
                                        options={{
                                            search: false,
                                            pageSize: 5,
                                            showFirstLastPageButtons: false,
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                        }}
                                    />
                                    </Col>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ParameterValuetype