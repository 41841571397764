import React from 'react';
import { Form, Row, Col, FormControl ,Container} from "react-bootstrap";
import Button from '@mui/material/Button';
import share from '../../../assets/img/share.png';
import Image from 'react-bootstrap/Image';
import IconButton from '@mui/material/IconButton';
import {  useNavigate } from "react-router-dom";
import age1 from '../../../assets/img/age1.png'
import gender from '../../../assets/img/gender.png'
import boundary from '../../../assets/img/boundary.png'
import education from '../../../assets/img/education.png'
import employee from '../../../assets/img/employee.png'
 


export default function AdminPage() {

const navigate = useNavigate();



  const AgeReport = ()=>{
    
    navigate('/AgewiseReport')
  }

  const GenderReport = ()=>{
    
    navigate('/GenderwiseReport')
  }

  const CountyReport = ()=>{
    
    navigate('/CountywiseReport')
  }

  const EducationReport = ()=>{
    
    navigate('/EducationwiseReport')
  }

  const OccupationReport = ()=>{
    
    navigate('/OccupationwiseReport')
  }


  const CelebrityAgeReport = ()=>{

    navigate('/AgewiseCelebrityReport')

  }

  const CelebrityGenderReport = ()=>{

    navigate('/GenderwiseCelebrityReport')

  }


  const CelebrityCountyReport = ()=>{

    navigate('/CountywiseCelebrityReport')

  }


  const CelebrityEducationReport = ()=>{

    navigate('/EducationwiseCelebrityReport')

  }


  const CelebrityOccupationReport = ()=>{

    navigate('/OccupationwiseCelebrityReport')

  }

  return (
    <>
    <Container>
    
    <div className="text-start mt-5 mb-4 org-head">
       <h4 className='org-heading '> Organization's Overview</h4>
    </div>
   
    <h2 className="mt-4 text-start mx-auto adm-heading1">PRODUCT REPORTS</h2>
    
      <br/>
      <div class="row row-cols-1 row-cols-lg-5 g-2 g-lg-3">
        <div class="col">
            <div class="card bg-c-blue order-card" onClick={AgeReport}>
                <div class="card-block">
                    <h5 class="m-b-20">Age Report</h5>
                    <h2 class="text-right"><img src={age1} alt='age' /></h2>
                </div>
            </div>
        </div>
        
        <div class="col">
            <div class="card bg-c-green order-card" onClick={GenderReport}>
                <div class="card-block">
                    <h5 class="m-b-20">Gender Report</h5>
                    <h2 class="text-right"><img src={gender} alt='gender' /></h2>
                </div>
            </div>
        </div>
        
        <div class="col">
            <div class="card bg-c-yellow order-card" onClick={CountyReport}>
                <div class="card-block">
                    <h5 class="m-b-20">County Report</h5>
                    <h2 class="text-right"><img src={boundary} alt='county' /></h2>
                </div>
            </div>
        </div>
        
        <div class="col">
            <div class="card bg-c-pink order-card" onClick={EducationReport}>
                <div class="card-block">
                    <h5 class="m-b-20">Education Report</h5>
                    <h2 class="text-right"><img src={education} alt="education" /></h2>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card bg-c-purple order-card" onClick={OccupationReport}>
                <div class="card-block">
                    <h5 class="m-b-20">Occupation Report</h5>
                    <h2 class="text-right"><img src={employee} alt="emplyoee" /></h2>
                </div>
            </div>
        </div>
	</div>
      <div className='dashed-line'></div>
      <div className="text-center mt-5"></div>
      <h2 className="mt-4 text-start mx-auto adm-heading1">CELEBRITY REPORTS</h2>
      <br/>

      
  

 

  <div>

  <div class="row row-cols-1 row-cols-lg-5 g-2 g-lg-3">
        <div class="col">
            <div class="card bg-c-blue order-card" onClick={CelebrityAgeReport}>
                <div class="card-block">
                    <h5 class="m-b-20">Age Report</h5>
                    <h2 class="text-right"><img src={age1} alt="age" /></h2>
                </div>
            </div>
        </div>
        
        <div class="col">
            <div class="card bg-c-green order-card" onClick={CelebrityGenderReport}>
                <div class="card-block">
                    <h5 class="m-b-20">Gender Report</h5>
                    <h2 class="text-right"><img src={gender} alt="gender" /></h2>
                </div>
            </div>
        </div>
        
        <div class="col">
            <div class="card bg-c-yellow order-card" onClick={CelebrityCountyReport}>
                <div class="card-block">
                    <h5 class="m-b-20">County Report</h5>
                    <h2 class="text-right"><img src={boundary} alt="county" /></h2>
                </div>
            </div>
        </div>
        
        <div class="col">
            <div class="card bg-c-pink order-card" onClick={CelebrityEducationReport}>
                <div class="card-block">
                    <h5 class="m-b-20">Education Report</h5>
                    <h2 class="text-right"><img src={education} alt="education" /></h2>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card bg-c-purple order-card" onClick={CelebrityOccupationReport}>
                <div class="card-block">
                    <h5 class="m-b-20">Occupation Report</h5>
                    <h2 class="text-right"><img src={employee} alt="emplyoee" /></h2>
                    
                </div>
            </div>
        </div>
	</div>
  </div>

 
  </Container>

  
  </>
  )
}
