import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Card, Container } from "react-bootstrap";
import Select from "react-select";
import Button from '@mui/material/Button';
import { getCelebrityCampaign,getCelebrityReport, getcelebrityReportByOccupation,} from './CelebrityServiceAPI'
import Swal from "sweetalert2";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Loader from '../../../common/Loader'
import { useReactToPrint } from 'react-to-print'
import axios from "../../../ServerCalls/AxiosInstance";
import Chart from 'react-apexcharts';
import { getGenderReport } from '../ProductServiceAPI';
import NotFound from '../../../common/NotFound';

const CelebrityOccupationReport = () => {


  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  
  const [Occupationdata, setOccupationdata] = useState([]);
  const [OccupationTotal,setOccupationTotal] = useState([]);
  const [Total, setTotal] = useState([])

  
    const [loading, setloading] = useState(false);
   

    const useStyles = makeStyles({

      table: {


          "& .MuiTableCell-root": {

              border: "1px solid rgba(0, 0, 0, 0.3)"

          }

      }

  });

  const classes = useStyles();


  const componentRef = useRef();

  const handlePrint = useReactToPrint({
      content: () => componentRef.current
  })



  const handleCampaign = (selected) => {
      setSelectedCampaign(selected)
  }


  const clear = () => {

      setSelectedCampaign('')

  }



  useEffect(() => {

      campaignList();
      if(selectedCampaign != ''){
        reports();
     
        // occupationReport();
      }
     
    


  }, [selectedCampaign])


  const campaignList = () => {
      getCelebrityCampaign().then((res) => {

          let arr = [];
          let list = res.data._response.data.getcelebritycampaign;

          list.map((a) => (
              arr.push({
                  label: a.campaignShortName,
                  value: a.campaignId,
              })
          ))
          setCampaignOptions(arr)
      })  .catch((err)=>{
        console.log(err,'err in get campaignlist');
    })

  }


//   const occupationReport = ()=>{

//     // axios.get(`https://localhost:5002/api/ProductManagement/CelebrityReportforOccupation?campaignid=${selectedCampaign.value}`)
//     getCelebrityOccupationReport(selectedCampaign.value)
//    .then((res)=>{

//       console.log(res,"got occupationReport")
//       const occupationdata = res.data._response.data.alloccupationcount

//       let occupationName = [];
//       let occupationCount = [];

//       occupationdata.map((a)=>{
//       occupationName.push(a.occupation)
//   })

//       occupationdata.map((a)=>{
//           occupationCount.push(a.count)

//       })

//       setPieOccupationName(occupationName)
//       setPieOccupationCount(occupationCount)


//    }).catch((err)=>{
//        console.log(err)
//    })

// }

const reports = () => {
    setloading(true);
    //  axios.get(`https://localhost:5002/api/ProductManagement/CelebrityOccupationReport?campaignid=${selectedCampaign.value}`)
        getcelebrityReportByOccupation(selectedCampaign.value)
        .then((res) => {

            console.log(res, "got celebrity occupation report");


            if (res.data._response.status === 1) {
             
               
                let Occupation = res.data._response.data.individualoccupationforcelebrity;
                let overallcounttotal =  res.data._response.data.occupationtotal
                let TotalProductOccupation = res.data._response.data.occupationwisetotalofallcelebrity;
             
                setOccupationdata(Occupation);
                setTotal(overallcounttotal);
                setOccupationTotal(TotalProductOccupation);
                setloading(false);


              
            } else if (res.data._response.status === 2) {

              setloading(false);
              setOccupationdata([]);
              setTotal([]);
              setOccupationTotal([]);

              
             
            }
        }).catch((err)=>{
            setloading(false);
            console.log(err,"err in get occupationReport");
        })
}




  return (
    <div>
    <div>
        <div className="content-wrapper consti-section" id="divToPrint">
            <div className="content-header">
                <div className="container-fluid">
                    <br /><br />
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <h1 className="m-0 text-center">Voting List</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container">
                    <div className="row">
                        <Col md={12}>
                            <Card style={{ padding: "20px" }}>
                                <Form.Group
                                    as={Row}
                                    className="mb-1 justify-content-center"
                                    controlId="formHorizontalEmail"
                                >

                                    <Col sm={12} className="mb-4">
                                        <Form.Label className="w-100">Campaign</Form.Label>
                                        <Select
                                            className="w-100"
                                            options={campaignOptions}
                                            value={selectedCampaign}
                                            onChange={handleCampaign}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <div className="button_style m-0 text-center">

                                    <Button
                                        type="button"
                                        variant='contained'
                                        color='error'
                                        onClick={clear}
                                    >
                                        Cancel
                                    </Button>
                                </div>

                            </Card>
                        </Col>

                    </div>

                </div>

            </section>

        </div>
        <br></br>
    </div>
    <br /><br />







    {
     loading ?
     <Loader/>
     :

    
    selectedCampaign === '' || Occupationdata.length === 0 ? (

        <NotFound />


    ) :
        <>
            <div ref={componentRef}>
                <h1 className="m-0 text-center">Voting  Report Summary</h1>
                <h4 className="m-0 text-center">{selectedCampaign.label}</h4>
                <br></br>

                <Container>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report">
                        <TableContainer >
                        
                            <div className="mt-5">
                                <h2>Occupation Wise</h2>
{/* 
                                <div className="row">
       <div className="col-md-6"> */}



<Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                            <TableHead >

                                                <TableRow className="backgroundrow">
                                                    <TableCell></TableCell>
                                                    <>
                                                        {Occupationdata.map((data, id) => (
                                                            <TableCell width="10%" align="center" rowSpan={2} className="head1">{data.productname}</TableCell>
                                                         ))} 


                                                    </>

                                                    <TableCell align="center" width="10%" rowSpan={2} className="head1">Total</TableCell>

                                                    
                                                </TableRow>


                                            </TableHead>
                                            <TableBody>
                                                
                                                   {Occupationdata.map((a, i)=>{
                                                      
                                                           if(i === 0){
                                                            return(
                                                            a.c.map((f)=>(
                                                                <TableRow>
                                                            <TableCell width="10%"  align="center" >{f.occupationName}</TableCell>
                                                          {  Occupationdata.map((data,ind)=>{
                                                        
                                                              let count = data.c.find((g)=>g.occupationName === f.occupationName);
                                                              let total = OccupationTotal.find((t)=> t.occupationname === f.occupationName )

                                                              if(Occupationdata.length - 1 !== ind){
                                                                return(
                                                                    <TableCell width="10%" align="center"  >{count.count}</TableCell>
                                                                   
                                                                   
                                                               
                                                                )
                                                              }
                                                               if(Occupationdata.length - 1 === ind){
                                                                return(
                                                                    <>
                                                                    <TableCell width="10%" align="center"  >{count.count}</TableCell>
                                                                    <TableCell width="10%" align="center"  >{total.count}</TableCell>
                                                                    </>
                                                                   
                                                                   
                                                               
                                                                )
                                                              }
                                                               
                                                               
                                                            })}

                                                        

                                                            </TableRow> 
                                                               ))
                                                       )

                                                           }
                                                        
                                                        
                                                    
                                          } )}

                                                    

                                                <TableRow>

                                                    <TableCell align="left" className="backgroundrow2"  >Total Votes</TableCell>

                                                {Occupationdata.map((total, e) => ( 
                                                        <TableCell align="center">{total.overallcount}</TableCell>
                                                  ))} 
                                                    <TableCell className="headerbgcolor" align="center">{Total}</TableCell> 



                                                </TableRow>

                                            </TableBody>

                                        </Table>

                                </div>
                                {/* <div className="col-md-6">       
                                      
                     <Chart
      
      type="pie"
      width={500}
      height={500}
       series={PieOccupationCount}

      options={{
        title: { text: "Voting Report" },
        noData: { text: "Empty Data" },
        colors: ['#ac92eb', '#4fc1e8', '#8bcb30', '#ffc809', '#ed5564', '#e0440e', '#e6693e',"#679cab","#777777"],
        labels: PieOccupationName,
        responsive: [{
            breakpoint: 1024,
            options: {
              chart: {
                width: 700
              },
              legend: {
                position: 'right'
              }

            },
            breakpoint: 480,
            options: {
              chart: {
                width: 250
              },
              legend: {
                position: 'bottom'
              },
              
            },
          

          }]
      }}

    />                  </div> */}
                                {/* </div>

                    
                            </div> */}




                              


                        </TableContainer>
                    </Paper>
                </Container>
            </div>
            <br></br>
            <div className="text-center m-0">
                {/* <Button variant="contained" onClick={downloadReceipt}>Download</Button> */}
                &nbsp;&nbsp;
                <Button variant="contained" onClick={handlePrint}>Print</Button>
            </div>

        </>

    }





</div>
  )
}

export default CelebrityOccupationReport