import React, { useState, useEffect } from "react";
import { ProductwithPage, productbyCategoryandBrand, Allbrands, Categorywithnoparent } from "./ProductServiceAPI";
import {Container, Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import ProductURL from "../../ServerCalls/ProductServer";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FcInfo, FcFeedback} from "react-icons/fc";
import { BsShareFill } from 'react-icons/bs';
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import { Pagination } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@material-ui/core'
import Loader from "../../common/Loader";
import NotFound from "../../common/NotFound";
import { FacebookIcon,FacebookShareButton,WhatsappShareButton,WhatsappIcon,TwitterShareButton,TwitterIcon,} from 'react-share';
import { useDispatch,useSelector } from "react-redux";
import { AddUpdateBrand,AddUpdateCategory } from "../../../redux/action/userproductgalleryAction";
import bcrypt from 'bcryptjs' ;
import {Helmet} from 'react-helmet';
import MetaDataComponent from '../Citizen/MetaDataComponent';
// import Tooltip from '@mui/material/Tooltip';
// import { BsShareFill } from 'react-icons/bs';
// import IconButton from "@mui/material/IconButton";
const title = 'Product Information';
const ProductGallery = () => {
    /* redux */
    const state = useSelector((state) => state.userproduct);
    const dispatch = useDispatch();
    /* end redux */
  const [productsList, setProductList] = useState([]);
  const [image, setImage] = useState('');
  const [showB, setShowB] = useState(false);
  const [categorySelected, setcategorySelected] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [brandSelected, setBrandSelected] = useState([]);
  const [brandOption, setBrandOption] = useState([]);
  const PhotoPath = ProductURL.photoURL + "ProductImages/";
  const [loading, setLoading] = useState(false);
  const [productID, setProductID] = useState(0)
  const [pageC, setPageC] = useState(8)
  const [pageNumber, setPageNumber] = useState(1);
  // const [hashid, setHashId] = useState(0);
  const dataPerPage = 8;
  // const encryptid = () => {
  //   const hashpassword = bcrypt.hashSync(productID);
  //   console.log(hashpassword);
  // };
  const handleClose = () => {
    setShowB(false);
  };
  useEffect(() => {
    if(Object.keys(state.brand).length !== 0 && Object.keys(state.category).length !== 0){
      setBrandSelected(state.brand);
      setcategorySelected(state.category);
    }
    if(Object.keys(state.brand).length !== 0 &&  Object.keys(state.category).length === 0){
      setBrandSelected(state.brand);
    }
    if(Object.keys(state.category).length !== 0 && Object.keys(state.brand).length === 0  ){
      setcategorySelected(state.category);
    }
    getCategory();
    getBrand();
  }, []);
  const products = (a, b) => {
    setLoading(true);
    ProductwithPage(a, b)
      .then((res) => {
        if (res.data._response.status === 1) {
          let product = res.data._response.data.getproductwithpagination;
          let count = res.data._response.data.count;
          let APIpagecount = Math.ceil(count / dataPerPage);
          setProductList(product);
          setPageC(APIpagecount)
          setImage(product.productimage1)
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "errr");
        setLoading(false)
      });
  };
  const getCategory = () => {
    Categorywithnoparent().then((res) => {
      const category = res.data._response.data.getprocategory
      let categorydata = category.map((list) => ({
        value: list.categoryid,
        label: list.categoryName
      }))
      setCategoryOption(categorydata)
    }).catch((err) => {
      console.log(err);
    })
  }
  useEffect(() => {
    if (brandSelected.length !== 0 || categorySelected.length !== 0) {
      getbyBrandandCategoryId(1);
    } else {
      setPageNumber(1)
      if(Object.keys(state.brand).length === 0 && Object.keys(state.category).length === 0){
      products(1, 8);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandSelected, categorySelected])
  const getbyBrandandCategoryId = (pgno) => {
    setLoading(true);
    let brandid = brandSelected.value === undefined ? 0 : brandSelected.value;
    let categoryid = categorySelected.value === undefined ? 0 : categorySelected.value;
    productbyCategoryandBrand(brandid, categoryid, pgno)
      .then((res) => {
        const pdata = res.data._response.data.productgetid
        if (pdata.length <= 0) {
          setProductList([])
          setLoading(false);
        } else {
          let count = res.data._response.data.count;
          let APIpagecount = Math.ceil(count / dataPerPage)
          setProductList(pdata)
          setPageC(APIpagecount)
          setLoading(false);
        }
      }).catch((error) => {
        setLoading(false)
        Swal.fire({
          icon: "error",
          title: "Product not Found",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      })
  }
  const handleCategory = (selected) => {
    selected ? dispatch(AddUpdateCategory(selected)) : dispatch(AddUpdateCategory([])) 
    selected ? setcategorySelected(selected) : setcategorySelected([]);
  }
  const handleBrand = (selected) => {
    selected ? dispatch(AddUpdateBrand(selected)) : dispatch(AddUpdateBrand([])) 
    selected ? setBrandSelected(selected) : setBrandSelected([]);
  }
  const changePage = (e, a) => {
    setPageNumber(a);
    if (brandSelected.length !== 0 || categorySelected.length !== 0) {
      getbyBrandandCategoryId(a, dataPerPage)
    } else {
      products(a, dataPerPage);
    }
  }
  const getBrand = () => {
    Allbrands()
      .then((res) => {
        if (res.data._response.status === 1) {
          let data1 = res.data._response.data.getbrand;
          let options = data1.map((d) => ({
            value: d.brandId,
            label: d.brandName,
          }));
          setBrandOption(options);
        }
      })
      .catch((err)=>{
        console.log(err,'err in getallbrands');
      })
  }
  const openModal = (e) => {
    // console.log(e);
    let id = btoa(e.productid);
    let text = id.toString();
    const hashpassword = bcrypt.hashSync(text);
    // setHashId(hashpassword)
    setProductID(id)
    setShowB(true);
  };
  const renderCard = (e, index) => {
    return (
      <div className="col-md-3 col-sm-6 pb-5">
        <Card className="h-100">
            <div className="product-grid">
              <div className="product-image">
                <img className="pic-1" src={PhotoPath + e.productimage1} alt="products"  />
                <img className="pic-2" src={PhotoPath + e.productimage1} alt="products" />
                <ul className="product-links">
                  <Link className="p" to={`/product-details/${e.productid}`}><Tooltip title="More Info"><IconButton><FcInfo fontSize="35px" /></IconButton></Tooltip></Link>
                  &nbsp;<Link className="p" to={`/feedback/${e.productid}/${e.productname}`}><Tooltip title="Feedback"><IconButton><FcFeedback fontSize="35px" /></IconButton></Tooltip> </Link>
                  &nbsp;<div className="p"><Tooltip title='Share'><IconButton><BsShareFill fontSize="25px" color="#45a147" onClick={() => openModal(e)} /></IconButton></Tooltip></div>
                </ul>
              </div>
              {/* <div className="product-content"> */}
              {/* <ul className="rating">
                      <li className="fas fa-star ulli"></li>
                      <li className="fas fa-star ulli"></li>
                      <li className="fas fa-star ulli"></li>
                      <li className="fas fa-star ulli"></li>
                      <li className="fas fa-star disable"></li>
                      <li className="disable">(1 reviews)</li>
                    </ul> */}
               <Card.Body>
              <div className="text-start mx-auto py-2 font-weight-bold h4 bname">{e.brandname}</div>
              <div className="text-start mx-auto mt-2 font-weight-bold h5 text-primary pname">{e.productname}</div>
              </Card.Body>
            </div>
        </Card>
        <br />
      </div>
    );
  };
  return (
    <>


{/* <MetaDataComponent></MetaDataComponent>  */}
{/* <Helmet>
  <title>topbrandsranking sharelink</title>
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta charset="utf-8" />
  <meta property="url" content={`https://topbrandsranking.com/ShareProductDetails/${productID}`} />
  <meta property="og:url" content={`https://topbrandsranking.com/ShareProductDetails/${productID}`} />
  <meta property="image" content={PhotoPath + image} />
  <meta property="og:image" content={PhotoPath + image}/>
</Helmet> */}
      <div>
        <div className="">
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <br /><br />
                <div className="row mb-2">
                  <div className="col-sm-12">
                    <h1 className="m-0 text-center">BRAND RANKING</h1>
                  </div>
                </div>
              </div>
            </div>
            <section className="content  mt-4 consti-section">
              <div className="container">
                <div className="row">
                  <Col md={12}>
                    <Card style={{ padding: "20px" }}>
                      <Form.Group
                        as={Row}
                        className="justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={6} className="mb-4">
                          <h5>
                            Select Brand
                          </h5>
                          <Select
                            isClearable={brandSelected}
                            className="w-100"
                            options={brandOption}
                            onChange={handleBrand}
                            value={brandSelected}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mb-4">
                          <h5>
                            Select Category
                          </h5>
                          <Select
                            isClearable={categorySelected}
                            className="w-100"
                            options={categoryOption}
                            onChange={handleCategory}
                            value={categorySelected}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                        </Col>
                      </Form.Group>
                      {/* <Button className="detailsbtn">Clear</Button> */}
                    </Card>
                  </Col>
                </div>
              </div>
            </section>
            <Container>
              <card-group className="d-flex" style={{ marginTop: "36px" }}>
                <Col md={12} className="mb-2">
                  {loading ?
                    <Loader />
                    :
                    productsList.length !== 0 ?
                      <Row sm="5"> {productsList.map(renderCard)} </Row>
                      :
                      <NotFound name="Product Not Found" />
                  }
                </Col>
              </card-group>
            </Container>
            <br /><br />
            {/* <Pagination_comp/> */}
            {
              productsList.length !== 0 ?
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Pagination
                    count={pageC}
                    page={pageNumber}
                    onChange={changePage}
                    shape="rounded" color="primary" size="large"
                  />
                </Box>
                :
                null
            }
            <br /><br />
          </div>
        </div>
      </div>
      {/* sharing option */}
      <Modal
        show={showB}
        onHide={handleClose}
        size="sm"
        backdrop="static"
        centered
      >
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="mx-auto">
            <h5>
              <b>Share Product</b>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Link to="https://web.whatsapp.com/"> */}
          <Row className="text-center">
            
              <Col sm={4}>
              < WhatsappShareButton
                url={`https://topbrandsranking.com/ShareProductDetails/${productID}`}
                quote={title}
                image='../../../../public/logo1.ico'
                // imageURL={PhotoPath + image}
                className="Demo__some-network__share-button"
              >
                < WhatsappIcon size={32} round />
              </ WhatsappShareButton>

              {/* <Button>
                <meta property="og:title" content="short title of your website/webpage" />
                <meta property="og:url" content= {`https://topbrandsranking.com/ShareProductDetails/${productID}`} />
                <meta property="og:description" content="description of your website/webpage" />
                <meta property="og:image" content={PhotoPath + image} />
              </Button> */}
            </Col>
            <Col sm={4}>
              <FacebookShareButton
                url={`https://topbrandsranking.com/ShareProductDetails/${productID}`}
                quote={title}
                className="Demo__some-network__share-button"
                // image={PhotoPath + image}
                image='../../../../public/logo1.ico'
                // onClick={encryptid}
              >
                {/* <meta property="og:title" content="short title of your website/webpage" />
                <meta property="og:url" content= {`https://topbrandsranking.com/ShareProductDetails/${productID}`} />
                <meta property="og:description" content="description of your website/webpage" />
                <meta property="og:image" content={PhotoPath + image} /> */}
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </Col>
            
            <Col sm={4}>
            < TwitterShareButton
                url={`https://topbrandsranking.com/ShareProductDetails/${productID}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
              <TwitterIcon size={32} round />
              </ TwitterShareButton>
            </Col>
            {/* <Col sm={3}>
            < InstapaperShareButton
                url={`http://tbr.softlabsgroup.in/ShareProductDetails/${productID}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
              <InstapaperIcon size={32} round />
              </ InstapaperShareButton>
            </Col> */}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ProductGallery;