import React, { useState, useEffect } from 'react';

import { Form, Row, Col, Card } from "react-bootstrap";
import Select from "react-select";
import MaterialTable from "material-table";
import ProductURL from '../../../ServerCalls/ProductServer';
import { campaignData, pollingreport } from '../ProductServiceAPI'
import { useNavigate } from 'react-router-dom';
const PhotoPath = ProductURL.PhotoUrl + "ProductImages/";


export default function PollingReports() {

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('')


  const columns = [
    { title: "Product", field: "productname", align: "center" },
    {
      title: "Product image", field: "productimage",
      render: (item) => (
        <img src={PhotoPath + item.productimage} alt="" border="3" height="50px" width="50px" />
      ),
      align: "center",
    },
    { title: "Average Polling Percentage (%)", field: "average", align: "center", },
  ];


  const handleCampaign = (selected) => {
    setSelectedCampaign(selected)
  }


  const viewdetails = (e) => {
    navigate("/ViewpollingDetails", { state: e });
  };

  useEffect(() => {
    reports();
    campaignList();
  }, [selectedCampaign])


  const campaignList = () => {
    campaignData().then((res) => {

      let arr = [];
      let list = res.data._response.data.getpollingcampaign;

      list.map((a) => (
        arr.push({
          label: a.pollingCampaignShortName,
          value: a.campaignId,
        })
      ))
      setCampaignOptions(arr)
    })

  }


  const reports = () => {

    pollingreport(selectedCampaign.value).then((res) => {

      if (res.data._response.status === 1) {

        setData(res.data._response.data.citizenvotinggetcampaignid1)
      }

    }).catch((err) => {
      console.log(err, "err in get polling list");
    })
  }


  return (


    <div>
      <div>
        <div className="content-wrapper consti-section">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-12">
                  <h1 className="m-0 text-center">Polling List</h1>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <Col md={{ span: 8, offset: 2 }}>
                  <Card style={{ padding: "20px" }}>
                    <Form.Group
                      as={Row}
                      className="mb-1 justify-content-center"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={12} className="mb-4">
                        <Form.Label className="w-100">Campaign</Form.Label>
                        <Select
                          className="w-100"
                          options={campaignOptions}
                          value={selectedCampaign}
                          onChange={handleCampaign}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                      </Col>
                    </Form.Group>

                    <div className="button_style mt-3 text-center">

                      <button
                        type="button"
                        className="btn btn-success btn-pos2"
                      //onClick={clear}
                      >
                        Cancel
                      </button>
                    </div>

                  </Card>
                </Col>
                <Col className='mt-2' md={{ span: 8, offset: 2 }}>
                  <MaterialTable
                    title={false}
                    data={data}
                    columns={columns}
                    options={{
                      search: false,
                      pageSize: 5,
                      showFirstLastPageButtons: false,
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                    }}

                    actions={[
                      {
                        icon: "visibility",
                        tooltip: "View Details",
                        onClick: (event, rowData) => {
                          viewdetails(rowData);
                        },
                      },
                    ]}
                  />
                </Col>
              </div>
            </div>
          </section>
        </div>
      </div>

    </div>

  )
}
