import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Card, Alert, Container } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import Auth from '../../ServerCalls/Auth';
import Swal from "sweetalert2";
import Header from '../../common/header/Header';
import ProductURL from "../../ServerCalls/ProductServer";
import { FcInfo } from "react-icons/fc";
import { FaVoteYea } from "react-icons/fa";
import { MdHowToVote } from "react-icons/md";
import Loader from "../../common/Loader";
import NotFound from "../../common/NotFound";
import { Col, Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import axios from "../../ServerCalls/AxiosInstance";
// import {CelebrityParticipationwithcitizenID,AddCelebrityVoting,getCelebritySummary,getTopCelebrity} from '../../Admin/CelebrityPages/CelebrityServiceAPI'
import {
  TableCell,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Chart from 'react-apexcharts';
import { Rating } from 'react-simple-star-rating';
// import '../voting.css';


const PhotoPath = "https://media.topbrandsranking.com/CelebrityImages/";

export default function ShareCelebrityCampaign() {
  const { id, date, campaignname, question,categoryid } = useParams();

  const auth = Auth.getUserId();
  const navigate = useNavigate();

  const [listByNames, setlistByNames] = useState([]);
  const [alreadyvote, setAlreadyVote] = useState([]);
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [Celebrity, setCelebrity] = useState([]);
  const [TotalVotes, setTotalVotes] = useState([]);
  const [PiechartCelebrity,setPiechartCelebrity] = useState([]);
  const [PiechartCelebrityCounts,setPieChartCelebrityCounts] = useState([]);
  const [TopCelebrity,setTopCelebrity] = useState([]);
  const [Length,setLength] = useState([]);
  const [Campaigndata,setCampaigndata] = useState([]);

 

  const handleClose = () => {
    setShowReport(false)
  };


  const useStyles = makeStyles({
    table: {
      "& .MuiTableCell-root": {
        border: "1px solid rgba(0, 0, 0, 0.3)",
      },
    },
  });
  const classes = useStyles();




  useEffect(() => {
    ParticipatedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ParticipatedProducts = () => {
    setLoading(true);
    let campaignid = atob(id)
    let citizenid = Number(auth);
    //  CelebrityParticipationwithcitizenID(citizenid,campaignid)
      axios.get(`https://api.topbrandsranking.com/ProductManagement/ShareCelebrityCampaignGetById/${campaignid}`)
      .then((res) => {
        console.log(res,"got cele data")
        if (res.data._response.status === 1) {
          let data = res.data._response.data.productId;
          let campaigndata = res.data._response.data.getcampaign[0]
          setlistByNames(data);
          setCampaigndata(campaigndata)
          setLoading(false);
          let alreadyvotes = [];
          data &&
            data.map((f) => {
              if (f.isVoted === 0) {
                alreadyvotes.push(f);
              }
            });
          setAlreadyVote(alreadyvotes);
        }
      })
      .catch((err) => {
        console.log("err in get", err);
        setLoading(false);
      });
  };


  const OpenReport = () => {

    setShowReport(true)

  }


  

  return (
    <>

{
        !auth ?
          <Header />
          :
          null

      }

      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <br />
            <br />
            <div className="row mb-2">
              <div className="col-sm-12">
                <h1 className="m-0 text-center mb-3">{Campaigndata.campaignshortname}</h1>
              </div>
              <br />
              <br />
              <Row>
                <Col sm={12}>
                  <div className="d-flex justify-content-center mt-3">
                    {/* <Button variant="contained"  color="success" onClick={OpenReport}>Report</Button> */}
                  </div>

                </Col>
              </Row>
            </div>
          </div>
        </div>


        <section className="section-2">
          <div className="container">
            <div className="row">
              {/* {new Date(date) >= new Date() ? null : (
                <Alert
                  variant="warning"
                  show={show}
                  onClose={() => setShow(false)}
                  dismissible
                >
                  <Alert.Heading>Campaign Closed!!!</Alert.Heading>
                  <strong>You Cannot Vote this Campaign.</strong>
                </Alert>
              )} */}
              {/* {alreadyvote.length > 0 ? (
                <Alert
                  variant="warning"
                  show={show}
                  onClose={() => setShow(false)}
                  dismissible
                >
                  <Alert.Heading>
                  You have already voted for this campaign!!
                  </Alert.Heading>
                  <strong>You Cannot Vote this Campaign again.</strong>
                </Alert>
              ) : null} */}

              <Alert variant="primary">
                {/* <Alert.Heading>QUESTION: </Alert.Heading> */}
                <h4
                  style={{
                    fontWeight: "600",
                    fontSize: "1.8rem",
                    textTransform: "uppercase",
                  }}
                >
                  {Campaigndata.pollingquestion}?
                </h4>
              </Alert>
              <card-group  style={{ marginTop: "36px" }}>
                <Col md={12} className="mb-2">
                  <Row sm="12">
                    {loading ? (
                      <Loader />
                    ) : listByNames.length !== 0 ? (
                      listByNames.map((list, id) => {
                        return (
                          <Col md={3}>
                            <Card
                              className="gallerycard"
                              style={{
                                height: "350px",
                                textAlign: 'center',
                                marginTop: "15px",
                              }}
                            >
                              <Card.Img
                                  className="zoom"
                                  width="100%"
                                  height="200px"
                                  variant="top"
                                  src={PhotoPath + list.celebrityimage1}
                                />
                              <Card.Body>
                                <Card.Title className="category" title={list.brandname}>{list.brandname}</Card.Title>
                               
                                <Card.Text className="cardcateg-description" title= {list.celebrityName}>
                                  {list.celebrityName}
                                </Card.Text>
                                <Link to="/subregister" state={{ from: Campaigndata.campaignid, date: Campaigndata.campaignenddate, name: Campaigndata.campaignshortname, question: Campaigndata.pollingquestion,page: "authorisedCelebritycampaigndetails", categoryid: Campaigndata.celebritycategoryid }}>
                                  <button
                                    className={
                                      new Date(date) <= new Date()
                                        ? " cursor-not-allowed"
                                        : "myclass"
                                    }
                                    disabled={new Date(date) <= new Date()}
                                    title="vote now"
                                    style={{
                                      border: "none",
                                      marginTop: "10px",
                                      marginRight: "20px",
                                    }}
                                    // onClick={() => handleVote(list)}
                                    type="submit"
                                  >
                                    <MdHowToVote
                                      fontSize="35px"
                                      color="orangered"
                                    />
                                  </button>
                              
                                </Link>

                                <Link
                                  to={`/ShareCelebrityDetails/${list.celebrityid}`}
                                  title="more info"
                                >
                                  <FcInfo fontSize="35px" />
                                </Link>
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      })
                    ) : (
                      <NotFound />
                    )}
                  </Row>
                </Col>
              </card-group>
            </div>
          </div>
        </section>
        <div className="button_style m-0 text-center">
          <Button
          className="m-0 text-center"
            type="button"
            variant="contained"
            color="error"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
        <br />
        <br />



        <Modal show={showReport} className="mt-3  product-modal" onHide={handleClose} size="lg" backdrop="static" centered>
          <Modal.Header className="headerBlue" closeButton >
            <Modal.Title className=''>
              <p className="text-center mt-2 mx-auto"> Report Summary </p>
            </Modal.Title>


          </Modal.Header>
          <h4 className="mx-auto">{campaignname}</h4>
          <Modal.Body>
          <Container>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report">
                                <TableContainer >
                                    <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                        <TableHead >

                                            <TableRow className="backgroundrow">
                                                <TableCell width="10%" className="head1">Celebrity Name</TableCell>
                                             
                                                <TableCell align="center" width="10%" rowSpan={2} className="head1">Total Vote</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Celebrity.map((list,id)=>( 
                                            <TableRow>
                                             
                                                <TableCell className="headerbgcolor" >{list.celebrityname}</TableCell> 
                                 

                                                <TableCell className="headerbgcolor" align="center">{list.count}</TableCell>
                                           
                                            </TableRow>
                                            ))} 
                                      
                                        </TableBody>
                                    </Table>


                                    <Chart

                                        type="pie"
                                        width={700}
                                        height={250}
                                        series={PiechartCelebrityCounts}

                                        options={{
                                            title: { text: "Voting Report" },
                                            noData: { text: "Empty Data" },
                                            labels: PiechartCelebrity,
                                            responsive: [{
                                              breakpoint: 1024,
                                              options: {
                                                chart: {
                                                  width: 700
                                                },
                                                legend: {
                                                  position: 'right'
                                                }

                                              },
                                              breakpoint: 480,
                                              options: {
                                                chart: {
                                                  width: 250
                                                },
                                                legend: {
                                                  position: 'bottom'
                                                },
                                                
                                              },
                                              breakpoint: 768,
                                              options: {
                                                chart: {
                                                  width: 400
                                                },
                                                legend: {
                                                  position: 'bottom'
                                                },
                                                
                                              }

                                            }]
                                        }}

                                    />


                                </TableContainer>
                            </Paper>
                        </Container>
          

          </Modal.Body>
        </Modal>

      </div>
    </>
  );
}
