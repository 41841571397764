import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  RESET_ERROR,
  REFERESH_TOKEN,
  AUTHENTICATOR_SUCCESS
} from '../action/authAction'

const initialState = {
  isLoggedIn: false,
  user_details: {},
  error: {},
  role:""
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        isLoggedIn: false,
        error: {}
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        user_details: action.payload,
        isLoggedIn: true,
        role:action.role,
        isAuthenticatorVerified:false,
        isAuthenticatorEnable:action.isAuthenticatorEnable
      }
      case REFERESH_TOKEN:
        return {
          ...state,
          user_details: action.payload,
          isLoggedIn: true,
          role:action.role
        }

      case AUTHENTICATOR_SUCCESS:
        return {
          ...state,
          isAuthenticatorVerified:true,
          isAuthenticatorEnable:true
        }
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        error: action.payload
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        user_details: {},
        isLoggedIn: false,
        role:"",
        isAuthenticatorVerified:false,
        isAuthenticatorEnable:false
      }
    case RESET_ERROR:
      return {
        ...state,
        error:{}
      }
    default:
      return state
  }
}
