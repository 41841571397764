import React ,{ useState, useEffect } from "react";
import "../../../components/pages/Login/UserDetails.css";
import { Container, Card, Row, Col, Form } from "react-bootstrap";
import Auth from "../../ServerCalls/Auth";
import moment from 'moment-timezone';
import { Link } from "react-router-dom";
import { getmemeberdetailsforGoogleLogin } from '../Admin/ProductServiceAPI';
import ProductURL from '../../ServerCalls/ProductServer';
import Button from '@mui/material/Button';
export default function UserDetailsloginWithGoogle() {
    const [userdetails, setuserdetails] = useState([]);
    const auth = Auth.getUserId();

    useEffect(() => {
        getUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
      const getUserDetails = () => {
        getmemeberdetailsforGoogleLogin(auth).then((res) => {
          const user = res.data._response.data.member[0];
          setuserdetails(user);
        })
          .catch((err) => {
    
            console.log(err);
          });
      };

  return (
    <div className='user-info-body'>
    <div className='container-fluid'>
      
      {/*new design */}

      <Container>
       <Card>
          <Row>
            <Col sm={4} className='border-end mb-4'>
              <Card className="p-3" style={{ backgroundColor: '#3f51b5', color: 'white' }}>
                <Card.Body>
                  <div className="d-flex user-pic justify-content-center">
                    {/* <img className='rounded-circle m-4' src={PhotoPath + userdetails.photofilename}
                      width='150px'
                      height='150px'
                      alt='profile'
                    /> */}
                  </div>
                  <div className='m-0 text-center'>
                    <h6 style={{ fontSize: '2rem' }}>{userdetails.firstname}</h6>
                    <h6>{userdetails.email}</h6>
                    <br />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={8}>
              <div className='card user-if-card m-0' style={{ backgroundColor: '#3f51b5', color: 'white' }}>
                <div className='card-body'>
                  <h3 className="text-center m-0">User Details</h3>
                </div>
              </div>

          
              <Row className="mb-3 mt-3 p-3">
              <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Name</Form.Label>
                  <h6 className='text-muted'>{userdetails.firstname}</h6>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Email</Form.Label>
                  <h6 className='text-muted'>{userdetails.email}</h6>
                </Form.Group>
              </Row>
              {/* <hr /> */}
              {/* <Row className="mb-3 mt-3 p-3">
              

                <Form.Group as={Col} controlId="formGridZip">
                  <Form.Label>County</Form.Label>
                  <h6 className='text-muted'>{userdetails.countyname}</h6>

                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Constituency</Form.Label>
                  <h6 className='text-muted'>{userdetails.constituencyname}</h6>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>Ward</Form.Label>
                  <h6 className='text-muted'>{userdetails.wardname}</h6>

                </Form.Group>
              </Row> */}
              {/* <hr /> */}
         
{/*               
              <Row className="mb-3 mt-3 p-3">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Occupation</Form.Label>
                  <h6 className='text-muted'>{userdetails.occupation}</h6>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>Education level</Form.Label>
                  <h6 className='text-muted'>{userdetails.educationlevel}</h6>
                </Form.Group>
              </Row> */}
              <hr/>
              <Row className="mb-3 mt-3 p-3">
              <Form.Group as={Col} controlId="formGridCity">
                  <Link to={`/UserProfile/${auth}`}><Button variant="contained">Edit Profile</Button></Link>
                </Form.Group>
              </Row>
            </Col>
          </Row>
        </Card> 
      </Container>
    </div>
  </div>
  )
}
