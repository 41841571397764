import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import { Home } from '../pages/Home';
import { GeneralSetup } from '../pages/GeneralSetup';
import { AdminSetupPage } from '../pages/AdminSetupPage';
import { DepartmentSetupPage } from '../pages/DepartmentSetupPage';
import { SubDepartmentSetupPage } from '../pages/SubDepartmentSetupPage';
import { DeviceSetupPage } from '../pages/DeviceSetupPage';
import { useStyles } from '../static/MiniDrawerStyles';
import clsx from 'clsx';
import SimpleAppBar from './SimpleAppBar';


export const Routing = (props) => {
    const { content, toolbar, contentShift } = useStyles();
    const { openSideBar } = props;

    return (
        <main className={clsx(content, {
            [contentShift]: openSideBar,
        })}>
            <SimpleAppBar />
            {/* <div className={toolbar} /> */}
            <Routes>
                <Route exact path='/'  element={<Home />} />

                <Route path='/generalSetup' exact element={<GeneralSetup/>} />
                <Route path='/generalSetup/adminSetup' element={<AdminSetupPage/>} />
                <Route path='/generalSetup/department' element={<DepartmentSetupPage/>} />
                <Route path='/generalSetup/sub-department' element={<SubDepartmentSetupPage/>} />
                <Route path='/device' element={<DeviceSetupPage/>} />
            </Routes>
        </main>
        );
}