import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControlR from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MaterialTable from "material-table";
import Button from '@mui/material/Button';
import DatePicker from 'react-datepicker';
import Swal from "sweetalert2";
import ProductURL from '../../../ServerCalls/ProductServer';
import Select from 'react-select';
import { getlCelebrityList, addCelebrity, CelebrityPhotos, editCelebritydata, deleteCelebrity, awardList, platformList, getsingleCelebrity, getreleaseCategorylist, deleteawardrel, deletemediarel, deletesocialrel, getCelebrityWithNoparent } from './CelebrityServiceAPI';
import { Modal } from "react-bootstrap";
import { Tooltip, Typography, IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Edit } from '@mui/icons-material';
const PhotoPath = ProductURL.photoURL + "CelebrityImages/";

const CelebrityRegister = () => {

    const [Realname, setRealname] = useState('');
    const [socialName, setSocialName] = useState('');
    const [familyBackground, setFamilyBackground] = useState('');
    const [actingCareer, setactingCareer] = useState('');
    const [socialBackground, setSocialBackground] = useState('');
    const [maritalStatus, setMaritalStatus] = useState([]);
    const [profile, setProfile] = useState('');
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [AllData, setAllData] = useState([]);
    const [CategoryOptions, setCategoryOptions] = useState([]);
    const [formError, setFormError] = useState({});
    const [BirthDate, setBirthDate] = useState(new Date());
    const [view, setView] = useState(false);
    const [viewsocialProfile, setViewsocialProfile] = useState(false);
    const [ReleaseCategoryOptions, setReleaseCategoryOptions] = useState([]);
    const [gender, setgender] = useState([]);
    const [CelebrityImage1, setCelebrityImage1] = useState("");
    const [CelebrityImage2, setCelebrityImage2] = useState("");
    const [CelebrityImage3, setCelebrityImage3] = useState("");
    const [CelebrityImage4, setCelebrityImage4] = useState("");
    const [CelebrityImage5, setCelebrityImage5] = useState("");
    const [image1Path, setimage1Path] = useState([]);
    const [image2Path, setimage2Path] = useState([]);
    const [image3Path, setimage3Path] = useState([]);
    const [image4Path, setimage4Path] = useState([]);
    const [image5Path, setimage5Path] = useState([]);
    const [selectedPlatform, setSelectedPlatform] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [socialLinks, setSocialLinks] = useState([]);
    const [socialLink, setsocialLink] = useState('');
    const [selectedRelease, setSelectedRelease] = useState([]);
    const [mediaReleases, setMediaReleases] = useState([]);
    const [mediarease, setMediarease] = useState('')
    const [viewrelease, setViewrelease] = useState(false);
    const [update, setUpdate] = useState(false);
    const [Showlist, setShowlist] = useState(false)
    const [celebrityID, setCelebrityID] = useState(0);
    const [radioInput, setRadioInput] = useState(0);
    const [awardData, setAwardData] = useState([]);
    const [selectedAward, setSelectedAward] = useState([]);
    const [awardTitles, setAwardTitles] = useState([]);
    const [awardtitle, setawardtitle] = useState('');
    const [awardindex, setawardindex] = useState(0);
    const [awardupdate, setawardupdate] = useState(false);
    const [platformupdate, setPlatformupdate] = useState(false);
    const [platformindex, setPlatformindex] = useState(0);
    const [releaseupdate, setReleaseupdate] = useState(false);
    const [releaseindex, setReleaseindex] = useState(0);
    const [followerCount, setFollowerCount] = useState('')

    const inputRefrence1 = useRef(null);
    const inputRefrence2 = useRef(null);
    const inputRefrence3 = useRef(null);
    const inputRefrence4 = useRef(null);
    const inputRefrence5 = useRef(null);
    const jumptoBack = useLocation();

    const genderoption = [
        { value: '1', label: 'Male' },
        { value: '2', label: 'Female' },
        { value: '3', label: 'Other' }
    ]

    const statusOptions = [
        { value: '1', label: 'Single' },
        { value: '2', label: 'Married' },
        { value: '3', label: 'Divorce' },
        { value: '4', label: 'Widowed' }

    ]

    const columns = [
        { title: " Real Name ", field: "realname", align: "center" },
        { title: " social Name ", field: "socialname", align: "center" },
        { title: " BirthDate ", field: "dob", align: "center", type: "date" },
        { title: " gender ", field: "gendername", align: "center" },
        { title: "Category", field: "celebritycategoryname", align: "center" },
        { title: "Marital Status", field: "maritalstatusname", align: "center" },
    ];

    const handlerealname = (e) => {
        setRealname(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setFormError({ ...formError, Realname: "" })
    }

    const handlesocialname = (e) => {
        setSocialName(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setFormError({ ...formError, socialName: "" })
    }

    const handleprofile = (e) => {
        setProfile(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setFormError({ ...formError, profile: "" })

    }

    const handlesocialBackground = (e) => {
        setSocialBackground(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setFormError({ ...formError, socialBackground: "" })
    }

    const handleGender = (selected) => {
        setgender(selected)
        setFormError({ ...formError, gender: "" })
    }


    const handleRadio = (e) => {
        setRadioInput(Number(e.target.value));
    }


    const handleCategory = (selected) => {
        setSelectedCategory(selected)
        setFormError({ ...formError, Category: "" })
    }

    const handleDetails = (e) => {
        setFamilyBackground(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setFormError({ ...formError, familyBackground: "" })
    }

    const handleCareer = (e) => {
        setactingCareer(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setFormError({ ...formError, actingCareer: "" })
    }

    const handleStatus = (selected) => {
        setMaritalStatus(selected);
        setFormError({ ...formError, maritalStatus: "" })
    }

    const handleplatform = (selected) => {
        setSelectedPlatform(selected)

    }

    const handleawardChange = (selected) => {
        setSelectedAward(selected)

    }
    const handleMediaReleaseCategory = (selected) => {

        setSelectedRelease(selected)
    }

    const handleimage1 = (e) => {
        setCelebrityImage1(e.target.files[0]);
        setimage1Path(URL.createObjectURL(e.target.files[0]));
        setFormError({ ...formError, CelebrityImage1: "" });
    };

    const handleimage2 = (e) => {
        setCelebrityImage2(e.target.files[0]);
        setimage2Path(URL.createObjectURL(e.target.files[0]));
        setFormError({ ...formError, CelebrityImage2: "" });
    };

    const handleimage3 = (e) => {
        setCelebrityImage3(e.target.files[0]);
        setimage3Path(URL.createObjectURL(e.target.files[0]));
        setFormError({ ...formError, CelebrityImage3: "" });
    };

    const handleimage4 = (e) => {
        setCelebrityImage4(e.target.files[0]);
        setimage4Path(URL.createObjectURL(e.target.files[0]));
        setFormError({ ...formError, CelebrityImage4: "" });
    };

    const handleimage5 = (e) => {
        setCelebrityImage5(e.target.files[0]);
        setimage5Path(URL.createObjectURL(e.target.files[0]));
        setFormError({ ...formError, CelebrityImage5: "" });
    };

    useEffect(() => {
        setShowlist(false);
        getlist();
        allCategories();
        awards();
        socialPlatformList();
        optionList();
        // let result = [...awardTitles]
        // result.push(
        //     {
        //         awardsid: 0,
        //         awardtitle: ''
        //     }
        // )
        // setAwardTitles(result)
    }, [jumptoBack.key]);

    const handleaddtitle = () => {

        if (awardtitle !== '') {
            let result = [...awardTitles]
            let alreadyPresent = result.find(f =>
                f.awardsid === selectedAward.value && f.awardtitle === awardtitle)
            if (alreadyPresent === undefined) {
                result.push(
                    {
                        awardsid: selectedAward.value,
                        awardname: selectedAward.label,
                        awardtitle: awardtitle,
                        celebrityawardsid: 0,
                    }
                )
            }
            else {
                Swal.fire('Already Exist!', '', 'warning');
            }

            setAwardTitles(result);
            setawardtitle('');
        }
    }

    const handleupdatetitle = () => {
        if (awardtitle !== '') {
            let result = [...awardTitles]
            result[awardindex]['awardtitle'] = awardtitle;
            result[awardindex]['awardname'] = selectedAward.label;
            result[awardindex]['awardsid'] = selectedAward.value;
            setAwardTitles(result);
            setawardtitle('');
            setawardindex(0);
            setawardupdate(false);
        }
    }

    const handleEditchange = (data, idx) => {
        setSelectedAward({
            value: data.awardsid,
            label: data.awardname
        })
        setawardindex(idx);
        setawardtitle(data.awardtitle);
        setawardupdate(true);
      

    };

    const handleDeleteByAwardid = (ind, celebrityawardsid) => {
        if (celebrityawardsid !== 0) {
            deleteawardrel(celebrityawardsid)
                .then((res) => {
                    if (res.data._response.status === 1) {
                        let newArr = [...awardTitles];
                        newArr.splice(ind, 1);
                        setAwardTitles(newArr);
                        Swal.fire('Record Delete Successfully', '', 'success');
                    }
                })
                .catch((err) => {
                    console.log(err, 'err in delete');
                });
        } else {
            let newArr = [...awardTitles];
            newArr.splice(ind, 1);
            setAwardTitles(newArr);
            Swal.fire('Record Delete Successfully', '', 'success');
        }
    };

    const handleaddplatform = () => {
        if (socialLink !== '') {
            let result = [...socialLinks]

            let alreadyPresent = result.find(a =>
                a.socialmediaplatformid === selectedPlatform.value && a.profilelink === socialLink)
            if (alreadyPresent === undefined) {
                result.push(
                    {
                        socialmediaplatformid: selectedPlatform.value,
                        socialMediaPlatformName: selectedPlatform.label,
                        profilelink: socialLink,
                        celebrityfollowers: followerCount,
                        celebritysocialprofileid: 0,

                    }
                )
            }
            else {
                Swal.fire('Already Exist!', '', 'warning');

            }
            setSocialLinks(result);
            setsocialLink('');
            setFollowerCount('');
        }
    }

    const handleEditPlatform = (data, idx) => {
        setSelectedPlatform({
            value: data.socialmediaplatformid,
            label: data.socialmediaplatformname
        })
        setPlatformindex(idx);
        setsocialLink(data.profilelink);
        setFollowerCount(data.celebrityfollowers);
        setPlatformupdate(true);
       
    };

    const handleupdateLink = () => {
        if (socialLink !== '') {
            let result = [...socialLinks]
            let name = 'profilelink'
            result[platformindex][name] = socialLink;
            result[platformindex]['celebrityfollowers'] = followerCount;
            result[platformindex]['socialMediaPlatformName'] = selectedPlatform.label;
            result[platformindex]['socialmediaplatformid'] = selectedPlatform.value;

            setSocialLinks(result);
            setsocialLink('');
            setPlatformindex(0);
            setFollowerCount('')
            setPlatformupdate(false);
        }
    }

    const handleDeleteByplatformID = (ind, celebritysocialprofileid) => {

        if (celebritysocialprofileid !== 0) {
            deletesocialrel(celebritysocialprofileid)
                .then((res) => {
                    if (res.data._response.status === 1) {
                        let newArr = [...socialLinks];
                        newArr.splice(ind, 1);
                        setSocialLinks(newArr);
                        Swal.fire('Record Delete Successfully', '', 'success');
                    }
                })
                .catch((err) => {
                    console.log(err, 'err in delete');
                });
        } else {
            let newArr = [...socialLinks];
            newArr.splice(ind, 1);
            setSocialLinks(newArr);
            Swal.fire('Record Delete Successfully', '', 'success');
        }
    };

    const handleaddmedia = () => {
        if (mediarease !== '') {
            let result = [...mediaReleases]
            let alreadyPresent = result.find(b =>
                b.mediareleasecategoryid === selectedRelease.value && b.mediareleasename === mediarease)
            if (alreadyPresent === undefined) {
                result.push(
                    {
                        mediareleasecategoryid: selectedRelease.value,
                        mediareleasecategoryname: selectedRelease.label,
                        mediareleasename: mediarease,
                        successfulyn: radioInput,
                        mediareleaseid: 0,
                    }
                )
            } else {
                Swal.fire('Already Exist!', '', 'warning');
            }
            setMediaReleases(result);
            setMediarease('');
            setRadioInput(0)
        }
    }

    const handleupdatemedia = () => {
        if (mediarease !== '') {
            let result = [...mediaReleases]

            let name = 'mediareleasename'
            result[releaseindex][name] = mediarease;
            let sname = 'successfulyn'
            result[releaseindex][sname] = radioInput;
            result[releaseindex]['mediareleasecategoryname'] = selectedRelease.label;
            result[releaseindex]['mediareleasecategoryid'] = selectedRelease.value;
            setMediaReleases(result);
            setMediarease('');
            setReleaseindex(0);
            setReleaseupdate(false);
            setRadioInput(0)
        }
    }

    const handleEditmedia = (data, idx) => {
        setSelectedRelease({
            value: data.mediareleasecategoryid,
            label: data.mediareleasecategoryname
        })
        setReleaseindex(idx);
        setMediarease(data.mediareleasename);
        setReleaseupdate(true);
        setRadioInput(data.successfulyn)
      
    };

    const handleDeleteBymediaID = (ind, mediareleaseid) => {

        if (mediareleaseid !== 0) {
            deletemediarel(mediareleaseid)
                .then((res) => {
                    if (res.data._response.status === 1) {
                        let newArr = [...mediaReleases];
                        newArr.splice(ind, 1);
                        setMediaReleases(newArr);
                        Swal.fire('Record Delete Successfully', '', 'success');
                    }
                })
                .catch((err) => {
                    console.log(err, 'err in delete');
                });
        } else {
            let newArr = [...mediaReleases];
            newArr.splice(ind, 1);
            setMediaReleases(newArr);
            Swal.fire('Record Delete Successfully', '', 'success');
        }
    };

    const getlist = () => {
        getlCelebrityList().then((res) => {
            if (res.data._response.status === 1) {
                setAllData(res.data._response.data.getgetcelebrity)

            }
        })
            .catch((err) => {
                console.log(err, "error in get celebrity");
            })

    }

    const handleClose = (name) => {
        // if(name === "Award"){
        setView(false);
        // }else if(name === "Social"){
        setViewsocialProfile(false);
        // }else if(name === "Media"){
        setViewrelease(false);
        // }
    };

    const awards = () => {
        awardList()
            .then((res) => {

                if (res.data._response.status === 1) {
                    let list = res.data._response.data.getawards
                    const options = list.map((a) => ({
                        value: a.awardsId,
                        label: a.awardName,
                    }));

                    setAwardData(options);

                }
            })
            .catch((err) => {
                console.log(err, "err in get awardlist");
            })
    }

    const socialPlatformList = () => {

        platformList().then((res) => {

            let list = res.data._response.data.getsocialmediaplatforms
            if (res.data._response.status === 1) {
                const options = list.map((a) => ({
                    value: a.socialMediaPlatformId,
                    label: a.socialMediaPlatformName,
                }));

                setPlatforms(options)
            }
        }).catch((err) => {
            console.log(err, "error");
        })
    }

    const optionList = () => {

        getreleaseCategorylist().then((res) => {
            if (res.data._response.status === 1) {

                let list = res.data._response.data.getmediacategory
                const options = list.map((a) => ({
                    value: a.mediacategoryid,
                    label: a.mediacategoryname,
                }));
                setReleaseCategoryOptions(options)
            }

        }).catch((err) => {
            console.log(err, "err in get release category");
        })
    }

    const awardOperations = () => {
        setView(true);
    }

    const socialProfiles = () => {
        setViewsocialProfile(true);
    }

    const mediaRelease = () => {
        setViewrelease(true)
    }

    const allCategories = () => {
        getCelebrityWithNoparent().then((res) => {
            let list = res.data._response.data.getcelcategorywithoutnoparent;
            if (res.data._response.status === 1) {
                const options = list.map((a) => ({
                    value: a.celebcategoryid,
                    label: a.celebcategoryName,
                }));
                setCategoryOptions(options)
            }
        })
            .catch((err) => {
                console.log(err, "err in get celebritycategorylist");
            })
    }

    const validate = () => {
        let regex = /^[A-Za-z0-9 ]+$/;

        let formError = {}
        let isValid = true;

        if (Realname === '') {
            isValid = false;
            formError["Realname"] = "please Enter Real Name"
        }
        else if (regex.test(Realname) === false) {
            formError = "Please enter alphabetic characters";

        }else if(Realname.length > 50){
            isValid = false;
            formError["Realname"]= "Maximum 50 characters are allowed";
        }

        if (socialName === '') {
            isValid = false;
            formError["socialName"] = "please Enter Your Social Name"

        } else if (regex.test(socialName) === false) {
            formError = "Please enter alphabetic characters";
        }else if(socialName.length > 50){
            isValid = false;
            formError["socialName"]= "Maximum 50 characters are allowed";
        }

        if (familyBackground === '') {
            isValid = false;
            formError["familyBackground"] = "please Enter Family Background Details"
        }
       

        if (actingCareer === '') {
            isValid = false;
            formError["actingCareer"] = "please Enter Celebrity Career Details"
        }
     

        if (socialBackground === '') {
            isValid = false;
            formError["socialBackground"] = "please Enter Social Background Details"
        }
       

        if (maritalStatus.length < 1) {
            isValid = false;
            formError["maritalStatus"] = "please Enter Marital Status"
        }

        if (profile === '') {
            isValid = false;
            formError["profile"] = "please Enter Profile Details"
        }
   



        if (gender.length < 1) {
            isValid = false;
            formError["gender"] = "Please select gender"
        }

        if (selectedCategory.length < 1) {
            isValid = false;
            formError["Category"] = "Please select Category"
        }

        if (CelebrityImage1.length < 1) {
            isValid = false;
            formError["CelebrityImage1"] = "Please select Image"

        }
        if (CelebrityImage2.length < 1) {
            isValid = false;
            formError["CelebrityImage2"] = "Please select Image"

        }
        if (CelebrityImage3.length < 1) {
            isValid = false;
            formError["CelebrityImage3"] = "Please select Image"

        }
        if (CelebrityImage4.length < 1) {
            isValid = false;
            formError["CelebrityImage4"] = "Please select Image"

        }
        if (CelebrityImage5.length < 1) {
            isValid = false;
            formError["CelebrityImage5"] = "Please select Image"

        }



        setFormError(formError);

        return isValid;

    }

    const saveData = async () => {

        const isValid = validate();
        if (isValid) {
            let awardsdata = awardTitles.map((S) => {
                return {
                    awardsid: Number(S.awardsid),
                    awardtitle: S.awardtitle,
                    celebrityawardsid: S.celebrityawardsid
                }
            })
            const data = {
                realname: Realname,
                socialname: socialName,
                dob: new Date(BirthDate),
                celebritycategoryid: selectedCategory.value,
                gender: gender.value,
                maritalstatus: maritalStatus.value,
                familybackground: familyBackground,
                actingcareer: actingCareer,
                socialbackground: socialBackground,
                profile: profile,
                celebrityimage1: CelebrityImage1.name,
                celebrityimage2: CelebrityImage2.name,
                celebrityimage3: CelebrityImage3.name,
                celebrityimage4: CelebrityImage4.name,
                celebrityimage5: CelebrityImage5.name,
                celebrityawardsrel: awardsdata,
                mediarelease: mediaReleases,
                celebritysocialprofiles: socialLinks,


            }
            let formdata = new FormData();
            formdata.append("file", CelebrityImage1);
            formdata.append("file", CelebrityImage2);
            formdata.append("file", CelebrityImage3);
            formdata.append("file", CelebrityImage4);
            formdata.append("file", CelebrityImage5);
            formdata.getAll("file")
            await image_upload(formdata);

            addCelebrity(data).then((res) => {

                if (res.data._response.status === 1) {
                    Swal.fire({
                        icon: "success",
                        title: "Celebrity Added Successfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });
                    getlist();
                    clearData();

                } else {
                    Swal.fire({
                        icon: "warning",
                        title: res.data._response.message,
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });

                }
            }).catch((err) => {

                console.log(err, "err in add celebrity");

            })
        }
    }

    const image_upload = async (data) => {

        await CelebrityPhotos(data)
            .then((res) => {

                console.log("photo res", res)
            }).catch((err) => {
                console.log(err, "err in photo submit")
            })
    }

    const getData = (id) => {
        setUpdate(true);
        setShowlist(false);
        setCelebrityID(id.celebrityid);
        getsingleCelebrity(id.celebrityid).then((res) => {

            let singleData = res.data._response.data.celebritygetbyid[0];
            let singleAward = res.data._response.data.celebrityawardsrel;
            let singlemediaRelease = res.data._response.data.mediarelease;
            let singlesocial = res.data._response.data.celebritysocialrel;


            setRealname(singleData.realname);
            setSocialName(singleData.socialname);
            setFamilyBackground(singleData.familybackground);
            setactingCareer(singleData.actingcareeer);
            setSocialBackground(singleData.socialbackground);
            setCelebrityImage1(singleData.celebrityimage1);
            setCelebrityImage2(singleData.celebrityimage2);
            setCelebrityImage3(singleData.celebrityimage3);
            setCelebrityImage4(singleData.celebrityimage4);
            setCelebrityImage5(singleData.celebrityimage5)
            setProfile(singleData.profile);
            setMaritalStatus({ value: singleData.maritalstatus, label: singleData.maritalstatusname })
            setgender({ value: singleData.gender, label: singleData.gendername })
            setBirthDate(new Date(singleData.dob));
            setSelectedCategory({ value: singleData.celebritycategoryid, label: singleData.celebritycategoryname })
            setAwardTitles(singleAward);
            setMediaReleases(singlemediaRelease);
            setSocialLinks(singlesocial);
            // setFacebookCount(singleData.facebookfollwers);
            // setTwitterCount(singleData.twitterfollwers);
            // setYoutubeCount(singleData.youtubesubscribers);
            // setInstagramCount(singleData.instagramfollwers)
        }).catch((err) => {
            console.log(err, 'err in get celebrity register');
        })
    }

    const editData = async () => {
        const isValid = validate();

        if (isValid) {

            let awardsdata = awardTitles.map((S) => {
                return {
                    awardsid: Number(S.awardsid),
                    awardtitle: S.awardtitle,
                    celebrityawardsid: S.celebrityawardsid
                }
            })

            const data = {
                celebrityId: celebrityID,
                realname: Realname,
                socialname: socialName,
                dob: new Date(BirthDate),
                celebritycategoryid: selectedCategory.value,
                gender: gender.value,
                maritalstatus: maritalStatus.value,
                familybackground: familyBackground,
                actingcareer: actingCareer,
                socialbackground: socialBackground,
                profile: profile,
                // instagramfollwers: instagramCount,
                // facebookfollowers: facebookCount,
                // twitterfollowers: twitterCount,
                // youtubesubscribers: youtubeCount,
                celebrityimage1: CelebrityImage1.name === undefined ? CelebrityImage1 : CelebrityImage1.name,
                celebrityimage2: CelebrityImage2.name === undefined ? CelebrityImage2 : CelebrityImage2.name,
                celebrityimage3: CelebrityImage3.name === undefined ? CelebrityImage3 : CelebrityImage3.name,
                celebrityimage4: CelebrityImage4.name === undefined ? CelebrityImage4 : CelebrityImage4.name,
                celebrityimage5: CelebrityImage5.name === undefined ? CelebrityImage5 : CelebrityImage5.name,
                celebrityawardsrel: awardsdata,
                mediarelease: mediaReleases,
                celebritysocialprofiles: socialLinks
            }


            let formdata = new FormData();
            if (CelebrityImage1.name !== undefined) { formdata.append("file", CelebrityImage1) }
            if (CelebrityImage2.name !== undefined) { formdata.append("file", CelebrityImage2) }
            if (CelebrityImage3.name !== undefined) { formdata.append("file", CelebrityImage3) }
            if (CelebrityImage4.name !== undefined) { formdata.append("file", CelebrityImage4) }
            if (CelebrityImage5.name !== undefined) { formdata.append("file", CelebrityImage5) }

            formdata.getAll("file")
            await image_upload(formdata);


            editCelebritydata(data).then((res) => {

                if (res.data._response.status === 1) {
                    Swal.fire({
                        icon: "success",
                        title: "Celebrity Updated Sucessfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });
                    getlist();
                    clearData();
                }
            })
                .catch((err) => {
                    console.log(err, "error");
                })
        }
    }

    const TrashData = (row) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((res) => {

            if (res.isConfirmed) {
                deleteCelebrity(row.celebrityid)
                    // axios.delete(`https://localhost:5002/api/ProductManagement/RemoveCelebrity/${row.celebrityid}`)
                    .then((res) => {
                        if (res.data._response.status === 1) {

                            Swal.fire({
                                icon: "success",
                                title: "Celebrity Deleted Sucessfully",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });
                            getlist();
                        }
                        else if (res.data._response.status === 2) {

                            Swal.fire({
                                icon: "warning",
                                title: res.data._response.message,
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });
                        }
                    }).catch((err) => {
                        console.log(err, 'err in delete celebrity');
                    })
            }
        })
            .catch((err) => {
                console.log(err, "err in trash brand");
            })
    }

    const clearData = () => {

        setUpdate(false);
        setSocialBackground('');
        setRealname('');
        setSocialName('');
        setactingCareer('');
        setFamilyBackground('');
        setMaritalStatus([]);
        setSelectedCategory([])
        setProfile('');
        setFormError('');
        setBirthDate(new Date());
        setgender([]);
        inputRefrence1.current.value = null;
        inputRefrence2.current.value = null;
        inputRefrence3.current.value = null;
        inputRefrence4.current.value = null;
        inputRefrence5.current.value = null;
        setimage1Path("");
        setimage2Path("");
        setimage3Path("");
        setimage4Path("");
        setimage5Path("");
        setSelectedAward([]);
        setSelectedPlatform([])
        setSocialLinks([]);
        setAwardTitles([]);
        setSelectedRelease([])
        setMediaReleases([]);
        setCelebrityImage1("");
        setCelebrityImage2("");
        setCelebrityImage3("");
        setCelebrityImage4("");
        setCelebrityImage5("");
        setBirthDate("");

    }

    const handleClear = () => {

        setSelectedPlatform([]);
        setSelectedRelease([]);
        setSelectedAward([])
        setawardtitle('');
        setsocialLink('');
        setawardtitle('')
        setMediarease('');
        setRadioInput(0)
        setFollowerCount('');
    }

    return (

        <div className='container'>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <br /><br />
                        <div className="row">

                            <div className="col-sm-12">
                                {!update ? (
                                    <h1 className="m-0 text-center">ADD CELEBRITY</h1>
                                ) :
                                    <h1 className="m-0 text-center">EDIT CELEBRITY</h1>
                                }
                            </div>
                            <Col md={12} >
                                <div className='d-flex justify-content-end'>
                                    {!Showlist ?
                                        <button
                                            type="button"
                                            className="btn btn-pos3 "
                                            onClick={() => {
                                                setShowlist(true);
                                                clearData();
                                            }}
                                        >
                                            <i className="fa-solid fa-list"></i> List
                                        </button>
                                        :
                                        <button
                                            type="button"
                                            className="btn btn-pos4 "
                                            onClick={() => setShowlist(false)}
                                        >
                                            <i className="fa fa-arrow-circle-left"></i> back
                                        </button>
                                    }
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
                <section className="content consti-section">
                    <div className="container">
                        <div className="row">
                            <Col md={12}>
                                <Card style={{ padding: "20px" }}>
                                    {!Showlist ? (
                                        <div>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3 justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100">Real Name</Form.Label>
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        name="Realname"
                                                        placeholder="Real Name"
                                                        value={Realname}
                                                        onChange={(e) => handlerealname(e)}
                                                    />
                                                    {
                                                        formError ?
                                                            <div style={{ color: "red" }}>{formError.Realname}</div>
                                                            : null

                                                    }
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100">Social Name</Form.Label>
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        name="socialName"
                                                        placeholder="Social Name"
                                                        value={socialName}
                                                        onChange={(e) => handlesocialname(e)}
                                                    />
                                                    {
                                                        formError ?
                                                            <div style={{ color: "red" }}>{formError.socialName}</div>
                                                            : null

                                                    }
                                                </Col>
                                                <Col sm={6}>
                                                    <Form.Label className="w-100">
                                                        Date Of Birth
                                                    </Form.Label>
                                                    <DatePicker
                                                        className="w-100 form-control"
                                                        selected={BirthDate}
                                                        maxDate={new Date()}
                                                        name="BirthDate"
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        scrollableYearDropdown
                                                        yearDropdownItemNumber={50}
                                                        onChange={(date) => {
                                                            setBirthDate(date)
                                                        }}

                                                    />
                                                </Col>
                                                <Col sm={6}>
                                                    <Form.Label className="w-100">Gender</Form.Label>
                                                    <Select
                                                        className="w-100"
                                                        options={genderoption}
                                                        onChange={handleGender}
                                                        value={gender}
                                                        menuPortalTarget={document.body}
                                                        styles={{
                                                            menuPortal: (base) => ({
                                                                ...base,
                                                                zIndex: 9999,
                                                            }),
                                                        }}
                                                    />
                                                    <br/>
                                                    <div style={{ color: "red" }}>
                                                        {formError.gender}
                                                    </div>

                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">Marital Status</Form.Label>
                                                    <Select
                                                        className="w-100"
                                                        options={statusOptions}
                                                        name='maritalStatus'
                                                        value={maritalStatus}
                                                        onChange={handleStatus}
                                                        menuPortalTarget={document.body}
                                                        styles={{
                                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                        }}
                                                    />
                                                    <br/>
                                                    {
                                                        formError ?
                                                            <div style={{ color: "red" }}>{formError.maritalStatus}</div>
                                                            : null

                                                    }

                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">Celebrity Category</Form.Label>
                                                    <Select
                                                        className="w-100"
                                                        options={CategoryOptions}
                                                        name='selectedCategory'
                                                        onChange={handleCategory}
                                                        value={selectedCategory}
                                                        menuPortalTarget={document.body}
                                                        styles={{
                                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                        }}
                                                    />
                                                    <br/>

                                                    {
                                                        formError ?
                                                            <div style={{ color: "red" }}>{formError.Category}</div>
                                                            : null

                                                    }

                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100">
                                                        Family Background
                                                    </Form.Label>
                                                    <textarea
                                                        className="w-100 form-control"
                                                        placeholder="Tell us more"
                                                        rows="3"
                                                        onChange={handleDetails}
                                                        name="familyBackground"
                                                        value={familyBackground}
                                                    ></textarea>
                                                    <div style={{ color: "red" }}>{formError.familyBackground}</div>
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100">
                                                        Celebrity Career
                                                    </Form.Label>
                                                    <textarea
                                                        className="w-100 form-control"
                                                        placeholder="Tell us more"
                                                        rows="3"
                                                        onChange={handleCareer}
                                                        name="actingCareer"
                                                        value={actingCareer}
                                                    ></textarea>
                                                    <div style={{ color: "red" }}>{formError.actingCareer}</div>
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100">
                                                        Social Background
                                                    </Form.Label>
                                                    <textarea
                                                        className="w-100 form-control"
                                                        placeholder="Tell us more"
                                                        rows="3"
                                                        onChange={handlesocialBackground}
                                                        name="socialBackground"
                                                        value={socialBackground}
                                                    ></textarea>
                                                    <div style={{ color: "red" }}>{formError.socialBackground}</div>
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100">
                                                        Profile
                                                    </Form.Label>
                                                    <textarea
                                                        className="w-100 form-control"
                                                        placeholder="Tell us more"
                                                        rows="3"
                                                        onChange={handleprofile}
                                                        name="profile"
                                                        value={profile}
                                                    ></textarea>
                                                    <div style={{ color: "red" }}>{formError.profile}</div>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group
                                                as={Row}
                                                className="mb-3 justify-content-center"
                                            // controlId="formHorizontalEmail"
                                            >
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100"> Image 1</Form.Label>
                                                    <FormControl className="m-2" type="file" name='file'
                                                        controlId={CelebrityImage1} ref={inputRefrence1} onChange={handleimage1}
                                                    />
                                                    <br/>
                                                    <div style={{ color: "red" }}>{formError.CelebrityImage1}</div>
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100"> Image 2</Form.Label>
                                                    <FormControl className="m-2" type="file" name='file'
                                                        controlId={CelebrityImage2} ref={inputRefrence2} onChange={handleimage2}
                                                    />
                                                    <br/>
                                                    <div style={{ color: "red" }}>{formError.CelebrityImage2}</div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="imagepreview">
                                                        <img alt=" " width="80" height="60"
                                                            src={update === false ? image1Path : CelebrityImage1.name === undefined ? PhotoPath + CelebrityImage1 : image1Path}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="imagepreview">
                                                        <img alt=" " width="80" height="60"
                                                            src={update === false ? image2Path : CelebrityImage2.name === undefined ? PhotoPath + CelebrityImage2 : image2Path}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100"> Image 3</Form.Label>
                                                    <FormControl className="m-2" type="file" name='file'
                                                        controlId={CelebrityImage3} ref={inputRefrence3} onChange={handleimage3}
                                                    />
                                                    <br/>
                                                    <div style={{ color: "red" }}>{formError.CelebrityImage3}</div>
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100"> Image 4</Form.Label>
                                                    <FormControl className="m-2" type="file" name='file'
                                                        controlId={CelebrityImage4} ref={inputRefrence4} onChange={handleimage4}
                                                    />
                                                    <br/>
                                                    <div style={{ color: "red" }}>{formError.CelebrityImage4}</div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="imagepreview">
                                                        <img alt=" " width="80" height="60"
                                                            src={update === false ? image3Path : CelebrityImage3.name === undefined ? PhotoPath + CelebrityImage3 : image3Path}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="imagepreview">
                                                        <img alt=" " width="80" height="60"
                                                            src={update === false ? image4Path : CelebrityImage4.name === undefined ? PhotoPath + CelebrityImage4 : image4Path}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100"> Image 5</Form.Label>
                                                    <FormControl className="m-2" type="file" name='file'
                                                        controlId={CelebrityImage5} ref={inputRefrence5} onChange={handleimage5}
                                                    />
                                                    <br/>
                                                    <div style={{ color: "red" }}>{formError.CelebrityImage5}</div>
                                                </Col>
                                                <Col md={6} className="mb-4">
                                                </Col>
                                                <Col md={6}>
                                                    <div className="imagepreview">
                                                        <img alt=" " width="80" height="60"
                                                            src={update === false ? image5Path : CelebrityImage5.name === undefined ? PhotoPath + CelebrityImage5 : image5Path}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}></Col>

                                            </Form.Group>
                                            <Form.Group
                                                as={Row}
                                                className="mb-3 justify-content-center">

                                            </Form.Group>
                                            <Form.Group as={Row}
                                                className="mb-3 justify-content-center"
                                                controlId="formHorizontalEmail">
                                                <Col md={4}>
                                                    <Tooltip title="Add Awards">
                                                        <Button onClick={awardOperations} tooltip="award" variant="outlined"><i className="fa-solid fa-plus"></i>	&nbsp;	&nbsp;Award Details</Button>
                                                    </Tooltip>
                                                </Col>
                                                <br />
                                                <Col md={4}>
                                                    <Tooltip title="Add Socai Link">
                                                        <Button onClick={socialProfiles} variant="outlined"><i className="fa-solid fa-plus"></i>	&nbsp;	&nbsp;Social profile Link Details</Button>
                                                    </Tooltip>
                                                </Col>
                                                <Col md={4}>
                                                    <Tooltip title="Add Media Release">
                                                        <Button onClick={mediaRelease} variant="outlined"><i className="fa-solid fa-plus"></i>	&nbsp;	&nbsp;Media Release</Button>
                                                    </Tooltip>
                                                </Col>
                                            </Form.Group>
                                            <div className="button_style m-0 text-center">
                                                {update === false ?
                                                    <Button
                                                        type="button"
                                                        variant='contained'

                                                        onClick={() => saveData()}
                                                    >
                                                        Submit
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="button"
                                                        variant='contained'
                                                        color='success'
                                                        onClick={() => editData()}
                                                    >
                                                        Update
                                                    </Button>
                                                } {" "}

                                                <Button
                                                    type="button"
                                                    variant='contained'
                                                    color='error'
                                                    onClick={() => clearData()}

                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <MaterialTable
                                                title="Celebrity Details"
                                                data={AllData}
                                                columns={columns}
                                                actions={[
                                                    {
                                                        icon: "edit",
                                                        tooltip: "Edit",
                                                        onClick: (event, rowData) => {

                                                            getData(rowData);

                                                        },
                                                    },

                                                    {
                                                        icon: "delete",
                                                        tooltip: "Delete",
                                                        onClick: (event, rowData) => {
                                                            TrashData(rowData);
                                                        },
                                                    },
                                                ]}
                                                options={{
                                                    search: true,
                                                    pageSize: 10,
                                                    showFirstLastPageButtons: false,
                                                    actionsColumnIndex: -1,
                                                    addRowPosition: "first",
                                                    pageSizeOptions: [10, 15, 20, 50],
                                                }}
                                            />
                                        </div>
                                    )}
                                </Card>
                            </Col>
                        </div>
                    </div>
                </section>
                <br /><br />


                <Modal show={view} size="xl" backdrop="static" centered onHide={handleClose}>
                    <Modal.Header className="headerBlue" closeButton>
                        <Modal.Title className="">
                            <h5 className="text-start">Add Award</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container newscard">
                            <article className="card shadow">

                                <Row>
                                    <Col sm={8} className="mb-4 ">
                                        <Card.Body>
                                            <Form.Label className="w-100 text-start">
                                                Award
                                            </Form.Label>
                                            <Select
                                                className="w-100"
                                                options={awardData}
                                                onChange={handleawardChange}
                                                value={selectedAward}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                    }),
                                                }}
                                            />
                                        </Card.Body>
                                    </Col>
                                    <Col sm={4}>
                                        <Card.Body className="m-0 pt-4 text-center">
                                            {/* <div className="button_style m-0 text-center">
                                                <Button variant="contained" onClick={(a) => { handleClose(a) }}>
                                                    Save Changes
                                                </Button>
                                            </div> */}

                                            {/* {awardupdate === false ?
                                                <Tooltip title="Add Title">
                                                    <Button variant='contained' onClick={handleaddtitle} disabled={selectedAward.length === 0}><i className="fa-solid fa-plus"></i> Add&Save Changes</Button>
                                                </Tooltip> :
                                                <Tooltip title="Update Title">
                                                    <Button variant='contained' onClick={handleupdatetitle}><i className="fa-solid fa-plus"></i> Update&Save Changes</Button>
                                                </Tooltip>
                                            } */}
                                        </Card.Body>
                                    </Col>
                                </Row>

                                <br />
                                <Row>
                                    <Col sm={8} className="mb-4">
                                        <Card.Body className='m-0 text-center'>
                                            {/* {awardupdate === false ?
                                                <Tooltip title="Add Title">
                                                    <Button variant='contained' onClick={handleaddtitle} disabled={selectedAward.length === 0}><i className="fa-solid fa-plus"></i></Button>
                                                </Tooltip> :
                                                <Tooltip title="Update Title">
                                                    <Button variant='contained' onClick={handleupdatetitle}><i className="fa-solid fa-plus"></i></Button>
                                                </Tooltip>
                                            } */}
                                            <Form.Label className="w-100 text-start">
                                                Enter Title
                                            </Form.Label>
                                            <input
                                                className="w-100 form-control"
                                                type="text"
                                                name="awardtitle"
                                                placeholder="Enter title"
                                                value={awardtitle}
                                                onChange={(e) => {
                                                    setawardtitle(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@.,;'"" "]/ig, ''));
                                                }}
                                            />
                                        </Card.Body>
                                    </Col>
                                    <Col sm={3}>
                                        <Card.Body className='text-start'>
                                            {/* <input
                                                className="w-100 form-control"
                                                type="text"
                                                name="award-title"
                                                placeholder="enter title"
                                                value={awardtitle}
                                                onChange={(e) => setawardtitle(e.target.value)}
                                            /> */}

                                            <div className="button_style m-0 text-center">
                                                {/* <Button variant="contained" onClick={(a) => { handleClose(a) }}>
                                                    Save Changes
                                                </Button> */}
                                            </div>
                                        </Card.Body>
                                    </Col>
                                </Row>

                                <div className='m-auto'>

                                    {awardupdate === false ?
                                        <Tooltip title="Add Title">
                                            <Button variant='contained' onClick={handleaddtitle} disabled={selectedAward.length === 0}><i className="fa-solid fa-plus"></i> Add&Save Changes</Button>
                                        </Tooltip> :
                                        <Tooltip title="Update Title">
                                            <Button variant='contained' onClick={handleupdatetitle}><i className="fa-solid fa-plus"></i> Update&Save Changes</Button>
                                        </Tooltip>
                                    }
                                    &nbsp;
                                    <Tooltip title="Cancel">
                                        <Button variant='contained' onClick={handleClear}> Cancel</Button>
                                    </Tooltip>

                                </div>
                                <br /><br />

                            <div style={{ overflow: 'auto' }}>
                                <table
                                    id="mytable"
                                    className="normaltxt table-responsive m-0 text-center "
                                // style={{ overflow: "auto", width: "95vw" }}
                                >
                                    <tbody>
                                        <tr>
                                            <th>AwardList</th>
                                            <th>Award title</th>
                                            <th>Action</th>
                                        </tr>
                                        {awardTitles &&
                                            awardTitles.map(
                                                (
                                                    data,
                                                    ind
                                                ) => (
                                                    <tr key={ind}>
                                                        <td style={{ width: "10%" }}>
                                                            <>

                                                                {
                                                                    awardData &&
                                                                    awardData.map((f, i) => {
                                                                        if (f.value === Number(data.awardsid)) {
                                                                            return (
                                                                                <Typography key={i}>{f.label}</Typography>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })}
                                                            </>
                                                        </td>
                                                        <td>

                                                            <Typography>{data.awardtitle}</Typography>

                                                        </td>
                                                        <td >
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditchange(data, ind)}
                                                            >
                                                                <Edit />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() =>
                                                                    handleDeleteByAwardid(ind, data.celebrityawardsid)
                                                                }
                                                            // disabled={ind === awardTitles.length - 1}
                                                            >
                                                                <DeleteOutlineIcon />
                                                            </IconButton>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                                </div>
                            </article>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={viewsocialProfile} size="xl" backdrop="static" centered onHide={handleClose}>
                    <Modal.Header className="headerBlue" closeButton >
                        <Modal.Title className="">
                            <h5 className="text-start">Social Platforms</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container newscard content-center">
                            <article className="card shadow">
                                <Row>
                                    <Col sm={8} className="mb-4 text-start">
                                        <Card.Body>
                                            <Form.Label className="w-100">
                                                Platform
                                            </Form.Label>
                                            <Select
                                                className="w-100"
                                                options={platforms}
                                                onChange={handleplatform}
                                                value={selectedPlatform}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                    }),
                                                }}
                                            />
                                        </Card.Body>
                                    </Col>
                                    <Col sm={4}>
                                        <Card.Body className="m-0 pt-4 text-center">
                                            {/* <div className="button_style m-0 text-center">
                                                <Button variant="contained" onClick={(a) => { handleClose(a) }}>
                                                    Save Changes
                                                </Button>
                                            </div> */}
                                            {/* {platformupdate === false ?
                                                <Tooltip title="Add link">
                                                    <Button variant='contained' onClick={handleaddplatform} disabled={selectedPlatform.length === 0}><i className="fa-solid fa-plus"></i> Add&Save Changes</Button>
                                                </Tooltip> :
                                                <Tooltip title="Update Title">
                                                    <Button variant='contained' onClick={handleupdateLink}><i className="fa-solid fa-plus"></i> Update&Save Changes</Button>
                                                </Tooltip>
                                            } */}
                                        </Card.Body>
                                    </Col>
                                </Row>

                                <br />
                                <Row>
                                    <Col sm={8} className="mb-4">
                                        <Card.Body className='text-center m-0'>
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formHorizontalEmail"
                                            >

                                                <Col sm={8}>
                                                    <Form.Label className="text-start w-100">
                                                        Social platform link
                                                    </Form.Label>
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        name="profilelink"
                                                        placeholder="Social Platform link"
                                                        value={socialLink}
                                                        onChange={(e) => {
                                                            setsocialLink(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@.,/;'"" "]/ig, ''))
                                                        }

                                                        }
                                                    />
                                                </Col>

                                                <Col sm={4}>
                                                    <Form.Label className="text-start w-100">
                                                        Followers Count
                                                    </Form.Label>
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        name="award-title"
                                                        placeholder=" Followers Count"
                                                        value={followerCount}
                                                        onChange={(e) => {
                                                            setFollowerCount(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@.,;'"" "]/ig, ''))
                                                        }

                                                        }
                                                    />
                                                </Col>
                                            </Form.Group>

                                        </Card.Body>
                                    </Col>
                                    <Col sm={3}>
                                        <Card.Body>
                                            {/* <input
                                                className="w-100 form-control"
                                                type="text"
                                                name="award-title"
                                                placeholder="enter title"
                                                value={socialLink}
                                                onChange={(e) => setsocialLink(e.target.value)}
                                            /> */}

                                            {/* <div className="button_style m-0 text-center">
                                                <Button variant="contained" onClick={(a) => { handleClose(a) }}>
                                                    Save Changes
                                                </Button>
                                            </div> */}
                                        </Card.Body>
                                    </Col>
                                </Row>
                                <div className='m-auto'>
                                    {platformupdate === false ?
                                        <Tooltip title="Add link">
                                            <Button variant='contained' onClick={handleaddplatform} disabled={selectedPlatform.length === 0}><i className="fa-solid fa-plus"></i> Add&Save Changes</Button>
                                        </Tooltip> :
                                        <Tooltip title="Update Title">
                                            <Button variant='contained' onClick={handleupdateLink}><i className="fa-solid fa-plus"></i> Update&Save Changes</Button>
                                        </Tooltip>
                                    }
                                    &nbsp;
                                    <Tooltip title="Cancel">
                                        <Button variant='contained' onClick={handleClear}> Cancel</Button>
                                    </Tooltip>
                                </div>
                                <br /><br />
                                <div style={{ overflow: "auto" }}>
                                <table
                                    id="mytable"
                                    className="normaltxt table-responsive  m-0 text-center"

                                >
                                    <tbody>
                                        <tr>
                                            <th>PlatformList</th>
                                            <th>Link</th>
                                            <th>Count</th>
                                            <th>Action</th>
                                        </tr>
                                        {socialLinks &&
                                            socialLinks.map(
                                                (
                                                    data,
                                                    ind
                                                ) => (
                                                    <tr key={ind}>
                                                        <td style={{ width: "10%" }}>
                                                            <>

                                                                {
                                                                    platforms &&
                                                                    platforms.map((f, i) => {
                                                                        if (f.value === Number(data.socialmediaplatformid)) {
                                                                            return (
                                                                                <Typography key={i}>{f.label}</Typography>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })}
                                                            </>
                                                        </td>
                                                        <td>

                                                            <Typography>{data.profilelink}</Typography>

                                                        </td>
                                                        <td>

                                                            <Typography>{data.celebrityfollowers}</Typography>

                                                        </td>
                                                        <td >
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditPlatform(data, ind)}
                                                            >
                                                                <Edit />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() =>
                                                                    handleDeleteByplatformID(ind, data.celebritysocialprofileid)
                                                                }
                                                            // disabled={ind === awardTitles.length - 1}
                                                            >
                                                                <DeleteOutlineIcon />
                                                            </IconButton>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                                </div>

                            </article>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={viewrelease} size="xl" backdrop="static" centered onHide={handleClose}>
                    <Modal.Header className="headerBlue" closeButton >
                        <Modal.Title className="">
                            <h5 className="text-start">Media Release</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container newscard">
                            <article className="card shadow">
                                <Form.Group
                                    as={Row}
                                    className="mb-3 justify-content-center"
                                    controlId="formHorizontalEmail"
                                >

                                    <Col sm={8} className="mb-4 text-start">
                                        <Card.Body>
                                            <Form.Label className="w-100">
                                                Media Release category
                                            </Form.Label>
                                            <Select
                                                className="w-100"
                                                options={ReleaseCategoryOptions}
                                                onChange={handleMediaReleaseCategory}
                                                value={selectedRelease}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                    }),
                                                }}
                                            />
                                        </Card.Body>
                                    </Col>
                                    <Col sm={4}>
                                        <Card.Body className='m-0 pt-4 text-center'>
                                            {/* <div className="button_style m-0 text-center">
                                                <Button variant="contained" onClick={(a) => { handleClose(a) }}>
                                                    Save Changes
                                                </Button>
                                            </div> */}
                                            {/* {releaseupdate === false ?
                                                <Tooltip title="Add Title">
                                                    <Button variant='contained' onClick={handleaddmedia} disabled={selectedRelease.length === 0}><i className="fa-solid fa-plus"></i> Add&Save Changes</Button>
                                                </Tooltip> :
                                                <Tooltip title="Update Title">
                                                    <Button variant='contained' onClick={handleupdatemedia}><i className="fa-solid fa-plus"></i> Update&Save Changes</Button>
                                                </Tooltip>
                                            } */}
                                        </Card.Body>
                                    </Col>


                                    <br />
                                    <Row>
                                        <Col sm={8} className="mb-4">
                                            <Card.Body className='m-0 text-center'>
                                                {/* {releaseupdate === false ?
                                                    <Tooltip title="Add Title">
                                                        <Button variant='contained' onClick={handleaddmedia} disabled={selectedRelease.length === 0}><i className="fa-solid fa-plus"></i></Button>
                                                    </Tooltip> :
                                                    <Tooltip title="Update Title">
                                                        <Button variant='contained' onClick={handleupdatemedia}><i className="fa-solid fa-plus"></i></Button>
                                                    </Tooltip>
                                                } */}
                                            </Card.Body>
                                            <Card.Body className="text-start">
                                                <Form.Label className="w-100">
                                                    Enter title
                                                </Form.Label>
                                                <input
                                                    className="w-100 form-control"
                                                    type="text"
                                                    name="mediareleasename"
                                                    placeholder="enter title"
                                                    value={mediarease}
                                                    onChange={(e) => setMediarease(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@.,;'"" "]/ig, ''))}
                                                />

                                                <FormControlR>
                                                    <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="Successful"
                                                        name="radio-buttons-group"
                                                        onChange={handleRadio}
                                                        value={radioInput}
                                                    >
                                                        <FormControlLabel value={1} control={<Radio />} label="Successful" />
                                                        <FormControlLabel value={0} control={<Radio />} label="Not Successfull" />
                                                    </RadioGroup>
                                                </FormControlR>
                                            </Card.Body>

                                        </Col>
                                        <Col sm={3}>
                                            <Card.Body className="">
                                                {/* <div className="button_style pt-4 m-0 text-center">
                                                    <Button variant="contained" onClick={() => { handleClose() }}>
                                                        Save Changes
                                                    </Button>
                                                </div> */}
                                            </Card.Body>
                                        </Col>


                                    </Row>
                                    <div className='m-auto'>
                                        {releaseupdate === false ?
                                            <Tooltip title="Add Title">
                                                <Button variant='contained' onClick={handleaddmedia} disabled={selectedRelease.length === 0}><i className="fa-solid fa-plus"></i> Add&Save Changes</Button>
                                            </Tooltip> :
                                            <Tooltip title="Update Title">
                                                <Button variant='contained' onClick={handleupdatemedia}><i className="fa-solid fa-plus"></i> Update&Save Changes</Button>
                                            </Tooltip>
                                        }
                                        &nbsp;
                                        <Tooltip title="Cancel">
                                            <Button variant='contained' onClick={handleClear}> Cancel</Button>
                                        </Tooltip>
                                    </div>
                                    <br /><br />
                                    <div style={{ overflow: 'auto' }}>
                                    <table
                                        id="mytable"
                                        className="normaltxt table-responsive m-0 text-center"
                                    // style={{ overflow: "auto", width: "95vw" }}
                                    >
                                        <tbody >
                                            <tr >
                                                <th>Media release</th>
                                                <th>Media release name</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            {mediaReleases &&
                                                mediaReleases.map(
                                                    (
                                                        data,
                                                        ind
                                                    ) => (
                                                        <tr key={ind}>
                                                            <td style={{ width: "10%" }}>
                                                                <>

                                                                    {
                                                                        ReleaseCategoryOptions &&
                                                                        ReleaseCategoryOptions.map((f, i) => {
                                                                            if (f.value === Number(data.mediareleasecategoryid)) {
                                                                                return (
                                                                                    <Typography key={i}>{f.label}</Typography>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })}
                                                                </>
                                                            </td>
                                                            <td>

                                                                <Typography>{data.mediareleasename}</Typography>

                                                            </td>
                                                            <td>

                                                                <Typography>{data.successfulyn}</Typography>

                                                            </td>

                                                            <td >
                                                                <IconButton
                                                                    aria-label="edit"
                                                                    color="primary"
                                                                    onClick={() => handleEditmedia(data, ind)}
                                                                >
                                                                    <Edit />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    color="error"
                                                                    onClick={() =>
                                                                        handleDeleteBymediaID(ind, data.mediareleaseid)
                                                                    }
                                                                // disabled={ind === awardTitles.length - 1}
                                                                >
                                                                    <DeleteOutlineIcon />
                                                                </IconButton>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                        </tbody>
                                    </table>
                                    </div>
                                </Form.Group>

                            </article>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}


export default CelebrityRegister