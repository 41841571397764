import React, { useState, useEffect } from 'react';
import { Categorywithnoparent, brandcategoryfeedback } from './ProductServiceAPI'
import { Col, Form, Row, Card } from 'react-bootstrap';
import forbidden from '../../../assets/img/forbidden.png';
import Comments2 from './Comments2';
import Select from 'react-select';
import Auth from '../../ServerCalls/Auth';
import { Box } from '@material-ui/core';
import { Pagination } from '@mui/material';
import Loader from '../../common/Loader';


export default function FeedbackReply() {
  const id = Auth.getUserId();
  const [feedback, setFeedback] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageC, setPageC] = useState(2)
  const [pageNumber, setPageNumber] = useState(1);
  const dataPerPage = 2;

  useEffect(() => {
    getCategoryOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  useEffect(() => {
    brandfeedback(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

  const brandfeedback = (pgno) => {
    setLoading(true);
    let catagories = selectedCategory.value === undefined ? 0 : selectedCategory.value
    brandcategoryfeedback(id, catagories, pgno)
      .then((res) => {
        const data = res.data._response.data.finalid
        if(data.length <= 0){
          setFeedback([]);
          setLoading(false);

        }
        else{
          let count = res.data._response.data.count;
          let APIpagecount = Math.ceil(count / dataPerPage);
          setFeedback(data)
          setPageC(APIpagecount);
          setLoading(false);

        }
      }).catch((err) => {
        console.log(err, "err in get feddback")

      })
  }


  const handleCategory = (selected) => {
    setLoading(true);
    selected ? setSelectedCategory(selected) : setSelectedCategory([])


  }
  const getCategoryOption = () => {
    Categorywithnoparent()
      .then((res) => {
        const category = res.data._response.data.getprocategory
        let categorydata = category.map((list) => ({
          value: list.categoryid,
          label: list.categoryName
        }))
        setCategoryOption(categorydata)
      }).catch((err) => {
        console.log(err);
      })
  }

  const changePage = (e, a) => {
    setPageNumber(a);
    brandfeedback(a)

  }

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <br/><br/>
            <div className="row mb-2">
              <div className="col-sm-12">
                <h1 className="m-0 text-center">Feedback Reply</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content mt-4  consti-section">
          <div className="container">
            <div className="row">
              <Col md={12}>
                <Card style={{ padding: "20px" }}>
                  <Form.Group
                    as={Row}
                    className="justify-content-center"
                    controlId="formHorizontalEmail"
                  >

                    <Col sm={10} className="mb-4">
                      <h5>
                        Select Category
                      </h5>
                      <Select
                        isClearable={selectedCategory}
                        className="w-100"
                        options={categoryOption}
                        onChange={handleCategory}
                        value={selectedCategory}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </Col>
                  </Form.Group>
                  {/* <Button className="detailsbtn">Clear</Button> */}
                </Card>
              </Col>
            </div>
          </div>
        </section>

        {

          loading ?
            <Loader />
            :
            feedback.length !== 0 ?
              feedback && feedback.map((rootComment,i) => (
                <Comments2
                key={i}
                  comment={rootComment}
                />
              ))
              :
              <>
                <div className="container mb-5 mt-5">
                  <div className="feedback-card">
                    <div className='m-0 text-center'>
                      <img className='img-fluid' src={forbidden} />
                    </div>
                    <h2 className='m-0 text-center' style={{fontWeight:'600', color:'grey'}}>No Comments Yet</h2>
                  </div>
                </div>

              </>
        }

        <Box display="flex" alignItems="center" justifyContent="center">
          <Pagination
               count={pageC}
              page={pageNumber}
             onChange={changePage}
            shape="rounded" color="primary" size="large"
          />
        </Box>
        <br /><br />
      </div>
    </div>
  )

}
