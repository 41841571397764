
import React, { useState, useEffect, useRef } from 'react'
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import MaterialTable from "material-table";
import Button from '@mui/material/Button';
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import DatePicker from 'react-datepicker'
import { Allbrands, BrandPhotos, updateBrandDetails, deleteData } from '../Admin/ProductServiceAPI'
import ProductURL from '../../ServerCalls/ProductServer';
import axios from "../../ServerCalls/AxiosInstance";


const PhotoPath = ProductURL.photoURL + "BrandImages/";


const Brand = () => {

    const columns = [
        { title: " Brand Name ", field: "brandName", align: "center" },
        { title: " Brand Details ", field: "brandDetails", align: "center" },
        { title: " Person Name ", field: "contactPersonName", align: "center" },
        { title: " Established Date ", field: "yearOfEstablishment", align: "center", type: "date" },
        { title: "Contact No.", field: "contactPersonContactNumber", align: "center" },

    ];

    const [brand, setBrand] = useState('');
    const [brandData, setbrandData] = useState([])
    const [personName, setpersonName] = useState('');
    const [contact, setcontact] = useState("");
    const [email, setemail] = useState('');
    const [userName, setuserName] = useState('');
    const [password, setpassword] = useState('')
    const [brandID, setbrandID] = useState('')
    const [update, setupdate] = useState(false);
    const [establishDate, setestablishDate] = useState(new Date())
    const [image11, setimage11] = useState('');
    const [image12, setimage12] = useState('');
    const [image11path, setImage11path] = useState('')
    const [image12path, setImage12path] = useState('')
    const [brandDetails, setbrandDetails] = useState('');
    const [cpassword, setcpassword] = useState('')
    const [Showlist, setShowlist] = useState(false)

    const [formError, setFormError] = useState([])
    const jumptoBack = useLocation();

    const handleimage12 = (e) => {
        setimage12(e.target.files[0])
        setImage12path(URL.createObjectURL(e.target.files[0]))
        setFormError({ ...formError, image12: "" })

    }

    const handleimage11 = (e) => {
        setimage11(e.target.files[0])
        setImage11path(URL.createObjectURL(e.target.files[0]))
        setFormError({ ...formError, image11: "" })

    }


    const inputRefrence1 = useRef(null);
    const inputRefrence2 = useRef(null);

    const handleInput = (e) => {
        setBrand(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))

        setFormError({ ...formError, BrandName: "" })

    }

    const handleConcern = (e) => {

        setpersonName(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''));
        setFormError({ ...formError, personName: "" })
    }

    const handleEmail = (e) => {

        setemail(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@." "]/ig, ''))
        setFormError({ ...formError, email: "" })
    }

    const handleusername = (e) => {

        setuserName(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setFormError({ ...formError, userName: "" })
    }

    const handleContact = (e) => {

        setcontact(e.target.value)
        setFormError({ ...formError, contact: "" })
    }

    const handlePassword = (e) => {

        setpassword(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setFormError({ ...formError, password: "" })
    }

    const handlecPassword = (e) => {

        setcpassword(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setFormError({ ...formError, cpassword: "" })
    }

    const handleBrandDetails = (e) => {

        setbrandDetails(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setFormError({ ...formError, brandDetails: "" })
    }

    useEffect(() => {
        getBrandList();
        setShowlist(false);
    }, [jumptoBack.key]);

    const getBrandList = () => {
        Allbrands()
            .then((res) => {
                const data = res.data._response.data.getbrand
                setbrandData(data)
            }).catch((err) => {
                console.log(err, "err in get  brand");
            })

    }

    const validate = () => {

        let regex = /^[A-Za-z0-9 ]+$/;
        let emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // let passregex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
        let contactregex = /^[6-9]\d{9}$/gi;



        let formError = {}
        let isValid = true;
        if (brand === '') {
            isValid = false;
            formError["BrandName"] = "please Enter Brand Name"
        }
        else if (regex.test(brand) === false) {
            formError = "Please enter alphabetic characters and no spaces";
        } else if (brand.length > 200) {
            isValid = false;
            formError["BrandName"] = "Maximum 200 characters are allowed";
        }

        if (personName === '') {
            isValid = false;
            formError["personName"] = "please Enter Your Name"

        } else if (personName.length > 200) {
            isValid = false;
            formError["personName"] = "Maximum 200 characters are allowed";
        }
        if (contact === "") {
            isValid = false;
            formError["contact"] = "please Enter Contact No."

        }
        else if (contact.length > 20) {
            isValid = false;
            formError["contact"] = 'Please enter valid phone number';
        }

        if (email === "") {
            isValid = false;
            formError["email"] = "please Enter Email"

        } else if (!emailregex.test(email)) {
            isValid = false;
            formError["email"] = "Please Enter a Valid Email";

        }

        if (userName === "") {
            isValid = false;
            formError["userName"] = "please Enter Username"

        } else if (userName.length > 200) {
            isValid = false;
            formError["userName"] = "Maximum 200 characters are allowed";
        }
        if (establishDate === "") {
            isValid = false;
            formError["establishDate"] = "please Select Year"

        }

        if (password === "") {
            isValid = false;
            formError["password"] = "please Enter Password"

        }
        else if (password.length < 8) {
            isValid = false;
            formError ["password"]= "Entered Password must be of atleast 8 Characters !!";
          }

        if (cpassword === "" && update === false) {
            isValid = false;
            formError["cpassword"] = "please Enter Password"

        } else if (cpassword !== password && update === false) {
            isValid = false;
            formError["cpassword"] = "Passwords do not match";
        }

        if (brandDetails === '') {
            isValid = false;
            formError["brandDetails"] = "please Enter Brand Details"
        }
        //  else if (brandDetails.length > 500) {
        //     isValid = false;
        //     formError["brandDetails"] = "Maximum 500 characters are allowed";
        // }
        if (image11.length < 1) {
            isValid = false;
            formError["image11"] = "Please select Image"

        }

        if (image12.length < 1) {
            isValid = false;
            formError["image12"] = "Please select Image"

        }

        setFormError(formError);

        return isValid;

    }

    const saveData = async (e) => {
        const isValid = validate();
        if (isValid) {
            const data = {
                brandDetails: brandDetails,
                brandName: brand,
                contactEmail: email,
                contactPersonContactNumber: contact,
                contactPersonName: personName,
                // image1: image1.length === 0 ? 'images.jpeg' : image1,
                // image2: image2,
                image1: image11.name,
                image2: image12.name,
                password: password,
                userName: userName,
                yearOfEstablishment: new Date(establishDate),
                Role:3
            }

            let formdata = new FormData();

            formdata.append("file", image11);
            formdata.append("file", image12);
            formdata.getAll("file")

            await image_upload(formdata);

            axios.post("https://api.topbrandsranking.com/Authorization/AddUserData", data)
                .then((res) => {
                    if (res.data._response.status === 1) {
                        Swal.fire({
                            icon: "success",
                            title: "Registration Successful, Kindly verify your email by clicking on the verification link sent on your email address.",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        getBrandList();
                        cancel();
                    }
                    else if (res.data._response.status === 2) {
                        Swal.fire({
                            icon: "warning",
                            title: res.data._response.message,
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err, "err in add brand data");
                });


        }

    }

    const image_upload = async (data) => {

        await BrandPhotos(data)
            .then((res) => {
                console.log("photo res", res)
            }).catch((err) => {
                console.log(err, "err in photo submit")
            })
    }

    const getdata = (a) => {

        setShowlist(false);
        setupdate(true);
        setbrandID(a.brandId);
        setBrand(a.brandName);
        setestablishDate(new Date(a.yearOfEstablishment));
        setcontact(a.contactPersonContactNumber);
        setbrandDetails(a.brandDetails);
        setuserName(a.userName);
        setpassword(a.password);
        setpersonName(a.contactPersonName);
        setemail(a.contactEmail);
        setimage11(a.image1);
        setimage12(a.image2)
    }

    const updateData = async () => {
        setShowlist(false)
        const isValid = validate();

        if (isValid) {
            const updatedata = {
                brandId: brandID,
                brandDetails: brandDetails,
                brandName: brand,
                contactEmail: email,
                contactPersonContactNumber: contact,
                contactPersonName: personName,
                image1: image11.name === undefined ? image11 : image11.name,
                image2: image12.name === undefined ? image12 : image12.name,
                password: password,
                userName: userName,
                yearOfEstablishment: new Date(establishDate)

            }
            let formdata = new FormData();
            formdata.append("file", image11);
            formdata.append("file", image12);

            await image_upload(formdata);
        updateBrandDetails(updatedata)
                .then((res) => {
                  

                    if (res.data._response.status === 1) {


                        Swal.fire({
                            icon: "success",
                            title: "Brand Updated Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        setShowlist(false)
                        getBrandList();
                        cancel();
                    }
                })
                .catch((err) => {
                    console.log(err, "err in update brand");
                })
        }
    }

    const TrashData = (row) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((res) => {

            if (res.isConfirmed) {
                deleteData(row.brandId).then((res) => {
                    if (res.data._response.status === 1) {

                        Swal.fire({
                            icon: "success",
                            title: "Brand Deleted Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        getBrandList();
                    }
                    else if (res.data._response.status === 2) {

                        Swal.fire({
                            icon: "warning",
                            title: res.data._response.message,
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                    }
                }).catch((err) => {
                    console.log(err, "err in delete brand");
                })
            }
        })
            .catch((err) => {
                console.log(err, "err in trash brand");
            })
    }

    const cancel = () => {
        setBrand('')
        setuserName('')
        setpassword('')
        setbrandDetails('')
        setpersonName('')
        setcontact("")
        setemail('')
        setestablishDate("")
        setupdate(false)
        inputRefrence2.current.value = null;
        inputRefrence1.current.value = null;
        setImage11path('');
        setImage12path('');
        setFormError([]);
        setcpassword('');
        setimage11('');
        setimage12('')
    }

    return (
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <br /><br />
                  
                           <div className="row mb-2">
                  {!Showlist ? (
                    <div className="col-sm-12">
                       {!update ? (
                      <h1 className="m-0 text-center"> ADD BRAND </h1>
                       ):
                      <h1 className="m-0 text-center"> EDIT BRAND </h1>
                       }
                    </div>
                  ) :
                    <div className="col-sm-12">
                      <h1 className="m-0 text-center"> Brand List </h1>
                    </div>
                  }
                </div>
                   
                    <Col md={12} >
                        <div className='d-flex justify-content-end'>
                            {!Showlist ?
                                <button
                                    type="button"
                                    className="btn btn-pos3 "
                                    onClick={() => {
                                        setShowlist(true);
                                        cancel();
                                    }}
                                >
                                    <i className="fa-solid fa-list"></i> List
                                </button>
                                :
                                <button
                                    type="button"
                                    className="btn btn-pos4 "
                                    onClick={() => setShowlist(false)}
                                >
                                    <i className="fa fa-arrow-circle-left"></i> back
                                </button>
                            }
                        </div>
                    </Col>
                    </div>
                </div>
                <section className="content consti-section">
                    <div className="container">
                        <div className="row">
                            <Col md={12}>
                                <Card style={{ padding: "20px" }}>
                                    {!Showlist ? (
                                        <div>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3 justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100">Brand Name</Form.Label>
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        name="BrandName"
                                                        placeholder="Brand Name"
                                                        value={brand}
                                                        onChange={handleInput}
                                                    />
                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 13,color: "red" }}>{formError.BrandName}</div>
                                                            : null

                                                    }
                                                </Col>
                                                <Col sm={6} className="mb-4" style={{ position: "relative", zIndex: "999" }}>
                                                    <Form.Label className="w-100 pb-1">Year of Establishment</Form.Label>
                                                    <DatePicker
                                                        className="w-100 form-control"
                                                        selected={establishDate}
                                                        onChange={(date) => { setestablishDate(date)
                                                            setFormError({ ...formError,establishDate:""}) 
                                                        
                                                        }}
                                                        // dateFormat="dd/MM/yyyy"
                                                        showYearPicker
                                                        dateFormat="yyyy"
                                                        maxDate={new Date()}
                                                    />
                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 13,color: "red" }}>{formError.establishDate}</div>
                                                            : null

                                                    }
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">Responsible Person Name:</Form.Label>
                                                    <input className="w-100 form-control" type="text" placeholder='Concern Person Name' name='personName' value={personName} onChange={handleConcern} />
                                                    {
                                                        formError ?
                                                            <div style={{fontSize: 13, color: "red" }}>{formError.personName}</div>
                                                            : null

                                                    }

                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">Contact No:</Form.Label>
                                                    <input className="w-100 form-control" type="number" placeholder='Contact' value={contact} name='contact' onChange={handleContact} />
                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 13, color: "red" }}>{formError.contact}</div>
                                                            : null

                                                    }
                                                </Col>
                                               {/* {!update?( */}
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">Email:</Form.Label>
                                                    <input className="w-100 form-control" type="text" placeholder='Email' name='email' value={email} onChange={handleEmail} />
                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 13, color: "red" }}>{formError.email}</div>
                                                            : null

                                                    }
                                                </Col>
                                                {/* ):     <Col sm={6} className="mb-4">
                                                <Form.Label className="w-100 pb-1">Email:</Form.Label>
                                                <input className="w-100 form-control" type="text" title="Email cannot be edited" placeholder='Email' disabled="true" name='email' value={email} onChange={handleEmail} />
                                                {
                                                    formError ?
                                                        <div style={{ fontSize: 14, color: "red" }}>{formError.email}</div>
                                                        : null

                                                }
                                            </Col> */}
                                                


                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">Username:</Form.Label>
                                                    <input className="w-100 form-control" type="text" placeholder='Username' name='userName' value={userName} onChange={handleusername} />

                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 13, color: "red" }}>{formError.userName}</div>
                                                            : null

                                                    }
                                                </Col>
                                                {!update?(
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">Password:</Form.Label>
                                                    <input className="w-100 form-control" type="password"  placeholder='Password' name='password' value={password} onChange={handlePassword} />
                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 13, color: "red" }}>{formError.password}</div>
                                                            : null

                                                    }
                                                </Col>
                                                ): <Col sm={6} className="mb-4">
                                                 <Form.Label className="w-100 pb-1">Password:</Form.Label>
                                                 <input className="w-100 form-control" type="password" title="Password cannot be edited" disabled='true' placeholder='Password' name='password' value={password} onChange={handlePassword} />
                                                 {
                                                     formError ?
                                                         <div style={{ fontSize: 13, color: "red" }}>{formError.password}</div>
                                                         : null

                                                 }
                                             </Col> 
}
                                               
                                                {
                                                    update === false ?
                                                        <Col sm={6} className="mb-4">
                                                            <Form.Label className="w-100 pb-1">Confirm Password:</Form.Label>
                                                            <input className="w-100 form-control" type="password" placeholder='Confirm Password' name='password' value={cpassword} onChange={handlecPassword} />
                                                            {
                                                                formError ?
                                                                    <div style={{ fontSize: 13, color: "red" }}>{formError.cpassword}</div>
                                                                    : null

                                                            }
                                                        </Col>
                                                        :
                                                        null
                                                }

                                                <Col sm={12} className="mb-4">
                                                    <Form.Label className="w-100">
                                                        Brand Details
                                                    </Form.Label>
                                                    <textarea
                                                        className="w-100 form-control"
                                                        placeholder="Tell us more"
                                                        rows="3"
                                                        onChange={handleBrandDetails}
                                                        name="brandDetails"
                                                        value={brandDetails}
                                                    ></textarea>
                                                    <div style={{ fontSize: 13,color: "red" }}>{formError.brandDetails}</div>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group
                                                as={Row}
                                                className="mb-3 justify-content-center"
                                            // controlId="formHorizontalEmail"
                                            >
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100"> Image 1</Form.Label>
                                                    <FormControl className="m-2" type="file" name='file' controlId={image11} ref={inputRefrence1} onChange={handleimage11} />
                                                    <br/>
                                                    <div style={{fontSize: 13, color: "red" }}>{formError.image11}</div>
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100"> Image 2</Form.Label>
                                                    <FormControl className="m-2" type="file" name='file' controlId={image12} ref={inputRefrence2} onChange={handleimage12} />
                                                    <br/>
                                                    <div style={{ fontSize: 13,color: "red" }}>{formError.image12}</div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="imagepreview">
                                                        <img alt=" " width="80" height="60" src={update === false ? image11path : image11.name === undefined ? PhotoPath + image11 : image11path} />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="imagepreview">
                                                        <img alt=" " width="80" height="60" src={update === false ? image12path : image12.name === undefined ? PhotoPath + image12 : image12path} />
                                                    </div>
                                                </Col>
                                                <Col md={6}></Col>
                                            </Form.Group>
                                            <div className="button_style m-0 text-center">
                                                {update === false ?
                                                    <Button
                                                        type="button"
                                                        variant='contained'
                                                        onClick={() => saveData()}
                                                    >
                                                        Submit
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="button"
                                                        variant='contained'
                                                        color='success'
                                                        onClick={() => updateData()}
                                                    >
                                                        Update
                                                    </Button>
                                                }{" "}
                                                <Button
                                                    type="button"
                                                    variant='contained'
                                                    color='error'
                                                    onClick={cancel}

                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>

                                            <MaterialTable
                                                // className="BrandHeaderTbl"
                                                title="Brand Details"
                                                data={brandData}
                                                columns={columns}
                                                actions={[
                                                    {
                                                        icon: "edit",
                                                        tooltip: "Edit",
                                                        onClick: (event, rowData) => {
                                                            // setFormError("")
                                                            getdata(rowData);

                                                        },
                                                    },

                                                    {
                                                        icon: "delete",
                                                        tooltip: "Delete",
                                                        onClick: (event, rowData) => {
                                                            TrashData(rowData);
                                                        },
                                                    },

                                                ]}
                                                options={{
                                                    search: true,
                                                    pageSize: 10,
                                                    showFirstLastPageButtons: false,
                                                    actionsColumnIndex: -1,
                                                    addRowPosition: "first",
                                                    pageSizeOptions: [10, 15, 20, 50],
                                                }}
                                            />
                                            {/* <button
                                                type="button"
                                                className="btn btn-pos2 m-4"
                                                onClick={() => setShowlist(false)}
                                            >
                                                <i className="fa-solid fa-circle-left"></i>&nbsp;Back
                                            </button> */}
                                        </div>
                                    )}
                                </Card>
                            </Col>
                        </div>
                    </div>
                </section>
            </div>

        </div>

    )
}

export default Brand;