import React, { useState, useEffect } from 'react';
import Footer from '../footer/Footer';
import '../home/Home.css';
import icon1 from '../../../assets/img/icon1.png';
import icon2 from '../../../assets/img/icon2.png';
import icon3 from '../../../assets/img/icon3.png';
import share from '../../../assets/img/share.png';
import '../../../fonts/pallmb.ttf';
import { campaignData, Topbrandproduct } from "../../pages/Admin/ProductServiceAPI";
import Auth from '../../ServerCalls/Auth';
import { getCelebrityCampaign, getTopCelebrity } from '../../pages/Admin/CelebrityPages/CelebrityServiceAPI'
import { Nav, Navbar, Container, Dropdown, Row, Col, Button, Card, NavDropdown, Badge } from 'react-bootstrap';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import logo1 from '../../../assets/img/logo1.png';
// import '../header/Header.css';
import { Link } from 'react-router-dom';
import ProductURL from "../../ServerCalls/ProductServer";
import { Rating } from 'react-simple-star-rating';
import axios from "axios";
import moment from 'moment-timezone';
import { Modal } from "react-bootstrap";
import { FacebookIcon, FacebookShareButton, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon, } from 'react-share';
import { FaShareAlt,FaVoteYea } from 'react-icons/fa';
import { MdMore } from 'react-icons/md';
// animation effects library
import Aos from 'aos';
import 'aos/dist/aos.css';
import CollapsibleExample from '../NewHeader';
import Swal from "sweetalert2";
import logo from '../../../assets/img/3.jpeg'

const PhotoPath = ProductURL.photoURL + "CampaignImages/";
const ProductPhoto = ProductURL.photoURL + "ProductImages/";
const PhotoPathCelebrityCampaign = ProductURL.photoURL + "CelebrityCampaignImages/";
const CelebrityPhotoPath = ProductURL.photoURL + "CelebrityImages/";
const TopNewsPhotoPath = ProductURL.photoURL + "GeneralNewsImages/";




const Homepage = (props) => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  const token = Auth.getToken();
  const [campaignShortName, setCampaignShortName] = useState([]);
  const [CelebrityCampaigndata, setCelebrityCampaigndata] = useState([]);
  const [TopCelebrity, setTopCelebrity] = useState([]);
  const [brand, setBrand] = useState([]);
  const [TopNews, setTopNews] = useState([]);
  const [view, setView] = useState(false);
  const [Topgeneralnews, setTopgeneralnews] = useState([]);
  const [pageC, setPageC] = useState(4);
  const [pageNumber, setPageNumber] = useState(1);
  const [ShareCampaign, setShareCampaign] = useState(0);
  const [ShareCelebrityCampaign, setShareCelebrityCampaign] = useState(0)
  const [showB, setShowB] = useState(false);
  const [showc, setShowc] = useState(false);
  const classes = ["row1-container", "box red", "box box-down blue", "row2-container"];

  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Contact, setContact] = useState("");
  const [Message, setMessage] = useState("");
  const [FormError, setFormError] = useState("");

  const dataPerPage = 4;


  const validate = () => {

    let FormError = {}
    let isValid = true;

    if (Name === "") {
      isValid = false;
      FormError["Name"] = "Please Enter Name ";
    }

    if (Email === "") {
      isValid = false;
      FormError["Email"] = "Please Enter Email ";
    }


    if (Contact === "") {
      isValid = false;
      FormError["Contact"] = "Please Enter Contact no";
    }

    if (Message === "") {
      isValid = false;
      FormError["Message"] = "Please Enter Message ";
    }


    setFormError(FormError)
    return isValid;
  }

  const ContactUs = () => {
    debugger
    const isValid = validate()
    if (isValid === true) {

      const data = {
        name: Name,
        email: Email,
        contactno: Contact,
        message: Message
      }
      axios.post("https://api.topbrandsranking.com/ProductManagement/ContactUsData", data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Your Data Added Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          setName("");
          setEmail("");
          setContact("");
          setMessage("");
        }).catch((err) => {
          console.log(err)
        })
    } else {
      console.log("err")
    }
  }


  const handleClose = () => {
    setShowB(false);
  };

  const title = 'Campaign Information';



  const handleClose2 = () => {
    setView(false);
  };

  const handleClose3 = () => {
    setShowc(false);
  };






  useEffect(() => {
    Topbrandproduct(1, 4)
  }, [])


  const Topbrandproduct = (a, b) => {
    axios.get(`https://api.topbrandsranking.com/ProductManagement/TopProductGetByProductId?PageNo=${a}&PageSize=${b}`)
      .then((res) => {
        let data = res.data._response.data.topproductgetbyproductid3
        let count = res.data._response.data.count;
        let APIpagecount = Math.ceil(count / dataPerPage);
        setPageC(APIpagecount)
        setBrand(data)
      })
  }


  useEffect(() => {

    // getCelebrityCampaignlist();
    topBrandCelebrity(1, 4);
    getTopCelebrityCampaign();

  }, [])

  const openModal = (campid) => {
    let id = btoa(campid)
    setShareCampaign(id)
    setShowB(true);
  }


  const openCelebrityModal = (list) => {
    let id = btoa(list.campaignId)
    setShareCelebrityCampaign(id)
    setShowc(true);
  }


  const topBrandCelebrity = (a, b) => {
    // getTopCelebrity()
    axios.get(`https://api.topbrandsranking.com/ProductManagement/TopCelebrityGetByCelebrityId?PageNo=${a}&PageSize=${b}`)
      .then((res) => {
        const topcelebritydata = res.data._response.data.topcelebritygetbycelebrityid3
        let count = res.data._response.data.count;
        let APIpagecount = Math.ceil(count / dataPerPage);
        setPageC(APIpagecount)
        setTopCelebrity(topcelebritydata)
      }).catch((err) => {
        console.log(err)
      })

  }

  useEffect(() => {
    getTopNews();
    getTopCampaign();
  }, [])

  const getTopNews = () => {
    axios.get("https://api.topbrandsranking.com/ProductManagement/GetGeneralNewsdatetimetop3")
      .then((res) => {

        const topnews = res.data._response.data.getgeneralnewsdatetimetop3
        setTopNews(topnews)

      }).catch((err) => {
        console.log(err)
      })
  }


  const getTopCampaign = () => {

    axios.get("https://api.topbrandsranking.com/ProductManagement/GetProductPollingCampaigntop4")

      .then((res) => {
        if (res.data._response.status === 1) {
          let topCelebrityCampaign = res.data._response.data.getProductPollingCampaigntop4

          setCampaignShortName(topCelebrityCampaign)
        }

      }).catch((err) => {
        console.log(err)
      })
  }

  const getTopCelebrityCampaign = () => {
    axios.get("https://api.topbrandsranking.com/ProductManagement/GetCelebrityCampaigntop4")

      .then((res) => {
        console.log(res, "got celebrity")
        if (res.data._response.status === 1) {

          let topCampaign = res.data._response.data.getCelebrityCampaigntop4

          setCelebrityCampaigndata(topCampaign)
        }

      }).catch((err) => {
        console.log(err)
      })
  }

  const openNews = (id) => {
    setView(true)

    axios.get(`https://api.topbrandsranking.com/ProductManagement/GeneralNewsdatetimetop3GetById/${id}`)
      .then((res) => {
        const news = res.data._response.data.generalNewsdatetimetop3getByid
        setTopgeneralnews(news)
      }).catch((err) => {
        console.log(err)
      })

  }

//  const photo ="https://pixabay.com/images/search/nature/"

  return (
    <>
      <CollapsibleExample />

     
      <section className='section-1'
      >

        <div className='container'>
          <div className='home-banner' >



            <div className='row text-start'>

              <div className='col-sm-12'>

                <div className="p-4 responsive" >

                  <div className='brand-heading ' data-aos="fade-right">
                    <h1 className='text-start'><span>Topbrands</span> Ranking</h1>
                    <p className='brandsub-heading'>
                      Top Brands Ranking is a blockchain e-voting platform  <br />
                      that allows you to transparently Verify, Vote and Qualify  <br />
                      Brands that deserves to be conferred with various   <br />
                      Topscorebrand Awards  held annually.
                    </p>


                  </div>

                </div>
              </div>


            </div>

          </div>
        </div>

      </section>

      {/* news section */}
      <section>

        <Row>
          <Col>
            <div>
              <div class="ticker-wrap w-100">
                <div class="ticker">
                  {TopNews.map((a, i) => (
                    <>
                      <div class="ticker__item">{a.newsHeader}</div>
                    
                    </>
                  ))}
                </div>



              </div>
            </div>
          </Col>
        </Row>



      
      </section>

     
      <section className='mt-4 mb-4 new-news'>
        <div class='container ' data-aos="fade-left">
          <div>
            <h2 className='m-0 text-center trend-heading'>TRENDING <span>NEWS</span></h2>
          </div>
          <br />
          <Row>
            {TopNews.map((list, id) => (

              <Col onClick={() => openNews(list.generalNewsId)}>
                <div class='normal'>

                  <div class='module'>
                    <div class='thumbnail'>
                      <img src={TopNewsPhotoPath + list.newsImages} />
                      <div class='date'>
                        {/* <div>Jan</div>
                        <div>20</div> */}
                     
                        <div>{moment(list.newsDatetime).format('DD')}</div>
                        <div>{moment(list.newsDatetime).format('MMM')}</div>
                      </div>
                    </div>
                    <div class='content1'>

                      <h1 class='title text-start'>{list.newsHeader}</h1>
                      {/* <h2 class='sub-title'>The city that never sleeps.</h2> */}
                      <div class="description text-start">{list.newsDescription}</div>

                    </div>
                  </div>
                </div>
              </Col>

            ))}

          </Row>
          <br />
          <Row className='text-center'>
            <div>
              <Link to="/GeneralNewsList"> <button class="button-85 pt-2" role="button">View More</button></Link>

            </div>
          </Row>
        </div>
      </section>
      <section className='section-4-cards'>
        <Container data-aos="zoom-in">
          <div>
            <h2 className='brranking1 text-center m-0 text-light'>FAVOURITE BRAND</h2>
          </div>
          <div class="row1-container">
            <div class="box box-down cyan">
              <div className='d-flex justify-content-between'>
                <h2 className='text-start fav-h2'>{campaignShortName[0] !== undefined ? campaignShortName[0].pollingCampaignShortName : ""}</h2>
                <IconButton className='text-end'><FaShareAlt fontSize="25px" color='#CD0404' onClick={() => openModal(campaignShortName[0].campaignId)} title="click to share" /></IconButton>
              </div>
              <p className='text-start'>{campaignShortName[0] !== undefined ? campaignShortName[0].categoryname : ""}</p>
              <img className='img-fluid img-brands' src={campaignShortName[0] !== undefined ? PhotoPath + campaignShortName[0].pollingImage : ""} alt="" />
            </div>

            <div class="box red">
              <div className='d-flex justify-content-between'>
                <h2 className='text-start fav-h2'>{campaignShortName[1] !== undefined ? campaignShortName[1].pollingCampaignShortName : ""}</h2>
                <IconButton className='text-end'><FaShareAlt fontSize="25px" color='#CD0404' onClick={() => openModal(campaignShortName[1].campaignId)} title="click to share" /></IconButton>
              </div>
              <p className='text-start'>{campaignShortName[1] !== undefined ? campaignShortName[1].categoryname : ""}</p>
              <img className='img-fluid img-brands' src={campaignShortName[1] !== undefined ? PhotoPath + campaignShortName[1].pollingImage : ""} alt="" />
            </div>

            <div class="box box-down blue">
              <div className='d-flex justify-content-between'>
                <h2 className='text-start fav-h2'>{campaignShortName[2] !== undefined ? campaignShortName[2].pollingCampaignShortName : ""} </h2>
                <IconButton className='text-end'><FaShareAlt fontSize="25px" color='#CD0404' onClick={() => openModal(campaignShortName[2].campaignId)} title="click to share" /></IconButton>
              </div>      <p className='text-start'>{campaignShortName[2] !== undefined ? campaignShortName[2].categoryname : ""}</p>
              <img className='img-fluid img-brands' src={campaignShortName[2] !== undefined ? PhotoPath + campaignShortName[2].pollingImage : ""} alt="" />
            </div>
          </div>
          <div class="row2-container">
            <div class="box orange">
              <div className='d-flex justify-content-between'>
                <h2 className='text-start fav-h2'>{campaignShortName[3] !== undefined ? campaignShortName[3].pollingCampaignShortName : ""} </h2>
                <IconButton className='text-end'><FaShareAlt fontSize="25px" color='#CD0404' onClick={() => openModal(campaignShortName[3].campaignId)} title="click to share" /></IconButton>
              </div>      <p className='text-start'>{campaignShortName[3] !== undefined ? campaignShortName[3].categoryname : ""}</p>
              <img className='img-fluid img-brands' src={campaignShortName[3] !== undefined ? PhotoPath + campaignShortName[3].pollingImage : ""} alt="" />
            </div>
          </div>
          <Row className='text-center'>
            <div>
              <Link to="/moreProductCampaign"><button class="button-85 pt-2" role="button">View More</button></Link>

            </div>
          </Row>
        </Container>
      </section>
    


      <section className='cardsec'>

        <div className='container' data-aos="fade-left">

          <h2 className='m-0 text-center trend-heading'>BRAND <span>RANKING</span></h2>
          <br />
          <div className="row">
            {brand.map((a) => (
              <div className="col-md-3 col-sm-6 pb-5">
                <Card className='h-100'>

                  <div className="product-grid">
                    <div className="product-image">
                      <div className="image" >
                        <img className="pic-1" alt='' src={ProductPhoto + a.productimage1} style={{ objectFit: 'fill' }} />
                        <img className="pic-2" alt='' src={ProductPhoto + a.productimage1} style={{ objectFit: 'fill' }} />

                      </div>

                    </div>

                    <div className="product-content">
                      <Card.Title className='product-title h4'>{a.brandname}</Card.Title>

                      <div className="price h6">{a.productname}
                      </div>

                      {/* <div className="price"></div> */}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Rating
                        ratingValue={a.avergae}
                        // allowHover={false}
                        readonly
                        size='20px'

                      />
                      <div className="price">Total Ratings:&nbsp;{(a.avergae).toFixed(2)}%</div>


                    </div>
                  </div>
                </Card>

              </div>

            ))}
          </div>
          <Row className='text-center'>
            <div>
              <Link to="/MoreBrandRanking_List"><button class="button-85 pt-2" role="button">View More</button></Link>

            </div>
          </Row>
          {/* <Box display="flex" alignItems="center" justifyContent="center">
                          <Pagination
                              count={pageC}
                              page={pageNumber}
                              onChange={changePages}
                              shape="rounded" color="primary" size="large"
                          />
                      </Box> */}


        </div>
      </section>



      <section className='section-5'
      // style={{ backgroundColor: '#2185d0', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}

      >
        <div className='container' data-aos="zoom-in">


          {/* <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-5'>
              <h1 className='color'>Learn More about Brands</h1>
            </div>
            <div className='col-md-2 '>
              <Link to={'/all-brands'}><button className='button-85 m-0 text-center'>Learn More</button></Link>
            </div>
          </div> */}
          <Row className='d-flex justify-content-center'>
            <Col sm={8}>
          <div className='d-flex justify-content-around'>
          <h1 className='color'>Learn More about Brands</h1>
          <div className='pt-2'>
          <Link to={'/all-brands'}><button className='button-85 m-0 text-center'>Learn More</button></Link>

          </div>
          </div>
          </Col>
          </Row>
        </div>

      </section>

      <section className='celebrity-camp' >
        <Container data-aos="fade-right">
          {/* <div className='d-flex justify-content-end'>
            <img src={celebrity1} width='64' height="64" alt='' />
          </div> */}
          <div className='pt-4 pb-4'>
            <h2 className='m-0 text-center trend-heading'>FAVOURITE <span>CELEBRITY</span></h2>
          </div>          <br />


          <div className='row'>
            {CelebrityCampaigndata.map((list, id) => (
              <div className='col-md-3 pb-4'>
               
                    

                      <div className="brandcateg card-blog">

                        <div className="cardcateg-image">
                          <div><img className="img" src={PhotoPathCelebrityCampaign + list.celebrityImage} alt='' />
                          </div>
                          <div className="ripple-cont"></div>
                        </div>
                        <div className='card-body'>
                          <div>
                            <p className="category" title='{list.campaignShortName}' >{list.campaignShortName}</p>
                            <p className="cardcateg-description">{list.celebritycategoryname}</p>
                          </div>
                          <div className='d-flex justify-content-between'>
                            <IconButton className='text-end'><FaShareAlt fontSize="25px" color='#CD0404' onClick={() => openCelebrityModal(list)} title="click to share" /></IconButton>
                            <Tooltip title="Click To Vote">
                              {token ? (
                                <Link className='text-decoration-none' to={`/CelebrityVoting/${list.campaignId}/${list.campaignEndDate}/${list.campaignShortName}/${list.questions}/${list.celebCategoryId}`}><IconButton className='text-end'>
                                  <FaVoteYea fontSize="25px" color='#000' />
                                </IconButton>
                                </Link>


                              ) :
                                <Link to="/subregister" state={{ from: list.campaignId, date: list.campaignEndDate, name: list.campaignShortName, question: list.questions, category: list.celebCategoryId, page: "authorisedCelebritycampaigndetails" }}><IconButton className='text-end'>
                                  <FaVoteYea fontSize="25px" color='#000' />
                                </IconButton>
                                </Link>
                              }
                            </Tooltip>

                          </div>
                        </div>
                      </div>
                      {/* </Link> */}

                      {/* <Card>
                        <Card.Body>
                          <div className='m-0 text-center' onClick={() => openCelebrityModal(list)} title="click to share">
                            <IconButton className="text-center">
                              <Image src={share} alt='share' />
                            </IconButton> <span className='brand-span'>Share Campaign !</span>
                          </div>
                        </Card.Body>
                      </Card> */}



              </div>

            ))}

          </div>




          <Row className='text-center mt-4'>
            <div>
              <Link to="/moreCelebrityCampaign"><button class="button-85" role="button">View More</button></Link>
            </div>
          </Row>
        </Container>

      </section>
      <br />



      <section className='celebrity-top' >
        <Container data-aos="fade-left">
          {/* <div className='d-flex justify-content-start'>
            <img src={celebrity} width='64' height="64" alt='' />
          </div> */}
          <div className='pt-4 pb-4'>
            <h2 className='m-0 text-center brranking1 '>TOP CELEBRITY RANKING</h2>
          </div>
          <br />

          <div className="row">
            {TopCelebrity.map((list, id) => (
              <div className="col-md-3 col-sm-6 pb-5">
                <Card className='cele-top-card'>

                  <div className="product-grid">
                    {/* <Card.Title>{list.brandname}</Card.Title> */}
                    <div className="product-image">
                      <div className="image" >
                        <img className="pic-1" alt='' src={CelebrityPhotoPath + list.celebrityimage1} />
                        <img className="pic-2" alt='' src={CelebrityPhotoPath + list.celebrityimage1} />

                      </div>

                    </div>
                    <Card.Body className="celeb-card-body" style={{ backgroundColor: ' #0f0c29' }}>

                      <div className="product-content">

                        <div className="text-center mx-auto">{list.celebrityname}
                        </div>

                        {/* <div className="price"></div> */}
                        <div className="text-center mx-auto">
                          <Rating
                            ratingValue={list.avergae}
                            // allowHover={false}
                            readonly
                            size='20px' />
                        </div>
                        <div className="text-center mx-auto">Total Ratings:&nbsp;{(list.avergae).toFixed(2)}%</div>
                      </div>
                    </Card.Body>
                  </div>

                </Card>
              </div>
            ))}
          </div>

          {/* <Box display="flex" alignItems="center" justifyContent="center">
            <Pagination
              count={pageC}
              page={pageNumber}
              onChange={changePage}
              shape="rounded" color="primary" size="large"
            />
          </Box> */}
          <Row className='text-center'>
            <div>
              <Link to="/MoreCelebrityRanking_List"><button class="button-85 pt-2" role="button">View More</button></Link>

            </div>
          </Row>
        </Container>

      </section>
      <section>
        <div className="container" data-aos="zoom-in">
          <div className="wrapper">
            <div className="form">
              <div className="left-col">


                <div className="bull">
                  <img src={icon1} alt='icon address' />
                  <h2 className=" addheading">Address</h2>
                  <p className="">
                    Graceland 2nd block  2nd floor, Parklands, Nairobi , Kenya.
                  </p>
                  <img src={icon2} alt='icon phone' />
                  <h2 className=" addheading">Phone</h2>
                  <p className="">
                    +254722535472
                  </p>
                  <img src={icon3} alt='icon phone' />
                  <h2 className=" addheading">Email</h2>
                  <p className="">support@topbrandsranking.com</p>
                </div>

              </div>
              <div className="right-col" id="contactus" >
                <h2 className='m-0 text-center trend-heading'>QUICK <span>CONTACT</span></h2>
                <br />
                <form>
                  <div className="result"></div>
                  <div className="form-group pb-4">

                    <input id="name" value={Name} onChange={(e) => { setName(e.target.value); setFormError({ ...FormError, Name: "" }) }} type="text" placeholder="Enter your name" />
                    <div style={{ color: "red" }}>
                      {FormError.Name}
                    </div>{" "}
                  </div>



                  <div className="form-group pb-4">
                    <input id="email" value={Email} onChange={(e) => { setEmail(e.target.value); setFormError({ ...FormError, Email: "" }) }} type="email" placeholder="Enter your email" />
                    <div style={{ color: "red" }}>
                      {FormError.Email}
                    </div>{" "}
                  </div>




                  <div className="form-group pb-4">
                    <input id="no" value={Contact} onChange={(e) => { setContact(e.target.value); setFormError({ ...FormError, Contact: "" }) }} type="Number" placeholder="Enter your phone number" />
                    <div style={{ color: "red" }}>
                      {FormError.Contact}
                    </div>{" "}
                  </div>



                  <div className="form-group">
                    <textarea id="message" value={Message} onChange={(e) => { setMessage(e.target.value); setFormError({ ...FormError, Message: "" }) }} type="text-area" placeholder="Enter your message" />
                    <div style={{ color: "red" }}>
                      {FormError.Message}
                    </div>{" "}
                  </div>



                  <button type="button" onClick={() => ContactUs()}>SUBMIT</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Modal
        show={view}
        onHide={handleClose2}
        size="md"
        backdrop="static"
        centered
      >
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="">
            <h5 className="text-center">News Details</h5>
          </Modal.Title>
        </Modal.Header>

        {Topgeneralnews.map((list, id) => (
          <Modal.Body>
            <div className="container newscard">
              <article className="card shadow">
                <div className="row">
                  <img
                    className="rounded-circle"
                    src={TopNewsPhotoPath + list.newsImages}
                    alt="news"
                  />
                  <strong>
                    <p className="text-center mt-2 mx-auto">
                      {list.newsHeader}
                    </p>
                  </strong>
                </div>
                <div>
                  <p className="text-center mt-2 mx-auto">{list.newsDescription}</p>
                </div>
              </article>
            </div>
          </Modal.Body>
        ))}
      </Modal>

      {/*section-6 footer*/}




      <Modal
        show={showB}
        onHide={handleClose}
        size="sm"
        backdrop="static"
        centered
      >
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="mx-auto">
            <h4 className='text-start m-0'>
              <b>Share Product</b>
            </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <Link to="https://web.whatsapp.com/"> */}
          <Row className="text-center">
            <Col sm={4}>
              <FacebookShareButton
                url={`https://topbrandsranking.com/SharedCampaign/${ShareCampaign}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={64} round />
              </FacebookShareButton>
            </Col>
            <Col sm={4}>
              < WhatsappShareButton
                url={`https://topbrandsranking.com/SharedCampaign/${ShareCampaign}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
                < WhatsappIcon size={64} round />
              </ WhatsappShareButton>
            </Col>
            <Col sm={4}>
              < TwitterShareButton
                url={`https://topbrandsranking.com/SharedCampaign/${ShareCampaign}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={64} round />
              </ TwitterShareButton>
            </Col>
            {/* <Col sm={3}>
            < InstapaperShareButton
                url={`http://tbr.softlabsgroup.in/ShareProductDetails/${productID}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
              <InstapaperIcon size={32} round />
              </ InstapaperShareButton>
            </Col> */}
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showc}
        onHide={handleClose3}
        size="sm"
        backdrop="static"
        centered
        className='landing-modal'
      >
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="mx-auto">
            <h5>
              <b>Share Celebrity</b>
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <Link to="https://web.whatsapp.com/"> */}
          <Row className="text-center">
            <Col sm={4}>
              <FacebookShareButton
                url={`http://topbrandsranking.com/SharedCelebritycampaign/${ShareCelebrityCampaign}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={64} round />
              </FacebookShareButton>
            </Col>
            <Col sm={4}>
              < WhatsappShareButton
                url={`http://topbrandsranking.com/SharedCelebritycampaign/${ShareCelebrityCampaign}`}
                quote={title}
                media="https://images.unsplash.com/photo-1615022702095-ff2c036f3360?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW5kaWFuJTIwZ2lybHxlbnwwfHwwfHw%3D&w=1000&q=80"
                className="Demo__some-network__share-button"
              >
                < WhatsappIcon size={64} round />
              </ WhatsappShareButton>
            </Col>
            <Col sm={4}>
              < TwitterShareButton
                url={`http://topbrandsranking.com/SharedCelebritycampaign/${ShareCelebrityCampaign}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={64} round />
              </ TwitterShareButton>
            </Col>
            {/* <Col sm={3}>
            < InstapaperShareButton
                url={`http://tbr.softlabsgroup.in/ShareProductDetails/${productID}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
              <InstapaperIcon size={32} round />
              </ InstapaperShareButton>
            </Col> */}
          </Row>
        </Modal.Body>
      </Modal>

      <Footer />

    </>
  )
}

export default Homepage;