import React, { useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { Form, Row, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import logo1 from "../../../assets/img/logo1.png";
import "./Login.css";
import Button from "@mui/material/Button";
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Auth from '../../ServerCalls/Auth';
import {
  InputAdornment,
  
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import TextField from '@mui/material/TextField';
import FormControl from "@mui/material/FormControl";
import FacebookLogin from 'react-facebook-login';
import {BsFacebook} from 'react-icons/bs'
import { GoogleLogin } from '@react-oauth/google';
import IconButton from '@mui/material/IconButton';
import {AiFillHome} from 'react-icons/ai';
import jwt_decode from "jwt-decode";
// import { GoogleLogin, GoogleLogout } from 'react-google-login';
const LogIn = () => {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setemailError] = useState({});
  const [passError, setPassError] = useState({});
  const [cpassword, setcpassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const key = location.state
  const token = Auth.getToken();
  const [googleLogin, setGoogleLogin] = useState('');
  // const clientID = '279650001757-vj5a0or6srm4k068fs06dmrpfc00fi7s.apps.googleusercontent.com';   //for email softlabssd@gmail
  const clientID = '1041623456314-svm0pd4b9rm4d03h10vliav3qnuoancm.apps.googleusercontent.com';     //for email manasi.softlabs

  // <GoogleOAuthProvider clientId="279650001757-vj5a0or6srm4k068fs06dmrpfc00fi7s.apps.googleusercontent.com">...</GoogleOAuthProvider>;



  const ChangeEmail = (e) => {
    setEmail(e.target.value);
    setemailError("");
  };

  const changePass = (e) => {
    setPassword(e.target.value);
    setPassError("");
  };


  const login = async (e) => {

    let isValid = formValidation();
    if (isValid) {
      let body = {
        email: email,
        password: password,
        RegistrationMethod:0
      };
      let headers = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      setLoading(true)
      axios
        .post(
          `https://api.topbrandsranking.com/Authorization/authenticate`,
          // `https://localhost:5001/api/Authorization/authenticate`,
          body,
          headers
        )
        .then((res) => {
          if (res.status === 200) {
            const data = res.data._response;
            if (res.data._response.status === 1) {
              setLoading(false)
              const userDetails = {
                email: data.email,
                token: data.token,
                id: data.id,
                firstName: data.firstName,
                memberIdBrandId: data.memberIdBrandId,
                images: data.images,
                lastName: data.lastName,
                // signInTypeId: data.signInTypeId,
                role: data.role,
              };
              const loginDetails = JSON.stringify(userDetails);
              localStorage.setItem("UserDetails", loginDetails);
              if (data.role === 1) {
                window.location.href = "/admindashboard";
              } else if (data.role === 2) {
                if(key === null){
                  window.location.href = "/UserDetails";
                }
                else{
                  if (key.page === "Dashboard") {
                    window.location.href = "/UserDetails";
                  } else if (key.page === "Voting") {
                    window.location.href = "/productVoting"
                  }else if (key.page === "authorisedcampaigndetails"){
                    window.location.href = "/productVoting"
                  }else if (key.page === "authorisedCelebritycampaigndetails"){
                    window.location.href = "/CelebrityVoting"
                  }
                  else if (key.page === "CelebrityVoting") {
                    window.location.href = "/CelebrityVoting"
                  } else {
                    window.location.href = "/UserDetails";
                  }
                 
                  
                }
              } else if (data.role === 3) {
                window.location.href = "/BrandDetails";
              } 
            
            } else {
              setLoading(false)
              Swal.fire({
                icon: "warning",
                title: 'Your are not a valid user',
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            }
          }
        })
        .catch((err) => {
          setLoading(false)
          Swal.fire({
            icon: "warning",
            title: "Invalid Login Details",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          // setEmail("");
          // setPassword("");
        });
    }
  };



  const formValidation = () => {
    const emailError = {};
    const passError = {};

    let isValid = true;

    if (email === "" || email === null) {
      emailError["email"] = "*Email is required";
      isValid = false;
    }

    if (password === "" || password === null) {
      passError["password"] = "*Password required";
      isValid = false;
    }

    setemailError(emailError);
    setPassError(passError);

    return isValid;
  };


  const onForgotPass = () => {
    Swal.fire({
title: 'Forgot Password',
text: 'Enter Email',
input: 'text',
inputAttributes: {
  autocapitalize: 'off'
},
showCancelButton: true,
confirmButtonText: 'Submit',
showLoaderOnConfirm: true,
preConfirm: async (login) => {
  let valid = true;
  const emailregex = /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;
  if (login === '') {
    Swal.showValidationMessage(`Please Enter Email`);
    valid = false;
  } else {
    if (!emailregex.test(login)) {
      Swal.showValidationMessage(`Please Enter Valid Email`);
      valid = false;
    }
  }
  if (valid === true) {
    let data = { email: login }
    let headers = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    return axios.post('https://api.topbrandsranking.com/Authorization/forgot-password',
      data, headers).then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            return res.data._response.message
            // 
          } else {
            throw new Error('Wrong details')
          }
        } else {
          throw new Error('Wrong details')
        }
      })
      .catch((e) => {
        Swal.showValidationMessage(
          `Request failed: Wrong details`
        )
        console.log(e, 'err in forgot password');
      })
  }
},
allowOutsideClick: () => !Swal.isLoading()
}).then((result) => {
if (result.isConfirmed) {
  Swal.fire('Forgot password link sent to your registered email', "", "success");
}
}).catch((err)=>{
  Swal.fire({
    icon: "warning",
    title: "Something went wrong",
    showConfirmButton: true,
    confirmButtonColor: "#3085d6",
    // cancelButtonColor: '#d33',
    confirmButtonText: "ok",
  });
})
}

  const handleClickShowPassword = () => {
    setcpassword(!cpassword);
  };

  const googleLoginData = (data)=>{
    var decoded = jwt_decode(data.credential);
    let emailData = decoded.email

    let body = {
      email: emailData,
      RegistrationMethod:1
    };
    let headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoading(true)
    axios.post(`https://api.topbrandsranking.com/Authorization/GoogleFbauthenticate`, body, headers)
    // axios.post(`https://localhost:5001/api/Authorization/GoogleFbauthenticate`, body, headers)
      .then((res) => {
        if (res.status === 200) {
          const logindata = res.data._response;
          if (res.data._response.status === 1) {
            setLoading(false)
            const details = {
              email: logindata.email,
              token: logindata.token,
              id: logindata.id,
              firstName: logindata.firstName,
              memberIdBrandId: logindata.memberIdBrandId,
              images: logindata.images,
              lastName: logindata.lastName,
              // signInTypeId: logindata.signInTypeId,
              role: logindata.role,
            };
            const loginDetails = JSON.stringify(details);
            localStorage.setItem("UserDetails", loginDetails);
            if (logindata.role === 1) {
              window.location.href = "/admindashboard";
            } else if (logindata.role === 2) {
              // if (key === null) {
                window.location.href = "/UserDetails";
              // }
              // else {
              //   if (key.page === "Dashboard") {
              //     window.location.href = "/UserDetails";
              //   } else if (key.page === "Voting") {
              //     window.location.href = `/productVoting/${key.from}/${key.date}/${key.name}/${key.question}/${key.categoryid}`
              //   }
              //   else if (key.page === "CelebrityVoting") {
              //     window.location.href = `/CelebrityVoting/${key.from}/${key.date}/${key.name}/${key.question}/${key.category}`
              //   } else {
              //     window.location.href = "/UserDetails";
              //   }
              // }
            } else if (logindata.role === 3) {
              window.location.href = "/BrandDetails";
            }          
          }else{          
            setLoading(false);            
            Swal.fire({
              icon: "warning",
              title: "Invalid Details! Your Topbrands Account in not link with Your Google account",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          
          }
        }else {
          setLoading(false);
          Swal.fire({
            icon: "warning",
            title: "Invalid Login Details",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
         
        
        }
      }).catch((err)=>{
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });

      })
  }

  const responseFacebook = (res)=>{

    let body = {
      email: res.email,
      RegistrationMethod:2

    };
    let headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoading(true)
    axios.post(`https://api.topbrandsranking.com/Authorization/GoogleFbauthenticate`, body, headers)
      //  axios.post(`https://localhost:44368/api/Authorization/GoogleFbauthenticate`, body, headers)
      .then((res) => {

        if (res.status === 200) {
          if (res.data._response.status === 1) {

            setLoading(false);
            let newUserLoginDetails = res.data._response
            let Newdetails = {
              email: newUserLoginDetails.email,
              token: newUserLoginDetails.token,
              id: newUserLoginDetails.id,
              firstName: newUserLoginDetails.firstName,
              memberIdBrandId: newUserLoginDetails.memberIdBrandId,
              images: newUserLoginDetails.images,
              lastName: newUserLoginDetails.lastName,
              role: newUserLoginDetails.role,
            };
            const loginInfo = JSON.stringify(Newdetails);
            localStorage.setItem("UserDetails", loginInfo);
            if (Newdetails.role === 1) {
              window.location.href = "/admindashboard";
            } else if (Newdetails.role === 2) {
              // if (key === null) {
              window.location.href = "/UserDetails";
              // }
              // else {
              //   if (key.page === "Dashboard") {
              //     window.location.href = "/UserDetails";
              //   } else if (key.page === "Voting") {
              //     window.location.href = `/productVoting/${key.from}/${key.date}/${key.name}/${key.question}/${key.categoryid}`
              //   }
              //   else if (key.page === "CelebrityVoting") {
              //     window.location.href = `/CelebrityVoting/${key.from}/${key.date}/${key.name}/${key.question}/${key.category}`
              //   } else {
              //     window.location.href = "/UserDetails";
              //   }
              // }
            } else if (newUserLoginDetails.role === 3) {
              window.location.href = "/BrandDetails";
            }

          } else {

            setLoading(false);
            Swal.fire({
              icon: "warning",
              title: "Invalid Login Details",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });

          }
        }

      }).catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      })
  }

    // const componentClicked = (res) => {
  //   console.log(res);
  // }
  return (
    <>

      {/* {
        !token ?
          <Header />
          :
          null

      } */}

    <div className='loginform'>
      <section className="login-bg">
        <div className="container">
          
          <div className="row">
            <Col md={5}>
              <Card className="mt-4 login-card " style={{ padding: "20px" }}>
              <div className='text-end'>
                    <Link to="/"> <IconButton size='large'><AiFillHome color='#CD0404' size="35px"/></IconButton></Link> 
                    </div>
                <div className="text-center m-0">
                  <Link to="/"><img
                    className="img-fluid "
                    src={logo1}
                    alt="logo-topbrands"
                    height="80px"
                    width="120px"
                  />
                  </Link>
                  <h1 className="m-0 text-center login-heading">LOGIN HERE!</h1>
                </div>

                <Form.Group
                  as={Row}
                  className="mb-4 mt-4"
                  controlId="formHorizontalEmail"
                >
                  <Col sm={12}>
                    <FormControl fullWidth>
                      <TextField
                        margin="normal"
                        required
                        variant="outlined"
                        id="email"
                        label="Enter Email"
                        name="email"
                        autoComplete="email"
                        onChange={ChangeEmail}
                        value={email}
                        color="error"
                      />

                    </FormControl>
                    {Object.keys(emailError).map((key) => {
                      return (
                        <div style={{ fontSize: 12, color: "red" }}>
                          {emailError[key]}
                        </div>
                      );
                    })}
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-4"
                  controlId="formHorizontalEmail"
                >
                  <Col sm={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="outlined-adornment-password"
                        color="error"
                        required
                      >
                        Password
                      </InputLabel>

                      <OutlinedInput
                        variant="outlined"
                        required
                        onChange={changePass}
                        value={password}
                        label="Password"
                        name="cpassword"
                        color="error"
                        type={cpassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}

                              edge="end"
                            >
                              {cpassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />

                    </FormControl>

                    {Object.keys(passError).map((key) => {
                      return (
                        <div style={{ fontSize: 12, color: "red" }}>
                          {passError[key]}
                        </div>
                      );
                    })}
                  </Col>
                </Form.Group>
                

                <button
                  type="submit"
                  
                  className="button-85 pt-2"
                  onClick={(e) => {
                    e.preventDefault();
                    login();
                  }}
                >
                  {
                    loading === true ?
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>


                      :
                      null
                  }
                  &nbsp;
                  <span>LOGIN</span>

                </button>
                <br/>
               
                <Form.Group>
                  <div className=" d-flex justify-content-center">
                  <div>
                  <GoogleLogin
                      
                      onSuccess={credentialResponse => {
                       googleLoginData(credentialResponse)
                        setGoogleLogin(credentialResponse);
                        
                        
                       
                      }}
                      onError={() => {
                        console.log('Login Failed');
                      }}
                      // width='250px'
                      logo_alignment='center'
                      className="g-login"
                      type= "icon"
                      shape="pill"
                    />
                  </div>
                    <div>
                    <FacebookLogin
                              appId="1131064557801831"
                              fields="name,email,picture"
                              // onClick={componentClicked}
                              callback={responseFacebook}
                              textButton=''
                              size='medium '
                              cssClass="btnFacebook"
                              icon={<BsFacebook fontSize="35px" color="#4C69BA" />}
                              
                            />
                    </div>
                  </div>
                </Form.Group>
                {/* <Form.Group
                as={Row}
                className="mb-4 text-center"
                controlId="formHorizontalEmail"
                >
                   

                </Form.Group> */}
                <Form.Group
                  as={Row}
                  className="mb-4"
                  controlId="formHorizontalEmail"
                >
                  <Col sm={12}>
                    <Link
                      type="submit"
                      
                      onClick={onForgotPass}
                      to="/"
                      className='fgt-psw'
                    >
                      Forgot Password?
                    </Link>
                    <Link
                      type="submit"
                      
                      to="/register"
                      style={{ float: "right" }}
                      className='fgt-psw'
                    >
                      Sign Up Here!
                    </Link>
                  </Col>
                    {/* <Col sm={4}>
                      <GoogleLogin
                        onSuccess={credentialResponse => {
                         googleLoginData(credentialResponse)
                          setGoogleLogin(credentialResponse);
                        }}
                        onError={() => {
                          console.log('Login Failed');
                        }}
                      />
                    </Col> */}
                  </Form.Group>
                </Card>
              </Col>
            </div>
            

          </div>
        </section>

        {/* <div>
          <Container>
          <div className="row justify-content-center">
              <Col className="justify-content-center" sm={5}>
                <Card className="mt-4 login-card " style={{ padding: "20px" }}>
                  <div className="text-center m-0">
                    <img
                      className="img-fluid "
                      src={logo1}
                      alt="logo-topbrands"
                      height="80px"
                      width="120px"
                    />
                    <h1 className="m-0 text-center login-heading">LOGIN</h1>
                  </div>

                  <Form.Group
                    as={Row}
                    className="mb-4 mt-4"
                    controlId="formHorizontalEmail"
                  >
                    <Col sm={12}>
                      <FormControl fullWidth>
                        <TextField
                          margin="normal"
                          required
                          variant="outlined"
                          id="email"
                          label="Enter Email"
                          name="email"
                          autoComplete="email"
                          onChange={ChangeEmail}
                          value={email}
                        />

                      </FormControl>
                      {Object.keys(emailError).map((key) => {
                        return (
                          <div style={{ fontSize: 12, color: "red" }}>
                            {emailError[key]}
                          </div>
                        );
                      })}
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-4"
                    controlId="formHorizontalEmail"
                  >
                    <Col sm={12}>
                      <FormControl fullWidth>
                        <InputLabel
                          htmlFor="outlined-adornment-password"
                          color="primary"
                          required
                        >
                          Password
                        </InputLabel>

                        <OutlinedInput
                          variant="outlined"
                          required
                          onChange={changePass}
                          value={password}
                          label="Password"
                          name="cpassword"
                          color="primary"
                          type={cpassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}

                                edge="end"
                              >
                                {cpassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />

                      </FormControl>

                      {Object.keys(passError).map((key) => {
                        return (
                          <div style={{ fontSize: 12, color: "red" }}>
                            {passError[key]}
                          </div>
                        );
                      })}
                    </Col>
                  </Form.Group>
                  <Button
                    type="submit"
                    variant="contained"
                    className=" mb-4"
                    onClick={(e) => {
                      e.preventDefault();
                      login();
                    }}
                  >
                    <b>LOGIN</b>
                  </Button>
                  <Form.Group
                    as={Row}
                    className="mb-4"
                    controlId="formHorizontalEmail"
                  >
                    <Col>
                      <Link
                        type="submit"
                        className=" text-decoration-none "
                        onClick={onForgotPass}
                        to="/"
                      >
                        Forgot your Password?
                      </Link>
                      <Link
                        type="submit"
                        className="text-decoration-none mr-2"
                        to="/register"
                        style={{ float: "right" }}
                      >
                        Sign Up Here!
                      </Link>
                    </Col>
                  </Form.Group>
                </Card>
              </Col>
            </div>
          </Container>
        </div> */}
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default LogIn;
