import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, FormControl, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import MaterialTable from "material-table";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { useLocation } from "react-router-dom";
import axios from "../../ServerCalls/AxiosInstance";
import ProductURL from "../../ServerCalls/ProductServer";
import getYouTubeID from "get-youtube-id";
import {
  PostpollingCampaign,
  CampaignLevelList,
  CampaignPhotos,
  campaignData,
  ProductbyCategories,
  Allbrands,
  Campaigngetbyid,
  EditpollingCampaign,
  deletePollingCampaign,
  getCountyData,
  Categorywithnoparent,
  NocountyList,
} from "./ProductServiceAPI";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import {
  Paper,
  TableCell,
  Table,
  TableBody,
  TextField,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  IconButton,
} from "@mui/material";
import { tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
const ProductCampaign = () => {
  const columns = [
    {
      title: "Campaign ShortName ",
      field: "campaignShortName",
      align: "center",
    },
    {
      title: "Campaign StartDate ",
      field: "campaignStartDate ",
      type: "date",
      align: "center",
      render: (rowdata) => {
        var d = new Date(rowdata.campaignStartDate);

        d.setDate(d.getDate() - 1);
        return <span>{moment(d).format("DD-MM-yyyy")}</span>;
      },
    },
    {
      title: "Campaign EndDate ",
      field: "campaignEndDate",
      type: "date",
      align: "center",
      render: (rowdata) => {
        var d = new Date(rowdata.campaignEndDate);

        d.setDate(d.getDate() - 1);
        return <span>{moment(d).format("DD-MM-yyyy")}</span>;
      },
    },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [campaign, setCampaign] = useState("");
  const [campaignID, setCampaignID] = useState(0);
  const [pollData, setPollData] = useState([]);
  const [formError, setFormError] = useState([]);
  const [childFormError, setChildFormError] = useState("");
  const [campstartDate, setCampstartDate] = useState(null);
  const [campendDate, setCampendDate] = useState(null);
  const [productSelected, setProductselected] = useState([]);
  const [campaignLevelSelected, setCampaignLevelSelected] = useState([]);
  const [campaignleveloption, setcampaignleveloption] = useState([]);
  const [countySelected, setCountySelected] = useState([]);
  const [countyOption, setCountyOption] = useState([]);
  const [campaignShortname, setCampaignShortname] = useState("");
  const [Question, setQuestion] = useState("");
  const [update, setupdate] = useState(false);
  const [countydisable, setCountydisable] = useState(true);
  const [campaignImg, setCampaignImg] = useState([]);
  const [campaignImgpath, setCampaignImgpath] = useState([]);
  const [Showlist, setShowlist] = useState(false);
  const [AdvertiseSelected, setAdvertiseSelected] = useState([]);
  const [AdvertiseImg, setAdvertiseImg] = useState([]);
  const [AdvertiseImgpath, setAdvertiseImgpath] = useState([]);
  const [selectBrand, setselectBrand] = useState([]);
  const [BrandOption, setBrandOption] = useState([]);
  const [selectVideo, setselectVideo] = useState([]);
  const [videoOption, setVideoOption] = useState([]);
  const [Link, setLink] = useState("");
  const [addChild, setaddChild] = useState([]);
  const [childID, setchildID] = useState(0);
  const [serviceupdate, setserviceupdate] = useState(false);
  const [childUpdate, setchildUpdate] = useState(false);
  const PhotoPath = ProductURL.photoURL + "CampaignImages/";
  const advertisePhoto =
    "https://media.topbrandsranking.com/CampaignAdvertiseImages/";

  const inputRefrence = useRef(null);
  const inputRefrence2 = useRef(null);

  const jumptoBack = useLocation();
  const validate = () => {
    let formError = {};
    let isValid = true;
    if (campaignShortname === "") {
      isValid = false;
      formError["campaignShortName"] = "Please Enter Campaign shortName";
    } else if (campaignShortname.length < 3) {
      isValid = false;
      formError["campaignShortName"] = "Please Enter Minimun 3 Characters.";
    } else if (campaignShortname.length > 200) {
      isValid = false;
      formError["campaignShortName"] = "Maximum 200 characters are allowed";
    }
    if (campaign === "") {
      isValid = false;
      formError["campaignDescription"] = "Please Enter campaign Description";
    }

    if (campaignLevelSelected.length < 1) {
      isValid = false;
      formError["campaignLevelSelected"] = "Please Select campaign level";
    }
    if (campstartDate === null) {
      isValid = false;
      formError["campstartDate"] = "Please Select Start Date";
    }
    if (campendDate === null) {
      isValid = false;
      formError["campendDate"] = "Please Select End Date";
    }
    if (campaignImg.length < 1) {
      isValid = false;
      formError["campaignImg"] = "Please Select Image";
    }
    if (Question === "") {
      isValid = false;
      formError["question"] = "Please Enter Question";
    } else if (Question.length > 5000) {
      isValid = false;
      formError["question"] = "Maximum 5000 characters are allowed";
    }
    setFormError(formError);
    return isValid;
  };
  const childValidate = () => {
    let childFormError = {};
    let isValid = true;

    if (selectBrand.length < 1) {
      isValid = false;
      childFormError["selectBrand"] = "Please Select Brand";
    }
    if (selectVideo.length < 1) {
      isValid = false;
      childFormError["selectVideo"] = "Please Select Video";
    }

    setChildFormError(childFormError);
    return isValid;
  };

  const image_upload = async (data) => {
    await CampaignPhotos(data)
      .then((res) => {
        console.log("photo res", res);
      })
      .catch((err) => {
        console.log(err, "err in photo submit");
      });
  };

  const advertise_image_upload = async (data) => {
    // await CampaignPhotos(data)
    await axios
      .post(
        "https://api.topbrandsranking.com/ProductManagement/PollingCampaignSaveAdvertise/SaveFile",
        data
      )
      .then((res) => {
        console.log("photo res", res);
      })
      .catch((err) => {
        console.log(err, "err in photo submit");
      });
  };

  const AdvertiseOption = [
    { value: 1, label: "Upload_Link" },
    { value: 2, label: "Upload_Video" },
    { value: 3, label: "Upload_Image" },
  ];

  const handleAdvertise = (selected) => {
    setAdvertiseSelected(selected);
  };

  const handleimage = (e) => {
    setCampaignImg(e.target.files[0]);
    setCampaignImgpath(URL.createObjectURL(e.target.files[0]));
    setFormError({ ...formError, campaignImg: "" });
  };

  const handleAdvertiseimage = (e) => {
    setAdvertiseImg(e.target.files[0]);
    setAdvertiseImgpath(URL.createObjectURL(e.target.files[0]));
    // setFormError({ ...formError, campaignImg: "" });
  };

  const handleLink = (e) => {
    setLink(e.target.value);
  };

  const getBrand = () => {
    Allbrands()
      .then((res) => {
        let data1 = res.data._response.data.getbrand;
        let options = data1.map((d) => ({
          value: d.brandId,
          label: d.brandName,
        }));

        setBrandOption(options);
      })
      .catch((err) => {
        console.log(err, "err in getbrands");
      });
  };

  const changeQuestion = (e) => {
    const { name, value } = e.target;
    setQuestion(value);
    setFormError({ ...formError, question: "" });
  };
  const cancelUser = () => {
    setFormError({
      campaignShortName: "",
      campaignDescription: "",
      campstartDate: "",
      campendDate: "",
      productSelected: "",
      procategoryselected: "",
      campaignLevelSelected: "",
      countySelected: "",
    });
    setCampstartDate(null);
    setCampendDate(null);
    setCampaignShortname("");
    // inputRefrence.current.value = null;
    // inputRefrence2.current.value = null;
    setCampaign("");
    setLink("");
    setAdvertiseSelected([]);
    setAdvertiseImgpath([]);
    setAdvertiseImg([]);
    setCampaignID(0);
    setQuestion("");
    setCampaignLevelSelected([]);
    setCountySelected([]);
    setupdate(false);
    setCampaignImgpath([]);
    setCampaignImg([]);
    setselectBrand([]);
    setselectVideo([]);
    setChildFormError("");
    setaddChild([]);
    setchildUpdate(false);
  };
  const handleInput = (e) => {
    setCampaign(
      e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@.,;'"" "]/gi, "")
    );
    setFormError({ ...formError, campaignDescription: "" });
  };
  const handleShortname = (e) => {
    setCampaignShortname(e.target.value);
    setFormError({ ...formError, campaignShortName: "" });
  };
  const handleBrand = (selected) => {
    setselectVideo([]);
    setselectBrand(selected);
    axios
      .get(
        `https://api.topbrandsranking.com/ProductManagement/VideoMasterGetByBrandId/${selected.value}`
      )
      .then((response) => {
        if (response.data._response.status === 1) {
          const list = response.data._response.data.getvideomaster;
          const option = list.map((a) => ({
            value: a.videoid,
            label: a.uploadvideo,
          }));
          setVideoOption(option);
        } else {
          setVideoOption([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setChildFormError({ ...childFormError, selectBrand: "" });
  };

  const handleVideo = (selected) => {
    setselectVideo(selected);
  };

  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
  };
  const saveData = async () => {
    debugger;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    const isvalid = validate();
    const childvalid = childValidate();
    if (isvalid === true && childvalid === true) {
      let county = [];
      countySelected &&
        Object.entries(countySelected).map(([k, v]) => county.push(v.value));
      const data = {
        CampaignShortName: campaignShortname,
        CampaignDescription: campaign,
        CampaignLevel: campaignLevelSelected.value,
        CampaignStartDate: fixTimezoneOffset(
          new Date(
            campstartDate.getFullYear(),
            campstartDate.getMonth(),
            campstartDate.getDate(),
            23,
            59,
            59
          )
        ),
        CampaignEndDate: fixTimezoneOffset(
          new Date(
            campendDate.getFullYear(),
            campendDate.getMonth(),
            campendDate.getDate(),
            23,
            59,
            59
          )
        ),
        countyid: county,
        Question: Question,
        campaignimage: campaignImg.name === "" ? "" : campaignImg.name,
        ParticipationDate: today,
        AdvertiseType: AdvertiseSelected.label,
        advertisesource:
          AdvertiseSelected.value === 1
            ? Link
            : AdvertiseImg.name === ""
            ? ""
            : AdvertiseImg.name,
        videoparticipation: addChild,
      };

      let formdata = new FormData();
      formdata.append("file", campaignImg);
      await image_upload(formdata);

      let formdata1 = new FormData();
      formdata1.append("file", AdvertiseImg);
      await advertise_image_upload(formdata1);
      // PostpollingCampaign(data)
      axios
        .post(
          "https://api.topbrandsranking.com/ProductManagement/VideoCampaignSave",
          data
        )
        .then((res) => {
          debugger;
          if (res.data._response.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Video Campaign Added Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
            getList();
            getList("");
            cancelUser();
          } else {
            Swal.fire({
              icon: "error",
              title: res.data._response.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    setShowlist(false);
    getList();
    getLevel();
    getBrand();
    getCounty();
  }, [jumptoBack.key]);
  const getList = () => {
    // campaignData()
    axios
      .get(
        "https://api.topbrandsranking.com/ProductManagement/GetVideoCampaign"
      )
      .then((res) => {
        setPollData(res.data._response.data.getvideocampaign);
      })
      .catch((err) => {
        console.log(err, "err in campaign getlist");
      });
  };

  const getLevel = () => {
    CampaignLevelList()
      .then((res) => {
        let list = res.data._response.data.getcampaignlevel;
        const options = list.map((a) => ({
          value: a.campaignLevelId,
          label: a.campaignLevelName,
        }));
        setcampaignleveloption(options);
      })
      .catch((err) => {
        console.log(err, "err in get campaignlevel");
      });
  };
  const getCounty = () => {
    NocountyList()
      .then((res) => {
        let list = res.data._response.data.getcountywithnocounty;
        const options = list.map((a) => ({
          value: a.countyId,
          label: a.countyName,
        }));
        setCountyOption(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateUser = (pollID) => {
    setShowlist(false);
    setCountydisable(true);
    // Campaigngetbyid(pollID)
    axios
      .get(
        `https://api.topbrandsranking.com/ProductManagement/VideoCampaignGetById/${pollID}`
      )
      .then((res) => {
        console.log(res, "get data");

        let result = res.data._response.data.getvideocampaign[0];
        let countylevel =
          res.data._response.data.getvideocampaign[0].campaignLevel;
        if (countylevel === 2) {
          setCountydisable(false);
        }
        let shortname =
          res.data._response.data.getvideocampaign[0].campaignShortName;
        let description =
          res.data._response.data.getvideocampaign[0].campaignDescription;
        let pollingCampaignStartDate = new Date(
          res.data._response.data.getvideocampaign[0].campaignStartDate
        );
        pollingCampaignStartDate.setDate(
          pollingCampaignStartDate.getDate() - 1
        );
        let pollingCampaignEndDate = new Date(
          res.data._response.data.getvideocampaign[0].campaignEndDate
        );
        pollingCampaignEndDate.setDate(pollingCampaignEndDate.getDate() - 1);
        let pollingCampaignLevel =
          res.data._response.data.getvideocampaign[0].campaignlevelname;
        let pollingCampaignLevelId =
          res.data._response.data.getvideocampaign[0].campaignLevel;

        let productId = res.data._response.data.productId;
        let countyId = res.data._response.data.countyid;
        let countyValues = [];
        let countyOption = countyId.map((a) =>
          countyValues.push({ value: a.countyid, label: a.countyname })
        );
        // let productValues = [];
        // let productOption = productId.map((b) =>
        //     productValues.push({ value: b.productid, label: b.productname })
        // );

        const childData = res.data._response.data.videoparticipation;
        setaddChild(childData);
        setCampaign(description);
        setCampaignShortname(shortname);
        setCampaignID(result.videocampaignId);
        setCampstartDate(pollingCampaignStartDate);
        setCampendDate(pollingCampaignEndDate);
        setCampaignLevelSelected({
          value: pollingCampaignLevelId,
          label: pollingCampaignLevel,
        });

        setCountySelected(countyValues);
        setQuestion(result.question);
        setCampaignImg(
          result.campaignimage === null ? [] : result.campaignimage
        );
        setAdvertiseSelected({
          value: result.advertisetypelabel,
          label: result.advertisetype,
        });
        setLink(result.advertisesource);
        setAdvertiseImg(
          result.advertisesource === null ? [] : result.advertisesource
        );
        setupdate(true);
      })
      .catch((err) => {
        console.log(err, "err in get campaign with id");
      });
  };
  const updateClick = async () => {
    debugger;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    const isvalid = validate();
    if (isvalid === true) {
      let product = [];
      productSelected &&
        Object.entries(productSelected).map(([a, b]) => product.push(b.value));
      let county = [];
      countySelected &&
        Object.entries(countySelected).map(([k, v]) => county.push(v.value));
      const data = {
        videocampaignid: campaignID,
        CampaignShortName: campaignShortname,
        CampaignDescription: campaign,
        CampaignLevel: campaignLevelSelected.value,
        CampaignStartDate: fixTimezoneOffset(
          new Date(
            campstartDate.getFullYear(),
            campstartDate.getMonth(),
            campstartDate.getDate(),
            23,
            59,
            59
          )
        ),
        CampaignEndDate: fixTimezoneOffset(
          new Date(
            campendDate.getFullYear(),
            campendDate.getMonth(),
            campendDate.getDate(),
            23,
            59,
            59
          )
        ),
        countyid: county,
        Question: Question,
        campaignimage:
          campaignImg.name === undefined ? campaignImg : campaignImg.name,
        ParticipationDate: today,
        AdvertiseType: AdvertiseSelected.label,
        advertisesource:
          AdvertiseSelected.value === 1
            ? Link
            : AdvertiseImg.name === undefined
            ? AdvertiseImg
            : AdvertiseImg.name,
        videoparticipation: addChild,
      };

      let formdata = new FormData();
      formdata.append("file", campaignImg);
      await image_upload(formdata);

      let formdata1 = new FormData();
      formdata1.append("file", AdvertiseImg);
      await advertise_image_upload(formdata1);

      // EditpollingCampaign(data)
      axios
        .put(
          "https://api.topbrandsranking.com/ProductManagement/EditVideoCampaign",
          data
        )
        .then((response) => {
          if (response.data._response.status === 1) {
            Swal.fire({
              icon: "success",
              title: response.data._response.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            getList();
            cancelUser();
            setupdate(false);
          } else {
            Swal.fire({
              icon: "error",
              title: response.data._response.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // Swal.fire({
          //   icon: "error",
          //   title: "Campaign  Not Added",
          //   showConfirmButton: true,
          //   confirmButtonColor: "#3085d6",
          //   // cancelButtonColor: '#d33',
          //   confirmButtonText: "ok",
          // });
        });
    }
  };
  function deleteUser(pollingCampaignId) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          // deletePollingCampaign(pollingCampaignId)
          axios
            .delete(
              `https://api.topbrandsranking.com/ProductManagement/RemoveVideoCampaign/${pollingCampaignId}`
            )
            .then((res) => {
              if (res.data._response.status === 1) {
                Swal.fire({
                  icon: "success",
                  title: "Video Campaign Deleted Sucessfully",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  // cancelButtonColor: '#d33',
                  confirmButtonText: "ok",
                });
                getList();
              } else if (res.data._response.status === 2) {
                Swal.fire({
                  icon: "warning",
                  title: res.data._response.message,
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  // cancelButtonColor: '#d33',
                  confirmButtonText: "ok",
                });
              }
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in trash product campaign");
      });
  }

  const handleLevel = (selected) => {
    setCampaignLevelSelected(selected);
    if (selected.value === 2) {
      setCountydisable(false);
    } else {
      setCountydisable(true);
    }
    setFormError({ ...formError, campaignLevelSelected: "" });
  };


  const addVideo = () => {
    let isvalid = childValidate();
    if (isvalid === true) {
      let result = [...addChild];


      let alreadyPresent = result.find(a =>

        a.brandid === selectBrand.value)

    if (alreadyPresent === undefined) {

      result.push({
        VideoCampaignParticipationID: 0,
        brandid: selectBrand.value,
        brandname: selectBrand.label,
        videoid: selectVideo.value,
        videoname: selectVideo.label,
        participationdate: new Date(),
      });

    } else {

        Swal.fire('This Brand Already Exist For This Campaign!', '', 'warning');

    }

    
      setaddChild(result);
    } else {
      console.log("err");
    }
    // ResetChild()
  };

  const editChildArea = (list, ind) => {
    // setLoading(true)

    setchildUpdate(true);

    setchildID(ind);
    setselectBrand({ value: list.brandid, label: list.brandname });
    setselectVideo({ value: list.videoid, label: list.videoname });
    // setLoading(false)
  };

  const editVideo = () => {
    // setserviceupdate(true)

    setchildUpdate(false);
    // ResetChild()

    let res = [...addChild];

    res[childID]["brandid"] = selectBrand.value;
    res[childID]["brandname"] = selectBrand.label;
    res[childID]["videoid"] = selectVideo.id;
    res[childID]["videoname"] = selectVideo.label;

    setaddChild(res);
  };

  const ResetChild = () => {
    setselectBrand([]);
    setselectVideo([]);
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <br />
            <br />
            <div className="row mb-2">
              {!Showlist ? (
                <div className="col-sm-12">
                  {!update ? (
                    <h1 className="m-0 text-center"> ADD VIDEO CAMPAIGN </h1>
                  ) : (
                    <h1 className="m-0 text-center"> EDIT VIDEO CAMPAIGN </h1>
                  )}
                </div>
              ) : (
                <div className="col-sm-12">
                  <h1 className="m-0 text-center"> VIDEO CAMPAIGN LIST </h1>{" "}
                </div>
              )}
            </div>
            <Col md={12}>
              <div className="d-flex justify-content-end">
                {!Showlist ? (
                  <button
                    type="button"
                    className="btn btn-pos3 "
                    onClick={() => {
                      setShowlist(true);
                      cancelUser();
                    }}
                  >
                    <i className="fa-solid fa-list"></i> List
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-pos4 "
                    onClick={() => setShowlist(false)}
                  >
                    <i className="fa fa-arrow-circle-left"></i> back
                  </button>
                )}
              </div>
            </Col>
          </div>
        </div>
        <section className="content  consti-section">
          <div className="container">
            {!Showlist ? (
              <>
                <div className="row">
                  <Card style={{ padding: "20px", height: "fit-content" }}>
                    <Row>
                      <Col sm={6}>
                        <Form.Label className="w-100">
                          Campaign Short Name{" "}
                        </Form.Label>
                        <input
                          className="w-100 form-control"
                          name="campaignShortName"
                          value={campaignShortname}
                          onChange={handleShortname}
                        ></input>{" "}
                        <div style={{ color: "red" }}>
                          {formError.campaignShortName}
                        </div>{" "}
                      </Col>{" "}
                      <Col sm={6}>
                        <Form.Label className="w-100">
                          {" "}
                          Campaign Description{" "}
                        </Form.Label>{" "}
                        <textarea
                          className="w-100 form-control"
                          placeholder="Tell us more"
                          rows="3"
                          onChange={handleInput}
                          name="campaignDescription"
                          value={campaign}
                        ></textarea>{" "}
                        <div style={{ color: "red" }}>
                          {formError.campaignDescription}
                        </div>{" "}
                      </Col>{" "}
                    </Row>
                    <Row>
                      <Col
                        sm={6}
                        style={{
                          position: "relative",
                          // zIndex: "999",
                        }}
                      >
                        <Form.Label className="w-100"> Start Date </Form.Label>{" "}
                        <DatePicker
                          className="w-100 form-control"
                          selected={campstartDate}
                          name="campaignStartDate"
                          value={campstartDate}
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            setCampstartDate(date);
                            setFormError({ ...formError, campstartDate: "" });
                          }}
                        />
                        <div style={{ color: "red" }}>
                          {formError.campstartDate}
                        </div>
                      </Col>
                      <Col
                        sm={6}
                        style={{
                          position: "relative",
                          // zIndex: "999",
                        }}
                      >
                        <Form.Label className="w-100"> End Date </Form.Label>{" "}
                        <DatePicker
                          className="w-100 form-control"
                          selected={campendDate}
                          name="campaignEndDate"
                          dateFormat="dd/MM/yyyy"
                          minDate={campstartDate}
                          // maxDate={new Date()}
                          showYearDropdown
                          yearDropdownItemNumber={50}
                          scrollableYearDropdown
                          onChange={(date) => {
                            setCampendDate(date);
                            setFormError({ ...formError, campendDate: "" });
                          }}
                        />{" "}
                        <div style={{ color: "red" }}>
                          {formError.campendDate}
                        </div>{" "}
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <Form.Label className="w-100">
                          {" "}
                          Polling Campaign Level{" "}
                        </Form.Label>{" "}
                        <Select
                          className="w-100"
                          options={campaignleveloption}
                          onChange={handleLevel}
                          value={campaignLevelSelected}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <br />
                        <div style={{ color: "red" }}>
                          {formError.campaignLevelSelected}
                        </div>{" "}
                      </Col>{" "}
                      <Col sm={6}>
                        <Form.Label className="w-100"> County </Form.Label>{" "}
                        <MultiSelect
                          className="w-100"
                          options={countyOption}
                          value={countySelected}
                          onChange={setCountySelected}
                          labelledBy="Select"
                          disabled={countydisable}
                          // menuPosition="fixed"
                          // menuShouldBlockScroll="true"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          // style={{overflowY: "scroll"}}
                        />
                        {/* <div style={{ color: "red" }}>{formError.countySelected}</div>{" "} */}
                      </Col>{" "}
                    </Row>

                    {/* <Row>
                                        <Col sm={6}>
                                            <Form.Label className="w-100">
                                                {" "}
                                                Select Brand{" "}
                                            </Form.Label>{" "}
                                            <Select
                                                className="w-100"
                                                options={categoryselectOption}
                                                onChange={handleCategory}
                                                value={procategoryselected}
                                                menuPortalTarget={document.body}
                                                isDisabled={update}
                                                styles={{
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                            <br />
                                            <div style={{ color: "red" }}>
                                                {formError.procategoryselected}
                                            </div>{" "}
                                        </Col>{" "}
                                      
                                        <Col sm={6}>
                                            <Form.Label className="w-100">
                                                {" "}
                                                Brand Video {" "}
                                            </Form.Label>{" "}
                                            <MultiSelect
                                                className="w-100"
                                                options={productselectOption}
                                                value={productSelected}
                                                onChange={handleProduct}
                                                labelledBy="Select"
                                                disabled={productDisable}
                                            />
                                            <br />
                                            <div style={{ color: "red" }}>
                                                {formError.productSelected}
                                            </div>
                                        </Col>{" "}
                                    </Row> */}
                    <Row>
                      <Col sm={6}>
                        <Form.Label className="w-100"> Question </Form.Label>{" "}
                        <input
                          className="w-100 form-control"
                          name="question"
                          type="text"
                          value={Question}
                          onChange={changeQuestion}
                        ></input>{" "}
                        <div style={{ color: "red" }}>{formError.question}</div>{" "}
                      </Col>{" "}
                      <Col sm={6}>
                        <Form.Label className="w-100">
                          Campaign Image{" "}
                        </Form.Label>{" "}
                        <FormControl
                          className="m-2"
                          type="file"
                          name="file"
                          ref={inputRefrence}
                          onChange={handleimage}
                        />
                        <div className="imagepreview">
                          <img
                            alt="Please select "
                            width="80"
                            height="60"
                            src={
                              update === false
                                ? campaignImgpath
                                : campaignImg.name === undefined
                                ? PhotoPath + campaignImg
                                : campaignImgpath
                            }
                          />
                        </div>
                        <br />
                        <div style={{ color: "red" }}>
                          {formError.campaignImg}
                        </div>
                      </Col>
                    </Row>

                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      controlId="formHorizontalEmail"
                    >
                      <Col
                        sm={6}
                        style={{
                          position: "relative",
                          // zIndex: "999",
                        }}
                      >
                        <Form.Label className="w-100">
                          {" "}
                          Advertise Type{" "}
                        </Form.Label>{" "}
                        <Select
                          className="w-100"
                          options={AdvertiseOption}
                          onChange={handleAdvertise}
                          value={AdvertiseSelected}
                          menuPortalTarget={document.body}
                          // isDisabled={update}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                      </Col>

                      <Col
                        sm={6}
                        style={{
                          position: "relative",
                          // zIndex: "999",
                        }}
                      >
                        {AdvertiseSelected.length ===
                        0 ? null : AdvertiseSelected.value === 1 ? (
                          <>
                            <Form.Label className="w-100">
                              {" "}
                              Copy Link
                            </Form.Label>
                            <input
                              className="w-100 form-control"
                              name="Link"
                              value={Link}
                              onChange={handleLink}
                            ></input>
                          </>
                        ) : (
                          <>
                            <Form.Label className="w-100">
                              {" "}
                              select Image/File
                            </Form.Label>
                            <FormControl
                              className="m-2"
                              type="file"
                              name="file"
                              ref={inputRefrence2}
                              onChange={handleAdvertiseimage}
                            />
                            <div className="imagepreview">
                              <img
                                alt="Please select "
                                width="80"
                                height="60"
                                src={
                                  update === false
                                    ? AdvertiseImgpath
                                    : AdvertiseImg.name === undefined
                                    ? advertisePhoto + AdvertiseImg
                                    : AdvertiseImgpath
                                }
                              />
                            </div>
                          </>
                        )}
                      </Col>
                    </Form.Group>
                  </Card>
                </div>
                <div className="text-center">
                  <h4 className="mb-4">ADD VIDEO FOR BRAND </h4>
                </div>
                <div className="container">
                  <div className="row">
                    <Paper
                      className="content  consti-section"
                      sx={{ boxShadow: 3 }}
                    >
                      <Card>
                        <Card.Body>
                          <Form>
                            <Row className="mb-3">
                              <Form.Group
                                className="col-sm-6 mb-2"
                                controlId="formGridState"
                              >
                                <Select
                                  className=""
                                  options={BrandOption}
                                  onChange={handleBrand}
                                  value={selectBrand}
                                  placeholder="Select Brand"
                                  menuPortalTarget={document.body}
                                  name="servicetype"
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  // isDisabled={serviceupdate}
                                />
                                <div style={{ color: "red" }}>
                                  {childFormError.selectBrand}
                                </div>
                              </Form.Group>

                              <Form.Group
                                className="col-sm-3 mb-2"
                                controlId="formGridState"
                              >
                                <Select
                                  className=""
                                  options={videoOption}
                                  onChange={handleVideo}
                                  value={selectVideo}
                                  placeholder="Select Video"
                                  menuPortalTarget={document.body}
                                  name="servicetype"
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  // isDisabled={serviceupdate}
                                />
                                <div style={{ color: "red" }}>
                                  {childFormError.selectVideo}
                                </div>
                              </Form.Group>
                              {/* <Form.Group className='col-sm-3 mb-2' controlId="formGridState">
                                                <Select
                                                    className=""
                                                    options={videoOption}
                                                     onChange={handleVideo}
                                                    value={selectVideo}
                                                    placeholder='Select Video'
                                                    menuPortalTarget={document.body}
                                                    name='servicetype'
                                                    styles={{
                                                        menuPortal: (base) => ({
                                                            ...base,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                               
                                                />
                                                  <div style={{ color: "red" }}>{childFormError.selectVideo}</div>
                                          </Form.Group> */}
                              <Form.Group
                                className="col-sm-3 mb-2"
                                controlId="formGridState"
                              >
                                <div className="imagepreview">
                                  <iframe
                                    className="video"
                                    title="Youtube player"
                                    allowfullscreen="allowfullscreen"
                                    width="230"
                                    height="100"
                                    sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                                    src={`https://youtube.com/embed/${getYouTubeID(
                                      selectVideo.label
                                    )}?autoplay=0`}
                                  />
                                </div>
                              </Form.Group>
                            </Row>
                          </Form>
                        </Card.Body>
                      </Card>
                      <div className="button_style  m-0 text-center mt-5">
                        {childUpdate === false ? (
                          <Button
                            variant="contained"
                            title="add service"
                            onClick={addVideo}
                          >
                            + Add Video
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            title="Update service"
                            onClick={editVideo}
                          >
                            update
                          </Button>
                        )}
                      </div>
                    </Paper>
                  </div>
                </div>
                <Container className="mt-4 mb-4">
                  <Paper sx={{ overflowX: "auto" }}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Sr. No</StyledTableCell>
                            <StyledTableCell>Brand Name</StyledTableCell>
                            <StyledTableCell>Brand Video Name</StyledTableCell>
                            <StyledTableCell>Brand Video</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>

                            {/* <StyledTableCell align="right" >Actions</StyledTableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {addChild &&
                            addChild.map((data, ind) => (
                              <StyledTableRow>
                                <TableCell> {ind + 1}</TableCell>
                                <TableCell> {data.brandname}</TableCell>
                                <TableCell>{data.videoname} </TableCell>
                                <TableCell>
                                  <iframe
                                    className="video"
                                    title="Youtube player"
                                    allowfullscreen
                                    width="150"
                                    height="80"
                                    sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                                    // autoplay="1"
                                    // allow="autoplay"
                                    //   src={advertisePhoto + data.videoname}
                                    src={`https://youtube.com/embed/${getYouTubeID(
                                      data.videoname
                                    )}?autoplay=0`}
                                  ></iframe>
                                </TableCell>

                                <TableCell align="right">
                                  <Button
                                    onClick={() => editChildArea(data, ind)}
                                  >
                                    <EditIcon
                                      color="success"
                                      titleAccess="Edit"
                                    />
                                  </Button>{" "}
                                  {/* <Button onClick={() => trashService(ind, data.acsid)}><DeleteIcon titleAccess='Delete' color='error' /></Button>{" "} */}
                                </TableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Container>
                <div className="button_style m-0 text-center mt-5">
                  {update === false ? (
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={saveData}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => updateClick()}
                    >
                      Update
                    </Button>
                  )}{" "}
                  <Button
                    variant="contained"
                    color="error"
                    onClick={cancelUser}
                  >
                    Cancel
                  </Button>
                </div>{" "}
              </>
            ) : (
              <>
                <br />
                <br />
                <br />
                <Col className="mt-0" md={12}>
                  <MaterialTable
                    title={false}
                    data={pollData}
                    columns={columns}
                    actions={[
                      {
                        icon: "edit",
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                          console.log(rowData, "jjj");
                          // setFormError("")
                          updateUser(rowData.videocampaignId);
                        },
                      },
                      {
                        icon: "delete",
                        tooltip: "Delete",
                        onClick: (event, rowData) => {
                          deleteUser(rowData.videocampaignId);
                        },
                      },
                    ]}
                    options={{
                      search: true,
                      pageSize: 10,
                      showFirstLastPageButtons: false,
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      pageSizeOptions: [10, 15, 20, 50],
                    }}
                  />
                </Col>
              </>
            )}
          </div>{" "}
        </section>{" "}
      </div>{" "}
      <br /> <br />
    </div>
  );
};
export default ProductCampaign;
