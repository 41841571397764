export const ADD_FIRST_FORM = 'ADD_FIRST_FORM';
export const Add_gender = 'Add_gender';
export const Add_age = "Add_age";
export const Add_image = "Add_image";
export const Add_county = "Add_county";
export const Add_consti = "Add_consti";
export const Add_ward = "Add_ward";
export const Add_occupation = "Add_occupation";
export const Add_education = "Add_education";
export const Add_imagepath = "Add_imagepath";
export const CLEAR_FORM = "CLEAR_FORM"
export const AddFirstForm = (data) => {
    return dispatch => {
        dispatch({
            type: ADD_FIRST_FORM,
            payload: data
        })
    };
};

export const Addgender = (data) => {
    return dispatch => {
        dispatch({
            type: Add_gender,
            payload: data
        })
    };
};

export const Addage = (data) => {
    return dispatch => {
        dispatch({
            type: Add_age,
            payload: data
        })
    };
};

export const Addimage = (data) => {
    return dispatch => {
        dispatch({
            type: Add_image,
            payload: data
        })
    };
};

export const Addimagepath = (data) => {
    return dispatch => {
        dispatch({
            type: Add_imagepath,
            payload:data
        })
    };
};
export const Addcounty = (data) => {
    return dispatch => {
        dispatch({
            type: Add_county,
            payload: data
        })
    };
};
export const Addconsti = (data) => {
    return dispatch => {
        dispatch({
            type: Add_consti,
            payload: data
        })
    };
};
export const Addward = (data) => {
    return dispatch => {
        dispatch({
            type: Add_ward,
            payload: data
        })
    };
};
export const Addoccupation = (data) => {
    return dispatch => {
        dispatch({
            type: Add_occupation,
            payload: data
        })
    };
};
export const Addeducation = (data) => {
    return dispatch => {
        dispatch({
            type: Add_education,
            payload: data
        })
    };
};
export const ClearForm = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_FORM,
        })
    };
};