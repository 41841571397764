export const ProductURL = {

  RegionService: "https://api.topbrandsranking.com/RegionManagement", //live url for regionService

  ProductUrl: "https://api.topbrandsranking.com/ProductManagement", //live productService URL
  // ProductUrl: "https://localhost:5002/api/ProductManagement", //local productService URL


  //ProductNOTIUrl: "http://5.189.157.40:10049/Api/ProductManagement",
  ProductNOTIUrl: "https://api.topbrandsranking.com/ProductManagement",

  userPhoto: "https://api.topbrandsranking.com/ProductManagement/MemberMaster",
  photoURL: 'https://media.topbrandsranking.com/', //live photo url,
  //photoURL:"https://localhost:5002/" ,//local photo url

  productNewsPhoto: "https://api.topbrandsranking.com/ProductManagement/ProductNews",
  GeneralNewsPhoto: "https://api.topbrandsranking.com/ProductManagement/GeneralNews",
  CampaignPhoto: "https://api.topbrandsranking.com/ProductManagement/PollingCampaignSave",
  celebrityCampaignPhoto: "https://api.topbrandsranking.com/ProductManagement/CelebrityCampaign",
  testURl:'https://api.topbrandsranking.com/ProductManagement/Testing'
  // magazineurl:"https://localhost:5002/"

}

export default ProductURL;
