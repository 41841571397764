
import React,{useEffect, useState} from 'react'
import {Row,Col,Card,Form} from 'react-bootstrap';
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import {CampaignLevelList,createCampaignLevel,updateLevelData,trashLevel} from './ProductServiceAPI'
import Button from '@mui/material/Button';


const Productcampaign = () => {

    const [campaignLevel, setcampaignLevel] = useState('');
    const [campLevelId,setcampLevelId] = useState(0);
    const [CampaignData, setCampaignData] = useState([]);
    const [update, setUpdate] = useState(false);


    const [levelError, setlevelError] = useState('');

    const columns = [

        { title: "Campaign Level", field: "campaignLevelName", align: "center" },

      ];

    const handleLevel = (e)=>{
        setcampaignLevel(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setlevelError('')
    }

    useEffect(()=>{
        getlist();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getlist = () =>{
        CampaignLevelList()
        .then((res)=>{
            const data = res.data._response.data.getcampaignlevel
            setCampaignData(data)
        })
        .catch((err)=>{
            console.log(err,"error in getlist");
        })
    }

    const formValidation = ()=>{
        let levelError = '';
        let regex =/^[a-zA-Z0-9_.-]*$/;
        console.log(regex.test(campaignLevel));
        if (campaignLevel === '') {
            levelError = "Please fill the Details";
        }else if(campaignLevel.length < 3){
            levelError = 'Please Enter Minimun 3 Characters and do not use special Character'
        } else if(campaignLevel.length > 50)
        {
          levelError = "Maximum 50 characters are allowed"
        }
        if(levelError){
            setlevelError(levelError)
            return false;
        }
        return true;
    }

    const saveData = ()=>{
        const isValid = formValidation();
        if(isValid){
            const data = {
                campaignLevelName:campaignLevel
            }

            createCampaignLevel(data)
            .then((res)=>{
                if(res.data._response.status === 1){
                    Swal.fire({
                        icon: "Campaign Level Added Succesfully",
                        title: res.data._response.message,
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });

                    getlist();
                    ClearData();
                }
                else if(res.data._response.status === 2){
                    Swal.fire({
                        icon: "Campaign Level Not Added",
                        title: res.data._response.message,
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });
                }
                
            })
            .catch((err)=>{
                console.log(err);
            })

        }
    }

    const singleCampaignData = (f)=>{

        setUpdate(true)
        setcampLevelId(f.campaignLevelId)
        setcampaignLevel(f.campaignLevelName)

    }

    const handleUpdate = () => {
        const isValid = formValidation();
        if(isValid){
            const data = {
                campaignLevelId:campLevelId,
                campaignLevelName:campaignLevel
            }
            updateLevelData(data)
            .then((res)=>{
                if(res.data._response.status === 1){

                    Swal.fire({
                        icon: "success",
                        title: "Campaign Level Updated Sucessfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });
                    getlist();
                    ClearData();
                }

            })
            .catch((err)=>{
                console.log(err,"error in update");
            })
        }
    }

    const trashuser = (f)=>{

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result)=>{

            if(result.isConfirmed){

                trashLevel(f.campaignLevelId)
                .then((res)=>{
                    if(res.status === 200){
        
                        Swal.fire({
                            icon: "success",
                            title: "Campaign Level Deleted Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        getlist();
        
                    }
                })
                .catch((err)=>{       
                    console.log(err,"error in trash");
        
                })
            }
        })
        .catch((err)=>{
            console.log(err,"error in trashData");
        })
      

    }

    const ClearData = () =>{
        setcampLevelId(0);
        setUpdate(false);
        setcampaignLevel('');
        setlevelError('');

    }

    return (

        <>
            <div>
                <div>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                            <br/><br/>
                                <div className="row ">
                                    <div className="col-sm-12">
                                    {!update ? (
                                        <h1 className="m-0 text-center" >PRODUCT CAMPAIGN LEVEL</h1>
                                    ):
                                        <h1 className="m-0 text-center" >EDIT PRODUCT CAMPAIGN LEVEL</h1>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="content consti-section">
                            <div className="container">
                                <div className="row">
                                    <Col md={12} >
                                        <Card className='mt-4' style={{ padding: "20px"}}>
                                            <Form.Group as={Row} className="justify-content-center" controlId="formHorizontalEmail">
                                                <Col sm={12} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">Campaign Level</Form.Label>
                                                    <input className="w-100 form-control" type="text" placeholder='Campaign Level' value={campaignLevel} onChange={handleLevel} />
                                                    <div style={{ fontSize: 13, color: "red" }}>{levelError}</div>
                                                </Col>                                               
                                            </Form.Group>
                                            <div className="button_style m-0 text-center">
                                    {update === false ?
                                                <Button
                                                    type="button"
                                                    variant='contained'
                                                    onClick={saveData}
                                                >
                                                    Submit
                                                </Button>
                                                :
                                                <Button
                                                    type="button"
                                                    variant='contained'
                                                    color='success'
                                                    onClick={handleUpdate}
                                                >
                                                    Update
                                                </Button> }
                                                {" "} {" "}


                                                <Button
                                                    type="button"
                                                    variant='contained'
                                                    color='error'
                                                    onClick={ClearData}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Card>
                                    </Col>

                                    <Col className='mt-4' md={12} >
                                    <MaterialTable
                                        title=''
                                           data={CampaignData}
                                        columns={columns}
                                        actions={[
                                            {
                                                icon: "edit",
                                                tooltip: "Edit",
                                                  onClick: (event, rowData) => {
                    
                                                    singleCampaignData(rowData);
                                                    
                                                  },
                                            },
                                            {
                                                icon: "delete",
                                                tooltip: "Delete",
                                                  onClick: (event, rowData) => {

                                                    trashuser(rowData);
                                                  },
                                            },
                                        ]}
                                        options={{
                                            search: true,
                                            pageSize: 5,
                                            showFirstLastPageButtons: false,
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                        }}
                                    />
                                    </Col>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Productcampaign