import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import {store} from './redux/store/index'
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
  
{/* <GoogleOAuthProvider clientId="1041623456314-svm0pd4b9rm4d03h10vliav3qnuoancm.apps.googleusercontent.com"> */}
  <GoogleOAuthProvider clientId='509926061683-lt4hs98vmepgfcutn04ebvlois2l6goo.apps.googleusercontent.com'>

   <App />
  </GoogleOAuthProvider>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
