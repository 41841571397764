import React, { useEffect, useState } from 'react'
import MaterialTable from "material-table";
import { useLocation, useNavigate } from "react-router-dom";
import { votingCount } from '../ProductServiceAPI';
import { Col } from 'react-bootstrap';

export default function ViewDetails() {

    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const location = useLocation();
    const camandproid = location.state;

    const columns = [
        { title: "First Name", field: "firstname", align: "center" },
        { title: "Last Name", field: "lastname", align: "center" }

    ];


    useEffect(() => {
        allData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const allData = () => {
       
         votingCount(camandproid.productid,camandproid.campagineid)
            .then((res) => {
                if (res.data._response.status === 1) {

                    setData(res.data._response.data.citizenVotingGetByCitizenId)
                }
            }).catch((err)=>{
                console.log(err,"err in get all voting details");
            })


    }


    const backtoform = () => {
        navigate("/votingReport");
    }

    return (
        <div>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <h1 className="m-0 text-center">Voting Details</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content  consti-section">
                        <div className="container-fluid">
                            <div className="row">
                                <Col className='mt-2' md={{ span: 8, offset: 2 }}>
                                    <MaterialTable
                                        title={false}
                                        data={data}
                                        columns={columns}
                                        options={{
                                            search: false,
                                            pageSize: 5,
                                            showFirstLastPageButtons: false,
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                        }}
                                    />
                                </Col>
                            </div>
                            <button
                                type="button"
                                className="btn btn-pos2 m-4"
                                onClick={backtoform}
                            >
                                <i className="fa-solid fa-circle-left"></i>&nbsp;Back
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </div>


    )
}
