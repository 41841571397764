import axios from 'axios';

import URlFile from '../../components/ServerCalls/ProductServer'
import swal from 'sweetalert';
import Auth from './Auth';

let headers = {};

const axiosInstance = axios.create({
    baseURL: URlFile.ProductUrl,
    headers
});

axiosInstance.interceptors.request.use(
    async (config) => {

        const token = await Auth.getToken();
        if (token) {
            config.headers = {
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use((response) => {
    return response;
},
async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = await refreshAccessToken();
        localStorage.setItem('token', access_token);
        axiosInstance.defaults.headers.common['Authorization'] = access_token;
        return axiosInstance.request(originalRequest);
    }
    return Promise.reject(error);
});

export default axiosInstance;


const refreshAccessToken = async () => {
    // const user = JSON.parse(localStorage.getItem('user_data'))
    // const refreshToken = user.tokenDetails.refreshToken;

    // let body = JSON.stringify({
    //     token: refreshToken
    // });
    let headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return new Promise((resolve, reject) => {

        axiosInstance.post(`https://api.topbrandsranking.com/Authorization/refresh-token`, headers)
            .then(async response => {
                resolve(response.data._response.token);
            })
            .catch(error => {
                swal({
                    title: "Session expired",
                    text: "You have been logged out",
                    icon: "error",
                    button: "Ok",
                }).then(() => {
                    Auth.logout()
                    window.location.replace('/login')
                });
                reject(error);
            });
    });
}