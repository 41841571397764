import "../product/ProductDetails.css";
import React, { useState, useEffect } from "react";
import ProductURL from "../../ServerCalls/ProductServer";
import { Container, Card, Row, Col, Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Auth from "../../ServerCalls/Auth";
import Swal from "sweetalert2";
import { ProductListgetById, addFavourite, EditProductFavourite, getProFavBycitizenidProductId, feedbackOfProductwithPagination } from "../../pages/Admin/ProductServiceAPI";
import ProductComments from '../../pages/Citizen/ProductComments'
import Loader from "../Loader";
import { Box } from '@material-ui/core'
import { Pagination } from "@mui/material";
import forbidden from '../../../assets/img/forbidden.png';
const PhotoPath = ProductURL.photoURL + "ProductImages/";


const ProductDetails = () => {
  const { id } = useParams();
  const productId = Number(id);
  const [proName, setproName] = useState([]);
  const [proBrand, setproBrand] = useState([]);
  const [proCategory, setproCategory] = useState([]);
  const [proImage1, setproImage1] = useState([]);
  const [proImage2, setproImage2] = useState([]);
  const [proImage3, setproImage3] = useState([]);
  const [proImage4, setproImage4] = useState([]);
  const [proImage5, setproImage5] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryid, setCategoryid] = useState(0);
  const [parameters, setParameters] = useState([]);
  const [showB, setShowB] = useState(false);
  const [previewImg, setPreviewImg] = useState('');
  const [pageC, setPageC] = useState(2)
  const [pageNumber, setPageNumber] = useState(1);
  const dataPerPage = 2;
  const [FavorUnfav, setFavorUnfav] = useState([]);
  const [brandID, setBrandID] = useState(0);
  const navigate = useNavigate();
  const auth = Auth.getUserIdF();

  const AddFavourites = () => {
    var today = new Date();
    var current_hour =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const data = {
      ProductId: productId,
      memberId: auth,
      Active: 1,
      FavDateTime: current_hour,
    };

    addFavourite(data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Add to Favourite",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ok",
        });
        getFavAndunfav()

      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProductDetails();
    setPageNumber(1)
    Allfeedback(1,2);
    getFavAndunfav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, categoryid]);

  const Allfeedback = (a,b) => {
    setLoading(true);
    feedbackOfProductwithPagination(productId,a,b).then((res) => {
      let data = res.data._response.data.productRecognitionproductgetbyId
      let count = res.data._response.data.count;
      let APIpagecount = Math.ceil(count / dataPerPage);
      setFeedback(data);
      setLoading(false);
      setPageC(APIpagecount);
    }).catch((err) => {
      console.log(err, "err in get feddback")
      setLoading(false);

    })
  }

  const openModal = (imgurl) => {
    setPreviewImg(imgurl)
    setShowB(true);

  };

  const handleClose = () => {
    setShowB(false);
    setPreviewImg('')

  };


  const getProductDetails = () => {
    ProductListgetById(productId).then((res) => {
      let data = res.data._response.data.productgetid;
      let data1 = res.data._response.data.productparameterdetails;
      let parametersArr = data1 && data1.map((x) => ({
        value: x.parametervalue,
        name: x.parametername
      }))
      setParameters(parametersArr);
      setproName(data[0].productname);
      setproBrand(data[0].brandname);
      setproCategory(data[0].categoryname);
      setproImage1(data[0].productimage1);
      setproImage2(data[0].productimage2);
      setproImage3(data[0].productimage3);
      setproImage4(data[0].productimage4);
      setproImage5(data[0].productimage5);
      setCategoryid(data[0].categoryid);
      setBrandID(data[0].brandid)
    }).catch((err) => {
      console.log(err, "err in product get")
    })
  };
  const getFavAndunfav = (Edit) => {
    getProFavBycitizenidProductId(productId, auth)
      .then((res) => {

        let productFavid = res.data._response.data.productfavouritesgetcitizenidproductid;
        // .productFavourtiesId
        setFavorUnfav(productFavid);
        if (Edit === "Edit") {
          EditFavandUnFav(productFavid[0].productFavourtiesId);
        }
      }).catch((err) => {
        console.log(err, "err in get")
      })
  }

  const EditFavandUnFav = (productFavid) => {
    var today = new Date();
    var current_hour = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    const unfav = {
      productFavourtiesId: productFavid,
      productId: productId,
      memberId: auth,
      Active: 0,
      FavDateTime: current_hour
    };
    EditProductFavourite(unfav)
      .then((res) => {
        getFavAndunfav();
        Swal.fire({
          icon: "error",
          title: "Removed from Favorites",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ok",
        })
      }).catch((err) => {
        console.log("err in edit", err)
      })
  }

  const changePage = (e, a) => {
    setPageNumber(a);
    Allfeedback(a,dataPerPage);
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <br /><br />
            <div className="row mb-2">
              <div className="col-sm-12">
                <h1 className="m-0 text-center">Product Details</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='p-4'>
        <Container fluid className='p-4'>
          <Card>

              <Row className="text-center">
                <Col sm={6} className='border-end '>
                  <div className="d-flex flex-column justify-content-center ">
                    <div className="main_image  mt-2 p-2">
                      <img
                        src={PhotoPath + proImage1}
                        id="main_product_image"
                        width="400"
                        alt="products"
                        className="img-fluid"
                      />
                    </div>
                    <div className="thumbnail_images p-2">
                      <ul id="thumbnail">
                        <li>
                          <img
                            src={PhotoPath + proImage2}
                            width="70" height="70"
                            alt="products"
                            onClick={() => openModal(PhotoPath + proImage2)}
                            className="img-fluid"
                          />
                        </li>
                        <li>
                          <img
                            src={PhotoPath + proImage3}
                            width="70" height="70"
                            alt="products"
                            onClick={() => openModal(PhotoPath + proImage3)}
                            className="img-fluid"
                          />
                        </li>
                        <li>
                          <img
                            src={PhotoPath + proImage4}
                            width="70" height="70"
                            alt="products"
                            onClick={() => openModal(PhotoPath + proImage4)}
                            className="img-fluid"
                          />
                        </li>
                        <li>
                          <img
                            src={PhotoPath + proImage5}
                            width="70" height="70"
                            alt="products"
                            onClick={() => openModal(PhotoPath + proImage5)}
                            className="img-fluid"
                          />
                        </li>
                        {/* <li><img onclick="changeImage(this)" src="https://i.imgur.com/6ZufmNS.jpg" width="70" /></li> */}
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm={6} >
                  <div className="p-3 text-start">
                    {/* <div className="p-0 text-start">
                      <h3 className="prod-heading ">Name: {proName}</h3>
                    </div> */}
                    <br/>
                    <div className="mt-2 content">
                      <h5 className="content-name" style={{ fontSize: '2rem', fontWeight: '700' }}> {proName}</h5>
                    </div>
                    <div className="mt-2 content">
                      <h5 className="content-name1" style={{ fontSize: '1.2rem', fontWeight: '600' }}>Brand: {proBrand}</h5>
                    </div>
                    <br />
                    {/* <h6 className="text-muted" style={{fontSize:'1.5rem'}}>
                    Category: {proCategory}
                  </h6> */}
                    <Row className="text-center">
                      <Col sm={12}>
                        <Row>
                          
                          <Col>
                          <div className="d-flex justify-content-start">
                          <div className="text-muted m-0 text-start pdt-details" style={{ fontSize: '1.2rem' }}>
                              Category
                            </div>
                            <div className="m-0 text-start text-muted pdt-details" style={{ fontSize: '1.2rem' }}>
                              {/* <p style={{ fontSize: '1.5rem' }}>:</p>  */}
                              <span className="mx-2">:</span> 
                              {proCategory}
                            </div>
                          </div>
                          </Col>

                          {/* <Col sm={4}>
                            <div className="text-muted text-start" style={{ fontSize: '1.5rem' }}>
                              {proCategory}
                            </div>
                          </Col> */}

                        </Row>
                      </Col>
                    </Row>
                    {parameters && parameters.map((f) => {
                      {
                        if(f.value !== ""){
                          return (
                            <>
                              {/* <h6 className="text-muted" style={{fontSize:'1.5rem'}}>{f.name} : {f.value}</h6> */}
                              <Row>

                                <Col sm={12}>

                                  <Row>

                                    <Col sm={4}>

                                      <div className="text-muted m-0 text-start pdt-details" style={{ fontSize: '1.5rem' }}>

                                        {f.name}

                                      </div>

                                    </Col>

                                    {/* <Col sm={4}>
    
                                      <div className="m-0 text-start">
    
                                        <p style={{ fontSize: '1.5rem' }}>:</p>
    
                                      </div>
    
                                    </Col> */}

                                    <Col sm={8}>

                                      <div className="text-muted m-0 text-start pdt-details" style={{ fontSize: '1.5rem' }}>

                                      <span className="mx-2">:</span> {f.value}

                                      </div>

                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          )
                        }
                      }                    
                    }
                    )}
                    {/* <div className="ratings flex-row align-items-center"> */}
                    {/* <div className="flex-row">
                      <BsStarFill />
                      <BsStarFill />
                      <BsStarFill />
                      <BsStarFill />
                      <BsStarHalf />
                    </div> */}
                    {/* <span>441 reviews</span> */}
                    {/* </div> */}
                    <div className="text-start">
                      <button
                        className="btn btn-danger ml-2 mt-4"
                        onClick={() => navigate(-1)}
                      >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;
                        Back
                      </button>
                      {/* <button
                        className="btn btn-danger ml-2 mt-4"
                        onClick={() => {
                          console.log(categoryid,'cat',brandID,'br');
                          // navigate(-1,{state:{cat:categoryid,brand:brandID}})}}
                          navigate(-1,{state:"fvdzcv",replace:true},)
                        }}

                      >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;
                        Back
                      </button> */}
                      {FavorUnfav.length === 0 || FavorUnfav[0].active === 0 ? (
                        <Button
                          className="btn btn-danger fd"
                          onClick={() => AddFavourites(productId)}
                          title="Add to Favorites"
                        >
                          <i className="fa fa-heart" aria-hidden="true"></i>
                        </Button>
                      ) : (
                        <Button
                          className="btn btn-success fd"
                          onClick={() => getFavAndunfav("Edit")}
                          title="Remove From Favorites"
                        >
                          <i className="fa fa-heart" aria-hidden="true"></i>
                        </Button>
                      )}
                    </div>
                    <br /><br />
                  </div>
                </Col>
              </Row>
          
          </Card>
        </Container>
        </div>
        <h3 className="mb-5">Comments</h3>

        {

          loading ?
            <>
              <Loader />

            </>

            :

            feedback.length !== 0 ?
              feedback.map((data) => (
                <>
                  <div>
                    <ProductComments
                      productcomm={data}
                    />

                  </div>
                </>

              ))
              :
              <>
                <div className="container mb-5 mt-5">
                  <div className="feedback-card">
                    {/* <div className='m-0 text-center'>
                      <img className='img-fluid' alt="" src={forbidden} />
                    </div> */}
                    <h2 className='m-0 text-center' style={{ fontWeight: '600', color: 'grey' }}>No Comments Yet</h2>
                  </div>
                </div>

              </>
        }

        <br /><br />
        {
          feedback.length !== 0 ?
            <Box display="flex" alignItems="center" justifyContent="center">
              <Pagination
                count={pageC}
                page={pageNumber}
                onChange={changePage}
                shape="rounded" color="primary" size="large"
              />
            </Box>
            : null
        }
      </div>

      <Modal
        show={showB}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        centered
      >
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="mx-auto">
            Images
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <Link to="https://web.whatsapp.com/"> */}
          <Row className="text-center">
            <Col>
              <img
              className="img-fluid"
                src={previewImg}
                width="500" height="500"
                alt="products"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ProductDetails;
