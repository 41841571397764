import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Card, Container } from "react-bootstrap";
import Select from "react-select";
import Button from '@mui/material/Button';
import { getCelebrityCampaign,getCelebrityReport,getcelebrityReportByCounty} from './CelebrityServiceAPI'
import Swal from "sweetalert2";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Loader from '../../../common/Loader'
import { useReactToPrint } from 'react-to-print'
import axios from "../../../ServerCalls/AxiosInstance";
import Chart from 'react-apexcharts';
import { getGenderReport } from '../ProductServiceAPI';
import NotFound from '../../../common/NotFound';


const CelebrityCountyReport = () => {

  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [Countydata, setCountydata] = useState([]);
  const [CountyTotal, setCountyTotal] = useState([]);
  const [Total, setTotal] = useState([]);



  const [loading, setloading] = useState(false);


  const useStyles = makeStyles({

    table: {


        "& .MuiTableCell-root": {

            border: "1px solid rgba(0, 0, 0, 0.3)"

        }

    }

});

const classes = useStyles();


const componentRef = useRef();

const handlePrint = useReactToPrint({
    content: () => componentRef.current
})



const handleCampaign = (selected) => {
    setSelectedCampaign(selected)
}


const clear = () => {

    setSelectedCampaign('')

}



useEffect(() => {

    campaignList();
    if(selectedCampaign != ''){

        reports();
      
    }
}, [selectedCampaign])


const campaignList = () => {
    getCelebrityCampaign().then((res) => {

        let arr = [];
        let list = res.data._response.data.getcelebritycampaign;

        list.map((a) => (
            arr.push({
                label: a.campaignShortName,
                value: a.campaignId,
            })
        ))
        setCampaignOptions(arr)
    })
    .catch((err)=>{
        console.log(err,'err in get campaignlist');
    })

}


// const CountyReport = () => {

//    axios.get(`https://localhost:5002/api/ProductManagement/CelebrityCountyReport?campaignid=${selectedCampaign.value}`)
//     //   getCelebrityCountyReport(selectedCampaign.value)
//       .then((res) => {

//         console.log(res,"got celebrity county res")
       

//           const counties = res.data._response.data.allcountycount
          


//           let piecounty = []
//           let piecountyCount = []

//           counties.map((a) => {
//               piecounty.push(a.countyname)
//           })

//           counties.map((a) => {
//               piecountyCount.push(a.count)
//           })

//           setPieChartCounty(piecounty)
//           setPieChartCountyCount(piecountyCount)

//       }).catch((err) => {
//           console.log(err)
//       })

// }


const reports = () => {
    setloading(true);
    // axios.get(`https://localhost:5002/api/ProductManagement/CelebrityCountyReport?campaignid=${selectedCampaign.value}`)
       getcelebrityReportByCounty(selectedCampaign.value)
      .then((res) => {
          console.log(res,"got celebrity count ");

          if (res.data._response.status === 1) {
             
             
               let County = res.data._response.data.individualcelebritycounty;
           
               let TotalProductCounty = res.data._response.data.countywisetotalofallcelebrity;
               let overallcounttotal =  res.data._response.data.countytotal

              
             
               setCountydata(County);
               setTotal(overallcounttotal);
          
               setCountyTotal(TotalProductCounty);
                 
               setloading(false);
        
          } else if (res.data._response.status === 2) {
                    
            setCountydata([]);
            setTotal([]);
            setCountyTotal([]);            
            setloading(false);
          }
      })
      .catch((err)=>{
          console.log(err,'err in getcountyReport');
      })
}


  return (
    <div>
    <div>
        <div className="content-wrapper consti-section" id="divToPrint">
            <div className="content-header">
                <div className="container-fluid">
                    <br /><br />
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <h1 className="m-0 text-center">Voting List</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container">
                    <div className="row">
                        <Col md={12}>
                            <Card style={{ padding: "20px" }}>
                                <Form.Group
                                    as={Row}
                                    className="mb-1 justify-content-center"
                                    controlId="formHorizontalEmail"
                                >

                                    <Col sm={12} className="mb-4">
                                        <Form.Label className="w-100">Campaign</Form.Label>
                                        <Select
                                            className="w-100"
                                            options={campaignOptions}
                                            value={selectedCampaign}
                                            onChange={handleCampaign}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <div className="button_style m-0 text-center">

                                    <Button
                                        type="button"
                                        variant='contained'
                                        color='error'
                                        onClick={clear}
                                    >
                                        Cancel
                                    </Button>
                                </div>

                            </Card>
                        </Col>

                    </div>

                </div>

            </section>

        </div>
        <br></br>
    </div>
    <br /><br />







    {
    loading ? 
    <Loader/>
    :

    
    selectedCampaign === '' || Total === 0 ? (

        <NotFound />


    ) :
        <>
            <div ref={componentRef}>
                <h1 className="m-0 text-center">Voting  Report Summary</h1>
                <h4 className="m-0 text-center">{selectedCampaign.label}</h4>
                <br></br>

                <Container>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report">
                                <TableContainer >

                                    <div className="mt-5">
                                        <h2>County Wise</h2>
                                        {/* <div className="row">
                                        <div className="col-md-6"> */}

                                    <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                            <TableHead >

                                                <TableRow className="backgroundrow">
                                                    <TableCell></TableCell>
                                                    <>
                                                        {Countydata.map((data, id) => (
                                                            <TableCell width="10%" align="center" rowSpan={2} className="head1">{data.celebrityname}</TableCell>
                                                         ))} 


                                                    </>

                                                    <TableCell align="center" width="10%" rowSpan={2} className="head1">Total</TableCell>

                                                    
                                                </TableRow>


                                            </TableHead>
                                            <TableBody>
                                                
                                                   {Countydata.map((a, i)=>{
                                                      
                                                           if(i === 0){
                                                            return(
                                                            a.c.map((f)=>(
                                                                <TableRow>
                                                            <TableCell width="10%"  align="center" >{f.cn}</TableCell>
                                                          {  Countydata.map((data,ind)=>{
                                                        
                                                              let count = data.c.find((g)=>g.cn === f.cn);
                                                              let total = CountyTotal.find((t)=> t.countyname === f.cn )

                                                              if(Countydata.length - 1 !== ind){
                                                                return(
                                                                    <TableCell width="10%" align="center"  >{count.count}</TableCell>
                                                                   
                                                                   
                                                               
                                                                )
                                                              }
                                                               if(Countydata.length - 1 === ind){
                                                                return(
                                                                    <>
                                                                    <TableCell width="10%" align="center"  >{count.count}</TableCell>
                                                                    <TableCell width="10%" align="center"  >{total.count}</TableCell>
                                                                    </>
                                                                   
                                                                   
                                                               
                                                                )
                                                              }
                                                               
                                                               
                                                            })}

                                                        

                                                            </TableRow> 
                                                               ))
                                                       )

                                                           }
                                                        
                                                        
                                                    
 } )}

                                                    

                                                <TableRow>

                                                    <TableCell align="left" className="backgroundrow2"  >Total Votes</TableCell>

                                                {Countydata.map((total, e) => ( 
                                                        <TableCell align="center">{total.overallcount}</TableCell>
                                                  ))} 
                                                    <TableCell className="headerbgcolor" align="center">{Total}</TableCell> 



                                                </TableRow>

                                            </TableBody>

                                        </Table>

                                        </div>
                                {/* <div className="col-md-6">

        <Chart

                                            type="pie"
                                            width={500}
                                            height={500}
                                            series={PieEducationCount}

                                            options={{
                                                title: { text: "Voting Report" },
                                                noData: { text: "Empty Data" },
                                                colors: ['#ac92eb', '#4fc1e8', '#8bcb30', '#ffc809', '#ed5564'],
                                                labels: PieEducationName,
                                                responsive: [{
                                                    breakpoint: 1024,
                                                    options: {
                                                      chart: {
                                                        width: 700
                                                      },
                                                      legend: {
                                                        position: 'right'
                                                      }
      
                                                    },
                                                    breakpoint: 480,
                                                    options: {
                                                      chart: {
                                                        width: 250
                                                      },
                                                      legend: {
                                                        position: 'bottom'
                                                      },
                                                      
                                                    },
                                                 
      
                                                  }]
                                            }}

                                        />

                                </div> */}
                                {/* </div>
                        


                                        
                                    </div> */}


                                </TableContainer>
                            </Paper>
                    </Container>
            </div>
            <br></br>
            <div className="text-center m-0">
                {/* <Button variant="contained" onClick={downloadReceipt}>Download</Button> */}
                &nbsp;&nbsp;
                <Button variant="contained" onClick={handlePrint}>Print</Button>
            </div>

        </>

    }





</div>
  )
}

export default CelebrityCountyReport;