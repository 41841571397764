import React, { useEffect, useState } from 'react'
import MaterialTable from "material-table";
import { Form, Row, Col, Card } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import Button from '@mui/material/Button';


import { constituencyList, getCountyData, addconstituency, editConstituency, trashConstituency,NoConstilist,NocountyList } from '../Admin/ProductServiceAPI'

export default function Constituency() {


    const [data, setdata] = useState([]);
    const [constituency, setConstituency] = useState('');
    const [selectedCounty, setSelectedCounty] = useState('')
    const [countyOptions, setCountyOptions] = useState([]);
    const [constiID, setConstiID] = useState('');
    const [update, setUpdate] = useState(false);

    const [countyError, setCountyError] = useState('');
    const [constituencyError, setConstituencyError] = useState('');

    const columns = [
        { title: "Constituency", field: "constituencyname", align: "center" },
        { title: "Parent County", field: "countyname", align: "center", },
    ];

    const handleConstituency = (e) => {
        setConstituency(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setConstituencyError('')
    }

    const handleCounty = (selected) => {
        setSelectedCounty(selected)
        setCountyError('')
    }

    useEffect(() => {

        getlist();
        countylist();

    }, [])


    const getlist = () => {
        NoConstilist()
            .then((res) => {
                if (res.data._response.status === 1) {
                    let consti = res.data._response.data.getconstituency
                    setdata(consti)
                }
            }).catch((err) => {
                console.log(err);
            })

    }

    const countylist = () => {
        NocountyList()
            .then((res) => {
                if (res.data._response.status === 1) {
                    let arr = [];
                    let arrData = res.data._response.data.getcountywithnocounty
                    arrData.map((a) => (

                        arr.push({
                            label: a.countyName,
                            value: a.countyId
                        })
                    ))
                    setCountyOptions(arr);
                }
            })
            .catch((err) => {
                console.log(err, "err in get countylist");

            })
    }

    const FormValidation = () => {

        let constituencyError = '';
        let countyError = '';
        let regex = /^[A-Za-z0-9 ]+$/;


        if (constituency === '') {

            constituencyError = "Please fill the Details";

        } else if (regex.test(constituency) === false) {

            constituencyError = 'Please do not use special Character'
        }else if(constituency.length > 50){
            constituencyError = "Maximum 50 characters are allowed";
        }


        if (selectedCounty.length < 1) {

            countyError = "Please Select County";

        }

        if (constituencyError || countyError) {

            setConstituencyError(constituencyError);
            setCountyError(countyError);


            return false;
        }

        return true;
    }

    const saveData = () => {

        const isValid = FormValidation();

        if (isValid) {


            const addData = {
                constituencyName: constituency,
                CountyId: (selectedCounty.value).toString()


            }
            addconstituency(addData)
                .then((res) => {

                    if (res.data._response.status === 1) {
                        Swal.fire({
                            icon: "success",
                            title: "Constistuency Added Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        getlist();
                        clearData();
                    }
                })
                .catch((err) => {

                    console.log(err, "err in add constituency");
                })

        }
    }

    const getdata = (rowData) => {
      
        setUpdate(true);
        setConstiID(rowData.constituencyid)
        setConstituency(rowData.constituencyname);
        setSelectedCounty({ label: rowData.countyname, value: rowData.countyid });
    }

    const editData = () => {

        const isValid = FormValidation();

        if (isValid) {

            const addData = {
                constituencyName: constituency,
                CountyId: (selectedCounty.value).toString(),
                constituencyId: constiID

            }
            editConstituency(addData)
                .then((res) => {


                    if (res.data._response.status === 1) {

                        Swal.fire({
                            icon: "success",
                            title: "Constistuency Update Successfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        getlist();
                        clearData();
                    }

                })
                .catch((err) => {
                    console.log(err, "err in edit constituency");
                })

        }
    }

    const trashData = (rowdata) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        })
            .then((result) => {
                if (result.isConfirmed) {
                    trashConstituency(rowdata.constituencyid).then((res) => {
                        if (res.data._response.status === 1) {

                            Swal.fire({
                                icon: "success",
                                title: "Constistuency Deleted Sucessfully",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });
                            getlist();
                        }else if(res.data._response.status === 2){
          
                            Swal.fire({
                              icon: "warning",
                              title: res.data._response.message,
                              showConfirmButton: true,
                              confirmButtonColor: "#3085d6",
                              // cancelButtonColor: '#d33',
                              confirmButtonText: "ok",
                          });
              
              
                      }
              
                  })
                 
              }
              })
                  
            .catch((err) => {
                console.log(err, "err in trash Constituency");
            })

    }

    const clearData = () => {
        setUpdate(false);
        setConstituency('');
        setSelectedCounty('')
        setConstituencyError('');
        setCountyError('');
    }
    return (


        <div>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                        <br/><br/>
                            <div className="row">
                                <div className="col-sm-12">
                                    {!update ?(
                                    <h1 className="m-0 text-center">CONSTITUENCY INFORMATION</h1>
    ):
                                    <h1 className="m-0 text-center">EDIT CONSTITUENCY INFORMATION</h1>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content consti-section">
                        <div className="container">
                            <div className="row">
                                <Col md={12}>
                                    <Card style={{ padding: "30px" }}>
                                        <Form.Group
                                            as={Row}
                                            className="mb-3 justify-content-center"
                                            controlId="formHorizontalEmail"
                                        >
                                            <Col sm={6} className="mb-4">
                                                <Form.Label className="w-100">Constituency</Form.Label>
                                                <input
                                                    type="text"
                                                    
                                                    value={constituency}
                                                    onChange={handleConstituency}
                                                    className="w-100 form-control"
                                                />
                                                <div style={{ color: "red",fontSize: 13  }}>{constituencyError}</div>
                                            </Col>
                                            <Col sm={6} >
                                                <Form.Label className="w-100">Parent County</Form.Label>
                                                <Select
                                                    className="w-100"
                                                    options={countyOptions}
                                                    onChange={handleCounty}
                                                    value={selectedCounty}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                                <br/>
                                                <div style={{ color: "red",fontSize: 13  }}>{countyError}</div>
                                            </Col>
                                        </Form.Group>

                                        <div className="button_style m-0 text-center">
                                            {
                                                update === false ?
                                                    <Button
                                                        type="button"
                                                        variant='contained'
                                                        onClick={() => saveData()}
                                                    >
                                                        Submit
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="button"
                                                        variant='contained'
                                                        color='success'
                                                        onClick={() => editData()}
                                                    >
                                                        Update
                                                    </Button>

                                            }
                                            {" "}{" "}

                                            <Button
                                                type="button"
                                                variant='contained'
                                                color='error'
                                                onClick={() => clearData()}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Card>
                                </Col>
                                <Col className='mt-4' md={12} >

                                    <MaterialTable
                                        title=''
                                        data={data}
                                        columns={columns}
                                        actions={[
                                            {
                                                icon: "edit",
                                                tooltip: "Edit",
                                                onClick: (event, rowData) => {
                                                    getdata(rowData);
                                                },
                                            },
                                            {
                                                icon: "delete",
                                                tooltip: "Delete",
                                                onClick: (event, rowData) => {

                                                    trashData(rowData);
                                                },
                                            },
                                        ]}
                                        options={{
                                            search: true,
                                            pageSize: 10,
                                            showFirstLastPageButtons: false,
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                            pageSizeOptions: [10, 15, 20, 50],
                                        }}
                                    />
                                </Col>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
