import React,{useState,useEffect} from 'react'
import "../../../components/pages/Login/UserDetails.css"
import {  Card , Container, Col, Row} from 'react-bootstrap';
import Auth from "../../ServerCalls/Auth";
import {Link} from 'react-router-dom'
import { BrandgetbyId } from "../Admin/ProductServiceAPI";
import ProductURL from "../../ServerCalls/ProductServer";
import moment from 'moment';
import Button from '@mui/material/Button';
const brandphoto = ProductURL.photoURL + "BrandImages/";



function BrandDetails() {

const [brand,setBrand] = useState([]);    




useEffect(() => {
    BrandgetbyId(auth)
    .then((res)=>{
     const brand = res.data._response.data.brand[0]
     setBrand(brand)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

const auth = Auth.getUserId();


  return (
  <div className='user-info-body usecard'>
      <div className='container-fluid'>   
<br/><br/>
<div>
<Container>
  <Card>
    <Row>
      <Col sm={4} className='border-end mb-4'>
      <Card className="p-3" style={{backgroundColor:'#3f51b5',color:'white'}}>
        <Card.Body>
        <div className="d-flex user-pic justify-content-center">
        <img className='rounded-circle m-4' src={ brandphoto + brand.image1}
          width='150px'
          height='150px'
          alt='profile'
          />
        </div>
        </Card.Body>
        <Card.Title className='m-0 text-center' style={{color:'white',fontSize:'2rem'}}>{brand.brandName}</Card.Title>
        <br/>
        </Card>
      </Col>
      <br/>
      <Col sm={8}>
      <div className='card user-if-card ' style={{backgroundColor:'#3f51b5',color:'white'}}>
          <div className='card-body justify-content-center'>

            <h3 className="text-center m-0">Brand Details</h3>
          </div>
          </div>
            <div className='card user-card text-start'>
              <div className="card-body user-card-body ">
                <div className="row ">
                  <div className="col-sm-6">
                    <h6 className="mb-0">Brand Name</h6>
                  </div>
                  
                  <div className="col-sm-6">
                  {brand.brandName}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0">Year of Establishment</h6>
                  </div>
                  <div className="col-sm-6">
                  {moment(brand.yearOfEstablishment).year()}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0">Concern Person Name</h6>
                  </div>
                  <div className="col-sm-6">
                  {brand.contactPersonName}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0">Contact Number</h6>
                  </div>
                  <div className="col-sm-6">
                  {brand.contactPersonContactNumber}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0">Username</h6>
                  </div>
                  <div className="col-sm-6">
                  {brand.userName}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0">Brand Details</h6>
                  </div>
                  <div className="col-sm-6">
                     {brand.brandDetails}
                 </div>
                </div>
              
                
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="mb-0">Brand Image2</h6>
                  </div>              
                  <div className="col-sm-6">
                        <img
                           alt=" "
                            width="80"
                            height="60"
                            src={
                              brandphoto + brand.image2
                            }
                          />
                         </div>                
                </div>
                <hr />
                   <Link to={`/BrandInfo/${auth}`}><Button variant="contained" >Edit Profile</Button></Link>                
              </div>
            </div>
      </Col>
    </Row>
  </Card>
</Container>
</div>
</div>
  </div>
  )
}

export default BrandDetails