

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { campaignwithvotingResult } from '../../Admin/ProductServiceAPI';
import { getCelebrityCampaignwithMemberid, getCelebrityCampaignwithMemberidPagination } from '../../Admin/CelebrityPages/CelebrityServiceAPI'
import Auth from '../../../ServerCalls/Auth';
import ProductURL from "../../../ServerCalls/ProductServer";
import IconButton from '@mui/material/IconButton';
import share from '../../../../assets/img/share.png';
import { FaVoteYea } from 'react-icons/fa';
import { MdHowToVote } from 'react-icons/md';
import moment from 'moment';
import Loader from '../../../common/Loader';
import { Box } from '@material-ui/core'
import { Pagination, OutlinedInput, InputAdornment } from "@mui/material";
import { FiSearch } from 'react-icons/fi'
import { Col, Row, Form, Card, Image } from "react-bootstrap";
import NotFound from '../../../common/NotFound';
import { FaShareAlt } from 'react-icons/fa';
import { Modal } from "react-bootstrap";
import { FacebookIcon, FacebookShareButton, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon, } from 'react-share';
import Swal from "sweetalert2";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const PhotoPath = ProductURL.photoURL + "CelebrityCampaignImages/";

export default function CelebrityCampaignList() {

    const [nonFilteredCampaign, setnonFilteredCampaign] = useState([]);
    const [searchValue, setSearchValue] = useState("")
    const [CampaignNames, setCampaignNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageC, setPageC] = useState(8)
    const [pageNumber, setPageNumber] = useState(1);
    const dataPerPage = 8;
    const memberid = Auth.getUserId();
    const [showB, setShowB] = useState(false);
    const [ShareCampaign, setShareCampaign] = useState(0);

    const title = 'Campaign Information';


    useEffect(() => {
        setPageNumber(1)
        celebrityVoting(1, 8);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberid, searchValue])

    const changePage = (e, a) => {
        setPageNumber(a);
        celebrityVoting(a, dataPerPage);
    }

    useEffect(() => {

        const showRemaining = setInterval(() => {

            const newRemainingTime = CampaignNames.map((data) => {
                let dates = moment(data.campaignEndDate).format();
                var countDownDate = new Date(dates).getTime();

                var now = new Date().getTime();
                var timeleft = countDownDate - now;
                var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
                var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) - 5;
                var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)) - 30;
                var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
                return {
                    ...data,
                    days,
                    hours,
                    minutes,
                    seconds
                };
            });
            setCampaignNames(newRemainingTime);
        }, 1000)

        return () => {
            clearInterval(showRemaining);
        };
    }, [CampaignNames]);

    // useEffect(() => {

    //     if(searchValue != ""){

    //         const searchFilter =  CampaignNames.filter(val=>val.campaignShortName.toLowerCase().includes(searchValue.toLowerCase()));

    //        setCampaignNames(searchFilter);

    //     }else{

    //         setCampaignNames(nonFilteredCampaign);
    //     }

    // }, [searchValue])


    const celebrityVoting = (a, b) => {
        setLoading(true);
        //   axios.get(`https://localhost:5002/api/ProductManagement/GetCelebrityCampaign?memberid=${memberid}`)
        getCelebrityCampaignwithMemberidPagination(memberid, a, b, searchValue)
            .then((res) => {
               
                if (res.data._response.status === 1) {
                    setLoading(false);
                    let data = res.data._response.data.getpollingcampaign
                    let count = res.data._response.data.count;
                    let APIpagecount = Math.ceil(count / dataPerPage)
                    let filterlist = [];

                    data && data.map((a) => {

                        if (a.voted === 1) {
                            a.result = true
                        } else {
                            a.result = false
                        }

                        if (new Date(a.campaignEndDate) >= new Date()) {

                            a.filterdata = true
                        } else {
                            a.filterdata = false

                        }

                        // if (a.voted === 1 || new Date(a.campaignEndDate) >= new Date()) {

                        filterlist.push(a);

                        // }
                    })
                    setPageC(APIpagecount);
                    setCampaignNames(filterlist);
                    setnonFilteredCampaign(filterlist)
                } else if (res.data._response.status === 2) {
                    setLoading(false)
                    setPageC(8);
                    setCampaignNames([]);
                    setnonFilteredCampaign([])

                }
            }).catch((err) => {

                setLoading(false)
                setPageC(8);
                setCampaignNames([]);
                setnonFilteredCampaign([])
                console.log(err, "err in get celebrityVoting");
            })
    }

    const openModal = (list) => {
        let id = btoa(list.campaignId)
        setShareCampaign(id)
        setShowB(true);
    }
    const handleClose = () => {
        setShowB(false);
    };


    return (
        <>
            <div className="content-wrapper p-5">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <h1 className="m-0 text-center">CELEBRITY  CAMPAIGN</h1>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <div className='row'>
                        <Col md={{ span: 3, offset: 9 }}>
                            <Form.Group
                                as={Row}
                                className="justify-content-center"
                                controlId="formHorizontalEmail"
                            >
                                <Col sm={12} className="mb-4">
                                    {/* <h5 className="w-100 pb-1">
                                        Search Campaign by Campaign name
                                    </h5> */}
                                    
<FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            
            type="text"
                                        size='small'
                                        placeholder='Search Campaign by Name'
                                        name="searchValue"
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                startAdornment={
                    <InputAdornment position='start'>
                        <FiSearch />
                    </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
                                </Col>
                            </Form.Group>
                        </Col>
                    </div>
                    <div className='row'>
                        {loading ?
                            <Loader />
                            :
                            CampaignNames.length !== 0 ? (
                                CampaignNames.map((list, id) => {
                                    if (list.voted === 1 || new Date(list.campaignEndDate) >= new Date()) {

                                        return (
                                            <div className='col-md-3 pb-4'>

                                                {/* <Link className='text-decoration-none' to={`/CelebrityVoting/${list.campaignId}/${list.campaignEndDate}/${list.campaignShortName}/${list.question}/${list.celebrityCategoryId}`}> */}
                                                <div className="cardcateg card-blog" style={{ height: '420px' }}>
                                                    <div className="cardcateg-image">
                                                        <div><img className="img" alt='' src={PhotoPath + list.celebrityimage} /></div>
                                                        <div className="ripple-cont"></div>
                                                    </div>
                                                    <div className='card-body'>

                                                        <div className="">
                                                            <p className="category" title={list.campaignShortName}>{list.campaignShortName}</p>

                                                            <p className="cardcateg-description" title={list.celebritycategoryname}>
                                                                {list.celebritycategoryname}	&nbsp;	&nbsp;

                                                            </p>
                                                            <div className='d-flex justify-content-between'>
                                                                <IconButton className='text-end'><FaShareAlt fontSize="25px" color='#CD0404' onClick={() => openModal(list)} title="click to share" /></IconButton>
                                                                <Link className='text-decoration-none'  to="/CelebrityVoting" state={{data:list,page: "authorisedCelebritycampaigndetails"}}>
                                                                    <div className='mb-2'>
                                                                        {list.result === true ?

                                                                            <FaVoteYea fontSize="35px" color='green' title='Already Voted' />
                                                                            :

                                                                            < MdHowToVote fontSize='35px' color='orangered' title='Vote here...' />
                                                                        }


                                                                    </div>


                                                                </Link>




                                                            </div>
                                                            <div className='camp-timer p-2  mt-2' style={{ backgroundColor: '#3f51b5', color: 'white', borderRadius: '10px' }}>
                                                                <div className='m-0 text-center'>
                                                                    {
                                                                        list.days < 0 ?
                                                                            <h5 > EXPIRED!!!</h5>
                                                                            :

                                                                            <h5>{`${list.days} d ${list.hours} h ${list.minutes} m ${list.seconds} s`}</h5>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* </Link> */}
                                                    {/* <button className='btn btn-primary float-right'  onClick={() => openModal(list)}>share</button> */}
                                                    {/* <Card>
                                                    <Card.Body>
                                                        <div className='m-0 text-center' onClick={() => openModal(list)} title="click to share">
                                                            <IconButton className="text-center">
                                                                <Image src={share} alt='share' />
                                                            </IconButton> <span className='brand-span'>Share Campaign !</span>
                                                        </div>
                                                    </Card.Body>
                                                </Card> */}
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (


                                            <div className='col-md-3 pb-4'
                                                onClick={() => {
                                                    Swal.fire({
                                                        icon: "warning",
                                                        title: "Campaign Expired!!! You cannot vote or share this campaign",
                                                        showConfirmButton: true,
                                                        confirmButtonColor: "#3085d6",
                                                        confirmButtonText: "ok",
                                                    });
                                                }}>
                                                <div className="cardcateg card-blog" style={{ height: '420px' }}>
                                                    <div className="cardcateg-image">
                                                        <div><img className="img" alt='' src={PhotoPath + list.celebrityimage} /></div>
                                                        <div className="ripple-cont"></div>
                                                    </div>
                                                    <div className='card-body'>

                                                        <div className="">
                                                            <p className="category" title={list.campaignShortName}>{list.campaignShortName}</p>
                                                            <p className="cardcateg-description" title={list.celebritycategoryname}>
                                                                {list.celebritycategoryname}	&nbsp;	&nbsp;

                                                            </p>
                                                            <div className='d-flex justify-content-between'>
                                                                <IconButton className='text-end'><FaShareAlt fontSize="25px" color='#CD0404' onClick={() => openModal(list)} title="click to share" /></IconButton>
                                                                <Link className='text-decoration-none' to="/CelebrityVoting" state={{data:list,page: "authorisedCelebritycampaigndetails"}}>
                                                                    <div className='mb-2'>
                                                                        {list.result === true ?

                                                                            <FaVoteYea fontSize="35px" color='green' title='Already Voted' />
                                                                            :

                                                                            < MdHowToVote fontSize='35px' color='orangered' title='Vote here...' />
                                                                        }


                                                                    </div>


                                                                </Link>




                                                            </div>
                                                        <div className='camp-timer p-2  mt-2' style={{ backgroundColor: '#3f51b5', color: 'white', borderRadius: '10px' }}>
                                                            <div className='m-0 text-center'>
                                                                {
                                                                    list.days < 0 ?
                                                                        <h5 > EXPIRED!!!</h5>
                                                                        :

                                                                        <h5>{`${list.days} d ${list.hours} h ${list.minutes} m ${list.seconds} s`}</h5>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <button className='btn btn-primary float-right'  onClick={() => openModal(list)}>share</button> */}
                                                {/* <Card >
                                                    <Card.Body>
                                                        <div className='m-0 text-center' title="click to share">
                                                            <IconButton className="text-center">
                                                                <Image src={share} alt='share' />
                                                            </IconButton> <span className='brand-span'>Share Campaign !</span>
                                                        </div>
                                                    </Card.Body>
                                                </Card> */}
                                            </div>
                                            </div>
                                           
                                        )
                                    }


                                })
                            ) : (
                                <NotFound />
                            )
                        }
                        {
                            CampaignNames.length !== 0 ?
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <Pagination
                                        count={pageC}
                                        page={pageNumber}
                                        onChange={changePage}
                                        shape="rounded" color="primary" size="large"
                                    />
                                </Box>
                                :
                                null
                        }
                    </div>
                </div>
                <Modal
                    show={showB}
                    onHide={handleClose}
                    size="sm"
                    backdrop="static"
                    centered
                >
                    <Modal.Header className="headerBlue" closeButton>
                        <Modal.Title className="mx-auto">
                            <h5>
                                <b>Share Product</b>
                            </h5>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {/* <Link to="https://web.whatsapp.com/"> */}
                        <Row className="text-center">
                            <Col sm={4}>
                                <FacebookShareButton
                                    url={`https://topbrandsranking.com/SharedCelebritycampaign/${ShareCampaign}`}
                                    quote={title}
                                    className="Demo__some-network__share-button"
                                >
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                            </Col>
                            <Col sm={4}>
                                < WhatsappShareButton
                                    url={`https://topbrandsranking.com/SharedCelebritycampaign/${ShareCampaign}`}
                                    quote={title}
                                    className="Demo__some-network__share-button"
                                >
                                    < WhatsappIcon size={32} round />
                                </ WhatsappShareButton>
                            </Col>
                            <Col sm={4}>
                                < TwitterShareButton
                                    url={`https://topbrandsranking.com/SharedCelebritycampaign/${ShareCampaign}`}
                                    quote={title}
                                    className="Demo__some-network__share-button"
                                >
                                    <TwitterIcon size={32} round />
                                </ TwitterShareButton>
                            </Col>
                            {/* <Col sm={3}>
            < InstapaperShareButton
                url={`http://tbr.softlabsgroup.in/ShareProductDetails/${productID}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
              <InstapaperIcon size={32} round />
              </ InstapaperShareButton>
            </Col> */}
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

