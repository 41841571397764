import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Navbar, Container, Nav, Button, Offcanvas, Dropdown, NavDropdown } from 'react-bootstrap';
import { FaRegUserCircle} from 'react-icons/fa';
import logo1 from '../../../assets/img/logo1.png';
import '../header/Header.css';

const Header = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light" sticky="top">
        <Container>
          <Navbar.Brand href="/">
            <div className="logo-bg">
            <img src={logo1} alt="" className='img-fluid logo-img' />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto ">
              <Nav.Link href="/" >Home</Nav.Link>
              <Nav.Link href="/about-us" id="aboutus">About Us</Nav.Link>
              {/* <Nav.Link>
              <Dropdown >
                      <Dropdown.Toggle variant="transparent" id="dropdown-basic" size="sm" style={{fontSize:'1.2rem',fontWeight:'500',color:'#003300'}}>
                        Magzine
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item><Link className='text-decoration-none' to='/register'>Top Brands Ranking</Link></Dropdown.Item>
                        <NavDropdown.Divider />
                        <Dropdown.Item ><Link className='text-decoration-none' to='/BrandRegistration'>Top Score Brands</Link></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

              </Nav.Link> */}
              {/* <Nav.Link href="#ranking" id="ranking">Apply for Brand Ranking</Nav.Link> */}

              
    <NavDropdown title="Magazine" id="collasible-nav-dropdown">
        <NavDropdown.Item><Link to='/rankinglistdata'>Topbrandsranking</Link></NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item><Link to='/scoredata'>Topscorebrands</Link></NavDropdown.Item>
      </NavDropdown>
   

            </Nav>
            <Nav>
              {/* <Nav.Link href="#pricing">
                <Button variant="warning" size="sm">Subscribe</Button>
              </Nav.Link> */}

              

              {/* <Nav.Link href="#deets">Contact Us</Nav.Link> */}

              <Nav.Link>
                    <Button variant="primary" size="md">  <Link className='text-decoration-none' style={{ color: "white" }} to={{ pathname: '/login' }} state={{ page: "Dashboard" }}>Login</Link></Button>

                  </Nav.Link>
              <Nav.Link>
              <Dropdown >
                      <Dropdown.Toggle variant="transparent" id="dropdown-basic" size="sm">
                        <FaRegUserCircle fontSize="30px" color='grey' />

                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item><Link className='text-decoration-none' to='/register'>User Registration</Link></Dropdown.Item>
                        <Dropdown.Item ><Link className='text-decoration-none' to='/BrandRegistration'>Brandowner Registration</Link></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

              </Nav.Link>
              {/*
      <Nav.Link>
      <Button variant="primary" size='sm' onClick={handleShow}>
        <FaBars/>
      </Button>
      </Nav.Link>*/}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      <div>
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Product Survey</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            Some text as placeholder. In real life you can have the elements you
            have chosen. Like, text, images, lists, etc.
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  )
}

export default Header;