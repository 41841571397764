import React from 'react';
import MiniDrawerContainer from '../container/MiniDrawerContainer';
import { Routing } from './Routing';
import { useStyles } from '../static/MiniDrawerStyles';
// import AppBarComponent from './AppBarComponent';
import ContentRoutes from '../components/pages/ContentRoutes';
import Auth from './ServerCalls/Auth';

function MainPage(props) {
    const classes = useStyles;
    const { openSideBar, toggleHandler } = props;
    const token = Auth.getToken();
    return (
        <div className={classes.root}>
            {/* <AppBarComponent /> */}

            {
                !token ?

                    <ContentRoutes openSideBar={openSideBar} sideBarClick={toggleHandler} />
                    :
                    <>
                        <MiniDrawerContainer openSideBar={openSideBar} sideBarClick={toggleHandler} />
                        <ContentRoutes openSideBar={openSideBar} sideBarClick={toggleHandler} />
                    </>
            }

        </div>
    );
}

export default MainPage;