import axios from '../../../ServerCalls/AxiosInstance';
import ProductURL from '../../../ServerCalls/ProductServer';


//celebrityCategory all calls
const celebrityCategoryList = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetcelebrityCategory`
    })
}


const AddCategory = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/celebrityCategoryData`,
        data: data

    })
}

const editCelebrityCategory = async (data) => {
    return axios({
        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditcelebrityCategory`,
        data: data
    })
}

const deleteCelebrityCategory = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemovecelebrityCategory/${id}`,
    })
}

//release category

const getreleaseCategorylist = ()=>{
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetMediaReleaseCategory`
    })

}

const addreleaseCategory = (data)=>{

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/MediaReleaseCategoryData`,
        data: data

    })

}

const editReleaseCategory = async (data) => {
    return axios({
        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditMediaReleaseCategory`,
        data: data
    })
}

const deleteReleaseCategory = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveMediaReleaseCategory/${id}`,
    
    })
}

//platformMaster
const platformList = ()=>{
    return axios({
        method:"GET",
        url:`${ProductURL.ProductUrl}/GetSocialMediaPlatforms`
    })
}


const addSocialPlatform = (data)=>{

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/SocialMediaPlatformsData`,
        data: data

    })

}

const EditsocialPlatform = async (data) => {
    return axios({
        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditSocialMediaPlatforms`,
        data: data
    })
}

const deleteplatform = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveSocialMediaPlatforms/${id}`,
    
    })
}

//celebrity register
const getlCelebrityList = ()=>{

    return axios({
        method:"GET",
        url:`${ProductURL.ProductUrl}/GetCelebrity`
    })


}

const getsingleCelebrity = (id)=>{

    return axios({

        method: "GET",
        url: `${ProductURL.ProductUrl}/CelebrityGetById/${id}`,
    
    })

}

const addCelebrity = (data)=>{

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/CelebrityData`,
        data: data

    })
}

const editCelebritydata = (data)=>{

    return axios({
        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditCelebrity`,
        data: data

    })
}

const deleteCelebrity = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveCelebrity/${id}`,
    
    })
}

const deleteawardrel = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveCelebrityAwardsRel/${id}`,
    
    })
}

const deletemediarel = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveMediaRelease/${id}`,
    
    })
}

const deletesocialrel = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveCelebritySocialProfiles/${id}`,
    
    })
}






//photoAPI
const CelebrityPhotos = async (data) => {

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/CelebritySave/SaveFile`,
        data: data
    })
}


const awardList = ()=>{
    return axios({
        method:"GET",
        url:`${ProductURL.ProductUrl}/GetAwardsMaster`
    })
}

const addAward = (data)=>{

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/AwardsMasterData`,
        data: data

    })
}

const editawardList = async (data) => {
    return axios({
        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditAwardsMaster`,
        data: data
    })
}

const deleteAward = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveAwardsMaster/${id}`,
    
    })
}

//mediarelease 

const mediareleaseList = ()=>{
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetMediaRelease`
    })

}

const addmediaRelase = (data)=>{
      
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/MediaReleaseData`,
        data: data

    })
}


const AddCelebrityCampaign = (data)=>{

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/CelebrityCampaignData`,
        data: data


    })

}
const getCelebrityCampaign = ()=>{

    return axios({
        method:"GET",
        url:`${ProductURL.ProductUrl}/GetCelebrityCampaign`
    })
    
}

const getCelebrityCampaignWithPagination = (pgeno,pagsize)=>{

    return axios({
        method:"GET",
        url:`${ProductURL.ProductUrl}/GetCelebrityCampaignWithPagination?PageNo=${pgeno}&PageSize=${pagsize}`
    })
    
}

const getbyidCelebrityCampaign = (id)=>{

    return axios({

        method: "get",
        url: `${ProductURL.ProductUrl}/CelebrityCampaignGetById/${id}`,
    
    })

}


const EditCelebrityCampaign= (data) => {

    return axios({
        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditCelebrityCampaign`,
        data: data
    })
}


const DeleteCelebrityCampaign = (id)=> {

    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveCelebrityCampaign/${id}`,
    
    })
}

const getCelebritybyCategory = (id)=>{

    return axios({

        method: "get",
        url: `${ProductURL.ProductUrl}/CelebrityGetByCategoryId/${id}`,
    
    })


}


const getCelebrityCampaignwithMemberid = (memeberid)=>{

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/GetCelebrityCampaign?memberid=${memeberid}`
    })

}
const getCelebrityCampaignwithMemberidPagination = (memeberid,pgeno,pagsize,search)=>{

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/GetCelebrityCampaignWithPaginationForUser?memberid=${memeberid}&PageNo=${pgeno}&PageSize=${pagsize}&message=${search}`
    })

}

const CelebrityCampaignPhotos = async (data) => {

    return axios({

        method: "POST",
        url: `${ProductURL.celebrityCampaignPhoto}/SaveFile`,
        data: data
    })
}


const CelebrityParticipationwithcitizenID = async (citizenid, campaignid) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/CelebrityParticipationGetByCampaignIdVerified?memberid=${citizenid}&campaignid=${campaignid}`,
    })

}

const getCelebrityWithPagination = async (memberid,a,b)=>{

    return axios({
        method: "GET",
        url: `${ProductURL.ProductNOTIUrl}/GetCelebrityWithPagination?memberid=${memberid}&pageno=${a}&pagesize=${b}`,
    })

}

const getCelebrityWithNoparent = async () =>{

    return axios({
        method:"GET",
        url:`${ProductURL.ProductUrl}/GetCelebrityCategoryWithoutNoParent`,
    })

}

const getCelebritywithCategorywithPagination = async (categoryid,memberid,pgno)=>{

    return axios({
        method:"GET",
        url:`${ProductURL.ProductNOTIUrl}/CelebrityGetByCombination?categoryid=${categoryid}&memberid=${memberid}&PageNo=${pgno}&PageSize=${8}`
    })

}


const AddCelebrityVoting = (data) => {

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/CelebrityVotingData`,
        data: data

    })


}

const postCelebrityRating = (data)=>{

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/CelebrityRatingsData`,
        data: data

    })

}

const getTopCelebrity = ()=>{

    return axios({
        method:"GET",
        url:`${ProductURL.ProductUrl}/TopCelebrityGetByCelebrityId`
    })
    

}

const getCelebritySummary = (id,categoryid)=>{

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/CelebritySummary?campaignid=${id}&categoryid=${categoryid}`,
    })
}

const getCelebrityReport = (campaignid)=>{

      return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/CelebrityChart?campaignid=${campaignid}`,
    })
}


const getCelebrityGenderReport = (campaignid)=>{

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/CelebrityReportforGender?campaignid=${campaignid}`,
    })

}

const getCelebrityAgeReport = (campaignid)=>{

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/CelebrityReportforAge?campaignid=${campaignid}`,
    })
}

const getCelebrityCountyReport = (campaignid)=>{

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/CelebrityReportforCounty?campaignid=${campaignid}`,
    })

}
const getCelebrityOccupationReport = (campaignid)=>{

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/CelebrityReportforOccupation?campaignid=${campaignid}`,
    })

}

const getCelebrityEducationReport = (campaignid)=>{

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/CelebrityReportforEducationlevel?campaignid=${campaignid}`,
    })

}

const getcelebrityReportByCounty = (campaignid)=>{

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/CelebrityCountyReport?campaignid=${campaignid}`,
    })

}

const getcelebrityReportByOccupation = (campaignid)=>{


    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/CelebrityOccupationReport?campaignid=${campaignid}`,
    })

}




export {
    celebrityCategoryList,
    AddCategory,
    editCelebrityCategory,
    deleteCelebrityCategory,

    getreleaseCategorylist,
    addreleaseCategory,
    editReleaseCategory,
    deleteReleaseCategory,

    platformList,
    addSocialPlatform,
    EditsocialPlatform,
    deleteplatform,

    getlCelebrityList,
    addCelebrity,
    getsingleCelebrity,
    editCelebritydata,
    deleteCelebrity,


    CelebrityPhotos,

    awardList,
    addAward,
    editawardList,
    deleteAward,

    deleteawardrel,
    deletemediarel,
    deletesocialrel,

    mediareleaseList,
    addmediaRelase,
    
    AddCelebrityCampaign,
    getCelebrityCampaign,
    getCelebrityCampaignWithPagination,
    getbyidCelebrityCampaign,
    EditCelebrityCampaign,
    DeleteCelebrityCampaign,
    getCelebritybyCategory,
    getCelebrityCampaignwithMemberid,
    getCelebrityCampaignwithMemberidPagination,

    CelebrityCampaignPhotos,
    CelebrityParticipationwithcitizenID,
    getCelebrityWithPagination,
    getCelebrityWithNoparent,
    getCelebritywithCategorywithPagination,
    AddCelebrityVoting,
    postCelebrityRating,
    getTopCelebrity,
    getCelebritySummary,

    getCelebrityReport,

    getCelebrityGenderReport,
    getCelebrityAgeReport,
    getCelebrityCountyReport,
    getCelebrityOccupationReport,
    getCelebrityEducationReport,

    getcelebrityReportByCounty,
    getcelebrityReportByOccupation
    
}