import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Card } from "react-bootstrap";
import MaterialTable from "material-table";
import Button from '@mui/material/Button';
import Swal from "sweetalert2";
import { awardList, addAward, editawardList, deleteAward } from './CelebrityServiceAPI';

export default function CelbrityAwardMaster() {

    const [award, setAward] = useState('');
    const [update, setupdate] = useState(false);
    const [formerror, setformError] = useState('');
    const [wardlist, setWardlist] = useState([]);
    const [awardID, setAwardID] = useState(0);

    const columns = [
        { title: "Award Name ", field: "awardName", align: "center" },
    ];

    const handleName = (e) => {

        setAward(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setformError({ ...formerror, award: "" })

    }
    useEffect(() => {

        allList();

    }, [])


    const allList = () => {
        awardList()
            .then((res) => {
                if (res.data._response.status === 1) {

                    setWardlist(res.data._response.data.getawards);

                }
            })
            .catch((err) => {
                console.log(err, "err in get awardlist");
            })
    }


    const validates = () => {
        let formError = {};
        let isValid = true;
        let regex = /^[A-Za-z0-9 ]+$/;

        if (award === "") {
            isValid = false;
            formError["award"] = "Please Enter Award Name";
        } else if (award.length < 3) {
            isValid = false;
            formError["award"] = "Please Enter Minimun 3 Characters";
        }else if (award.length > 100) {
            isValid = false;
            formError["award"] = "Maximum 100 characters are allowed";
        }
        setformError(formError);
        return isValid;
    };


    const saveData = () => {

        const isValid = validates();

        if (isValid) {

            const data = {
                awardname: award
            }
            addAward(data).then((res) => {
                if (res.data._response.status === 1) {

                    Swal.fire({
                        icon: "success",
                        title: "Award Added Successfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });
                    allList();
                    clearData();
                }
            })
                .catch((err) => {
                    console.log(err, 'err in addaward');
                })

        }
    }

    const getaward = (id) => {

        setupdate(true)
        setAwardID(id.awardsId);
        setAward(id.awardName)
    }

    const editData = () => {

        const data = {
            awardsid: awardID,
            awardname: award,
        }

        editawardList(data).then((res) => {

            if (res.data._response.status === 1) {

                Swal.fire({
                    icon: "success",
                    title: "Award Updated Sucessfully",
                    showConfirmButton: true,
                    confirmButtonColor: "#3085d6",
                    // cancelButtonColor: '#d33',
                    confirmButtonText: "ok",
                });
                allList();
                clearData();
            }
        }).catch((err) => {

            console.log(err, "err in edit awardlist");

        })
    }

    const trashData = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {

                deleteAward(id)
                    .then((res) => {

                        if (res.data._response.status === 1) {
                            Swal.fire({
                                icon: "success",
                                title: "Award Deleted Sucessfully",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });
                            allList();
                        } else if (res.data._response.status === 2) {

                            Swal.fire({
                                icon: "warning",
                                title: res.data._response.message,
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });


                        }

                    }).catch((err) => {
                        console.log(err, 'err in getresult');
                    })

            }
        }).catch((err) => {
            console.log(err)
        })
    }



    const clearData = () => {
        setupdate(false);
        setAward('');
        setformError('')

    }


    return (

        <div>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <br /><br />
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    {!update ? (
                                        <h1 className="m-0 text-center para-heading">ADD AWARD MASTER</h1>
                                    ) :
                                        <h1 className="m-0 text-center para-heading"> EDIT AWARD MASTER</h1>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container">
                            <div className="row justify-content-center">
                                <Col md={12}>
                                    <Card className='mt-4' style={{ padding: "20px" }}>
                                        <Form.Group as={Row} className="justify-content-center" controlId="formHorizontalEmail">
                                            
                                                <Form.Label className="w-100 pb-1">Award Name</Form.Label>
                                                <input className="w-100 form-control" type="text" placeholder='Award Name' value={award} onChange={handleName} />
                                                <div style={{ fontSize: 13, color: "red" }}>{formerror.award}</div>
                                            
                                        </Form.Group>
                                        <div className="button_style m-0 text-center">

                                            {
                                                update === false ?

                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        onClick={() => saveData()}
                                                    >
                                                        Submit
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="success"
                                                        onClick={() => editData()}
                                                    >
                                                        Update
                                                    </Button>
                                            } {" "}

                                            <Button
                                                type="clear"
                                                variant="contained"
                                                color="error"
                                                onClick={() => clearData()}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Card>
                                </Col>
                                <Col className='mt-4' md={12}>
                                    <MaterialTable
                                        title="Award List"
                                        data={wardlist}
                                        columns={columns}
                                        actions={[
                                            {
                                                icon: "edit",
                                                tooltip: "Edit",

                                                onClick: (event, rowData) => {
                                                    // setNameError("")
                                                    getaward(rowData);
                                                },
                                            },
                                            {
                                                icon: "delete",
                                                tooltip: "Delete",
                                                onClick: (event, rowData) => {

                                                    trashData(rowData.awardsId);
                                                },
                                            },
                                        ]}
                                        options={{
                                            search: true,
                                            pageSize: 10,
                                            showFirstLastPageButtons: false,
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                            pageSizeOptions: [10, 15, 20, 50],
                                        }}
                                    />
                                </Col>
                            </div>
                        </div>
                        <br /> <br />
                    </section>
                </div>
            </div>
        </div>

    )
}
