import React, { useState } from 'react';
import { createReply, getreplies } from './ProductServiceAPI'
import { Rating } from 'react-simple-star-rating'
import { Row, Col, Card, Button } from 'react-bootstrap';
import { BsThreeDotsVertical, BsReplyFill , } from 'react-icons/bs';
import { AiFillLike } from 'react-icons/ai';
import { MdOutlineExpandMore} from 'react-icons/md'
import { IconButton } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment-timezone'
import Auth from '../../ServerCalls/Auth';
import ProductURL from "../../ServerCalls/ProductServer";
const PhotoPath = ProductURL.photoURL + "MemberMasterImages/";
const brandphoto = ProductURL.photoURL + "BrandImages/";
const productphoto = ProductURL.photoURL + "ProductImages/"
const userid = Auth.getUserIdF();

const CommentExampleComment = ({ comment }) => {

    const [addComment, setaddComment] = useState(false);
    const [text, setText] = useState('');
    const [feedbackID, setfeedbackID] = useState(comment.feedbackid);
    const [replies, setReplies] = useState([]);
    const [replyopen, setreplyopen] = useState(false);
    const [formError, setFormError] = useState('')


    const replylist = (id, open) => {
        let openreply = replyopen === false ? true : false
        setreplyopen(openreply);
        if (openreply === true || open === "open") {
            getreplies(id).then((res) => {
                if (res.data._response.status === 1) {

                    let replydata = res.data._response.data.brandrecognitioncommgetid1

                    setReplies(replydata)
                }
            }).catch((err) => {
                console.log(err, "err in replies get");
            })
        } else {
            setReplies([]);
        }

    }
    const fixTimezoneOffset = (date) => {

        if (!date) return "";

        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();

    }
    const onSubmit = () => {

        if (text !== '') {

            const allData = {
                feedbackid: feedbackID,
                message: text,
                messagefrom: userid,
                ReplyDate: fixTimezoneOffset(new Date()),
            }
            createReply(allData).then((res) => {
                if (res.data._response.status === 1) {
                    setaddComment(false);
                    setText('')
                    replylist(feedbackID, "open");
                }
            }).catch((err) => {
                console.log(err, "err in createreply");
            })

        }else{

            setFormError("Please fill the details")

        }


    };
    const openCommentForm = () => {
        return (
            <>
                <form >
                    <textarea
                        className="form-control"
                        value={text}
                        onChange={(e) => {
                            setText(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
                            setFormError('');
                    }}
                    />
                    <div style={{ color: "red" }}>{formError}</div>

                    <Button type='submit' onClick={(e) => { e.preventDefault(); onSubmit() }} >
                        Send
                    </Button>&nbsp;
                    <Button type='submit' onClick={() => { setaddComment(false) }}>cancel</Button>
                </form>
            </>
        )
    }

    return (
        <>
            <div className="container mb-5 mt-5">
                <br /><br />
                <Card>
                    <Card.Body>
                        <Row>
                            <Col sm={8}>
                                <div className="d-flex media1 ">
                                    <img className="mr-4 rounded-circle im" alt="Bootstrap Media Preview" src={productphoto + comment.productimage} />
                                    <h5 className='productname m-4'>Product : <span style={{ color: "#0066b2", fontSize: "medium", fontSize: '1.5rem' }}>{comment.productname}</span></h5>
                                </div>
                                <div className="media-body m-4">
                                    <div className="row">                  
                                        <div className="col-8 d-flex mt-2 mb-4">
                                            <h5>{comment.feedback}</h5>
                                        </div>
                                        <br />
                                        <div className="col-8">
                                            <Rating
                                                ratingValue={comment.brandratings}
                                                readonly
                                            />
                                        </div>
                                        <br /><br /><br />
                                        <div className="col-12 mb-4">

                                            {
                                                addComment ? openCommentForm() : null
                                            }


                                        </div>
                                    </div>
                                   
                                </div>

                            </Col>
                            <Col sm={4}>
                                <div className="d-flex justify-content-end">
                                    <div className='cursorpointer m-4' onClick={() => { setaddComment(true); }} >
                                        <span style={{ color: 'blue', fontSize: '1.2rem', fontWeight: '600' }}>
                                            <BsReplyFill fontSize="25px" />
                                            Reply</span>
                                    </div>               
                                    <div className='replies m-2' onClick={() => replylist(feedbackID)}>
                                        <Tooltip title='Replies'>
                                            <IconButton>
                                            <MdOutlineExpandMore fontSize="25px" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end m-5 align-bottom" style={{ paddingTop: '60px' }}>
                                    <strong>{moment(comment.date).format('LLL')}</strong>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            
                        {
                                        replies && replies.map((a) => {
                                            return (

                                                <>                                           
                                                    <div className='justify-content-center'>
                                                        <Row>
                                                            <Col sm={8}>
                                                                <div className='media1 p-2'>
                                                                    <div className='d-flex'>
                                                                        <div className="aa pr-3">
                                                                            <img className="rounded-circle im" alt="Bootstrap Media Another Preview" src={a.role === 3 ? brandphoto + a.profile : a.role === 2 ? PhotoPath + a.profile : null} />

                                                                        </div>
                                                                        <div className='m-2'>
                                                                            <h5 className='Nametext'>{a.replier}</h5>
                                                                            <h6> {a.message}</h6>
                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            </Col>
                                                            <Col sm={4} className="media1 p-2 d-flex justify-content-start">
                                                                <div className='' style={{ paddingTop: '20px' }}>
                                                                    <strong>{moment(a.replydate).format('LLL')}</strong>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                            
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}



export default CommentExampleComment