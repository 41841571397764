import{Add_Update_Brand,Add_Update_Category,Celebrity_Update_Category} from '../action/userproductgalleryAction'
  
  const initialState = {
    brand: {},
    category:{},
    celebrityCategory:{}
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
        case Add_Update_Brand:
            let branddata = action.payload;
           
            return {
              ...state,
              brand: branddata
            };
            case Add_Update_Category:
            let categorydata = action.payload;
           
            return {
              ...state,
              category: categorydata
            };
            case Celebrity_Update_Category:
              let celebrityCategorydata = action.payload;
              return{
                ...state,
                celebrityCategory:celebrityCategorydata
              };
      default:
        return state;
    }
  };
  