import React from 'react';
import { Settings, DesktopMac, Store, AccountBox } from "@material-ui/icons";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import FeedSharpIcon from '@mui/icons-material/FeedSharp';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CollectionsIcon from '@mui/icons-material/Collections';
import FeedbackIcon from '@mui/icons-material/Feedback';

export const BrandMenu = [



    // {
    //     id:1,
    //     menuName: 'Profile',
    //     menuDescription: 'Device mac id setup menus',
    //     path: '/BrandDetails',
    //     exact: true,
    //     icon: <ManageAccountsIcon />,
    //     submenu: []
    // },
    {
        id:2,
        menuName: 'Product Gallery',
        menuDescription: 'Device mac id setup menus',
        path: '/BrandGallery',
        exact: true,
        icon: <ProductionQuantityLimitsIcon />,
        submenu: []
    },
 
    {
        id:2,
        menuName: 'Feedback Reply',
        menuDescription: 'Device mac id setup menus',
        path: '/feedbackReply',
        exact: true,
        icon: <FeedbackIcon />,
        submenu: []
    },
    {
        id:2,
        menuName: 'Add Product',
        menuDescription: 'Device mac id setup menus',
        path: '/BrandAddProduct',
        exact: true,
        icon: <AccountBox />,
        submenu: []
    },
    {
        id:2,
        menuName: 'Post News',
        menuDescription: 'Device mac id setup menus',
        path: '/Addnews',
        exact: true,
        icon: <FeedSharpIcon />,
        submenu: []
    },

];