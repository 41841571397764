import React from 'react';
import {Container,Row, Col} from 'react-bootstrap';
import './aboutus.css';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import ranking from '../../../assets/img/ranking.png';
import blueseal from '../../../assets/img/blueseal.png';
import greenseal from '../../../assets/img/greenseal.png';
import rubyseal from '../../../assets/img/rubyseal.png';
import CollapsibleExample from '../NewHeader';


const About = () => {
    return(
        <>
              <CollapsibleExample />

        <div className='aboutus-body'>
            <section>
             <div className='abt-us-heading'>
              <div className='abt-heading '>
                <h2>About Us</h2>
              </div>
             </div>
            </section>
            <section className='abt-us-sec3'>
            <Container >
                <Row >
                    <Col sm={4} >
                    <div className="p-4" >
                        <img src={blueseal} alt="rank" className='img-fluid pt-4' 
                        width="300"
                        />
                    </div>
                    
                    </Col>
                    <Col sm={4}>
                    <div className="p-4" >
                        <img src={greenseal} alt="rank" className='img-fluid pt-4' 
                        width="300"
                        />
                    </div>
                    </Col>
                    <Col sm={4}>
                    <div className="p-4" >
                        <img src={rubyseal} alt="rank" className='img-fluid pt-4' 
                        width="300"
                        />
                    </div>
                    </Col>
                    
                </Row>
                
            </Container>
            <div>
                <section>
                    <Container>
                    <Row>
                <Col >
                    <p>
                    <span >Top Brands Ranking</span> is a blockchain based e-voting platform that enables you to VOTE for your most Favorite brand Transparently and in Real time , making it possible for us to properly verify and certify those particular brands that deserves to be conferred with various <span>Topscorebrands Awards</span> every year . 
                     Your voting and Feedback information goes along way into helping us make informed and objective decisions about the brands listing, Ranking and Awarding thereof.
                    </p>
                    </Col>
                </Row>
                    </Container>

                </section>
            </div>
            </section>
       
        </div>
        <Footer/>
        </>
    )
}

export default About;