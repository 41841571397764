import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import Button from '@mui/material/Button';
import Select from "react-select";
import Swal from "sweetalert2";
import MaterialTable, { MTableEditField } from "material-table";
import ProductURL from "../../ServerCalls/ProductServer";
import { Addproduct, getProductByBandId, editProduct, deleteProduct, Categorywithnoparent, ProductPhotos, trashProductparameters, Allparameters } from '../Admin/ProductServiceAPI';
import Auth from '../../ServerCalls/Auth';


export default function AddProduct() {
  const [update, setupdate] = useState(false)
  const brandid = Auth.getUserId();
  const PhotoPath = ProductURL.photoURL + "ProductImages/";
  // const [productname, setProductName] = useState("");
  const [BrandName, setBrandName] = useState('');
  const [allProducts, setAllProducts] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [productname, setProductname] = useState('');
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [image1Path, setimage1Path] = useState('');
  const [image2Path, setimage2Path] = useState('');
  const [image3Path, setimage3Path] = useState('');
  const [image4Path, setimage4Path] = useState('');
  const [image5Path, setimage5Path] = useState('');
  const [productImage1, setProductImage1] = useState("");
  const [productImage2, setProductImage2] = useState("");
  const [productImage3, setProductImage3] = useState("");
  const [productImage4, setProductImage4] = useState("");
  const [productImage5, setProductImage5] = useState("");
  const [formError, setFormError] = useState([]);
  const [productID, setProductID] = useState(0);
  const [showlist, setShowlist] = useState(false);
  const [dataList, setdataList] = useState([]);
  const [parameters, setParameters] = useState([])


  const columns = [
    { title: "Product Name", field: "productname", align: "center" },
    { title: "Product Image1", field: "productimage1", align: "center" },
    // { title: "Product Image2", field: "productimage2", align: "center" },
    // { title: "Product Image3", field: "productimage3", align: "center" },
    // { title: "product Image4", field: "productimage4", align: "center" },
    // { title: "product Image5", field: "productimage5", align: "center" },
    { title: "Brand Name", field: "brandname", align: "center" },
    { title: "Category", field: "categoryname", align: "center" },
  ];

  const columns1 = [

    { title: "Paramter Name", field: "parametername", align: "center", editable: "never" },
    {
      title: "parameter value", field: "parametervalue", align: "center", editComponent: ({ ...props }) => {

        return <MTableEditField {...props} />;
      },
    }

  ];


  const handleProductName = (e) => {
    setProductname(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''));
    setFormError({ ...formError, productName: "" })

  }

  const handleCategory = (selected) => {
    setSelectedCategory(selected)
    setFormError({ ...formError, categoryselected: "" })

  }

  const handleimage1 = (e) => {

    setProductImage1(e.target.files[0]);
    setimage1Path(URL.createObjectURL(e.target.files[0]));
    setFormError({ ...formError, productImage1: "" });
  };

  const handleimage2 = (e) => {

    setProductImage2(e.target.files[0]);
    setimage2Path(URL.createObjectURL(e.target.files[0]));
    setFormError({ ...formError, productImage2: "" });

  };

  const handleimage3 = (e) => {

    setProductImage3(e.target.files[0]);
    setimage3Path(URL.createObjectURL(e.target.files[0]));
    setFormError({ ...formError, productImage3: "" });
     
  };

  const handleimage4 = (e) => {

    setProductImage4(e.target.files[0]);
    setimage4Path(URL.createObjectURL(e.target.files[0]));
    setFormError({ ...formError, productImage4: "" });

  };

  const handleimage5 = (e) => {

    setProductImage5(e.target.files[0]);
    setimage5Path(URL.createObjectURL(e.target.files[0]));
    setFormError({ ...formError, productImage5: "" });

  };

  const inputRefrence1 = useRef(null);
  const inputRefrence2 = useRef(null);
  const inputRefrence3 = useRef(null);
  const inputRefrence4 = useRef(null);
  const inputRefrence5 = useRef(null);

  useEffect(() => {
    getProductList();
    getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetParameters()
  
  }, [selectedCategory,productID])
  

  const getProductList = () => {
    getProductByBandId(brandid)
      .then((res) => {
        if (res.data._response.status === 1) {

          const list = res.data._response.data.productgetid;
          setAllProducts(list);
          setBrandName(list.brandname)
        }

      })
      .catch((err) => {
        console.log(err, "error in get");
      });
  };

  const getCategory = () => {
    Categorywithnoparent()
      .then((res) => {
        if (res.data._response.status === 1) {
          let data = res.data._response.data.getprocategory;
          let options = data.map((a) => ({
            value: a.categoryid,
            label: a.categoryName,
          }));
          setCategories(options);
        }
      })
      .catch((err) => {
        console.log(err, "err in category get");
      });
  };

  const GetParameters = () => {
    Allparameters(productID, selectedCategory.value).then((res) => {

      let parametersData = res.data._response.data.productparametersgetid
      let data = [];
      parametersData && parametersData.map((x) => {

        if (x.categoryid === selectedCategory.value) {
          data.push(parametersData);
        }

      })
      setdataList(parametersData);

    }).catch((err) => {
      console.log(err, "err in get")
    })
  }

  const handleRowupdate = (newData, resolve, reject) => {
    if (newData) {
      let mapdata = [];
      let array = [...dataList]
      newData && Object.entries(newData).map(([k, v]) => {

        mapdata.push(v.newData);
        array[v.newData.tableData.id].parametervalue = v.newData.parametervalue
      })
      setParameters(mapdata)
      setdataList(array)
      resolve();
    } else {
      Swal.fire({
        icon: "warning",
        title: "Enter valid Parameter Value",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        // cancelButtonColor: '#d33',
        confirmButtonText: "ok",
      });
      reject();
    }

  }


  const handleDelete = (oldData, resolve, reject) => {
    if (oldData.productparameterid != undefined) {
      trashProductparameters(oldData.productparameterid)
        .then((res) => {
          GetParameters();
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        })
    } else {

      const dataDelete = [...parameters];
      const index = oldData.tableData.id;
      dataDelete.splice(index, 1);
      setParameters([...dataDelete]);
      const dataRemove = [...dataList];
      dataRemove[index].parametervalue = "";
      setdataList(dataRemove)
      resolve();  

    }

  }

  const validate = () => {
    let formError = {};
    let isValid = true;
    let regex = /^[A-Za-z0-9 ]+$/;

    if (productname === "") {
      isValid = false;
      formError["productName"] = "please Enter product Name";
    } else if (regex.test(productname) === false) {
      isValid = false;
      formError["productName"] =
        "Please Enter Minimun 3 Characters and do not use special Character";
    }
    if (selectedCategory.length < 1) {
      isValid = false;
      formError["categoryselected"] = "Please select category";
    }

    if (productImage1.length < 1) {
      isValid = false;
      formError["productImage1"] = "Please select Image"

    }
    if (productImage2.length < 1) {
      isValid = false;
      formError["productImage2"] = "Please select Image"

    }
    if (productImage3.length < 1) {
      isValid = false;
      formError["productImage3"] = "Please select Image"

    }
    if (productImage4.length < 1) {
      isValid = false;
      formError["productImage4"] = "Please select Image"

    }
    if (productImage5.length < 1) {
      isValid = false;
      formError["productImage5"] = "Please select Image"

    }


    setFormError(formError);
    return isValid;
  };

  const addData = async () => {
    const isValid = validate();

    if (isValid) {

      const data = {
        productName: productname,
        ProductImage1: productImage1.name,
        productImage2: productImage2.name,
        productImage3: productImage3.name,
        productImage4: productImage4.name,
        productImage5: productImage5.name,
        brandId: brandid,
        categoryId: selectedCategory.value,
        productparameters: parameters
      }


      let formdata = new FormData();
      // let formdata2 = new FormData();
      // let formdata3 = new FormData();
      // let formdata4 = new FormData();
      // let formdata5 = new FormData();

      formdata.append("file", productImage1);
      formdata.append("file", productImage2);
      formdata.append("file", productImage3);
      formdata.append("file", productImage4);
      formdata.append("file", productImage5);
      formdata.getAll("file")

      await image_upload(formdata);
      // await image_upload(formdata2);
      // await image_upload(formdata3);
      // await image_upload(formdata4);
      // await image_upload(formdata5);


      Addproduct(data)
        .then((res) => {
          if (res.data._response.status === 1) {

            Swal.fire({
              icon: "success",
              title: "Added Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            getProductList();
            cancel();

          }
          else if (res.data._response.status === 2) {
            Swal.fire({
              icon: "warning",
              title: res.data._response.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err)=>{
          console.log(err,"err in add product");
      })

    }
  }

  const image_upload = async (data) => {

    await ProductPhotos(data)
      .then((res) => {
        console.log("photo res", res);

      })
      .catch((err) => {
        console.log(err, "err in photo submit");
      });
  };

  const getdata = (id) => {
    setShowlist(false);
    setupdate(true);
    setProductID(id.productid)
    setProductname(id.productname);
    setProductImage1(id.productimage1);
    setProductImage2(id.productimage2);
    setProductImage3(id.productimage3);
    setProductImage4(id.productimage4);
    setProductImage5(id.productimage5);
    setSelectedCategory({ value: id.categoryid, label: id.categoryname })
  }

  const editData = async () => {
    setShowlist(false);
    const data = {
      productId: productID,
      productName: productname,
      productImage1: productImage1.name === undefined ? productImage1 : productImage1.name,
      productImage2: productImage2.name === undefined ? productImage2 : productImage2.name,
      productImage3: productImage3.name === undefined ? productImage3 : productImage3.name,
      productImage4: productImage4.name === undefined ? productImage4 : productImage4.name,
      productImage5: productImage5.name === undefined ? productImage5 : productImage5.name,
      brandId: brandid,
      categoryId: selectedCategory.value,
      productparameters: parameters

    };
    let formdata = new FormData();
    // let formdata2 = new FormData();
    // let formdata3 = new FormData();
    // let formdata4 = new FormData();
    // let formdata5 = new FormData();
    formdata.append("file", productImage1);
    formdata.append("file", productImage2);
    formdata.append("file", productImage3);
    formdata.append("file", productImage4);
    formdata.append("file", productImage5);

    if (productImage1.name !== undefined)
    if (productImage2.name !== undefined)
    if (productImage3.name !== undefined)
    if (productImage4.name !== undefined)
    if (productImage5.name !== undefined)

    formdata.getAll("file")
    await image_upload(formdata);
    editProduct(data)
      .then((response) => {
        if (response.data._response.status === 1) {

          Swal.fire({
            icon: "success",
            title: "Updated Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
          getProductList();
          cancel();
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        console.log(`err in update:${err}`);
      });
  };


  const DeleteProduct = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          deleteProduct(id.productid)
            .then((res) => {
              if (res.data._response.status === 1) {
              Swal.fire({
                icon: "success",
                title: "Product Deleted Successfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              getProductList();
            }else if(res.data._response.status === 2){
              Swal.fire({
                icon: "warning",
                title: res.data._response.message,
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok!",
            });
        }
    })
    .catch((err)=>{
      console.log(err,'err in deleteproduct');
    })
}
})
      .catch((err) => {
        console.log(err, "err in trash product");
      });
  };

  const cancel = () => {
    setSelectedCategory([]);
    setProductname('')
    setupdate(false);
    inputRefrence1.current.value = null;
    inputRefrence2.current.value = null;
    inputRefrence4.current.value = null;
    inputRefrence3.current.value = null;
    inputRefrence5.current.value = null;
    setFormError([]);
    setimage1Path("");
    setimage2Path("");
    setimage3Path("");
    setimage4Path("");
    setimage5Path("");
    setParameters([]);
    setProductID(0)
    setdataList([]);
    setProductImage1("");
    setProductImage2("");
    setProductImage3("");
    setProductImage4("");
    setProductImage5("");

  }

  return (

    <>
      <div>
        <form>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container">
                <br /><br />
                <div className="row">
                  {
                    !showlist ?
                      <div className="col-sm-12">
                           {!update ? (
                        <h1 className="m-0 text-center">Add Product</h1>
                        ):
                        <h1 className="m-0 text-center"> Edit Product </h1>
                         }
                      </div>
                      :
                      <div className="col-sm-12">
                        <h1 className="m-0 text-center">Product List</h1>
                      </div>
                  }
                </div>

                <Col md={12} >
                  <div className='d-flex justify-content-end'>
                    {
                      !showlist ?
                        <button
                          type="button"
                          className="btn btn-pos3 "
                          onClick={() => {
                            setShowlist(true);
                            cancel();
                          }}
                        >
                          <i className="fa-solid fa-list"></i> List
                        </button>
                        :
                        <button
                          type="button"
                          className="btn btn-pos4 "
                          onClick={() => setShowlist(false)}
                        >
                          <i className="fa fa-arrow-circle-left"></i> Back
                        </button>
                    }
                  </div>
                </Col>
              </div>
            </div>
            <section className="content consti-section">
              <div className="container">
                <div className="row">
                  {
                    showlist === false ?
                      <Col md={12}>
                        <Card style={{ padding: "20px" }}>
                          {/* {!isEditable ? ( */}
                          <>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalEmail"
                            >
                              <Col sm={6}>
                                <Form.Label className="w-100">
                                  Product Name
                                </Form.Label>

                                <input
                                  className="w-100 form-control"
                                  type="text"
                                  name="productname"
                                  value={productname}
                                  onChange={handleProductName}
                                />

                                <div style={{ color: "red" }}>
                                  {formError.productName}
                                </div>
                              </Col>
                              <Col sm={6}>
                                <Form.Label className="w-100">
                                  Category
                                </Form.Label>

                                <Select
                                  className="w-100"
                                  options={Categories}
                                  onChange={handleCategory}
                                  value={selectedCategory}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                                <div style={{ color: "red" }}>
                                  {formError.categoryselected}
                                </div>
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formHorizontalEmail"
                            >
                              <Col className='mt-4' md={12}>

                                <MaterialTable
                                  title="Parameters"
                                  columns={columns1}
                                  data={dataList}
                                  options={{
                                    search: false
                                  }}
                                  editable={{
                                    onBulkUpdate: newData =>
                                      new Promise((resolve, reject) => {
                                        handleRowupdate(newData, resolve, reject);
                                      }),
                                    onRowDelete: oldData =>
                                      new Promise((resolve, reject) => {

                                        handleDelete(oldData, resolve, reject)
                                      }),
                                  }}
                                />
                              </Col>

                            </Form.Group>

                            <Form.Group
                              as={Row}
                              className="mb-3 justify-content-center"
                              // controlId="formHorizontalEmail"
                            >
                              <Col sm={6} className="mb-4">
                                <Form.Label className="w-100"> Image 1</Form.Label>
                                <FormControl className="m-2" type="file" name='file' controlId={productImage1} ref={inputRefrence1} onChange={handleimage1} />
                                <div style={{ color: "red" }}>{formError.productImage1}</div>
                              </Col>
                              <Col sm={6} className="mb-4">
                                <Form.Label className="w-100"> Image 2</Form.Label>
                                <FormControl className="m-2" type="file" name='file' controlId={productImage2} ref={inputRefrence2} onChange={handleimage2} />
                                <div style={{ color: "red" }}>{formError.productImage2}</div>
                              </Col>

                              <Col md={6}>
                                <div className="imagepreview">
                                  <img alt=" " width="80" height="60" src={update === false ? image1Path : productImage1.name === undefined ? PhotoPath + productImage1 : image1Path} />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="imagepreview">
                                  <img alt=" " width="80" height="60" src={update === false ? image2Path : productImage2.name === undefined ? PhotoPath + productImage2 : image2Path} />
                                </div>
                              </Col>

                              <Col sm={6} className="mb-4">
                                <Form.Label className="w-100"> Image 3</Form.Label>
                                <FormControl className="m-2" type="file" name='file' controlId={productImage3} ref={inputRefrence3} onChange={handleimage3} />
                                <div style={{ color: "red" }}>{formError.productImage3}</div>
                              </Col>
                              <Col sm={6} className="mb-4">
                                <Form.Label className="w-100"> Image 4</Form.Label>
                                <FormControl className="m-2" type="file" name='file' controlId={productImage4} ref={inputRefrence4} onChange={handleimage4} />
                                <div style={{ color: "red" }}>{formError.productImage4}</div>
                              </Col>
                              <Col md={6}>
                                <div className="imagepreview">
                                  <img alt=" " width="80" height="60" src={update === false ? image3Path : productImage3.name === undefined ? PhotoPath + productImage3 : image3Path} />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="imagepreview">
                                  <img alt=" " width="80" height="60" src={update === false ? image4Path : productImage4.name === undefined ? PhotoPath + productImage4 : image4Path} />
                                </div>
                              </Col>
                              <Col sm={6} className="mb-4">
                                <Form.Label className="w-100"> Image 5</Form.Label>
                                <FormControl className="m-2" type="file" name='file' controlId={productImage5} ref={inputRefrence5} onChange={handleimage5} />
                                <div style={{ color: "red" }}>{formError.productImage5}</div>
                              </Col>
                              <Col sm={6} className="mb-4"></Col>
                              <Col md={6}>
                                <div className="imagepreview">
                                  <img alt=" " width="80" height="60" src={update === false ? image5Path : productImage5.name === undefined ? PhotoPath + productImage5 : image5Path} />
                                </div>
                              </Col>
                              <Col md={6}></Col>
                            </Form.Group>

                            <div className="button_style m-0 text-center">
                              {update === false ? (
                                <Button
                                  type="button"
                                  variant='contained'
                                  onClick={() => addData()}
                                >
                                  Submit
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  variant='contained'
                                  color='success'
                                  onClick={() => editData()}
                                >
                                  Update
                                </Button>
                              )} {" "}
                              <Button
                                type="button"
                                variant='contained'
                                color='error'
                                onClick={() => cancel()}
                              >
                                Cancel
                              </Button>
                            </div>
                          </>

                          {/* )} */}
                        </Card>
                      </Col>
                      :
                      <Col className='mt-5' md={12} >
                        <MaterialTable
                          title="Products"
                          data={allProducts}
                          columns={columns}
                          actions={[
                            {
                              icon: "edit",
                              tooltip: "Edit",
                              onClick: (event, rowData) => {
                                getdata(rowData);
                              },
                            },

                            {
                              icon: "delete",
                              tooltip: "Delete",
                              onClick: (event, rowData) => {
                                DeleteProduct(rowData);
                              },
                            },
                          ]}
                          options={{
                            search: false,
                            pageSize: 5,
                            showFirstLastPageButtons: false,
                            actionsColumnIndex: -1,
                            addRowPosition: "first",
                          }}
                        />
                      </Col>
                  }
                </div>
              </div>
            </section>
          </div>
        </form>
      </div>
    </>
  )
}
