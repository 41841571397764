import './App.css';
import { BrowserRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import MainPageContainer from './container/MainPageContainer';
import Content from './components/pages/HomeRoutes';
import MetaDataComponent from './components/pages/Citizen/MetaDataComponent';

function App(props) {


  return (

    <div>
      <Router>
        {/* <MetaDataComponent /> */}

        <div className="App">
          <Content />
          <MainPageContainer />
          {/* </MetaDataComponent> */}
        </div>
      </Router>
    </div>

  );

}

export default App;
