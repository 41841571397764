import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export default function MetaDataComponent(props) {



  let location = useLocation();
  let currentUrl = "https://topbrandsranking.com" + location.pathname;
  let quote = props.quote !== undefined ? props.quote : "";
  let title = props.title !== undefined ? props.title : "CampersTribe - World is yours to explore";
  let image = props.image !== undefined ? "../../../../public/logo192.png" : "../../../../public/logo192.png";
  let description = props.description !== undefined ? props.description  : "description";
  let hashtag = props.hashtag !== undefined ? props.hashtag : "#camperstribe";

  return (
    
    <Helmet encodeSpecialCharacters={true}
    titleTemplate="topbrandsranking - %s"
    defaultTitle="topbrandsranking">
   
    <title>Topbrands_Rank</title>
    <meta charset="utf-8" />
    <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
    
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta name="description" content="TopBrandsRanking app" />
    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
    integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous" />

    <link rel="stylesheet" href="path/to/font-awesome/css/font-awesome.min.css"/>

    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="csrf_token" content="" />
    <meta property="type" content="website" />
    <meta property="url" content={currentUrl} />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
    <meta name="msapplication-TileColor" content="#ffffff" />
    <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
    <meta name="theme-color" content="#ffffff" />
    <meta name="_token" content="" />
    <meta name="robots" content="noodp" />
    <meta property="title" content={title} />
    <meta property="quote" content={quote} />
    <meta name="description" content='TopBrandsRanking app' />
    <meta property="image" content={image} />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:quote" content={quote} />
    <meta property="og:hashtag" content={hashtag} />
    <meta property="og:image" content= {image}/>
    <meta content="image/*" property="og:image:type" />
    <meta property="og:url" content={currentUrl} />
    <meta property="og:site_name" content="topbrandsranking" />
    <meta property="og:description" content='TopBrandsRanking app' />   
    </Helmet>
  )
}
