import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container, Form } from "react-bootstrap";
import Select from "react-select";
import Footer from '../../common/footer/Footer';
import { ProductwithPage, productbyCategoryandBrand, Allbrands, Categorywithnoparent } from "./ProductServiceAPI";
import axios from 'axios';
import Swal from "sweetalert2";
import ProductURL from "../../ServerCalls/ProductServer";
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@material-ui/core'
import Loader from "../../common/Loader";
import NotFound from "../../common/NotFound";
import { Link } from "react-router-dom";
import { FcInfo, FcFeedback } from "react-icons/fc";
import IconButton from "@mui/material/IconButton";
import { BsShareFill } from 'react-icons/bs';
import { Pagination } from "@mui/material";

const PhotoPath = ProductURL.photoURL + "ProductImages/";


export default function HomeProducts() {

  const [productsList, setProductList] = useState([]);
  const [categorySelected, setcategorySelected] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [brandSelected, setBrandSelected] = useState([]);
  const [brandOption, setBrandOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageC, setPageC] = useState(8)
  const [pageNumber, setPageNumber] = useState(1);
  const dataPerPage = 8;

  useEffect(() => {
    if (brandSelected.length !== 0 || categorySelected.length !== 0) {
      getbyBrandandCategoryId(1);
    } else {
      setPageNumber(1)
      products(1, 8);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandSelected, categorySelected])

  useEffect(() => {

    getCategory();
    getBrand();

  }, []);



  const changePage = (e, a) => {
    setPageNumber(a);
    if (brandSelected.length !== 0 || categorySelected.length !== 0) {
      getbyBrandandCategoryId(a, dataPerPage)
    } else {
      products(a, dataPerPage);

    }
  }
  const products = (a, b) => {
    setLoading(true);

    axios.get(`https://api.topbrandsranking.com/ProductManagement/GetHomeProductWithPagination?PageNo=${a}&PageSize=${b}`)
      .then((res) => {
        if (res.data._response.status === 1) {
          let product = res.data._response.data.getproductwithpagination;
          let count = res.data._response.data.count;
          let APIpagecount = Math.ceil(count / dataPerPage);

          setProductList(product);
          setPageC(APIpagecount)
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "errr");
        setLoading(false)
      });
  };

  const getbyBrandandCategoryId = (pgno) => {
    setLoading(true);
    let brandid = brandSelected.value === undefined ? 0 : brandSelected.value;
    let categoryid = categorySelected.value === undefined ? 0 : categorySelected.value;
    // productbyCategoryandBrand(brandid, categoryid, pgno)
    axios.get(`https://api.topbrandsranking.com/ProductManagement/HomeProductGetByCombination?brandid=${brandid}&categoryid=${categoryid}&PageNo=${pgno}&PageSize=${dataPerPage}`)

      .then((res) => {
        const pdata = res.data._response.data.productgetid
        if (pdata.length <= 0) {
          setProductList([])
          setLoading(false);
        } else {
          let count = res.data._response.data.count;
          let APIpagecount = Math.ceil(count / dataPerPage)

          setProductList(pdata)
          setPageC(APIpagecount)
          setLoading(false);
        }

      }).catch((error) => {
        setLoading(false)
        Swal.fire({
          icon: "error",
          title: "Product not Found",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });

      })
  }

  const getCategory = () => {
    axios.get(`https://api.topbrandsranking.com/ProductManagement/GetHomeProductCategoryWithoutNoParent`).then((res) => {
      const category = res.data._response.data.getprocategory
      let categorydata = category.map((list) => ({
        value: list.categoryid,
        label: list.categoryName
      }))
      setCategoryOption(categorydata)
    }).catch((err) => {
      console.log(err);
    })
  }

  const handleCategory = (selected) => {

    selected ? setcategorySelected(selected) : setcategorySelected([]);

  }

  const handleBrand = (selected) => {

    selected ? setBrandSelected(selected) : setBrandSelected([]);
  }

  const getBrand = () => {
    axios.get(`https://api.topbrandsranking.com/ProductManagement/GetHomeBrand`)
      .then((res) => {

        if (res.data._response.status === 1) {
          let data1 = res.data._response.data.getbrand;
          let options = data1.map((d) => ({
            value: d.brandId,
            label: d.brandName,
          }));

          setBrandOption(options);
        }
      })
      .catch((err)=>{
        console.log(err,'err in get homeproduct');
      })

  }

  const renderCard = (e, index) => {

    return (

      // <section className="all-brands-body">
      <div className="col-md-3 col-sm-6 pb-5" >
        {/* <Container fluid>
        <br/>
        <Row>
          <Col sm={3}> */}
        <Card className="all-brands-card">
          <Card.Body>
            <Card.Title> {e.brandname}</Card.Title>
            <div className="product-image">
              <img className='news-image' variant="top" src={PhotoPath + e.productimage1} alt="" style={{ objectFit: 'contain' }} />
            </div>
            <Card.Text className="mt-4">
              {e.productname}
            </Card.Text>
            <Card.Link href="#">See More</Card.Link>
            {/* <Card.Link href="#">Another Link</Card.Link> */}
          </Card.Body>
        </Card>
        {/* </Col>
        </Row>
       </Container> */}
      </div>
      // <br/>
      // </section>


    )
  }


  return (

    <>
      {/* <Header /> */}
      <div>
        <div className="">
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <br /><br />
                <div className="row mb-2">
                  <div className="col-sm-12">
                    <h1 className="m-0 text-center">Brand Ranking</h1>
                  </div>
                </div>
              </div>
            </div>
            <section className="content  mt-4 consti-section">
              <div className="container">
                <div className="row">
                  <Col md={12}>
                    <Card style={{ padding: "20px" }}>
                      <Form.Group
                        as={Row}
                        className="justify-content-center"
                        controlId="formHorizontalEmail"
                      >

                        <Col sm={6} className="mb-4">

                          <h5>
                            Select Brand
                          </h5>
                          <Select
                            isClearable={brandSelected}
                            className="w-100"
                            options={brandOption}
                            onChange={handleBrand}
                            value={brandSelected}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                        </Col>

                        <Col sm={6} className="mb-4">
                          <h5>
                            Select Category
                          </h5>
                          <Select
                            isClearable={categorySelected}
                            className="w-100"
                            options={categoryOption}
                            onChange={handleCategory}
                            value={categorySelected}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                        </Col>
                      </Form.Group>
                      {/* <Button className="detailsbtn">Clear</Button> */}
                    </Card>
                  </Col>
                </div>
              </div>
            </section>

            <Container>
              <card-group className="d-flex" style={{ marginTop: "36px" }}>
                <Col md={12} className="mb-2">
                  {loading ?
                    <Loader />
                    :
                    productsList.length !== 0 ?

                      <Row sm="5"> {productsList.map(renderCard)} </Row>
                      :
                      <NotFound name="Product Not Found" />
                  }
                </Col>
              </card-group>
            </Container>
            <br /><br />
            {/* <Pagination_comp/> */}
            {
              productsList.length !== 0 ?
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Pagination
                    count={pageC}
                    page={pageNumber}
                    onChange={changePage}
                    shape="rounded" color="primary" size="large"
                  />
                </Box>
                :
                null
            }
            <br /><br />
          </div>
        </div>
      </div>
      <Footer />

    </>

  )
}
