import React, { useState, useEffect } from 'react';
import { Col, Row, CardGroup, Card, Button, Container } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom'
import Auth from '../../ServerCalls/Auth';
import { ProductbyCategories } from '../Admin/ProductServiceAPI';
import ProductURL from "../../ServerCalls/ProductServer";
import Loader from '../../common/Loader';
import NotFound from '../../common/NotFound';
export default function ProductCategory1() {
    const PhotoPath = ProductURL.PhotoUrl + "ProductImages/";
    const navigate = useNavigate();
    const { id } = useParams();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        productlist();
    }, [id])


    const productlist = () => {
        setLoading(true);
        ProductbyCategories(id).then((res) => {

            if (res.data._response.status === 1) {

                setProducts(res.data._response.data.productgetid)
                setLoading(false)
            }

        })
            .catch((err) => {
                console.log(err, "err in get products by ctegories");
                setLoading(false)
            })
    }

    return (

        <>
            <div>
                <card-group className="d-flex" style={{ marginTop: "36px" }}>
                    <Row sm="5" >
                        {
                            loading ?
                                <Loader />
                                :
                                products.length !== 0 ?
                                    products && products.map((e, index) => {
                                        return (
                                            <>
                                                <Col key={index}>
                                                    <Container>
                                                        <Card
                                                            className="gallerycard"

                                                            style={{
                                                                width: "230px",
                                                                height: "280px",
                                                                marginLeft: "300px",
                                                                marginTop: "15px",
                                                            }}
                                                        >
                                                            <Card.Body>
                                                                <Card.Img
                                                                    src={PhotoPath + e.productimage1}
                                                                    width="150px"
                                                                    height="150px"
                                                                />
                                                                <Card.Text className="Cname">{e.productname}</Card.Text>{" "}
                                                                <div className="favbutton">
                                                                    <Link
                                                                        className="btn btn-warning more"
                                                                        //  onClick={() => moreInfo(e)}
                                                                        to={`/product-details/${e.productid}`}
                                                                        title="More info"
                                                                    >

                                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                    </Link>
                                                                    &nbsp;
                                                                    <Link
                                                                        className="btn btn-primary fr"
                                                                        title="Add Feedback"
                                                                        to={`/feedback/${e.productid}`}
                                                                    >
                                                                        <i className="fa fa-commenting" aria-hidden="true"></i>
                                                                    </Link>

                                                                    <Button
                                                                        className="sh"
                                                                        // onClick={() => openModal()}
                                                                        title="Share"

                                                                    >
                                                                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                                                                    </Button>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                        <div>
                                                        </div>

                                                    </Container>

                                                </Col>

                                            </>
                                        )
                                    })
                                    :
                                    
                                    <NotFound/>
                        }
                    </Row>
                </card-group>


                <div className="button_style mt-3 text-center">
                    <button
                        type="button"
                        className="btn btn-pos2 m-4"
                        onClick={() => navigate(-1)}
                    >
                        <i className="fa-solid fa-circle-left"></i>&nbsp;Back
                    </button>
                </div>
            </div>
        </>
    )
}
