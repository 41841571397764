import React, { useState, useEffect } from 'react'
import { Col, Row, Card, Form } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { parameterValueTypeList, ParamtersList, AddParamtere, updateParameter, trashParamter } from './ProductServiceAPI';
import Swal from "sweetalert2";
import Select from 'react-select';
import Button from '@mui/material/Button';
import axios from "../../ServerCalls/AxiosInstance";





const Parameters = () => {


    const [parameterData, setparameterData] = useState([]);
    const [parameterName, setparameterName] = useState([]);
    const [update, setupdate] = useState(false);
    const [parameterDataID, setparameterDataID] = useState('');


    //error handling
    const [parameterNameError, setparameterNameError] = useState('');



    const columns = [

        { title: "Parameter Name", field: "parameterName", align: "center" },

    ];

    const handlename = (e) => {

        setparameterName(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''));
        setparameterNameError('')

    }

    useEffect(() => {

        getList();

    }, []);

    const getList = () => {

        ParamtersList()
            .then((res) => {
                if (res.data._response.status === 1) {

                    let data = res.data._response.data.getparameter
                    setparameterData(data)
                }

            })
            .catch((err) => {

                console.log(err, "err in parameters Data");

            })
    }

    const FormValidation = () => {

        let parameterNameError = '';
        let parameterTypeError = '';
        let regex = /^[A-Za-z0-9 ]+$/;


        if (parameterName === '') {

            parameterNameError = "Please fill the Details";

        } else if (regex.test(parameterName) === false) {

            parameterNameError = 'Please do not use special Character'
        }else if(parameterName.length > 100)
        {
            parameterNameError = "Maximum 100 characters are allowed"
        }

        if (parameterNameError) {

            setparameterNameError(parameterNameError);


            return false;
        }

        return true;
    }

    const saveData = () => {

        const isValid = FormValidation();

        if (isValid) {

            const allData = {

                parameterName: parameterName,

            }

            AddParamtere(allData)
                .then((res) => {
                    if (res.data._response.status === 1) {

                        Swal.fire({
                            icon: "success",
                            title: "Parameter Added Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });

                        getList();
                        ClearData();
                    } else if (res.data._response.status === 2) {

                        Swal.fire({
                            icon: "warning",
                            title: res.data._response.message,
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        getList();
                        ClearData();
                    }

                })
                .catch((err) => {

                    console.log(err, "err in add parameters data");

                })
        }
    }

    const getData = (id) => {
        setparameterDataID(id.parameterId)
        setupdate(true);
        setparameterName(id.parameterName)

    }

    const editUser = () => {

        const isValid = FormValidation();
        if (isValid) {

            const allData = {

                parameterId: parameterDataID,
                parameterName: parameterName,

            }

            updateParameter(allData)
                .then((res) => {
                    if (res.data._response.status === 1) {

                        Swal.fire({
                            icon: "success",
                            title: "Parameter Updated Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        getList();
                        ClearData();
                    }

                })
                .catch((err) => {
                    console.log(err, "NEw ERR")

                })
        }
    }

    const deleteData = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                trashParamter(id)
                 axios.delete(`https://localhost:5002/api/ProductManagement/RemoveParameter/${id}`)
                    .then((res) => {
                        if (res.data._response.status === 1) {

                            Swal.fire({
                                icon: "success",
                                title: "Parameter Deleted Sucessfully",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });
                            getList();

                        }else if(res.data._response.status === 2){
                            Swal.fire({
                                icon: "warning",
                                title: res.data._response.message,
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });
    

                        }

                    })
                   
            }
        })
            .catch((err) => {
                console.log(err, "err in trash parameters data");
            })
    }

    const ClearData = () => {

        setparameterName('');
        setupdate(false);
        setparameterNameError('');
    }

    return (
        <>

            <div>
                <div>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                            <br/><br/>
                                <div className="row mb-2">

                                    <div className="col-sm-12">
                                        {!update? (
                                        <h1 className="m-0 text-center para-heading">PARAMETER INFORMATION</h1>
                                        ):
                                        <h1 className="m-0 text-center para-heading">EDIT PARAMETER INFORMATION</h1>
                                    }
                                       

                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="content">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <Col md={12}>
                                        <Card className='mt-4' style={{ padding: "20px" }}>
                                            <Form.Group as={Row} className="justify-content-center" controlId="formHorizontalEmail">
                                                
                                                    <Form.Label className="w-100 pb-1">Parameter Name</Form.Label>
                                                    <input className="w-100 form-control" type="text" placeholder='Parameter Name' value={parameterName} onChange={handlename} />
                                                    <div style={{ fontSize: 13, color: "red" }}>{parameterNameError}</div>
                                                
                                            </Form.Group>
                                            <div className="button_style m-0 text-center">
                                             
                                                {
                                                    update === false ?

                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            onClick={() => saveData()}
                                                        >
                                                            Submit
                                                        </Button>
                                                        :
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="success"
                                                            onClick={() => { editUser() }}
                                                        >
                                                            Update
                                                        </Button>
                                                } {" "}

                                                <Button
                                                    type="clear"
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => ClearData()}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col className='mt-4' md={12}>
                                        <MaterialTable
                                            title={false}
                                            data={parameterData}
                                            columns={columns}
                                            actions={[
                                                {
                                                    icon: "edit",
                                                    tooltip: "Edit",
                                                    
                                                    onClick: (event, rowData) => {
                                                        // setNameError("")
                                                        getData(rowData);
                                                    },
                                                },
                                                {
                                                    icon: "delete",
                                                    tooltip: "Delete",
                                                    onClick: (event, rowData) => {
                                                        console.log(rowData,"got rowdata")

                                                        deleteData(rowData.parameterId);
                                                    },
                                                },
                                            ]}
                                            options={{
                                                search: true,
                                                pageSize: 5,
                                                showFirstLastPageButtons: false,
                                                actionsColumnIndex: -1,
                                                addRowPosition: "first",
                                                pageSizeOptions: [10, 15, 20, 50],
                                            }}
                                        />
                                    </Col>
                                </div>
                            </div>
                            <br/> <br/>
                        </section>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Parameters