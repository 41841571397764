import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Card, Container } from "react-bootstrap";
import Select from "react-select";
import Button from '@mui/material/Button';
import { campaignData, getbyCountyReport,getCountyReport} from '../ProductServiceAPI'
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Loader from '../../../common/Loader'
import { useReactToPrint } from 'react-to-print'
import Chart from 'react-apexcharts';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import NotFound from "../../../common/NotFound";
import axios from "../../../ServerCalls/AxiosInstance";
import { login } from '../../../../redux/action/authAction';



const Countyreport = () => {

    const [campaignOptions, setCampaignOptions] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [Countydata, setCountydata] = useState([]);
    const [CountyTotal, setCountyTotal] = useState([]);
    const [loading, setloading] = useState(false)
    const [Total, setTotal] = useState([])
  

    const useStyles = makeStyles({

        table: {


            "& .MuiTableCell-root": {

                border: "1px solid rgba(0, 0, 0, 0.3)"

            }

        }

    });

    const classes = useStyles();


    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })



    const handleCampaign = (selected) => {
        setSelectedCampaign(selected)
    }


    const clear = () => {

        setSelectedCampaign('')

    }



    useEffect(() => {

        campaignList();
        if(selectedCampaign != ''){

            reports();
            
        }    
    }, [selectedCampaign])


    const campaignList = () => {
        campaignData().then((res) => {

            let arr = [];
            let list = res.data._response.data.getpollingcampaign;

            list.map((a) => (
                arr.push({
                    label: a.pollingCampaignShortName,
                    value: a.campaignId,
                })
            ))
            setCampaignOptions(arr)
        }) .catch((err)=>{
            console.log(err,"err in get campaignlist");
        })
    }


    const reports = () => {
        setloading(true)
        //  axios.get(`https://localhost:5002/api/ProductManagement/CountyReport?campaignid=${selectedCampaign.value}`)
           getbyCountyReport(selectedCampaign.value)
            .then((res) => {
               

                console.log(res, "got prouct county report");


                if (res.data._response.status === 1) {
                
                    let County = res.data._response.data.individualproductcounty;
                     let overallcounttotal =  res.data._response.data.countytotal
                     let TotalProductCounty = res.data._response.data.countywisetotalofallproducts;


                     setTotal(overallcounttotal);
                 
                    setCountydata(County);
                     setCountyTotal(TotalProductCounty);
                   
                    setloading(false);
                  
                } else if (res.data._response.status === 2) {
                  
                  
                    // setOverallCountyCount([]);
                    setCountydata([]);
                    setTotal([]);
                    setCountyTotal([]);
               
                setloading(false);
                   
                }
            }) .catch((err)=>{
                setloading(false);
                console.log(err,"err in get voting report");
            })
    }






    return (
        <div>
        <div>
            <div className="content-wrapper consti-section" id="divToPrint">
                <div className="content-header">
                    <div className="container-fluid">
                        <br /><br />
                        <div className="row mb-4">
                            <div className="col-sm-12">
                                <h1 className="m-0 text-center">Voting List</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container">
                        <div className="row">
                            <Col md={12}>
                                <Card style={{ padding: "20px" }}>
                                    <Form.Group
                                        as={Row}
                                        className="mb-1 justify-content-center"
                                        controlId="formHorizontalEmail"
                                    >

                                        <Col sm={12} className="mb-4">
                                            <Form.Label className="w-100">Campaign</Form.Label>
                                            <Select
                                                className="w-100"
                                                options={campaignOptions}
                                                value={selectedCampaign}
                                                onChange={handleCampaign}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <div className="button_style m-0 text-center">

                                        <Button
                                            type="button"
                                            variant='contained'
                                            color='error'
                                            onClick={clear}
                                        >
                                            Cancel
                                        </Button>
                                    </div>

                                </Card>
                            </Col>

                        </div>

                    </div>

                </section>

            </div>
            <br></br>
        </div>
        <br /><br />

        {
        loading ? 

        <Loader />
        :
        selectedCampaign === '' || Total === 0 ? (

            <>
            <NotFound/>
            </>

        ) :
            <>
                <div ref={componentRef}>
                    <h1 className="m-0 text-center">Voting  Report Summary</h1>
                    <h4 className="m-0 text-center">{selectedCampaign.label}</h4>
                    <br></br>

                    <Container>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report">
                                <TableContainer >

                                    <div className="mt-5">
                                        <h2>County Wise</h2>
                                       
                                        <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                            <TableHead >

                                                <TableRow className="backgroundrow">
                                                    <TableCell></TableCell>
                                                    <>
                                                        {Countydata.map((data, id) => (
                                                            <TableCell width="10%" align="center" rowSpan={2} className="head1">{data.productname}</TableCell>
                                                         ))} 


                                                    </>

                                                    <TableCell align="center" width="10%" rowSpan={2} className="head1">Total</TableCell>

                                                    
                                                </TableRow>


                                            </TableHead>
                                            <TableBody>
                                                
                                                   {Countydata.map((a, i)=>{
                                                      
                                                           if(i === 0){
                                                            return(
                                                            a.c.map((f)=>(
                                                                <TableRow>
                                                            <TableCell width="10%"  align="center" >{f.cn}</TableCell>
                                                          {  Countydata.map((data,ind)=>{
                                                        
                                                              let count = data.c.find((g)=>g.cn === f.cn);
                                                              let total = CountyTotal.find((t)=> t.countyname === f.cn )

                                                              if(Countydata.length - 1 !== ind){
                                                                return(
                                                                    <TableCell width="10%" align="center"  >{count.count}</TableCell>
                                                                   
                                                                   
                                                               
                                                                )
                                                              }
                                                               if(Countydata.length - 1 === ind){
                                                                return(
                                                                    <>
                                                                    <TableCell width="10%" align="center"  >{count.count}</TableCell>
                                                                    <TableCell width="10%" align="center"  >{total.count}</TableCell>
                                                                    </>
                                                                   
                                                                   
                                                               
                                                                )
                                                              }
                                                               
                                                               
                                                            })}

                                                        

                                                            </TableRow> 
                                                               ))
                                                       )

                                                           }
                                                        
                                                        
                                                    
 } )}

                                                    

                                                <TableRow>

                                                    <TableCell align="left" className="backgroundrow2"  >Total Votes</TableCell>

                                                {Countydata.map((total, e) => ( 
                                                        <TableCell align="center">{total.overallcount}</TableCell>
                                                  ))} 
                                                    <TableCell className="headerbgcolor" align="center">{Total}</TableCell> 



                                                </TableRow>

                                            </TableBody>

                                        </Table>

                                        </div>
             


                                </TableContainer>
                            </Paper>
                    </Container>
                </div>
                <br></br>
                <div className="text-center m-0">
                    &nbsp;&nbsp;
                    <Button variant="contained" onClick={handlePrint}>Print</Button>
                </div>

            </>

        }





    </div>
  )
}

export default Countyreport