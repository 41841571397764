
import React from 'react'
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { parameter_bycategory_List, ParamtersList, addParameters } from './ProductServiceAPI'
import { Container, Col } from 'react-bootstrap';
import Swal from 'sweetalert2'

export default function ParametersCategory() {

  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state;

  const [categoryData, setcategoryData] = useState(id);
  const [addData, setaddData] = useState([]);
  const [gotData, setgotData] = useState([]);
  const [formError, setFormerror] = useState([]);


  useEffect(() => {
    getlist()
    parametrs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getlist = () => {
    parameter_bycategory_List(categoryData.categoryid)
      .then((res) => {
        if (res.data._response.status === 1) {
          let selecteddata = res.data._response.data.parametercategorygetcategoryid
          let data = []
          selecteddata && selecteddata.map((x) => {
            data.push({ label: x.parametername, value: x.parameterid, categoryid: x.categoryId })
          })
          setgotData(data);
        }
      })
      .catch((err) => {
        console.log(err, "error in tooo");
      })

  }

  const parametrs = () => {
    ParamtersList()
      .then((res) => {

        if (res.data._response.status === 1) {

          let data = res.data._response.data.getparameter;

          let parametersData = data.map((a) => ({
            value: a.parameterId,
            label: a.parameterName
          })
          )
          setaddData(parametersData)
        }

      })
      .catch((err) => {
        console.log(err, "err in parameter list");
      })

  }
  const handleAdd = (a) => {
    let add_data = [...gotData]
    let Fdata = add_data.filter((e) => e.label !== a.label)

    setgotData([...Fdata, a])
    setFormerror({...formError,gotData:''})

  }

  const formValidate = () => {
    let formError = {};
    let isValid = true;

    if (gotData.length < 1) {
      isValid = false;
      formError["gotData"] = "Please select parameters";
    }

    setFormerror(formError)
    return isValid;

  }


  const saveData = () => {

    const isValid = formValidate();

    if (isValid) {
      let product = [];
      gotData && Object.entries(gotData).map(([a, b]) => {
        product.push({ ParameterId: b.value, categoryId: categoryData.categoryid, });
      })

      addParameters(product)
        .then((res) => {
          if (res.data._response.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Data Added Sucessfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            getlist();
          }
        })
        .catch((err) => {
          console.log(err, "err in add parameter");
        })

    }

  }

  const deleteRow = (index) => {

    let Ddata = gotData.filter((e) => e !== index)

    setgotData(Ddata)


  }

  return (
    <>
      <div>
        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-12">
                    <h1 className="m-0 text-center">Category Parameters Relation</h1>
                  </div>
                </div>
              </div>
            </div>

            <section className="content  consti-section">
              <div className="container-fluid">
                <div className="row">

                  <Container>
                    <Col sm={4} md={{ span: 8, offset: 2 }}>
                      <div className='form-control'>{categoryData.categoryName}</div>
                    </Col>	&nbsp;	&nbsp;
                    <div className='row'>
                      <Col md={{ span: 3, offset: 2 }}>
                        <fieldset className=''>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Select Parameters</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                addData && addData.map((a) => (
                                  <tr>
                                    <td onClick={() => handleAdd(a)}>
                                      {a.label}
                                    </td>
                                  </tr>

                                ))
                              }

                            </tbody>
                          </table>
                        </fieldset>
                      </Col>

                      <Col md={{ span: 3, offset: 2 }}>
                        <fieldset className=''>
                          {/* <section > */}
                          <table className="table table-bordered for">
                            <thead>
                              <tr>
                                <th scope="col">Selected Parameters</th>
                              </tr>
                            </thead>
                            {

                              <>
                                <tbody>
                                  {
                                    gotData && gotData.map((a) => (
                                      <tr>
                                        <td>

                                          {/* &nbsp;&nbsp; */}
                                          {a.label}
                                          <button className='btn btn-primary cancelbtn' onClick={() => deleteRow(a)} >
                                            <i className="fa-solid fa-xmark"></i>
                                          </button>
                                        </td>
                                      </tr>

                                    ))
                                  }
                                </tbody>
                              
                              </>
                            }
                          </table>
                          <div style={{ color: "red" }}>{formError.gotData}</div>
                        </fieldset>
                      </Col>
                      <div className='button_style m-3 text-center'>
                        <button
                          type="submit"
                          className="btn btn-pos2"
                          onClick={() => saveData()}
                        >
                          Add Parameters
                        </button>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className='button_style m-3 text-center'>
                  <button
                    type="submit"
                    className="btn btn-pos2 m-4"
                    onClick={() => navigate(-1)}
                  >
                    <i className="fa-solid fa-circle-left"></i>&nbsp;Back
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
