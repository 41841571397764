import React, { useState, useEffect } from "react";
import './learnmore.css';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Image from 'react-bootstrap/Image'
import share from '../../../assets/img/share.png';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FaShareAlt ,FaVoteYea} from 'react-icons/fa';
import { MdMore } from 'react-icons/md';
import axios from 'axios';
import Swal from "sweetalert2";
import bcrypt from "bcryptjs";
import { Link } from "react-router-dom";
import { Pagination } from "@mui/material";
import { Box } from '@material-ui/core'
import Loader from "../../common/Loader";
import { campaignData, campaignWithPagination } from "../../pages/Admin/ProductServiceAPI";
import NotFound from "../../common/NotFound";
import Auth from '../../ServerCalls/Auth';
import voting from '../../../assets/img/voting.png';
import ProductURL from "../../ServerCalls/ProductServer";
import { Modal } from "react-bootstrap";
import { FacebookIcon,FacebookShareButton,WhatsappShareButton,WhatsappIcon,TwitterShareButton,TwitterIcon,} from 'react-share';
import CollapsibleExample from "../NewHeader";
const PhotoPath = ProductURL.photoURL + "CampaignImages/";

export default function MoreProductCampaign() {

  const token = Auth.getToken();
  const [campaignShortName, setCampaignShortName] = useState([]);

  const [loading, setLoading] = useState(false);
  const [pageC, setPageC] = useState(8)
  const [pageNumber, setPageNumber] = useState(1);
  const [Sharehashid,setSharehashid] = useState(0)

  const [ShareCampaign,setShareCampaign] = useState(0);
  const [showB, setShowB] = useState(false);

  const dataPerPage = 8;

  // const hash = bcrypt.hashSync(ShareCampaign)
 console.log(ShareCampaign, "got hash password")

  const changePage = (e, a) => {
    setPageNumber(a);
    campaignlist(a,dataPerPage);
  }

  const title = 'Campaign Information';
  const imgf = "https://pixabay.com/photos/flowers-meadow-sunlight-summer-276014/"

  useEffect(() => {
    setPageNumber(1)
    campaignlist(1, 8);

  }, [])

  // const campaignlist = () => {
  //   setLoading(true)
  //   campaignData()
  //     .then((res) => {
  //       if (res.data._response.status === 1) {
  //         setLoading(false)
  //         let data = res.data._response.data.getpollingcampaign
  //         let filterlist = [];
  //         data && data.map((a) => {

  //           if (new Date(a.pollingCampaignEndDate) >= new Date()) {
  //             filterlist.push(a);
  //           }

  //         })
  //         setCampaignShortName(filterlist)

  //       }


  //     })
  //     .catch((err) => {
  //       console.log(err, "err in home");
  //     })


  // }
  const campaignlist = (a,b) => {
    setLoading(true)
     campaignWithPagination(a,b)
    // axios.get(`https://localhost:5002/api/ProductManagement/GetPollingCampaignWithPagination?PageNo=${a}&PageSize=${b}`)
      .then((res) => {
        if (res.data._response.status === 1) {

          let data = res.data._response.data.getpollingcampaign
          let count = res.data._response.data.count;
          let APIpagecount = Math.ceil(count / dataPerPage)
          // let filterlist = [];
          // data && data.map((a) => {

          //   if (new Date(a.pollingCampaignEndDate) >= new Date()) {
          //     filterlist.push(a);
          //   }

          // })
          setCampaignShortName(data);
          setPageC(APIpagecount);
          setLoading(false)

        }


      })
      .catch((err) => {
        console.log(err, "err in home");
      })


  }


  const openModal = (list)=> {
    let id1 =  btoa(list.campaignId)

    // let id1 =  btoa(list.campaignId)
    //  setSharehashid(id)
     setShareCampaign(id1)
// if(
//   showB === true){
//    setShareCampaign(id)

//  }else{
//    setShareCampaign(id1)

//  }
   
    setShowB(true);
  }

  const handleClose = () => {
    setShowB(false);
  };


  // const openid = ()=>{

  //   setShareCampaign(Sharehashid)



  // }


  return (
    <>

      {
        !token ?
          <CollapsibleExample />
          :
          null

      }

      <div>
        <div className="">
          <div className="content-wrapper">
            <section >
              
              <div className='container' >
                <h2 className='trend-heading m-0 text-center pt-4 pb-4' >FAVOURITE <span>BRAND</span></h2>
                <div className='row'>
                  {loading === true ?
                    <Loader />
                    :

                    campaignShortName.map((list, id) => (
                      <div className='col-md-3 pb-4'>
                      
                              <div className="brandcateg card-blog">
                                <div className="cardcateg-image">
                                  <div><img className="img" src={PhotoPath + list.pollingImage} alt='' />
                                  </div>
                                  <div className="ripple-cont"></div>
                                </div>
                                <Card.Body>
                                  <div className="table">
                                    <p className="category">{list.pollingCampaignShortName}</p>
                                    <p className="cardcateg-description">{list.categoryname}</p>
                                  </div>
                                  <div className='d-flex justify-content-between'>
                              <IconButton className='text-end'><FaShareAlt fontSize="25px" color='#CD0404' onClick={() => openModal(list)} title="click to share" cursor="pointer" /></IconButton>
                              <Tooltip title="Click To Vote">
                                {token?(
                                 <Link className='text-decoration-none' to={`/productVoting/${list.campaignId}/${list.pollingCampaignEndDate}/${list.pollingCampaignShortName}/${list.pollingquestion}/${list.pollingCampaignProductCategoryId}`}><IconButton className='text-end'>
                                  <FaVoteYea fontSize="25px" color='#000' />
                                </IconButton>
                                </Link>
                                ):
                                <Link to="/subregister" state={{ from: list.campaignId, date: list.pollingCampaignEndDate, name: list.pollingCampaignShortName, question: list.pollingquestion, page: "authorisedcampaigndetails", categoryid: list.pollingCampaignProductCategoryId }}><IconButton className='text-end'>
                                <FaVoteYea fontSize="25px" color='#000' />
                              </IconButton>
                              </Link>
                              }
                                </Tooltip>

                            </div>
                                </Card.Body>
                              </div>

                      </div>
                    ))}

                  {
                    campaignShortName.length !== 0 ?
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <Pagination
                          count={pageC}
                          page={pageNumber}
                          onChange={changePage}
                          shape="rounded" color="primary" size="large"
                        />
                      </Box>
                      :
                      null
                  }
                </div>
                <br/>

              </div>
            </section>
          </div>
        </div>
      </div>



      <Modal
        show={showB}
        onHide={handleClose}
        size="sm"
        backdrop="static"
        centered
      >
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="mx-auto">
            <h5>
              <b>Share Product</b>
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <Link to="https://web.whatsapp.com/"> */}
          <Row className="text-center">
            <Col sm={4}>
              <FacebookShareButton
                url={`https://topbrandsranking.com/SharedCampaign/${ShareCampaign}`}
                quote={title}
                className="Demo__some-network__share-button"
               
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </Col>
            <Col sm={4}>
              < WhatsappShareButton
                 url={`https://topbrandsranking.com/SharedCampaign/${ShareCampaign}`}
                quote={title}
                // onClick={openid}
                // img ={imgf}
                // imageurl={imgf}
                // <meta property="og:image" content="//cdn.example.com/uploads/images/webpage_300x200.png" />
                className="Demo__some-network__share-button"
              >
                < WhatsappIcon size={32} round />
              </ WhatsappShareButton>
            </Col>
            <Col sm={4}>
            < TwitterShareButton
                 url={`https://topbrandsranking.com/SharedCampaign/${ShareCampaign}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
              <TwitterIcon size={32} round />
              </ TwitterShareButton>
            </Col>
            {/* <Col sm={3}>
            < InstapaperShareButton
                url={`http://tbr.softlabsgroup.in/ShareProductDetails/${productID}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
              <InstapaperIcon size={32} round />
              </ InstapaperShareButton>
            </Col> */}
          </Row>
        </Modal.Body>
      </Modal>
      <Footer />
    </>


  )
}
