import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { UserMenu } from '../static/Menus';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AppBar, Toolbar, IconButton, Typography, Button, Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, useNavigate } from 'react-router-dom';
import ListItemLinkContainer from '../container/ListItemLinkContainer';
import { useStyles } from '../static/MiniDrawerStyles';
import Auth from '../components/ServerCalls/Auth';
import logo1 from '../assets/img/logo1.png';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { AdminMenu } from '../static/AdminMenu';
import { BrandMenu } from '../static/BrandMenu';
import ProductURL from "../../src/components/ServerCalls/ProductServer";
import { getNewsbyid, GetNotificationbyid,getandEditNews } from "./pages/Admin/ProductServiceAPI"
import { Modal } from "react-bootstrap";
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const inlineStyles = {
  spacer: {
    flex: 1,
  },
  badgeFix: {
    display: "inline-flex",
  },
};

function MiniDrawer(props) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClosed = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();
  const theme = useTheme();
  const { openSideBar, handlerSideBar } = props;
  const [view, setView] = useState(false);
  const [NewsList, setNewsList] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [Notification, setNotification] = useState([]);
  const [NotificationCount, setNotificationCount] = useState([]);
  const token = Auth.getToken();
  const role = Auth.getUserRole();
  const memberID = Auth.getUserIdF();

 

  const backclr = {
    backgroundColor: "white",
  };

  const backclr2 = {
    backgroundColor: "rgb(197 226 245)",
    fontWeight :'bold'
  };

  const logout = () => {
    Auth.logout();
  };
  const newsPhoto = ProductURL.photoURL + "ProductNewsImages/";

  const handleClose2 = () => {
    setView(false);
    getAllNotification()     

  };

  useEffect(() => {
    getAllNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getAllNotification = () => {
    GetNotificationbyid(memberID)
      .then((response) => {
        let newslist = response.data._response.data.getnewsnotifications;
        let countnotifications = response.data._response.data.count;
        setNewsList(newslist);
        setNotificationCount(countnotifications);
      })
      .catch((err)=>{
        console.log(err,'err in get all notification');
      });
  };

  const openNotification = (event) => {
    setAnchorEl(event.currentTarget);
    getAllNotification();
  };

  const viewNewsDetailsModal = (id) => {
    getNewsbyid(id.newsid)
      .then((response) => {
        let newsdata = response.data._response.data.productnewsgetid;
        setNewsData(newsdata);
        setView(true);
          //  axios.get(`https://localhost:5002/api/ProductManagement/GetAndEditNewsNotificationsById/${id.notificationid}`)
          getandEditNews(id.notificationid)
          .then((response) => {
            const notification = response.data._response.data.newsnotificationsgetid[0]
            setNotification(notification)   
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    setAnchorEl(false);

  
  };

  const openFeedback =(notificationid)=> {
    // axios.get(`https://localhost:5002/api/ProductManagement/GetAndEditNewsNotificationsById/${notificationid}`)
    getandEditNews(notificationid)
    .then((response) => {
      getAllNotification();   
      navigate( role === 3 ?  '/feedbackReply' : role === 2 ? '/userAllfeedback' : null)
    })
    .catch((err) => {
      console.log(err);
    });
 

  }




  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openSideBar,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Notifications"
            onClick={handlerSideBar}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: openSideBar,
            })}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            m={2}
            style={{ color: "white" }}
            component={Link}
            to="/"
            variant="h6"
            noWrap
          ></Typography>

          <Grid container justifyContent='flex-end'>
            <Stack direction="row" spacing={2}>
              {/* <Tooltip title="Notifications">
                <IconButton
                  onClick={handleClick}
                  size="large"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                
                </IconButton>
              </Tooltip> */}

              {!token ? null : (
                <>
                  {
                    role === 2 || role === 3 ?
                      <>
                        <span style={inlineStyles.spacer}></span>
                        <IconButton aria-label="Notifications">
                          {
                            NotificationCount.length === 0 ? 

                            <NotificationsIcon style={{ color: 'white', fontSize: "2rem" }} onClick={openNotification} />
                           :(
                              <Badge badgeContent={NotificationCount} color="secondary" >

                              <NotificationsIcon className="notification" style={{ color: 'white', fontSize: "1.7rem" }} onClick={openNotification} />
                            </Badge>

                            )}
                        
                        </IconButton>
                      </>
                      :
                      null
                  }

                  <Button color="inherit" onClick={() => logout()} >Logout</Button>
                </>
              )}
            </Stack>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openSideBar,
          [classes.drawerClose]: !openSideBar,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openSideBar,
            [classes.drawerClose]: !openSideBar,
          }),
        }}
        open={openSideBar}
      >
        <div className={classes.toolbar}>
          <span className='text-start'>
            <Typography style={{ color: 'white', textAlign: 'left' }} component={Link} to='/' variant="h6" noWrap>
              {/* <img className='text-start' src={logo1} width='100px' alt='logo' /> */}
            </Typography>
          </span>
          <IconButton onClick={handlerSideBar}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>

        <Divider />
        <div>
          <img className='text-start mt-2' src={logo1} width='75px' alt='logo'
            style={{ objectFit: 'contain' }}
          />
           </div>
           <Divider />

        {role === 1 ? (
          <nav className={classes.lists}>
            <List>
              {AdminMenu.map((menu, index) => (
                <React.Fragment key={index}>
                  <ListItemLinkContainer menuObj={menu} />
                </React.Fragment>
              ))}
            </List>
          </nav>
        ) : role === 2 ? (
          <nav className={classes.lists}>
            <List>
              {UserMenu.map((menu, index) => (
                <React.Fragment key={index}>
                  <ListItemLinkContainer menuObj={menu} />
                </React.Fragment>
              ))}
            </List>
          </nav>
        ) : role === 3 ? (
          <nav className={classes.lists}>
            <List>
              {BrandMenu.map((menu, index) => (
                <React.Fragment key={index}>
                  <ListItemLinkContainer menuObj={menu} />
                </React.Fragment>
              ))}
            </List>
          </nav>
        ) : null}

        <Divider />
      </Drawer>

      <Menu
        anchorEl={anchorEl}
        id="long-menu"
        open={open}
        onClose={handleClosed}
        //  onClick={() => viewNewsDetailsModal(list.newsid)}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 500,
            height: 400,
            overflowY: "scroll",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 56,
              height: 56,
              ml: -0.5,
              mr: 1,
            },

            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {NewsList.map((list, id) => {


          return (

            list.newsid === null  ?
              <MenuItem style={list.viewnotification === 0 ? backclr : backclr2} onClick={()=>openFeedback(list.notificationid)}>
           
                <Avatar alt="rdj Sharp" src="https://d23.com/app/uploads/2019/08/2019-disneylegend-rdj.jpg" /> <Typography variant="inherit" noWrap  >
                  new Feedback Reply
                </Typography>
              
              </MenuItem>
              : <>
                <MenuItem onClick={() => viewNewsDetailsModal(list)} style={list.viewnotification === 0 ? backclr : backclr2}>
               
             
                  <Avatar alt="rdj Sharp" src="https://d23.com/app/uploads/2019/08/2019-disneylegend-rdj.jpg" /> <Typography variant="inherit" noWrap  >
                    {list.newsheader}
                  </Typography>
                </MenuItem>
              </>
          )        
        })}
      </Menu>
      <Modal
        show={view}
        onHide={handleClose2}
        size="md"
        backdrop="static"
        centered
      >
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="">
            <h5 className="text-center">News Details</h5>
          </Modal.Title>
        </Modal.Header>

        {newsData.map((list) => (
          <Modal.Body>
            <div className="container newscard">
              <article className="card shadow">
                <div className="row">
                  <img
                    className="rounded-circle"
                    src={newsPhoto + list.newsimage}
                    alt="news"
                  />
                  <strong>
                    <p className="text-center mt-2 mx-auto">
                      {list.newsheader}
                    </p>
                  </strong>
                </div>
                <div>
                  <p className="ml-5 mt-2">{list.newsdescription}</p>
                </div>
              </article>
            </div>
          </Modal.Body>
        ))}
      </Modal>
    </React.Fragment>
  );
}

export default MiniDrawer;
