import React from 'react';
import { DeviceSetupPage } from "../pages/DeviceSetupPage";
import { Settings, DesktopMac, Store, AccountBox } from "@material-ui/icons";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import CollectionsIcon from '@mui/icons-material/Collections';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import GroupIcon from '@mui/icons-material/Group';



export const UserMenu = [



    {
        id: 2,
        menuName: 'Profile',
        menuDescription: 'Device mac id setup menus',
        path: '/UserDetails',
        exact: true,
        icon: <ManageAccountsIcon />,
        submenu:[]

    },

    {
        id: 1,
        menuName: 'Product',
        menuDescription: 'Device mac id setup menus',
        path: '#',
        exact: true,
        icon: <ProductionQuantityLimitsIcon />,
        submenu: [
           
            {
                id: 2,
                menuName: 'Product Voting',
                menuDescription: 'Device mac id setup menus',
                path: '/campaignList',
                component: DeviceSetupPage,
                exact: true,
                icon: <HowToVoteIcon />,

            },
              
            {
                id: 2,
                menuName: 'Brand Video Voting',
                menuDescription: 'Device mac id setup menus',
                path: '/Video_Campaign_List',
                component: DeviceSetupPage,
                exact: true,
                icon: <HowToVoteIcon />,

            },
            {
                id: 2,
                menuName: 'Brand Ranking',
                menuDescription: 'Device mac id setup menus',
                path: '/UserProductGallery',
                component: DeviceSetupPage,
                exact: true,
                icon: <CollectionsIcon />,

            },
            {
                id: 2,
                menuName: 'Feedback History',
                menuDescription: 'Device mac id setup menus',
                path: '/userAllfeedback',
                component: DeviceSetupPage,
                exact: true,
                icon: <FeedbackIcon />,

            },
        ]
    },

    {
        id: 1,
        menuName: 'Celebrity',
        menuDescription: 'Device mac id setup menus',
        path: '#',
        exact: true,
        icon: <GroupIcon />,
        submenu: [

            {
                id: 2,
                menuName: 'Celebrity Voting',
                menuDescription: 'Device mac id setup menus',
                path: '/CelebrityCampaignList',
                // component: DeviceSetupPage,
                exact: true,
                icon: <HowToVoteIcon />,

            },
            {
                id: 2,
                menuName: 'Celebrity Ranking',
                menuDescription: 'Device mac id setup menus',
                path: '/CelebrityGallery',
                component: DeviceSetupPage,
                exact: true,
                icon: <CollectionsIcon />,

            },
        ]
    },
];