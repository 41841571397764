import React from "react";
import Auth from "../../ServerCalls/Auth";


import { Navigate, Outlet } from 'react-router-dom'


const PrivateComp = () => {
    const auth = Auth.getToken();
    const role = Auth.getUserRole();


    return auth && role === 2 ? <Outlet /> : <Navigate to="/" />
}


export default PrivateComp;