import React,{useState,useEffect} from 'react';
import CollapsibleExample from "../../components/common/NewHeader";
import Footer from '../../components/common/footer/Footer';
import axios from "axios";
import ProductURL from "../ServerCalls/ProductServer";
import { Rating } from 'react-simple-star-rating';
import { Box } from '@material-ui/core';
import { Pagination } from "@mui/material";
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';




const BrandRankingList = () => {

  const [pageC, setPageC] = useState(4);
  const [brand, setBrand] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
const ProductPhoto = ProductURL.photoURL + "ProductImages/";



  const dataPerPage = 4;

  const changePages = (e, a) => {
    setPageNumber(a);
    Topbrandproduct(a, dataPerPage);

  }






  useEffect(() => {
    Topbrandproduct(1, 4)
  }, [])


  const Topbrandproduct = (a, b) => {
    axios.get(`https://api.topbrandsranking.com/ProductManagement/TopProductGetByProductId?PageNo=${a}&PageSize=${b}`)
      .then((res) => {
        // console.log(res,"got ppppppppp")
        let data = res.data._response.data.topproductgetbyproductid3
        let count = res.data._response.data.count;
        let APIpagecount = Math.ceil(count / dataPerPage);
        setPageC(APIpagecount)
        setBrand(data)
      })
  }




  return (
   <>
    <CollapsibleExample />

   <Container>
    <div>
    <h2 className='m-0 text-center trend-heading pt-4 pb-4'>BRAND <span>RANKING</span></h2>
    </div>
   <div className="row mt-4">
            {brand.map((a) => (
              <div className="col-md-3 col-sm-6 pb-5">
                <Card className='h-100'>

                  <div className="product-grid">
                    <div className="product-image">
                      <div className="image" >
                        <img className="pic-1" alt='' src={ProductPhoto + a.productimage1} style={{ objectFit: 'fill' }} />
                        <img className="pic-2" alt='' src={ProductPhoto + a.productimage1} style={{ objectFit: 'fill' }} />

                      </div>

                    </div>

                    <div className="product-content">
                      <Card.Title className='product-title h4'>{a.brandname}</Card.Title>

                      <div className="price h6">{a.productname}
                      </div>

                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Rating
                        ratingValue={a.avergae}
                        readonly
                        size='20px'

                      />
                      <div className="price">Total Ratings:&nbsp;{(a.avergae).toFixed(2)}%</div>


                    </div>
                  </div>
                </Card>

              </div>

            ))}
          </div>

              <Box display="flex" alignItems="center" justifyContent="center">
                          <Pagination
                              count={pageC}
                              page={pageNumber}
                              onChange={changePages}
                              shape="rounded" color="primary" size="large"
                          />
                      </Box>
                      <br/>
   </Container>

    <Footer/>
    </>
  )
}

export default BrandRankingList