import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form } from "react-bootstrap";
import Select from "react-select";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import {
  postMenudata,
  getMenudata,
  editMenudata,
  deleteMenudata,
} from "./ProductServiceAPI";
import Button from '@mui/material/Button';


const MenuData = () => {
  const [menu, setmenu] = useState([]);
  const [menuId, setmenuId] = useState(0);
  const [menuselected, setmenuselected] = useState([]);
  const [data, setData] = useState([]);
  const [update, setupdate] = useState(false);
  const [menuselectOption, setmenuselectOption] = useState([]);
  const [error, setError] = useState([]);

  const columns = [
    { title: "Menu Name", field: "menutype", align: "center" },
    { title: "Parent Menu ", field: "parentmenutype", align: "center" },
  ];

  const formValidation = () => {
    let error = {};
    let isValid = true;
    let regex = /^[A-Za-z0-9 ]+$/;

    if (menu.length < 1) {
      isValid = false;
      error["menu"] = "please fill menu";
    } else if (menu.length < 3 || regex.test(menu) === false) {
      isValid = false;
      error["menu"] =
        "Please Enter Minimun 3 Characters and do not use special Character";
    }

    if (menuselected.length < 1) {
      isValid = false;
      error["menuselected"] = "please select menu";
    }

    setError(error);
    return isValid;
  };

  const handleMenu = (selected) => {
    setmenuselected(selected);
    setError({ ...error, menuselected: "" });
  };

  const handleInput = (e) => {
    setmenu(e.target.value);
    setError({ ...error, menu: "" });
  };

  const saveData = () => {
    const isValid = formValidation();
    if (isValid == true) {
      let data = {
        MenuType: menu,
        parentMenuId: menuselected.value,
      };

      postMenudata(data)
        .then((response) => {
          if (response.data._response.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Menu Added Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            getList();
            setmenu("");
            setmenuselected("");
          } else {
            Swal.fire({
              icon: "error",
              title: response.data._response.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getList();
    getParentMenu();
  }, []);

  const getList = () => {
    getMenudata()
      .then((res) => {
        setData(res.data._response.data.getmenu);
      })
      .catch((err) => {
        console.log(err, "err in menu getlist");
      });
  };

  const getParentMenu = () => {
    getMenudata()
      .then((res) => {
        let list = res.data._response.data.getmenu;
        const options = list.map((a) => ({
          value: a.menuid,
          label: a.menutype,
        }));
        setmenuselectOption(options);
      })
      .catch((err) => {
        console.log(err, "err in get parent menu");
      });
  };

  const editUser = (a) => {
    setupdate(true);
    setmenu(a.menutype);
    setmenuId(a.menuid);
    setmenuselected({ value: a.parentmenuid, label: a.parentmenutype });
  };

  const updateClick = () => {
    const data = {
      menuId: menuId,
      menuType: menu,
      parentmenuId: menuselected.value,
    };
    editMenudata(data)
      .then((res) => {
        if (res.data._response.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Menu Updated Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
          getList();
          setmenu("");
          setmenuselected("");
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Menu Not Added",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      });
  };

  function deleteUser(menuid) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          deleteMenudata(menuid)
            .then((result) => {
              Swal.fire({
                icon: "success",
                title: "Menu Deleted Sucessfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });

              getList();
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Menu Not Deleted",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in trash menu");
      });
  }

  const ClearData = () => {
    setmenu("");
    setmenuselected("");
    setError("");
  };

  return (
    <>
      <div>
        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <br/><br/>
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="m-0 text-center">Menu</h1>
                  </div>
                </div>
              </div>
            </div>

            <section className="content  consti-section">
              <div className="container">
                <div className="row">
                  <Col md={12}>
                    <Card style={{ padding: "20px"}}>
                      <Form.Group
                        as={Row}
                        className="justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={6} className="mb-4">
                          <Form.Label className="w-100 pb-1">
                            Menu Title
                          </Form.Label>
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="menu"
                            placeholder="Enter your menu"
                            value={menu}
                            onChange={handleInput}
                          />

                          <div style={{ color: "red" }}>{error.menu}</div>
                        </Col>

                        <Col sm={6} className="mb-4">
                          <Form.Label className="w-100 pb-1">
                            Parent Menu Title
                          </Form.Label>

                          <Select
                            className="w-100"
                            options={menuselectOption}
                            onChange={handleMenu}
                            value={menuselected}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />

                          <div style={{ color: "red" }}>
                            {error.menuselected}
                          </div>
                        </Col>
                      </Form.Group>

                      <div className="button_style m-0 text-center">
                        {update === false ? (
                          <Button
                            type="button"
                            variant='contained'
                            onClick={saveData}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                           variant='contained'
                           color='success'
                            onClick={() => updateClick()}
                          >
                            Update
                          </Button>
                        )}
                       {" "}{" "}
                        <Button
                          type="button"
                          variant='contained'
                          color='error'
                          onClick={() => ClearData()}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Card>
                  </Col>
                  <Col className='mt-4' md={12} >

                  <MaterialTable
                    title={false}
                    data={data}
                    columns={columns}
                    actions={[
                      {
                        icon: "edit",
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                          editUser(rowData);
                        },
                      },
                      {
                        icon: "delete",
                        tooltip: "Delete",
                        onClick: (event, rowData) => {
                          deleteUser(rowData.menuid);
                        },
                      },
                    ]}
                    options={{
                      search: false,
                      pageSize: 5,
                      showFirstLastPageButtons: false,
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                    }}
                  />
                  </Col>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuData;
