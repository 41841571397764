import React, { useEffect, useState } from 'react'
import { Col , Row} from "react-bootstrap";
import axios from 'axios';
import ProductURL from '../../ServerCalls/ProductServer';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Document, Page, } from 'react-pdf/dist/esm/entry.webpack';
import NotFound from '../../common/NotFound';
import Loader from "../../common/Loader";
import Button from '@mui/material/Button';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import CollapsibleExample from '../../common/NewHeader';

const PhotoPath = ProductURL.photoURL + "MagazinePdf/";


export default function Rankingdata() {

  const [allMagazines, setAllMagazines] = useState([])
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    //  setLoading(true)
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }


  useEffect(() => {
    getlist();

  }, [])
  const saveFile = () => {
    window.open(`https://media.topbrandsranking.com/MagazinePdf/${allMagazines.pdffile}`, "_blank");
    // saveAs(
    //   `https://media.topbrandsranking.com/MagazinePdf/${allMagazines.pdffile}`,
    //   `${allMagazines.pdffile}`
    // );
  };
  const getlist = () => {
    setLoading(true);

    axios.get(`https://api.topbrandsranking.com/ProductManagement/GetMagazine`)
      //   axios.get(`https://localhost:5002/api/ProductManagement/GetMagazine`)
      .then((res) => {
        if (res.data._response.status === 1) {
          setLoading(false);
          let data = res.data._response.data.getmagazine
          let topBrands = [];

          data && data.map((a) => {
            if (a.magazinetype === 1) {
              topBrands.push(a);

            }
          })
          setAllMagazines(topBrands[0]);
        }
      })
      .catch((err)=>{
        console.log(err,'err in get magazine');
      })
  }

  

  return (
    <>
      <CollapsibleExample />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <br /><br />
            <div className="row mb-2">
              <div className="col-sm-12">
                <h1 className="m-0 text-center"> Ranking Magazine </h1>
              </div>
              {
                loading ?
                  // <Loader />
                  null
                  :
                  Object.keys(allMagazines).length !== 0 ?
                    <Col md={12} >
                      <div className='d-flex justify-content-end'>
                        <button
                          type="button"
                          className="btn btn-pos3 "
                          onClick={(e) => {
                            saveFile(e)
                          }}
                        >
                          <i className="fa-solid fa-down"></i>Download PDF
                        </button>
                      </div>
                    </Col>
                    :
                    <NotFound />
              }
            </div>

            <Row>
            <section className="">
          <div className="container">
            <div className='row'>
              <div className='col-sm-12'>

              
            <>
            <div className='row'>
              <div className='col-sm-12'>
              <div className="viewer" width="100%">
                {Object.keys(allMagazines).length !== 0 ?
                  <Document file={PhotoPath + allMagazines.pdffile}
                    options={{ workerSrc: "/pdf.worker.js" }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<Loader />}>
                    
                    <Page pageNumber={pageNumber} />
                  </Document>
                  : null}
              </div>
              </div>
              </div>
            </>
            <div className='d-flex justify-content-center pt-4 pb-4'>
              
              <Button variant='contained' type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                Previous
              </Button>
              &nbsp;
              <Button variant='contained'
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}>
                Next
              </Button>
            </div>
            <div className='d-flex justify-content-center  pb-4'>
            <p>
                Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
              </p>
            </div>
            </div>

            </div>
          </div>
        </section>
            </Row>
          </div>
        </div>
       
        <Footer />
      </div>
      
    </>
  )
}
