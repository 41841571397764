import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
// import { VerifyEmailData } from './LoginRegisterAPI';
import check from '../../../assets/img/check.png';

import './EmailVerification.css';
import swal from 'sweetalert';
import axios from 'axios';



const EmailVerification = () => {
    const [data, setData] = useState({
        verifyEmail: true,
        token: "",
    });







    let [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const user = searchParams.get("user")
        setData({ ...data, token: user })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const checkEmailVerification = (e) => {
        e.preventDefault();
        let data1 = {
            token: data.token
        }
        axios.post("https://api.topbrandsranking.com/Authorization/verify-email", data1).then((res) => {
            if (res.status === 200) {
                window.location.href = "/login";
                swal("Email Verified Succussfully", "", "success")
            }
            else {
                swal("Not verified", "", "error");
            }
        }).catch((err)=>{
            console.log(err,"err in email verification");
        })
    }

    return (
        <div>
            <Modal show={data.verifyEmail} id="verifyEmail" className="modal-min verifyEmailModal animate__animated animate__zoomIn" aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body className="modal-body m-0 text-center">
                    <div>
                        <img src={check}  alt=''/>
                    </div>
                    <br />
                    <h1 style={{ fontWeight: '600' }}>Verify Your Email</h1>
                    <br />
                    <form onSubmit={checkEmailVerification}>
                        <Button type="submit" className="btn btn-primary shadow-none" size='lg'>Click here to Verify</Button>
                    </form>
                </Modal.Body>
            </Modal>

        </div>
    )
}
export default EmailVerification