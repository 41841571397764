import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import Header from '../../common/header/Header';
import { Link, useNavigate } from "react-router-dom";
import {
  MemberImage, Agelist, NoAgelist
} from "../Admin/ProductServiceAPI";
import logo1 from "../../../assets/img/logo1.png";
import Select from "react-select";
import ProductURL from '../../ServerCalls/ProductServer';
import "./Registration.css";
import DatePicker from "react-datepicker";
import DefaultImg from '../../../assets/img/default.jpg'
import Swal from "sweetalert2";
import Auth from '../../ServerCalls/Auth';
import Button from '@mui/material/Button';
import axios from "../../ServerCalls/AxiosInstance";
import Cleave from 'cleave.js/react';
import "cleave.js/dist/addons/cleave-phone.ke";
import { AddFirstForm, Addgender, Addage, Addimage, Addimagepath, ClearForm } from '../../../redux/action/registerAction';
import { useDispatch, useSelector } from 'react-redux';
import { store } from "../../../redux/store";
import { GoogleLogin } from '@react-oauth/google';
import { BsFacebook } from 'react-icons/bs'
import IconButton from '@mui/material/IconButton';
import { AiFillHome } from 'react-icons/ai';
import jwt_decode from "jwt-decode";
import FacebookLogin from 'react-facebook-login';
import TextField from '@mui/material/TextField';


const Registration = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state.registeruser.userDetails);
  const [user, setUser] = useState({
    firstName: "",
    password: "",
    confirmPassword: "",
    email: "",
    contactNumber: "",
  });
  const [UserImage, setUserImage] = useState([]);
  const [UserImagePath, setUserImagePath] = useState('');
  const [update, setupdate] = useState(false);
  const [genderSelected, setgenderSelected] = useState([]);
  const [AgeSelected, setAgeSelected] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [googleLogin, setGoogleLogin] = useState('');

  const PhotoPath = ProductURL.productPhoto + "MemberMasterImages/";
  const inputRefrence1 = useRef(null);
  const token = Auth.getToken();
  const dispatch = useDispatch()
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    dispatch(AddFirstForm(e))
    if (e.target.name === "firstName") {
      setFirstNameErr("");
    }
    else if (e.target.name === "age") {
      setageError("");
    } else if (e.target.name === "gender") {
      setgenderError("");
    } else if (e.target.name === "password") {
      setPasswordErr("");
    } else if (e.target.name === "email") {
      setEmailErr("");
    } else if (e.target.name === "contactNumber") {
      setContactErr("");
    } else if (e.target.name === "confirmPassword") {
      setConfirmPasswordErr("");
    }
  };
  //error handlling
  const [FirstNameErr, setFirstNameErr] = useState("");
  const [ageError, setageError] = useState("");
  const [genderError, setgenderError] = useState("");
  const [PasswordErr, setPasswordErr] = useState("");
  const [ConfirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [EmailErr, setEmailErr] = useState("");
  const [ContactErr, setContactErr] = useState("");
  const [imgErr, setImgErr] = useState("");
  const formValidate = () => {
    let redux_data = store.getState();
    let data = redux_data.registeruser.userDetails
    let FirstNameErr = "";
    let ageError = "";
    let genderError = "";
    let PasswordErr = "";
    let ConfirmPasswordErr = "";
    let EmailErr = "";
    let ContactErr = "";
    // let imgErr = "";
    let regex = /^[a-z]+$/i;
    let emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let contactregx = /^[6-9]\d{9}$/gi;
    let passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (data.firstName !== undefined) {
      if (data.firstName.length > 25) {
        FirstNameErr = "FirstName cannot exceed 25 characters";
      }
    }
    if (regex.test(data.firstName) === false) {
      FirstNameErr = "Please enter alphabetic characters and no spaces";
    }
    if (data.firstName === "") {
      FirstNameErr = "Please enter Your First Name";
    }
    if (Object.keys(data.age).length === 0) {
      ageError = "Please select Age";
    }
    if (data.email === "") {
      EmailErr = "Please enter Your Email";
    }
    if (emailregex.test(data.email) === false) {
      EmailErr = "Please enter a valid email";
    }
    if (data.contactNumber === "") {
      ContactErr = "Please enter Your Contact";
    }
    else if (data.contactNumber.length > 20) {
      ContactErr = 'Please enter valid phone number';
    }
    if (data.confirmPassword !== data.password) {
      ConfirmPasswordErr = "Password And Confirm Password Do not match";
    }
    if (data.password === "") {
      PasswordErr = "Please Enter your Password";
    }
    else if (data.password.length < 8) {
      PasswordErr = "Entered Password must be of atleast 8 Characters !!";
    }
    if (data.confirmPassword === "") {
      ConfirmPasswordErr = "Please enter Your Confirm password";
    }
    if (Object.keys(data.gender).length === 0) {
      genderError = "Please select Your Gender";
    }
    // if (data.userImagePath.length < 1) {
    //   imgErr = "Please select Image"

    // }
    if (
      FirstNameErr ||
      ageError ||
      genderError ||
      PasswordErr ||
      ConfirmPasswordErr ||
      EmailErr ||
      // imgErr ||
      ContactErr
    ) {
      setFirstNameErr(FirstNameErr);
      setEmailErr(EmailErr);
      setContactErr(ContactErr);
      setPasswordErr(PasswordErr);
      setConfirmPasswordErr(ConfirmPasswordErr);
      setageError(ageError);
      setImgErr(imgErr);
      setgenderError(genderError);
      return false;
    }
    return true;
  };
  const genderoption = [
    { value: '1', label: 'Male' },
    { value: '2', label: 'Female' },
    { value: '3', label: 'Other' },
  ]
  const handleGender = (selected) => {
    setgenderSelected(selected)
    dispatch(Addgender(selected))
    setgenderError("")
  }
  const image_upload = async (data) => {
    MemberImage(data)
      .then((res) => {
        console.log("photo res", res);
      })
      .catch((err) => {
        console.log(err, "err in photo submit");
      });
  };
  const handleimage = (e) => {
    setUserImage(e.target.files[0]);
    setUserImagePath(URL.createObjectURL(e.target.files[0]));
    let urlpath = URL.createObjectURL(e.target.files[0])
    dispatch(Addimage(e.target.files[0]));
    dispatch(Addimagepath(urlpath));
    setImgErr("")
  };
  useEffect(() => {
    ages();
  }, []);
  const ages = () => {
    NoAgelist().then((res) => {
      if (res.data._response.status === 1) {
        let age = res.data._response.data.getages
        let option = age.map((a) => ({
          value: a.ageId,
          label: a.ageDropdown
        }))
        setAgeOptions(option)
      }
    }).catch((err) => {
      console.log(err, 'err in get agelist');
      Swal.fire({
        icon: "warning",
        title: "err in get agelist",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        // cancelButtonColor: '#d33',
        confirmButtonText: "ok",
      });
    })
  }
  const NextForm = () => {
    let isValid = formValidate();
    if (isValid) {
      navigate('/Other-Info');
    }
  }

  const handleReset = () => {
    setUser({
      FirstName: "",
      Age: "",
      Gender: "",
      Password: "",
      Email: "",
      ContactNumber: "",
      ConfirmPassword: "",
      ProfileImg: "",
    });
    inputRefrence1.current.value = null;
    setUserImagePath('')
    setFirstNameErr("");
    setEmailErr("");
    setContactErr("");
    setPasswordErr("");
    setageError("");
    setgenderError("");
    setConfirmPasswordErr("");
    setgenderSelected("");
    setAgeSelected([]);
    // setImgErr("")
  };
  const handleAge = (selected) => {
    setAgeSelected(selected)
    dispatch(Addage(selected))
    setageError("")
  }

  const googleLoginData = (data) => {
    var decoded = jwt_decode(data.credential);
    let emailData = decoded.email
    let username = decoded.name
    let ProfileImg = decoded.picture

    let body = {
      email: emailData,
      RegistrationMethod: 1

    };
    let NonUserbody = {
      EmailAddress: emailData,
      RegistrationMethod: 1,
      Role: 2,
      FirstName: username,
      // PhotoFileName:ProfileImg,
    };

    let headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoading(true);
    axios.post(`https://api.topbrandsranking.com/Authorization/AddUserData`, NonUserbody, headers)
      //axios.post(`https://localhost:44368/api/Authorization/AddUserData`, NonUserbody, headers)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            setLoading(true)
            axios.post(`https://api.topbrandsranking.com/Authorization/GoogleFbauthenticate`, body, headers)
              //axios.post(`https://localhost:44368/api/Authorization/GoogleFbauthenticate`, body, headers)
              .then((res) => {
                if (res.status === 200) {
                  if (res.data._response.status === 1) {
                    setLoading(false);
                    let newUserLoginDetails = res.data._response
                    let Newdetails = {
                      email: newUserLoginDetails.email,
                      token: newUserLoginDetails.token,
                      id: newUserLoginDetails.id,
                      firstName: newUserLoginDetails.firstName,
                      memberIdBrandId: newUserLoginDetails.memberIdBrandId,
                      images: newUserLoginDetails.images,
                      lastName: newUserLoginDetails.lastName,
                      role: newUserLoginDetails.role,
                    };
                    const loginInfo = JSON.stringify(Newdetails);
                    localStorage.setItem("UserDetails", loginInfo);
                    if (Newdetails.role === 1) {
                      window.location.href = "/admindashboard";
                    } else if (Newdetails.role === 2) {
                      // if (key === null) {
                      window.location.href = "/UserDetails";
                      // }
                      // else {
                      //   if (key.page === "Dashboard") {
                      //     window.location.href = "/UserDetails";
                      //   } else if (key.page === "Voting") {
                      //     window.location.href = `/productVoting/${key.from}/${key.date}/${key.name}/${key.question}/${key.categoryid}`
                      //   }
                      //   else if (key.page === "CelebrityVoting") {
                      //     window.location.href = `/CelebrityVoting/${key.from}/${key.date}/${key.name}/${key.question}/${key.category}`
                      //   } else {
                      //     window.location.href = "/UserDetails";
                      //   }
                      // }
                    } else if (newUserLoginDetails.role === 3) {
                      window.location.href = "/BrandDetails";
                    }

                  } else {
                    setLoading(false);
                    Swal.fire({
                      icon: "warning",
                      title: res.data._response.message,
                      showConfirmButton: true,
                      confirmButtonColor: "#3085d6",
                      // cancelButtonColor: '#d33',
                      confirmButtonText: "ok",
                    });
                  }
                }
              })
          } else {
            setLoading(false);
            Swal.fire({
              icon: "warning",
              title: res.data._response.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });

          }
        }
      })
  }


  const responseFacebook = (res) => {

    let body = {
      email: res.email,
      RegistrationMethod: 2

    };

    let facebookBody = {
      EmailAddress: res.email,
      RegistrationMethod: 2,
      Role: 2,
      FirstName: res.name,
      // PhotoFileName:res.picture.data.url,
    };

    let headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    setLoading(true);
    axios.post(`https://api.topbrandsranking.com/Authorization/AddUserData`, facebookBody, headers)
      // axios.post(`https://localhost:44368/api/Authorization/AddUserData`, facebookBody, headers)
      .then((res) => {
        if (res.status === 200) {
          if (res.data._response.status === 1) {
            setLoading(true)
            axios.post(`https://api.topbrandsranking.com/Authorization/GoogleFbauthenticate`, body, headers)
              //  axios.post(`https://localhost:44368/api/Authorization/GoogleFbauthenticate`, body, headers)
              .then((res) => {

                if (res.status === 200) {
                  if (res.data._response.status === 1) {

                    setLoading(false);
                    let newUserLoginDetails = res.data._response
                    let Newdetails = {
                      email: newUserLoginDetails.email,
                      token: newUserLoginDetails.token,
                      id: newUserLoginDetails.id,
                      firstName: newUserLoginDetails.firstName,
                      memberIdBrandId: newUserLoginDetails.memberIdBrandId,
                      images: newUserLoginDetails.images,
                      lastName: newUserLoginDetails.lastName,
                      role: newUserLoginDetails.role,
                    };
                    const loginInfo = JSON.stringify(Newdetails);
                    localStorage.setItem("UserDetails", loginInfo);
                    if (Newdetails.role === 1) {
                      window.location.href = "/admindashboard";
                    } else if (Newdetails.role === 2) {
                      // if (key === null) {
                      window.location.href = "/UserDetails";
                      // }
                      // else {
                      //   if (key.page === "Dashboard") {
                      //     window.location.href = "/UserDetails";
                      //   } else if (key.page === "Voting") {
                      //     window.location.href = `/productVoting/${key.from}/${key.date}/${key.name}/${key.question}/${key.categoryid}`
                      //   }
                      //   else if (key.page === "CelebrityVoting") {
                      //     window.location.href = `/CelebrityVoting/${key.from}/${key.date}/${key.name}/${key.question}/${key.category}`
                      //   } else {
                      //     window.location.href = "/UserDetails";
                      //   }
                      // }
                    } else if (newUserLoginDetails.role === 3) {
                      window.location.href = "/BrandDetails";
                    }

                  } else {

                    setLoading(false);
                    Swal.fire({
                      icon: "warning",
                      title: "Invalid Login Details",
                      showConfirmButton: true,
                      confirmButtonColor: "#3085d6",
                      // cancelButtonColor: '#d33',
                      confirmButtonText: "ok",
                    });

                  }
                }

              }).catch((err) => {
                setLoading(false);
                Swal.fire({
                  icon: "error",
                  title: "Something went wrong",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  // cancelButtonColor: '#d33',
                  confirmButtonText: "ok",
                });
              })
          } else {
            setLoading(false);
            Swal.fire({
              icon: "warning",
              title: "Invalid Login Details",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });

          }
        }

      }).catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      })
  }

  // const componentClicked = (res) => {
  //   console.log(res);
  // }



  return (
    <>
      {/* {
        !token ?
          <Header />
          :
          null

      } */}
      {/* <Header />
      <Menu1 /> */}
      <div className="regiform">
        <div>
          <section className="regi-bg">
            <div className="container">
              <br /><br />
              <div className="row">
                <Col md={8} className="">
                  <Card className="register-card bg-white">
                    <div className='text-end'>
                      <Link to="/"><IconButton size='large'><AiFillHome color='#CD0404' size="35px" /></IconButton></Link>
                    </div>
                    <div className=" m-0 text-center">
                      <Link to="/"><img
                        className="img-fluid "
                        src={logo1}
                        alt="logo-topbrands"
                        height="80px"
                        width="120px"
                      />
                      </Link>
                      <h1 className="m-0 text-center register-heading">
                        SIGN UP HERE!
                      </h1>
                    </div>
                    <div>
                      <Form.Group
                        as={Row}
                        className="mb-3 mt-4 justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={6}>
                          <Form.Label className="w-100">
                            Enter Name
                          </Form.Label>
                          <Form.Control
                            className="w-100"
                            placeholder='Enter Name'
                            type="text"
                            value={state.firstName}
                            name="firstName"
                            onChange={handleChange}
                          />
                          <div style={{ color: "red", fontSize: 13 }}>
                            {FirstNameErr}
                          </div>
                        </Col>
                        <Col sm={6}>
                          <Form.Label className="w-100">Gender</Form.Label>
                          <Select
                            className="w-100"
                            options={genderoption}
                            onChange={handleGender}
                            value={state.gender}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                          />
                          <br />
                          <div style={{ color: "red", fontSize: 13 }}>
                            {genderError}
                          </div>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3 justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={6}>
                          <Form.Label className="w-100">Age</Form.Label>
                          <Select
                            className="w-100"
                            options={ageOptions}
                            onChange={handleAge}
                            value={state.age}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                          />
                          <br />
                          <div style={{ color: "red", fontSize: 13 }}>
                            {ageError}
                          </div>
                        </Col>

                        <Col sm={6}>
                          <Form.Label className="w-100">
                            Contact Number
                          </Form.Label>
                          <Form.Control
                            className="w-100"
                            placeholder='Contact Number'
                            type="Number"
                            value={state.contactNumber}
                            name="contactNumber"
                            onChange={handleChange}
                          />
                          <div style={{ color: "red", fontSize: 13 }}>
                            {ContactErr}
                          </div>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={6}>
                          <Form.Label className="w-100">Password</Form.Label>
                          <Form.Control
                            className="w-100"
                            placeholder='Password*'
                            type="password"
                            value={state.password}
                            name="password"
                            onChange={handleChange}
                          />
                          <div style={{ color: "red", fontSize: 13 }}>
                            {PasswordErr}
                          </div>
                        </Col>
                        <Col sm={6}>
                          <Form.Label className="w-100">
                            Confirm Password
                          </Form.Label>
                          <Form.Control
                            className="w-100"
                            placeholder='Confirm Password*'
                            type="password"
                            value={state.confirmPassword}
                            name="confirmPassword"
                            onChange={handleChange}
                          />
                          <div style={{ color: "red", fontSize: 13 }}>
                            {ConfirmPasswordErr}
                          </div>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3 justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={12}>
                          <Form.Label className="w-100">
                            Email Address
                          </Form.Label>
                          <Form.Control
                            className="w-100"
                            placeholder='Email'
                            type="text"
                            value={state.email}
                            name="email"
                            onChange={handleChange}
                          />
                          <div style={{ color: "red", fontSize: 13 }}>
                            {EmailErr}
                          </div>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3 justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={12} className="mb-4">
                          <Form.Label className="w-100"> User Image (Optional)</Form.Label>

                          <FormControl
                            className="m-2"
                            type="file"
                            name="file"
                            onChange={handleimage}
                            ref={inputRefrence1}
                          />
                          <Col md={6}>
                            <div className="imagepreview">
                              <img
                                alt="Please select"
                                width="80"
                                height="60"
                                src={update === false &&  state.userImage.name === undefined  ? DefaultImg : update === true ? PhotoPath + state.userImage : state.userImagePath}
                              />
                            </div>
                          </Col>

                          {/* <div style={{ color: "red", fontSize: 14 }}>
                          {imgErr}
                        </div> */}
                        </Col>
                      </Form.Group>
                      <div className="button_style m-0 text-center">
                        <button
                        className="button-85 pt-2"
                          type="button"
                          variant="contained"
                          color="error"

    onClick={() => dispatch(ClearForm(), setFirstNameErr(), setageError(""), setgenderError(""), setContactErr(""), setPasswordErr(""), setConfirmPasswordErr(""), setEmailErr(""), setImgErr(""), inputRefrence1.current.value = null)}
                        >
                          Cancel
                        </button>
                        &nbsp;&nbsp;
                        <button
                          variant="contained"
                          onClick={NextForm}
                          className="button-85 pt-2"
                        >Next</button>
                        {/* </Link> */}
                      </div>
                      {/* 
                      <div className="button_style m-0 text-center">
                        <Button
                          variant="contained"
                          onClick={saveForm}
                        >
                          {
                    loading === true ?
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      :
                      null
                  }
                  &nbsp;
                  <span>Submit</span>
                        </Button>{" "}*/}

                      {/* </div>  */}
                      <br />
                      <div className="regi-a row justify-content-center mb-4">
                        <Col sm={12} className='text-center m-0'>
                          <p className='fgt-psw'>
                            Already have an Account?{""}
                            <Link to='/login' className='fgt-psw'> Sign In !</Link>
                          </p>
                        </Col>
                      </div>
                      <div className="row text-center">
                        {
                          loading === true ?
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>


                            :
                            null
                        }
                      </div>

                      <div className="d-flex justify-content-center">
                        <div>
                          <GoogleLogin

                            onSuccess={credentialResponse => {
                              googleLoginData(credentialResponse)
                              setGoogleLogin(credentialResponse);
                            }}
                            onError={() => {
                              console.log('Login Failed');
                            }}
                            type="icon"
                            shape="pill"
                          />
                        </div>
                        <div>
                          <FacebookLogin
                            appId="1131064557801831"
                            // autoLoad={true}
                            fields="name,email,picture"
                            // onClick={componentClicked}
                            callback={responseFacebook}
                            // scope="public_profile,user_friends,user_actions.books"
                            cssClass="btnFacebook"
                            size='medium '
                            textButton=''

                            icon={<BsFacebook fontSize="35px" color="#4C69BA" />}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default Registration;