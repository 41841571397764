import React, { useState, useEffect } from 'react';
import { Table ,Button} from 'react-bootstrap';
import { CategoryList } from '../Admin/ProductServiceAPI';
import { Link } from 'react-router-dom'
import Auth from '../../ServerCalls/Auth';

export default function AllCategories() {
    const auth = Auth.getUserRole()
    const [categories, setCategories] = useState([]);

    useEffect(() => {

        getCategorydata();
    }, [])

    const getCategorydata = () => {

        CategoryList().then((res) => {
            const list = res.data._response.data.getprocategory;
            setCategories(list);
        }).catch((err)=>{
            console.log(err,"err in get category");
        })
    };




    return (

        <>

            <Table className='container mt-3'  bordered hover size="md" style={{marginLeft:"270px"}}>
                <thead>
                    <tr>
                        <th>Product Category</th>

                        <th>Products</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        categories && categories.map((a) => {


                            return (
                                <>
                                    <tr>
                                        <td>{a.categoryName}</td>
                                        {
                                            auth === 2 ?
                                                <td><Link to={`/ProductGallery/${a.categoryid}`}><Button>Products</Button></Link></td>
                                                :
                                                auth === 3 ?
                                                    <td><Link to={`/BrandGallery/${a.categoryid}`}><Button>Products</Button></Link></td>
                                                    :
                                                    null

                                        }
                                    </tr>
                                </>
                            )
                        })

                    }


                </tbody>
            </Table>
        </>
    )
}
