import {
  ADD_FIRST_FORM,
  Add_gender,Add_age,Add_image,Add_county,Add_consti,Add_ward,Add_occupation,Add_education,Add_imagepath,CLEAR_FORM
} from "../action/registerAction";

const initialState = {
  userDetails: {
    firstName: "",
    gender: {},
    age: {},
    email: "",
    password: "",
    userImage: [],
    userImagePath:'',
    confirmPassword: "",
    contactNumber: "",
    county: {},
    constituency: {},
    ward: {},
    occupation: {},
    education_Level: {}
  },

};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_FIRST_FORM:
      let e = action.payload;
      const {
        name, value
      } = e.target;
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          [name]: value
        }
      };
      case Add_gender :
        let gender = action.payload ;
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ["gender"]: gender
        }
      };
      case Add_age :
        let age = action.payload ;
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ["age"]: age
        }
      };
      case Add_image :
        let UserImage = action.payload ;
        return {
          ...state,
            userDetails:{
              ...state.userDetails,
              ["userImage"]: UserImage,
          }
        };
        case Add_imagepath :
          let UserImagepath = action.payload ;
        return {
          ...state,
            userDetails:{
              ...state.userDetails,
              ["userImagePath"]: UserImagepath,
          }
        };
      case Add_county:
        let county = action.payload ;
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ["county"]: county
        }
      };
      case Add_consti:
        let consti = action.payload ;
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ["constituency"]: consti
        }
      };
      case Add_ward:
        let ward = action.payload ;
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ["ward"]: ward
        }
      };
      case Add_occupation:
        let occupation = action.payload ;
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ["occupation"]: occupation
        }
      };
      case Add_education:
        let education = action.payload ;
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ["education_Level"]: education
        }
      };
      case CLEAR_FORM : 
      return {
        ...state,
        userDetails:{
          ...state.userDetails,
          ["firstName"]: "",
          ["gender"]: {},
          ["age"]: {},
          ["email"]: "",
          ["password"]: "",
          ["userImage"]: "",
          ["userImagePath"]:"",
          ["confirmPassword"]: "",
          ["contactNumber"]: "",
          ["county"]: {},
          ["constituency"]: {},
          ["ward"]: {},
          ["occupation"]: {},
          ["education_Level"]: {}
        }
      }
    default:
      return state;
  }
  
};