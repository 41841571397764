import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Container } from "react-bootstrap";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import ProductURL from "../../ServerCalls/ProductServer";
import Auth from "../../ServerCalls/Auth";
import { CategoryList, getProductByBandId, productsOfBrands } from "../Admin/ProductServiceAPI";
import Loader from "../../common/Loader";
import NotFound from "../../common/NotFound";

const BrandGalleryByCategories = () => {
  const [productdata, setproductdata] = useState([]);
  const PhotoPath = ProductURL.photoURL + "ProductImages/";
  const auth = Auth.getUserId();
  const navigate = useNavigate()
  const { id } = useParams();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    productsByCategories();

  }, [])

  
  const productsByCategories = () => {
    setLoading(true)
    productsOfBrands(auth, id).then((res) => {

      if (res.data._response.status === 1) {

        let data = res.data._response.data.productgetid;
        setproductdata(data);
        setLoading(false)



      }
    }).catch((err) => {
      console.log(err, "er in get productts");
      setLoading(false)

    })

  }

  const renderCard = (e, index) => {
    return (
      <Col>
        <Card
          className="gallerycard"
          key={index}
          style={{
            width: "250px",
            height: "280px",
            
            marginTop: "15px",
          }}
        >
          <Card.Body>
            <Card.Img
              src={PhotoPath + e.productimage1}
              width="150px"
              height="150px"
            />
            <Card.Text className="Cname">{e.productname}</Card.Text>{" "}
            <Link
              className="btn btn-warning more"
              title="More info"
              to={`/brandproduct-details/${e.productid}`}           >
              <i className="fa fa-info-circle" aria-hidden="true"></i>

            </Link>{" "}
          </Card.Body>
        </Card>
      </Col>
    );
  };


  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <div className="container-fluid">
            {/* <Card style={{ marginTop: "36px" }}> */}
            <Col md={12} className="mb-2">
              {
                loading ?
                  <Loader />
                  :
                  productdata.length !== 0 ?
                    <Row sm="5"> {productdata.map(renderCard)} </Row>
                    :

                    <NotFound />
              }

            </Col>{" "}
            {/* </Card> */}
          </div>{" "}
          <div className="button_style mt-5 text-center">
            <button
              type="button"
              className="btn btn-pos2"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </section>{" "}
      </div>{" "}
    </div>

    //modal

  );
};

export default BrandGalleryByCategories;
