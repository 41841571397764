import React, { useState, useEffect } from "react";
import "../../../components/pages/Login/UserDetails.css";
import { Container, Card, Row, Col, Form } from "react-bootstrap";
import userinfo from '../../../assets/img/userinfo.png';
import email from '../../../assets/img/email.png';
import age from '../../../assets/img/age.png';
import couple from '../../../assets/img/couple.png';
import telephone from '../../../assets/img/telephone.png';
import placeholder from '../../../assets/img/placeholder.png';
import address from '../../../assets/img/address.png';
import greencity from '../../../assets/img/greencity.png';
import constituency from '../../../assets/img/constituency.png';
import ward from '../../../assets/img/ward.png';
import dob from '../../../assets/img/dob.png';
import id from '../../../assets/img/id.png';
import "./Userdetails.css";

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import { MdModeEditOutline } from 'react-icons/md';
import Auth from "../../ServerCalls/Auth";
import moment from 'moment-timezone';
import { Link } from "react-router-dom";
import { getmemeberdetails } from '../Admin/ProductServiceAPI';
import ProductURL from '../../ServerCalls/ProductServer';
import Button from '@mui/material/Button';
const PhotoPath = ProductURL.photoURL + "MemberMasterImages/";





const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function UserDeatails() {
 
  const [userdetails, setuserdetails] = useState([]);
  

  const auth = Auth.getUserId();

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDetails = () => {

    getmemeberdetails(auth).then((res) => {
      const user = res.data._response.data.member[0];
      setuserdetails(user);
    })
      .catch((err) => {

        console.log(err);
      });
  };

  return (

    <div className='user-info-body'>
      <div className='container-fluid'>
        
        {/*new design */}

        <Container>
          <div className="row mb-4">
            <div className="col-sm-12">
            <h1 className="text-center m-0">USER DETAILS</h1>
             </div>
            
          </div>
          

          <Card className="userinfo-card">
         
            <Card.Body>
            <div class="row mb-4">
        <div class="profile-header-container">   
    		<div class="profile-header-img">
                <img class="img-circle" src={PhotoPath + userdetails.photofilename} />
                {/* <!-- badge --> */}
                {/* <div class="rank-label-container">
                    <span class="label label-default rank-label">100 puntos</span>
                </div> */}
            </div>
        </div> 
	</div>
             
          <div>
          <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
>
        <Grid item xs={6}>
          <Item className="mb-4 text-start"><p className="user-p">Name : {userdetails.firstname}</p></Item>
          <Item className="mb-4 text-start"><p className="user-p">Age Group : {userdetails.agename}</p></Item>
          <Item className="mb-4 text-start"><p className="user-p">Gender : {userdetails.gender}</p></Item>
          <Item className="mb-4 text-start"><p className="user-p">Contact Number : {userdetails.contactno1}</p></Item>
          <Item className="mb-4 text-start"><p className="user-p">Email : {userdetails.emailaddress}</p></Item>


        </Grid>
        <Grid item xs={6}>
        <Item className="mb-4 text-start"><p className="user-p">County : {userdetails.countyname}</p></Item>
        <Item className="mb-4 text-start"><p className="user-p">Constituency : {userdetails.constituencyname}</p></Item>
        <Item className="mb-4 text-start"><p className="user-p">Ward : {userdetails.wardname}</p></Item>
        <Item className="mb-4 text-start"><p className="user-p">Occupation : {userdetails.occupation}</p></Item>
        <Item className="mb-4 text-start"><p className="user-p">Education Level : {userdetails.educationlevel}</p></Item>

        </Grid>

        
        
      </Grid>
    </Box>
          </div>
          <Row className="mb-3 mt-3 p-3 text-center">
                <Form.Group as={Col} controlId="formGridCity">
                    <Link to={`/UserProfile/${auth}`}><Button variant="contained">Edit Profile</Button></Link>
                  </Form.Group>
                </Row>
          </Card.Body>
          </Card>
          
        </Container>
      </div>
    </div>

  );
}

export default UserDeatails;
