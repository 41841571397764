import React, { useState, useEffect } from "react";
import './learnmore.css';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import axios from 'axios';
import Swal from "sweetalert2";
import Image from 'react-bootstrap/Image'
import share from '../../../assets/img/share.png';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FaShareAlt,FaVoteYea } from 'react-icons/fa';
import { MdMore } from 'react-icons/md';
import { Link } from "react-router-dom";
import { Pagination } from "@mui/material";
import { Box } from '@material-ui/core'
import Loader from "../../common/Loader";
import { campaignData } from "../../pages/Admin/ProductServiceAPI";
import NotFound from "../../common/NotFound";
import Auth from '../../ServerCalls/Auth';
import voting from '../../../assets/img/voting.png';
import ProductURL from "../../ServerCalls/ProductServer";
import { getCelebrityCampaign, getTopCelebrity ,getCelebrityCampaignWithPagination} from '../../pages/Admin/CelebrityPages/CelebrityServiceAPI'
import celebrity1 from '../../../assets/img/celebrity1.png';
import { Modal } from "react-bootstrap";
import { FacebookIcon,FacebookShareButton,WhatsappShareButton,WhatsappIcon,TwitterShareButton,TwitterIcon,} from 'react-share';
import CollapsibleExample from "../NewHeader";
const PhotoPathCelebrityCampaign = ProductURL.photoURL + "CelebrityCampaignImages/";

export default function MoreCelebrityCampaign() {
    const token = Auth.getToken();
    const [CelebrityCampaigndata, setCelebrityCampaigndata] = useState([]);

    const [loading, setLoading] = useState(false);
    const [pageC, setPageC] = useState(8)
    const [pageNumber, setPageNumber] = useState(1);
    const [ShareCampaign,setShareCampaign] = useState(0);
    const [showB, setShowB] = useState(false);
    const dataPerPage = 8;

    const changePage = (e, a) => {
        setPageNumber(a);
        getCelebrityCampaignlist(a,dataPerPage);
    }


   const title = 'Campaign Information';


    useEffect(() => {

        setPageNumber(1)
        getCelebrityCampaignlist(1,8);
    }, [])

    const getCelebrityCampaignlist = (a,b) => {
        setLoading(true)
        getCelebrityCampaignWithPagination(a,b)
            .then((res) => {
                if (res.data._response.status === 1) {
                    const data = res.data._response.data.getcelebritycampaign;
                    let count = res.data._response.data.count;
                    let APIpagecount = Math.ceil(count / dataPerPage);
                    
                    setLoading(false);
                    setPageC(APIpagecount);
                    setCelebrityCampaigndata(data);
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    const openModal = (list)=> {
        let id = btoa(list.campaignId)
        setShareCampaign(id)
        setShowB(true);
      }

      const handleClose = () => {
        setShowB(false);
      };

    return (
        <>
            {
                !token ?
                    <CollapsibleExample />
                    :
                    null

            }

            <div>
                <div className="">
                    <div className="content-wrapper">
                        <section className='celebrity-camp' >
                            <Container>
                                
                                <h2 className='m-0 text-center trend-heading' >FAVOURITE <span>CELEBRITY</span> </h2>
                                <br />


                                <div className='row'>
                                    {
                                        loading === true ?
                                            <Loader />
                                            :

                                            CelebrityCampaigndata.map((list, id) => (
                                                <div className='col-md-3 pb-4'>
                                                  
                                                        

                                                                <div className="brandcateg card-blog">

                                                                    <div className="cardcateg-image">
                                                                        <div><img className="img" src={PhotoPathCelebrityCampaign + list.celebrityimage} alt='' />
                                                                        </div>
                                                                        <div className="ripple-cont"></div>
                                                                    </div>
                                                                    <div className='card-body'>
                                                                        <div className="table">
                                                                            <p className="category">{list.campaignShortName}</p>
                                                                            <p className="cardcateg-description">{list.celebritycategoryname}</p>
                                                                        </div>
                                                                        <div className='d-flex justify-content-between'>
                              <IconButton className='text-end'><FaShareAlt fontSize="25px" color='#CD0404'onClick={() => openModal(list)} /></IconButton>
                              <Tooltip title="Click To Vote">
                                {token?(
                                 <Link className='text-decoration-none' to={`/CelebrityVoting/${list.campaignId}/${list.campaignEndDate}/${list.campaignShortName}/${list.question}/${list.celebrityCategoryId}`}> <IconButton className='text-end'>
                                  <FaVoteYea fontSize="25px" color='#000' />
                                </IconButton>
                                </Link>
                                )
                              : 
                              <Link to="/subregister" state={{ from: list.campaignId, date: list.campaignEndDate, name: list.campaignShortName, question: list.question, category: list.celebrityCategoryId, page: "authorisedCelebritycampaigndetails" }}>
                              <IconButton className='text-end'>
                              <FaVoteYea fontSize="25px" color='#000' />
                            </IconButton>
                            </Link>
}
                                </Tooltip>

                            </div>
                                                                    </div>
                                                                </div>


                          {/* <Card onClick={() => openModal(list)} title="click to share">
                      <Card.Body>
                      <div className='m-0 text-center'>
                        <IconButton  className="text-center">
                       <Image src={share} alt='share' />
                      </IconButton> <span className='brand-span'>Share Campaign !</span>
                        </div>
                      </Card.Body>
                    </Card> */}

                                                </div>
                                                
                                            ))}

                                    {
                                        CelebrityCampaigndata.length !== 0 ?
                                            <Box display="flex" alignItems="center" justifyContent="center">
                                                <Pagination
                                                    count={pageC}
                                                    page={pageNumber}
                                                    onChange={changePage}
                                                    shape="rounded" color="primary" size="large"
                                                />
                                            </Box>
                                            :
                                            null
                                    }
                                </div>
                            </Container>

                        </section>
                    </div>
                </div>
            </div>

            <Modal
        show={showB}
        onHide={handleClose}
        size="sm"
        backdrop="static"
        centered
      >
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="mx-auto">
            <h5>
              <b>Share Product</b>
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <Link to="https://web.whatsapp.com/"> */}
          <Row className="text-center">
            <Col sm={4}>
              <FacebookShareButton
                url={`https://topbrandsranking.com/SharedCelebritycampaign/${ShareCampaign}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </Col>
            <Col sm={4}>
              < WhatsappShareButton
                 url={`https://topbrandsranking.com/SharedCelebritycampaign/${ShareCampaign}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
                < WhatsappIcon size={32} round />
              </ WhatsappShareButton>
            </Col>
            <Col sm={4}>
            < TwitterShareButton
                 url={`https://topbrandsranking.com/SharedCelebritycampaign/${ShareCampaign}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
              <TwitterIcon size={32} round />
              </ TwitterShareButton>
            </Col>
            {/* <Col sm={3}>
            < InstapaperShareButton
                url={`http://tbr.softlabsgroup.in/ShareProductDetails/${productID}`}
                quote={title}
                className="Demo__some-network__share-button"
              >
              <InstapaperIcon size={32} round />
              </ InstapaperShareButton>
            </Col> */}
          </Row>
        </Modal.Body>
      </Modal>
            <Footer />
        </>
    )
}
