import React from 'react';
import { Routes, Route } from 'react-router-dom'
import LogIn from "./Login/Login";
import Registration from "./Login/Registration";
import BrandRegistration from "./BrandOwner/BrandRegistration";

import Home from "../common/home/Home";
import Resetpassword from './Login/ResetPassword';
import EmailVerification from './Login/EmailVerification';
import ShareProductDetails from './sharePages/ShareProductDetails';
import ShareCampaign from './sharePages/Sharecampaign';
import ShareCelebrityCampaign from './sharePages/ShareCelebrityCampaign';
import SharecelbrityDetails from './sharePages/SharecelebrityDetails';
import Aboutus from '../common/about/Aboutus';
import Learnmore from '../common/home/Learnmore';
import OtherInfo from './Login/OtherInformation';
import EducationalInfo from './Login/EducationInformation';
import Rankingdata from './Admin/Rankingdata';
import ScoreMagazine from './Admin/ScoreMagazine';
import AllGeneralNews from './Admin/AllGeneralNews';
import HomeProducts from './Admin/HomeProducts';
import MoreProductCampaign from '../common/home/MoreProductCampaign';
import MoreCelebrityCampaign from '../common/home/MoreCelebrityCampaign';
import SubRegister from './Login/SubRegister';
import GeneralVoting from './Citizen/GeneralVoting';
import GeneralCelebrityVoting from './Citizen/GeneralCelebrityVoting';
import GeneralVideoVoting from './Citizen/GeneralVideoVoting';
import BrandRanking from '../pages/BrandRankingList';
import CelebrityRanking from '../pages/CelebrityRankingList';
import ShareVideoCampaign from './sharePages/ShareVideoCampaign';


export default function HomeRoutes() {

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/subregister" element={<SubRegister />} />

            <Route path="/SharedCampaign/:id" element={<ShareCampaign/>} />
            <Route path="/ShareVideoCampaign/:id" element={<ShareVideoCampaign/>} />
            <Route path="/SharedCelebritycampaign/:id" element={<ShareCelebrityCampaign/>} />
            <Route path='/BrandRegistration' element={<BrandRegistration />} />
            <Route path="/reset-password" element={<Resetpassword />} />
            <Route path="/verify-email" element={<EmailVerification />} />
            <Route path='/ShareProductDetails/:id' element={<ShareProductDetails/>}/>
            <Route path='/ShareCelebrityDetails/:id' element={<SharecelbrityDetails/>}/>
            <Route path='/about-us' element={<Aboutus />} />
            {/* <Route path='/all-brands' element={<HomeProducts />} /> */}
            <Route path='/Other-Info' element={<OtherInfo />}/>
            <Route path='/Educational-Info' element={<EducationalInfo />}/>
            <Route path="/rankinglistdata" element={<Rankingdata/>}/> 
            <Route path="/scoredata" element={<ScoreMagazine/>}/> 
            <Route path="/GeneralNewsList" element={<AllGeneralNews/>}/> 
            <Route path="/all-brands" element={<Learnmore/>}/> 
            <Route path='/moreProductCampaign' element={<MoreProductCampaign/>}/>
            <Route path='/moreCelebrityCampaign' element={<MoreCelebrityCampaign/>}/>
            <Route path='/Voting' element={<GeneralVoting/>}/>
            <Route path='/GeneralCelebrityVoting' element={<GeneralCelebrityVoting/>}/>
            <Route path='/GeneralVideoVoting' element={<GeneralVideoVoting/>}/>
            <Route path="/MoreBrandRanking_List" element={<BrandRanking/>}/>
            <Route path="/MoreCelebrityRanking_List" element={<CelebrityRanking/>}/>




        </Routes>
    )
}
