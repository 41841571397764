import React, { useState, useEffect } from 'react'
import { Col, Row, Card, Form } from 'react-bootstrap';
import MaterialTable from 'material-table';
import Button from '@mui/material/Button';
import { platformList, addSocialPlatform, EditsocialPlatform, deleteplatform } from './CelebrityServiceAPI';
import Swal from "sweetalert2";



export default function SocialMediaPlatforms() {


    const [platform, setPlatform] = useState('');
    const [PlatformError, setPlatformError] = useState('');
    const [platformID, setPlatformID] = useState(0);
    const [platformdata, setPlatformdata] = useState([]);
    const [update, setupdate] = useState(false);

    const handlename = (e) => {

        setPlatform(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setPlatformError('')
    }

    const columns = [

        { title: "Platform", field: "socialMediaPlatformName", align: "center" },

    ];


    useEffect(() => {

        socialPlatformList();

    }, [])


    const socialPlatformList = () => {

        platformList().then((res) => {

            if (res.data._response.status === 1) {

                setPlatformdata(res.data._response.data.getsocialmediaplatforms)
            }
        }).catch((err) => {
            console.log(err, "error");
        })

    }

    const FormValidation = () => {

        let PlatformError = '';
        let regex = /^[A-Za-z0-9 ]+$/;


        if (platform === '') {

            PlatformError = "Please fill the Details";

        } else if (regex.test(platform) === false) {

            PlatformError = 'Please do not use special Character'
        }else if(platform.length > 100){
            PlatformError = "Maximum 100 characters are allowed";
        }

        if (PlatformError) {

            setPlatformError(PlatformError);


            return false;
        }

        return true;
    }

    const saveData = () => {

        const isValid = FormValidation();
        if (isValid) {

            const data = {
                socialmediaplatformname: platform
            }
            addSocialPlatform(data).then((res) => {

                if (res.data._response.status === 1) {

                    Swal.fire({
                        icon: "success",
                        title: "Social Platform Added Successfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });
                    socialPlatformList();
                    clearData();
                }
            }).catch((err)=>{
                console.log(err,'err in add social platform');
            })

        }
    }

    const getData = (id) => {

        setupdate(true);
        setPlatform(id.socialMediaPlatformName);
        setPlatformID(id.socialMediaPlatformId);
    }

    const EditData = () => {

        const isValid = FormValidation();
        if (isValid) {

            const data = {
                socialmediaplatformid: platformID,
                socialmediaplatformname: platform

            }

            EditsocialPlatform(data).then((res) => {

                if (res.data._response.status === 1) {

                    Swal.fire({
                        icon: "success",
                        title: "Product Updated Successfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "ok",
                    });
                    socialPlatformList();
                    clearData();
                }

            }).catch((err)=>{
                console.log(err,'err in edit social platform');
            })
        }
    }

    const trashData = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {

                deleteplatform(id)
                    .then((res) => {
                        if (res.data._response.status === 1) {
                            Swal.fire({
                                icon: "success",
                                title: "Parameter Deleted Sucessfully",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });
                            socialPlatformList();

                        }else if(res.data._response.status === 2) {
                            Swal.fire({
                                icon: "warning",
                                title: res.data._response.message,
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                    
                            });
          
          
                        }
                
                    }) .catch((err)=>{
                        console.log(err,"err in remove platform");
                    })
                   
                }
                }).catch((err)=>{
            console.log(err)
        })


    }



    const clearData = () => {
        setupdate(false);
        setPlatform('');
        setPlatformError('');

    }

    return (
        <div>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <br /><br />
                            <div className="row mb-2">

                                <div className="col-sm-12">
                                    {!update?(
                                    <h1 className="m-0 text-center para-heading">ADD SOCIAL MEDIA PLATFORMS</h1>
                                    ):
                                    <h1 className="m-0 text-center para-heading">EDIT SOCIAL MEDIA PLATFORMS</h1>
                                }
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container">
                            <div className="row justify-content-center">
                                <Col md={12}>
                                    <Card className='mt-4' style={{ padding: "20px" }}>
                                        <Form.Group as={Row} className="justify-content-center" controlId="formHorizontalEmail">
                                            
                                                <Form.Label className="w-100 pb-1">Media Platform</Form.Label>
                                               
                                              <input className="w-100 form-control" type="text" placeholder='Media Platform' value={platform} onChange={handlename} />
                                                <div style={{ fontSize: 13, color: "red" }}>{PlatformError}</div>
                                            
                                        </Form.Group>
                                        <div className="button_style m-0 text-center">

                                            {
                                                update === false ?

                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        onClick={() => saveData()}
                                                    >
                                                        Submit
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="success"
                                                        onClick={() => EditData()}
                                                    >
                                                        Update
                                                    </Button>
                                            } {" "}

                                            <Button
                                                type="clear"
                                                variant="contained"
                                                color="error"
                                                onClick={() => clearData()}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Card>
                                </Col>
                                <Col className='mt-4' md={12}>
                                    <MaterialTable
                                        title=''
                                        data={platformdata}
                                        columns={columns}
                                        actions={[
                                            {
                                                icon: "edit",
                                                tooltip: "Edit",

                                                onClick: (event, rowData) => {
                                                    getData(rowData);
                                                },
                                            },
                                            {
                                                icon: "delete",
                                                tooltip: "Delete",
                                                onClick: (event, rowData) => {
                                                    trashData(rowData.socialMediaPlatformId);
                                                },
                                            },
                                        ]}
                                        options={{
                                            search: true,
                                            pageSize: 5,
                                            showFirstLastPageButtons: false,
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                        }}
                                    />
                                </Col>
                            </div>
                        </div>
                        <br /><br />
                    </section>
                </div>
            </div>
        </div>

    )
}
