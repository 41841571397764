import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Card, Alert, Container } from "react-bootstrap";
import { useNavigate, useParams, Link,useLocation } from "react-router-dom";
import Auth from "../../../ServerCalls/Auth";
import Swal from "sweetalert2";
import ProductURL from "../../../ServerCalls/ProductServer";
import { FcInfo } from "react-icons/fc";
import { FaVoteYea } from "react-icons/fa";
import { MdHowToVote } from "react-icons/md";
import Loader from "../../../common/Loader";
import NotFound from "../../../common/NotFound";
import { Col, Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import axios from "../../../ServerCalls/AxiosInstance";
import {CelebrityParticipationwithcitizenID,AddCelebrityVoting,getCelebritySummary,getTopCelebrity} from '../../Admin/CelebrityPages/CelebrityServiceAPI'
import {
  TableCell,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Chart from 'react-apexcharts';
import { Rating } from 'react-simple-star-rating';
import '../voting.css';
import getYouTubeID from 'get-youtube-id';



const PhotoPath = ProductURL.photoURL + "CelebrityImages/";

export default function CelebrityVoting() {
  const location = useLocation();
 if(location.state) {
  var { campaignId , campaignEndDate, campaignShortName,question,celebrityCategoryId,} = location.state.data;
 }
 

  const auth = Auth.getUserId();
  const navigate = useNavigate();

  const [listByNames, setlistByNames] = useState([]);
  const [alreadyvote, setAlreadyVote] = useState([]);
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [Celebrity, setCelebrity] = useState([]);
  const [TotalVotes, setTotalVotes] = useState([]);
  const [PiechartCelebrity,setPiechartCelebrity] = useState([]);
  const [PiechartCelebrityCounts,setPieChartCelebrityCounts] = useState([]);
  const [TopCelebrity,setTopCelebrity] = useState([]);
  const [Length,setLength] = useState([]);
  const [view, setView] = useState(false);
  const [Add, setAdd] = useState([]);
 

  const handleClose = () => {
    setShowReport(false)
  };


  const useStyles = makeStyles({
    table: {
      "& .MuiTableCell-root": {
        border: "1px solid rgba(0, 0, 0, 0.3)",
      },
    },
  });
  const classes = useStyles();

  
  const advertisePhoto = "https://media.topbrandsranking.com/CelebrityCampaignAdvertiseImages/"



  const openAdvertiseModal = () => {
    setView(true)
  }

  const handleClose2 = () => {
    setView(false);
  };

  useEffect(() => {
    // Campaigngetbyid(id)
    axios.get(`https://api.topbrandsranking.com/ProductManagement/CelebrityCampaignGetById/${campaignId}`)
      .then((response) => {
        const data = response.data._response.data.getcelebritycampaignid
        setAdd(data)

      })

  }, [])


  useEffect(() => {

    getReport()
  
  }, [])


  const getReport = ()=>{

    getCelebritySummary(campaignId,celebrityCategoryId)
    .then((response) => {
      const celebrity = response.data._response.data.campaigncelebritycount
      const CelebrityTotalCount = response.data._response.data.celebritysummarytotalcount
      
      let Celebritynamedata = [];
      let Celebrityvotingdata = [];
      celebrity.map((a) => {
        Celebritynamedata.push(a.celebrityname);
        Celebrityvotingdata.push(a.count)
      });



      setPiechartCelebrity(Celebritynamedata)
      setPieChartCelebrityCounts(Celebrityvotingdata)
      setCelebrity(celebrity)
      setTotalVotes(CelebrityTotalCount)

    })

  }


  useEffect(() => {
    ParticipatedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ParticipatedProducts = () => {
    setLoading(true);
    let campaignid = campaignId;
    let citizenid = Number(auth);
     CelebrityParticipationwithcitizenID(citizenid,campaignid)
    //  axios.get(`https://localhost:5002/api/ProductManagement/CelebrityParticipationGetByCampaignIdVerified?memberid=${citizenid}&campaignid=${campaignid}`)
      .then((res) => {
        if (res.data._response.status === 1) {
          let data = res.data._response.data.celebrityparticipationgetcampaignid;
          setlistByNames(data);
          setLoading(false);
          let alreadyvotes = [];
          data &&
            data.map((f) => {
              if (f.isVoted === 0) {
                alreadyvotes.push(f);
              }
            });
          setAlreadyVote(alreadyvotes);
        }
      })
      .catch((err) => {
        console.log("err in get", err);
        setLoading(false);
      });
  };

  const handleVote = (votedata) => {
    Swal.fire({
      title:
        "You Are Voting to <br><h4 style='color:#3085d6'>" +
        votedata.celebrityName +
        "</h4>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Confirm it!",
    })
      .then((result) => {
        if (result.isConfirmed === true) {
          const data = {
            campaignId: votedata.campaignid,
            celebrityvotingdate: new Date(),
            memberid: Number(auth),
            celebrityid: votedata.celebrityid,
          };
          AddCelebrityVoting(data)
            .then((res) => {
              if (res.data._response.status === 1) {
                Swal.fire({
                  icon: "success",
                  title:
                    "Voted <h4 style='color:#5364da'>" +
                    votedata.celebrityName +
                    "</h4> <h5 style='color:#94726f'>" +
                    votedata.celebrityName +
                    "</h5",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "ok",
                });
                ParticipatedProducts();
                openAdvertiseModal();
                getReport();
              } else if (res.data._response.status === 2) {
                Swal.fire({
                  icon: "warning",
                  title: res.data._response.message,
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "ok",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in product voting ");
      });
  };


  const OpenReport = () => {

    setShowReport(true)

  }

  useEffect(() => {

    topCelebrity(); 
   
  }, [])

  const topCelebrity = ()=> {

    getTopCelebrity()
    
    .then((res)=>{
      // console.log(res,"got cele data")
     const celebritydata = res.data._response.data.topcelebritygetbycelebrityid2
     const prolength = res.data._response.data.topcelebritygetbycelebrityid2.length
     setLength(prolength)
     setTopCelebrity(celebritydata)

    })

    

  }
  

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <br />
            <br />
            <div className="row mb-2">
              <div className="col-sm-12">
                <h1 className="m-0 text-center mb-3">{campaignShortName}</h1>
              </div>
              <br />
              <br />
              <Row>
                <Col sm={12}>
                  <div className="d-flex justify-content-center mt-3">
                    <Button variant="contained"  color="success" onClick={OpenReport}>Report</Button>
                  </div>

                </Col>
              </Row>
            </div>
          </div>
        </div>


        <section className="section-2">
          <div className="container">
            <div className="row">
              {new Date(campaignEndDate) >= new Date() ? null : (
                <Alert
                  variant="warning"
                  show={show}
                  onClose={() => setShow(false)}
                  dismissible
                >
                  <Alert.Heading>Campaign Closed!!!</Alert.Heading>
                  <strong>You Cannot Vote this Campaign.</strong>
                </Alert>
              )}
              {alreadyvote.length > 0 ? (
                <Alert
                  variant="warning"
                  show={show}
                  onClose={() => setShow(false)}
                  dismissible
                >
                  <Alert.Heading>
                  You have already voted for this campaign!!
                  </Alert.Heading>
                  <strong>You Cannot Vote this Campaign again.</strong>
                </Alert>
              ) : null}

              <Alert variant="primary">
                {/* <Alert.Heading>QUESTION: </Alert.Heading> */}
                <h4
                  style={{
                    fontWeight: "600",
                    fontSize: "1.8rem",
                    textTransform: "uppercase",
                  }}
                >
                  {question}?
                </h4>
              </Alert>
              <card-group  style={{ marginTop: "36px" }}>
                <Col md={12} className="mb-2">
                  <Row sm="12">
                    {loading ? (
                      <Loader />
                    ) : listByNames.length !== 0 ? (
                      listByNames.map((list, id) => {
                        return (
                          <Col md={3}>
                            <Card
                              className="gallerycard"
                              style={{
                                height: "350px",
                                textAlign: 'center',
                                marginTop: "15px",
                              }}
                            >
                              <Card.Img
                                  className="zoom"
                                  width="100%"
                                  height="200px"
                                  variant="top"
                                  src={PhotoPath + list.celebrityimage}
                                />
                              <Card.Body>
                                <Card.Title className="category" title={list.brandname}>{list.brandname}</Card.Title>
                               
                                <Card.Text className="cardcateg-description" title= {list.celebrityName}>
                                  {list.celebrityName}
                                </Card.Text>
                                {list.isVoted === 0 ? (
                                  <button
                                    className={
                                      list.isVoted === 0
                                        ? " cursor-not-allowed"
                                        : "myclass"
                                    }
                                    title="Already Voted"
                                    style={{
                                      border: "none",
                                      marginRight: "20px",
                                    }}
                                    disabled="true"
                                    onClick={() => handleVote(list)}
                                    type="submit"
                                  >
                                    <FaVoteYea fontSize="35px" color="green" />
                                  </button>
                                ) : alreadyvote.length === 0 ? (
                                  <button
                                    className={
                                      new Date(campaignEndDate) <= new Date()
                                        ? " cursor-not-allowed"
                                        : "myclass"
                                    }
                                    disabled={new Date(campaignEndDate) <= new Date()}
                                    title="vote now"
                                    style={{
                                      border: "none",
                                      marginTop: "10px",
                                      marginRight: "20px",
                                    }}
                                    onClick={() => handleVote(list)}
                                    type="submit"
                                  >
                                    <MdHowToVote
                                      fontSize="35px"
                                      color="orangered"
                                    />
                                  </button>
                                ) : null}

                                <Link
                                  to={`/Celebrity-details/${list.celebrityid}`}
                                  title="more info"
                                >
                                  <FcInfo fontSize="35px" />
                                </Link>
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      })
                    ) : (
                      <NotFound />
                    )}
                  </Row>
                </Col>
              </card-group>
            </div>
          </div>
        </section>
        <div className="button_style m-0 text-center">
          <Button
          className="m-0 text-center"
            type="button"
            variant="contained"
            color="error"
            onClick={() => navigate("/CelebrityCampaignList")}
          >
            Back
          </Button>
        </div>
        <br />
        <br />



        <Modal show={showReport} className="mt-3  product-modal" onHide={handleClose} size="lg" backdrop="static" centered>
          <Modal.Header className="headerBlue" closeButton >
            <Modal.Title className=''>
              <p className="text-center mt-2 mx-auto"> Report Summary </p>
            </Modal.Title>


          </Modal.Header>
          <h4 className="mx-auto">{campaignShortName}</h4>
          <Modal.Body>
          <Container>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report">
                                <TableContainer >
                                    <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                        <TableHead >

                                            <TableRow className="backgroundrow">
                                                <TableCell width="10%" className="head1">Celebrity Name</TableCell>
                                             
                                                <TableCell align="center" width="10%" rowSpan={2} className="head1">Total Vote</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Celebrity.map((list,id)=>( 
                                            <TableRow>
                                             
                                                <TableCell className="headerbgcolor" >{list.celebrityname}</TableCell> 
                                 

                                                <TableCell className="headerbgcolor" align="center">{list.count}</TableCell>
                                           
                                            </TableRow>
                                            ))} 
                                      
                                        </TableBody>
                                    </Table>


                                    <Chart

                                        type="pie"
                                        width={750}
                                        height={250}
                                        series={PiechartCelebrityCounts}

                                        options={{
                                            title: { text: "Voting Report" },
                                            noData: { text: "Empty Data" },
                                            labels: PiechartCelebrity,
                                            responsive: [{
                                              breakpoint: 1024,
                                              options: {
                                                chart: {
                                                  width: 700
                                                },
                                                legend: {
                                                  position: 'right'
                                                }

                                              },
                                              breakpoint: 480,
                                              options: {
                                                chart: {
                                                  width: 250
                                                },
                                                legend: {
                                                  position: 'bottom'
                                                },
                                                
                                              },
                                              breakpoint: 768,
                                              options: {
                                                chart: {
                                                  width: 400
                                                },
                                                legend: {
                                                  position: 'bottom'
                                                },
                                                
                                              }

                                            }]
                                        }}

                                    />


                                </TableContainer>
                            </Paper>
                        </Container>
          

          </Modal.Body>
        </Modal>

        <Modal
                show={view}
                onHide={handleClose2}
                size="md"
                backdrop="static"
                centered
              >
                <Modal.Header className="headerBlue" closeButton>
                  <Modal.Title className="">
                    <h5 className="text-center">Sponsored</h5>
                  </Modal.Title>
                </Modal.Header>

                {Add.map((list) => (
                  <Modal.Body>
                    <div className="container newscard">
                      <article className="card shadow">
                        {
                          list.advertisetypelabel == "Upload_Link" ? (

                            <iframe className='video'
                              title='Youtube player'
                              allowfullscreen
                              width="640" height="360"
                              sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                              src={`https://youtube.com/embed/${getYouTubeID(list.advertisesource)}?autoplay=1 allow="autoplay"`}>
                            </iframe>

                          ) : list.advertisetypelabel == "Upload_Video" ? (
                            <div className="row">
                              <video width="100%" controls>
                                <source src={advertisePhoto + list.advertisesource} type="video/mp4" />
                              </video>
                            </div>

                          ) :

                            <div className="row">
                              <img
                                className="img-fluid"
                                src={advertisePhoto + list.advertisesource}
                                alt="news"
                              />
                            </div>
                        }
                  
                      </article>
                    </div>
                  </Modal.Body>
                ))}
              </Modal>


      </div>
    </>
  );
}
