import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, Container } from "react-bootstrap";
import Select from "react-select";
import Button from "@mui/material/Button";
import axios from "../../../ServerCalls/AxiosInstance";
import {
  campaignData,
  FinalvotingReport,
  getAgeReport,
} from "../ProductServiceAPI";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../../common/Loader";
import { useReactToPrint } from "react-to-print";
import Chart from "react-apexcharts";
import NotFound from "../../../common/NotFound";
export default function AgeReport() {
  const [data, setData] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [AgeCount, setAgeCount] = useState([]);
  const [AgeCount1, setAgeCount1] = useState([]);
  const [AgeCount2, setAgeCount2] = useState([]);
  const [AgeCount3, setAgeCount3] = useState([]);
  const [AgeCount4, setAgeCount4] = useState([]);
  const [AgeCount5, setAgeCount5] = useState([]);
  const [AgeCount6, setAgeCount6] = useState([]);
  const [AgeCount7, setAgeCount7] = useState([]);
  const [AgeCount8, setAgeCount8] = useState([]);
  const [AgeCount9, setAgeCount9] = useState([]);
  const [loading, setloading] = useState(false);
  const [AgeTotal, setAgeTotal] = useState([]);

  const [PieAgeName, setPieAgeName] = useState([]);
  const [PieAgeCount, setPieAgeCount] = useState([]);

  const [Age, setAge] = useState([]);

  const useStyles = makeStyles({
    table: {
      "& .MuiTableCell-root": {
        border: "1px solid rgba(0, 0, 0, 0.3)",
      },
    },
  });

  const classes = useStyles();

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleCampaign = (selected) => {
    setSelectedCampaign(selected);
  };

  const clear = () => {
    setSelectedCampaign("");
  };

  useEffect(() => {
    campaignList();
    if(selectedCampaign != ''){

    reports();
    // ageReport();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaign]);

  const campaignList = () => {
    campaignData().then((res) => {
      let arr = [];
      let list = res.data._response.data.getpollingcampaign;

      list.map((a) =>
        arr.push({
          label: a.pollingCampaignShortName,
          value: a.campaignId,
        })
      );
      setCampaignOptions(arr);
    })
      .catch((err) => {
        console.log(err, "err in get campaignlist");
      })
  };

  const reports = () => {
    setloading(true)
  //  axios.get(`https://localhost:5002/api/ProductManagement/ProductChart?campaignid=${selectedCampaign.value}`)
  //  .then((res) => {
   FinalvotingReport(selectedCampaign.value).then((res) => {
   
      
      if (res.data._response.status === 1) {
       
        let age = res.data._response.data.individualproductvoteage;
        let agetotal =
          res.data._response.data.agewisetotalofallproducts.age21to25;
        let agetotal1 =
          res.data._response.data.agewisetotalofallproducts.age26to30;
        let agetotal2 =
          res.data._response.data.agewisetotalofallproducts.age31to35;
        let agetotal3 =
          res.data._response.data.agewisetotalofallproducts.age36to40;
        let agetotal4 =
          res.data._response.data.agewisetotalofallproducts.age41to45;
        let agetotal5 =
          res.data._response.data.agewisetotalofallproducts.age46to50;
        let agetotal6 =
          res.data._response.data.agewisetotalofallproducts.age51to55;
        let agetotal7 =
          res.data._response.data.agewisetotalofallproducts.age56to60;
        let agetotal8 =
          res.data._response.data.agewisetotalofallproducts.ageabove60;
          let agetotal9 =
          res.data._response.data.agewisetotalofallproducts.age00to00;
        let agetotalVote = res.data._response.data.agegrouptotal;
        setloading(false);
        setAgeTotal(agetotalVote);
        setAgeCount(agetotal);
        setAgeCount1(agetotal1);
        setAgeCount2(agetotal2);
        setAgeCount3(agetotal3);
        setAgeCount4(agetotal4);
        setAgeCount5(agetotal5);
        setAgeCount6(agetotal6);
        setAgeCount7(agetotal7);
        setAgeCount8(agetotal8);
        setAgeCount9(agetotal9);
        setAge(age);
        setData(res.data._response.data.productsummary);
      } else if (res.data._response.status === 2) {
       
        setloading(false);
        setAgeTotal([]);
        setAgeCount([]);
        setAgeCount1([]);
        setAgeCount2([]);
        setAgeCount3([]);
        setAgeCount4([]);
        setAgeCount5([]);
        setAgeCount6([]);
        setAgeCount7([]);
        setAgeCount8([]);
        setAgeCount9([]);
        setAge([]);
        setData([])
      }
    })
      .catch((err) => {
        setloading(false);
        console.log(err, "err in get agereport");
      })
  };

  // const ageReport = () => {
  //   // axios.get(`https://localhost:5002/api/ProductManagement/ReportforAge?campaignid=${selectedCampaign.value}`)
  //   getAgeReport(selectedCampaign.value)
  //     .then((res) => {
  //       console.log(res, "got age response");
  //       const agedata = res.data._response.data.allagescount;

  //       let agepiecount = [];
  //       let agename = [];

  //       agedata.map((a) => {
  //         agepiecount.push(a.count);
  //       });

  //       agedata.map((a) => {
  //         agename.push(a.age);
  //       });

  //       setPieAgeName(agename);
  //       setPieAgeCount(agepiecount);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <>
      <div>
        <div>
          <div className="content-wrapper consti-section" id="divToPrint">
            <div className="content-header">
              <div className="container-fluid">
                <br />
                <br />
                <div className="row mb-4">
                  <div className="col-sm-12">
                    <h1 className="m-0 text-center">Voting List</h1>
                  </div>
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container">
                <div className="row">
                  <Col md={12}>
                    <Card style={{ padding: "20px" }}>
                      <Form.Group
                        as={Row}
                        className="mb-1 justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={12} className="mb-4">
                          <Form.Label className="w-100">Campaign</Form.Label>
                          <Select
                            className="w-100"
                            options={campaignOptions}
                            value={selectedCampaign}
                            onChange={handleCampaign}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                        </Col>
                      </Form.Group>

                      <div className="button_style m-0 text-center">
                        <Button
                          type="button"
                          variant="contained"
                          color="error"
                          onClick={clear}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Card>
                  </Col>
                </div>
              </div>
            </section>
          </div>
          <br></br>
        </div>
        <br />
        <br />

        { loading ? (
          <Loader />
        ) : selectedCampaign === '' || data.length === 0 ?

        <>
        <NotFound/>
        </>
         
         :
        
        (
          <>
            <div ref={componentRef}>
              <h1 className="m-0 text-center">Voting Report Summary</h1>
              <h4 className="m-0 text-center">{selectedCampaign.label}</h4>
              <br></br>

              <Container>
                <Paper sx={{ width: "100%", overflow: "hidden" }} id="report">
                  <TableContainer>
                    <div className="mt-5">
                      <h2>Age Wise</h2>

                      {/* <div className="row">
                        <div className="col-md-6"> */}

                          <Table
                            sx={{ maxHeight: 440 }}
                            aria-label="simple table"
                            id="example"
                            border="1"
                            cellpadding="3"
                            className={classes.table}
                          >
                            <TableHead>
                              <TableRow className="backgroundrow">
                                <TableCell></TableCell>
                                <>
                                  
                                  {Age.map((age, id) => (
                                    <TableCell
                                      width="10%"
                                      align="center"
                                      rowSpan={2}
                                      className="head1"
                                    >
                                      {age.productname}
                                    </TableCell>
                                  ))}
                                </>

                                <TableCell
                                  align="center"
                                  width="10%"
                                  rowSpan={2}
                                  className="head1"
                                >
                                  Total
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            
                            <TableBody>
                            <TableRow>
                                <TableCell width="10%" className="backgroundrow2">
                                 Other Years
                                </TableCell>

                                {Age.map((a, e) => (
                                  <TableCell align="center">{a.age00to00}</TableCell>
                                ))}

                                <TableCell className="headerbgcolor" align="center">
                                  {AgeCount9}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell width="10%" className="backgroundrow2">
                                  21-25 Years
                                </TableCell>

                                {Age.map((a, e) => (
                                  <TableCell align="center">{a.age21to25}</TableCell>
                                ))}

                                <TableCell className="headerbgcolor" align="center">
                                  {AgeCount}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="backgroundrow2">
                                  26-30 Years
                                </TableCell>

                                {Age.map((a, e) => (
                                  <TableCell align="center">{a.age26to30}</TableCell>
                                ))}

                                <TableCell className="headerbgcolor" align="center">
                                  {AgeCount1}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell className="backgroundrow2">
                                  31-35 Years
                                </TableCell>

                                {Age.map((a, e) => (
                                  <TableCell align="center">{a.age31to35}</TableCell>
                                ))}

                                <TableCell className="headerbgcolor" align="center">
                                  {AgeCount2}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell className="backgroundrow2">
                                  36-40 Years
                                </TableCell>

                                {Age.map((a, e) => (
                                  <TableCell align="center">{a.age36to40}</TableCell>
                                ))}
                                <TableCell className="headerbgcolor" align="center">
                                  {AgeCount3}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell className="backgroundrow2">
                                  41-45 Years{" "}
                                </TableCell>

                                {Age.map((a, e) => (
                                  <TableCell align="center">{a.age41to45}</TableCell>
                                ))}
                                <TableCell className="headerbgcolor" align="center">
                                  {AgeCount4}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell className="backgroundrow2">
                                  46-50 Years{" "}
                                </TableCell>

                                {Age.map((a, e) => (
                                  <TableCell align="center">{a.age46to50}</TableCell>
                                ))}
                                <TableCell className="headerbgcolor" align="center">
                                  {AgeCount5}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell className="backgroundrow2">
                                  51-55 Years{" "}
                                </TableCell>

                                {Age.map((a, e) => (
                                  <TableCell align="center">{a.age51to55}</TableCell>
                                ))}
                                <TableCell className="headerbgcolor" align="center">
                                  {AgeCount6}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="backgroundrow2">
                                  56-60 Years
                                </TableCell>

                                {Age.map((a, e) => (
                                  <TableCell align="center">{a.age56to60}</TableCell>
                                ))}
                                <TableCell className="headerbgcolor" align="center">
                                  {AgeCount7}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="backgroundrow2">
                                  above 60 Years{" "}
                                </TableCell>

                                {Age.map((a, e) => (
                                  <TableCell align="center">{a.above60}</TableCell>
                                ))}
                                <TableCell className="headerbgcolor" align="center">
                                  {AgeCount8}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left" className="backgroundrow2">
                                  Total Votes
                                </TableCell>

                                {Age.map((total, e) => (
                                  <TableCell align="center">
                                    {total.overallcount}
                                  </TableCell>
                                ))}
                                <TableCell className="headerbgcolor" align="center">
                                  {AgeTotal}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>

                        </div>
                        {/* <div className="col-md-6">
                          <Chart
                            type="pie"
                            className="chart"
                            width={500}
                            height={300}
                            series={PieAgeCount}
                            options={{
                              title: { text: "Voting Report" },
                              noData: { text: "Empty Data" },
                              colors: ['#ac92eb', '#4fc1e8', '#8bcb30', '#ffc809', '#ed5564', '#e0440e', '#e6693e', "#679cab", "#777777"],
                              labels: PieAgeName,
                              responsive: [{
                                breakpoint: 1024,
                                options: {
                                  chart: {
                                    width: 700
                                  },
                                  legend: {
                                    position: 'right'
                                  }

                                },
                                breakpoint: 480,
                                options: {
                                  chart: {
                                    width: 250
                                  },
                                  legend: {
                                    position: 'bottom'
                                  },

                                },

                              }]
                            }}
                          />

                        </div> */}
                      {/* </div>
                    </div> */}
                  </TableContainer>
                </Paper>
              </Container>
            </div>
            <br></br>
            <div className="text-center m-0">
              &nbsp;&nbsp;
              <Button variant="contained" onClick={handlePrint}>
                Print
              </Button>
            </div>
          </>
        )}
      </div>
    </>

  );
}
