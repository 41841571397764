import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import Swal from "sweetalert2";
import MaterialTable from "material-table";
import Select from "react-select";
import { productList, Allbrands, CategoryList, postNewsData, ProductListgetById, getNewsData, EditNewsData, NewsImages, DeleteNewsData } from "./ProductServiceAPI";
import ProductURL from "../../ServerCalls/ProductServer";
import Button from '@mui/material/Button';
import { useLocation } from "react-router-dom";


const News = () => {
  const columns = [
    {
      title: "Product Name",
      field: "productname",
      align: "center",
    },
    {
      title: "News Header",
      field: "newsheader",
      align: "center",
    },
    {
      title: "Product Category",
      field: "categoryname",
      align: "center",
    },
    {
      title: "Product Brand",
      field: "brandname",
      align: "center",
    },
    {
      title: "News Description",
      field: "newsdescription",
      type: "date",
      align: "center",
    },
    {
      title: "Product Image",
      field: "newsimage",
      align: "center",
    },

  ];

  const [formError, setFormError] = useState([]);
  const [update, setupdate] = useState(false);
  const [productSelected, setProductSelected] = useState([]);
  const [ProductOption, setProductOption] = useState([]);
  const [header, setHeader] = useState("");
  const [newsDescription, setNewsDescription] = useState("");
  const [NewsImage, setNewsImage] = useState("");
  const [NewsImagePath, setNewsImagePath] = useState([]);
  const [NewsListData, setNewsListData] = useState([]);
  const [categorySelected, setcategorySelected] = useState([]);
  const [categoryOption, setcategoryOption] = useState([]);
  const [brandSelected, setbrandSelected] = useState([]);
  const [brandOption, setbrandOption] = useState([]);
  const [news, setNews] = useState(0);
  const [Showlist, setShowlist] = useState(false);
  const PhotoPath = ProductURL.photoURL + "ProductNewsImages/";
  const inputRefrence = useRef(null);
  const jumptoBack = useLocation();

  const validate = () => {
    let formError = {};
    let isValid = true;

    if (header === "") {
      isValid = false;
      formError["header"] = "Please Enter Header Name";
    } else if (header.length < 3) {
      isValid = false;
      formError["header"] =
        "Please Enter Minimun 3 Characters "
      }
        else if (header.length > 250) {
      isValid = false;
      formError["header"] =
        "Maximum 250 characters are allowed";
    }

    if (newsDescription === "") {
      isValid = false;
      formError["newsDescription"] = "Please Enter campaign Description!";

    }

    if (productSelected.length < 1) {
      isValid = false;
      formError["productSelected"] = "Please select product";
    }

    if (categorySelected.length < 1) {
      isValid = false;
      formError["categorySelected"] = "Please select category";
    }
    if (brandSelected.length < 1) {
      isValid = false;
      formError["brandSelected"] = "Please select Brand";
    }
    if (NewsImage.length < 1) {
      isValid = false;
      formError["NewsImage"] = "Please select Image"

    }



    setFormError(formError);
    return isValid;
  };

  const HandleProduct = (selected) => {
    setProductSelected(selected);

    ProductListgetById(selected.value)
      .then((res) => {
        const prodata = res.data._response.data.productgetid[0]
        setcategorySelected({ value: prodata.categoryid, label: prodata.categoryname })
        setbrandSelected({ value: prodata.brandid, label: prodata.brandname })
      })
      .catch((err)=>{
        console.log(err, 'err in getprouctlist');
      })
    setFormError({ ...formError, productSelected: "" });
  };


  const handleInput = (e) => {
    setHeader(e.target.value);
    setFormError({ ...formError, header: "" });
  };

  const handleDescription = (e) => {
    setNewsDescription(e.target.value);
    setFormError({ ...formError, newsDescription: "" });
  };

  const handleimage1 = (e) => {
    setNewsImage(e.target.files[0]);
    setNewsImagePath(URL.createObjectURL(e.target.files[0]));
    setFormError({ ...formError, NewsImage: "" });

  };

  useEffect(() => {
    setShowlist(false);
    productListdata();
    NewsData();
    getCategory()
    getBrand()
  }, [jumptoBack.key]);

  const getCategory = () => {
    CategoryList()
      .then((res) => {

        let data = res.data._response.data.getprocategory;
        let options = data.map((a) => ({
          value: a.categoryid,
          label: a.categoryName,
        }));
        setcategoryOption(options);

      })
      .catch((err)=>{
        console.log(err,'err in get categorylist');
      })

  }

  const getBrand = () => {
    Allbrands()
      .then((res) => {
        let data1 = res.data._response.data.getbrand;
        let options = data1.map((d) => ({
          value: d.brandId,
          label: d.brandName,
        }));

        setbrandOption(options);

      })
      .catch((err)=>{
        console.log(err,'err in getbrands');
      })

  }



  const image_upload = async (data) => {

    NewsImages(data)
      .then((res) => {
        console.log("photo res", res);
      })
      .catch((err) => {
        console.log(err, "err in photo submit");
      });
  };

  const saveData = async () => {

    const isvalid = validate();
    if (isvalid) {

      const fixTimezoneOffset = (date) => {

        if (!date) return "";

        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();

      }
      const data = {
        ProductId: productSelected.value,
        NewsHeader: header,
        NewsDescription: newsDescription,
        NewsImage: NewsImage.name,
        BrandId: brandSelected.value,
        CategoryId: categorySelected.value,
        NewsDateTime: fixTimezoneOffset(new Date()),
      };
      let formdata = new FormData();
      formdata.append("file", NewsImage);
      await image_upload(formdata);
      postNewsData(data)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "News Added Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          })
          NewsData()
          cancelUser()
        })
        .catch((error) => {
          console.log(error);
        })
    }
  };



  const productListdata = () => {
    productList()
      .then((res) => {
        let list = res.data._response.data.getproduct;
        const options = list.map((a) => ({
          value: a.productid,
          label: a.productname,
        }));
        setProductOption(options);
      })
      .catch((err) => {
        console.log(err, "err in get category list");
      });
  };


  const NewsData = () => {
    getNewsData()
      .then((response) => {
        const list = response.data._response.data.getproductnews
        setNewsListData(list)
      }).catch((err) => {
        console.log(err);
      })

  }

  const updateUser = (a) => {
    setShowlist(false);
    setupdate(true)
    setNews(a.newsid)
    setProductSelected({
      value: a.productid,
      label: a.productname,
    });
    setbrandSelected({
      value: a.brandid,
      label: a.brandname,
    });
    setcategorySelected({
      value: a.categoryid,
      label: a.categoryname
    })
    setHeader(a.newsheader)
    setNewsDescription(a.newsdescription)
    setNewsImage(a.newsimage)

  }

  const updateClick = async () => {

    const fixTimezoneOffset = (date) => {

      if (!date) return "";

      return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();

    }

    const data = {
      NewsId: news,
      ProductId: productSelected.value,
      NewsHeader: header,
      NewsDescription: newsDescription,
      BrandId: brandSelected.value,
      CategoryId: categorySelected.value,
      NewsImage: NewsImage.name === undefined ? NewsImage : NewsImage.name,
      NewsDateTime: fixTimezoneOffset(new Date()),
    };
    let formdata = new FormData();
    formdata.append("file", NewsImage);
    await image_upload(formdata);
    EditNewsData(data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "News Updated Successfully",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ok",
        });
        NewsData()
        cancelUser();

      }).catch((err) => {
        Swal.fire({
          icon: "error",
          title: "News Not Updated",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      })

  }

  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteNewsData(id)
            .then((res) => {
              if (res.data._response.status === 1) {
              Swal.fire({
                icon: "success",
                title: "News Deleted Sucessfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              NewsData();
            }else if(res.data._response.status === 2){
                      
              Swal.fire({
                icon: "warning",
                title: res.data._response.message,
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
            });


        }

    })
   
}
}).catch((err) => {
        console.log(err, "err in trash product");
      });
  };

  const cancelUser = () => {
    setupdate(false);
    setProductSelected("");
    setHeader("");
    setNewsDescription("");
    setNewsImagePath("");
    setNewsImage("")
    inputRefrence.current.value = null;
    setFormError("");
    setcategorySelected("");
    setbrandSelected("")
    setNewsImage("");

  };

  return (


    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <br /><br />
            <div className="row mb-2">
              {!Showlist ? (
                <div className="col-sm-12">
                  {!update ? (
                  <h1 className="m-0 text-center"> ADD NEWS </h1>
                  ):
                  <h1 className="m-0 text-center"> EDIT NEWS </h1>
              }
                </div>
              ) :
                <div className="col-sm-12">
                  <h1 className="m-0 text-center"> NEWS LIST </h1>
                </div>
              }
            </div>
     
          <Col md={12} >
            <div className='d-flex justify-content-end'>
              {!Showlist ?
                <button
                  type="button"
                  className="btn btn-pos3 "
                  onClick={() => setShowlist(true)}
                >
                  <i className="fa-solid fa-list"></i> List
                </button>
                :
                <button
                  type="button"
                  className="btn btn-pos4 "
                  onClick={() => setShowlist(false)}
                >
                  <i className="fa fa-arrow-circle-left"></i> back
                </button>
              }
            </div>
          </Col>
          </div>
        </div>
        <section className="content  consti-section">
          <div className="container">
            {!Showlist ? (
              <div className="row">
                <Col
                  md={12}
                >
                  <Card
                    style={{
                      padding: "20px"
                    }}
                  >
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={6}>
                        <Form.Label className="w-100"> Product </Form.Label>
                        <Select
                          className="w-100"
                          options={ProductOption}
                          onChange={HandleProduct}
                          value={productSelected}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <br/>
                        <div style={{ color: "red" }}>
                          {formError.productSelected}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <Form.Label className="w-100"> Product Brand </Form.Label>
                        <Select
                          className="w-100"
                          options={brandOption}
                          // onChange={HandleBrand}
                          value={brandSelected}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <br/>
                        <div style={{ color: "red" }}>
                          {formError.brandSelected}
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={6}>
                        <Form.Label className="w-100"> Product Category</Form.Label>
                        <Select
                          className="w-100"
                          options={categoryOption}
                          // onChange={HandleCategory}
                          value={categorySelected}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <br/>
                        <div style={{ color: "red" }}>
                          {formError.categorySelected}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <Form.Label className="w-100">

                          News Header
                        </Form.Label>
                        <input
                          className="w-100 form-control"
                          type="text"
                          name="header"
                          value={header}
                          onChange={handleInput}
                        />
                        <div style={{ color: "red" }}>{formError.header}</div>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      // controlId="formHorizontalEmail"
                    >
                      <Col sm={6}>
                        <Form.Label className="w-100">
                          News Description
                        </Form.Label>
                        <textarea
                          className="w-100 form-control"
                          placeholder="Tell us more"
                          rows="3"
                          onChange={handleDescription}
                          name="newsDescription"
                          value={newsDescription}
                        ></textarea>
                        <div style={{ color: "red" }}>
                          {formError.newsDescription}
                        </div>
                      </Col>                     
                      <Col sm={6}>
                        <Form.Label className="w-100">

                          News Image
                        </Form.Label>
                        
                        <FormControl
                            className="w-100 form-control"
                            type="file"
                            name="NewsImage"
                            onChange={handleimage1}
                            ref={inputRefrence}
                          />
                          <div className="imagepreview">
                            <img
                              src={
                                update === false
                                  ? NewsImagePath
                                  : NewsImage.name === undefined
                                    ? PhotoPath + NewsImage
                                    : NewsImagePath
                              }
                              alt=" "
                              width="80"
                              height="60"
                            />
                          </div>
                        <br/>
                        <div style={{ color: "red" }}>
                          {formError.NewsImage}
                        </div>
                      </Col>
                    </Form.Group>
                    <div className="button_style m-0 text-center">
                      {update === false ? (
                        <Button
                          type="submit"
                          variant='contained'
                          onClick={saveData}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          variant='contained'
                          color='success'
                          onClick={() => updateClick()}
                        >
                          Update
                        </Button>
                      )} {" "}

                      <Button
                        type="button"
                        variant='contained'
                        color='error'
                        onClick={() => cancelUser()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Card>
                </Col>
              </div>
            ) :
              <>
                <br />
                <br />
                <br />
                <Col className='mt-0' md={12} >
                  <MaterialTable
                    title={false}
                    data={NewsListData}
                    columns={columns}
                    actions={[
                      {
                        icon: "edit",
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                          updateUser(rowData);
                        },
                      },

                      {
                        icon: "delete",
                        tooltip: "Delete",
                        onClick: (event, rowData) => {
                          deleteUser(rowData.newsid);
                        },
                      },
                    ]}
                    options={{
                      search: true,
                      pageSize: 10,
                      showFirstLastPageButtons: false,
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      pageSizeOptions: [10, 15, 20, 50],

                    }}
                  />
                </Col>
              </>
            }
          </div>
        </section>
      </div>

    </div>
  );
};

export default News;
