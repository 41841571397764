
import React, { useState, useEffect, useRef } from 'react'
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import MaterialTable from "material-table";
import Button from '@mui/material/Button';
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import getYouTubeID from 'get-youtube-id';
import DatePicker from 'react-datepicker'
import Select from "react-select";
import { Allbrands, BrandPhotos, updateBrandDetails, deleteData } from './ProductServiceAPI'
import ProductURL from '../../ServerCalls/ProductServer';
import axios from "../../ServerCalls/AxiosInstance";


const PhotoPath = "https://media.topbrandsranking.com/VideoFiles/";


const VideoCampaign = () => {

    const columns = [
        { title: " Brand Name ", field: "brandname", align: "center" },
        { title: " Video ShortName ", field: "videoshortname", align: "center" },
        { title: " Video Description ", field: "videodescription", align: "center" },
        { title: " Video Name ", field: "uploadvideo", align: "center", type: "date" },

    ];

    const [selectBrand, setselectBrand] = useState([]);
    const [brandOption,setBrandOption] = useState([]);
    const [VideoName,setVideoName] = useState('');
    const [shortName,setShortName] = useState('');
    const [VideoDescription,setVideoDescription] = useState('');
    const [Data,setData] = useState([]);
    const [Id,setId] = useState(0)
    
    const [update, setupdate] = useState(false);
   
    const [image11, setimage11] = useState('');
  
    const [image11path, setImage11path] = useState('')
 
    const [Showlist, setShowlist] = useState(false)

    const [formError, setFormError] = useState([])
    const jumptoBack = useLocation();



    const handleimage11 = (e) => {
        setimage11(e.target.files[0])
        setImage11path(URL.createObjectURL(e.target.files[0]))
        setFormError({ ...formError, image11: "" })

    }


    const inputRefrence1 = useRef(null);



    const handleShortName = (e) => {

        setShortName(e.target.value)
        setFormError({ ...formError, shortName: "" })
    }
    const handleDescription = (e) => {

        setVideoDescription(e.target.value)
        setFormError({ ...formError, VideoDescription: "" })
    }

    const handleBrand = (selected)=>{
        setselectBrand(selected)
        setFormError({ ...formError, selectBrand: "" })
    }


    const handleVideo = (e)=>{
        setVideoName(e.target.value)
        setFormError({ ...formError, VideoName: "" })
    }
   


    useEffect(() => {
        getBrandList();
        getList()
        setShowlist(false);
    }, [jumptoBack.key]);

    const getBrandList = () => {
        Allbrands()
            .then((res) => {
                const data = res.data._response.data.getbrand
                const option = data.map((a)=>({
                    value:a.brandId,
                    label:a.brandName
                   
                }))
                setBrandOption(option)
                
            }).catch((err) => {
                console.log(err, "err in get  brand");
            })

    }

    const getList = ()=> {

        axios.get("https://api.topbrandsranking.com/ProductManagement/GetVideoMaster")
        .then((res)=>{
            const list = res.data._response.data.getvideomaster
            setData(list)
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })
  


    }

    const validate = () => {

        let regex = /^[A-Za-z0-9 ]+$/;
        let emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // let passregex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
        let contactregex = /^[6-9]\d{9}$/gi;



        let formError = {}
        let isValid = true;
        if (shortName === '') {
            isValid = false;
            formError["shortName"] = "please Enter Short Name"
        }
        if (VideoDescription === '') {
            isValid = false;
            formError["VideoDescription"] = "please Enter Video Description"
        }

        if (selectBrand.length < 1) {
            isValid = false;
            formError["selectBrand"] = "Please select Brand"

        }
 

        if (VideoName === "") {
            isValid = false;
            formError["VideoName"] = "Please Enter Video Link"

        }

        setFormError(formError);

        return isValid;

    }

    const saveData = async () => {
  

    const isValid = validate()
    if(isValid === true) {

        const data = {
            brandid:selectBrand.value,
            videoshortname:shortName,
            videodescription:VideoDescription,
            uploadvideo:VideoName

        }

    //     let formdata = new FormData();
    //    formdata.append("file", image11);
    //    await image_upload(formdata);

        axios.post("https://api.topbrandsranking.com/ProductManagement/AddVideoMasterData",data)
        // axios.post("https://localhost:5002/api/ProductManagement/AddVideoMasterData",data)
        .then((res)=>{
           
            if (res.data._response.status === 1) {


                Swal.fire({
                    icon: "success",
                    title: "Brand Video Added Sucessfully",
                    showConfirmButton: true,
                    confirmButtonColor: "#3085d6",
                    // cancelButtonColor: '#d33',
                    confirmButtonText: "ok",
                });
                setShowlist(false)
                getList();
                cancel();
            }
        }).catch((err)=>{
            console.log(err)
        })
       
      

    }
}

    const image_upload = async (data) => {

        await axios.post("https://api.topbrandsranking.com/ProductManagement/VideoMaster/SaveFile",data)
        // BrandPhotos(data)
            .then((res) => {
                console.log("photo res", res)
            }).catch((err) => {
                console.log(err, "err in photo submit")
            })
    }

    const getdata = (a) => {

        setShowlist(false);
        setupdate(true);
        setShortName(a.videoshortname);
        setselectBrand({value:a.brandid,label:a.brandname})
        setVideoDescription(a.videodescription)
        setId(a.videoid)
        setVideoName(a.uploadvideo);
        
    }

    const updateData = async () => {
        setShowlist(false)
        const isValid = validate();

        if (isValid) {
            const updatedata = {
               videoid:Id,
               brandid:selectBrand.value,
            videoshortname:shortName,
            videodescription:VideoDescription,
            uploadvideo:VideoName


            }
            // let formdata = new FormData();
            // formdata.append("file", image11);

            // await image_upload(formdata);
            axios.put("https://api.topbrandsranking.com/ProductManagement/EditVideoMaster",updatedata)
        // updateBrandDetails(updatedata)
                .then((res) => {
                  

                    if (res.data._response.status === 1) {


                        Swal.fire({
                            icon: "success",
                            title: "Brand Video Updated Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        setShowlist(false)
                        getBrandList();
                        cancel();
                    }
                })
                .catch((err) => {
                    console.log(err, "err in update brand");
                })
        }
    }

    const TrashData = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((res) => {

            if (res.isConfirmed) {
                axios.delete(`https://api.topbrandsranking.com/ProductManagement/RemoveVideoMaster/${id}`)
                // deleteData(row.brandId)
                .then((res) => {
                    if (res.data._response.status === 1) {

                        Swal.fire({
                            icon: "success",
                            title: "Brand Video Deleted Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        getBrandList();
                    }
                    else if (res.data._response.status === 2) {

                        Swal.fire({
                            icon: "warning",
                            title: res.data._response.message,
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                    }
                }).catch((err) => {
                    console.log(err, "err in delete brand");
                })
            }
        })
            .catch((err) => {
                console.log(err, "err in trash brand");
            })
    }

    const cancel = () => {
      
        
    setselectBrand([])
    setShortName("")
    setVideoDescription("")
    setimage11("")
    setFormError('')
    setVideoName("")
    setupdate(false)
    inputRefrence1.current.value = null;
    setImage11path("")

  
    }

    return (
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <br /><br />
                  
                           <div className="row mb-2">
                  {!Showlist ? (
                    <div className="col-sm-12">
                       {!update ? (
                      <h1 className="m-0 text-center"> ADD BRAND VIDEO </h1>
                       ):
                      <h1 className="m-0 text-center"> EDIT BRAND VIDEO </h1>
                       }
                    </div>
                  ) :
                    <div className="col-sm-12">
                      <h1 className="m-0 text-center"> Brand video List </h1>
                    </div>
                  }
                </div>
                   
                    <Col md={12} >
                        <div className='d-flex justify-content-end'>
                            {!Showlist ?
                                <button
                                    type="button"
                                    className="btn btn-pos3 "
                                    onClick={() => {
                                        setShowlist(true);
                                        cancel();
                                    }}
                                >
                                    <i className="fa-solid fa-list"></i> List
                                </button>
                                :
                                <button
                                    type="button"
                                    className="btn btn-pos4 "
                                    onClick={() => setShowlist(false)}
                                >
                                    <i className="fa fa-arrow-circle-left"></i> back
                                </button>
                            }
                        </div>
                    </Col>
                    </div>
                </div>
                <section className="content consti-section">
                    <div className="container">
                        <div className="row">
                            <Col md={12}>
                                <Card style={{ padding: "20px" }}>
                                    {!Showlist ? (
                                        <div>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3 justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >
                                                
                                              
                                          
                                               


                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">Brand Name:</Form.Label>
                                                    <Select
                                className="w-100"
                                 options={brandOption}
                                 onChange={handleBrand}
                                 value={selectBrand}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />

                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 13, color: "red" }}>{formError.selectBrand}</div>
                                                            : null

                                                    }
                                                </Col>
                                               
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">Video ShortName:</Form.Label>
                                                    <input className="w-100 form-control" type="text"  placeholder='Video ShortName' name='Video ShortName' value={shortName} onChange={handleShortName} />
                                                    {
                                                        formError ?
                                                            <div style={{ fontSize: 13, color: "red" }}>{formError.shortName}</div>
                                                            : null

                                                    }
                                                </Col>
                                              
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100">
                                                        Video  Description
                                                    </Form.Label>
                                                    <textarea
                                                        className="w-100 form-control"
                                                        placeholder="Tell us more"
                                                        rows="3"
                                                        onChange={handleDescription}
                                                        name="VideoDescription"
                                                        value={VideoDescription}
                                                    ></textarea>
                                                    <div style={{ fontSize: 13,color: "red" }}>{formError.VideoDescription}</div>
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100"> Upload Video</Form.Label>
                                                    <input className="w-100 form-control" type="text"  placeholder='Video Link' name='Video ShortName' value={VideoName} onChange={handleVideo} />

                                                    {/* <FormControl className="m-2" type="file" name='file' controlId={image11} ref={inputRefrence1} onChange={handleimage11} /> */}
                                                    {/* <br/> */}
                                                    <div style={{fontSize: 13, color: "red" }}>{formError.VideoName}</div>
                                                    <div className="imagepreview">
                                                        <iframe className='video'
                  title='Youtube player'
                  allowfullscreen="allowfullscreen"
                  width="180" height="120"
                  sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                 
                  src={`https://youtube.com/embed/${getYouTubeID(VideoName)}?autoplay=0`}
                />
                                                    </div>
                                             
                                                </Col>
                                        
                                              
                                                   
                                            </Form.Group>
                                         
                                               
                                    
                                            <div className="button_style m-0 text-center">
                                                {update === false ?
                                                    <Button
                                                        type="submit"
                                                        variant='contained'
                                                        onClick={() => saveData()}
                                                    >
                                                        Submit
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="button"
                                                        variant='contained'
                                                        color='success'
                                                        onClick={() => updateData()}
                                                    >
                                                        Update
                                                    </Button>
                                                }{" "}
                                                <Button
                                                    type="submit"
                                                    variant='contained'
                                                    color='error'
                                                    onClick={()=>cancel()}

                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>

                                            <MaterialTable
                                                // className="BrandHeaderTbl"
                                                title="Brand Video Details"
                                                data={Data}
                                                columns={columns}
                                                actions={[
                                                    {
                                                        icon: "edit",
                                                        tooltip: "Edit",
                                                        onClick: (event, rowData) => {
                                                   console.log(rowData,"ooo")
                                                            getdata(rowData);

                                                        },
                                                    },

                                                    {
                                                        icon: "delete",
                                                        tooltip: "Delete",
                                                        onClick: (event, rowData) => {
                                                            console.log(rowData,"jj")
                                                            TrashData(rowData.videoid);
                                                        },
                                                    },

                                                ]}
                                                options={{
                                                    search: true,
                                                    pageSize: 10,
                                                    showFirstLastPageButtons: false,
                                                    actionsColumnIndex: -1,
                                                    addRowPosition: "first",
                                                    pageSizeOptions: [10, 15, 20, 50],
                                                }}
                                            />
                                            {/* <button
                                                type="button"
                                                className="btn btn-pos2 m-4"
                                                onClick={() => setShowlist(false)}
                                            >
                                                <i className="fa-solid fa-circle-left"></i>&nbsp;Back
                                            </button> */}
                                        </div>
                                    )}
                                </Card>
                            </Col>
                        </div>
                    </div>
                </section>
            </div>

        </div>

    )
}

export default VideoCampaign;