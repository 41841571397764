import React from 'react';
import {VscSymbolParameter} from 'react-icons/vsc';
import { Settings, AccountBox } from "@material-ui/icons";
import InfoIcon from '@mui/icons-material/Info';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CategoryIcon from '@mui/icons-material/Category';
import CampaignIcon from '@mui/icons-material/Campaign';
import PublicIcon from '@mui/icons-material/Public';
import MenuIcon from '@mui/icons-material/Menu';
import FeedSharpIcon from '@mui/icons-material/FeedSharp';
import AssessmentSharpIcon from '@mui/icons-material/AssessmentSharp';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import SatelliteIcon from '@mui/icons-material/Satellite';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import DiamondIcon from '@mui/icons-material/Diamond';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import WcIcon from '@mui/icons-material/Wc';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';

export const AdminMenu = [



    {
        id: 1,
        menuName: 'Dashboard',
        menuDescription: 'Device mac id setup menus',
        path: '/Admindashboard',
        exact: true,
        icon: <HomeIcon />,
        submenu: []
    },



    {
        id: 1,
        menuName: 'Region',
        menuDescription: 'Parameters menus',
        path: '#',
        exact: true,
        icon: <PublicIcon />,
        submenu: [
            {
                id: 3,
                menuName: 'County',
                menuDescription: 'Parameters info',
                path: '/county',

                icon: <SatelliteIcon />,
            },
            {
                id: 4,
                menuName: 'Constituency',
                menuDescription: 'Parameter value type',
                path: '/constituency',
                icon: <ShareLocationIcon />
            },
            {
                id: 5,
                menuName: 'Ward',
                menuDescription: 'To Setup sub departments',
                path: '/ward',
                icon: <AssistantDirectionIcon />
            }
        ]
    },


    {
        id: 2,
        menuName: 'Product Section',
        menuDescription: 'Products',
        path: '#',
        exact: true,
        icon: <ProductionQuantityLimitsIcon />,
        submenu: [
            {
                id: 3,
                menuName: 'Add Product',
                menuDescription: 'product info',
                path: '/Product',
                icon: <AddShoppingCartIcon />,
            },
            {
                id: 4,
                menuName: 'Product Category',
                menuDescription: 'Parameter value type',
                path: '/productCategory',
                icon: <CategoryIcon />
            },
                 {
                id: 4,
                menuName: 'Product Campaign',
                menuDescription: 'Parameter value type',
                path: '/ProductCampaign',
                icon: <Inventory2Icon />
            },
            {
                id: 3,
                menuName: 'Brand Video ',
                menuDescription: 'video campaign',
                path: '/Brand_Video',
                icon: <LeaderboardIcon />,
            },
            {
                id: 3,
                menuName: 'Video Campaign ',
                menuDescription: 'Video Campaign',
                path: '/Video_Campaign',
                icon: <LeaderboardIcon />,
            },
               {
                id: 3,
                menuName: 'Campaign Level',
                menuDescription: 'product info',
                path: '/campaignLevel',
                icon: <LeaderboardIcon />,
            },
            {
                id: 3,
                menuName: 'Parameter Information',
                menuDescription: 'Parameters info',
                path: '/parameters',
                icon: <InfoIcon />,
            },
            {
                id: 1,
                menuName: 'Brand',
                menuDescription: 'Device mac id setup menus',
                path: '/Brand',
                exact: true,
                icon: <DiamondIcon />,
                submenu: []
            },
        
        
        
            {
                id: 1,
                menuName: 'News',
                menuDescription: 'Device mac id setup menus',
                path: '/News',
                exact: true,
                icon: <FeedSharpIcon />,
                submenu: []
            },


        ]
    },
 

    // {
    //     id: 2,
    //     menuName: 'Report',
    //     menuDescription: 'campaign',
    //     path: '#',
    //     exact: true,
    //     icon: <CampaignIcon />,
    //     submenu: [
            // {
            //     id: 3,
            //     menuName: 'Campaign Level',
            //     menuDescription: 'product info',
            //     path: '/campaignLevel',
            //     icon: <LeaderboardIcon />,
            // },
            // {
            //     id: 4,
            //     menuName: 'Product Campaign',
            //     menuDescription: 'Parameter value type',
            //     path: '/ProductCampaign',
            //     icon: <Inventory2Icon />
            // },

        // ]
    // },



   

    {
        id: 1,
        menuName: 'General News',
        menuDescription: 'Device mac id setup menus',
        path: '/General_news',
        exact: true,
        icon: <FeedSharpIcon />,
        submenu: []
    },



    {
        id: 1,
        menuName: 'Member',
        menuDescription: 'Parameters menus',
        path: '#',
        exact: true,
        icon: <CardMembershipIcon />,
        submenu: [
            {
                id: 3,
                menuName: 'Add Member',
                menuDescription: 'Parameters info',
                path: '/MemberRegistration',

                icon: <BookmarkAddIcon />,
            },
           
        ]
    },

    {
        id: 1,
        menuName: 'Celebrity Section',
        menuDescription: 'Celebrity pages',
        path: '#',
        exact: true,
        icon: <TheaterComedyIcon />,
        submenu: [
            {
                id: 3,
                menuName: 'Add Celebrity Category',
                menuDescription: 'Celebrity Category',
                path: '/celebrityCategory',

                icon: <CategoryIcon />,
            },
            {
                id: 4,
                menuName: 'Award Master',
                menuDescription: 'Award Master',
                path: '/AwardMaster',
                icon: <EmojiEventsIcon />
            },
            {
                id: 5,
                menuName: 'Add Celebrity',
                menuDescription: 'Celebrity',
                path: '/AddCelebrity',
                icon: <PersonAddAlt1Icon />
            },
            {
                id: 6,
                menuName: 'Release Category',
                menuDescription: 'Release Category',
                path: '/RelaseCategory',
                icon: <NewReleasesIcon />
            },
            {
                id: 6,
                menuName: 'Social Platforms',
                menuDescription: 'Socialplatforms',
                path: '/Socialplatforms',
                icon: <GroupsIcon />
            },
            // {
            //     id: 6,
            //     menuName: 'Media Release',
            //     menuDescription: 'mediarelease',
            //     path: '/mediarelease',
            //     icon: <CardMembershipIcon />
            // },
            {
                id: 6,
                menuName: 'Celebrity Campaign',
                menuDescription: 'Socialplatforms',
                path: '/CelebrityCampaign',
                icon: <CampaignIcon />
            },
            
          
           
        ]
    },

    // {
    //     id: 1,
    //     menuName: 'Product Reports',
    //     menuDescription: 'Parameters menus',
    //     path: '#',
    //     exact: true,
    //     icon: <AssessmentSharpIcon />,
    //     submenu: [
    //         {
    //             id: 3,
    //             menuName: 'Age Report',
    //             menuDescription: 'Parameters info',
    //             path: '/AgewiseReport',

    //             icon: <PlusOneIcon />,
    //         },
    //         {
    //             id: 4,
    //             menuName: 'Gender Report',
    //             menuDescription: 'Parameter value type',
    //             path: '/GenderwiseReport',
    //             icon: <WcIcon />
    //         },
    //         {
    //             id: 4,
    //             menuName: 'County Report',
    //             menuDescription: 'Parameter value type',
    //             path: '/CountywiseReport',
    //             icon: <PublicIcon />
    //         },
    //         {
    //             id: 4,
    //             menuName: 'Education Report',
    //             menuDescription: 'Parameter value type',
    //             path: '/EducationwiseReport',
    //             icon: <SchoolIcon />
    //         },
    //         {
    //             id: 4,
    //             menuName: 'Occupation Report',
    //             menuDescription: 'Parameter value type',
    //             path: '/OccupationwiseReport',
    //             icon: <WorkIcon />
    //         },
    //     ]
    // },



    // {
    //     id: 1,
    //     menuName: 'Celebrity Reports',
    //     menuDescription: 'Parameters menus',
    //     path: '#',
    //     exact: true,
    //     icon: <AssessmentSharpIcon />,
    //     submenu: [
    //         {
    //             id: 3,
    //             menuName: 'Age Report',
    //             menuDescription: 'Parameters info',
    //             path: '/AgewiseCelebrityReport',

    //             icon: <PlusOneIcon />,
    //         },
    //         {
    //             id: 4,
    //             menuName: 'Gender Report',
    //             menuDescription: 'Parameter value type',
    //             path: '/GenderwiseCelebrityReport',
    //             icon: <WcIcon />
    //         },
    //         {
    //             id: 4,
    //             menuName: 'County Report',
    //             menuDescription: 'Parameter value type',
    //             path: '/CountywiseCelebrityReport',
    //             icon: <PublicIcon />
    //         },
    //         {
    //             id: 4,
    //             menuName: 'Education Report',
    //             menuDescription: 'Parameter value type',
    //             path: '/EducationwiseCelebrityReport',
    //             icon: <SchoolIcon />
    //         },
    //         {
    //             id: 4,
    //             menuName: 'Occupation Report',
    //             menuDescription: 'Parameter value type',
    //             path: '/OccupationwiseCelebrityReport',
    //             icon: <WorkIcon />
    //         },
    //         // {
    //         //     id: 4,
    //         //     menuName: ' Report',
    //         //     menuDescription: 'Parameter value type',
    //         //     path: '/CelebrityReport',
    //         //     icon: <AssessmentSharpIcon />
    //         // },
    //     ]
    // },

    {
        id: 1,
        menuName: 'Add Magazines',
        menuDescription: 'Magazines',
        path: '/addmagzine',
        exact: true,
        icon: <AssessmentSharpIcon />,
        submenu: []
    },

    {
        id: 1,
        menuName: 'Contact Us List',
        menuDescription: 'Contact Us',
        path: '/ContactUs_List',
        exact: true,
        icon: <AssessmentSharpIcon />,
        submenu: []
    }

];