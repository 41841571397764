import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Card, Container } from "react-bootstrap";
import Select from "react-select";
import Button from '@mui/material/Button';
import { campaignData, FinalvotingReport,getGenderReport ,getAgeReport,getCountyReport,getOccupationReport,getEducationReport} from '../ProductServiceAPI'
import Swal from "sweetalert2";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Loader from '../../../common/Loader'
import { useReactToPrint } from 'react-to-print'
import axios from "../../../ServerCalls/AxiosInstance";
import Chart from 'react-apexcharts';



export default function VotingReports() {

    const [data, setData] = useState([]);
    const [campaignOptions, setCampaignOptions] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState('')
    const [Count, setCount] = useState([]);
    const [AgeCount, setAgeCount] = useState([]);
    const [AgeCount1, setAgeCount1] = useState([]);
    const [AgeCount2, setAgeCount2] = useState([]);
    const [AgeCount3, setAgeCount3] = useState([]);
    const [AgeCount4, setAgeCount4] = useState([]);
    const [AgeCount5, setAgeCount5] = useState([]);
    const [AgeCount6, setAgeCount6] = useState([]);
    const [AgeCount7, setAgeCount7] = useState([]);
    const [AgeCount8, setAgeCount8] = useState([]);
    const [GenderCount, setGenderCount] = useState([]);
    const [GenderTotalCount, setGenderTotalCount] = useState([]);
    const [GenderFemaleCount, setGenderFemaleCount] = useState([]);
    const [Total, setTotal] = useState([]);
    const [AgeTotal, setAgeTotal] = useState([]);
    const [Countydata, setCountydata] = useState([]);
    const [CountyProduct, setCountyProduct] = useState([]);
    const [County1, setCounty1] = useState([]);
    const [CountyTotal, setCountyTotal] = useState([]);
    const [OverallCountyCount, setOverallCountyCount] = useState([]);
    const [Occupationdata, setOccupationdata] = useState([]);
    const [DoctotalCount, setDoctotalCount] = useState([]);
    const [FarmertotalCount, setFarmertotalCount] = useState([]);
    const [ITtotalCount, setITtotalCount] = useState([]);
    const [TeachertotalCount, setTeachertotalCount] = useState([]);
    const [PolicetotalCount, setPolicetotalCount] = useState([]);
    const [MechanictotalCount, setMechanictotalCount] = useState([]);
    const [TradertotalCount, setTradertotalCount] = useState([]);
    const [OthertotalCount, setOthertotalCount] = useState([]);
    const [OccupationtotalCount, setOccupationtotalCount] = useState([]);
    const [Educationdata, setEducationdata] = useState([]);
    const [CollegeTotal, setCollegeTotal] = useState([]);
    const [GraduationTotal, setGraduationTotal] = useState([]);
    const [PrimaryCount, setPrimaryCount] = useState([]);
    const [SecondaryCount, setSecondaryCount] = useState([]);
    const [UniversityCount, setUniversityCount] = useState([]);
    const [EducationTotal, setEducationTotal] = useState([]);



    const [pieproductname, setpieproductname] = useState([]);
    const [pieprocount, setpieprocount] = useState([]);

    const [piegender, setpiegender] = useState([]);
    const [piegenderCount, setpiegenderCount] = useState([]);
    const [PieAgeName,setPieAgeName] = useState([])
    const [PieAgeCount, setPieAgeCount] = useState([])

    const [PieChartCounty, setPieChartCounty] = useState([])
    const [PieChartCountyCount, setPieChartCountyCount] = useState([]);

    const [PieOccupationName,setPieOccupationName] = useState([]);
    const [PieOccupationCount,setPieOccupationCount] = useState([]);

    const [PieEducationName,setPieEducationName] = useState([]);
    const [PieEducationCount,setPieEducationCount] = useState([]);


    const [Age, setAge] = useState([]);

    const useStyles = makeStyles({

        table: {


            "& .MuiTableCell-root": {

                border: "1px solid rgba(0, 0, 0, 0.3)"

            }

        }

    });

    const classes = useStyles();


    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })



    const handleCampaign = (selected) => {
        setSelectedCampaign(selected)
    }


    const clear = () => {

        setSelectedCampaign('')

    }



    useEffect(() => {

        campaignList();
        reports();
        genderReport();
        ageReport();
        CountyReport();
        occupationReport();
        educationReport();

    }, [selectedCampaign])


    const campaignList = () => {
        campaignData().then((res) => {

            let arr = [];
            let list = res.data._response.data.getpollingcampaign;

            list.map((a) => (
                arr.push({
                    label: a.pollingCampaignShortName,
                    value: a.campaignId,
                })
            ))
            setCampaignOptions(arr)
        }).catch((err)=>{
            console.log(err,"err in get campaignlist");
        })

    }

    const reports = () => {
        // axios.get(`https://localhost:5002/api/ProductManagement/ProductChart?campaignid=${selectedCampaign.value}`)
        FinalvotingReport(selectedCampaign.value)
            .then((res) => {

                console.log(res, "got prouct product report");


                if (res.data._response.status === 1) {
                    let count = res.data._response.data.summarytotalcount
                    let age = res.data._response.data.individualproductvoteage
                    let agetotal = res.data._response.data.agewisetotalofallproducts.age21to25
                    let agetotal1 = res.data._response.data.agewisetotalofallproducts.age26to30
                    let agetotal2 = res.data._response.data.agewisetotalofallproducts.age31to35
                    let agetotal3 = res.data._response.data.agewisetotalofallproducts.age36to40
                    let agetotal4 = res.data._response.data.agewisetotalofallproducts.age41to45
                    let agetotal5 = res.data._response.data.agewisetotalofallproducts.age46to50
                    let agetotal6 = res.data._response.data.agewisetotalofallproducts.age51to55
                    let agetotal7 = res.data._response.data.agewisetotalofallproducts.age56to60
                    let agetotal8 = res.data._response.data.agewisetotalofallproducts.ageabove60
                    let gender = res.data._response.data.individualproductidmailfemalecount
                    let totalMCount = res.data._response.data.genderwisecount[0] === undefined ? { gender: "Male", count: 0 } : res.data._response.data.genderwisecount[0]
                    let totalFCount = res.data._response.data.genderwisecount[1] === undefined ? { gender: "Female", count: 0 } : res.data._response.data.genderwisecount[1]
                    let total = res.data._response.data.totalcountofgender;
                    let agetotalVote = res.data._response.data.agegrouptotal;
                    let County = res.data._response.data.allcounty;
                    let countyProducts = res.data._response.data.county;
                    let CountyVote1 = res.data._response.data.individualproductcounty;
                    let TotalProductCounty = res.data._response.data.countywisetotalofallproducts;
                    let OverAllCount = res.data._response.data.countytotal;
                    let pieProduct = res.data._response.data.productsummary;
                    let Occupation = res.data._response.data.individualoccupation;
                    let Doctotal = res.data._response.data.occupationwisetotalofallproducts.Doctor;
                    let Farmertotal = res.data._response.data.occupationwisetotalofallproducts.Farmer;
                    let Ittotal = res.data._response.data.occupationwisetotalofallproducts.IT;
                    let Teachertotal = res.data._response.data.occupationwisetotalofallproducts.Techer;
                    let Policetotal = res.data._response.data.occupationwisetotalofallproducts.Police;
                    let Mechanictotal = res.data._response.data.occupationwisetotalofallproducts.Mechanic;
                    let Tradertotal = res.data._response.data.occupationwisetotalofallproducts.Trader;
                    let Otherstotal = res.data._response.data.occupationwisetotalofallproducts.Others;
                    let OccupationTotals = res.data._response.data.occupationtotal;
                    let Educationdatas = res.data._response.data.individualeducationlevel;
                    let CollegeTotalCount = res.data._response.data.educationlevelwisetotalofallproducts.College;
                    let PostgraduationTotal = res.data._response.data.educationlevelwisetotalofallproducts.PostGraduation;
                    let PrimaryTotal = res.data._response.data.educationlevelwisetotalofallproducts.Primary;
                    let SecondaryTotal = res.data._response.data.educationlevelwisetotalofallproducts.Secondary;
                    let UniversityTotal = res.data._response.data.educationlevelwisetotalofallproducts.University;
                    let EducationTotals = res.data._response.data.educationleveltotal;


                    let proname = [];
                    let procount = [];
                    pieProduct.map((a) => {
                        proname.push(a.productname);
                        procount.push(a.count)
                    });

                    setpieproductname(proname);
                    setpieprocount(procount)

                    setTotal(total);
                    setAgeTotal(agetotalVote);
                    setGenderTotalCount(totalMCount);
                    setGenderFemaleCount(totalFCount);
                    setAgeCount(agetotal);
                    setAgeCount1(agetotal1);
                    setAgeCount2(agetotal2);
                    setAgeCount3(agetotal3);
                    setAgeCount4(agetotal4);
                    setAgeCount5(agetotal5);
                    setAgeCount6(agetotal6);
                    setAgeCount7(agetotal7);
                    setAgeCount8(agetotal8);
                    setGenderCount(gender);
                    setCountydata(County);
                    setCountyProduct(countyProducts);
                    setCounty1(CountyVote1);
                    setCountyTotal(TotalProductCounty);
                    setOverallCountyCount(OverAllCount);
                    setOccupationdata(Occupation);
                    setDoctotalCount(Doctotal);
                    setFarmertotalCount(Farmertotal);
                    setITtotalCount(Ittotal);
                    setTeachertotalCount(Teachertotal);
                    setPolicetotalCount(Policetotal);
                    setMechanictotalCount(Mechanictotal);
                    setTradertotalCount(Tradertotal);
                    setOthertotalCount(Otherstotal);
                    setOccupationtotalCount(OccupationTotals);
                    setEducationdata(Educationdatas);
                    setCollegeTotal(CollegeTotalCount);
                    setGraduationTotal(PostgraduationTotal);
                    setPrimaryCount(PrimaryTotal);
                    setSecondaryCount(SecondaryTotal);
                    setUniversityCount(UniversityTotal);
                    setEducationTotal(EducationTotals);


                    setAge(age)
                    setCount(count)
                    setData(res.data._response.data.productsummary)
                } else if (res.data._response.status === 2) {
                    Swal.fire({
                        icon: "error",
                        title: "No Votes for this Campaign",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });
                    setData([])
                    setTotal([]);
                    setAgeTotal([]);
                    setGenderTotalCount([]);
                    setGenderFemaleCount([]);
                    setAgeCount([]);
                    setAgeCount1([]);
                    setAgeCount2([]);
                    setAgeCount3([]);
                    setAgeCount4([]);
                    setGenderCount([]);
                    setCountydata([]);
                    setCountyProduct([]);
                    setCounty1([]);
                    setCountyTotal([]);
                    setOverallCountyCount([]);
                    setAge([]);
                    setCount([]);

                }
            }).catch((err)=>{
                console.log(err,"err in get report");
            })
    }


    const genderReport = () => {
        // axios.get(`https://localhost:5002/api/ProductManagement/ReportforGender?campaignid=${selectedCampaign.value}`)
        getGenderReport(selectedCampaign.value)
            .then((res) => {
                console.log(res, "got gender wise response")
                const gender = res.data._response.data.genderwisecount

                let gendername = [];
                let gendercount = [];

                gender.map((a) => {
                    gendername.push(a.gender)
                    gendercount.push(a.count)
                })

                setpiegender(gendername)
                setpiegenderCount(gendercount)

            }).catch((err) => {
                console.log(err)

            })
    }


    const ageReport = () => {
        // axios.get(`https://localhost:5002/api/ProductManagement/ReportforAge?campaignid=${selectedCampaign.value}`)
        getAgeReport(selectedCampaign.value)
            .then((res) => {
                console.log(res,"got age response")
                const agedata = res.data._response.data.allagescount

                let agepiecount = [];
                let agename= []

                agedata.map((a) => {
                    agepiecount.push(a.count)
                })

                agedata.map((a)=>{
                    agename.push(a.age)

                })

                setPieAgeName(agename)
                setPieAgeCount(agepiecount)


            }).catch((err) => {
                console.log(err)
            })
    }


    const CountyReport = () => {

        // axios.get(`https://localhost:5002/api/ProductManagement/ReportforCounty?campaignid=${selectedCampaign.value}`)
         getCountyReport(selectedCampaign.value)
            .then((res) => {

                const counties = res.data._response.data.allcounty
                const countyCount = res.data._response.data.counties


                let piecounty = []
                let piecountyCount = []

                counties.map((a) => {
                    piecounty.push(a.countyname)
                })

                countyCount.map((a) => {
                    piecountyCount.push(a.count)
                })

                setPieChartCounty(piecounty)
                setPieChartCountyCount(piecountyCount)

            }).catch((err) => {
                console.log(err)
            })

    }

         const occupationReport = ()=>{

            //  axios.get(`https://localhost:5002/api/ProductManagement/ReportforOccupation?campaignid=${selectedCampaign.value}`)
             getOccupationReport(selectedCampaign.value)
             .then((res)=>{

                console.log(res,"got occupationReport")
                const occupationdata = res.data._response.data.alloccupationcount

                let occupationName = [];
                let occupationCount = [];

                occupationdata.map((a)=>{
                occupationName.push(a.occupation)
            })

                occupationdata.map((a)=>{
                    occupationCount.push(a.count)

                })

                setPieOccupationName(occupationName)
                setPieOccupationCount(occupationCount)


             }).catch((err)=>{
                 console.log(err)
             })

    }


    const educationReport = ()=>{

        // axios.get(`https://localhost:5002/api/ProductManagement/ReportforEducationlevel?campaignid=${selectedCampaign.value}`)
        getEducationReport(selectedCampaign.value)
        .then((res)=>{

            const Educationdata = res.data._response.data.alleducationcount

            let educationName = [];
            let educationCount = [];

            Educationdata.map((a)=>{
                educationName.push(a.educationlevel)
        })

            Educationdata.map((a)=>{
                educationCount.push(a.count)

            })

            setPieEducationName(educationName)
            setPieEducationCount(educationCount)


           console.log(res,"got Education Report")

        }).catch((err)=>{
            console.log(err)
        })


    }

    return (

        <div>
            <div>
                <div className="content-wrapper consti-section" id="divToPrint">
                    <div className="content-header">
                        <div className="container-fluid">
                            <br /><br />
                            <div className="row mb-4">
                                <div className="col-sm-12">
                                    <h1 className="m-0 text-center">Voting List</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container">
                            <div className="row">
                                <Col md={12}>
                                    <Card style={{ padding: "20px" }}>
                                        <Form.Group
                                            as={Row}
                                            className="mb-1 justify-content-center"
                                            controlId="formHorizontalEmail"
                                        >

                                            <Col sm={12} className="mb-4">
                                                <Form.Label className="w-100">Campaign</Form.Label>
                                                <Select
                                                    className="w-100"
                                                    options={campaignOptions}
                                                    value={selectedCampaign}
                                                    onChange={handleCampaign}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>

                                        <div className="button_style m-0 text-center">

                                            <Button
                                                type="button"
                                                variant='contained'
                                                color='error'
                                                onClick={clear}
                                            >
                                                Cancel
                                            </Button>
                                        </div>

                                    </Card>
                                </Col>

                            </div>

                        </div>

                    </section>

                </div>
                <br></br>
            </div>
            <br /><br />







            {selectedCampaign === '' ? (

                <Loader />


            ) :
                <>
                    <div ref={componentRef}>
                        <h1 className="m-0 text-center">Voting  Report Summary</h1>
                        <h4 className="m-0 text-center">{selectedCampaign.label}</h4>
                        <br></br>

                        <Container>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report">
                                <TableContainer >
                                    <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                        <TableHead >

                                            <TableRow className="backgroundrow">
                                                <TableCell width="10%" className="head1">Product Name</TableCell>
                                                <>
                                                    {data.map((list, id) => (
                                                        <TableCell align="center" width="10%" rowSpan={2} className="head1">{list.productname}</TableCell>

                                                    ))}
                                                </>

                                                <TableCell align="center" width="10%" rowSpan={2} className="head1">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>

                                                <TableCell className="backgroundrow2" >Total Vote</TableCell>

                                                {data.map((list, id) => (

                                                    <TableCell align="center">{list.count}</TableCell>
                                                ))}


                                                <TableCell className="headerbgcolor" align="center">{Count}</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>


                                    <Chart

                                        type="pie"
                                        width={700}
                                        height={500}
                                        series={pieprocount}

                                        options={{
                                            title: { text: "Voting Report" },
                                            noData: { text: "Empty Data" },
                                            labels: pieproductname,
                                            responsive: [{
                                                breakpoint: 1024,
                                                options: {
                                                  chart: {
                                                    width: 700
                                                  },
                                                  legend: {
                                                    position: 'right'
                                                  }
  
                                                },
                                                breakpoint: 480,
                                                options: {
                                                  chart: {
                                                    width: 250
                                                  },
                                                  legend: {
                                                    position: 'bottom'
                                                  },
                                                  
                                                },
                                                breakpoint: 768,
                                                options: {
                                                  chart: {
                                                    width: 400
                                                  },
                                                  legend: {
                                                    position: 'bottom'
                                                  },
                                                  
                                                }
  
                                              }]
                                        }}

                                    />




                                    <div className="mt-5">
                                        <h2>Gender Wise</h2>


                                        <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                            <TableHead >

                                                <TableRow className="backgroundrow">
                                                    <TableCell></TableCell>
                                                    <>

                                                        {GenderCount.map((g, i) => (
                                                            <TableCell align="center" width="10%" rowSpan={2} className="head1">{g.productname}</TableCell>

                                                        ))}
                                                    </>
                                                    <TableCell align="center" width="10%" rowSpan={2} className="head1">Total</TableCell>
                                                </TableRow>


                                            </TableHead>
                                            <TableBody>
                                                <TableRow>

                                                    <TableCell width="12%" className="backgroundrow2"  >Male</TableCell>

                                                    <>
                                                        {GenderCount.map((m, i) => (
                                                            <TableCell align="center" className="head1">{m.maleCount}</TableCell>

                                                        ))}</>

                                                    <>

                                                        <TableCell className="headerbgcolor" align="center">{GenderTotalCount.count}</TableCell>
                                                    </>
                                                </TableRow>
                                                <TableRow>

                                                    <TableCell width="12%" className="backgroundrow2"  >Female</TableCell>

                                                    <>
                                                        {GenderCount.map((f, i) => (
                                                            <TableCell align="center" className="head1">{f.feMaleCount}</TableCell>

                                                        ))}</>


                                                    <TableCell align="center" className="headerbgcolor" >{GenderFemaleCount.count}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell align="left" className="backgroundrow2" >Total Votes</TableCell>

                                                    {GenderCount.map((all, i) => (
                                                        <TableCell align="center">{all.overallcount}</TableCell>
                                                    ))}

                                                    <TableCell align="center">{Total}</TableCell>



                                                </TableRow>

                                            </TableBody>

                                        </Table>



                                        <Chart

                                            type="pie"
                                            width={700}
                                            height={250}
                                            series={piegenderCount}

                                            options={{
                                                title: { text: "Voting Report" },
                                                noData: { text: "Empty Data" },
                                                labels: piegender
                                            }}

                                        />
                                    </div>


                                    <div className="mt-5">
                                        <h2>Age Wise</h2>


                                        <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                            <TableHead >

                                                <TableRow className="backgroundrow">
                                                    <TableCell></TableCell>
                                                    <>
                                                        {data.map((age, id) => (
                                                            <TableCell width="10%" align="center" rowSpan={2} className="head1">{age.productname}</TableCell>
                                                        ))}


                                                    </>

                                                    <TableCell align="center" width="10%" rowSpan={2} className="head1">Total</TableCell>
                                                </TableRow>


                                            </TableHead>
                                            <TableBody>
                                                <TableRow>

                                                    <TableCell width="10%" className="backgroundrow2"  >21-25 Years</TableCell>

                                                    {Age.map((a, e) => (
                                                        <TableCell align="center">{a.age21to25}</TableCell>
                                                    ))}

                                                    <TableCell className="headerbgcolor" align="center">{AgeCount}</TableCell>
                                                </TableRow>
                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >26-30 Years</TableCell>


                                                    {Age.map((a, e) => (
                                                        <TableCell align="center">{a.age26to30}</TableCell>
                                                    ))}

                                                    <TableCell className="headerbgcolor" align="center">{AgeCount1}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >31-35 Years</TableCell>


                                                    {Age.map((a, e) => (
                                                        <TableCell align="center">{a.age31to35}</TableCell>
                                                    ))}

                                                    <TableCell className="headerbgcolor" align="center">{AgeCount2}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >36-40 Years</TableCell>


                                                    {Age.map((a, e) => (
                                                        <TableCell align="center">{a.age36to40}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{AgeCount3}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >41-45 Years </TableCell>


                                                    {Age.map((a, e) => (
                                                        <TableCell align="center">{a.age41to45}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{AgeCount4}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >46-50 Years </TableCell>


                                                    {Age.map((a, e) => (
                                                        <TableCell align="center">{a.age46to50}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{AgeCount5}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >51-55 Years </TableCell>


                                                    {Age.map((a, e) => (
                                                        <TableCell align="center">{a.age51to55}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{AgeCount6}</TableCell>
                                                </TableRow>
                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >56-60 Years</TableCell>


                                                    {Age.map((a, e) => (
                                                        <TableCell align="center">{a.age56to60}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{AgeCount7}</TableCell>
                                                </TableRow>
                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >above 60 Years </TableCell>


                                                    {Age.map((a, e) => (
                                                        <TableCell align="center">{a.above60}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{AgeCount8}</TableCell>
                                                </TableRow>
                                                <TableRow>

                                                    <TableCell align="left" className="backgroundrow2"  >Total Votes</TableCell>

                                                    {Age.map((total, e) => (
                                                        <TableCell align="center">{total.overallcount}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{AgeTotal}</TableCell>



                                                </TableRow>

                                            </TableBody>

                                        </Table>


                                        <Chart

                                            type="pie"
                                            width={700}
                                            height={250}
                                            series={PieAgeCount}

                                            options={{
                                                title: { text: "Voting Report" },
                                                noData: { text: "Empty Data" },
                                                colors: ["#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#00FFFF", "#FF00FF", "#000080", "#808080", "#800080"],
                                                labels: PieAgeName
                                            }}

                                        />



                                    </div>

                                    <div className="mt-5">
                                        <h2>County Wise</h2>


                                        <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                            <TableHead >

                                                <TableRow className="backgroundrow">
                                                    <TableCell></TableCell>
                                                    <>    {County1.map((p, i) => (
                                                        <TableCell align="center" width="12%" rowSpan={2} className="head1">{p.productname}</TableCell>
                                                    ))}


                                                    </>
                                                    <TableCell align="center" rowSpan={3} className="head1">Total</TableCell>


                                                </TableRow>


                                            </TableHead>
                                            <TableBody>
                                                {Countydata.map((c, i) => (

                                                    <TableRow>

                                                        <TableCell width="12%" className="backgroundrow2"  >{c.countyname}</TableCell>

                                                        {c.countyname === "Mob" ?
                                                            <>
                                                                {County1.map((s, i) => (
                                                                    <>
                                                                        <TableCell align="center" >{s.county1}</TableCell>
                                                                    </>
                                                                ))}
                                                            </>
                                                            : c.countyname === "Kwale" ?
                                                                <>
                                                                    {County1.map((s, i) => (

                                                                        <TableCell align="center" >{s.county2}</TableCell>

                                                                    ))}
                                                                </>
                                                                : c.countyname === "Kilifi" ?
                                                                    <>
                                                                        {County1.map((s, i) => (

                                                                            <TableCell align="center" >{s.county3}</TableCell>

                                                                        ))}
                                                                    </>
                                                                    : c.countyname === "Tana River" ?
                                                                        <>
                                                                            {County1.map((s, i) => (

                                                                                <TableCell align="center" >{s.county4}</TableCell>

                                                                            ))}
                                                                        </>
                                                                        : null}



                                                        {c.countyname === "Mob" ?
                                                            <TableCell align="center" width="12%" >{CountyTotal.county1}</TableCell>
                                                            : c.countyname === "Kwale" ?
                                                                <TableCell width="12%" align="center">{CountyTotal.county2}</TableCell>
                                                                : c.countyname === "Kilifi" ?
                                                                    <TableCell width="12%" align="center" >{CountyTotal.county3}</TableCell>
                                                                    : c.countyname === "Tana River" ?
                                                                        <TableCell width="12%" align="center">{CountyTotal.county4}</TableCell>
                                                                        : null
                                                        }

                                                    </TableRow>
                                                ))}

                                                <TableRow>
                                                    <TableCell width="12%" className="backgroundrow2"  >Total Votes</TableCell>
                                                    {County1.map((s, i) => (
                                                        <TableCell align="center" >{s.overallcount}</TableCell>
                                                    ))}
                                                    <TableCell width="12%" align="center">{OverallCountyCount}</TableCell>


                                                </TableRow>



                                            </TableBody>

                                        </Table>



                                        <Chart

                                            type="pie"
                                            width={700}
                                            height={250}
                                            series={PieChartCountyCount}

                                            options={{
                                                title: { text: "Voting Report" },
                                                noData: { text: "Empty Data" },
                                                // colors: ["#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#00FFFF", "#FF00FF", "#000080", "#808080", "#800080"],
                                                labels: PieChartCounty
                                            }}

                                        />



                                    </div>



                                    <div className="mt-5">
                                        <h2>Occupation Wise</h2>


                                        <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                            <TableHead >

                                                <TableRow className="backgroundrow">
                                                    <TableCell></TableCell>
                                                    <>
                                                        {Occupationdata.map((product, id) => (
                                                            <TableCell width="10%" align="center" rowSpan={2} className="head1">{product.productname}</TableCell>
                                                        ))}


                                                    </>

                                                    <TableCell align="center" width="10%" rowSpan={2} className="head1">Total</TableCell>
                                                </TableRow>


                                            </TableHead>
                                            <TableBody>
                                                <TableRow>

                                                    <TableCell width="10%" className="backgroundrow2"  >Doctor</TableCell>

                                                    {Occupationdata.map((a, e) => (
                                                        <TableCell align="center">{a.doctor}</TableCell>
                                                    ))}

                                                    <TableCell className="headerbgcolor" align="center">{DoctotalCount}</TableCell>
                                                </TableRow>
                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >Police</TableCell>


                                                    {Occupationdata.map((a, e) => (
                                                        <TableCell align="center">{a.police}</TableCell>
                                                    ))}

                                                    <TableCell className="headerbgcolor" align="center">{PolicetotalCount}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >Teacher</TableCell>


                                                    {Occupationdata.map((a, e) => (
                                                        <TableCell align="center">{a.teacher}</TableCell>
                                                    ))}

                                                    <TableCell className="headerbgcolor" align="center">{TeachertotalCount}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >Farmer</TableCell>


                                                    {Occupationdata.map((a, e) => (
                                                        <TableCell align="center">{a.farmer}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{FarmertotalCount}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >Mechanic </TableCell>


                                                    {Occupationdata.map((a, e) => (
                                                        <TableCell align="center">{a.mechanic}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{MechanictotalCount}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >IT </TableCell>


                                                    {Occupationdata.map((a, e) => (
                                                        <TableCell align="center">{a.it}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{ITtotalCount}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >Trader </TableCell>


                                                    {Occupationdata.map((a, e) => (
                                                        <TableCell align="center">{a.trader}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{TradertotalCount}</TableCell>
                                                </TableRow>
                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >Others</TableCell>


                                                    {Occupationdata.map((a, e) => (
                                                        <TableCell align="center">{a.others}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{OthertotalCount}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell align="left" className="backgroundrow2"  >Total Votes</TableCell>

                                                    {Occupationdata.map((total, e) => (
                                                        <TableCell align="center">{total.overallcount}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{OccupationtotalCount}</TableCell>



                                                </TableRow>

                                            </TableBody>

                                        </Table>

                                        <Chart

                                            type="pie"
                                            width={700}
                                            height={250}
                                            series={PieOccupationCount}

                                            options={{
                                                title: { text: "Voting Report" },
                                                noData: { text: "Empty Data" },
                                                colors: ["#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#00FFFF", "#FF00FF", "#000080", "#808080", "#800080"],
                                                labels: PieOccupationName
                                            }}

                                        />
                                    </div>



                                    <div className="mt-5">
                                        <h2>Education Wise</h2>


                                        <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                            <TableHead >

                                                <TableRow className="backgroundrow">
                                                    <TableCell></TableCell>
                                                    <>
                                                        {Educationdata.map((education, id) => (
                                                            <TableCell width="10%" align="center" rowSpan={2} className="head1">{education.productname}</TableCell>
                                                        ))}


                                                    </>

                                                    <TableCell align="center" width="10%" rowSpan={2} className="head1">Total</TableCell>
                                                </TableRow>


                                            </TableHead>
                                            <TableBody>
                                                <TableRow>

                                                    <TableCell width="10%" className="backgroundrow2"  >Primary</TableCell>

                                                    {Educationdata.map((a, e) => (
                                                        <TableCell align="center">{a.primary}</TableCell>
                                                    ))}

                                                    <TableCell className="headerbgcolor" align="center">{PrimaryCount}</TableCell>
                                                </TableRow>
                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >Secondary</TableCell>


                                                    {Educationdata.map((a, e) => (
                                                        <TableCell align="center">{a.secondary}</TableCell>
                                                    ))}

                                                    <TableCell className="headerbgcolor" align="center">{SecondaryCount}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >College</TableCell>


                                                    {Educationdata.map((a, e) => (
                                                        <TableCell align="center">{a.college}</TableCell>
                                                    ))}

                                                    <TableCell className="headerbgcolor" align="center">{CollegeTotal}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >University</TableCell>


                                                    {Educationdata.map((a, e) => (
                                                        <TableCell align="center">{a.university}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{UniversityCount}</TableCell>
                                                </TableRow>

                                                <TableRow>

                                                    <TableCell className="backgroundrow2"  >Post Graduate </TableCell>


                                                    {Educationdata.map((b, e) => (
                                                        <TableCell align="center">{b.postGraduation}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{GraduationTotal}</TableCell>
                                                </TableRow>


                                                <TableRow>

                                                    <TableCell align="left" className="backgroundrow2"  >Total Votes</TableCell>

                                                    {Educationdata.map((total, e) => (
                                                        <TableCell align="center">{total.overallcount}</TableCell>
                                                    ))}
                                                    <TableCell className="headerbgcolor" align="center">{EducationTotal}</TableCell>



                                                </TableRow>

                                            </TableBody>

                                        </Table>

                                        <Chart

                                            type="pie"
                                            width={700}
                                            height={250}
                                            series={PieEducationCount}

                                            options={{
                                                title: { text: "Voting Report" },
                                                noData: { text: "Empty Data" },
                                                colors: ["#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#00FFFF"],
                                                labels: PieEducationName
                                            }}

                                        />
                                    </div>


                                </TableContainer>
                            </Paper>
                        </Container>
                    </div>
                    <br></br>
                    <div className="text-center m-0">
                        {/* <Button variant="contained" onClick={downloadReceipt}>Download</Button> */}
                        &nbsp;&nbsp;
                        <Button variant="contained" onClick={handlePrint}>Print</Button>
                    </div>

                </>

            }





        </div>
    )
}
