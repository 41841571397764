// import axios from "axios";
import axios from '../../ServerCalls/AxiosInstance'

import ProductURL from '../../ServerCalls/ProductServer'

//constant data
const occupationlist = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetOccupation`
    })
}
const NoOccupationlist = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetOccupationWithNoOccupation`
    })
}
const Educationlist = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetEducationLevel`
    })
}
const NoEducationlist = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetEducationLevelWithNoEducation`
    })
}
const Agelist = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetAges`
    })
}
const NoAgelist = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetAgesWithNoAge`
    })
}
//parameters
const ParamtersList = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetParameter`
    })
}
const AddParamtere = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/ParameterData`,
        data: data

    })
}
const getdatawithID = async (id) => {
    return axios({

        method: "GET",
        url: `${ProductURL.ProductUrl}/ParameterGetById/${id}`,

    })
}
const updateParameter = async (data) => {
    return axios({

        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditParameter`,
        data: data
    })
}
const trashParamter = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveParameter/${id}`

    })
}

//ProductParameters calls

const Allparameters = (Productid, categoryid) => {
    return axios({

        method: "GET",
        url: `${ProductURL.ProductNOTIUrl}/GetparametersByProductId?Productid=${Productid}&categoryid=${categoryid}`

    });

}

const ProductParameterList = async () => {
    return axios({

        method: "GET",
        url: `${ProductURL.ProductUrl}/GetProductParameters`

    });
}
const trashProductparameters = async (id) => {

    return axios({

        method: 'DELETE',
        url: `${ProductURL.ProductUrl}/RemoveProductParameter/${id}`
    })
}

//ParameterValueType Calls
const parameterValueTypeList = async () => {
    return axios({

        method: "GET",
        url: `${ProductURL.ProductUrl}/GetParameterValueType`

    })
}
const paramterValue = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/ParameterValueTypeData`,
        data: data
    })
}
const OneparameterValue = async (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ParameterValueTypeGetById/${id}`,
    })
}
const updateData = async (data) => {
    return axios({
        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditParameterValueType`,
        data: data
    })
}
const trashData = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveParameterValueType/${id}`,
    })
}

// Campaign Level Calls
const CampaignLevelList = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetCampaignLevel`
    })

}
const createCampaignLevel = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/CampaignLevelData`,
        data: data

    })
}
const getOneCampaignData = async (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/CampaignLevelGetById/${id}`,

    })
}
const updateLevelData = async (data) => {
    return axios({
        method: 'PUT',
        url: `${ProductURL.ProductUrl}/EditCampaignLevel`,
        data: data

    })
}
const trashLevel = async (id) => {
    return axios({
        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemovecampaignLevel/${id}`

    })
}

//productparameters calls
const Postparameter = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/ProductParametersData`,
        data: data
    })
}
const getProductparameters = async (id) => {

    return axios({

        method: 'GET',
        url: `${ProductURL.ProductUrl}/GetparametersByProductId/${id}`

    })
}

//brands
const Allbrands = async () => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetBrand`
    })
}
const addRegistration = async (data) => {

    return axios({

        method: "POST",
        url: `${ProductURL.AuthorizationURL}/AddUserData`,
        data: data
    })

}


const BrandgetbyId = (id) => {

    return axios({

        method: 'GET',
        url: `${ProductURL.ProductUrl}/BrandGetById/${id}`

    })

}

const Topbrandproduct = () => {

    return axios({

        method: "GET",
        url: `${ProductURL.ProductUrl}/TopProductGetByProductId`
    })

}


const BrandPhotos = async (data) => {

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/BrandData/SaveFile`,
        data: data
    })
}


const ProductPhotos = async (data) => {

    return axios({

        method: "POST",
        url: `${ProductURL.ProductUrl}/SaveFile`,
        data: data
    })
}

const CampaignPhotos = async (data) => {

    return axios({

        method: "POST",
        url: `${ProductURL.CampaignPhoto}/SaveFile`,
        // api/ProductManagement/Testing/SaveFile
        data: data
    })
}


const testingImage = async(data)=>{

    return axios({

        method: "POST",
        url: `${ProductURL.testURl}/SaveFile`,
        // api/ProductManagement/Testing/SaveFile
        data: data
    })
}

const MemberImage = async (data) => {

    return axios({

        method: "POST",
        url: `${ProductURL.userPhoto}/SaveFile`,
        data: data
    })
}

const NewsImages = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.productNewsPhoto}/SaveFile`,
        data: data

    })
}

const GeneralNewsImage = async (data) => {

    return axios({
        method: "POST",
        url: `${ProductURL.GeneralNewsPhoto}/SaveFile`,
        data: data

    })

}

const GetGeneralNewsWithPagination = (pgeno, pagsize) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetGeneralNewsWithPagination?PageNo=${pgeno}&PageSize=${pagsize}`
    })

}


const updateBrandDetails = async (data) => {

    return axios({
        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditBrand`,
        data: data
    })
}
const deleteData = async (id) => {

    return axios({
        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveBrand/${id}`
    })
}

//paramter_category Relation calls
const parameter_bycategory_List = async (id) => {

    return axios({

        method: 'GET',
        url: `${ProductURL.ProductUrl}/ParameterCategoryGetByCategoryId/${id}`
    })

}
const addParameters = async (data) => {

    return axios({
        method: 'POST',
        url: `${ProductURL.ProductUrl}/ParameterCategoryData`,
        data: data
    })
}

//productVoting calls 
const VotingDetails = () => {

    return axios({

        method: "GET",
        url: `${ProductURL.ProductUrl}/GetCitizenVoting`
    })
}
const add_Votingdata = (data) => {

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/CitizenVotingData`,
        data: data
    })
}

//productCampaign
const campaignData = () => {
    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/GetPollingCampaign`
    })
}

const campaignWithPagination = (pgeno, pagsize) => {

    return axios({

        method: 'GET',
        url: `${ProductURL.ProductUrl}/GetPollingCampaignWithPagination?PageNo=${pgeno}&PageSize=${pagsize}`
    })
}

const campaignwithvotingResult = (memeberid) => {
    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/GetPollingCampaign?memberid=${memeberid}`
    })
}

const campaignwithvotingResultWithPagination = (memeberid, pgeno, pagsize,search) => {
    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/GetPollingCampaignWithPaginationForUser?memberid=${memeberid}&PageNo=${pgeno}&PageSize=${pagsize}&message=${search}`
    })
}

const campaignNameData = (citizenid, campaignid) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductParticipationGetByCampaignIdVerified?citizenid=${citizenid}&campaignid=${campaignid}`,
    })
}

//Menudata Calls
const postMenudata = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/MenuData`,
        data: data
    })
}
const getMenudata = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetMenu`
    })
}
const editMenudata = async (data) => {
    return axios({

        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditMenu`,
        data: data
    })
}
const deleteMenudata = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveMenu/${id}`
    })
}

//Role Calls
const postRole = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/RoleData`,
        data: data
    })
}
const getRole = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetRole`
    })
}
const editRole = async (data) => {
    return axios({

        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditRole`,
        data: data
    })
}
const deleteRole = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveRole/${id}`
    })
}

//product APIURL
const Addproduct = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/ProductData`,
        data: data,

    })
}

const productsOfBrands = async (brandid, categoryid) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductGetByBrandIdCategoryId?brandid=${brandid}&categoryid=${categoryid}`
    })
}

const ProductbyCategories = async (id) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductGetByCategoryId/${id}`
    })
}

const productList = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetProduct`
    })
}

const ProductwithPage = async (pageNo, PageSize) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetProductWithPagination?PageNo=${pageNo}&PageSize=${PageSize}`
    })
}
const ProductListgetById = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductGetById/${id}`,

    })
}
const editProduct = async (data) => {
    return axios({

        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditProduct`,
        data: data
    })
}

const getproductByBrand = async (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductGetByBrandId/${id}`,

    })

}



const deleteProduct = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveProduct/${id}`
    })
}

//product category 
const postCategory = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/ProductCategoryData`,
        data: data

    })
}
const CategoryList = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetProductCategory`
    })
}

const Categorywithnoparent = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetProductCategoryWithoutNoParent`
    })
}

const categorywithID = async (id) => {
    return axios({

        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductCategoryGetById/${id}`
    })
}
const editCategory = async (data) => {
    return axios({
        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditProductCategory`,
        data: data
    })
}
const deleteCategory = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveProductCategory/${id}`,
    })
}

//parametrs by category
const parametersById = async (id) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/parameterDetailsByCategoryId/${id}`
    })
}

//productCampaign calls
const PostpollingCampaign = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/PollingCampaignSave`,
        data: data

    })
}
const Campaigngetbyid = async (id) => {
    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/PollingCampaignGetById/${id}`

    })
}

const CitizenPollingGetById = async (id) => {
    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/CitizenPollingGetByCitizenId/${id}`

    })

}


const EditpollingCampaign = async (data) => {
    return axios({

        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditPollingCampaign`,
        data: data
    })
}
const deletePollingCampaign = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemovePollingCampaign/${id}`
    })
}

//rolemenu call
const postRoleMenu = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/RoleMenuData`,
        data: data
    })
}
const updateRoleMenu = async (data) => {
    return axios({

        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditRoleMenu`,
        data: data
    })
}
const getRoleMenu = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetRoleMenu`
    })
}
const getRoleMenubyId = async (id) => {
    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/RoleMenuGetById/${id}`

    })
}
const deleteRoleMenu = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveRoleMenu/${id}`
    })
}

//productPolling Route
const AddCitizenPolling = (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/CitizenPollingData`,
        data: data
    })

}
const ProductParticipationGetbyId = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductParticipationGetByCampaignId/${id}`,

    })
}
const getcitizenpolling = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/CitizenPollingGetById/${id}`,

    })
}

//feedback

const addFeedback = (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/ProductRecognitionData`,
        data: data
    })
}

// const feedbackList = () => {
//     return axios({
//         method: "GET",
//         url: `${ProductURL.ProductUrl}/GetProductRecognition`
//     })

// }

const singleFeedback = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/BrandGetByBrandIdProductId/${id}`
    })

}

const productbyCategoryandBrand = (brandid, categoryid, pageNo) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductGetByCombination?brandid=${brandid}&categoryid=${categoryid}&PageNo=${pageNo}&PageSize=${8}`,
    })

}



const feedbackList = () => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetProductRecognition`
    })

}

const feedbackOfProduct = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductRecognitionGetByProductId/${id}`
    })
}

const feedbackOfProductwithPagination = (id, pageNo, PageSize) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductRecognitionGetByProductIdWithPagination?id=${id}&PageNo=${pageNo}&PageSize=${PageSize}`
    })
}

const createReply = (data) => {

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/BrandRecognitionCommData`,
        data: data
    })
}

const getreplies = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/BrandRecognitionCommGetById/${id}`
    })

}

// const allFeedbackOfBrands = (brandid, productid) => {

//     return axios({
//         method: "GET",
//         url: `${ProductURL.ProductUrl}/BrandGetByBrandIdProductId?brandid=${brandid}&productid=${productid}`,
//     })
// }

const allFeedbackOfBrands = (id, pageno) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/BrandsProductGetByUserIdWithPagination?${id}&PageNo=${pageno}&PageSize=${2}`

    })
}

const brandcategoryfeedback = (brandid, categoryid, PageNo) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/BrandsProductGetByCategoryIdWithpagination?brandid=${brandid}&categoryid=${categoryid}&PageNo=${PageNo}&PageSize=${2}`
    })
}

const userAllfeedback = (id, PageNo) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductRecognitionGetByAllFeedbackbyuserIdWithPagination?id=${id}&PageNo=${PageNo}&PageSize=${2}`
    })
}



const userFeedbacklist = (productid, id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductRecognitionGetBycitizenId?productid=${productid}&id=${id}`,
    })
}

const feedbackWithBrand_Category = (brandid, userid, categoryid) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/FeedbackGetByCombination?brandid=${brandid}&userid=${userid}&categoryid=${categoryid}`,
    })

}


const feedbackWithPagination = (brandid, userid, categoryid, PageNo) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/FeedbackGetByCombinationWithPagination?brandid=${brandid}&userid=${userid}&categoryid=${categoryid}&PageNo=${PageNo}&PageSize=${2}`,
    })

}

// const userFeedbacklist =(id)=>{
//     return axios({
//         method: "GET",
//         url: `${ProductURL.ProductUrl}/ProductRecognitionGetBycitizenId/${id}`
//     })
// }


//FavouriteData

const addFavourite = (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/ProductFavouritesData`,
        data: data
    })
}




const getFavouriteByCiticenId = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductFavouritesGetByCitizenId/${id}`,

    })
}




const getProFavBycitizenidProductId = (productid, citizenid) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductFavouritesGetByCitizenIdProductId?productid=${productid}&citizenid=${citizenid}`,
    })
}


const EditProductFavourite = (data) => {
    return axios({

        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditProductFavourites`,
        data: data
    })

}





const votingReport = (id) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/CitizenVotingGetByCampaignId/${id}`
    })

}


const votingCount = (proid, campaignid) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/CitizenVotingGetByProductIdCampaignId?productid=${proid}&campaignid=${campaignid}`
    })
}

const getcitizenvotingReport = (id) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/CitizenVotingGetByCampaignId/${id}`
    })

}


const pollingreport = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/CitizenPollingGetByCampaignId/${id}`
    })

}

const viewPollingdetails = (id) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/CitizenPollingGetByCitizenId/${id}`
    })
}


const FinalvotingReport = (campainid) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductChart?campaignid=${campainid}`
    })

}


const postNewsData = (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/ProductNewsData`,
        data: data
    })
}

const getNewsData = () => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetProductNews`
    })
}

const BrandNews = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductNewsGetByBrandId/${id}`
    })

}


const getandEditNews = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetAndEditNewsNotificationsById/${id}`
    })


}

const getNewsbyid = (id) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductNewsGetById/${id}`
    })

}

const EditNewsData = (data) => {
    return axios({

        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditProductNews`,
        data: data
    })

}

const DeleteNewsData = (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveProductNews/${id}`
    })

}



const GetNotification = () => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetNewsNotifications`
    })
}


const GetNotificationbyid = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductNOTIUrl}/GetNewsNotifications?memberid=${id}`
        // url: `${ProductURL.ProductUrl}/GetNewsNotifications?memberid=${id}`

    })

}


const notificationbyNewsid = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/NewsNotificationsGetById/${id}`
    })


}

const updateNewsNotifications = (data) => {


    return axios({

        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditNewsNotifications`,
        data: data
    })
}


const getProductByBandId = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductGetByBrandId/${id}`
    })
}


//regionServices


const NocountyList = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.RegionService}/GetCountyWithNoCounty`,
    })
}
const NoConstilist = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.RegionService}/GetConstituencyWithNoConsti`,
    })
}
const NoWardlist = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.RegionService}/GetWardWithNoWard`,
    })
}

const getCountyData = () => {
    return axios({
        method: "GET",
        url: `${ProductURL.RegionService}/GetCounty`
    })
}

const addCounty = async (data) => {
    return axios({
        method: "POST",
        url: `${ProductURL.RegionService}/CountyData`,
        data: data
    })
}

const editCounty = async (data) => {

    return axios({
        method: "PUT",
        url: `${ProductURL.RegionService}/EditCounty`,
        data: data
    })
}

const trashCounty = async (id) => {
    return axios({
        method: "DELETE",
        url: `${ProductURL.RegionService}/RemoveCounty/${id}`,
    })
}


const constituencyList = async () => {

    return axios({
        method: "GET",
        url: `${ProductURL.RegionService}/GetConstituency`,
    })
}

const addconstituency = async (data) => {

    return axios({
        method: "POST",
        url: `${ProductURL.RegionService}/ConstituencyData`,
        data: data
    })

}

const editConstituency = async (data) => {
    return axios({
        method: "PUT",
        url: `${ProductURL.RegionService}/EditConstituency`,
        data: data
    })
}

const trashConstituency = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.RegionService}/RemoveConstituency/${id}`

    })

}
const ConstituencygetbyCounty = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.RegionService}/ConstituencyGetByCountyId/${id}`
    })
}

const getWardbyConstituency = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.RegionService}/WardGetByConstituencyId/${id}`
    })

}

const wardList = async () => {
    return axios({
        method: "GET",
        url: `${ProductURL.RegionService}/GetWard`,
    })

}
const addWard = async (data) => {

    return axios({
        method: "POST",
        url: `${ProductURL.RegionService}/WardData`,
        data: data
    })

}

const editUser = (data) => {

    return axios({

        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditMember`,
        data: data
    })

}

const getmember = () => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/GetMember`,
    })



}

const editWard = async (data) => {

    return axios({
        method: "PUT",
        url: `${ProductURL.RegionService}/EditWard`,
        data: data
    })

}

const trashWard = async (id) => {
    return axios({

        method: "DELETE",
        url: `${ProductURL.RegionService}/RemoveWard/${id}`

    })

}


const getmemeberdetails = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductNOTIUrl}/MemberGetById/${id}`
    })

}

const getmemeberdetailsforGoogleLogin = (id) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/MemberGetByIdforlogin/${id}`
    })

}


const getReportSummary = (campaignid, categoryid) => {
    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductSummary?campaignid=${campaignid}&categoryid=${categoryid}`
    })

}


const getReportChart = (id) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductChart?campaignid=${id}`
    })

}

const getbyCountyReport = (campaignid) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/CountyReport?campaignid=${campaignid}`
    })

}

const getbyOccupationReport = (campaignid) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/OccupationReport?campaignid=${campaignid}`
    })

}


const piechartSummary = (campaignid, reportid) => {

    return axios({
        method: "GET",
        url: `${ProductURL.ProductUrl}/ProductPieChart?campaignid=${campaignid}&categoryid=${reportid}`
    })
}



const getGenderReport = (campaignid) => {

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/ReportforGender?campaignid=${campaignid}`,
    })

}

const getAgeReport = (campaignid) => {

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/ReportforAge?campaignid=${campaignid}`,
    })
}

const getCountyReport = (campaignid) => {

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/ReportforCounty?campaignid=${campaignid}`,
    })

}
const getOccupationReport = (campaignid) => {

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/ReportforOccupation?campaignid=${campaignid}`,
    })

}

const getEducationReport = (campaignid) => {

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/ReportforEducationlevel?campaignid=${campaignid}`,
    })
}

const getMagzinelist = () => {

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/GetMagazine`

    })
}

const addMagzineData = (data) => {

    return axios({
        method: 'POST',
        url: `${ProductURL.ProductUrl}/MagazineData`,
        data: data,
    })
}

const magzinetypes = () => {

    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/GetMagazineType`

    })
}

const pdfData = (data) => {

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/Magazine/SavePdfFile`,
        data: data
    })

}

const editMagzine = (data) => {

    return axios({

        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditMagazine`,
        data: data
    })

}


const trashMagzine = (id) => {

    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveMagazine/${id}`

    })

}

const AddGeneralNews = (data) => {

    return axios({
        method: "POST",
        url: `${ProductURL.ProductUrl}/GeneralNewsData`,
        data: data
    })

}

const getGeneralNews = () => {
    return axios({
        method: 'GET',
        url: `${ProductURL.ProductUrl}/GetGeneralNews`

    })
}

const editGeneralNews = (data) => {

    return axios({
        method: "PUT",
        url: `${ProductURL.ProductUrl}/EditGeneralNews`,
        data: data
    })


}

const deleteGeneralNews = (id) => {

    return axios({

        method: "DELETE",
        url: `${ProductURL.ProductUrl}/RemoveGeneralNews/${id}`

    })

}
// const getTopGeneralNews = ()=>{

//     return axios({
//         method: 'GET',
//         url: `${ProductURL.ProductUrl}/GetGeneralNews`

//     })

// }



export {
    ProductParameterList,
    getmemeberdetails,
    parameterValueTypeList,
    paramterValue,
    OneparameterValue,
    Allparameters,
    updateData,
    trashData,

    ParamtersList,
    AddParamtere,
    getdatawithID,
    updateParameter,
    trashParamter,

    CampaignLevelList,
    createCampaignLevel,
    getOneCampaignData,
    updateLevelData,
    trashLevel,

    parametersById,
    Postparameter,
    getProductparameters,

    Allbrands,
    addRegistration,
    BrandPhotos,
    BrandgetbyId,
    Topbrandproduct,
    updateBrandDetails,
    deleteData,

    VotingDetails,
    add_Votingdata,

    campaignData,
    campaignNameData,
    campaignwithvotingResult,
    campaignwithvotingResultWithPagination,
    campaignWithPagination,
    addParameters,
    trashProductparameters,
    parameter_bycategory_List,

    postMenudata,
    getMenudata,
    editMenudata,
    deleteMenudata,

    postRole,
    getRole,
    editRole,
    deleteRole,

    ProductbyCategories,
    Addproduct,
    productList,
    ProductwithPage,
    ProductPhotos,
    ProductListgetById,
    editProduct,
    getproductByBrand,
    deleteProduct,

    postCategory,
    CategoryList,
    Categorywithnoparent,
    categorywithID,
    editCategory,
    deleteCategory,

    PostpollingCampaign,
    Campaigngetbyid,
    EditpollingCampaign,
    CitizenPollingGetById,
    deletePollingCampaign,

    updateRoleMenu,
    postRoleMenu,
    getRoleMenu,
    getRoleMenubyId,
    deleteRoleMenu,

    AddCitizenPolling,
    ProductParticipationGetbyId,
    getcitizenpolling,

    addCounty,
    editCounty,
    trashCounty,

    constituencyList,
    addconstituency,
    editConstituency,
    trashConstituency,

    wardList,
    addWard,
    editWard,
    trashWard,

    addFeedback,
    feedbackOfProduct,
    feedbackOfProductwithPagination,
    feedbackList,
    createReply,
    getreplies,
    allFeedbackOfBrands,
    userAllfeedback,
    feedbackWithBrand_Category,
    feedbackWithPagination,
    brandcategoryfeedback,

    addFavourite,
    getFavouriteByCiticenId,
    EditProductFavourite,
    getProFavBycitizenidProductId,
    userFeedbacklist,

    votingReport,
    votingCount,
    getcitizenvotingReport,
    pollingreport,
    viewPollingdetails,

    postNewsData,
    getNewsData,
    getNewsbyid,
    EditNewsData,
    DeleteNewsData,
    BrandNews,
    getandEditNews,

    GetNotification,
    GetNotificationbyid,
    notificationbyNewsid,
    updateNewsNotifications,


    getProductByBandId,
    productsOfBrands,
    singleFeedback,
    productbyCategoryandBrand,

    getCountyData,
    ConstituencygetbyCounty,
    getWardbyConstituency,

    editUser,
    getmember,

    CampaignPhotos,
    MemberImage,
    NewsImages,

    getReportSummary,
    getReportChart,
    getbyCountyReport,
    getbyOccupationReport,
    piechartSummary,
    FinalvotingReport,


    Agelist,
    occupationlist,
    Educationlist,

    getGenderReport,
    getAgeReport,
    getCountyReport,
    getOccupationReport,
    getEducationReport,

    getMagzinelist,
    addMagzineData,
    magzinetypes,
    pdfData,
    editMagzine,
    trashMagzine,

    AddGeneralNews,
    getGeneralNews,
    editGeneralNews,
    deleteGeneralNews,
    GeneralNewsImage,
    GetGeneralNewsWithPagination,


    getmemeberdetailsforGoogleLogin,
    testingImage,

    NocountyList,
    NoConstilist,
    NoWardlist,
    NoOccupationlist,
    NoEducationlist,
    NoAgelist

}