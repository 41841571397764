import { useState, useEffect } from "react";
import { Col, Row, Card, Form } from "react-bootstrap";
import MaterialTable from "material-table";
import Swal from "sweetalert2";
import { postRole, getRole, editRole, deleteRole } from "./ProductServiceAPI";
import Button from '@mui/material/Button';

const Role = () => {
  const columns = [
    { title: " Role Title ", field: "roleType", align: "center" },
  ];

  const [role, setRole] = useState(0);
  const [roleTypes, setroleType] = useState("");
  const [list, setList] = useState([]);
  const [roleError, setroleError] = useState("");
  const [update, setupdate] = useState(false);

  const handleRole = (e) => {
    setroleType(e.target.value);
    setroleError("");
  };

  const formValidation = () => {
    let roleError = "";
    let regex = /^[A-Za-z0-9 ]+$/;

    if (roleTypes == "") {
      roleError = "Please fill the Details";
    } else if (roleTypes.length < 3 || regex.test(roleTypes) === false) {
      roleError =
        " Please Enter Minimun 3 Characters and do not use special Character";
    }

    if (roleError) {
      setroleError(roleError);
      return false;
    }
    return true;
  };

  const saveData = () => {
    const isValid = formValidation();
    if (isValid) {
      let data = {
        RoleType: roleTypes,
      };
      postRole(data)
        .then((response) => {
          if (response.data._response.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Role Added Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            getlist();
            setroleType("");
          } else {
            Swal.fire({
              icon: "error",
              title: response.data._response.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }

          setroleType("");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getlist();
  }, []);

  const getlist = () => {
    getRole()
      .then((response) => {
        setList(response.data._response.data.getrole);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateUser = (a) => {
    setRole(a.roleId);
    setroleType(a.roleType);
    setupdate(true);
  };

  const updateClick = () => {
    const data = {
      RoleId: role,
      RoleType: roleTypes,
    };
    editRole(data)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Role Updated Sucessfully",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
        getlist();
        setroleType("");
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Role Not updated",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      });
  };

  function deleteUser(roleId) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          deleteRole(roleId)
            .then((result) => {
              Swal.fire({
                icon: "success",
                title: "Role Deleted Sucessfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });

              getlist();
              // clear();
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Role Not Deleted",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in trash role");
      });
  }

  const ClearData = () => {
    setroleType("");
    setroleError("");
  };

  return (
    <>
      <div>
        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
              <br/><br/>
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="m-0 text-center">Role</h1>
                  </div>
                </div>
              </div>
            </div>

            <section className="content  consti-section">
              <div className="container">
                <div className="row">
                  <Col md={12}>
                    <Card style={{ padding: "20px" }}>
                      <Form.Group
                        as={Row}
                        className="justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={12} className="mb-4">
                          <Form.Label className="w-100 pb-1">
                            Role Title
                          </Form.Label>
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="roleTypes"
                            placeholder="Enter your role"
                            value={roleTypes}
                            onChange={handleRole}
                          />
                          <div style={{ color: "red" }}>{roleError}</div>
                        </Col>
                      </Form.Group>
                      <div className="button_style m-0 text-center">
                        {update === false ? (
                          <Button
                            type="button"
                            variant='contained'
                            onClick={saveData}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                          variant='contained'
                          color='success'
                            onClick={() => updateClick()}
                          >
                            Update
                          </Button>
                        )}
                       {" "}{" "}
                        <Button
                          type="button"
                          variant='contained'
                          color='error'
                          onClick={() => ClearData()}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Card>
                  </Col>
                </div>
                <Col className='mt-4' md={12} >

                <MaterialTable
                  title={false}
                  data={list}
                  columns={columns}
                  actions={[
                    {
                      icon: "edit",
                      tooltip: "Edit",
                      onClick: (event, rowData) => {
                        updateUser(rowData);
                      },
                    },

                    {
                      icon: "delete",
                      tooltip: "Delete",
                      onClick: (event, rowData) => {
                        deleteUser(rowData.roleId);
                      },
                    },
                  ]}
                  options={{
                    search: false,
                    pageSize: 5,
                    showFirstLastPageButtons: false,
                    actionsColumnIndex: -1,
                    addRowPosition: "first",
                  }}
                />
                </Col>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Role;
