import React,{useState,useEffect} from 'react';
import { Container, Card, Row, Col, Form } from 'react-bootstrap';
import Button from "@mui/material/Button";
import Header from '../../common/header/Header';
import logo1 from "../../../assets/img/logo1.png";
import { Link, useLocation,useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Select from "react-select";
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import {AiFillHome} from 'react-icons/ai';
import {
  NoAgelist,
  NocountyList,
  NoEducationlist,
  NoOccupationlist
} from "../Admin/ProductServiceAPI";
import Swal from "sweetalert2";


const SubRegister = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const key = location.state


    const [selectAge, setselectAge] = useState([]);
    const [ageOptions,setageOptions] = useState([]);
    const [selectGender,setselectGender] = useState([]);
    const [genderOptions,setgenderOptions] = useState([]);
    const [selectEducation,setselectEducation] = useState([]);
    const [educationOptions,seteducationOptions] = useState([]);
    const [selectOccupation,setselectOccupation] = useState([]);
    const [occupationOptions,setoccupationOptions] = useState([]);
    const [selectLocation,setselectLocation] = useState([]);
    const [locationOptions,setlocationOptions] = useState([]);
    const [formError,setFormError] = useState([]);

  

    const handleAge = (selected) => {
      setselectAge(selected)
      setFormError({...formError,selectAge:""})
      };


    const handleGender = (selected) => {
      setselectGender(selected)
      setFormError({...formError,selectGender:""})
      };

      const handleOccupation = (selected) => {
        setselectOccupation(selected)
      setFormError({...formError,selectOccupation:""})

        };

        const handleEducation = (selected) => {
          setselectEducation(selected)
       setFormError({...formError,selectEducation:""})

          };

          const handleLocation = (selected) => {
            setselectLocation(selected)
       setFormError({...formError,selectLocation:""})

            };
    


            const genderoption = [
              { value: '1', label: 'Male' },
              { value: '2', label: 'Female' },
              { value: '3', label: 'Other' },
            ]


            const validate = () => {
              let formError = {};
              let isValid = true;
            
              if (selectAge.length < 1) {
                isValid = false;
                formError["selectAge"] = "Please Select Age";
              }
              if (selectGender.length < 1) {
                isValid = false;
                formError["selectGender"] = "Please Select Gender";
              }
              if (selectEducation.length < 1) {
                isValid = false;
                formError["selectEducation"] = "Please Select Education";
              }
              
              if (selectOccupation.length < 1) {
                isValid = false;
                formError["selectOccupation"] = "Please Select Occupation"
              }

              if (selectLocation.length < 1) {
                isValid = false;
                formError["selectLocation"] = "Please Select Location"
              }
             
              setFormError(formError);
              return isValid;
            };


            const addSubRegister = (e)=> {
              e.preventDefault();
            
              debugger;

              let isValid = validate()
              if(isValid===true) {


                  // navigate("/productVoting")
                const data = {
                  age:selectAge.value,
                  gender:selectGender.label,
                  occupation:selectOccupation.value,
                  educationLevel:selectEducation.value,
                  CountyId:selectLocation.value
                  
                }
                axios.post("https://api.topbrandsranking.com/ProductManagement/AddMemberDummy",data)
                .then((response) => {
                  const userid = response.data._response.data.memberid

                  if (key.page === "authorisedcampaigndetails") {

                    navigate(`/Voting`, {state:{key,userid}})

                  }else if(key.page === "authorisedCelebritycampaigndetails"){
                    
                    navigate(`/GeneralCelebrityVoting`, {state:{key,userid}})
                  }
                  else if(key.page === "authorisedVideocampaigndetails"){

                    navigate(`/GeneralVideoVoting`, {state:{key,userid}})

                  }else{

                    alert("Something Went Wrong")

                  }
                }).catch((error) => {
                  console.log(error)
                })

              }else{
                console.log("dsfs")
              }

            }



            useEffect(() => {
              getageList();
              CountyList();
              educationList();
              occupationList();
            }, []);



            const getageList = () => {
            NoAgelist().then((res) => {
      if (res.data._response.status === 1) {
        let age = res.data._response.data.getages
        let option = age.map((a) => ({
          value: a.ageId,
          label: a.ageDropdown
        }))
        setageOptions(option)
      }
    }).catch((err) => {
      console.log(err, 'err in get agelist');
      Swal.fire({
        icon: "warning",
        title: "err in get agelist",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        // cancelButtonColor: '#d33',
        confirmButtonText: "ok",
      });
    })
  }


  const CountyList = () => {
    NocountyList()
      .then((res) => {
        let county = res.data._response.data.getcountywithnocounty;
        let countylist = county.map((a) => ({
          value: a.countyId,
          label: a.countyName,
        }));
        setlocationOptions(countylist);
      }).catch((err) => {
        Swal.fire({
          icon: "warning",
          title: "Something went wrong",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      })

  };



  const occupationList = () => {
    NoOccupationlist().then((res) => {
      if (res.data._response.status === 1) {
        let occupations = res.data._response.data.getoccupation
        let option = occupations.map((a) => ({
          value: a.occupationId,
          label: a.occupationDropdown
        }))
        setoccupationOptions(option)
      }
    }).catch((err)=>{
      console.log(err,"err in get occupation");
  })
  }


  const educationList = () => {
    NoEducationlist().then((res) => {
      if (res.data._response.status === 1) {
        let educations = res.data._response.data.geteducation
        let option = educations.map((a) => ({
          value: a.educationId,
          label: a.educationDropdown
        }))
        seteducationOptions(option)
      }
    }).catch((err)=>{
      console.log(err,"err in get education");
  })
  }



  const ResetForm = (e)=> {
    e.preventDefault()

    setselectAge([])
    setselectGender([])
    setselectEducation([])
    setselectOccupation([])
    setselectLocation([])
    setFormError([])

  }
  

    return(
        <>
       
        
        <div className='sub-register-form'>
         <Container>
            <Row className='d-flex justify-content-start'>
                <Col sm={6}>
            <Card className='p-2 login-card'>
                <Card.Body>
                    <div className='text-end'>
                    <Link to="/"> <IconButton size='large'><AiFillHome color='#CD0404' size="35px"/></IconButton></Link> 

                    </div>
                <div className="text-center m-0">
                  <Link to="/"><img
                    className="img-fluid "
                    src={logo1}
                    alt="logo-topbrands"
                    height="80px"
                    width="120px"
                  />
                  </Link>
                  <h1 className="m-0 text-center login-heading">About the Voter</h1>
                </div>
                <br/>
                    <Form>
                    <Row className="mb-3">
        <Form.Group className='col-sm-6 mb-3' controlId="formGridEmail">
        <FormControl size="small" fullWidth>
      {/* <InputLabel id="demo-select-small">Age</InputLabel> */}
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        placeholder="Select Age"
        options={ageOptions}
        value={selectAge}
        label="Age"
        onChange={handleAge}
        
      >
      </Select>
    </FormControl>

    <div style={{ color: "red" }}>
                          {formError.selectAge}
                        </div>{" "}
        </Form.Group>

        <Form.Group className='col-sm-6' controlId="formGridState">

        <FormControl size="small" fullWidth>
      {/* <InputLabel id="demo-select-small">Gender</InputLabel> */}
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        placeholder="Select Gender"
        value={selectGender}
        options={genderoption}
        onChange={handleGender}
        
      >
      </Select>
    </FormControl>
    <div style={{ color: "red" }}>
                          {formError.selectGender}
                        </div>{" "}
        </Form.Group>
      </Row>
      <Row className="mb-3">
      <Form.Group className='col-sm-6 mb-3' controlId="formGridEmail">
      <FormControl size="small" fullWidth>
      {/* <InputLabel id="demo-select-small">Occupation</InputLabel> */}
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        placeholder="Select Occupation"
        value={selectOccupation}
        options={occupationOptions}
        onChange={handleOccupation}
        
      >
      </Select>
    </FormControl>

    <div style={{ color: "red" }}>
                          {formError.selectOccupation}
                        </div>{" "}
        </Form.Group>
        <Form.Group className='col-sm-6' controlId="formGridEmail">
        <FormControl size="small" fullWidth>
      {/* <InputLabel id="demo-select-small">Educational</InputLabel> */}
      <Select
        // labelId="demo-select-small"
        id="demo-select-small"
        placeholder= "Select Education"
        value={selectEducation}
        options={educationOptions}
        onChange={handleEducation}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        
      >
      </Select>
    </FormControl>
    <div style={{ color: "red" }}>
                          {formError.selectEducation}
                        </div>{" "}
        </Form.Group>
      </Row>
      <Row className="mb-3">
      <Form.Group  controlId="formGridEmail">
       <FormControl size="small" fullWidth> 
      {/* <InputLabel id="demo-select-small">Location</InputLabel> */}
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        placeholder="Select Location"
        options={locationOptions}
        value={selectLocation}
        onChange={handleLocation}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        
      >
      </Select>
    </FormControl>
    <div style={{ color: "red" }}>
                          {formError.selectLocation}
                        </div>{" "}
        </Form.Group>
      </Row>

      <div className='text-center m-0'>
        
        <button className='button-85' type="submit" onClick={addSubRegister}>Submit</button> &nbsp; &nbsp;
        <button className='button-85' type="submit"  onClick={ResetForm}>Cancel</button>
      </div>
                    </Form>
                </Card.Body>
            </Card>
            </Col>
            </Row>
            
         </Container>
        </div>
        </>
    )
}

export default SubRegister;