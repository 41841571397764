import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import { AddFirstForm, Addgender, Addage, Addimage, Addcounty, Addconsti, Addward } from '../../../redux/action/registerAction';
import { useDispatch, useSelector } from 'react-redux';
// import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  getCountyData,
  ConstituencygetbyCounty,
  getWardbyConstituency,
  MemberImage,
  NocountyList
} from "../Admin/ProductServiceAPI";
import logo1 from "../../../assets/img/logo1.png";
import Header from '../../common/header/Header';
import Select from "react-select";
import ProductURL from '../../ServerCalls/ProductServer';
import "./Registration.css";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import Auth from '../../ServerCalls/Auth';
import Button from '@mui/material/Button';
import axios from "../../ServerCalls/AxiosInstance";
import { store } from "../../../redux/store";
import CollapsibleExample from "../../common/NewHeader";
import IconButton from '@mui/material/IconButton';
import { AiFillHome } from 'react-icons/ai';

const OtherInformation = () => {

  const state = useSelector((state) => state.registeruser.userDetails);
  const [CountySelected, setCountySelected] = useState([]);
  const [ConstiSelected, setConstiSelected] = useState([]);
  const [WardSelected, setWardSelected] = useState([]);
  const [CountyOption, setCountyOption] = useState([]);
  const [ConstiOption, setConstiOption] = useState([]);
  const [WardOption, setWardOption] = useState([]);
  const [update, setupdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRefrence1 = useRef(null);

  const navigate = useNavigate();
  const token = Auth.getToken();
  const dispatch = useDispatch()


  //error handlling


  const [CountyErr, setCountyErr] = useState("");
  const [ConstiErr, setConstiErr] = useState("");
  const [WardErr, setWardErr] = useState("");




  const formValidate = () => {
    let redux_data = store.getState();
    let data = redux_data.registeruser.userDetails


    let CountyErr = "";
    let ConstiErr = "";
    let WardErr = "";
    let regex = /^[a-z]+$/i;






    if (Object.keys(data.county).length === 0) {
      CountyErr = "Please select County";
    }

    if (Object.keys(data.constituency).length === 0) {
      ConstiErr = "Please select Constituency";
    }
    if (Object.keys(data.ward).length === 0) {
      WardErr = "Please select Ward";
    }




    if (

      CountyErr ||
      ConstiErr ||
      WardErr

    ) {

      setCountyErr(CountyErr);
      setConstiErr(ConstiErr);
      setWardErr(WardErr);



      return false;
    }
    return true;
  };









  useEffect(() => {
    CountyData();
  }, []);

  const CountyData = () => {
    NocountyList()
      .then((res) => {
        let county = res.data._response.data.getcountywithnocounty;
        let countylist = county.map((a) => ({
          value: a.countyId,
          label: a.countyName,
        }));
        setCountyOption(countylist);
      }).catch((err) => {
        Swal.fire({
          icon: "warning",
          title: "Something went wrong",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      })

  };








  // const saveForm = async () => {
  //   const isValid = formValidate();
  //   if (isValid) {
  //     const data = {
  //       //  MemberId : auth,


  //       CountyId: CountySelected.value,
  //       ConstituencyId: ConstiSelected.value,
  //       WardId: WardSelected.value,

  //       Role: 2,
  //     };

  //     setLoading(true);
  //       axios.post("https://api.topbrandsranking.com/Authorization/AddUserData", data)
  //       // axios.post("https://localhost:5001/api/Authorization/AddUserData", data)

  //       .then((response) => {
  //         setLoading(false);
  //         Swal.fire({
  //           icon: "success",
  //           title: "Registration Successful, Kindly verify your email by clicking on the verification link sent on your email address.",
  //           showConfirmButton: true,
  //           confirmButtonColor: "#3085d6",
  //           // cancelButtonColor: '#d33',
  //           confirmButtonText: "ok",
  //         });
  //         navigate('/login')
  //         handleReset()
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         console.log(err);
  //       });
  //   }
  // };

  const handleReset = () => {

    inputRefrence1.current.value = null;

    setCountySelected("");
    setConstiSelected("");
    setWardSelected("");

  };


  const handleCounty = (selected) => {
    setCountySelected(selected);
    dispatch(Addcounty(selected))
    ConstituencygetbyCounty(selected.value)
      .then((response) => {
        const consti = response.data._response.data.constibycountyid
        const constiData = consti.map((a) => ({
          value: a.constituencyId,
          label: a.constituencyName

        }));
        setConstiOption(constiData)
      }).catch((err)=>{
        Swal.fire({
          icon: "warning",
          title: "err in get county",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      })
    setCountyErr("")
  };

  const handleConsti = (selected) => {
    setConstiSelected(selected);
    dispatch(Addconsti(selected))
    getWardbyConstituency(selected.value)
      .then((response) => {
        const ward = response.data._response.data.wardgetconstituencyid
        const wardData = ward.map((a) => ({
          value: a.wardId,
          label: a.wardName

        }));
        setWardOption(wardData)
      }).catch((err)=>{
        Swal.fire({
          icon: "warning",
          title: "err in get consti",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      })
    setConstiErr("")
  };

  const handleWard = (selected) => {
    setWardSelected(selected);
    dispatch(Addward(selected))
    setWardErr("")
  };

  const NextForm = () => {
    let isValid = formValidate();
    if (isValid) {
      navigate('/Educational-Info');
    }
  }



  return (
    <>
   
   {/* {
      !token ?
        <CollapsibleExample />
        :
        null

    } */}
      <div className="regiform">
        <div>
          <section >
            <div className="container">
            <br /><br /><br />
              <div className="row ">
                <Col md={12} className="">
                  <Card className="register-card bg-white">
                  <div className='text-end'>
                      <Link to="/"><IconButton size='large'><AiFillHome color='#CD0404' size="35px" /></IconButton></Link>
                    </div>
                    <div className=" m-0 text-center">
                      <img
                        className="img-fluid "
                        src={logo1}
                        alt="logo-topbrands"
                        height="80px"
                        width="120px"
                      />
                      <h1 className="m-0 text-center register-heading">
                        Sign Up!
                      </h1>
                    </div>


                    <div>






                      <Form.Group
                        as={Row}
                        className="mb-3 justify-content-center"
                        controlId="formHorizontalEmail"
                      >

                        <Col sm={12}>
                          <Form.Label className="w-100">County</Form.Label>
                          <Select
                            className="w-100"
                            options={CountyOption}
                            onChange={handleCounty}
                            value={state.county}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                          />
                          <br/>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {CountyErr}
                          </div>
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Row}
                        className="mb-3 justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={6}>
                          <Form.Label className="w-100">
                            Constituency
                          </Form.Label>

                          <Select
                            className="w-100"
                            options={ConstiOption}
                            onChange={handleConsti}
                            value={state.constituency}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                          />
                          <br/>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {ConstiErr}
                          </div>
                        </Col>
                        <Col sm={6}>
                          <Form.Label className="w-100">Ward</Form.Label>

                          <Select
                            className="w-100"
                            options={WardOption}
                            onChange={handleWard}
                            value={state.ward}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                          />
                          <br/>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {WardErr}
                          </div>
                        </Col>
                      </Form.Group>





                      <div className="button_style m-0 text-center">

                        <Link to="/register">  <button
                          type="button"
                          variant="contained"
                          color="error"
                          className="button-85 "
                        //   onClick={() => handleReset()}
                        >
                          Back
                        </button></Link>
                        &nbsp;&nbsp;
                        <button
                          variant="contained"
                          className="button-85 "
                          onClick={NextForm}
                        >Next</button>




                      </div>





                      {/* 
                      <div className="button_style m-0 text-center">
                        <Button
                          variant="contained"

                          onClick={saveForm}
                        >
                          {
                    loading === true ?
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>


                      :
                      null
                  }
                  &nbsp;
                  <span>Submit</span>
                        </Button>{" "}
                        <Button
                          type="button"
                          variant="contained"
                          color="error"
                          onClick={() => handleReset()}
                        >
                          Cancel
                        </Button>
                      </div> */}
                      <br />
                      <div className="regi-a">
                        <p className='fgt-psw'>
                          Already have an Account?{" "}
                          <Link className="fgt-psw" to='/login'> Login Here !</Link>
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
              </div>
              <br /> <br />
            </div>
          </section>
          </div>
      </div>
    </>
  );
};

export default OtherInformation;
