import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Card, Container } from "react-bootstrap";
import Select from "react-select";
import Button from '@mui/material/Button';
import { campaignData, FinalvotingReport,getGenderReport ,getAgeReport,getCountyReport,getOccupationReport,getEducationReport} from '../ProductServiceAPI'
import Swal from "sweetalert2";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Loader from '../../../common/Loader'
import { useReactToPrint } from 'react-to-print'
import axios from "../../../ServerCalls/AxiosInstance";
import Chart from 'react-apexcharts';
import NotFound from '../../../common/NotFound';

const Genderreport = () => {

    const [campaignOptions, setCampaignOptions] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [GenderCount, setGenderCount] = useState([]);
    const [MaleFemaleTotal,setMaleFemaleTotal] = useState([]);
    const [Total, setTotal] = useState([]);
    const [loading, setloading] = useState(false)

    const [piegender, setpiegender] = useState([]);
    const [piegenderCount, setpiegenderCount] = useState([]);

    const useStyles = makeStyles({

        table: {


            "& .MuiTableCell-root": {

                border: "1px solid rgba(0, 0, 0, 0.3)"

            }

        }

    });

    const classes = useStyles();


    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })



    const handleCampaign = (selected) => {
        setSelectedCampaign(selected)
    }


    const clear = () => {

        setSelectedCampaign('')

    }



    useEffect(() => {

        campaignList();
        if(selectedCampaign != ''){

            reports();
            // genderReport();
        }

    }, [selectedCampaign])


    const campaignList = () => {
        campaignData().then((res) => {

            let arr = [];
            let list = res.data._response.data.getpollingcampaign;

            list.map((a) => (
                arr.push({
                    label: a.pollingCampaignShortName,
                    value: a.campaignId,
                })
            ))
            setCampaignOptions(arr)
        }).catch((err)=>{
            console.log(err,"err in get campaignlist");
        })

    }


    const reports = () => {
      
        setloading(true);
        //  axios.get(`https://localhost:5002/api/ProductManagement/ProductChart?campaignid=${selectedCampaign.value}`)
          FinalvotingReport(selectedCampaign.value)
            .then((res) => {
              
                if (res.data._response.status === 1) {
                  
                    let gender = res.data._response.data.individualproductidmailfemalecount
                    let total = res.data._response.data.totalcountofgender;
                    let totalboth = res.data._response.data.genderwisetotalofallproducts
                   
                    setTotal(total);
                    setMaleFemaleTotal(totalboth)    
                    setloading(false)
                    setGenderCount(gender);                  
                    // setData(res.data._response.data.productsummary)
                } else if (res.data._response.status === 2) {
                  
                    setloading(false);
                    setTotal([]);                  
                    setMaleFemaleTotal([])             
                    setGenderCount([]);
                }
            }).catch((err)=>{
                setloading(false);
                console.log(err,"err in get report");
            })
    }

    // const genderReport = () => {
    //     // axios.get(`https://localhost:5002/api/ProductManagement/ReportforGender?campaignid=${selectedCampaign.value}`)
    //     getGenderReport(selectedCampaign.value)
    //         .then((res) => {
    //             console.log(res, "got gender wise response")
    //             const gender = res.data._response.data.genderwisecount

    //             let gendername = [];
    //             let gendercount = [];

    //             gender.map((a) => {
    //                 gendername.push(a.gender)
    //                 gendercount.push(a.count)
    //             })

    //             setpiegender(gendername)
    //             setpiegenderCount(gendercount)

    //         }).catch((err) => {
    //             console.log(err)

    //         })
    // }





  return (
    <>
    
    <div>
    <div>
        <div className="content-wrapper consti-section" id="divToPrint">
            <div className="content-header">
                <div className="container-fluid">
                    <br /><br />
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <h1 className="m-0 text-center">Voting List</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container">
                    <div className="row">
                        <Col md={12}>
                            <Card style={{ padding: "20px" }}>
                                <Form.Group
                                    as={Row}
                                    className="mb-1 justify-content-center"
                                    controlId="formHorizontalEmail"
                                >

                                    <Col sm={12} className="mb-4">
                                        <Form.Label className="w-100">Campaign</Form.Label>
                                        <Select
                                            className="w-100"
                                            options={campaignOptions}
                                            value={selectedCampaign}
                                            onChange={handleCampaign}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <div className="button_style m-0 text-center">

                                    <Button
                                        type="button"
                                        variant='contained'
                                        color='error'
                                        onClick={clear}
                                    >
                                        Cancel
                                    </Button>
                                </div>

                            </Card>
                        </Col>

                    </div>

                </div>

            </section>

        </div>
        <br></br>
    </div>
    <br /><br />







    {
        loading ? 
        <Loader/>
        :

    selectedCampaign === '' ||  GenderCount.length === 0?(

        <NotFound />


    ) :
        <>
            <div ref={componentRef}>
                <h1 className="m-0 text-center">Voting  Report Summary</h1>
                <h4 className="m-0 text-center">{selectedCampaign.label}</h4>
                <br></br>

                <Container>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report">
                        <TableContainer >
                         
                            <div className="mt-5">
                                <h2>Gender Wise</h2>
   

   {/* <div className="row">
       <div className="col-md-6"> */}

                                <Table sx={{ maxHeight: 300 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                    <TableHead >

                                        <TableRow className="backgroundrow">
                                            <TableCell></TableCell>
                                            <>

                                                {GenderCount.map((g, i) => (
                                                    <TableCell align="center" width="10%" rowSpan={2} className="head1">{g.productname}</TableCell>

                                                ))}
                                            </>
                                            <TableCell align="center" width="10%" rowSpan={2} className="head1">Total</TableCell>
                                        </TableRow>


                                    </TableHead>
                                    <TableBody>
                                        <TableRow>

                                            <TableCell width="12%" className="backgroundrow2"  >Male</TableCell>

                                            <>
                                                {GenderCount.map((m, i) => (
                                                    <TableCell align="center" className="head1">{m.maleCount}</TableCell>

                                                ))}</>

                                            <>

                                                <TableCell className="headerbgcolor" align="center">{MaleFemaleTotal.Male}</TableCell>
                                            </>
                                        </TableRow>
                                        <TableRow>

                                            <TableCell width="12%" className="backgroundrow2"  >Female</TableCell>

                                            <>
                                                {GenderCount.map((f, i) => (
                                                    <TableCell align="center" className="head1">{f.feMaleCount}</TableCell>

                                                ))}</>


                                            <TableCell align="center" className="headerbgcolor" >{MaleFemaleTotal.Female}</TableCell>
                                        </TableRow>

                                        <TableRow>

<TableCell width="12%" className="backgroundrow2"  >Other</TableCell>

<>
    {GenderCount.map((m, i) => (
        <TableCell align="center" className="head1">{m.notDefineCount}</TableCell>

    ))}</>

<>

    <TableCell className="headerbgcolor" align="center">{MaleFemaleTotal.NotDefine}</TableCell>
</>
</TableRow>

                                        <TableRow>

                                            <TableCell align="left" className="backgroundrow2" >Total Votes</TableCell>

                                            {GenderCount.map((all, i) => (
                                                <TableCell align="center">{all.overallcount}</TableCell>
                                            ))}

                                            <TableCell align="center">{Total}</TableCell>



                                        </TableRow>

                                    </TableBody>
                                    

                                </Table>
                                </div>
                                {/* <div className="col-md-6">

                                <Chart

type="pie"
width={500}
height={500}
series={piegenderCount}

options={{
    title: { text: "Voting Report" },
    noData: { text: "Empty Data" },
    labels: piegender,
    responsive: [{
        breakpoint: 1024,
        options: {
          chart: {
            width: 700
          },
          legend: {
            position: 'right'
          }

        },
        breakpoint: 480,
        options: {
          chart: {
            width: 250
          },
          legend: {
            position: 'bottom'
          },
          
        },
       

      }]
}} */}

{/* /> */}

                                {/* </div>
                                </div>
                              */}



                               
                            {/* </div> */}


                           

                         
                        </TableContainer>
                    </Paper>
                </Container>
            </div>
            <br></br>
            <div className="text-center m-0">
                {/* <Button variant="contained" onClick={downloadReceipt}>Download</Button> */}
                &nbsp;&nbsp;
                <Button variant="contained" onClick={handlePrint}>Print</Button>
            </div>

        </>

    }





</div>

{/* <div className="row d-flex justify-content-center mt-3 pt-1">
                     <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="eventadmin1 pl-4 pr-4 pt-3 pb-4">
                           <div className="container">
                              <div className="row pt-4">
                                 <div className="col-lg-3">
                                    <div className="carddash card-margin">
                                       <div className="card-header no-border">
                                          <h5 className="carddashheader1 ">Male</h5>
                                       </div>
                                       <div className="card-body pt-0">
                                          <p className='cusp'>20</p>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-lg-3">
                                    <div className="carddash card-margin">
                                       <div className="card-header no-border">
                                          <h5 className="carddashheader1">Female</h5>
                                       </div>
                                       <div className="card-body pt-0">
                                          <p className='cusp'>21</p>
                                       </div>
                                    </div>
                                 </div>
                                
                              </div>
                           </div>
                           <div className="container">
                              <div className="row pt-4">
                                 <div className="col-lg-12">

                                    <div className="boxordertrans pb-4">
                                       <div className="boxordertrans-header boxordertrans-border">
                                          <h4 className="boxordertrans-title">Total Votes </h4>
                                       </div>
                                       <div className="row pb-4">
                                          <div className="col-md-6" style={{marginLeft: "20px"}}>
                                            
                                          </div>

                                       

                    <Chart
                      type="pie"
                      width={700}
                      height={250}
                      series={piegenderCount}
                      options={{
                        title: { text: "Voting Report" },
                        noData: { text: "Empty Data" },
                        labels: piegender,
                      }}
                    />

                                       </div>
                                    </div>
                                 </div>
                                 
                              </div>
                           </div>
                        </div>
                     </div>
                  </div> */}


</>
  )
}

export default Genderreport