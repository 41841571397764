import React,{useState,useEffect} from 'react';
import CollapsibleExample from "../../components/common/NewHeader";
import Footer from '../../components/common/footer/Footer';
import axios from "axios";
import ProductURL from "../ServerCalls/ProductServer";
import { Rating } from 'react-simple-star-rating';
import { Box } from '@material-ui/core';
import { Pagination } from "@mui/material";
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';




const CelelbrityRankingList = () => {

  const [pageC, setPageC] = useState(4);
  const [TopCelebrity, setTopCelebrity] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const CelebrityPhotoPath = ProductURL.photoURL + "CelebrityImages/";



  const dataPerPage = 4;

  const changePages = (e, a) => {
    setPageNumber(a);
    topBrandCelebrity(a, dataPerPage);

  }






  useEffect(() => {
    topBrandCelebrity(1, 4);
  }, [])


  const topBrandCelebrity = (a, b) => {
    // getTopCelebrity()
    axios.get(`https://api.topbrandsranking.com/ProductManagement/TopCelebrityGetByCelebrityId?PageNo=${a}&PageSize=${b}`)
      .then((res) => {
        // console.log(res,"got res ssssssssssssss")
        const topcelebritydata = res.data._response.data.topcelebritygetbycelebrityid3
        let count = res.data._response.data.count;
        let APIpagecount = Math.ceil(count / dataPerPage);
        setPageC(APIpagecount)
        setTopCelebrity(topcelebritydata)
      }).catch((err) => {
        console.log(err)
      })

  }



  return (
   <>
    <CollapsibleExample />
<Container>
  <div className='pt-4 pb-4'>
  <h2 className='m-0 text-center trend-heading'>CELEBRITY <span>RANKING</span></h2>

  </div>
    <div className="row mt-2">
            {TopCelebrity.map((list, id) => (
              <div className="col-md-3 col-sm-6 pb-5">
                <Card className='cele-top-card'>

                  <div className="product-grid">
                    {/* <Card.Title>{list.brandname}</Card.Title> */}
                    <div className="product-image">
                      <div className="image" >
                        <img className="pic-1" alt='' src={CelebrityPhotoPath + list.celebrityimage1} />
                        <img className="pic-2" alt='' src={CelebrityPhotoPath + list.celebrityimage1} />

                      </div>

                    </div>
                    <Card.Body className="celeb-card-body" style={{ backgroundColor: ' #0f0c29' }}>

                      <div className="product-content">

                        <div className="text-center mx-auto">{list.celebrityname}
                        </div>

                        {/* <div className="price"></div> */}
                        <div className="text-center mx-auto">
                          <Rating
                            ratingValue={list.avergae}
                            // allowHover={false}
                            readonly
                            size='20px' />
                        </div>
                        <div className="text-center mx-auto">Total Ratings:&nbsp;{(list.avergae).toFixed(2)}%</div>
                      </div>
                    </Card.Body>
                  </div>

                </Card>
              </div>
            ))}
          </div>

              <Box display="flex" alignItems="center" justifyContent="center">
                          <Pagination
                              count={pageC}
                              page={pageNumber}
                              onChange={changePages}
                              shape="rounded" color="primary" size="large"
                          />
                      </Box>
                      <br/>
                      </Container>
    <Footer/>
    </>
  )
}

export default CelelbrityRankingList;