import axios from 'axios';
export const LOGIN_BEGIN = 'LOGIN_BEGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const RESET_ERROR = 'RESET_ERROR'
export const REFERESH_TOKEN = 'REFERESH_TOKEN'
export const AUTHENTICATOR_SUCCESS = 'AUTHENTICATOR_SUCCESS'



const APIURL = "/accounts/authenticate";

export const login = (email, password) => {
    return dispatch => {
        dispatch({
            type: LOGIN_BEGIN
        })

        axios
            .post(APIURL, {
                "email": email, "password": password,
            })
            .then(res => {

                if (res.data.jwtToken)
                {
                   document.cookie = `refreshToken=${res.data.jwtToken}; max-age=900000`;
                   localStorage.setItem('userDetails', JSON.stringify(res.data));

                    axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.jwtToken}`;
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload:res.data.jwtToken,
                        role:res.data.role,
                        isAuthenticatorEnable:res.data.twoFactorEnabled
                    });


                }
                else if (res.data.message) {
                    dispatch({
                        type: LOGIN_FAIL,
                        payload: res.data.message
                    })
                }
            })
            .catch(err => {
                dispatch({
                    type: LOGIN_FAIL,
                    payload:"Incorrect username or password"
                })
            });
    };
};

export const RefreshData = () => {
    return dispatch => {
      const ajax = axios({
        method:"POST",
        url:"/accounts/refresh-token",
      });

      ajax.then((res)=>{
        if (res.data.jwtToken)
        {
            axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.jwtToken}`;
            dispatch({
                type: REFERESH_TOKEN,
                payload:res.data.jwtToken,
                role:res.data.role
            });
            document.cookie = `refreshToken=${res.data.jwtToken}; max-age=900000`;
        }
        else
        {
            // dispatch({
            //     type: LOGIN_FAIL,
            //     payload:"Error"
            // });
        }
      });

      ajax.catch((error)=>{
        console.log(error);
        // dispatch({
        //     type: LOGIN_FAIL,
        //     payload:error
        // });
      });
    };
};

export const logout = { type: LOGOUT_SUCCESS }
export const resetError = { type: RESET_ERROR }

export const AuthenticatorVerification = () => {
    return dispatch => {
      dispatch({
          type: AUTHENTICATOR_SUCCESS,
          payload:"success",
      });
    };
};
