import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Card } from "react-bootstrap";
import MaterialTable from "material-table";
import { getreleaseCategorylist, mediareleaseList, addmediaRelase } from './CelebrityServiceAPI'
import Button from '@mui/material/Button';
import Select from "react-select";
import Swal from "sweetalert2";

export default function MediaRelease() {

    const [mediarelease, setMediarelease] = useState('');
    const [releaseCategoryOptions, setReleaseCategoryOptions] = useState([]);
    const [selectedReleaseCategory, setSelectedReleaseCategory] = useState([]);
    const [Allmediarelease, setAllMediarelease] = useState([]);
    const [formError, setFormError] = useState('');

    const [update, setUpdate] = useState(false);


    const columns = [
        { title: "Media Release", field: "mediareleasename", align: "center" },
        { title: "Media Release Category", field: "mediareleasecategoryname", align: "center" },
    ];


    const handleCategory = (selected) => {

        setSelectedReleaseCategory(selected)
    }
    const handleMediaRelease = (e) => {

        setMediarelease(e.target.value)
    }

    useEffect(() => {

        optionList();
        mediaReleaseData();

    }, [])


    const optionList = () => {

        getreleaseCategorylist().then((res) => {
            if (res.data._response.status === 1) {

                let list = res.data._response.data.getmediacategory
                const options = list.map((a) => ({
                    value: a.mediacategoryid,
                    label: a.mediacategoryname,
                }));
                setReleaseCategoryOptions(options)
            }

        }).catch((err) => {
            console.log(err, "err in get release category");
        })
    }

    const mediaReleaseData = () => {

        mediareleaseList().then((res) => {
            if (res.data._response.status === 1) {

                setAllMediarelease(res.data._response.data.getmediarelease)
            }
        })
            .catch((err) => {
                console.log(err, "err in get media release");
            })

    }



    const validates = () => {
        let formError = {};
        let isValid = true;
        let regex = /^[A-Za-z0-9 ]+$/;

        if (mediarelease === "") {
            isValid = false;
            formError["mediarelease"] = "Please Enter media release";
        } else if (
            mediarelease.length < 3 ||
            regex.test(mediarelease) === false
        ) {
            isValid = false;
            formError["mediarelease"] =
                "Please Enter Minimun 3 Characters and do not use special Character";
        }
        if (selectedReleaseCategory.length < 1) {
            isValid = false;
            formError["selectedReleaseCategory"] =
                "Please select Parent Release Category";
        }
        setFormError(formError);
        return isValid;
    };

    const saveData = () => {

        const isValid = validates();

        if (isValid) {

            const data = {
                MediaReleaseName: mediarelease,
                mediareleasecategoryid: selectedReleaseCategory.value
            }

            addmediaRelase(data).then((res) => {

                if (res.data._response.status === 1) {

                    Swal.fire({
                        icon: "success",
                        title: "Category Added Successfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });

                    mediaReleaseData();
                    clearData();

                }
            }).catch((err)=>{
                console.log(err,'err in add mediarelease');
            })

        }


    }

    const getData = (id)=>{
        setUpdate(true);
        setMediarelease({value:id.mediareleaseid,label:id.mediareleasename});
        setSelectedReleaseCategory({value:id.mediareleasecategoryid,label:id.mediareleasecategoryname});


    }

    const clearData = () => {
        setUpdate(false);
        setSelectedReleaseCategory([]);
        setMediarelease('');
        setFormError('');
    }

    return (

        <>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <br /><br />
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <h1 className="m-0 text-center">Media Release</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content consti-section">
                        <div className="container">
                            <div className="row">
                                <Col md={12} >
                                    <Card style={{ padding: "20px" }}>
                                        <>
                                            <Form.Group
                                                as={Row}
                                                className="justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">
                                                        Media Release
                                                    </Form.Label>
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        name="mediarelease"
                                                        placeholder="Enter Media Relase "
                                                        value={mediarelease}
                                                        onChange={handleMediaRelease}
                                                    />

                                                    <div style={{ color: "red" }}>
                                                        {formError.mediarelease}
                                                    </div>
                                                </Col>

                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">
                                                        Media Release Category
                                                    </Form.Label>
                                                    <Select
                                                        className="w-100"
                                                        options={releaseCategoryOptions}
                                                        name='selectedReleaseCategory'
                                                        onChange={handleCategory}
                                                        value={selectedReleaseCategory}
                                                        menuPortalTarget={document.body}
                                                        styles={{
                                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                        }}
                                                    />
                                                    <div style={{ color: "red" }}>
                                                        {formError.selectedReleaseCategory}
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                            <div className="button_style m-0 text-center">

                                                {update === false ? (
                                                    <Button
                                                        type="button"
                                                        variant='contained'
                                                        onClick={() => saveData()}
                                                    >
                                                        Submit
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant='contained'
                                                        color='success'
                                                    //onClick={() => editdata()}
                                                    >
                                                        Update
                                                    </Button>
                                                )}

                                                <Button
                                                    type="button"
                                                    variant='contained'
                                                    color="error"
                                                //onClick={() => ClearStates()}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </>
                                    </Card>
                                </Col>

                                <Col className='mt-4' md={12} >
                                    <MaterialTable
                                        title="Media Release"
                                        data={Allmediarelease}
                                        columns={columns}
                                        actions={[
                                            {
                                                icon: "edit",
                                                tooltip: "Edit",
                                                onClick: (event, rowData) => {
                                                    getData(rowData);
                                                },
                                            },

                                            {
                                                icon: "delete",
                                                tooltip: "Delete",
                                                onClick: (event, rowData) => {
                                                    // trashCategory(rowData.celebcategoryid);
                                                },
                                            },
                                        ]}
                                        options={{
                                            search: false,
                                            pageSize: 5,
                                            showFirstLastPageButtons: false,
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                        }}
                                    />
                                </Col>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>

    )
}
