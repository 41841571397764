import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import {Container, Card, Row, Col, Button} from 'react-bootstrap';
import './generalnews.css';
import axios from 'axios';
import React,{ useState, useEffect} from 'react';
// import { Nav, Navbar, Dropdown, Card, Button ,Row, Col, NavDropdown} from 'react-bootstrap';
import { GetGeneralNewsWithPagination} from "./ProductServiceAPI";
import ProductURL from "../../ServerCalls/ProductServer";
import moment from 'moment-timezone';
import { Pagination } from "@mui/material";
import { Box } from '@material-ui/core'
import CollapsibleExample from '../../common/NewHeader';

const TopNewsPhotoPath = ProductURL.photoURL + "GeneralNewsImages/";






const AllGeneralNews = () => {

    const [GeneralNews,setGeneralNews] = useState([]);
    const [pageC, setPageC] = useState(2);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const dataPerPage = 4;

    

    const changePage = (e, a) => {
      setPageNumber(a);
      getallgeneralnews(a,dataPerPage);
    

      
  }



    useEffect(()=>{
        getallgeneralnews(1,4)

    },[])

    const getallgeneralnews = (a,b) => {

      //  axios.get(`https://localhost:5002/api/ProductManagement/GetGeneralNewsWithPagination?PageNo=${a}&PageSize=${b}`) 
        GetGeneralNewsWithPagination(a,b)
        .then((res)=>{
          let count = res.data._response.data.count;
          let APIpagecount = Math.ceil(count / dataPerPage);
          setGeneralNews(res.data._response.data.getgeneralnews)
            setPageC(APIpagecount)
            setLoading(false);
        })
        .catch((err)=>{
          console.log(err,'err in get generalnewpagination');
        })

    }

  return (
<>
<CollapsibleExample/>
<div className='news-description-body'>
  <h1 className='text-center mt-4 mx-auto mb-5 trend-heading'>All General <span>News</span></h1>
  <Container>
    {GeneralNews.map((a,i)=>(
<Card className='news-descri-card mb-4'>
  <Card.Body>
    
    <Row>
      <Col sm={3}>
        <div className='news-img-grid'>
          <img src={TopNewsPhotoPath+ a.newsImages} width='250' height='200' alt='news' className='image-fluid news-img' />
        </div>
      </Col>
      <Col sm={8}>
        <div className='d-flex justify-content-end m-2'>
          <div className='news-date'>
           <p className='pt-2'>{moment(a.newsDatetime).format('DD/MM')}</p>
         
          </div>
        </div>
        <div className="news-description  pt-0">
          <h3 className="news-header text-start pb-4 m-0">{a.newsHeader}</h3>
          <p className="news-p">
         {a.newsDescription}
          </p>
        </div>
      </Col>
    </Row>
  </Card.Body>
</Card>
))}
</Container>
<Box display="flex" alignItems="center" justifyContent="center">
                          <Pagination
                              count={pageC}
                              page={pageNumber}
                              onChange={changePage}
                              shape="rounded" color="primary" size="large"
                          />
                      </Box>
</div>
{/* <Footer/> */}
</>
    

  )
}

export default AllGeneralNews