import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Card, Container } from "react-bootstrap";
import Select from "react-select";
import Button from '@mui/material/Button';
import { getCelebrityCampaign,getCelebrityReport,getCelebrityEducationReport} from './CelebrityServiceAPI'
import Swal from "sweetalert2";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Loader from '../../../common/Loader'
import { useReactToPrint } from 'react-to-print'
import axios from "../../../ServerCalls/AxiosInstance";
import Chart from 'react-apexcharts';
import { getGenderReport } from '../ProductServiceAPI';
import NotFound from '../../../common/NotFound';

const CelebrityEducationReport = () => {

  const [data, setData] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [Educationdata, setEducationdata] = useState([]);
  const [CollegeTotal, setCollegeTotal] = useState([]);
  const [GraduationTotal, setGraduationTotal] = useState([]);
  const [PrimaryCount, setPrimaryCount] = useState([]);
  const [SecondaryCount, setSecondaryCount] = useState([]);
  const [UniversityCount, setUniversityCount] = useState([]);
  const [EducationTotal, setEducationTotal] = useState([]);
  const [OtherTotals,setOtherTotals] = useState([]);
  const [loading, setloading] = useState(false);
  const [PieEducationName,setPieEducationName] = useState([]);
  const [PieEducationCount,setPieEducationCount] = useState([]);


  const useStyles = makeStyles({

    table: {


        "& .MuiTableCell-root": {

            border: "1px solid rgba(0, 0, 0, 0.3)"

        }

    }

});

const classes = useStyles();


const componentRef = useRef();

const handlePrint = useReactToPrint({
    content: () => componentRef.current
})



const handleCampaign = (selected) => {
    setSelectedCampaign(selected)
}


const clear = () => {

    setSelectedCampaign('')

}



useEffect(() => {

    campaignList();

    if(selectedCampaign != ''){
        reports();
        educationReport();
    }
   


}, [selectedCampaign])


const campaignList = () => {
    getCelebrityCampaign().then((res) => {

        let arr = [];
        let list = res.data._response.data.getcelebritycampaign;

        list.map((a) => (
            arr.push({
                label: a.campaignShortName,
                value: a.campaignId,
            })
        ))
        setCampaignOptions(arr)
    })  .catch((err)=>{
        console.log(err,'err in getcampaignlist');
    })

}

const educationReport = ()=>{

  // axios.get(`https://localhost:5002/api/ProductManagement/CelebrityReportforEducationlevel?campaignid=${selectedCampaign.value}`)
  getCelebrityEducationReport(selectedCampaign.value)
.then((res)=>{

    const Educationdata = res.data._response.data.alleducationcount

    let educationName = [];
    let educationCount = [];

    Educationdata.map((a)=>{
        educationName.push(a.educationlevel)
})

    Educationdata.map((a)=>{
        educationCount.push(a.count)

    })

    setPieEducationName(educationName)
    setPieEducationCount(educationCount)


  

}).catch((err)=>{
    console.log(err)
})


}


const reports = () => {
    setloading(true);
//   axios.get(`https://localhost:5002/api/ProductManagement/CelebrityChart?campaignid=${selectedCampaign.value}`)
       getCelebrityReport(selectedCampaign.value)
      .then((res) => {

        console.log(res,"got education")

          if (res.data._response.status === 1) {
          
               let Educationdatas = res.data._response.data.individualcelebrityeducationlevel;
               let CollegeTotalCount = res.data._response.data.educationlevelwisetotalofallcelebrity.College;
               let PostgraduationTotal = res.data._response.data.educationlevelwisetotalofallcelebrity.PostGraduation;
               let PrimaryTotal = res.data._response.data.educationlevelwisetotalofallcelebrity.Primary;
               let SecondaryTotal = res.data._response.data.educationlevelwisetotalofallcelebrity.Secondary;
               let UniversityTotal = res.data._response.data.educationlevelwisetotalofallcelebrity.University;
               let OtherTotal = res.data._response.data.educationlevelwisetotalofallcelebrity.noeducationlevel;
               let EducationTotals = res.data._response.data.educationleveltotal;


      

               setloading(false);
               setEducationdata(Educationdatas);
               setCollegeTotal(CollegeTotalCount);
               setGraduationTotal(PostgraduationTotal);
               setPrimaryCount(PrimaryTotal);
               setSecondaryCount(SecondaryTotal);
               setUniversityCount(UniversityTotal);
               setEducationTotal(EducationTotals); 
               setOtherTotals(OtherTotal)             
               setData(res.data._response.data.celebritysummary)
          } else if (res.data._response.status === 2) {
             
            setEducationdata([]);
            setCollegeTotal([]);
            setGraduationTotal([]);
            setPrimaryCount([]);
            setSecondaryCount([]);
            setUniversityCount([]);
            setEducationTotal([]);
            setData([])
            setOtherTotals([])
            setloading(false);
   
          }
      })  .catch((err)=>{
          setloading(false);
        console.log(err,'err in getEducationReport');
    })
}




  return (
    <div>
    <div>
        <div className="content-wrapper consti-section" id="divToPrint">
            <div className="content-header">
                <div className="container-fluid">
                    <br /><br />
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <h1 className="m-0 text-center">Voting List</h1>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container">
                    <div className="row">
                        <Col md={12}>
                            <Card style={{ padding: "20px" }}>
                                <Form.Group
                                    as={Row}
                                    className="mb-1 justify-content-center"
                                    controlId="formHorizontalEmail"
                                >

                                    <Col sm={12} className="mb-4">
                                        <Form.Label className="w-100">Campaign</Form.Label>
                                        <Select
                                            className="w-100"
                                            options={campaignOptions}
                                            value={selectedCampaign}
                                            onChange={handleCampaign}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            }}
                                        />
                                    </Col>
                                </Form.Group>

                                <div className="button_style m-0 text-center">

                                    <Button
                                        type="button"
                                        variant='contained'
                                        color='error'
                                        onClick={clear}
                                    >
                                        Cancel
                                    </Button>
                                </div>

                            </Card>
                        </Col>

                    </div>

                </div>

            </section>

        </div>
        <br></br>
    </div>
    <br /><br />







    { loading ? 
    <Loader/>
    :
    selectedCampaign === '' || Educationdata.length === 0  ? (

        <NotFound />


    ) :
        <>
            <div ref={componentRef}>
                <h1 className="m-0 text-center">Voting  Report Summary</h1>
                <h4 className="m-0 text-center">{selectedCampaign.label}</h4>
                <br></br>

                <Container>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report">
                        <TableContainer >
                           



                            <div className="mt-5">
                                <h2>Education Wise</h2>

                                {/* <div className="row">
       <div className="col-md-6"> */}



                                <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                    <TableHead >

                                        <TableRow className="backgroundrow">
                                            <TableCell></TableCell>
                                            <>
                                                {Educationdata.map((education, id) => (
                                                    <TableCell width="10%" align="center" rowSpan={2} className="head1">{education.celebrityname}</TableCell>
                                                ))}


                                            </>

                                            <TableCell align="center" width="10%" rowSpan={2} className="head1">Total</TableCell>
                                        </TableRow>


                                    </TableHead>
                                    <TableBody>
                                        <TableRow>

                                            <TableCell width="10%" className="backgroundrow2"  >Primary</TableCell>

                                            {Educationdata.map((a, e) => (
                                                <TableCell align="center">{a.primary}</TableCell>
                                            ))}

                                            <TableCell className="headerbgcolor" align="center">{PrimaryCount}</TableCell>
                                        </TableRow>
                                        <TableRow>

                                            <TableCell className="backgroundrow2"  >Secondary</TableCell>


                                            {Educationdata.map((a, e) => (
                                                <TableCell align="center">{a.secondary}</TableCell>
                                            ))}

                                            <TableCell className="headerbgcolor" align="center">{SecondaryCount}</TableCell>
                                        </TableRow>

                                        <TableRow>

                                            <TableCell className="backgroundrow2"  >College</TableCell>


                                            {Educationdata.map((a, e) => (
                                                <TableCell align="center">{a.college}</TableCell>
                                            ))}

                                            <TableCell className="headerbgcolor" align="center">{CollegeTotal}</TableCell>
                                        </TableRow>

                                        <TableRow>

                                            <TableCell className="backgroundrow2"  >University</TableCell>


                                            {Educationdata.map((a, e) => (
                                                <TableCell align="center">{a.university}</TableCell>
                                            ))}
                                            <TableCell className="headerbgcolor" align="center">{UniversityCount}</TableCell>
                                        </TableRow>

                                        <TableRow>

                                            <TableCell className="backgroundrow2"  >Post Graduate </TableCell>


                                            {Educationdata.map((b, e) => (
                                                <TableCell align="center">{b.postGraduation}</TableCell>
                                            ))}
                                            <TableCell className="headerbgcolor" align="center">{GraduationTotal}</TableCell>
                                        </TableRow>
                                        <TableRow>

<TableCell className="backgroundrow2"  >Other</TableCell>


{Educationdata.map((b, e) => (
    <TableCell align="center">{b.noeducationlevel}</TableCell>
))}
<TableCell className="headerbgcolor" align="center">{OtherTotals}</TableCell>
</TableRow>


                                        <TableRow>

                                            <TableCell align="left" className="backgroundrow2"  >Total Votes</TableCell>

                                            {Educationdata.map((total, e) => (
                                                <TableCell align="center">{total.overallcount}</TableCell>
                                            ))}
                                            <TableCell className="headerbgcolor" align="center">{EducationTotal}</TableCell>



                                        </TableRow>

                                    </TableBody>

                                </Table>


                                </div>
                                {/* <div className="col-md-6">       
                                      
                                <Chart
      
      type="pie"
      width={500}
      height={300}
       series={PieEducationCount}

      options={{
        title: { text: "Voting Report" },
        noData: { text: "Empty Data" },
        colors: ['#ac92eb', '#4fc1e8', '#8bcb30', '#ffc809', '#ed5564'],
        labels: PieEducationName,
        responsive: [{
            breakpoint: 1024,
            options: {
              chart: {
                width: 700
              },
              legend: {
                position: 'right'
              }

            },
            breakpoint: 480,
            options: {
              chart: {
                width: 250
              },
              legend: {
                position: 'bottom'
              },
              
            },
            // breakpoint: 768,
            // options: {
            //   chart: {
            //     width: 400
            //   },
            //   legend: {
            //     position: 'bottom'
            //   },
              
            // }

          }]
      }}

    /> 
                                </div>
                                </div>



                            </div> */}


                        </TableContainer>
                    </Paper>
                </Container>
            </div>
            <br></br>
            <div className="text-center m-0">
                {/* <Button variant="contained" onClick={downloadReceipt}>Download</Button> */}
                &nbsp;&nbsp;
                <Button variant="contained" onClick={handlePrint}>Print</Button>
            </div>

        </>

    }





</div>
  )
}

export default CelebrityEducationReport