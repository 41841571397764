import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Card, Alert, Container } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import getYouTubeID from 'get-youtube-id';
import Swal from "sweetalert2";
import { campaignNameData, add_Votingdata, getReportSummary } from "../Admin/ProductServiceAPI";
import ProductURL from "../../ServerCalls/ProductServer";
import { FcInfo } from "react-icons/fc";
import { FaVoteYea } from "react-icons/fa";
import { MdHowToVote } from "react-icons/md";
import Loader from "../../common/Loader";
import NotFound from "../../common/NotFound";
import { Col, Row } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Chart from 'react-apexcharts';
import { Rating } from 'react-simple-star-rating';
import axios from "../../ServerCalls/AxiosInstance";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import Auth from '../../ServerCalls/Auth';
import PausedOverlay from "../Citizen/PausedOverlay";
import LoadingOverlay from "../Citizen/LoadingOverLay";
import HoverVideoPlayer from 'react-hover-video-player';
import { makeStyles } from "@material-ui/core/styles";
// import './voting.css';





const PhotoPath = "https://media.topbrandsranking.com/ProductImages/";
const advertisePhoto = "https://media.topbrandsranking.com/CampaignAdvertiseImages/"

export default function Sharecampaign() {
  const { id, date, question, categoryid } = useParams();


  const token = Auth.getUserId();
  const navigate = useNavigate();

  const [listByNames, setlistByNames] = useState([]);
  const [alreadyvote, setAlreadyVote] = useState([]);
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [ProductName, setProductName] = useState([]);
  const [TotalVotes, setTotalVotes] = useState([]);
  const [PiechartProducts, setPieChartProducts] = useState([]);
  const [PiechartCounts, setPieChartCounts] = useState([]);
  const [TopProducts,setTopProducts] = useState([]);
  const [Length,setLength] = useState([]);
  const [campaignname,setcampaignname] = useState([]);
  const [campaignquestion,setcampaignquestion] = useState([]);
  const [Campaigndata,setCampaigndata] = useState([]);



  const useStyles = makeStyles({

    table: {


        "& .MuiTableCell-root": {

            border: "1px solid rgba(0, 0, 0, 0.3)"

        }

    }

});

const classes = useStyles();




  const handleClose = () => {
    setShowReport(false)
  };


  



  useEffect(() => {
    ParticipatedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ParticipatedProducts = () => {
    setLoading(true);

    let campaignid = atob(id)
    console.log(campaignid,"finally solve")
    let citizenid = Number(token);

    // campaignNameData(citizenid, campaignid)
       axios.get(`https://api.topbrandsranking.com/ProductManagement/ShareVideoCampaignGetById/${campaignid}`)
    //  axios.get(`https://localhost:5002/api/ProductManagement/SharePollingCampaignGetById/${campaignid}`)
      .then((res) => {
        console.log(res,"got share campaign video")
       
        if (res.data._response.status === 1) {
          let data = res.data._response.data.videoId;
          let campaigndata = res.data._response.data.getcampaign[0]
          console.log(res,"got response")
          setlistByNames(data);
          setCampaigndata(campaigndata)
          setLoading(false);


          let alreadyvotes = [];
          data &&
            data.map((f) => {
              if (f.isVoted === 0) {
                alreadyvotes.push(f);
              }
            });
          setAlreadyVote(alreadyvotes);
        }
      })
      .catch((err) => {
        console.log("err in get", err);
        setLoading(false);
      });
  };



  const OpenReport = () => {

    setShowReport(true)

  }


  return (
    <>

{
        !token ?
          <Header />
          :
          null

      }

      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <br />
            <br />
            <div className="row mb-2">
              <div className="col-sm-12">
                <h1 className="m-0 text-center">{Campaigndata.campaignShortName}</h1>
              </div>
              <br />
              <br />
              {/* <Row>
                <Col sm={12}>
                  <div className="d-flex justify-content-center mt-3">
                    <Button variant="contained" color="success" onClick={OpenReport} title="Click to show Report">Report</Button>
                  </div>

                </Col>
              </Row> */}
            </div>
          </div>
        </div>


        <section className="section-2">
          <div className="container">
            <div className="row">

              <Alert variant="primary">
       
                <h4
                  style={{
                    fontWeight: "600",
                    fontSize: "1.8rem",
                    textTransform: "uppercase",
                  }}
                >
                  {Campaigndata.question}?
                </h4>
              </Alert>
              
              <card-group className="d-flex" style={{ marginTop: "36px" }}>
                <Col md={12} className="mb-2">
                  <Row >
                    {loading ? (
                      <Loader />
                    ) : listByNames.length !== 0 ? (
                   
                      listByNames.map((list, id) => {
                       
                        return (
                          
                          <Col md={3}>
                            <Card
                              className="gallerycard"
                              style={{
                                height: "350px",

                                marginTop: "15px",
                              }}
                            >

<iframe className='video'
                                                                title='Youtube player'
                                                                //   allowfullscreen
                                                                width="440" height="230"
                                                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                                              src={`https://youtube.com/embed/${getYouTubeID(list.uploadvideo)}?autoplay=0` } type="video/mp4" />
                                                           
                              
                              {/* <HoverVideoPlayer
      videoSrc={advertisePhoto + list.uploadvideo}
    
      pausedOverlay={<PausedOverlay />}
      loadingOverlay={<LoadingOverlay width="100%" height="200%"/>}
    />
                                <Card.Body> */}
                                <Card.Body>
                                <Card.Title className='voting-title' title={list.brandname} >{list.brandname}</Card.Title>

                                <Card.Text title={list.videoshortname} className="votingsub-title">
                                  {list.videoshortname}
                                </Card.Text>
                                <Link to="/subregister" state={{ from: Campaigndata.videocampaignId
, date: Campaigndata.campaignEndDate, name: Campaigndata.campaignShortName, question: Campaigndata.question,page: "authorisedVideocampaigndetails" }}>
                                  <button
                                    className={
                                      new Date(date) <= new Date()
                                        ? " cursor-not-allowed"
                                        : "myclass"
                                    }
                                    disabled={new Date(date) <= new Date()}
                                    title="vote now"
                                    style={{
                                      border: "none",
                                      marginTop: "10px",
                                      marginRight: "20px",
                                    }}
                                    // onClick={() => navigate("/login" state=)}
                                    type="submit"
                                  >
                                    <MdHowToVote
                                      fontSize="35px"
                                      color="orangered"
                                    />
                                  </button>
                                  </Link>
                               {/* ) : null} */}

                                {/* <Link
                                  to={`/ShareProductDetails/${list.productid}`}
                                  title="more info"
                                >
                                  <FcInfo fontSize="35px" />
                                </Link> */}
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      })
                    ) : (
                      <NotFound />
                    )}
                  </Row>
                </Col>
              </card-group>
            </div>
          </div>
        </section>
        <div className="button_style m-0 text-center pt-4">
        <Link to="/"> <Button
          
            type="button"
            variant="contained"
            color="error"
            // onClick={() => navigate(-1)}
          >
            Home
          </Button>
          </Link>
        </div>
        <br />
        <br />


      <Container>
        <Row>
          <Col>
        <Modal show={showReport} className="mt-3 product-modal" onHide={handleClose} size="lg" backdrop="static" centered style={{padding:'30px'}}>
          <Modal.Header className="headerBlue" closeButton >
            <Modal.Title className=''>
              <p className="text-center mt-2 pl-2 mx-auto"> Report Summary </p>
            </Modal.Title>


          </Modal.Header>
          <h4 className="mx-auto">{campaignname}</h4>
          <Modal.Body>
            <div className="row">
          
          <Container>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }} id="report">
                                <TableContainer >
                                    <Table sx={{ maxHeight: 440 }} aria-label="simple table" id="example" border="1" cellpadding="3" className={classes.table} >
                                        <TableHead >

                                            <TableRow className="backgroundrow">
                                                <TableCell width="10%" className="head1">Product Name</TableCell>
                                             
                                                <TableCell align="center" width="10%" rowSpan={2} className="head1">Total Vote</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                             {ProductName.map((list,id)=>(
                                            <TableRow>
                                             
                                                <TableCell className="headerbgcolor" >{list.productname}</TableCell> 
                                 

                                                <TableCell className="headerbgcolor" align="center">{list.count}</TableCell>
                                           
                                            </TableRow>
                                            ))}
                                      
                                        </TableBody>
                                    </Table>

                                  <Row>
                                    <Col>
                                    
                                    
                                   
                                    </Col>
                                  </Row>


                                </TableContainer>
                                
                            </Paper>
                        </Container>
                        </div>
          

           
          </Modal.Body>
        </Modal>
        </Col>
        </Row>
        </Container>
      </div>
      {/* <Footer /> */}
    </>
  );
}
