
import React, { useState, useEffect } from 'react'
import { Form, Card, Button, Row, Col, Container } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Slider } from "@material-ui/core";
import Swal from 'sweetalert2'
import { AddCitizenPolling } from '../Admin/ProductServiceAPI'
import Auth from '../../ServerCalls/Auth';

const Addpoll = () => {

  const [isEditable, setIsEditable] = useState(false);
  const [pollingperc, setPollingPerc] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const productId = location.state;


  const marks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 100,
      label: '100%',
    },
    {
      value: pollingperc,
      label: pollingperc + "%"
    }
  ];




  const toGrid = (e) => {

    navigate("/ProductPolling", { state: e })
  }

  const savePolling = () => {


    var auth = Auth.getUserId()


    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    const data = ({

      PollingPerc: pollingperc,
      PollingDate: today,
      CampaignId: productId.campaignid,
      ProductId: productId.productid,
      CitizenId: parseInt(auth)


    })

    AddCitizenPolling(data)
      .then((response) => {

        Swal.fire({
          icon: "success",
          title: "Added Successfully",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });


      }).catch(() => {
        Swal.fire({
          icon: "error",
          title: "Not Added",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      })

  }

  return (
    <>

      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <h1 className="m-0 text-center">Pre-Polling</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <Col md={12} className="mb-2">
                <Card style={{ padding: "20px" }}>
                  <Form.Group
                    as={Row}
                    className="mb-3 justify-content-center"
                    controlId="formHorizontalEmail"
                  >

                    <Col sm={4}>
                      <Form.Label className="w-100">Product Name</Form.Label>


                      <input
                        className="w-100 form-control"
                        type="text"
                        name="productname"
                        value={productId.productName}
                        disabled
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-3 justify-content-center"
                    controlId="formHorizontalEmail"
                  >
                    <Col sm={4}>
                      <Form.Label className="w-100">
                        Polling Percentage
                      </Form.Label>
                      {!isEditable ? (
                        <Slider
                          className="Height 50%"
                          size='medium'
                          value={pollingperc}
                          valueLabelDisplay={pollingperc}
                          aria-label="Default"
                          marks={marks}
                          onChange={(e, data) => {
                            setPollingPerc(data);
                          }}
                        />
                      ) : (
                        <input
                          className="w-100"
                          type="text"
                          id="name"
                          // value={slidervalue}
                          disabled="disabled"
                        />
                      )}
                    </Col>

                  </Form.Group>

                  <div className="button_style mt-3 text-center">

                    <button
                      type="button"
                      className="btn btn-pos1"
                      onClick={savePolling}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-pos2"
                      onClick={() => toGrid(productId)}
                    >
                      Back
                    </button>

                  </div>
                </Card>
              </Col>

            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Addpoll