import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo1 from '../../assets/img/logo1.png';
import {FaUserCircle} from 'react-icons/fa';
import {BsStopCircleFill} from 'react-icons/bs';

function CollapsibleExample() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="black" variant="dark">
      <Container>
        <Navbar.Brand href="/">
        <img src={logo1} className='img-fluid logo-img' alt='' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">HOME</Nav.Link>
            <Nav.Link href="/about-us">ABOUT US</Nav.Link>
            <NavDropdown title="MAGAZINE" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/rankinglistdata"><span><BsStopCircleFill fontSize="25px" /></span> &nbsp; TOPBRANDSRANKING</NavDropdown.Item>
              <NavDropdown.Item href="/scoredata">
              <span><BsStopCircleFill fontSize="25px" /></span> &nbsp;  TOPSCOREBRANDS
              </NavDropdown.Item>
              
            </NavDropdown>
          </Nav>
          <Nav>
          <NavDropdown title="USERS" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/register"><span><FaUserCircle fontSize="25px" /></span> &nbsp; USER REGISTRATION</NavDropdown.Item>
              <NavDropdown.Item href="/BrandRegistration">
              <span><FaUserCircle fontSize="25px" /></span> &nbsp;   BRAND-OWNER REGISTRATION
              </NavDropdown.Item>
              
            </NavDropdown>
            <Nav.Link className='p-0 mx-4 login-navlink' eventKey={2} href="/login">
            <button class="button-85 " role="button">Login</button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;