export const Add_Update_Brand = "Add_Update_Brand"
export const Add_Update_Category = "Add_Update_Category"
export const Celebrity_Update_Category = "Celebrity_Update_Category"

export const AddUpdateBrand = (data) => {
    return dispatch => {
        dispatch({
            type: Add_Update_Brand,
            payload: data,
        })
    };
};
export const AddUpdateCategory = (data) => {
    return dispatch => {
        dispatch({
            type: Add_Update_Category,
            payload: data
        })
    };
};
export const celebritycategory = (data) => {
    return dispatch => {
        dispatch({
            type: Celebrity_Update_Category,
            payload: data
        })
    };
};