import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import MaterialTable from "material-table";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { useLocation } from 'react-router-dom'
import axios from "../../ServerCalls/AxiosInstance";
import ProductURL from "../../ServerCalls/ProductServer";
import { PostpollingCampaign, CampaignLevelList, CampaignPhotos, campaignData, ProductbyCategories, CategoryList, Campaigngetbyid, EditpollingCampaign, deletePollingCampaign, getCountyData, Categorywithnoparent,NocountyList } from './ProductServiceAPI'
import Button from '@mui/material/Button';
import moment from "moment";
const ProductCampaign = () => {
  
  const columns = [
    {
      title: "Campaign ShortName ",
      field: "pollingCampaignShortName",
      align: "center",
    },
    // {
    //   title: "Campaign Description ",
    //   field: "pollingCampaignDescription",
    //   align: "center",
    // },
    {
      title: "Campaign StartDate ",
      field: "pollingCampaignStartDate",
      type: "date",
      align: "center",
      render: rowdata => {
        var d = new Date(rowdata.pollingCampaignStartDate);

        d.setDate(d.getDate() - 1);
        return (
          <span>{moment(d).format('DD-MM-yyyy')}</span>

        )
      }
    },
    {
      title: "Campaign EndDate ",
      field: "pollingCampaignEndDate",
      type: "date",
      align: "center",
      render: rowdata => {
        var d = new Date(rowdata.pollingCampaignEndDate);

        d.setDate(d.getDate() - 1);
        return (
          <span>{moment(d).format('DD-MM-yyyy')}</span>

        )
      }
    },
   
  ];
  const [campaign, setCampaign] = useState('');
  const [campaignID, setCampaignID] = useState(0);
  const [pollData, setPollData] = useState([]);
  const [formError, setFormError] = useState([]);
  const [campstartDate, setCampstartDate] = useState(null);
  const [campendDate, setCampendDate] = useState(null);
  const [productSelected, setProductselected] = useState([]);
  const [productselectOption, setproductselectOption] = useState([]);
  const [procategoryselected, setProcategoryselected] = useState([]);
  const [categoryselectOption, setcategoryselectOption] = useState([]);
  const [campaignLevelSelected, setCampaignLevelSelected] = useState([]);
  const [campaignleveloption, setcampaignleveloption] = useState([]);
  const [countySelected, setCountySelected] = useState([]);
  const [countyOption, setCountyOption] = useState([]);
  const [campaignShortname, setCampaignShortname] = useState('');
  const [Question, setQuestion] = useState('');
  const [update, setupdate] = useState(false);
  const [countydisable, setCountydisable] = useState(true);
  const [categorydisable, setCategorydisable] = useState(true);
  const [productDisable, setproductDisable] = useState(true);
  const [campaignImg, setCampaignImg] = useState([]);
  const [campaignImgpath, setCampaignImgpath] = useState([]);
  const [Showlist, setShowlist] = useState(false);
  const [AdvertiseSelected,setAdvertiseSelected] = useState([]);
  const [AdvertiseImg,setAdvertiseImg] = useState([]);
  const [AdvertiseImgpath,setAdvertiseImgpath] = useState([]);
  const [Link, setLink] = useState("");
  const PhotoPath = ProductURL.photoURL + "CampaignImages/";
  const advertisePhoto = "https://media.topbrandsranking.com/CampaignAdvertiseImages/"

  const inputRefrence = useRef(null);
  const inputRefrence2 = useRef(null);

  const jumptoBack = useLocation();
  const validate = () => {
    let formError = {};
    let isValid = true;
    if (campaignShortname === "") {
      isValid = false;
      formError["campaignShortName"] = "Please Enter Campaign shortName";
    } else if (campaignShortname.length < 3) {
      isValid = false;
      formError["campaignShortName"] = "Please Enter Minimun 3 Characters.";
    } else if (campaignShortname.length > 200) {
      isValid = false;
      formError["campaignShortName"] = "Maximum 200 characters are allowed";
    }
    if (campaign === "") {
      isValid = false;
      formError["campaignDescription"] = "Please Enter campaign Description";
    }
    if (productSelected.length < 1) {
      isValid = false;
      formError["productSelected"] = "Please Select product";
    }
    if (procategoryselected.length < 1) {
      isValid = false;
      formError["procategoryselected"] = "Please Select category";
    }
    if (campaignLevelSelected.length < 1) {
      isValid = false;
      formError["campaignLevelSelected"] = "Please Select campaign level";
    }
    if (campstartDate === null) {
      isValid = false;
      formError["campstartDate"] = "Please Select Start Date"
    }
    if (campendDate === null) {
      isValid = false;
      formError["campendDate"] = "Please Select End Date"
    }
    if (campaignImg.length < 1) {
      isValid = false;
      formError["campaignImg"] = "Please Select Image"
    }
    if (Question === "") {
      isValid = false;
      formError["question"] = "Please Enter Question";
    } else if (Question.length > 5000) {
      isValid = false;
      formError["question"] = "Maximum 5000 characters are allowed";
    }
    setFormError(formError);
    return isValid;
  };
  const image_upload = async (data) => {
    await CampaignPhotos(data)
      .then((res) => {
        console.log("photo res", res);
      })
      .catch((err) => {
        console.log(err, "err in photo submit");
      });
  };

  const advertise_image_upload = async (data) => {
    // await CampaignPhotos(data)
    await axios.post("https://api.topbrandsranking.com/ProductManagement/PollingCampaignSaveAdvertise/SaveFile",data)
      .then((res) => {
        console.log("photo res", res);
      })
      .catch((err) => {
        console.log(err, "err in photo submit");
      });
  };


  const AdvertiseOption = [
    { value: 1, label: 'Upload_Link' },
    { value: 2, label: 'Upload_Video' },
    { value: 3, label: 'Upload_Image' },
  ]

  const handleAdvertise = (selected)=> {

    setAdvertiseSelected(selected)


  }

  const handleimage = (e) => {
    setCampaignImg(e.target.files[0]);
    setCampaignImgpath(URL.createObjectURL(e.target.files[0]));
    setFormError({ ...formError, campaignImg: "" });
  };



  const handleAdvertiseimage = (e) => {

    setAdvertiseImg(e.target.files[0]);
    setAdvertiseImgpath(URL.createObjectURL(e.target.files[0]));
    // setFormError({ ...formError, campaignImg: "" });

  }

  const handleLink = (e) => {
    setLink(e.target.value)
  }


  const changeQuestion = (e) => {
    const { name, value } = e.target
    setQuestion(value)
    setFormError({ ...formError, question: "" });
  }
  const cancelUser = (e) => {
    e.preventDefault()
    setFormError({
      campaignShortName: "",
      campaignDescription: "",
      campstartDate: "",
      campendDate:"",
      productSelected: "",
      procategoryselected: "",
      campaignLevelSelected: "",
      countySelected: "",
    });
    setCampstartDate(null);
    setCampendDate(null);
    setCampaignShortname('')
    inputRefrence.current.value = null;
    inputRefrence2.current.value = null;
    setCampaign('');
    setLink("");
    setAdvertiseSelected([]);
    setAdvertiseImgpath([]);
    setAdvertiseImg([]);
    setCampaignID(0);
    setQuestion('');
    setProcategoryselected([]);
    setCampaignLevelSelected([]);
    setCountySelected([]);
    setProductselected([]);
    setupdate(false)
    setCampaignImgpath([])
    setCampaignImg([])
  };
  const handleInput = (e) => {
    setCampaign(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@.,;'"" "]/ig, ''));
    setFormError({ ...formError, campaignDescription: "" });
  };
  const handleShortname = (e) => {
    setCampaignShortname(e.target.value)
    setFormError({ ...formError, campaignShortName: "" });
  }
  const fixTimezoneOffset = (date) => {
    if (!date) return "";
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
  }
  const saveData = async () => {
    debugger;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    const isvalid = validate();
    if (isvalid === true) {
      let product = [];
      productSelected &&
        Object.entries(productSelected).map(([a, b]) =>
          product.push(b.value)
        );
      let county = [];
      countySelected &&
        Object.entries(countySelected).map(([k, v]) =>
          county.push(v.value)
        );
      const data = {
        pollingCampaignShortName: campaignShortname,
        pollingCampaignDescription: campaign,
        pollingCampaignProductCategoryId: procategoryselected.value,
        pollingCampaignLevel: campaignLevelSelected.value,
        pollingCampaignStartDate: fixTimezoneOffset(new Date(campstartDate.getFullYear()
        , campstartDate.getMonth()
        , campstartDate.getDate()
        , 23, 59, 59)),
        pollingCampaignEndDate: fixTimezoneOffset(new Date(campendDate.getFullYear()
          , campendDate.getMonth()
          , campendDate.getDate()
          , 23, 59, 59)),
        productId: product,
        countyId: county,
        PollingQuestion: Question,
        PollingImage: campaignImg.name === "" ? "" : campaignImg.name,
        ParticipationDate: today,
        AdvertiseType:AdvertiseSelected.label,
        AdvertiseSource:AdvertiseSelected.value === 1?Link: AdvertiseImg.name === "" ? "" : AdvertiseImg.name
      };

      let formdata = new FormData();
      formdata.append("file", campaignImg);
      await image_upload(formdata);

      let formdata1 = new FormData();
      formdata1.append("file", AdvertiseImg);
      await advertise_image_upload(formdata1);
      PostpollingCampaign(data)
      // axios.post("https://localhost:5002/api/ProductManagement/PollingCampaignSave",data)
        .then((res) => {
          if (res.data._response.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Campaign Added Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
            getList();
            getList("");
            cancelUser()
          } else {
            Swal.fire({
              icon: "error",
              title: res.data._response.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    setShowlist(false)
    getList();
    getLevel();
    getCategoryList();
    getCounty();
  }, [jumptoBack.key]);
  const getList = () => {
    campaignData()
      .then((res) => {
        setPollData(res.data._response.data.getpollingcampaign);
      })
      .catch((err) => {
        console.log(err, "err in campaign getlist");
      });
  };
  const getCategoryList = () => {
    Categorywithnoparent()
      .then((res) => {
        let list = res.data._response.data.getprocategory;
        const options = list.map((a) => ({
          value: a.categoryid,
          label: a.categoryName,
        }));
        setcategoryselectOption(options);
      })
      .catch((err) => {
        console.log(err, "err in get category list");
      });
  };
  const getLevel = () => {
    CampaignLevelList()
      .then((res) => {
        let list = res.data._response.data.getcampaignlevel;
        const options = list.map((a) => ({
          value: a.campaignLevelId,
          label: a.campaignLevelName,
        }));
        setcampaignleveloption(options);
      })
      .catch((err) => {
        console.log(err, "err in get campaignlevel");
      });
  };
  const getCounty = () => {
    NocountyList()
      .then((res) => {
        let list = res.data._response.data.getcountywithnocounty;
        const options = list.map((a) => ({
          value: a.countyId,
          label: a.countyName,
        }));
        setCountyOption(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateUser = (pollID) => {
    setShowlist(false);
    setCountydisable(true);
    setCategorydisable(false);
    Campaigngetbyid(pollID)
    // axios.get(`https://localhost:5002/api/ProductManagement/PollingCampaignGetById/${pollID}`)
      .then((res) => {
     
        let result = res.data._response.data.getcampaign[0]
        let countylevel =
          res.data._response.data.getcampaign[0].pollingcampaignlevelid;
        if (countylevel === 2) {
          setCountydisable(false);
        }
        let shortname =
          res.data._response.data.getcampaign[0].campaignshortname;
        let description =
          res.data._response.data.getcampaign[0].campaigndescription;
        let pollingCampaignStartDate = new Date(
          res.data._response.data.getcampaign[0].campaignstartdate
        );
        pollingCampaignStartDate.setDate(pollingCampaignStartDate.getDate() - 1);
        let pollingCampaignEndDate = new Date(
          res.data._response.data.getcampaign[0].campaignenddate
        );
        pollingCampaignEndDate.setDate(pollingCampaignEndDate.getDate() - 1);
        let pollingCampaignLevel =
          res.data._response.data.getcampaign[0].campaignlevelname;
        let pollingCampaignLevelId =
          res.data._response.data.getcampaign[0].pollingcampaignlevelid;
        let pollingCampaignProductCategoryId =
          res.data._response.data.getcampaign[0]
            .pollingcampaignproductcategoryId;
        let pollingCampaignProductcategoryname =
          res.data._response.data.getcampaign[0].categoryname;
        let productId = res.data._response.data.productId;
        let countyId = res.data._response.data.countyid;
        let countyValues = [];
        let countyOption = countyId.map((a) =>
          countyValues.push({ value: a.countyid, label: a.countyname })
        );
        let productValues = [];
        let productOption = productId.map((b) =>
          productValues.push({ value: b.productid, label: b.productname })
        );
        setCampaign(description);
        setCampaignShortname(shortname)
        setCampaignID(pollID)
        setCampstartDate(pollingCampaignStartDate);
        setCampendDate(pollingCampaignEndDate);
        setCampaignLevelSelected({
          value: pollingCampaignLevelId,
          label: pollingCampaignLevel,
        });
        handleCategory({
          value: pollingCampaignProductCategoryId,
          label: pollingCampaignProductcategoryname
        })
        setProcategoryselected({
          value: pollingCampaignProductCategoryId,
          label: pollingCampaignProductcategoryname,
        });
        setProductselected(productValues);
        setCountySelected(countyValues);
        setQuestion(result.pollingquestion);
        setCampaignImg(result.pollingimage)
        setproductDisable(false);
        setAdvertiseSelected({value:result.advertisetype,label:result.advertisetypelabel})
        setLink(result.advertisesource)
        setAdvertiseImg(result.advertisesource === null ? []:result.advertisesource);
        setupdate(true);
      })
      .catch((err) => {
        console.log(err, "err in get campaign with id");
      });
  };
  const updateClick = async () => {
    debugger;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    const isvalid = validate();
    if (isvalid === true) {
      let product = [];
      productSelected &&
        Object.entries(productSelected).map(([a, b]) =>
          product.push(b.value)
        );
      let county = [];
      countySelected &&
        Object.entries(countySelected).map(([k, v]) =>
          county.push(v.value)
        );
      const data = {
        campaignId: campaignID,
        pollingCampaignShortName: campaignShortname,
        pollingCampaignDescription: campaign,
        pollingCampaignProductCategoryId: procategoryselected.value,
        pollingCampaignLevel: campaignLevelSelected.value,
        pollingCampaignStartDate: fixTimezoneOffset(new Date(campstartDate.getFullYear()
        , campstartDate.getMonth()
        , campstartDate.getDate()
        , 23, 59, 59)),
        pollingCampaignEndDate: fixTimezoneOffset(new Date(campendDate.getFullYear()
        , campendDate.getMonth()
        , campendDate.getDate()
        , 23, 59, 59)),
        productId: product,
        countyId: county,
        ParticipationDate: today,
        PollingQuestion: Question,
        PollingImage: campaignImg.name === undefined ? campaignImg : campaignImg.name,
        AdvertiseType:AdvertiseSelected.label,
        AdvertiseSource:AdvertiseSelected.value === 1?Link: AdvertiseImg.name === "" ? "" : AdvertiseImg.name
      };
      let formdata = new FormData();
      formdata.append("file", campaignImg);
      if (campaignImg.name !== undefined) { await image_upload(formdata); }

      let formdata1 = new FormData();
      formdata1.append("file", AdvertiseImg);
      await advertise_image_upload(formdata1);

      EditpollingCampaign(data)
        .then((response) => {
          // Swal.fire({
          //   icon: "success",
          //   title: "Campaign Updated Successfully",
          //   showConfirmButton: true,
          //   confirmButtonColor: "#3085d6",
          //   // cancelButtonColor: '#d33',
          //   confirmButtonText: "ok",
          // });
          // getList();
          // cancelUser();
          // setupdate(false);

          if (response.data._response.status === 1) {
            Swal.fire({
              icon: "success",
              title: response.data._response.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            getList();
            cancelUser();
            setupdate(false);
          } else {
            Swal.fire({
              icon: "error",
              title: response.data._response.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {

          console.log(err)
          // Swal.fire({
          //   icon: "error",
          //   title: "Campaign  Not Added",
          //   showConfirmButton: true,
          //   confirmButtonColor: "#3085d6",
          //   // cancelButtonColor: '#d33',
          //   confirmButtonText: "ok",
          // });
        });
    }
  };
  function deleteUser(pollingCampaignId) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          deletePollingCampaign(pollingCampaignId)
            .then((res) => {
              if (res.data._response.status === 1) {
                Swal.fire({
                  icon: "success",
                  title: "Campaign Deleted Sucessfully",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  // cancelButtonColor: '#d33',
                  confirmButtonText: "ok",
                });
                getList();
              } else if (res.data._response.status === 2) {
                Swal.fire({
                  icon: "warning",
                  title: res.data._response.message,
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  // cancelButtonColor: '#d33',
                  confirmButtonText: "ok",
                });
              }
            })
        }
      })
      .catch((err) => {
        console.log(err, "err in trash product campaign");
      });
  }
  const handleCategory = (selected) => {
    setProcategoryselected(selected);
    ProductbyCategories(selected.value)
      .then((response) => {
        console.log(response, "got resposne me here");
        const data = response.data._response.data.productgetid;
        const option = data.map((a) => ({
          value: a.productid,
          label: a.productname,
        }));
        setproductselectOption(option);
      })
      .catch((err) => {
        console.log(err, 'err in get productcategory');
      })
    setproductDisable(false);
    setFormError({ ...formError, procategoryselected: "" });
  };
  const handleLevel = (selected) => {
    setCampaignLevelSelected(selected);
    if (selected.value === 2) {
      setCountydisable(false);
    } else {
      setCountydisable(true);
    }
    setFormError({ ...formError, campaignLevelSelected: "" });
  };
  const handleProduct = (e) => {
    setProductselected(e);
    setFormError("");
  };
  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <br /><br />
            <div className="row mb-2">
              {!Showlist ? (
                <div className="col-sm-12">
                  {!update ? (
                    <h1 className="m-0 text-center"> ADD CAMPAIGN </h1>
                  ) :
                    <h1 className="m-0 text-center"> EDIT CAMPAIGN </h1>
                  }
                </div>
              ) :
                <div className="col-sm-12">
                  <h1 className="m-0 text-center"> CAMPAIGN LIST </h1>{" "}
                </div>
              }
            </div>
            <Col md={12} >
              <div className='d-flex justify-content-end'>
                {!Showlist ?
                  <button
                    type="button"
                    className="btn btn-pos3 "
                    onClick={() => {
                      setShowlist(true);
                      cancelUser()
                    }
                    }
                  >
                    <i className="fa-solid fa-list"></i> List
                  </button>
                  :
                  <button
                    type="button"
                    className="btn btn-pos4 "
                    onClick={() => setShowlist(false)}
                  >
                    <i className="fa fa-arrow-circle-left"></i> back
                  </button>
                }
              </div>
            </Col>
          </div>
        </div>
        <section className="content  consti-section">
          <div className="container">
            {!Showlist ? (
              <div className="row">
                <Col md={12}>
                  <Card style={{ padding: "20px", height: 'fit-content' }}>
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={12}>
                        <Form.Label className="w-100">
                          Campaign Short Name{" "}
                        </Form.Label>
                        <input
                          className="w-100 form-control"
                          name="campaignShortName"
                          value={campaignShortname}
                          onChange={handleShortname}
                        ></input>{" "}
                        <div style={{ color: "red" }}>
                          {formError.campaignShortName}
                        </div>{" "}
                      </Col>{" "}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={12}>
                        <Form.Label className="w-100">
                          {" "}
                          Campaign Description{" "}
                        </Form.Label>{" "}
                        <textarea
                          className="w-100 form-control"
                          placeholder="Tell us more"
                          rows="3"
                          onChange={handleInput}
                          name="campaignDescription"
                          value={campaign}
                        ></textarea>{" "}
                        <div style={{ color: "red" }}>
                          {formError.campaignDescription}
                        </div>{" "}
                      </Col>{" "}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      controlId="formHorizontalEmail"
                    >
                      <Col
                        sm={6}
                        style={{
                          position: "relative",
                          // zIndex: "999",
                        }}
                      >
                        <Form.Label className="w-100"> Start Date </Form.Label>{" "}
                        <DatePicker
                          className="w-100 form-control"
                          selected={campstartDate}
                          name="campaignStartDate"
                          value={campstartDate}
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            setCampstartDate(date);
                            setFormError({ ...formError,campstartDate:""})
                          }}
                        />
                        <div style={{ color: "red" }}>
                          {formError.campstartDate}
                        </div>
                      </Col>
                      <Col
                        sm={6}
                        style={{
                          position: "relative",
                          // zIndex: "999",
                        }}
                      >
                        <Form.Label className="w-100"> End Date </Form.Label>{" "}
                        <DatePicker
                          className="w-100 form-control"
                          selected={campendDate}
                          name="campaignEndDate"
                          dateFormat="dd/MM/yyyy"
                          minDate={campstartDate}
                          // maxDate={new Date()}
                          showYearDropdown
                          yearDropdownItemNumber={50}
                          scrollableYearDropdown
                          onChange={(date) => {
                            setCampendDate(date);
                            setFormError({ ...formError,campendDate:""})
                          }}
                        />{" "}
                        <div style={{ color: "red" }}>
                          {formError.campendDate}
                        </div>{" "}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={12}>
                        <Form.Label className="w-100">
                          {" "}
                          Polling Campaign Level{" "}
                        </Form.Label>{" "}
                        <Select
                          className="w-100"
                          options={campaignleveloption}
                          onChange={handleLevel}
                          value={campaignLevelSelected}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <br/>
                        <div style={{ color: "red" }}>
                          {formError.campaignLevelSelected}
                        </div>{" "}
                      </Col>{" "}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={12}>
                        <Form.Label className="w-100"> County </Form.Label>{" "}
                        <MultiSelect
                          className="w-100"
                          options={countyOption}
                          value={countySelected}
                          onChange={setCountySelected}
                          labelledBy="Select"
                          disabled={countydisable}
                          // menuPosition="fixed"
                          // menuShouldBlockScroll="true"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        // style={{overflowY: "scroll"}}
                        />
                        {/* <div style={{ color: "red" }}>{formError.countySelected}</div>{" "} */}
                      </Col>{" "}
                    </Form.Group>{" "}
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={12}>
                        <Form.Label className="w-100">
                          {" "}
                          Product Category{" "}
                        </Form.Label>{" "}
                        <Select
                          className="w-100"
                          options={categoryselectOption}
                          onChange={handleCategory}
                          value={procategoryselected}
                          menuPortalTarget={document.body}
                          isDisabled={update}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <br/>
                        <div style={{ color: "red" }}>
                          {formError.procategoryselected}
                        </div>{" "}
                      </Col>{" "}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={12}>
                        <Form.Label className="w-100">
                          {" "}
                          Product List{" "}
                        </Form.Label>{" "}
                        <MultiSelect
                          className="w-100"
                          options={productselectOption}
                          value={productSelected}
                          onChange={handleProduct}
                          labelledBy="Select"
                          disabled={productDisable}
                        />
                        <br/>
                        <div style={{ color: "red" }}>
                          {formError.productSelected}
                        </div>
                      </Col>{" "}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={12}>
                        <Form.Label className="w-100"> Question </Form.Label>{" "}
                        <input
                          className="w-100 form-control"
                          name="question"
                          type="text"
                          value={Question}
                          onChange={changeQuestion}
                        ></input>{" "}
                        <div style={{ color: "red" }}>{formError.question}</div>{" "}
                      </Col>{" "}
                    </Form.Group>{" "}
                    <Form.Group
                      as={Row}
                      className="mb-3"
                    // controlId="formHorizontalEmail"
                    >
                      <Col sm={12}>
                        <Form.Label className="w-100">Campaign Image </Form.Label>{" "}
                        <Col md={6}>
                          <FormControl
                            className="m-2"
                            type="file"
                            name="file"
                            ref={inputRefrence}
                            onChange={handleimage}
                          />
                          <div className="imagepreview">
                            <img
                              alt="Please select "
                              width="80"
                              height="60"
                              src={update === false
                                ? campaignImgpath
                                : campaignImg.name === undefined
                                  ? PhotoPath + campaignImg
                                  : campaignImgpath}
                            />
                          </div>
                          <br/>
                          <div style={{ color: "red" }}>{formError.campaignImg}</div>
                        </Col>
                        <div style={{ color: "red" }}>{formError.County}</div>{" "}
                      </Col>{" "}
                    </Form.Group>{" "}

                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      controlId="formHorizontalEmail"
                    >
                      <Col
                        sm={6}
                        style={{
                          position: "relative",
                          // zIndex: "999",
                        }}
                      >
                        <Form.Label className="w-100"> Advertise Type </Form.Label>{" "}
                        <Select
                          className="w-100"
                          options={AdvertiseOption}
                          onChange={handleAdvertise}
                          value={AdvertiseSelected}
                          menuPortalTarget={document.body}
                          // isDisabled={update}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        {/* <div style={{ color: "red" }}>
                          {formError.campstartDate}
                        </div> */}
                      </Col>


                      <Col
                        sm={6}
                        style={{
                          position: "relative",
                          // zIndex: "999",
                        }}
                      >
                        {AdvertiseSelected.length === 0  ? null
                        :AdvertiseSelected.value === 1 ?(
                          <>
                          <Form.Label className="w-100"> Copy Link</Form.Label>
                        <input
                          className="w-100 form-control"
                          name="Link"
                          value={Link}
                          onChange={handleLink}
                        ></input>
                          </>
                         
                        ):
                        <>
                           
                      
                          <Form.Label className="w-100"> select Image/File</Form.Label>
                        <FormControl
                            className="m-2"
                            type="file"
                            name="file"
                            ref={inputRefrence2}
                            onChange={handleAdvertiseimage}
                          />
                          <div className="imagepreview">
                            <img
                              alt="Please select "
                              width="80"
                              height="60"
                              src={update === false
                                ? AdvertiseImgpath
                                : AdvertiseImg.name === undefined
                                  ? advertisePhoto + AdvertiseImg
                                  : AdvertiseImgpath}
                            />
                          </div>
                      
                       
                      </>
                    
                       
                      }  
                      </Col>  
                     
                          
                    </Form.Group>
                   
                  
                    <div className="button_style m-0 text-center">
                      {update === false ? (
                        <Button
                          type="submit"
                          variant='contained'
                          onClick={saveData}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          variant='contained'
                          color='success'
                          onClick={() => updateClick()}
                        >
                          Update
                        </Button>
                      )}
                      {" "}{" "}
                      <Button
                        type="submit"
                        variant='contained'
                        color='error'
                        onClick={cancelUser}
                      >
                        Cancel
                      </Button>
                    </div>{" "}
                    <br /> <br /> <br /><br /><br />
                  </Card>
                </Col>{" "}
              </div>
            ) :
              <>
                <br />
                <br />
                <br />
                <Col className='mt-0' md={12}>
                  <MaterialTable
                    title={false}
                    data={pollData}
                    columns={columns}
                    actions={[
                      {
                        icon: "edit",
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                          // setFormError("")
                          updateUser(rowData.campaignId);
                        },
                      },
                      {
                        icon: "delete",
                        tooltip: "Delete",
                        onClick: (event, rowData) => {
                          deleteUser(rowData.campaignId);
                        },
                      },
                    ]}
                    options={{
                      search: true,
                      pageSize: 10,
                      showFirstLastPageButtons: false,
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      pageSizeOptions: [10, 15, 20, 50],
                    }}
                  />
                </Col>
              </>
            }
          </div>{" "}
        </section>{" "}
      </div>{" "}
      <br /> <br />
    </div>
  );
};
export default ProductCampaign;