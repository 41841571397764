import React, { useState, useEffect } from "react";
import Select from "react-select";
import {Container, Form, Row, Col, Card, Button, FormControl } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import {
  getCountyData,
  ConstituencygetbyCounty,
  getWardbyConstituency,
  editUser,
  getmemeberdetails, Agelist, occupationlist, Educationlist,NoAgelist,NoOccupationlist,NoEducationlist,NocountyList
} from "../Admin/ProductServiceAPI";
import Auth from "../../ServerCalls/Auth";
import ProductURL from '../../ServerCalls/ProductServer';
import { useNavigate } from "react-router-dom";
import './userinfo.css';

const UserInfo = () => {
  const auth = Auth.getUserId();
  const [user, setUser] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Address: "",
    Gender: "",
    PinCode: "",
    ContactNumber: "",
  });

  const [CountySelected, setCountySelected] = useState([]);
  const [ConstiSelected, setConstiSelected] = useState([]);
  const [WardSelected, setWardSelected] = useState([]);
  const [CountyOption, setCountyOption] = useState([]);
  const [ConstiOption, setConstiOption] = useState([]);
  const [occupationSelected, setOccupationSelected] = useState([]);
  const [EducationSelected, setEducationSelected] = useState([]);
  const [AgeSelected,setAgeSelected] = useState([]);
  const [occupationOption, setOccupationOption] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [educationoptions, setEducationoptions] = useState([]);
  const [WardOption, setWardOption] = useState([]);
  const [Nid, setNid] = useState([]);
  const [Birth, setBirth] = useState(null);
  const [userimg, setuserimg] = useState("");
  const [userimgPath, setuserimgPath] = useState("");
  const navigate = useNavigate();
  const PhotoPath = ProductURL.photoURL + "MemberMasterImages/";



  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    if (e.target.name === "FirstName") {
      setFirstNameErr("");
    } 
     else if (e.target.name === "Age") {
      setageError("");
    } else if (e.target.name === "Gender") {
      setgenderError("");
    
    } else if (e.target.name === "ContactNumber") {
      setContactErr("");
    }
  };

  useEffect(() => {
    getUserData();
    CountyData();
    ages();
    occupation();
    education();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleimage = (e) => {
    setuserimg(e.target.files[0]);
    setuserimgPath(URL.createObjectURL(e.target.files[0]));
  };

  const image_upload = async (data) => {
    axios.post("http://5.189.157.40:10049/api/ProductManagement/MemberMaster/SaveFile", data)
      .then((res) => {
        console.log("photo res", res);
      })
      .catch((err) => {
        console.log(err, "err in photo submit");
      });
  };


  const getUserData = () => {

   


    getmemeberdetails(auth).then((response) => {
      const userDetails = response.data._response.data.member[0];
      setUser({
        FirstName: userDetails.firstname,
        MiddleName: userDetails.middlename,
        LastName: userDetails.lastname,
        Address: userDetails.add1,
        Gender: userDetails.gender,
        PinCode: userDetails.pincode,
        ContactNumber: userDetails.contactno1,
      });
      setCountySelected({
        value: userDetails.countyid,
        label: userDetails.countyname,
      });
      setConstiSelected({
        value: userDetails.constituencyid,
        label: userDetails.constituencyname,
      });
      setWardSelected({
        value: userDetails.wardId,
        label: userDetails.wardname,
      });

      setBirth(new Date(userDetails.dob));

      setNid(userDetails.nid);
      setAgeSelected({
        value:userDetails.age,
        label:userDetails.agename
      })
      setOccupationSelected({
        value:userDetails.ocuupationid,
        label:userDetails.occupation
      })
      setEducationSelected({
        value:userDetails.educationlevelid,
        label:userDetails.educationlevel
      })
       

     if(userDetails.photofilename !== undefined && userDetails.photofilename !== null){
      setuserimg(userDetails.photofilename);

     }else{
      setuserimg("");
     }

     
    })
    .catch((err)=>{
      console.log(err,'err in get userinfo');
    })
  };

  const [FirstNameErr, setFirstNameErr] = useState("");

  const [ageError, setageError] = useState("");
  const [genderError, setgenderError] = useState("");

  const [ContactErr, setContactErr] = useState("");
  const [CountyErr, setCountyErr] = useState("");
  const [ConstiErr, setConstiErr] = useState("");
  const [WardErr, setWardErr] = useState("");
  const [OccupationErr,setOccupationErr] =useState("");
  const [EducationErr,setEducationErr] = useState("");

  const formValidate = () => {
    let FirstNameErr = "";
    let ageError = "";
    let genderError = "";
    let ContactErr = "";
    let CountyErr = "";
    let ConstiErr = "";
    let WardErr = "";
    let regex = /^[a-z]+$/i;


    if (user.FirstName.length > 15) {
      FirstNameErr = "FirstName cannot exceed 15 characters";
    }

    if (regex.test(user.FirstName) === false) {
      FirstNameErr = "Please enter alphabetic characters and no spaces";
    }

    if (user.FirstName === "") {
      FirstNameErr = "Please enter Your FirstName";
    }

    

    if (user.Age === "") {
      ageError = "Please enter Your Age";
    }

  
    if (user.ContactNumber === "") {
      ContactErr = "Please enter Your Contact";
    }

   

    if (user.Gender === "") {
      genderError = "Please enter Your Gender";
    }
    if (CountySelected.length < 1) {
      CountyErr = "Please select County";
    }

    if (ConstiSelected.length < 1) {
      ConstiErr = "Please select Constituency";
    }
    if (WardSelected.length < 1) {
      WardErr = "Please select Ward";
    }
    if (
      FirstNameErr ||
      ageError ||
      genderError ||
      CountyErr ||
      ConstiErr ||
      WardErr ||
      ContactErr
    ) {
      setFirstNameErr(FirstNameErr);
      setContactErr(ContactErr);
      setageError(ageError);
      setCountyErr(CountyErr);
      setConstiErr(ConstiErr);
      setWardErr(WardErr);
      setgenderError(genderError);

      return false;
    }
    return true;
  };

  const CountyData = () => {
    NocountyList()
      .then((res) => {
        let county = res.data._response.data.getcountywithnocounty;
        let countylist = county.map((a) => ({
          value: a.countyId,
          label: a.countyName,
        }));
        setCountyOption(countylist);
      })
      .catch((err)=>{
        console.log(err,'err in get county');
      })
  };

  const handlecountyclear = () => {
    setWardOption([]);
    setConstiOption([]);
    setConstiSelected([]);
    setWardSelected([]);
  };

  const handleCounty = (selected) => {
    setCountySelected(selected);
    ConstituencygetbyCounty(selected.value)

      .then((response) => {
        if (response.data._response.status === 1) {
          const consti = response.data._response.data.constibycountyid;
          if (consti.length !== 0) {
            const constiData = consti.map((a) => ({
              value: a.constituencyId,
              label: a.constituencyName,
            }));
            setConstiOption(constiData);
            setConstiSelected([]);
            setWardSelected([]);
            setWardOption([]);
          } else {
            handlecountyclear();
          }
        }
      })
      .catch((err) => {
        console.log(err);
        handlecountyclear();
      });
    setCountyErr("");
  };

  const handleConsti = (selected) => {
    setConstiSelected(selected);
    getWardbyConstituency(selected.value)
      .then((response) => {
        const ward = response.data._response.data.wardgetconstituencyid;
        const wardData = ward.map((a) => ({
          value: a.wardId,
          label: a.wardName,
        }));
        setWardOption(wardData);
      })
      .catch((err)=>{
        console.log(err,'err in get consti');
      })
    setConstiErr("");
  };
  const handleAge = (selected)=> {

    setAgeSelected(selected)
    setageError("")

  }
  const handleEducation = (selected) => {
    setEducationSelected(selected)
    setEducationErr("")
  }
  const handleOccupation = (selected) => {
    setOccupationSelected(selected)
    setOccupationErr("")
  }

  const ages = () => {
    NoAgelist().then((res) => {
      if (res.data._response.status === 1) {     
        let age = res.data._response.data.getages
        let option = age.map((a) => ({
          value: a.ageId,
          label: a.ageDropdown
        }))
        setAgeOptions(option)
      }
    })
    .catch((err)=>{
      console.log(err,'err in get agelist');
    })
  }

  const occupation = () => {
    NoOccupationlist().then((res) => {
      if (res.data._response.status === 1) {
        let occupations = res.data._response.data.getoccupation
        let option = occupations.map((a) => ({
          value: a.occupationId,
          label: a.occupationDropdown
        }))
        setOccupationOption(option)
      }
    })
    .catch((err)=>{
      console.log(err,'err in get occupation');
    })
  }


  const education = () => {
    NoEducationlist().then((res) => {
      if (res.data._response.status === 1) {
        let educations = res.data._response.data.geteducation
        let option = educations.map((a) => ({
          value: a.educationId,
          label: a.educationDropdown
        }))
        setEducationoptions(option)
      }
    })
    .catch((err)=>{
      console.log(err, 'err in get education');
    })
  }

  const Updateuser = async () => {

    const data = {
      MemberId: auth,
      FirstName: user.FirstName,
      Age: parseInt(AgeSelected.value),
      Gender: user.Gender,
      ContactNo1: user.ContactNumber,
      CountyId: CountySelected.value,
      ConstituencyId: ConstiSelected.value,
      Occupation:parseInt(occupationSelected.value),
      EducationLevel:parseInt(EducationSelected.value),
      photofilename: userimg.name === undefined ? userimg : userimg.name,
      WardId: WardSelected.value,
      Role: 2,
    };

    let formdata = new FormData();
    formdata.append("file", userimg);
    if (userimg.name !== undefined) {
      await image_upload(formdata);
    }

    editUser(data)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Profile Updated Successfully",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
        navigate('/UserDetails');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleWard = (selected) => {
    setWardSelected(selected);
    setWardErr("");
  };

  const handleReset = () => {
    setUser({
      FirstName: "",
      Age: "",
      Gender: "",
      // Password: "",
      // Email: "",
      ContactNumber: "",
      // ConfirmPassword: "",
      ProfileImg: "",
    });
    setCountySelected("");
    setConstiSelected("");
    setWardSelected([]);
    setFirstNameErr("");
    setageError("");
    setgenderError("");
    setCountyErr("");
    setConstiErr("");
    setWardErr("");
  };

  return (
    <div>
      <div className="">
        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12"></div>
                </div>
              </div>
            </div>
            <section className="content">
              <div className="container">
                <div className="row justify-content-center">

                  <h1 className="mb-4 m-0 text-center update-Heading">UPDATE PROFILE</h1>

                  {/* <Col md={12} className="d-flex justify-content-center mb-2 ml-2"> */}
                    <Card className="" style={{ padding: "20px", marginBottom: "6px" }}>
                     <Card.Body>
                     <div >
                        <Form.Group
                          as={Row}
                          className="mb-3 justify-content-center"
                          controlId="formHorizontalEmail"
                        >
                          <Col sm={6}>
                            <Form.Label className="w-100">
                               Name
                            </Form.Label>

                            <Form.Control
                              className="w-100"
                              type="text"
                              value={user.FirstName}
                              name="FirstName"
                              onChange={handleChange}
                            />
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {FirstNameErr}
                            </div>
                          </Col>
                          <Col sm={6}>
                            <Form.Label className="w-100">Age</Form.Label>

                            <Select
                              className="w-100"
                              options={ageOptions}
                              onChange={handleAge}
                              value={AgeSelected}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {ageError}
                            </div>
                          </Col>
                        </Form.Group>

                   

                        <Form.Group
                          as={Row}
                          className="mb-3 justify-content-center"
                          controlId="formHorizontalEmail"
                        >

                          <Col sm={6}>
                            <Form.Label className="w-100">Gender</Form.Label>

                            <Form.Control
                              className="w-100"
                              type="text"
                              value={user.Gender}
                              name="Gender"
                              onChange={handleChange}
                              disabled='true'
                            />
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {genderError}
                            </div>
                          </Col>
                          <Col sm={6}>
                            <Form.Label className="w-100">
                              Contact Number
                            </Form.Label>

                            <Form.Control
                              className="w-100"
                              type="number"
                              value={user.ContactNumber}
                              name="ContactNumber"
                              onChange={handleChange}
                            />
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {ContactErr}
                            </div>
                          </Col>
                        </Form.Group>

                       

                        <Form.Group
                          as={Row}
                          className="mb-3 justify-content-center"
                          controlId="formHorizontalEmail"
                        >
                          <Col sm={6}>
                            <Form.Label className="w-100">County</Form.Label>
                            <Select
                              className="w-100"
                              options={CountyOption}
                              onChange={handleCounty}
                              value={CountySelected}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {CountyErr}
                            </div>
                          </Col>
                          <Col sm={6}>
                            <Form.Label className="w-100">
                              Constituency
                            </Form.Label>

                            <Select
                              className="w-100"
                              options={ConstiOption}
                              onChange={handleConsti}
                              value={ConstiSelected}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {ConstiErr}
                            </div>
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3 justify-content-center"
                          controlId="formHorizontalEmail"
                        >
                          <Col sm={12}>
                            <Form.Label className="w-100">Ward</Form.Label>

                            <Select
                              className="w-100"
                              options={WardOption}
                              onChange={handleWard}
                              value={WardSelected}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {WardErr}
                            </div>
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3 justify-content-center"
                          controlId="formHorizontalEmail"
                        >
                        <Col sm={6}>
                            <Form.Label className="w-100">
                              Occupation
                            </Form.Label>

                            <Select
                              className="w-100"
                              options={occupationOption}
                              onChange={handleOccupation}
                              value={occupationSelected}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {OccupationErr}
                            </div>
                          </Col>
                          <Col sm={6}>
                          <Form.Label className="w-100">Education Level</Form.Label>

                          <Select
                            className="w-100"
                            options={educationoptions}
                            onChange={handleEducation}
                            value={EducationSelected}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                          />
                          <div style={{ color: "red", fontSize: "15px" }}>
                            {EducationErr}
                          </div>
                        </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3 justify-content-center"
                          controlId="formHorizontalEmail"
                        >
                    
                        </Form.Group>

                        <Col sm={12} className="mb-4">
                          <Form.Label className="w-100"> User Image</Form.Label>
                          <Col md={6}>
                            <div className="imagepreview">
                              <img
                                alt="Please select"
                                width="80"
                                height="60"
                                src={
                                  userimg.name === undefined
                                    ? PhotoPath + userimg
                                    : userimgPath
                                }
                              />
                            </div>
                          </Col>
                          <FormControl
                            className="m-2"
                            type="file"
                            name="file"
                            // ref={inputRefrence4}
                            onChange={handleimage}
                          />
                          <div style={{ color: "red" }}>
                            {/* {formError.productImage1} */}
                          </div>
                        </Col>


                        <Button
                          type="submit"
                          className="detailsbtn"
                          onClick={Updateuser}
                        >
                          Update
                        </Button>

                        <br />
                      </div>
                     </Card.Body>
                    </Card>
                  {/* </Col> */}
                </div>
                <br/>
                
              </div>
            </section>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default UserInfo;
