import React from "react";
import { Routes, Route } from "react-router-dom";

import Parameters from "./Admin/Parameters";
import Brand from "./BrandOwner/Brand";
import ParameterValuetype from "./Admin/ParameterValuetype";
import ProductCampaignLevel from "./Admin/ProductCampaiganLevel";
import ParametersCategory from "./Admin/ParametersCategory";
import ProductCategory from "./Admin/ProductCategory";
import ProductParameters from "./Admin/ProductParameters";
import PrivateComp from "./Login/PrivateComponent";
import Menu from "./Admin/Menu";
import Role from "./Admin/Role";
import Product from "./Admin/Product";
import ProductCampaign from "./Admin/ProductCampaign";
import RoleMenu from "./Admin/RoleMenu";
import ProductPolling from "./Citizen/ProductPolling";
import Addpoll from "./Citizen/Addpoll";
import PrivateAdminComponent from "./Login/PrivateAdminComponent";
import County from "./Regions/County";
import Constituency from "./Regions/Constituency";
import Ward from "./Regions/Ward";
import ProductGallery from "./Admin/ProductGallery";
import Feedback from "./Citizen/Feedback";
import VotingReports from "./Admin/Reports/VotingReports";
import PollingReports from "./Admin/Reports/PollingReports";
import FeedbackReply from "./Admin/FeedbackReply";
import News from "./Admin/News";
import ProductCategory1 from "./Citizen/ProductCategory1";
import ProductDetails from '../common/product/ProductDetails';
import BrandPrivateComp from "./Login/BrandPrivateComp";
import BrandInfo from './Admin/BrandInfo';
import UserInfo from './Admin/UserInfo';
import UserDetails from './Admin/UserDetails';
import BrandDetails from './Admin/BrandDetails';
import Memberadmin from './Admin/Memberadminregistration'
import Summary from './Admin/ReportSummary';
import ViewDetails from './Admin/Reports/ViewDetails';
import ViewPollingDetails from './Admin/Reports/ViewPollingDetails';
import Voting from "./Citizen/Voting";
import CampaignList from "./Citizen/CampaignList";
import AllCategories from "./Citizen/AllCategories";
import BrandGallery from "./BrandOwner/BrandGallery";
import BrandGalleryByCategories from "./BrandOwner/BrandGalleryByCategories";
import FeedbackHistory from "./Citizen/FeedbackHistory";
import AddProduct from "./BrandOwner/AddProduct";
import AddNews from "./BrandOwner/AddNews";
import { useStyles } from '../../static/MiniDrawerStyles';
import clsx from 'clsx';
import CelebrityCategory from "./Admin/CelebrityPages/CelebrityCategory";
import CelbrityAwardMaster from "./Admin/CelebrityPages/CelbrityAwardMaster";
import CelebrityRegister from "./Admin/CelebrityPages/CelebrityRegister";
import ReleaseCategory from "./Admin/CelebrityPages/ReleaseCategory";
import SocialMediaPlatforms from "./Admin/CelebrityPages/SocialMediaPlatforms";
import MediaRelease from "./Admin/CelebrityPages/MediaRelease";
import CelebrityCampaignPage from "./Admin/CelebrityPages/CelebrityCampaign";
import CelebrityCampaignLists from './Citizen/Celebrity/CelebrityCampaignList';
import CelebrityVoting from './Citizen/Celebrity/CelebrityVoting';
import CelebrityGallery from './Citizen/Celebrity/CelebrityGallery';
import CelebrityDetails from './Citizen/Celebrity/CelebrityDetails';
import CelebrityReport from './Admin/CelebrityPages/CelebrityReport'
import AgeReport from './Admin/Reports/Agereport';
import GenderReport from './Admin/Reports/Genderreport';
import CountyReport from './Admin/Reports/Countyreport';
import OccupationReport from './Admin/Reports/Occupationreport';
import EducationReport from './Admin/Reports/Educationreport';
import AddMagzines from './Admin/AddMagzines';
import Generalnews from './Admin/GeneralNews';
import BrandVideo from './Admin/BrandVideo';
import VideoCampaign from './Admin/VideoCampaign';
import VideoCampaignList from './Citizen/VideoCampaignList';
import VideoVoting from './Citizen/VideoVoting';

import CelebrityAgeReport from './Admin/CelebrityPages/CelebrityAgeReport';
import CelebrityGenderReport from './Admin/CelebrityPages/CelebrityGenderReport';
import CelebrityCountyReport from './Admin/CelebrityPages/CelebrityCountyReport';
import CelebrityOccupationReport from './Admin/CelebrityPages/CelebrityOccupationReport';
import CelebrityEducationReport from './Admin/CelebrityPages/CelebrityEducationReport'; 
import Rankingdata from './Admin/Rankingdata';
import GeneralNews from "./Admin/GeneralNews";
import UserDetailsloginWithGoogle from "./Citizen/UserDetailsloginWithGoogle";
import AdminDashboard from './Admin/AdminPage';
import ContactUs from './Admin/ContactUsInfo';

const ContentRoutes = (props) => {
  const { content, contentShift } = useStyles();
  const { openSideBar } = props;


  return (


    <main className={clsx(content, {
      [contentShift]: openSideBar,
    })}>

      <Routes>
        <Route element={<BrandPrivateComp />}>

          <Route path="/BrandGallery" element={<BrandGallery />} />
          <Route path="/brandproduct-details/:id" element={<ProductDetails />} />
          <Route path="/brandfeedback/:id" element={<Feedback />} />
          <Route path='/feedbackReply' element={<FeedbackReply />} />
          <Route path='/BrandDetails' element={<BrandDetails />} />
          <Route path="/brandInfo/:id" element={<BrandInfo />} />
          <Route path="/categorylist" element={<AllCategories />} />
          <Route path="/BrandGallery/:id" element={<BrandGalleryByCategories />} />
          <Route path='/BrandAddProduct' element={<AddProduct />} />
          <Route path="/addnews" element={<AddNews />} />

        </Route>

        <Route element={<PrivateComp />}>

          <Route path="/productVoting" element={<Voting />} />
          <Route path="/campaignList" element={<CampaignList />} />
          <Route path="/Video_Campaign_List" element={<VideoCampaignList />} />
          <Route path="/Video_Voting" element={<VideoVoting />} />
          <Route path="/ProductPolling" element={<ProductPolling />} />
          <Route path="/Addpoll" element={<Addpoll/>} />
          <Route path="/feedback/:id/:productname" element={<Feedback />} />
          <Route path="/allCategories" element={<AllCategories />} />
          <Route path="/UserProductGallery" element={<ProductGallery />} />
          <Route path='/product-details/:id' element={<ProductDetails />} />
          <Route path="/ProductGallery/:id" element={<ProductCategory1 />} />
          <Route path="/userAllfeedback" element={<FeedbackHistory />} />
          <Route path="/UserProfile/:id" element={<UserInfo />} />
          <Route path="/UserDetails" element={<UserDetails />} />
          <Route path="/userdetailswithGoogle" element={<UserDetailsloginWithGoogle />} />
          <Route path="/CelebrityCampaignList" element = {<CelebrityCampaignLists/>} />
          <Route path ="/CelebrityVoting" element={<CelebrityVoting/>}/>
          <Route path="/CelebrityGallery" element={<CelebrityGallery/>}/>
          <Route path='/Celebrity-details/:id' element={<CelebrityDetails/>}/>

        </Route>

        <Route element={<PrivateAdminComponent />}>

          <Route path='/parameters' element={<Parameters />} />
          <Route path='/brand' element={<Brand />} />
          <Route path='/parametervalues' element={<ParameterValuetype />} />
          <Route path='/campaignLevel' element={<ProductCampaignLevel />} />
          <Route path='/productparameters' element={<ProductParameters />} />
          <Route path='/parameters' element={<Parameters />} />
          <Route path='/Menu' element={<Menu />} />
          <Route path='/Role' element={<Role />} />
          <Route path='/Product' element={<Product />} />
          <Route path='/ProductCategory' element={<ProductCategory />} />
          <Route path='/ProductCampaign' element={<ProductCampaign />} />
          <Route path='/RoleMenu' element={<RoleMenu />} />
          <Route path='/parametersSelection' element={< ParametersCategory />} />
          <Route path='/county' element={<County />} />
          <Route path='/constituency' element={<Constituency />} />
          <Route path='/ward' element={<Ward />} />
          <Route path='/votingReport' element={<VotingReports />} />
          <Route path='/pollingReport' element={<PollingReports />} />
          <Route path='/ViewVotingDetails' element={<ViewDetails />} />
          <Route path='/ViewpollingDetails' element={<ViewPollingDetails />} />
          <Route path='/News' element={<News />} />
          <Route path='/MemberRegistration' element={<Memberadmin />} />
          <Route path='/ReportSummary' element={<Summary />} />
          <Route path ="/AgewiseReport" element={<AgeReport/>}></Route>
          <Route path ="/GenderwiseReport" element={<GenderReport/>}></Route>
          <Route path ="/CountywiseReport" element={<CountyReport/>}></Route>
          <Route path ="/OccupationwiseReport" element={<OccupationReport/>}></Route>
          <Route path ="/EducationwiseReport" element={<EducationReport/>}></Route>
          <Route path ="/addmagzine" element={<AddMagzines/>}></Route>
          <Route path ="/General_news" element={<GeneralNews/>}></Route>
          <Route path="/Admindashboard" element={<AdminDashboard/>}></Route>
          <Route path= "/ContactUs_List" element={<ContactUs/>}></Route>
          <Route path= "/Brand_Video" element={<BrandVideo/>}></Route>
          <Route path= "/Video_Campaign" element={<VideoCampaign/>}></Route>




          <Route path="/celebrityCategory" element = {<CelebrityCategory/>} />
          <Route path="/AwardMaster" element = {<CelbrityAwardMaster/>} />
          <Route path="/AddCelebrity" element = {<CelebrityRegister/>} />
          <Route path="/RelaseCategory" element = {<ReleaseCategory/>} />
          <Route path="/Socialplatforms" element = {<SocialMediaPlatforms/>} />
          <Route path="/mediarelease" element = {<MediaRelease/>} />
          <Route path="/CelebrityCampaign" element = {<CelebrityCampaignPage/>} />
          <Route path="/CelebrityReport" element={<CelebrityReport/>}/>
          <Route path="/AgewiseCelebrityReport" element={<CelebrityAgeReport/>}/>
          <Route path="/GenderwiseCelebrityReport" element={<CelebrityGenderReport/>}/>
          <Route path="/CountywiseCelebrityReport" element={<CelebrityCountyReport/>}/>
          <Route path="/OccupationwiseCelebrityReport" element={<CelebrityOccupationReport/>}/>
          <Route path="/EducationwiseCelebrityReport" element={<CelebrityEducationReport/>}/>
        


          {/* <Route path='/about-us' element={<AboutUs />} />

          <Route path="/about-us/aim" element={<OurAim />} />
          <Route path="/about-us/vision" element={<OurVision />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/services1" element={<ServicesOne />} />
          <Route path="/services/services2" element={<ServicesTwo />} />
          <Route path="/services/services3" element={<ServicesThree />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/events1" element={<EventsOne />} />
          <Route path="/events/events2" element={<EventsTwo />} />
          <Route path="/support" element={<Support />} /> */}
        </Route>

        {/* <Route path ='*' element={<Navigate to='/' replace />} /> */}
      </Routes>
    </main>




  );
};

export default ContentRoutes;
