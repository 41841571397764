import React, { useState, useEffect } from "react";
import { Form, Row, Col, Card } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import MaterialTable from "material-table";
import {
  postCategory,
  CategoryList,
  editCategory,
  deleteCategory,
  Categorywithnoparent
} from "./ProductServiceAPI";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import axios from "../../ServerCalls/AxiosInstance";


const ProductCategory = () => {
  const columns = [
    {
      title: " Product Category Name ",
      field: "categoryName",
      align: "center",
    },
    {
      title: "Parent Product Category Name ",
      field: "parentcategoryname",
      align: "center",
    },
  ];
  const [update, setupdate] = useState(false);

  const [productCategoryName, setproductCategoryName] = useState("");
  const [categoryid, setcategoryid] = useState(0);
  const [parentcategorySelected, setParentcategorySelected] = useState([]);
  const [parentcategoryOption, setParentcategoryOption] = useState([]);
  const [list, setList] = useState([]);
  const [formError, setformError] = useState([]);

  const validates = () => {
    let formError = {};
    let isValid = true;
    let regex = /^[A-Za-z0-9 ]+$/;

    if (productCategoryName === "") {
      isValid = false;
      formError["productCategoryName"] = "Please Enter Product Category Name";
    } else if (
      productCategoryName.length < 3 ||
      regex.test(productCategoryName) === false
    ) {
      isValid = false;
      formError["productCategoryName"] =
        "Please Enter Minimun 3 Characters and do not use special Character";
    }
    else if(productCategoryName.length > 200)
    {
      isValid = false;
      formError["productCategoryName"] = "Maximum 200 characters are allowed"
    }
    if (parentcategorySelected.length < 1) {
      isValid = false;
      formError["parentcategorySelected"] =
        "Please Select Product Category";
    }
    setformError(formError);
    return isValid;
  };
  const navigate = useNavigate();

  const saveData = () => {
    const isvalid = validates();
    if (isvalid) {
      let data = {
        categoryName: productCategoryName,
        parentCategoryId: parentcategorySelected.value,
      };

      postCategory(data)
        .then((res) => {
          if (res.data._response.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Category Added Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            getList();
            cancelUser();
          } else {
            Swal.fire({
              icon: "Category Not Added",
              title: res.data._response.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          } // getBrandList("")
        })
        .catch((err) => {
          console.log(err, "err in add category");
        });
    }
  };

  useEffect(() => {
    getList();
    getProductCategory();
  }, []);

  const getList = () => {
    Categorywithnoparent()
      .then((response) => {
        setList(response.data._response.data.getprocategory);
      })
      .catch((err) => {
        console.log(err, "err in get");
      });
  };

  const getProductCategory = () => {
    CategoryList()
      .then((res) => {
        let list = res.data._response.data.getprocategory;
        const options = list.map((a) => ({
          value: a.categoryid,
          label: a.categoryName,
        }));
        setParentcategoryOption(options);
      })
      .catch((err) => {
        console.log(err, "err in categorylist");
      });
  };

  const updateUser = (a) => {
    setupdate(true);
    setcategoryid(a.categoryid);
    setproductCategoryName(a.categoryName);
    setParentcategorySelected({
      value: a.parentcategoryid,
      label: a.parentcategoryname,
    });
  };

  const updateClick = () => {
    const data = {
      categoryId: categoryid,
      categoryName: productCategoryName,
      parentcategoryid: parentcategorySelected.value,
    };
    editCategory(data)
      .then((res) => {
        if (res.data._response.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Category Updated Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
          getList();
          cancelUser();
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Category Not Updated",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      });
  };

  const deleteUser = (catId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {

            deleteCategory(catId)
          // axios.delete(`https://localhost:5002/api/ProductManagement/RemoveProductCategory/${catId}`)
            .then((res) => {
                if (res.data._response.status === 1) {
              Swal.fire({
                icon: "success",
                title: "Category Deleted Sucessfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              getList();
            }else if(res.data._response.status === 2){

              Swal.fire({
                icon: "warning",
                title: res.data._response.message,
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
            });


        }

    })
   
}
})
      .catch((err) => {
        console.log(err, "err in trash category");
      });
  };

  const handlecategory = (selected) => {
    setParentcategorySelected(selected);
    setformError({ ...formError, parentcategorySelected: "" });
  };

  const ViewCategoryParamters = (e) => {
    navigate("/parametersSelection", { state: e });
  };

  const cancelUser = () => {
    setproductCategoryName("");
    setParentcategorySelected("");
    setupdate(false);
    setformError("");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setproductCategoryName(value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''));
    setformError({ ...formError, productCategoryName: "" });
  };

  return (
    <>
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
            <br/><br/>
              <div className="row mb-2">
                <div className="col-sm-12">
                  {!update?(
                  <h1 className="m-0 text-center">PRODUCT CATEGORY</h1>
  ):
                  <h1 className="m-0 text-center">EDIT PRODUCT CATEGORY</h1>
                }
                </div>
              </div>
            </div>
          </div>
          <section className="content consti-section">
            <div className="container">
              <div className="row">
                <Col md={12} >
                  <Card style={{ padding: "20px"}}>
                    {/* {!isEditable ? ( */}
                    <>
                      <Form.Group
                        as={Row}
                        className="justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={6} className="mb-4">
                          <Form.Label className="w-100 pb-1">
                            Category Title
                          </Form.Label>
                          <input
                            className="w-100 form-control"
                            type="text"
                            name="productCategoryName"
                            placeholder="Enter your product category name"
                            value={productCategoryName}
                            onChange={handleInput}
                          />
                           <br/>
                          <div style={{ color: "red",fontSize: 13 }}>
                            {formError.productCategoryName}
                          </div>
                        </Col>

                        <Col sm={6} className="mb-4">
                          <Form.Label className="w-100 pb-1">
                            Parent Category Title
                          </Form.Label>
                          <Select
                            className="w-100"
                            options={parentcategoryOption}
                            onChange={handlecategory}
                            value={parentcategorySelected}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                          <br/>

                          <div style={{ color: "red",fontSize: 13 }}>
                            {formError.parentcategorySelected}
                          </div>
                        </Col>
                      </Form.Group>
                      <div className="button_style m-0 text-center">
                        {update == false ? (
                          <Button
                            type="button"
                            variant='contained'
                            onClick={() => saveData()}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            variant='contained'
                            color='success'
                            onClick={() => updateClick()}
                          >
                            Update
                          </Button>
                        )} {" "}
                        <Button
                          type="button"
                          variant='contained'
                          color="error"
                          onClick={() => cancelUser()}
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  </Card>
                </Col>

              <Col className='mt-4' md={12} >
                <MaterialTable
                  title=''
                  data={list}
                  columns={columns}
                  actions={[
                    {
                      icon: "visibility",
                      tooltip: "View Parameters",
                      onClick: (evt, selectedRow) =>
                        ViewCategoryParamters(selectedRow),
                    },
                    {
                      icon: "edit",
                      tooltip: "Edit",
                      onClick: (event, rowData) => {
                        updateUser(rowData);
                      },
                    },

                    {
                      icon: "delete",
                      tooltip: "Delete",
                      onClick: (event, rowData) => {
                        deleteUser(rowData.categoryid);
                      },
                    },
                  ]}
                  options={{
                    search: true,
                    pageSize: 10,
                    showFirstLastPageButtons: false,
                    actionsColumnIndex: -1,
                    addRowPosition: "first",
                    pageSizeOptions: [10, 15, 20, 50],
                  }}
                />
              </Col>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ProductCategory;
