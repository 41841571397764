

import "../../common/product/ProductDetails.css";
import React, { useState, useEffect } from "react";
import ProductURL from "../../ServerCalls/ProductServer";
import { Container, Card, Row, Col ,Modal} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getsingleCelebrity } from '../../pages/Admin/CelebrityPages/CelebrityServiceAPI';
import axios from 'axios';

// import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PhotoPath = ProductURL.photoURL + "CelebrityImages/";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green" }}
                                                         onClick={onClick}
        />
    );
}


const CelebrityDetails = () => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const { id } = useParams();
    const [proName, setproName] = useState([]);
    const [proCategory, setproCategory] = useState([]);
    const [proImage1, setproImage1] = useState([]);
    const [proImage2, setproImage2] = useState([]);
    const [proImage3, setproImage3] = useState([]);
    const [proImage4, setproImage4] = useState([]);
    const [proImage5, setproImage5] = useState([]);
    const [awards, setAwards] = useState([]);
    const [socialLink, setSocialLink] = useState([]);
    const [mediarelease, setMediarelease] = useState([]);
    const [categoryid, setCategoryid] = useState(0);
    const [showB, setShowB] = useState(false);
    const [previewImg, setPreviewImg] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
        getProductDetails();

    }, []);




    const getProductDetails = () => {
         axios.get(`https://api.topbrandsranking.com/ProductManagement/CelebrityGetById/${id}`).then((res) => {
        // getsingleCelebrity(id).then((res) => {
            let data = res.data._response.data.celebritygetbyid;
            let award = res.data._response.data.celebrityawardsrel;
            let social = res.data._response.data.celebritysocialrel
            let media = res.data._response.data.mediarelease

            let awardDetails = award && award.map((a) => ({
                value: a.awardname,
                name: a.awardtitle

            }))

            let sociallink = social && social.map((b) => ({

                value: b.socialmediaplatformname,
                name: b.profilelink
            }))

            let mediarelease = media && media.map((c) => ({
                value: c.mediareleasecategoryname,
                name: c.mediareleasename

            }))

            setproName(data[0].realname);
            // setproBrand(data[0].brandname);
            setproCategory(data[0].celebritycategoryname);
            setproImage1(data[0].celebrityimage1);
            setproImage2(data[0].celebrityimage2);
            setproImage3(data[0].celebrityimage3);
            setproImage4(data[0].celebrityimage4);
            setproImage5(data[0].celebrityimage5);
            setCategoryid(data[0].categoryid);
            setAwards(awardDetails);
            setSocialLink(sociallink);
            setMediarelease(mediarelease);

        }).catch((err) => {
            console.log(err, "err in product get")
        })
    };


    const openModal = (imgurl) => {
        setPreviewImg(imgurl)
        setShowB(true);
    
      };

    const handleClose = () => {
        setShowB(false);
        setPreviewImg('')
    
      };

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <br /><br />
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <h1 className="m-0 text-center">Celebrity Details</h1>
                            </div>
                        </div>
                    </div>
                    <Container>
                        <br />
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col sm={5}>
                                        <div>
                                            <img className="img-fluid" src={PhotoPath + proImage1}
                                                width="400" height="400" style={{ objectFit: 'contain' }}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={7}>

                                        <div>
                                            <h2 className=" mb-4 text-start">{proName}</h2>
                                        </div>
                                        <br />
                                        <div className='text-start'>

                                            <Row>
                                                <Col sm={12}>
                                                    <Row>
                                                        <Col sm={3}>
                                                            <div className="text-muted text-start">
                                                                <h5>Category</h5>
                                                            </div>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <div className="text-muted text-start">
                                                                <h5>:</h5>
                                                            </div>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <div className="text-muted text-start">
                                                                <h5>{proCategory}</h5>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <br />

                                                    {awards && awards.map((f) => {
                                                        return (
                                                            <>

                                                                <Row>
                                                                    <Col sm={3}>
                                                                        <div className="text-muted text-start">
                                                                            <h5>{f.value}</h5>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={3}>
                                                                        <div className="text-muted text-start">
                                                                            <h5>:</h5>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={3}>
                                                                        <div className="text-muted text-start">
                                                                            <h5>{f.name}</h5>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                {/* <h6 className="text-muted" style={{ fontSize: '1.5rem' }}> {f.value}:{f.name}</h6> */}
                                                            </>
                                                        )
                                                    }
                                                    )}

                                                    <br />

                                                    {socialLink && socialLink.map((f) => {
                                                        return (
                                                            <>
                                                                <Row>
                                                                    <Col sm={3}>
                                                                        <div className="text-muted text-start">
                                                                            <h5> {f.value}</h5>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={3}>
                                                                        <div className="text-muted text-start">
                                                                            <h5>:</h5>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={3}>
                                                                        <div className="text-muted text-start">
                                                                            <h5>{f.name}</h5>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                {/* <h6 className="text-muted" style={{ fontSize: '1.5rem' }}> {f.value}:{f.name}</h6> */}
                                                            </>
                                                        )
                                                    }
                                                    )}
                                                    <br />
                                                    {mediarelease && mediarelease.map((f) => {
                                                        return (
                                                            <>
                                                                <Row>
                                                                    <Col sm={3}>
                                                                        <div className="text-muted text-start">
                                                                            <h5>{f.value}</h5>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={3}>
                                                                        <div className="text-muted text-start">
                                                                            <h5>:</h5>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={3}>
                                                                        <div className="text-muted text-start">
                                                                            <h5>{f.name}</h5>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                {/* <h6 className="text-muted" style={{ fontSize: '1.5rem' }}> {f.value}:{f.name}</h6> */}
                                                            </>
                                                        )
                                                    }
                                                    )}
                                                    <br />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                            </Card.Body>
                            <br />
                            <br /><br />
                            {/* <div className="m-auto" > */}
                            <Container>
                                <Row className="text-center">
                                    <Col sm={12}>

                                        <div className="m-auto celebrity ">
                                            <ul id="thumbnail">
                                                <Slider {...settings}>
                                                    <li>
                                                        <img src={PhotoPath + proImage2}
                                                            alt='celebrity'
                                                            width="230"
                                                            height="150"
                                                            style={{ objectFit: 'contain' }}
                                                            onClick={() => openModal(PhotoPath + proImage2)}
                                                        />
                                                    </li>
                                                    <li>
                                                        <img src={PhotoPath + proImage3}
                                                            alt='celebrity'
                                                            width="230"
                                                            height="150"
                                                            style={{ objectFit: 'contain' }}
                                                            onClick={() => openModal(PhotoPath + proImage3)}
                                                        />
                                                    </li>
                                                    <li>
                                                        <img src={PhotoPath + proImage4}
                                                            alt='celebrity'
                                                            width="230"
                                                            height="150"
                                                            style={{ objectFit: 'contain' }}
                                                            onClick={() => openModal(PhotoPath + proImage4)}
                                                        />
                                                    </li>
                                                    <li>
                                                        <img src={PhotoPath + proImage5}
                                                            alt='celebrity'
                                                            width="230"
                                                            height="150"
                                                            style={{ objectFit: 'contain' }}
                                                            onClick={() => openModal(PhotoPath + proImage5)}
                                                        />
                                                    </li>
                                                </Slider>
                                            </ul>
                                        </div>

                                    </Col>
                                </Row>
                            </Container>
                            {/* </div> */}
                            <br />
                        </Card>


                        <button
              className="btn btn-danger ml-2 mt-4"
              onClick={() => navigate(-1)}
            >
              <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;
              Back
            </button>
                    </Container>
                </div>
            </div>
            <Modal
        show={showB}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        centered
      >
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="mx-auto">
            Images
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <Link to="https://web.whatsapp.com/"> */}
          <Row className="text-center">
            <Col>
              <img
                src={previewImg}
                width="500" height="500"
                alt="products"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
        </>
    )
}

export default CelebrityDetails;
