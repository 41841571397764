import React, { useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { BsReplyFill } from 'react-icons/bs';
import { MdOutlineExpandMore } from 'react-icons/md'
import { IconButton } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import { Rating } from 'react-simple-star-rating';
import moment from 'moment-timezone';
import { getreplies, createReply } from '../Admin/ProductServiceAPI'
import ProductURL from "../../ServerCalls/ProductServer";
import Auth from '../../ServerCalls/Auth';
const PhotoPath = ProductURL.photoURL + "MemberMasterImages/";
const brandphoto = ProductURL.photoURL + "BrandImages/";
const productphoto = ProductURL.photoURL + "ProductImages/"


export default function ProductComments({ productcomm }) {
    const [text, setText] = useState('');
    const [addComment, setaddComment] = useState(false);
    const [formError, setFormError] = useState('')
    const [replyopen, setreplyopen] = useState(false);
    const [replies, setReplies] = useState([]);
    const [feedbackID, setfeedbackID] = useState(productcomm.feedbackid);
    // const userid = Auth.getUserId();
    const auth = Auth.getUserIdF();

    const replylist = (id, open) => {

        let openreply = replyopen === false ? true : false
        setreplyopen(openreply);
        if (openreply === true || open === "open") {
            getreplies(id).then((res) => {
                if (res.data._response.status === 1) {

                    let arr = [];
                    let replydata = res.data._response.data.brandrecognitioncommgetid1

                    setReplies(replydata)
                }
            }).catch((err) => {
                console.log(err, "err in replies get");
            })
        } else {
            setReplies([]);
        }

    }

    const fixTimezoneOffset = (date) => {

        if (!date) return "";

        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();

    }

    const onSubmit = () => {
        if (text !== '') {

            const allData = {
                feedbackid: feedbackID,
                message: text,
                messagefrom: auth,
                ReplyDate: fixTimezoneOffset(new Date()),
            };
            createReply(allData)
                .then((res) => {
                    if (res.data._response.status === 1) {
                        setaddComment(false);
                        setText("");
                        replylist(feedbackID, "open");
                    }
                })
                .catch((err) => {
                    console.log(err, "err in createreply");
                });

        }
        else {

            setFormError("Please fill the details")

        }


    };


    const openCommentForm = () => {
        return (
            <>
                <form>
                    <textarea
                        className="form-control"
                        value={text}
                        onChange={(e) => {
                            setText(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
                            setFormError('');
                    }}
              

                    />
                    <div style={{ color: "red" }}>{formError}</div>
                    <Button
                        type="submit"
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        Send
                    </Button>&nbsp;
                    <Button
                        type="submit"
                        onClick={() => {
                            setaddComment(false);
                        }}
                    >
                        Cancel
                    </Button>
                </form>
            </>
        );
    };

    return (
        <>
            <div className="container mb-5 mt-5">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col sm={8}>
                                <div className='d-flex media1 p-2'>
                                    <img className="mr-4 rounded-circle im" alt="Bootstrap Media Preview" src={productphoto + productcomm.productimage} />
                                    <h5 className='productname m-4'>Product : <span style={{ color: "#0066b2", fontSize: "medium", fontSize: '1.5rem' }}>{productcomm.productname}</span></h5>
                                </div>
                                <div className='media-body m-4'>
                                    <div className="row">

                                        <br />
                                        <div className="col-8 d-flex mt-2 mb-4">
                                            <h5>{productcomm.feedback}</h5>
                                        </div>
                                        <br />
                                        <div className="col-8 mt-2">
                                            <Rating
                                                ratingValue={productcomm.brandratings}
                                                // allowHover={false}
                                                readonly

                                            />
                                            <br />
                                        </div>
                                        <br /><br />
                                        <div className="col-12 mb-4">
                                            <br />
                                            {
                                                addComment ? openCommentForm() : null
                                            }
                                        </div>
                                    </div>

                                    <div>
                                        {
                                            replies && replies.map((a) => {
                                                return (

                                                    <>
                                                        <div className='justify-content-center'>
                                                            <Row>
                                                                <Col sm={9} >
                                                                    <div className='media1 p-2'>
                                                                        <div className='d-flex'>
                                                                            <a className="aa pr-3" href="#">
                                                                                <img className="rounded-circle im" alt="Bootstrap Media Another Preview" src={a.role === 3 ? brandphoto + a.profile : a.role === 2 ? PhotoPath + a.profile : null} />

                                                                            </a>
                                                                            <div className='m-2'>
                                                                                <h5 className='Nametext '>{a.firstname} {a.lastname}</h5>
                                                                                <h6>{a.message}</h6>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3} className="media1 p-2 d-flex justify-content-end">
                                                                    <div className='' style={{ paddingTop: '20px' }}>
                                                                        <strong className=''>{moment(a.replydate).format('LLL')}</strong>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4} >
                                <div className="d-flex justify-content-end">
                                    <div className='cursorpointer m-4' onClick={() => { setaddComment(true); }} >
                                        <span style={{ color: 'blue', fontSize: '1.2rem', fontWeight: '600' }}>
                                            <BsReplyFill fontSize="25px" />
                                            Reply
                                        </span>

                                    </div>
                                    <div className='replies m-2' onClick={() => replylist(feedbackID)}>
                                        <Tooltip title="Replies">
                                            <IconButton>
                                                <MdOutlineExpandMore fontSize="25px" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end m-5 align-bottom" style={{ paddingTop: '60px' }}>
                                    <strong>{moment(productcomm.date).format('LLL')}</strong>

                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </>

    )
}
