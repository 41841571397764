import React, { useState, useEffect } from "react";
import { Col, Row, Form, Card, Alert,  } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
    addFeedback,
    userFeedbacklist,
    ProductListgetById,
} from "../Admin/ProductServiceAPI";
import swal from "sweetalert";
import Auth from "../../ServerCalls/Auth";
import { Rating } from "react-simple-star-rating";
import Loader from "../../common/Loader";
import ProductURL from "../../ServerCalls/ProductServer";

import { Modal } from "react-bootstrap";
import ProductComments from "./ProductComments";


const productphoto = ProductURL.photoURL + "ProductImages/"


const Feedback = () => {
    const { id } = useParams();
    const productId = Number(id);
   

    const navigate = useNavigate();
    const auth = Auth.getUserIdF();

    const [productID, setproductID] = useState(Number(id));
    const [comment, setcomment] = useState("");
    const [rating, setRating] = useState(0);
    const [myFeedback, setMyFeedback] = useState([]);
    const [userID, setUserID] = useState(auth);
    const [feedbackID, setfeedbackID] = useState("");
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(true);
    const [proName, setproName] = useState([]);
    const [proBrand, setproBrand] = useState([]);
    const [proCategory, setproCategory] = useState([]);
    const [proImage1, setproImage1] = useState([]);
    const [proImage2, setproImage2] = useState([]);
    const [proImage3, setproImage3] = useState([]);
    const [proImage4, setproImage4] = useState([]);
    const [proImage5, setproImage5] = useState([]);
    const [showB, setShowB] = useState(false);
    const [showImg, setShowImg] = useState(false);
    const [commentError, setcommentError] = useState("");
    const [previewImg, setPreviewImg] = useState('');

    const handleComment = (e) => {
        setcomment(e.target.value);
        setcommentError("");
    };

    const handleRate = (rate) => {
        setRating(rate);
    };

    useEffect(() => {
        userfeedbacklist();
        getProductDetails();
        // getFavAndunfav();

    }, []);

    const userfeedbacklist = () => {
        setLoading(true);
        userFeedbacklist(productID, userID)
            .then((res) => {
                if (res.data._response.status === 1) {

                    setMyFeedback(res.data._response.data.productrecognitiongetid);
                    setfeedbackID(res.data._response.data.productrecognitiongetid[0].feedbackid);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err, "err in get data");
                setLoading(false);
            });
    };

    const getProductDetails = () => {
        ProductListgetById(productId).then((res) => {
            const data = res.data._response.data.productgetid;
            setproName(data[0].productname);
            setproBrand(data[0].brandname);
            setproCategory(data[0].categoryname);
            setproImage1(data[0].productimage1);
            setproImage2(data[0].productimage2);
            setproImage3(data[0].productimage3);
            setproImage4(data[0].productimage4);
            setproImage5(data[0].productimage5)
        }).catch((err) => {
            console.log(err, "err in product get")
        })
    };

    const formValidation = () => {
        let commentError = "";
        // let emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (comment == "") {
            commentError = "Please fill the Details";
        }

        if (commentError) {
            setcommentError(commentError);

            return false;
        }

        return true;
    };

    const fixTimezoneOffset = (date) => {

        if (!date) return "";

        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();

    }

    const saveData = () => {
        const isValid = formValidation();

        if (isValid) {
            const addData = {
                productid: productID,
                id: Number(auth),
                feedback: comment,
                brandratings: rating,
                date: fixTimezoneOffset(new Date())
            };

            addFeedback(addData).then((res) => {
                if (res.data._response.status === 1) {
                    swal("Thank you!", "We will get back to you if required", "success");
                    clearData();
                    userfeedbacklist();

                }
            }).catch((err)=>{
                console.log(err,"err in add feedback");
            })
        }
    };

    const clearData = () => {
        setcomment("");
        setRating(0);
    };


    const handleClose = () => {
        setShowB(false);
    };

    const handleClose1 = () => {
        setShowImg(false);
        setPreviewImg('')

    };

    const openModal = (imgurl) => {
        setPreviewImg(imgurl)
        setShowImg(true);
    }


    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <br /><br />
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <h1 className="m-0 text-center">Feedback & Rating</h1>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : myFeedback.length === 0 ? (
                    <section className="content">
                        <div className="container-fluid">
                            <div className='container'>
                                <div className='row'>


                                    <Alert variant="primary" show={show} onClose={() => setShow(false)} dismissible>
                                        <Alert.Heading>Give Feedback!!!</Alert.Heading>
                                        {/* <strong>Product Name: {productname}</strong> */}
                                    </Alert>

                                    <Col>
                                        <div className="container mt-5 mb-5">
                                            <div className="card">
                                                <div className="row g-0">
                                                    <div className="col-md-6 border-end">
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <div className="main_image  mt-2">
                                                                <img
                                                                    src={productphoto + proImage1}
                                                                    id="main_product_image"
                                                                    width="400"
                                                                    alt="products"
                                                                />
                                                            </div>
                                                            <div className="thumbnail_images">
                                                                <ul id="thumbnail">
                                                                    <li>
                                                                        <img
                                                                            src={productphoto + proImage2}
                                                                            width="70" height="70"
                                                                            alt="products"
                                                                            onClick={() => openModal(productphoto + proImage2)}
                                                                        />
                                                                    </li>
                                                                    <li>
                                                                        <img
                                                                            src={productphoto + proImage3}
                                                                            width="70" height="70"
                                                                            alt="products"
                                                                            onClick={() => openModal(productphoto + proImage3)}

                                                                        />
                                                                    </li>
                                                                    <li>
                                                                        <img
                                                                            src={productphoto + proImage4}
                                                                            width="70" height="70"
                                                                            alt="products"
                                                                            onClick={() => openModal(productphoto + proImage4)}
                                                                        />
                                                                    </li>
                                                                    <li>
                                                                        <img
                                                                            src={productphoto + proImage5}
                                                                            width="70" height="70"
                                                                            alt="products"
                                                                            onClick={() => openModal(productphoto + proImage5)}
                                                                        />
                                                                    </li>
                                                                    {/* <li><img onclick="changeImage(this)" src="https://i.imgur.com/6ZufmNS.jpg" width="70" /></li> */}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" style={{ textAlign: "center", padding:'10px' }}>
                                                        <div className="">
                                                            <div className="m-0">
                                                                <h3>Name: {proName}</h3>
                                                            </div>
                                                            <div className="mt-2  content">
                                                                <h5 >Brand: {proBrand}</h5>{" "}
                                                            </div>
                                                            <br />
                                                            <h6 >
                                                                Category: {proCategory}
                                                            </h6>

                                                            <div className="container mb-2">
                                                                <Card style={{ padding: "20px" }}>
                                                                    <Form.Group
                                                                        as={Row}
                                                                        className="justify-content-center"
                                                                        controlId="formHorizontalEmail"
                                                                    >
                                                                        <Col sm={12} className="mb-4">
                                                                            <h5 className="w-100 pb-1">
                                                                               Feedback & Rating:
                                                                            </h5>
                                                                            <Form.Control
                                                                                className="form-control w-100"
                                                                                placeholder="Feedback & Rating..."
                                                                                as="textarea"
                                                                                rows={4}
                                                                                value={comment}
                                                                                onChange={handleComment}
                                                                            ></Form.Control>
                                                                            <div style={{ fontSize: 14, color: "red" }}>
                                                                                {commentError}
                                                                            </div>
                                                                        </Col>
                                                                        {/* <Col sm={12} className="m-0 text-center pb-1">
                                                                            <Form.Label className="w-100 ">
                                                                                <h5>Ratings:</h5>
                                                                            </Form.Label>
                                                                            <div className="m-0 text-center ">
                                                                            <Rating
                                                                                onClick={(rate) => handleRate(rate)}
                                                                                ratingValue={rating}
                                                                                allowHalfIcon
                                                                                size='30px'
                                                                            />
                                                                            </div>
                                                                        </Col> */}
                                                                    </Form.Group>
                                                                </Card>
                                                              <Row>
                                                                <Col sm={12} className="m-0 text-center pb-1">
                                                                            <Form.Label className="w-100 ">
                                                                                <h5>Rating:</h5>
                                                                            </Form.Label>
                                                                            <div className="m-0 text-center ">
                                                                            <Rating
                                                                                onClick={(rate) => handleRate(rate)}
                                                                                ratingValue={rating}
                                                                                allowHalfIcon
                                                                                size='30px'
                                                                            />
                                                                            </div>
                                                                        </Col>
                                                               </Row>
                                                               <br/>

                                                                <div className="button_style m-0 text-center">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-pos2"
                                                                        onClick={() => saveData()}
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-pos2 m-4"
                                                                        onClick={() => navigate(-1)}
                                                                        style={{ marginRight: "600px" }}
                                                                    >
                                                                        <i className="fa-solid fa-circle-left"></i>Back
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </Col>


                                </div>
                            </div>
                        </div>
                    </section>
                ) : (

                    <section className="content">
                        <div className="container-fluid">
                            <div className='container'>
                                <div className='row'>
                                    <div>
                                        <Alert variant="primary" show={show} onClose={() => setShow(false)} dismissible>
                                            <Alert.Heading>Feedback already shared for this Brand/Product!!!</Alert.Heading>
                                        </Alert>
                                        {myFeedback &&
                                            myFeedback.map((a) => (
                                                <>
                                                    <h3 className="mb-5">Comments</h3>
                                                    <div>
                                                        <ProductComments
                                                            productcomm={a}
                                                        />
                                                      </div>                 
                                                    <button
                                                        type="button"
                                                        className="btn btn-pos2 m-4"
                                                        onClick={() => navigate(-1)}
                                                        style={{ marginRight: "600px" }}
                                                    >
                                                        <i className="fa-solid fa-circle-left"></i>Back
                                                    </button>
                                                </>
                                            )
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}



            </div>
            <div className="button_style mt-3 text-center">

            </div>

            <Modal
                className="modalnoti"
                show={showB}
                onHide={handleClose}
                size="md"
                backdrop="static"
                centered
            >
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto">
                        <h5 className="text-center">Add Feedback</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <>
                        {/* {NewsList.map((list, id) => ( */}
                        {/* <div className="alert newsalert"> */}
                        <div className="container">
                            <Form.Group
                                as={Row}
                                className="justify-content-center"
                                controlId="formHorizontalEmail"
                            >
                                <Col sm={12} className="mb-4">
                                    <Form.Label className="w-100 pb-1">
                                        Comment Or Query:
                                    </Form.Label>
                                    <textarea
                                        className="form-control w-100"
                                        placeholder="Comments & Reviews..."
                                        rows="4"
                                        value={comment}
                                        onChange={handleComment}
                                    ></textarea>
                                    <div style={{ fontSize: 14, color: "red" }}>
                                        {commentError}
                                    </div>
                                </Col>
                                <Col sm={12} className="mb-4">
                                    <Form.Label className="w-100 pb-1">
                                        Ratings:
                                    </Form.Label>
                                    {/* <textarea
                                                        className="form-control w-100"
                                                        placeholder="Comments & Reviews..."
                                                        rows="4"
                                                        value={comment}
                                                        onChange={handleComment}
                                                    ></textarea> */}
                                    <Rating
                                        onClick={(rate) => handleRate(rate)}
                                        ratingValue={rating}
                                        allowHalfIcon
                                    // allowHover={false}
                                    />
                                    {/* <div style={{ fontSize: 14, color: "red" }}>{commentError}</div> */}
                                </Col>
                            </Form.Group>
                            <div className="button_style mt-3 text-center">
                                <button
                                    type="button"
                                    className="btn btn-pos2"
                                    onClick={() => saveData()}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                        {/* </div> */}
                        {/* ))} */}
                    </>
                </Modal.Body>

            </Modal>

            <Modal
                show={showImg}
                onHide={handleClose1}
                size="lg"
                backdrop="static"
                centered
            >
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto">
                        Images
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {/* <Link to="https://web.whatsapp.com/"> */}
                    <Row className="text-center">
                        <Col>
                            <img
                                src={previewImg}
                                width="500" height="500"
                                alt="products"
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Feedback;
