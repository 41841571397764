import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Card } from "react-bootstrap";
import MaterialTable from "material-table";
import { celebrityCategoryList, AddCategory, editCelebrityCategory, deleteCelebrityCategory,getCelebrityWithNoparent } from './CelebrityServiceAPI'
import Button from '@mui/material/Button';
import Select from "react-select";
import Swal from "sweetalert2";
import axios from "../../../ServerCalls/AxiosInstance";




export default function CelebrityCategory() {

    const columns = [
        { title: " Celebrity Category Name ", field: "celebcategoryName", align: "center" },
        { title: "Parent Celebrity Category Name ", field: "celebparentcategoryname", align: "center" },
    ];

    const [categoryValue, setcategoryValue] = useState('');
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [CategoryOptions, setCategoryOptions] = useState([]);
    const [categorylist, setCategorylist] = useState([])
    const [categoryID, setCategoryID] = useState('');
    const [update, setupdate] = useState(false);
    const [formerror, setformError] = useState('')

    const handleCategory = (e) => {

        setcategoryValue(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setformError({ ...formerror, categoryValue: "" })

    }

    const handleparentCategory = (selected) => {

        setSelectedCategory(selected)
        setformError({ ...formerror, selectedCategory: "" })

    }

    useEffect(() => {

        allCategories();
     celbritycategories();

    }, [])


    const celbritycategories = () => {

        getCelebrityWithNoparent().then((res) => {

            if (res.data._response.status === 1) {

                setCategorylist(res.data._response.data.getcelcategorywithoutnoparent)
            }

        }).catch((err) => {
            console.log(err, "err");
        })

    }


    const allCategories = () => {

        celebrityCategoryList().then((res) => {

            let list = res.data._response.data.getcelebcategory;
            if (res.data._response.status === 1) {

                let options = list.map((a) => ({
                    value: a.celebcategoryid,
                    label: a.celebcategoryname,
                }));
                setCategoryOptions(options)
            }
        })
            .catch((err) => {
                console.log(err, "err in get celebritycategorylist");

            })
    }


    const validates = () => {
        let formError = {};
        let isValid = true;
        // let regex = /^[A-Za-z0-9 ]+$/;

        if (categoryValue === "") {
            isValid = false;
            formError["categoryValue"] = "Please Enter Celebrity Category Name";
        } else if (
            categoryValue.length < 3 
          
        ) {
            isValid = false;
            formError["categoryValue"] =
                "Please Enter Minimun 3 Characters";

        }else if(categoryValue.length > 100){
            isValid = false;
            formError["categoryValue"] = "Maximum 100 characters are allowed"

        }
     
        if (selectedCategory.length < 1) {
            isValid = false;
            formError["selectedCategory"] =
                "Please Select Category";
        }
        setformError(formError);
        return isValid;
    };

    const saveData = () => {

        const isValid = validates();

        if (isValid) {
            const data = {
                celebcategoryname: categoryValue,
                celebparentcategoryid: selectedCategory.value
            };
            AddCategory(data)
                .then((res) => {
                    if (res.data._response.status === 1) {
                        Swal.fire({
                            icon: "success",
                            title: "Category Added Successfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        celbritycategories();
                        ClearStates();
                    }
                })
                .catch((err) => {
                    console.log(err, "error in add celebritycategory");
                })
        }
    }

    const GetData = (a) => {
 
        setCategoryID(a.celebcategoryid)
        setupdate(true);
        setcategoryValue(a.celebcategoryName);
        setSelectedCategory({ value: a.parentcelebcategoryid, label: a.celebparentcategoryname })

    }

    const editdata = () => {

        const isValid = validates();
        if (isValid) {

            const data = {

                celebcategoryname: categoryValue,
                celebparentcategoryid: selectedCategory.value,
                celebcategoryid: categoryID
            };

            editCelebrityCategory(data)
                .then((res) => {
                    if (res.data._response.status === 1) {

                        Swal.fire({
                            icon: "success",
                            title: "Category Updated Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        celbritycategories();
                        ClearStates();

                    }
                })
                .catch((err) => {
                    console.log(err, "err in update celebrity category");
                })
        }
    }

    const trashCategory = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {

                  deleteCelebrityCategory(id)
                // axios.delete(`https://localhost:5002/api/ProductManagement/RemovecelebrityCategory/${id}`)
                    .then((res) => {

                        if (res.data._response.status === 1) {
                            Swal.fire({
                                icon: "success",
                                title: "Category Deleted Sucessfully",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });
                            celbritycategories();

                        }else if(res.data._response.status === 2){
                            Swal.fire({
                                icon: "warning",
                                title: res.data._response.message,
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });

                        }

                    }).catch((error) => {
                        console.log(error);
                    })
            }
        })
        .catch((err)=>{
            console.log(err,'err in result');
        })

    }

    const ClearStates = () => {
        setcategoryValue('');
        setformError('');
        setSelectedCategory([]);
        setupdate(false);
    }


    return (

        <>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <br /><br />
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    {!update?(
                                    <h1 className="m-0 text-center">ADD CELEBRITY CATEGORY</h1>
                                    ):
                                    <h1 className="m-0 text-center">EDIT CELEBRITY CATEGORY</h1>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content consti-section">
                        <div className="container">
                            <div className="row">
                                <Col md={12} >
                                    <Card style={{ padding: "20px" }}>
                                        <>
                                            <Form.Group
                                                as={Row}
                                                className="justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">
                                                        Category Title
                                                    </Form.Label>
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        name="categoryValue"
                                                        placeholder="Enter your celebrity category name"
                                                        value={categoryValue}
                                                        onChange={handleCategory}
                                                    />

                                                    <div style={{ color: "red" }}>
                                                        {formerror.categoryValue}
                                                    </div>
                                                </Col>

                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">
                                                        Parent Category Title
                                                    </Form.Label>
                                                    <Select
                                                        className="w-100"
                                                        options={CategoryOptions}
                                                        name='selectedCategory'
                                                        onChange={handleparentCategory}
                                                        value={selectedCategory}
                                                        menuPortalTarget={document.body}
                                                        styles={{
                                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                        }}
                                                    />
                                                     <br/>
                                                    <div style={{ color: "red" }}>
                                                        {formerror.selectedCategory}
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                            <div className="button_style m-0 text-center">

                                                {update === false ? (
                                                    <Button
                                                        type="button"
                                                        variant='contained'
                                                        onClick={() => saveData()}
                                                    >
                                                        Submit
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant='contained'
                                                        color='success'
                                                        onClick={() => editdata()}
                                                    >
                                                        Update
                                                    </Button>
                                                )} {" "}

                                                <Button
                                                    type="button"
                                                    variant='contained'
                                                    color="error"
                                                    onClick={() => ClearStates()}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </>
                                    </Card>
                                </Col>

                                <Col className='mt-4' md={12} >
                                    <MaterialTable
                                        title="Celebrity Category"
                                        data={categorylist}
                                        columns={columns}
                                        actions={[                                           
                                            {
                                                icon: "edit",
                                                tooltip: "Edit",
                                                onClick: (event, rowData) => {
                                                    GetData(rowData);
                                                },
                                            },

                                            {
                                                icon: "delete",
                                                tooltip: "Delete",
                                                onClick: (event, rowData) => {
                                                    trashCategory(rowData.celebcategoryid);
                                                },
                                            },
                                        ]}
                                        options={{
                                            search: true,
                                            pageSize: 5,
                                            showFirstLastPageButtons: false,
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                            pageSizeOptions: [10, 15, 20, 50],
                                        }}
                                    />
                                </Col>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>

    )
}
