import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link,useLocation } from 'react-router-dom';
import { productbyCategoryandBrand, Categorywithnoparent } from "../Admin/ProductServiceAPI";
import Auth from "../../ServerCalls/Auth";
import ProductURL from "../../ServerCalls/ProductServer";
import Select from "react-select";
import Swal from "sweetalert2";
import { FcInfo } from 'react-icons/fc';
import Loader from '../../common/Loader';
import NotFound from '../../common/NotFound';
import { Box } from '@material-ui/core'
import { Pagination } from "@mui/material";


export default function BrandGallery() {

  const auth = Auth.getUserId();
  const [productdata, setproductdata] = useState([]);
  const [categorySelected, setcategorySelected] = useState([]);
  const [categoryOption, setcategoryOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageC, setPageC] = useState(8)
  const [pageNumber, setPageNumber] = useState(1);
  const dataPerPage = 8;
  const PhotoPath = ProductURL.photoURL + "ProductImages/";


  useEffect(() => {

   // getBrand();
    getCategoryOption();

  }, [])


  useEffect(() => {
    getByCategory(1);
  }, [categorySelected])
  
  const getByCategory = (pgno) => {
    setLoading(true)
    let catagories = categorySelected.value === undefined ? 0 : categorySelected.value
    productbyCategoryandBrand(auth, catagories, pgno)
      .then((res) => {
        const category = res.data._response.data.productgetid
        if (category.length <= 0) {

          setproductdata([])
          setLoading(false);
        } else {
          let count = res.data._response.data.count;
          let APIpagecount = Math.ceil(count / dataPerPage);

          setproductdata(category)
          setPageC(APIpagecount)
          setLoading(false);

        }

      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Product not Found",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });

      })

  }

  const handleCategory = (selected) => {
    setLoading(true);
    selected ? setcategorySelected(selected) : setcategorySelected([])


  }

  const getCategoryOption = () => {
    Categorywithnoparent()
      .then((res) => {
        const category = res.data._response.data.getprocategory
        let categorydata = category.map((list) => ({
          value: list.categoryid,
          label: list.categoryName
        }))
        setcategoryOption(categorydata)
      }).catch((err) => {
        console.log(err);
      })
  }

  const changePage = (e, a) => {
    setPageNumber(a);
    getByCategory(a)

  }

  const renderCard = (e, index) => {

    return (
      <Col sm={3}>
        <Card
          className="gallerycard"
          key={index}
        style={{
          
          

          marginTop: "15px",
        }}
        >
          <Card.Body>
            <Card.Img
            className='zoom'
              src={PhotoPath + e.productimage1}
              width="250px"
              height="180px"
              variant='top'
              style={{objectFit:'contain'}}
            />
            <Card.Text className="Cname">{e.productname}</Card.Text>{" "}
            <div className="favbutton">

              <>
                <Link
                className='text-center'
                  to={`/brandproduct-details/${e.productid}`}
                  title="More info"
                >
                  <FcInfo fontSize='35px' />
                </Link>
                &nbsp;
              </>
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <div>
        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-12">
                    <h1 className="m-0 text-center">Brand Products Gallery</h1>
                  </div>
                </div>
              </div>
            </div>

            <section className="content  consti-section">
              <div className="container">
                <div className="row">
                  <Col md={12}>
                    <Card style={{ padding: "20px" }}>
                      <Form.Group
                        as={Row}
                        className="justify-content-center"
                        controlId="formHorizontalEmail"
                      >

                        <Col sm={10} className="mb-4">

                          <h5>
                            Select Category
                          </h5>

                          <Select
                            isClearable={categorySelected}
                            className="w-100"
                            options={categoryOption}
                            onChange={handleCategory}
                            value={categorySelected}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}

                          />
                        </Col>
                      </Form.Group>
                      {/* <Button className="detailsbtn">Clear</Button> */}
                    </Card>
                  </Col>
                </div>
              </div>
            </section>
            <Container>
            <card-group className="d-flex" style={{ marginTop: "36px" }}>
              <Col md={12} className="mb-2">
                {loading ?
                  <Loader />
                  :
                  productdata.length !== 0 ?

                    <Row sm="5"> {productdata.map(renderCard)} </Row>
                    :
                    <NotFound />
                }
              </Col>
            </card-group>
            </Container>
            <br /><br />
            {/* <Pagination_comp/> */}
            {
              productdata.length !== 0 ?
              <Box display="flex" alignItems="center" justifyContent="center">
              <Pagination
                count={pageC}
                page={pageNumber}
                onChange={changePage}
                shape="rounded" color="primary" size="large"
              />
            </Box>
            :
            null
            }
            <br /><br />
          </div>
        </div>
      </div>


    </>
  );
}
