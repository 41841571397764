import React, { useState, useEffect } from "react";
import { Form, Row, Col, Card, FormControl,Button } from "react-bootstrap";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import {
  BrandgetbyId,
  BrandPhotos,
  updateBrandDetails,
} from "../Admin/ProductServiceAPI";
import ProductURL from "../../ServerCalls/ProductServer";
import Auth from "../../ServerCalls/Auth";
import {  useNavigate } from "react-router-dom";
const PhotoPath = ProductURL.photoURL + "BrandImages/";

const BrandInfo = () => {
  const [brand, setBrand] = useState("");
  const [personName, setpersonName] = useState("");
  const [contact, setcontact] = useState("");
  const [email, setemail] = useState("");
  const [userName, setuserName] = useState("");
  const [password, setpassword] = useState("");
  const [brandID, setbrandID] = useState("");
  const [establishDate, setestablishDate] = useState(new Date());
  const [image11, setimage11] = useState("");
  const [image12, setimage12] = useState("");
  const [image11path, setImage11path] = useState("");
  const [image12path, setImage12path] = useState("");
  const [brandDetails, setbrandDetails] = useState("");
  const [formError, setFormError] = useState([]);

  const auth = Auth.getUserId();
const navigate = useNavigate();
  const handleimage12 = (e) => {
    setimage12(e.target.files[0]);
    setImage12path(URL.createObjectURL(e.target.files[0]));
  };

  const handleimage11 = (e) => {
    setimage11(e.target.files[0]);
    setImage11path(URL.createObjectURL(e.target.files[0]));
  };

  const handleInput = (e) => {
    setBrand(e.target.value);

    setFormError({ ...formError, BrandName: "" });
  };

  const handleConcern = (e) => {
    setpersonName(e.target.value);
    setFormError({ ...formError, personName: "" });
  };

  const handleEmail = (e) => {
    setemail(e.target.value);
    setFormError({ ...formError, email: "" });
  };

  const handleusername = (e) => {
    setuserName(e.target.value);
    setFormError({ ...formError, userName: "" });
  };

  const handleContact = (e) => {
    setcontact(e.target.value);
    setFormError({ ...formError, contact: "" });
  };

  const handlePassword = (e) => {
    setpassword(e.target.value);
    setFormError({ ...formError, password: "" });
  };

  const handleBrandDetails = (e) => {
    setbrandDetails(e.target.value);
    setFormError({ ...formError, brandDetails: "" });
  };

  const validate = () => {
    let regex = /^[A-Za-z0-9 ]+$/;
    let emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let passregex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
    let contactregex = /^[0-9]+$/i;

    let formError = {};
    let isValid = true;
    if (brand === "") {
      isValid = false;
      formError["BrandName"] = "please Enter Brand Name";
    } else if (regex.test(brand) === false) {
      formError = "Please enter alphabetic characters and no spaces";
    }

    if (personName === "") {
      isValid = false;
      formError["personName"] = "please Enter Your Name";
    } else if (personName.length > 20) {
      formError = "Name cannot exceed 20 characters";
    }
    if (contact === null) {
      isValid = false;
      formError["contact"] = "please Enter Contact No.";
    } else if (!contactregex.test(contact)) {
      isValid = false;
      formError["contact"] = "Please enter numerics and no spaces";
    } else if (!(contact.length > 9 && contact.length < 11)) {
      isValid = false;
      formError["contact"] = "Contact number must be 10 in length";
    }

    if (email === "") {
      isValid = false;
      formError["email"] = "please Enter Email";
    } else if (!emailregex.test(email)) {
      isValid = false;
      formError["email"] = "Please Enter a Valid Email";
    }

    if (userName === "") {
      isValid = false;
      formError["userName"] = "please Enter Username";
    }

    if (password === "") {
      isValid = false;
      formError["password"] = "please Enter Password";
    } else if (!passregex.test(password)) {
      isValid = false;
      formError["password"] =
        "Password must combination of Number,Capital letter, special character and min length is 8 !";
    }

    if (brandDetails === "") {
      isValid = false;
      formError["brandDetails"] = "please Enter Brand Details";
    }

    setFormError(formError);

    return isValid;
  };

  useEffect(() => {
    getBrandDetails();
  }, []);

  const getBrandDetails = () => {
    BrandgetbyId(auth).then((res) => {
      const brandData = res.data._response.data.brand[0];
      setbrandID(brandData.brandId);
      setBrand(brandData.brandName);
      setpersonName(brandData.contactPersonName);
      setcontact(brandData.contactPersonContactNumber);
      setemail(brandData.contactEmail);
      setuserName(brandData.userName);
      setpassword(brandData.password);
      setbrandDetails(brandData.brandDetails);
      setimage11(brandData.image1);
      setimage12(brandData.image2);
    })
    .catch((err)=>{
      console.log(err,'err in get branddetails');
    });
  };

  const image_upload = async (data) => {
    await BrandPhotos(data)
      .then((res) => {
        console.log("photo res", res);
      })
      .catch((err) => {
        console.log(err, "err in photo submit");
      });
  };

  const updateData = async () => {
    const isValid = validate();

    if (isValid) {
      const updatedata = {
        brandId: brandID,
        brandDetails: brandDetails,
        brandName: brand,
        contactEmail: email,
        contactPersonContactNumber: contact,
        contactPersonName: personName,
        image1: image11.name === undefined ? image11 : image11.name,
        image2: image12.name === undefined ? image12 : image12.name,
        password: password,
        userName: userName,
        yearOfEstablishment: new Date(establishDate),
      };

      let formdata = new FormData();
      let formdata2 = new FormData();
      formdata.append("file", image11);
      formdata2.append("file", image12);
      if (image11.name !== undefined) {
        await image_upload(formdata);
      }
      if (image12.name !== undefined) {
        await image_upload(formdata2);
      }
      updateBrandDetails(updatedata)
        .then((res) => {
          if (res.data._response.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Data Updated Sucessfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            navigate('/BrandDetails')
          }
        })
        .catch((err) => {
          console.log(err, "err in update brand");
        });
    }
  };

  const Cancel=()=>{
    setBrand("");
    setestablishDate(new Date());
    setpersonName("");
    setcontact("");
    setuserName("");
    setbrandDetails("");
  }

  return (

    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
          <br/><br/>
            <div className="row mb-2">
              <div className="col-sm-12">
                <h4 className=" pt-4 m-0 text-center">Update Brand</h4>
              </div>
            </div>
          </div>
        </div>
        <section className="content consti-section">
          <div className="container-fluid" style={{alignContent:'center'}}>
            <div className="d-flex justify-content-center "> 
              
                <Card className=" justify-content-center" style={{padding: "20px",marginBottom:"6px", width:'40rem' }}>
                  <div >
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={12} className="mb-4">
                        <Form.Label className="w-100">Brand Name</Form.Label>
                        <input
                          className="w-100 form-control"
                          type="text"
                          name="BrandName"
                          placeholder="Brand Name"
                          value={brand}
                          onChange={handleInput}
                        />
                        {formError ? (
                          <div style={{ color: "red" }}>
                            {formError.BrandName}
                          </div>
                        ) : null}
                      </Col>
                      <Col
                        sm={12}
                        className="mb-4"
                        style={{ position: "relative", zIndex: "999" }}
                      >
                        <Form.Label className="w-100 pb-1">
                          Year of Establishment
                        </Form.Label>
                        <DatePicker
                          className="w-100 form-control"
                          selected={establishDate}
                          onChange={(date) => {
                            setestablishDate(date);
                          }}
                          // dateFormat="dd/MM/yyyy"
                          showYearPicker
                          dateFormat="yyyy"
                          maxDate={new Date()}
                        />
                        {/* <div style={{ fontSize: 14, color: "red" }}>{formError}</div> */}
                      </Col>
                      <Col sm={12} className="mb-4">
                        <Form.Label className="w-100 pb-1">
                          Concern Person Name
                        </Form.Label>
                        <input
                          className="w-100 form-control"
                          type="text"
                          placeholder="Concern Person Name"
                          name="personName"
                          value={personName}
                          onChange={handleConcern}
                        />
                        {formError ? (
                          <div style={{ color: "red" }}>
                            {formError.personName}
                          </div>
                        ) : null}
                      </Col>
                      <Col sm={12} className="mb-4">
                        <Form.Label className="w-100 pb-1">Contact</Form.Label>
                        <input
                          className="w-100 form-control"
                          type="text"
                          placeholder="Contact"
                          value={contact}
                          name="contact"
                          onChange={handleContact}
                        />
                        {formError ? (
                          <div style={{ fontSize: 14, color: "red" }}>
                            {formError.contact}
                          </div>
                        ) : null}
                      </Col>
                      <Col sm={12} className="mb-4">
                        <Form.Label className="w-100 pb-1">
                          Username
                        </Form.Label>
                        <input
                          className="w-100 form-control"
                          type="text"
                          placeholder="Username"
                          name="userName"
                          value={userName}
                          onChange={handleusername}
                        />

                        {formError ? (
                          <div style={{ fontSize: 14, color: "red" }}>
                            {formError.userName}
                          </div>
                        ) : null}
                      </Col>

                      <Col sm={12} className="mb-4">
                        <Form.Label className="w-100">Brand Details</Form.Label>
                        <textarea
                          className="w-100 form-control"
                          placeholder="Tell us more"
                          rows="3"
                          onChange={handleBrandDetails}
                          name="brandDetails"
                          value={brandDetails}
                        ></textarea>
                        <div style={{ color: "red" }}>
                          {formError.brandDetails}
                        </div>
                      </Col>
                      </Form.Group>
                      <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      // controlId="formHorizontalEmail"
                    >
                      <Col sm={6} className="mb-4">
                        <Form.Label className="w-100"> Image 1</Form.Label>
                        <FormControl
                          className="m-2"
                          type="file"
                          name="file"
                          onChange={handleimage11}
                        />
                        <div style={{ color: "red" }}>
                          {formError.productImage1}
                        </div>
                      </Col>
                      <Col sm={6} className="mb-4">
                        <Form.Label className="w-100"> Image 2</Form.Label>
                        <FormControl
                          className="m-2"
                          type="file"
                          name="file"
                          onChange={handleimage12}
                        />
                        <div style={{ color: "red" }}>
                          {formError.productImage1}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="imagepreview">
                          <img
                            alt=" "
                            width="80"
                            height="60"
                            src={
                              image11.name === undefined
                                ? PhotoPath + image11
                                : image11path
                            }
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="imagepreview">
                          <img
                            alt=" "
                            width="80"
                            height="60"
                            src={
                              image12.name === undefined
                                ? PhotoPath + image12
                                : image12path
                            }
                          />
                        </div>
                      </Col>
                    </Form.Group>
                    <div className="button_style pt-3 m-0 text-center">
                      <button
                        type="submit"
                        className="btn btn-pos1"
                        onClick={() => updateData()}
                      >
                        Update
                      </button>
                      {/* <button
                        type="submit"
                        className="btn btn-danger update"
                        onClick={() => Cancel()}
                      >
                        Cancel
                      </button> */}
                    
                      </div>
                  </div>
                </Card>
             
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BrandInfo;
