import React, { useState,useEffect } from "react";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Avatar, TextField } from "@mui/material";
import {  Col, Container, Row } from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from "@mui/material/Button";
import { useSearchParams ,useNavigate} from 'react-router-dom';
const ResetPass = () =>{
    const [password, setPassword] = useState('');
    const [comPass, setComPass] = useState('');
    const [token, setToken] = useState('');
    const [errPassword, setErrPasword] = useState('');
    const [errComPass, setErrComPass] = useState('');
    const [loading, setLoading] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate();
    useEffect(() => {
        const user = searchParams.get("user")
        setToken(user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
    const Validate = () =>{
        let Valid = true;
        const passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/; 
        if(!password){
            setErrPasword('Please Enter Pssword');  
            Valid = false;  
        }else if(!passregex.test(password)){
            setErrPasword('Please Enter Strong Password');
            Valid = false;
        }else{
            setErrPasword('');
        }
        if(!comPass){
            setErrComPass('Please Enter Confirm Password');
            Valid = false;
        }else if(password !== comPass){
            setErrComPass(`Password Dosen't Match`);
            Valid = false;
        }else{
            setErrComPass('');
        }
        return Valid
    }
    const onReset = (e) =>{
        e.preventDefault();
        const isValid = Validate();
        if(isValid){
            let body = {
                token:token,
                password: password,
                confirmPassword: comPass,
            };
            let headers = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            setLoading(true);
            axios.post(`https://api.topbrandsranking.com/Authorization/reset-password`, body, headers)
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        if (res.data._response.status === 1) {
                            resetState();
                            navigate('/Login');
                            Swal.fire("Password reset Successfully", "", "success");
                        }
                        else {
                            setLoading(false);
                            Swal.fire("Something went wrong", "", "info");
                        }
                        window.location.href = "/login";
                    }
                })
                .catch(err => {
                    setLoading(false);
                    Swal.fire("Something went wrong", "", "info");
                    console.log("err in reset password", err)
                })
        }
    }
    const resetState = ()=>{
        setPassword('')
        setComPass('')
        setToken('')
        setLoading(false)
    }
    return(
        <Container fluid="sm" className="col-md-4  shadow p-3 mb-5 bg-white rounded">
            <div className="p-3">
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Avatar className="avatarStyleLoginReg"><LockOutlinedIcon /></Avatar>
                    </Col>
                </Row>
                <br/>
                <h2 className="text-center resetpassword">Reset Password</h2>
                <br/>
                <Row>
                    <TextField type="password" label="Password" name="password" onChange={(e)=>setPassword(e.target.value)} fullWidth />
                    {errPassword !== '' ? 
                    (<div className="mt-2"style={{ fontSize: 12, color: "red" }}>
                        {errPassword}
                    </div>
                    ) : null}
                </Row>
                <br/>
                <Row>
                    <TextField type="password" label="Confirm Password" name="comPass" onChange={(e)=>setComPass(e.target.value)} fullWidth />
                    {errComPass !== '' ? 
                    (<div className="mt-2"style={{ fontSize: 12, color: "red" }}>
                        {errComPass}
                    </div>
                    ) : null}
                </Row>
                <br/>
                <Row>
                    <Button  type="submit"
                    variant="contained"
                    className=" mb-4" onClick={onReset}>Reset</Button>
                </Row>
            </div>
        </Container>
    )
}
export default ResetPass