import React from "react";
import { useState, useEffect } from "react";
import { Col, Row, Card, Form } from "react-bootstrap";
import MaterialTable from "material-table";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Swal from "sweetalert2";
import {
  postRoleMenu,
  updateRoleMenu,
  getRole,
  getRoleMenu,
  getMenudata,
  getRoleMenubyId,
  deleteRoleMenu,
} from "./ProductServiceAPI";
import Button from '@mui/material/Button';

const RoleMenu = () => {
  const [menuselected, setMenuSelected] = useState([]);
  const [roleselected, setRoleSelected] = useState([]);
  const [roleselectOption, setroleselectOption] = useState([]);
  const [menuselectOption, setMenuselectOption] = useState([]);
  const [roleMenudata, setroleMenudata] = useState([]);
  const [update, setupdate] = useState(false);
  const [error, setError] = useState([]);

  const [rolemenuid, setrolemenuid] = useState(0);

  const columns = [
    { title: " Role Title ", field: "roletype", align: "center" },
    { title: " Menu Title ", field: "menutype", align: "center" },
  ];

  const handleMenu = (e) => {
    setMenuSelected(e);
    setError({ ...error, menuselected: "" });
  };

  const handleRole = (selected) => {
    setRoleSelected(selected);
    setError({ ...error, roleselected: "" });
  };

  const formValidation = () => {
    let error = {};
    let isValid = true;

    if (roleselected.length < 1) {
      isValid = false;
      error["roleselected"] = "please select role";
    }

    if (menuselected.length < 1) {
      isValid = false;
      error["menuselected"] = "please select menu";
    }

    setError(error);
    return isValid;
  };

  const saveData = () => {
    let isValid = formValidation();
    if (isValid === true) {
      let product = [];

      menuselected &&
        Object.entries(menuselected).map(([a, b]) => {
          product.push({ menuId: b.value, roleId: roleselected.value });
        });

      postRoleMenu(product)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Role-Menu Added Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          getList();
          setRoleSelected("");
          setMenuSelected("");
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Role-Menu Not Added",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
        });
    }
  };

  useEffect(() => {
    getRoleList();
    getMenu();
    getList();
  }, []);

  const getRoleList = () => {
    getRole()
      .then((res) => {
        let list = res.data._response.data.getrole;
        const options = list.map((a) => ({
          value: a.roleId,
          label: a.roleType,
        }));
        setroleselectOption(options);
      })
      .catch((err) => {
        console.log(err, "err in get rollist");
      });
  };

  const getMenu = () => {
    getMenudata()
      .then((res) => {
        let list = res.data._response.data.getmenu;
        const options = list.map((a) => ({
          value: a.menuid,
          label: a.menutype,
        }));
        setMenuselectOption(options);
      })
      .catch((err) => {
        console.log(err, "err in get menu data");
      });
  };

  const editUser = (id) => {
    getRoleMenubyId(id)
      .then((res) => {
        let rolemenu = res.data._response.data.rolemenugetid;

        const role = rolemenu.map((a) => ({
          value: a.roleid,
          label: a.roletype,
        }));
        setRoleSelected(role);
        setrolemenuid(id);

        const menu = rolemenu.map((a) => ({
          value: a.menuid,
          label: a.menutype,
        }));
        setMenuSelected(menu);
      })
      .catch((err) => {
        console.log(err, "got error");
      });
    setupdate(true);
  };

  const updateClick = () => {
    const data = {
      RoleMenuId: rolemenuid,
      RoleId: roleselected.value,
      menuId: menuselected[0].value,
    };

    updateRoleMenu(data)
      .then((res) => {
        if (res.data._response.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Role-Menu Updated Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
          getList();
          setRoleSelected("");
          setMenuSelected("");
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Role-Menu Not Updated",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      });
  };

  const getList = () => {
    getRoleMenu().then((response) => {
      setroleMenudata(response.data._response.data.getrolemenu);
    })
    .catch((err)=>{
      console.log(err,'err in getrolemenu');
    })
  };

  function deleteUser(categoryId) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          deleteRoleMenu(categoryId)
            .then((result) => {
              Swal.fire({
                icon: "success",
                title: "Role-Menu Deleted Sucessfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });

              getList();
              // clear();
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Role-Menu Not Deleted",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in trash rolemenu");
      });
  }

  const ClearData = () => {
    setRoleSelected("");
    setMenuSelected("");
    setError("");
  };

  return (
    <div>
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
            <br/><br/>
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="m-0 text-center">Role Menu</h1>
                </div>
              </div>
            </div>
          </div>

          <section className="content  consti-section">
            <div className="container">
              <div className="row">
                <Col md={12}>
                  <Card style={{ padding: "20px" }}>
                    <Form.Group
                      as={Row}
                      className="justify-content-center"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={6} className="mb-4">
                        <Form.Label className="w-100 pb-1">
                          Role Title
                        </Form.Label>
                        <Select
                          className="w-100"
                          options={roleselectOption}
                          onChange={handleRole}
                          value={roleselected}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />

                        <div style={{ fontSize: 14, color: "red" }}>
                          {error.roleselected}
                        </div>
                      </Col>

                      <Col sm={6} className="mb-4">
                        <Form.Label className="w-100 pb-1">
                          Menu Title
                        </Form.Label>

                        <MultiSelect
                          className="w-100"
                          options={menuselectOption}
                          value={menuselected}
                          onChange={handleMenu}
                          labelledBy="Select"
                        />

                        <div style={{ fontSize: 14, color: "red" }}>
                          {error.menuselected}
                        </div>
                      </Col>
                    </Form.Group>

                    <div className="button_style m-0 text-center">
                      {update === false ? (
                        <Button
                          type="button"
                          variant='contained'
                          onClick={saveData}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          variant='contained'
                          color='success'
                          onClick={() => updateClick()}
                        >
                          Update
                        </Button>
                      )}
                   {" "}{" "}
                      <Button
                        type="button"
                        variant='contained'
                        color='error'
                        onClick={() => ClearData()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col className='mt-4' md={12} >

                <MaterialTable
                  title={false}
                  data={roleMenudata}
                  columns={columns}
                  actions={[
                    {
                      icon: "edit",
                      tooltip: "Edit",
                      onClick: (event, rowData) => {
                        // setNameError("")
                        editUser(rowData.rolemenuid);
                      },
                    },
                    {
                      icon: "delete",
                      tooltip: "Delete",
                      onClick: (event, rowData) => {
                        deleteUser(rowData.rolemenuid);
                      },
                    },
                  ]}
                  options={{
                    search: false,
                    pageSize: 5,
                    showFirstLastPageButtons: false,
                    actionsColumnIndex: -1,
                    addRowPosition: "first",
                  }}
                />
                </Col>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default RoleMenu;
