import React from 'react'
import { Col, Card } from "react-bootstrap";
import { Chart } from "react-google-charts";


function ReportSummary() {



    const data = [
        ["Task", "Hours per Day"],
        ["Work", 11],
        ["Eat", 2],
        ["Commute", 2],
        ["Watch TV", 2],
        ["Sleep", 7],
    ];

    const options = {
        title: "Voting Report",
    };

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <br /><br />
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <h1 className="m-0 text-center">Voting  Report Summary</h1>
                        </div>
                    </div>
                </div>



                <section className="content mt-5">
                    <div className="container">
                        <div className="row">
                            <Col md={12}>
                                <Card style={{ padding: "20px" }}>


                                    <Chart
                                        chartType="PieChart"
                                        data={data}
                                        options={options}
                                        width={"100%"}
                                        height={"400px"}
                                    />



                                </Card>
                            </Col>

                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default ReportSummary