import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import Select from "react-select";
import ProductURL from "../../ServerCalls/ProductServer";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ProductParticipationGetbyId, campaignData, CitizenPollingGetById } from "../Admin/ProductServiceAPI";
import axios from "axios";
import Auth from "../../ServerCalls/Auth";

const CitizenPolling = () => {
  const [campshortnameselected, setCampshortnameselected] = useState([]);
  const [campshortnameoption, setcampaignShortnameoption] = useState([]);
  const [data, setData] = useState([]);
  const [polling, setPolling] = useState([]);
  const [campIDError, setcampIDError] = useState("");
  const PhotoPath = ProductURL.PhotoUrl + "ProductImages/";
  const navigate = useNavigate();
  const location = useLocation();
  const campaignID = location.state;

  const handleshortname = (selected) => {
    setCampshortnameselected(selected);
    ProductParticipationGetbyId(selected.value).then((response) => {
      const poll =
        response.data._response.data.productparticipationgetcampaignid;
      const listdata =
        poll &&
        poll.map((list) => {
          return { ...list, isButton: false };
        });
      let data = listdata.map(function (x) {
        var result = polling.filter((a1) => a1.productId == x.productid);
        if (result.length > 0) {
          x.isButton = true;
        }
        return x;
      });
      setData(data);
    }).catch((err)=>{
      console.log(err,"err in get campaign");
  })
  };

  const handleshortname1 = (selected) => {
    setCampshortnameselected(selected);
    ProductParticipationGetbyId(selected.value).then((response) => {
      const poll =
        response.data._response.data.productparticipationgetcampaignid;

      const listdata =
        poll &&
        poll.map((list) => {
          return { ...list, isButton: false };
        });
      CitizenPollingGetById(auth)
        .then((res) => {
          const citizendata =
            res.data._response.data.citizenpollinggetbycitizenid;

          let data = listdata.map(function (x) {
            var result = citizendata.filter(
              (a1) => a1.productId == x.productid
            );
            if (result.length > 0) {
              x.isButton = true;
            }
            return x;
          });
          setData(data);
          // setPolling(citizendata);
        })

    }).catch((err)=>{
      console.log(err,"err in get campaign");
  })
  };

  const moreInfo = (e) => {
    navigate("/ProductMoreInfo", { state: e });
  };

  const Polling = (e) => {
    navigate("/AddPoll", { state: e });
  };

  const feedback = (e) => {
    navigate("/feedback", { state: e });
  }

  useEffect(() => {
    getShortnameList();
    getPollingUser();
    if (campaignID != null) {
      // campaigngetbyid(campaignID)
      let options = {
        value: campaignID.campaignid,
        label: campaignID.campaignshortname,
      };
      handleshortname1(options);
    }
  }, []);

  const getShortnameList = () => {
    campaignData().then((res) => {
      let list = res.data._response.data.getpollingcampaign;
      let options = [];
      list.map((a) => {
        options.push({
          label: a.pollingCampaignShortName,
          value: a.campaignId,
        });
      });
      setcampaignShortnameoption(options);
    }).catch((err)=>{
      console.log(err,"err in get shortname");
  })
  };

  var auth = Auth.getUserId();
  const getPollingUser = () => {
    CitizenPollingGetById(auth)
      .then((res) => {
        const citizendata =
          res.data._response.data.citizenpollinggetbycitizenid;
        setPolling(citizendata);
      }).catch((err)=>{
        console.log(err,"err in get citizenpolling");
    })
  };

  const renderCard = (e, index) => {  
    return (
      <Card
        key={index}
        style={{
          width: "280px",
          height: "320px",
          marginLeft: "100px",
          marginTop: "25px",
        }}
      >
        <Card.Body>
          <Card.Img
            src={PhotoPath + e.productimage}
            width="150px"
            height="150px"
          />
          <Card.Text className="Cname"> {e.productName} </Card.Text>{" "}
          {/* <Button onClick={() => moreInfo(e)} className="learn">
            More{" "}
          </Button>{" "} */}
          <Link className="learn" to={`/ProductMoreInfo/${e.productid}`}>More</Link>
          {!e.isButton ? (
            <button className="btn btn-warning" onClick={() => Polling(e)}>
              Add Poll{" "}
            </button>
          ) : (
            <button className="btn btn-success">Polled </button>
          )}{" "}

          <button title="Feedback" className="btn btn-info" onClick={() => feedback(e)}>
            <i className="fa-solid fa-message"></i>
          </button>
        </Card.Body>{" "}
      </Card>
    );
  };

  return (
    <>
      <div>
        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-12">
                    <h1 className="m-0 text-center"> Citizen Polling </h1>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <Col
                    md={{
                      span: 6,
                      offset: 3,
                    }}
                  >
                    <Card
                      className="mt-4"
                      style={{
                        padding: "20px",
                      }}
                    >
                      <Form.Group
                        as={Row}
                        className="justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={12} className="mb-4">
                          <Form.Label className="w-100 pb-1">
                            Polling Campaign ShortName{" "}
                          </Form.Label>{" "}
                          <Select
                            className="w-100"
                            options={campshortnameoption}
                            onChange={handleshortname}
                            value={campshortnameselected}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                          />{" "}
                          <div
                            style={{
                              fontSize: 14,
                              color: "red",
                            }}
                          >
                            {" "}
                            {campIDError}{" "}
                          </div>{" "}
                        </Col>{" "}
                      </Form.Group>{" "}
                    </Card>{" "}
                  </Col>{" "}
                </div>{" "}
                <Col md={12} className="mb-2">
                  <Row sm="5"> {data.map(renderCard)} </Row>{" "}
                </Col>{" "}
              </div>{" "}
            </section>{" "}
          </div>{" "}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <Col
                  md={{
                    span: 6,

                    offset: 3,
                  }}
                >
                  <Card
                    className="mt-4"
                    style={{
                      padding: "20px",
                    }}
                  >
                    <button
                      className="btn btn-success m-2 whatsapp"
                      type="submit"
                    >
                      <i className="fa-brands fa-whatsapp-square fa-lg"></i>&nbsp;
                      Share on whatsapp
                    </button>
                    <button
                      className="btn btn-success m-2 twitter"
                      type="submit"
                    >
                      <i className="fa-brands fa-twitter-square fa-lg"></i>&nbsp;
                      Share on Twitter
                    </button>
                    <button
                      className="btn btn-success m-2 facebook"
                      type="submit"
                    >
                      <i className="fa-brands fa-facebook fa-lg"></i>&nbsp; Share on
                      Facebook
                    </button>
                    <button
                      className="btn btn-success m-2 reddit"
                      type="submit"
                    >
                      <i className="fa-brands fa-reddit-square fa-lg"></i>&nbsp;
                      Share on Reddit
                    </button>
                  </Card>{" "}
                </Col>{" "}
              </div>{" "}
            </div>{" "}
          </section>{" "}
        </div>{" "}
      </div>{" "}
    </>
  );
};

export default CitizenPolling;
