import React, { useState, useEffect, useRef } from 'react';

import { Form, FormControl, Col, Row, Card } from 'react-bootstrap';
import Select from 'react-select';
import MaterialTable from 'material-table';
import Button from '@mui/material/Button';

import ProductURL from "../../ServerCalls/ProductServer";
import { productList, postNewsData, productsOfBrands, EditNewsData, BrandNews, DeleteNewsData, CategoryList, Categorywithnoparent, NewsImages } from "../Admin/ProductServiceAPI";
import axios from "axios";
import Swal from "sweetalert2";
import Auth from '../../ServerCalls/Auth';

export default function AddNews() {

    const [update, setupdate] = useState(false);
    const [formError, setFormError] = useState([]);
    const [NewsImagePath, setNewsImagePath] = useState([]);
    const [NewsImage, setNewsImage] = useState("");
    const [Categories, setCategories] = useState([]);
    const [ProductList, setProductList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [header, setHeader] = useState('');
    const [newsDescription, setNewsDescription] = useState("");
    const [newsID, setNewsID] = useState(0);
    const [newsList, setnewsList] = useState([]);
    const [showlist, setShowlist] = useState(false)
    const brandID = Auth.getUserId();
    const PhotoPath = ProductURL.photoURL + "ProductNewsImages/";
    const inputRefrence = useRef(null);

    const columns = [
        {
            title: "Product Name",
            field: "brandname",
            align: "center",
        },
        {
            title: "News Header",
            field: "newsheader",
            align: "center",
        },
        {
            title: "News Description",
            field: "newsdescription",
            type: "date",
            align: "center",
        },
        {
            title: "Product Image",
            field: "newsimage",
            align: "center",
        },

    ];

    useEffect(() => {
        categorylist();
        AllNews();
    }, []);


    const handleInput = (e) => {
        setHeader(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''));
        setFormError({ ...formError, header: "" });
    };

    const handleDescription = (e) => {
        setNewsDescription(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''));
        setFormError({ ...formError, newsDescription: "" });
    };
    const handleimage1 = (e) => {
        setNewsImage(e.target.files[0]);
        setNewsImagePath(URL.createObjectURL(e.target.files[0]));
        setFormError({ ...formError, NewsImage: "" });

    };

    const handleCategory = (selected) => {

        setSelectedCategory(selected);

        productsOfBrands(brandID, selected.value)
            .then((response) => {
                const data = response.data._response.data.productgetid;
                const option = data.map((a) => ({
                    value: a.productid,
                    label: a.productname,
                }));
                setProductList(option);
            }).catch((err)=>{
                console.log(err,"err in get category");
            })

        setFormError({ ...formError, selectedCategory: "" });
    };

    const HandleProduct = (selected) => {

        setSelectedProduct(selected);
        setFormError({ ...formError, productSelected: "" });

    }

    const AllNews = () => {

        BrandNews(brandID).then((res) => {
            if (res.data._response.status === 1) {
                setnewsList(res.data._response.data.productnewsgetid);

            }

        }).catch((err)=>{
            console.log(err,"err in get brandnews");
        })

    }

    const categorylist = () => {
        Categorywithnoparent().then((res) => {
            if (res.data._response.status === 1) {

                let data = res.data._response.data.getprocategory
                let options = data.map((a) => ({
                    value: a.categoryid,
                    label: a.categoryName,
                }));
                setCategories(options);
            }

        }).catch((err) => {
            console.log(err, "err in get category");
        })


    }

    const validate = () => {
        let formError = {};
        let isValid = true;
        let regex = /^[A-Za-z0-9 ]+$/;

        if (header === "") {
            isValid = false;
            formError["header"] = "please Enter product Name";
        } else if (header.length < 3) {
            isValid = false;
            formError["header"] =
                "Please Enter Minimun 3 Characters and do not use special Character";
        }  else if (header.length > 250) {
            isValid = false;
            formError["header"] =
              "Maximum 250 characters are allowed";
          }

        if (newsDescription === "") {
            isValid = false;
            formError["newsDescription"] = "Please Enter news Description!";
        }else if(newsDescription.length > 500){
            isValid = false;
            formError["newsDescription"]= "Maximum 500 characters are allowed";
        }

        if (selectedProduct.length < 1) {
            isValid = false;
            formError["productSelected"] = "Please select product";
        }

        if (selectedCategory.length < 1) {
            isValid = false;
            formError["selectedCategory"] = "Please select category";
        }

        if (NewsImage.length < 1) {
            isValid = false;
            formError["NewsImage"] = "Please select Image"

        }
        setFormError(formError);
        return isValid;
    };

    const saveData = async () => {
        const isvalid = validate();
        if (isvalid) {

            const fixTimezoneOffset = (date) => {

                if (!date) return "";

                return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();

            }
            const data = {
                ProductId: selectedProduct.value,
                NewsHeader: header,
                NewsDescription: newsDescription,
                NewsImage: NewsImage.name,
                NewsDateTime: fixTimezoneOffset(new Date()),
                BrandId: brandID,
                CategoryId: selectedCategory.value
            };
            let formdata = new FormData();
            formdata.append("file", NewsImage);
            await image_upload(formdata);
            postNewsData(data)
                .then((res) => {
                    Swal.fire({
                        icon: "success",
                        title: "Added Successfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "ok",
                    })
                    AllNews();
                    cancelUser()
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };

    const image_upload = async (data) => {

        await NewsImages(data)
            .then((res) => {
                console.log("photo res", res);
            })
            .catch((err) => {
                console.log(err, "err in photo submit");
            });
    };

    const GetData = (a) => {
        setShowlist(false)
        setupdate(true)
        setNewsID(a.newsid)
        setSelectedProduct({
            value: a.productid,
            label: a.productname,
        });
        setSelectedCategory({
            value: a.categoryid,
            label: a.categoryname
        })
        setHeader(a.newsheader)
        setNewsDescription(a.newsdescription)
        setNewsImage(a.newsimage)

    }

    const updateClick = async () => {

        const fixTimezoneOffset = (date) => {

            if (!date) return "";

            return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();

        }

        const data = {
            NewsId: newsID,
            ProductId: selectedProduct.value,
            NewsHeader: header,
            NewsDescription: newsDescription,
            NewsImage: NewsImage.name === undefined ? NewsImage : NewsImage.name,
            NewsDateTime: fixTimezoneOffset(new Date()),
            BrandId: brandID,
            CategoryId: selectedCategory.value
        };
        let formdata = new FormData();
        formdata.append("file", NewsImage);
        await image_upload(formdata);
        EditNewsData(data)
            .then((response) => {
                if (response.data._response.status === 1) {

                    Swal.fire({
                        icon: "success",
                        title: "Updated Successfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "ok",
                    });
                    AllNews();
                    cancelUser();
                }


            }).catch((err) => {
                console.log(err, "err in update news");
            })

    }

    const deleteUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        })
            .then((result) => {
                if (result.isConfirmed) {
                    DeleteNewsData(id.newsid)
                        .then((result) => {
                            Swal.fire({
                                icon: "success",
                                title: "Deleted Sucessfully",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });
                            AllNews();
                        })
                        .catch((error) => {
                            Swal.fire({
                                icon: "error",
                                title: "Not Deleted",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });
                        });
                }
            })
            .catch((err) => {
                console.log(err, "err in trash product");
            });
    };

    const cancelUser = () => {
        setSelectedCategory([]);
        setSelectedProduct([]);
        setupdate(false);
        setHeader('');
        setNewsDescription("");
        setNewsImagePath([]);
        inputRefrence.current.value = null;
        setFormError([]);
        setNewsImage("")

    }

    return (

        <div>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container">
                            <br /><br />
                            <div className="row mb-2">
                                {
                                    !showlist ?
                                        <div className="col-sm-12">
                                            <h1 className="m-0 text-center">Post News</h1>
                                        </div>
                                        :
                                        <div className="col-sm-12">
                                            <h1 className="m-0 text-center">News List</h1>
                                        </div>
                                }
                            </div>

                            <Col md={12} >
                                <div className='d-flex justify-content-end'>
                                    {
                                        !showlist ?
                                            <button
                                                type="button"
                                                className="btn btn-pos3 "
                                                onClick={() => {
                                                    setShowlist(true);
                                                    cancelUser();
                                                }}
                                            >
                                                <i className="fa-solid fa-list"></i> List
                                            </button>
                                            :
                                            <button
                                                type="button"
                                                className="btn btn-pos4 "
                                                onClick={() => setShowlist(false)}
                                            >
                                                <i className="fa fa-arrow-circle-left"></i> Back
                                            </button>
                                    }
                                </div>
                            </Col>
                        </div>
                    </div>

                    <section className="content consti-section">
                        <div className="container">
                            <div className="row justify-content-center">
                                {
                                    showlist == false ?
                                        <Col md={12}>
                                            <Card style={{ padding: "20px" }}>
                                                <Form.Group
                                                    as={Row}
                                                    className="mb-3 justify-content-center"
                                                    controlId="formHorizontalEmail"
                                                >
                                                    <Col sm={6}>
                                                        <Form.Label className="w-100"> Category </Form.Label>
                                                        <Select
                                                            className="w-100"
                                                            options={Categories}
                                                            onChange={handleCategory}
                                                            value={selectedCategory}
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                            }}
                                                        />
                                                        <div style={{ color: "red" }}>
                                                            {formError.selectedCategory}
                                                        </div>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Label className="w-100"> Product </Form.Label>
                                                        <Select
                                                            className="w-100"
                                                            options={ProductList}
                                                            onChange={HandleProduct}
                                                            value={selectedProduct}
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                            }}
                                                        />
                                                        <div style={{ color: "red" }}>
                                                            {formError.productSelected}
                                                        </div>
                                                    </Col>

                                                </Form.Group>
                                                <Form.Group
                                                    as={Row}
                                                    className="mb-3 justify-content-center"
                                                    // controlId="formHorizontalEmail"
                                                >
                                                    <Col sm={6}>
                                                        <Form.Label className="w-100">

                                                            News Header
                                                        </Form.Label>
                                                        <input
                                                            className="w-100 form-control"
                                                            type="text"
                                                            name="header"
                                                            value={header}
                                                            onChange={handleInput}
                                                        />
                                                        <div style={{ color: "red" }}>{formError.header}</div>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Label className="w-100">

                                                            Product Image
                                                        </Form.Label>
                                                        <Col md={6}>
                                                            <div className="imagepreview">
                                                                <img
                                                                    src={
                                                                        update === false
                                                                            ? NewsImagePath
                                                                            : NewsImage.name === undefined
                                                                                ? PhotoPath + NewsImage
                                                                                : NewsImagePath
                                                                    }
                                                                    alt=" "
                                                                    width="80"
                                                                    height="60"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <FormControl
                                                                className="w-100 form-control"
                                                                type="file"
                                                                name="NewsImage"
                                                                onChange={handleimage1}
                                                                ref={inputRefrence}

                                                            />
                                                        </Col>
                                                        <div style={{ color: "red" }}>
                                                            {formError.NewsImage}
                                                        </div>
                                                    </Col>

                                                </Form.Group>
                                                <Form.Group
                                                    as={Row}
                                                    className="mb-3 justify-content-center"
                                                    controlId="formHorizontalEmail"
                                                >
                                                    <Col sm={12}>
                                                        <Form.Label className="w-100">
                                                            News Description
                                                        </Form.Label>
                                                        <textarea
                                                            className="w-100 form-control"
                                                            placeholder="Description should be 500 characters only..."
                                                            rows="3"
                                                            onChange={handleDescription}
                                                            name="newsDescription"
                                                            value={newsDescription}
                                                        ></textarea>
                                                        <div style={{ color: "red" }}>
                                                            {formError.newsDescription}
                                                        </div>
                                                    </Col>
                                                </Form.Group>
                                                <div className="button_style m-0 text-center">
                                                    {update === false ? (
                                                        <Button
                                                            type="submit"
                                                            variant='contained'
                                                            onClick={saveData}
                                                        >
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant='contained'
                                                            color='success'
                                                            onClick={() => updateClick()}
                                                        >
                                                            Update
                                                        </Button>
                                                    )}{" "}


                                                    <Button
                                                        type="button"
                                                        variant='contained'
                                                        color='error'
                                                        onClick={() => cancelUser()}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </Card>
                                        </Col>
                                        :
                                        <Col className='mt-5' md={12}>
                                            <MaterialTable
                                                title="News"
                                                data={newsList}
                                                columns={columns}
                                                actions={[
                                                    {
                                                        icon: "edit",
                                                        tooltip: "Edit",
                                                        onClick: (event, rowData) => {
                                                            GetData(rowData);
                                                        },
                                                    },

                                                    {
                                                        icon: "delete",
                                                        tooltip: "Delete",
                                                        onClick: (event, rowData) => {
                                                            deleteUser(rowData);
                                                        },
                                                    },
                                                ]}
                                                options={{
                                                    search: false,
                                                    pageSize: 5,
                                                    showFirstLastPageButtons: false,
                                                    actionsColumnIndex: -1,
                                                    addRowPosition: "first",
                                                }}
                                            />
                                        </Col>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
