import React from 'react';
import '../footer/Footer.css';
import {FaFacebook,FaInstagram,FaTwitter,FaLinkedin}from 'react-icons/fa';
import logo1 from '../../../assets/img/logo1.png';
import IconButton from '@mui/material/IconButton';
import {BsFacebook,BsLinkedin,BsTwitter,BsInstagram} from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Footer = () => {

    return(
        <>
        <section className='footer-section'>
    
    <div className='container'>
    <div className='row text-center'>
    <div className='col'>
    <div className='footer-1'>
        <img src={logo1} alt="logo-tbr" width="120px" />
     <p>Top Brands Ranking is a blockchain e-voting platform 
that allows you to transparently Verify, Vote and Qualify <br/>
Brands that deserves to be conferred with various
Topscorebrand Awards held annually.</p>
     
     </div>
     <hr/>
     <div>
        <p>
        Graceland 2nd block 2nd floor, Parklands, Nairobi , Kenya. <br/>
+254722535472 | support@topbrandsranking.com
        </p>
     </div>
     <br/>
     <div className='d-flex justify-content-center'>
      <div>
        <a href="https://www.facebook.com/profile.php?id=100083006486592">
     <IconButton size="large" className='btn-icon m-2'><BsFacebook className='fb-icon' /></IconButton>
     </a>
      </div>
      {/* <div>
        <a href="">
     <IconButton size="large" className='btn-icon m-2'><BsLinkedin className='fb-icon' /></IconButton>
     </a>
      </div> */}
      <div>
        <a href="https://twitter.com/TopBrandsRanki1">
     <IconButton size="large" className='btn-icon m-2'><BsTwitter className='fb-icon' /></IconButton>
     </a>
      </div>
      <div>
        <a href="https://www.instagram.com/topscorebrands/">
     <IconButton size="large" className='btn-icon m-2'><BsInstagram className='fb-icon' /></IconButton>
     </a>
      </div>
      
     </div>
    </div>
    
    </div>
    </div>

    
    </section>

   <section className='footer-section-2 border-top'>
   <div className='container'>
   <div className='row text-center'>
   <div className=''>
   <p>Copyright © Topbrandsranking 2023. All rights reserved.</p>   </div>
   </div>
   </div>
   </section>
    </>
    )
}

export default Footer;