import React, { useEffect, useState, useRef } from "react";
import { Container, Form, Row, Col, Card, FormControl } from "react-bootstrap";
import axios from "axios";
import {
  getCountyData,
  ConstituencygetbyCounty,
  getWardbyConstituency,
  occupationlist, Educationlist,
  Agelist, MemberImage, getmember,
  NocountyList,NoAgelist,NoOccupationlist,NoEducationlist
} from "./ProductServiceAPI";
import Select from "react-select";
import {useNavigate } from "react-router-dom";
import ProductURL from '../../ServerCalls/ProductServer';
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import Button from '@mui/material/Button';
import MaterialTable, { MTableEditField } from "material-table";


const Memberadminregistration = () => {
  const [user, setUser] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Gender: "",
    Password: "",
    ConfirmPassword: "",
    Email: "",
    ContactNumber: "",
  });


  const [CountySelected, setCountySelected] = useState([]);
  const [ConstiSelected, setConstiSelected] = useState([]);
  const [WardSelected, setWardSelected] = useState([]);
  const [CountyOption, setCountyOption] = useState([]);
  const [ConstiOption, setConstiOption] = useState([]);
  const [WardOption, setWardOption] = useState([]);
  const [UserImage, setUserImage] = useState('');
  const [UserImagePath, setUserImagePath] = useState('');
  const [update, setupdate] = useState(false);
  const [genderSelected, setgenderSelected] = useState([]);
  const [AgeSelected, setAgeSelected] = useState([]);
  const [Showlist, setShowlist] = useState(false)
  const [ageOptions, setAgeOptions] = useState([]);
  const [occupationSelected, setOccupationSelected] = useState([]);
  const [EducationSelected, setEducationSelected] = useState([]);
  const [occupationOption, setOccupationOption] = useState([]);
  const [educationoptions, setEducationoptions] = useState([]);
  const PhotoPath = ProductURL.photoURL + "MemberMasterImages/";
  const [memberData, setmemberData] = useState([]);

  const inputRefrence1 = useRef(null);





  const columns = [
    { title: "First Name ", field: "firstname", align: "center" },
    { title: " Email ", field: "emailaddress", align: "center" },
   { title: " Contact Number ", field: "contactno1", align: "center" },
    {
      title: "Gender",
      field: "gender",
      align: "center",
    },
    { title: "Age Group ", field: "age", align: "center" },
    // { title: "Pincode ", field: "pincode", align: "center" },

  ];


  const handleChange = (e) => {
    const { name, value } = e.target;
    let val = name === "Email" ? value.trimStart().replace(/[^\a-zA-Z0-9_@." "]/ig, '') : value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, '')

    setUser({ ...user, [name]: val });

    // setBrand(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))

    if (e.target.name === "FirstName") {
      setFirstNameErr("");


    } else if (e.target.name === "Age") {
      setageError("");
    } else if (e.target.name === "Gender") {
      setgenderError("");
    }
    else if (e.target.name === "Password") {
      setPasswordErr("");
    } else if (e.target.name === "Email") {
      setEmailErr("");
    } else if (e.target.name === "ContactNumber") {
      setContactErr("");
    } else if (e.target.name === "ConfirmPassword") {
      setConfirmPasswordErr("");
    }
  };



  //error handlling

  const [FirstNameErr, setFirstNameErr] = useState("");
  const [ageError, setageError] = useState("");
  const [genderError, setgenderError] = useState("");
  const [PasswordErr, setPasswordErr] = useState("");
  const [ConfirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [EmailErr, setEmailErr] = useState("");
  const [ContactErr, setContactErr] = useState("");
  const [CountyErr, setCountyErr] = useState("");
  const [ConstiErr, setConstiErr] = useState("");
  const [WardErr, setWardErr] = useState("");
  const [imgErr, setImgErr] = useState("")
  const [OccupationErr, setOccupationErr] = useState("");
  const [EducationErr, setEducationErr] = useState("");



  const navigate = useNavigate();


  const formValidate = () => {
    let FirstNameErr = "";
    let ageError = "";
    let genderError = "";
    let PasswordErr = "";
    let ConfirmPasswordErr = "";
    let EmailErr = "";
    let ContactErr = "";
    let CountyErr = "";
    let ConstiErr = "";
    let WardErr = "";
    let EducationErr = "";
    let OccupationErr = "";
    let regex = /^[a-z]+$/i;
    let emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let contactregx = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
    let passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;


    if (user.FirstName.length < 3) {
      FirstNameErr = "Please Enter Minimun 3 Characters and do not use special Character";
    }

    // if (regex.test(user.FirstName) === false) {
    //   FirstNameErr = "Please Enter alphabetic characters and no spaces";

    // }
    else if(user.FirstName.length > 100 ){

      FirstNameErr =  "Maximum 100 characters are allowed";
    }

    if (user.FirstName === "") {
      FirstNameErr = "Please Enter Your First Name";
    }


    if (AgeSelected.length < 1) {
      ageError = "Please select Age";
    }

    if (user.Email === "") {
      EmailErr = "Please Enter Your Email";
    }

    if (emailregex.test(user.Email) === false) {
      EmailErr = "Please Enter a valid email";
    }

    if (user.ContactNumber === "") {
      ContactErr = "Please Enter Your Contact";
    }
    else if (user.ContactNumber.length > 20) {
      ContactErr = 'Please enter valid phone number';
    }
    if (user.ConfirmPassword !== user.Password) {
      PasswordErr = "Password And Confirm Password Do not match";
    }
    if (user.Password === "") {
      PasswordErr = "Please Enter your Password";
    }
    else if (passregex.test(user.Password) === false) {
      PasswordErr = "Password must combination of Number,Capital letter, special character and min length is 8 !!";
    }
    if (user.ConfirmPassword === "") {
      ConfirmPasswordErr = "Please Enter Your Confirm password";
    }

    if (genderSelected.length < 1) {
      genderError = "Please select Your Gender";
    }
    if (CountySelected.length < 1) {
      CountyErr = "Please Select County";
    }

    if (ConstiSelected.length < 1) {
      ConstiErr = "Please Select Constituency";
    }
    if (WardSelected.length < 1) {
      WardErr = "Please Select Ward";
    }

    if (EducationSelected.length < 1) {
      EducationErr = "Please select Your Education";
    }


    if (occupationSelected.length < 1) {
      OccupationErr = "Please select Your Occupation";
    }
    if (
      FirstNameErr ||
      ageError ||
      genderError ||
      PasswordErr ||
      ConfirmPasswordErr ||
      EmailErr ||
      CountyErr ||
      ConstiErr ||
      WardErr ||
      OccupationErr ||
      EducationErr ||
      ContactErr
    ) {
      setFirstNameErr(FirstNameErr);
      setEmailErr(EmailErr);
      setContactErr(ContactErr);
      setPasswordErr(PasswordErr);
      setConfirmPasswordErr(ConfirmPasswordErr);
      setageError(ageError);
      setCountyErr(CountyErr);
      setConstiErr(ConstiErr);
      setWardErr(WardErr);
      setgenderError(genderError);
      setEducationErr(EducationErr);
      setOccupationErr(OccupationErr);
      return false;
    }
    return true;
  };


  const genderoption = [
    { value: '1', label: 'Male' },
    { value: '2', label: 'Female' },
    { value: '3', label: 'Other' }
  ]

  const handleGender = (selected) => {
    setgenderSelected(selected)
    setgenderError("")

  }

  const handleAge = (selected) => {

    setAgeSelected(selected)
    setageError("")

  }

  useEffect(() => {
    ages();
  }, []);


  const ages = () => {
    NoAgelist().then((res) => {
      if (res.data._response.status === 1) {
        let age = res.data._response.data.getages
        let option = age.map((a) => ({
          value: a.ageId,
          label: a.ageDropdown
        }))
        setAgeOptions(option)
      }
    })
    .catch((err)=>{
      console.log(err ,'err in get agelist');
    })
  }

  useEffect(() => {

    occupation();
    education();
  }, []);



  const occupation = () => {
    NoOccupationlist().then((res) => {
      if (res.data._response.status === 1) {
        let occupations = res.data._response.data.getoccupation
        let option = occupations.map((a) => ({
          value: a.occupationId,
          label: a.occupationDropdown
        }))
        setOccupationOption(option)
      }
    })
    .catch((err)=>{
      console.log(err,'err in get occupationlist');
    })
  }


  const education = () => {
    NoEducationlist().then((res) => {
      if (res.data._response.status === 1) {
        let educations = res.data._response.data.geteducation
        let option = educations.map((a) => ({
          value: a.educationId,
          label: a.educationDropdown
        }))
        setEducationoptions(option)
      }
    })
    .catch((err)=>{
      console.log(err,'err in get educationlist');
    })
  }




  const handleOccupation = (selected) => {
    setOccupationSelected(selected)
    setOccupationErr("")
  }

  const handleEducation = (selected) => {
    setEducationSelected(selected)
    setEducationErr("")
  }

  const image_upload = async (data) => {

    await axios.post(ProductURL.userPhoto + "/SaveFile", data)
      .then((res) => {
        console.log("photo res", res);
      })
      .catch((err) => {
        console.log(err, "err in photo submit");
      });
  };





  const handleimage = (e) => {
    setUserImage(e.target.files[0]);
    setUserImagePath(URL.createObjectURL(e.target.files[0]));
    setImgErr("")
  };
  useEffect(() => {
    CountyData();
    // getConstiData();
    // getWardData();
  }, []);

  const CountyData = () => {
    NocountyList()
      .then((res) => {
        let county = res.data._response.data.getcountywithnocounty;
        let countylist = county.map((a) => ({
          value: a.countyId,
          label: a.countyName,
        }));
        setCountyOption(countylist);
      })
      .catch((err)=>{
        console.log(err, 'err in get countyoptions');
      })

  };

  useEffect(() => {
    getmember()
      .then(response => {
        let member = response.data._response.data.member
        setmemberData(member)
      })
  }, [])





  const saveForm = async () => {

    const isValid = formValidate();
    if (isValid) {
      const data = {
        //  MemberId : auth,
        FirstName: user.FirstName,
        Age: parseInt(AgeSelected.value),
        Gender: genderSelected.label,
        Password: user.Password,
        EmailAddress: user.Email,
        ContactNo1: user.ContactNumber,
        CountyId: CountySelected.value,
        ConstituencyId: ConstiSelected.value,
        WardId: WardSelected.value,
        Occupation: parseInt(occupationSelected.value),
        EducationLevel: parseInt(EducationSelected.value),
        PhotoFileName: UserImage.name,
        Role: 2,
      };

      let formdata = new FormData();
      formdata.append("file", UserImage);
      await image_upload(formdata);

      axios.post("https://api.topbrandsranking.com/Authorization/AddUserData", data)
        .then((response) => {
          if(response.data._response.status === 1){ 
          Swal.fire({
            icon: "success",
            title: "Registration Successful, Kindly verify your email by clicking on the verification link sent on your email address.",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          handleReset()
        }else if(response.data._response.status === 2){
          Swal.fire({
            icon: "warning",
            title: response.data._response.message,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          });
          navigate('/MemberRegistration')
        }
        })
        .catch((err) => {
          console.log(err)
        });
    }
  };

  const handleReset = () => {
    setUser({
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Address: "",
      Gender: "",
      PinCode: "",
      Password: "",
      Email: "",
      ContactNumber: "",
      ConfirmPassword: "",
      ProfileImg: "",
    });
    inputRefrence1.current.value = null;
    setUserImagePath('')
    setCountySelected("");
    setConstiSelected("");
    setWardSelected("");
    setFirstNameErr("");
    setEmailErr("");
    setContactErr("");
    setPasswordErr("");
    setageError("");
    setgenderError("");
    setConfirmPasswordErr("");
    setCountyErr("");
    setConstiErr("");
    setWardErr("");
    setUserImage('')
    setOccupationErr("");
    setEducationErr("");
    setOccupationSelected("");
    setEducationSelected("");
    setAgeSelected("")
  };


  const handleCounty = (selected) => {
    setCountySelected(selected);
    ConstituencygetbyCounty(selected.value)
      .then((response) => {
        const consti = response.data._response.data.constibycountyid
        const constiData = consti.map((a) => ({
          value: a.constituencyId,
          label: a.constituencyName

        }));
        setConstiOption(constiData)
      })
      .catch((err)=>{
        console.log(err , 'err in get constituencylist ');
      })
    setCountyErr("")
  };

  const handleConsti = (selected) => {
    setConstiSelected(selected);
    getWardbyConstituency(selected.value)
      .then((response) => {
        const ward = response.data._response.data.wardgetconstituencyid
        const wardData = ward.map((a) => ({
          value: a.wardId,
          label: a.wardName

        }));
        setWardOption(wardData)
      })
      .catch((err)=>{
        console.log(err,'err in getlist');
      })
    setConstiErr("")
  };

  const handleWard = (selected) => {
    setWardSelected(selected);
    setWardErr("")
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <br />
            <Row>
              <Col sm={12}>
                {!Showlist?(
                <h1 className="m-0 text-center register-heading">
                  ADD MEMBER
                </h1>
                ):
                <h1 className="m-0 text-center register-heading">
                  MEMBER LIST
                </h1>
                }
              </Col>
            </Row>
            <Col md={12} >
              <div className='d-flex justify-content-end'>
                {!Showlist ?
                  <button
                    type="button"
                    className="btn btn-pos3 "
                    onClick={() => {
                      setShowlist(true);
                      // handleclear();
                    }
                    }
                  >
                    <i className="fa-solid fa-list"></i> List
                  </button>
                  :
                  <button
                    type="button"
                    className="btn btn-pos4 "
                    onClick={() => setShowlist(false)}
                  >
                    <i className="fa fa-arrow-circle-left"></i> Back
                  </button>
                }
              </div>
            </Col>
            <br />

            <section className="ad-member-form">
             {!Showlist?(
         
                <Container>

                
                  <Card className="p-4">
                    <Card.Body>
                      <Row className="gx-5 d-flex justify-content-center">
                        <Col sm={12}>


                          <div>
                            <Form.Group
                              as={Row}
                              className="mb-3 mt-4 justify-content-center"
                              controlId="formHorizontalEmail"
                            >
                              <Col sm={6}>
                                <Form.Label className="w-100">
                                  Name
                                </Form.Label>

                                <Form.Control
                                  className="w-100"
                                  type="text"
                                  value={user.FirstName}
                                  name="FirstName"
                                  onChange={handleChange}
                                />

                                <div style={{ color: "red", fontSize: "13px" }}>
                                  {FirstNameErr}
                                </div>
                              </Col>
                              <Col sm={6}>
                                <Form.Label className="w-100">Age</Form.Label>

                                <Select
                                  className="w-100"
                                  options={ageOptions}
                                  onChange={handleAge}
                                  value={AgeSelected}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                                <br/>
                                <div style={{ color: "red", fontSize: "13px" }}>
                                  {ageError}
                                </div>
                              </Col>

                            </Form.Group>



                            <Form.Group
                              as={Row}
                              className="mb-3 justify-content-center"
                              controlId="formHorizontalEmail"
                            >

                              <Col sm={6}>
                                <Form.Label className="w-100">Gender</Form.Label>



                                <Select
                                  className="w-100"
                                  options={genderoption}
                                  onChange={handleGender}
                                  value={genderSelected}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                                <br/>
                                <div style={{ color: "red", fontSize: "13px" }}>
                                  {genderError}
                                </div>

                              </Col>
                              <Col sm={6}>
                                <Form.Label className="w-100">Password*</Form.Label>

                                <Form.Control
                                  className="w-100"
                                  type="password"
                                  value={user.Password}
                                  name="Password"
                                  onChange={handleChange}
                                />
                                <br/>
                                <div style={{ color: "red", fontSize: "13px" }}>
                                  {PasswordErr}
                                </div>
                              </Col>
                            </Form.Group>


                            <Form.Group
                              as={Row}
                              className="mb-3 justify-content-center"
                              controlId="formHorizontalEmail"
                            >
                              <Col sm={6}>
                                <Form.Label className="w-100">
                                  Confirm Password*
                                </Form.Label>

                                <Form.Control
                                  className="w-100"
                                  type="password"
                                  value={user.ConfirmPassword}
                                  name="ConfirmPassword"
                                  onChange={handleChange}
                                />
                                <br/>
                                <div style={{ color: "red", fontSize: "13px" }}>
                                  {ConfirmPasswordErr}
                                </div>
                              </Col>
                              <Col sm={6}>
                                <Form.Label className="w-100">County</Form.Label>
                                <Select
                                  className="w-100"
                                  options={CountyOption}
                                  onChange={handleCounty}
                                  value={CountySelected}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                                <br/>
                                <div style={{ color: "red", fontSize: "13px" }}>
                                  {CountyErr}
                                </div>
                              </Col>
                            </Form.Group>

                            <Form.Group
                              as={Row}
                              className="mb-3 justify-content-center"
                              controlId="formHorizontalEmail"
                            >
                              <Col sm={6}>
                                <Form.Label className="w-100">
                                  Constituency
                                </Form.Label>

                                <Select
                                  className="w-100"
                                  options={ConstiOption}
                                  onChange={handleConsti}
                                  value={ConstiSelected}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                                <br/>
                                <div style={{ color: "red", fontSize: "13px" }}>
                                  {ConstiErr}
                                </div>
                              </Col>
                              <Col sm={6}>
                                <Form.Label className="w-100">Ward</Form.Label>

                                <Select
                                  className="w-100"section
                                  options={WardOption}
                                  onChange={handleWard}
                                  value={WardSelected}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                                <br/>
                                <div style={{ color: "red", fontSize: "13px" }}>
                                  {WardErr}
                                </div>
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3 justify-content-center"
                              controlId="formHorizontalEmail"
                            >
                              <Col sm={6}>
                                <Form.Label className="w-100">
                                  Contact No:
                                </Form.Label>

                                <Form.Control
                                  className="w-100"
                                  type="number"
                                  value={user.ContactNumber}
                                  name="ContactNumber"
                                  onChange={handleChange}
                                />

                                <div style={{ color: "red", fontSize: "13px" }}>
                                  {ContactErr}
                                </div>
                              </Col>
                              <Col sm={6}>
                                <Form.Label className="w-100">
                                  Email Address
                                </Form.Label>

                                <Form.Control
                                  className="w-100"
                                  type="text"
                                  value={user.Email}
                                  name="Email"
                                  onChange={handleChange}
                                />
                                <div style={{ color: "red", fontSize: "13px" }}>
                                  {EmailErr}
                                </div>
                              </Col>
                            </Form.Group>


                            <Form.Group
                              as={Row}
                              className="mb-3 justify-content-center"
                              controlId="formHorizontalEmail"
                            >
                              <Col sm={6}>
                                <Form.Label className="w-100">
                                  Occupation
                                </Form.Label>

                                <Select
                                  className="w-100"
                                  options={occupationOption}
                                  onChange={handleOccupation}
                                  value={occupationSelected}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                                <br/>
                                <div style={{ color: "red", fontSize: 13 }}>
                                  {OccupationErr}
                                </div>
                              </Col>
                              <Col sm={6}>
                                <Form.Label className="w-100">Education Level</Form.Label>

                                <Select
                                  className="w-100"
                                  options={educationoptions}
                                  onChange={handleEducation}
                                  value={EducationSelected}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                                <br/>
                                <div style={{ color: "red", fontSize: 13}}>
                                  {EducationErr}
                                </div>
                              </Col>
                            </Form.Group>

                            <Form.Group
                              as={Row}
                              className="mb-3 justify-content-center"
                              controlId="formHorizontalEmail"
                            >

                            </Form.Group>
                            <Col sm={6} className="mb-4">
                              <Form.Label className="w-100"> User Image</Form.Label>
                              <Col md={6}>
                                <div className="imagepreview">
                                  <img
                                    alt="Please select"
                                    width="80"
                                    height="60"
                                    src={update === false ? UserImagePath : UserImage.name === undefined ? PhotoPath + UserImage : UserImagePath}
                                  />
                                </div>
                              </Col>
                              <FormControl
                                className="m-2"
                                type="file"
                                name="file"
                                onChange={handleimage}
                                ref={inputRefrence1}
                              />
                              <br/>
                              <div style={{ color: "red", fontSize: "13px" }}>
                                {imgErr}
                              </div>
                            </Col>

                            <div className="button_style m-0 text-center">
                              <Button
                                type="submit"
                                variant="contained"

                                onClick={saveForm}
                                style={{ marginBottom: '5px' }}
                              >
                                Submit
                              </Button>
                              {" "} {" "}
                              <Button
                                type="button"
                                variant="contained"
                                color="error"
                                onClick={() => handleReset()}
                                style={{ marginBottom: '5px' }}
                              >
                                Cancel
                              </Button>
                            </div>
                            <br />

                          </div>
                        </Col>
                        </Row>
                       </Card.Body>
                        </Card>
                        </Container>
                       
  ):


                        <div>
                          <div className="content-header">
                            <div className="container-fluid">
                            
                            </div>
                            <br />

                            <div className="container">
                              <MaterialTable
                                title={false}
                                data={memberData}
                                columns={columns}

                                options={{
                                  search: true,
                                  pageSize: 10,
                                  showFirstLastPageButtons: false,
                                  actionsColumnIndex: -1,
                                  addRowPosition: "first",
                                  pageSizeOptions: [10, 15, 20, 50],
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        }                  
          </section>     
          </div>
        </div>
      </div>
    </>
  );
};

export default Memberadminregistration;
