import React, { useState, useEffect, useRef,useCallback } from "react";
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import Swal from "sweetalert2";
import MaterialTable from "material-table";
import {  AddGeneralNews, getGeneralNews, editGeneralNews, GeneralNewsImage, deleteGeneralNews } from "./ProductServiceAPI";
import ProductURL from "../../ServerCalls/ProductServer";
import Button from '@mui/material/Button';
import DatePicker from "react-datepicker";
import ReactCrop from 'react-image-crop';
import Cropper from 'react-easy-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useLocation } from "react-router-dom";


const GeneralNews = () => {
  const columns = [
   
    {
      title: "News Header",
      field: "newsHeader",
      align: "center",
    },
    {
      title: "News Description",
      field: "newsDescription",
      align: "center",
    },
    {
        title: "News Date",
        field: "newsDatetime",
        align: "center",
        type: "date",
      },
    {
      title: "News Image",
      field: "newsImages",
      align: "center",
    },

  ];

  const [formError, setFormError] = useState([]);
  const [update, setupdate] = useState(false);
  const [header, setHeader] = useState("");
  const [newsDescription, setNewsDescription] = useState("");
  const [NewsImage, setNewsImage] = useState("");
  const [NewsImagePath, setNewsImagePath] = useState([]);
  const [NewsListData, setNewsListData] = useState([]);
  const [Generalnews, setGeneralNews] = useState(0);
  const [Showlist, setShowlist] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  // const [zoom, setZoom] = useState(1)
  const [output, setOutput] = useState(null);
  const [NewsDate, setNewsDate] = useState(new Date());
  const PhotoPath = ProductURL.photoURL + "GeneralNewsImages/";
  const inputRefrence = useRef(null);
  const jumptoBack = useLocation();

  // const inputRefrence = useRef()


  const validate = () => {
    let formError = {};
    let isValid = true;
    // let regex = /^[A-Za-z0-9 ]+$/;

    if (header === "") {
      isValid = false;
      formError["header"] = "please Enter header";

    } else if (header.length < 3) {
      isValid = false;
      formError["header"] =
        "Please Enter Minimun 3 Characters ";

    }else if(header.length > 250){
      isValid = false;
      formError["header"] =
        "Maximum 250 Characters are allowed ";
  }

    if (newsDescription === "") {
      isValid = false;
      formError["newsDescription"] = "Please Enter campaign Description!";
    }
    // else if(newsDescription.length > 500){
    //   isValid = false;
    //   formError["newsDescription"] = "Maximum 500 characters are allowed";
    //     }
  
    debugger
  
    if (NewsImage.length < 1) {
      isValid = false;
      formError["NewsImage"] = "Please select Image"

    }
  //   else if(NewsImage.width !== 300 * 400){
  //     isValid = false;
  //    formError["NewsImage"] = "Please select Image fix size"
  // }

    if (NewsDate === "") {
      isValid = false;
      formError["NewsDate"] = "please Enter Date";

    }
    

    setFormError(formError);
    return isValid;
  };


  const handleInput = (e) => {
    setHeader(e.target.value);
    setFormError({ ...formError, header: "" });
  };

  const handleDescription = (e) => {
    setNewsDescription(e.target.value);
    setFormError({ ...formError, newsDescription: "" });
  };

  const handleimage1 = (e) => {
    setNewsImage(e.target.files[0]);
    setNewsImagePath(URL.createObjectURL(e.target.files[0]));
    setFormError({ ...formError, NewsImage: "" });

  };

  useEffect(() => {
    setShowlist(false);
    NewsData();

  }, [jumptoBack.key]);


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
  }, [])



  const cropImageNow = () => {
    const canvas = document.createElement('canvas');
    const scaleX = NewsImage.naturalWidth / NewsImage.width;
    const scaleY = NewsImage.naturalHeight / NewsImage.height;
    canvas.width =20;
    canvas.height =20;
    const ctx = canvas.getContext('2d');
  
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
  
    ctx.drawImage(
      NewsImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
      
    // Converting to base64
    const base64Image = canvas.toDataURL('image/jpeg');
    console.log(base64Image,"gg")
    setOutput(base64Image,PhotoPath+NewsImagePath);
  };




  const image_upload = async (data) => {
    //  axios.post("https://localhost:5002/api/ProductManagement/GeneralNews/SaveFile",data)
GeneralNewsImage(data)
      .then((res) => {
        console.log("photo res", res);
      })
      .catch((err) => {
        console.log(err, "err in photo submit");
      });
  };

  const saveData = async () => {

    const isvalid = validate();
    if (isvalid) {

      const fixTimezoneOffset = (date) => {

        if (!date) return "";

        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();

      }
      const data = {
        NewsHeader: header,
        NewsDescription: newsDescription,
        NewsImages: NewsImage.name,
        NewsDatetime:fixTimezoneOffset(new Date(NewsDate.getFullYear()
        , NewsDate.getMonth()
        , NewsDate.getDate()
        , 23, 59, 59)),
      };
      let formdata = new FormData();
      formdata.append("file", NewsImage);
      await image_upload(formdata);
    //   axios.post("https://localhost:5002/api/ProductManagement/GeneralNewsData",data)
        AddGeneralNews(data)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "News Added Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          })
          NewsData()
          cancelUser()
        })
        .catch((error) => {
          console.log(error);
        })
    }
  };



 


  const NewsData = () => {
        // axios.get("https://localhost:5002/api/ProductManagement/GetGeneralNews")
       getGeneralNews()
      .then((response) => {
        const list = response.data._response.data.getgeneralnews
        setNewsListData(list)
      }).catch((err) => {
        console.log(err);
      })

  }

  const updateUser = (a) => {
    setShowlist(false);
    setupdate(true);
      if(a.generalNewsId){
        setGeneralNews(a.generalNewsId)
        setHeader(a.newsHeader)
        setNewsDate(new Date(a.newsDatetime))
        setNewsDescription(a.newsDescription)
        setNewsImage(a.newsImages)
      }

    

  }

  const updateClick = async () => {

    const fixTimezoneOffset = (date) => {

      if (!date) return "";

      return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();

    }

    const data = {
      GeneralNewsId: Generalnews,
      NewsHeader: header,
      NewsDescription: newsDescription,
      NewsImages: NewsImage.name === undefined ? NewsImage : NewsImage.name,
      NewsDatetime:fixTimezoneOffset(new Date(NewsDate.getFullYear()
      , NewsDate.getMonth()
      , NewsDate.getDate()
      , 23, 59, 59)),
    
    };
    let formdata = new FormData();
    formdata.append("file", NewsImage);
    await image_upload(formdata);
    // axios.put("https://localhost:5002/api/ProductManagement/EditGeneralNews",data)
     editGeneralNews(data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "News Updated Successfully",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ok",
        });
        NewsData()
        cancelUser();

      }).catch((err) => {
        Swal.fire({
          icon: "error",
          title: "News Not Updated",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
      })

  }

  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
            // axios.delete(`https://localhost:5002/api/ProductManagement/RemoveGeneralNews/${id}`)
           deleteGeneralNews(id)
            .then((result) => {
              Swal.fire({
                icon: "success",
                title: "News Deleted Sucessfully",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              NewsData();
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "News Not Deleted",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err, "err in trash product");
      });
  };

  const cancelUser = () => {
    setupdate(false);
    setHeader("");
    setNewsDescription("");
    setNewsImagePath("");
    setNewsImage("")
    inputRefrence.current.value = null;
    setFormError("");
    setNewsDate("")
   

  };

  return (


    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <br /><br />
            <div className="row mb-2">
              {!Showlist ? (
                <div className="col-sm-12">
                  <h1 className="m-0 text-center"> ADD GENERAL NEWS </h1>
                </div>
              ) :
                <div className="col-sm-12">
                  <h1 className="m-0 text-center"> GENERAL NEWS LIST </h1>
                </div>
              }
            </div>
     
          <Col md={12} >
            <div className='d-flex justify-content-end'>
              {!Showlist ?
                <button
                  type="button"
                  className="btn btn-pos3 "
                  onClick={() => setShowlist(true)}
                >
                  <i className="fa-solid fa-list"></i> List
                </button>
                :
                <button
                  type="button"
                  className="btn btn-pos4 "
                  onClick={() => setShowlist(false)}
                >
                  <i className="fa fa-arrow-circle-left"></i> back
                </button>
              }
            </div>
          </Col>
          </div>
        </div>
        <section className="content  consti-section">
          <div className="container">
            {!Showlist ? (
              <div className="row">
                <Col
                  md={12}
                >
                  <Card
                    style={{
                      padding: "20px"
                    }}
                  >
                    {/* <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={6}>
                        <Form.Label className="w-100"> Product </Form.Label>
                        <Select
                          className="w-100"
                          options={ProductOption}
                          onChange={HandleProduct}
                          value={productSelected}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <div style={{ color: "red" }}>
                          {formError.productSelected}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <Form.Label className="w-100"> Product Brand </Form.Label>
                        <Select
                          className="w-100"
                          options={brandOption}
                          // onChange={HandleBrand}
                          value={brandSelected}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                        <div style={{ color: "red" }}>
                          {formError.brandSelected}
                        </div>
                      </Col>
                    </Form.Group> */}
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      controlId="formHorizontalEmail"
                    >
                   
                      <Col sm={6}>
                        <Form.Label className="w-100">

                          News Header
                        </Form.Label>
                        <input
                          className="w-100 form-control"
                          type="text"
                          name="header"
                          value={header}
                          autoComplete="off"
                          onChange={handleInput}
                        />
                        <div style={{ color: "red" }}>{formError.header}</div>
                      </Col>

                     
                       <Col sm={6}>
                          <Form.Label className="w-100">
                           News Date
                          </Form.Label>
                          <DatePicker
                            className="w-100 form-control"
                            selected={NewsDate}
                            value={NewsDate}
                            popperProps={{strategy: 'fixed'}}
                            minDate={new Date()}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={50}
                            name="NewsDate"
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => {
                              setNewsDate(date);
                              setFormError({ ...formError,NewsDate:""})
                            }}
                            autoComplete="off"
                          />
                         
                          <div style={{ color: "red" }}>
                          {formError.NewsDate}
                        </div>
                         
                        </Col>  
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-center"
                      // controlId="formHorizontalEmail"
                    >

                      <Col sm={6}>
                        <Form.Label className="w-100">
                          News Description
                        </Form.Label>
                        <textarea
                          className="w-100 form-control"
                          placeholder="Tell us more"
                          rows="3"
                          onChange={handleDescription}
                          name="newsDescription"
                          value={newsDescription}
                        ></textarea>
                        <div style={{ color: "red" }}>
                          {formError.newsDescription}
                        </div>
                      </Col>    
                                  
                      <Col sm={6}>
                        <Form.Label className="w-100">

                          News Image
                        </Form.Label>
                      
                        <FormControl
                            className="w-100 form-control"
                            type="file"
                            name="file"
                            onChange={handleimage1}
                            ref={inputRefrence}
                            controlId={NewsImage}
                            style={{diplay:"none"}}
                          />

                          <div className="imagepreview">
                            <img
                              src={
                                update === false
                                  ? NewsImagePath
                                  : NewsImage.name === undefined
                                    ? PhotoPath + NewsImage
                                    : NewsImagePath
                              }
                              alt=" "
                              width="200"
                              height="200"
                            />
                          </div>
                       <br/>
                        <div style={{ color: "red" }}>
                          {formError.NewsImage}
                        </div>
                      </Col>
                    </Form.Group>
                    <div className="button_style m-0 text-center">
                      {update === false ? (
                        <Button
                          type="submit"
                          variant='contained'
                          onClick={saveData}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                        type="submit"
                          variant='contained'
                          color='success'
                          onClick={() => updateClick()}
                        >
                          Update
                        </Button>
                      )} {" "}

                      <Button                     
                        type="button"
                        variant='contained'
                        color='error'
                        onClick={() => cancelUser()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Card>
                </Col>
              </div>
            ) :
              <>
                <br />
                <br />
                <br />
                <Col className='mt-0' md={12} >
                  <MaterialTable
                    title={false}
                    data={NewsListData}
                    columns={columns}
                    actions={[
                      {
                        icon: "edit",
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                            console.log(rowData,"got rowdata")
                          updateUser(rowData);
                        },
                      },

                      {
                        icon: "delete",
                        tooltip: "Delete",
                        onClick: (event, rowData) => {
                          deleteUser(rowData.generalNewsId);
                        },
                      },
                    ]}
                    options={{
                      search: true,
                      pageSize: 10,
                      showFirstLastPageButtons: false,
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      pageSizeOptions: [10, 15, 20, 50],
                    }}
                  />
                </Col>
              </>
            }
          </div>
        </section>
      </div>

    </div>
  );
};

export default GeneralNews;
