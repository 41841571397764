import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Card } from "react-bootstrap";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from "sweetalert2";
import Button from '@mui/material/Button';

import { wardList, constituencyList, addWard, editWard, trashWard ,NoWardlist,NoConstilist} from '../Admin/ProductServiceAPI';

export default function Ward() {

    const [data, setData] = useState([]);

    const [ward, setWard] = useState('');
    const [selectedConsti, setSelectedConsti] = useState('');
    const [wardID, setWardID] = useState('');
    const [ConstiOptions, setConstiOptions] = useState([]);
    const [update, setUpdate] = useState(false)

    const [WardError, setWardError] = useState('');
    const [constituencyError, setConstituencyError] = useState('');

    const columns = [
        { title: "Ward", field: "wardname", align: "center" },
        { title: "Parent Constituency", field: "constituencyname", align: "center" },
    ];


    const handleWard = (e) => {

        setWard(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''));
        setWardError('');

    }

    const handleConsti = (selected) => {

        setSelectedConsti(selected);
        setConstituencyError('');

    }

    useEffect(() => {

        getlist();
        constiList();

    }, [])

    const getlist = () => {
        NoWardlist().then((res) => {
            if (res.data._response.status === 1) {
                setData(res.data._response.data.getward)
            }
        })
            .catch((err) => {
                console.log(err, "err in get wardlist ");
            })
    }

    const constiList = () => {
        NoConstilist().then((res) => {

            if (res.data._response.status === 1) {
                let arr = [];
                let data = res.data._response.data.getconstituency;

                data.map((a) => (

                    arr.push({
                        label: a.constituencyname,
                        value: a.constituencyid
                    })
                ))
                setConstiOptions(arr);
            }

        })
            .catch((err) => {
                console.log(err, "err in Wardlist");
            })
    }

    const FormValidation = () => {

        let WardError = '';
        let constituencyError = '';
        let regex = /^[A-Za-z0-9 ]+$/;


        if (ward === '') {

            WardError = "Please fill the Details";

        } else if (regex.test(ward) === false) {

            WardError = 'Please do not use special Character'

        }else if(ward.length > 50){
            WardError = "Maximum 50 characters are allowed";
        }

        if (selectedConsti.length < 1) {

            constituencyError = "Please Select Constituency";

        }

        if (WardError || constituencyError) {

            setWardError(WardError);
            setConstituencyError(constituencyError);


            return false;
        }

        return true;
    }

    const saveData = () => {
        const isValid = FormValidation();
        if (isValid) {

            const allData = {

                wardName: ward,
                constituencyId: selectedConsti.value.toString(),

            }
            addWard(allData).then((res) => {
                if (res.data._response.status === 1) {
                    Swal.fire({
                        icon: "success",
                        title: "Ward Added Sucessfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });
                    getlist();
                    clearData();
                }
            })
                .catch((err) => {
                    console.log(err, "err in add Ward");
                })
        }
    }

    const getData = (rowdata) => {
        setUpdate(true);
        setWardID(rowdata.wardid);
        setWard(rowdata.wardname);
        setSelectedConsti({ label: rowdata.constituencyname, value: rowdata.constituencyid });

    }

    const editData = () => {
        const isValid = FormValidation();

        if (isValid) {

            const allData = {
                wardId: wardID,
                wardName: ward,
                constituencyId: selectedConsti.value.toString(),
            }
            editWard(allData).then((res) => {
                if (res.data._response.status === 1) {
                    Swal.fire({
                        icon: "success",
                        title: "Ward Updated Sucessfully",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        // cancelButtonColor: '#d33',
                        confirmButtonText: "ok",
                    });
                    getlist();
                    clearData();
                }
            })
                .catch((err) => {
                    console.log(err, "err in update Ward");
                })
        }
    }

    const trashData = (rowData) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {

            if (result.isConfirmed) {

                trashWard(rowData.wardid).then((res) => {
                    if (res.data._response.status === 1) {

                        Swal.fire({
                            icon: "success",
                            title: "Ward Deleted Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        getlist();
                    }else if(res.data._response.status === 2){
          
                        Swal.fire({
                          icon: "warning",
                          title: res.data._response.message,
                          showConfirmButton: true,
                          confirmButtonColor: "#3085d6",
                          // cancelButtonColor: '#d33',
                          confirmButtonText: "ok",
                      });
          
          
                  }
          
              })
             
          }
          })
                .catch((err) => {
                  console.log(err, "err in trash product");
                });
            };
          

    const clearData = () => {
        setUpdate(false)
        setWard('');
        setSelectedConsti('')
        setWardError('')
        setConstituencyError('')
    }


    return (
        <div>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                        <br/><br/>
                            <div className="row">
                                <div className="col-sm-12">
                                    {!update ?(
                                    <h1 className="m-0 text-center">WARD INFORMATION</h1>
                                    ):
                                    <h1 className="m-0 text-center">EDIT WARD INFORMATION</h1>
                                }
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content  consti-section">
                        <div className="container">
                            <div className="row">
                                <Col md={12}>
                                    <Card style={{ padding: "30px"}}>
                                        <Form.Group
                                            as={Row}
                                            className="mb-1 justify-content-center"
                                            controlId="formHorizontalEmail"
                                        >
                                            <Col sm={6} className="mb-4">
                                                <Form.Label className="w-100">Ward Name</Form.Label>
                                                <input
                                                    type="text"
                                                    value={ward}
                                                    onChange={handleWard}
                                                    className="w-100 form-control"
                                                />
                                                <div style={{ color: "red",fontSize: 13  }}>{WardError}</div>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Label className="w-100">
                                                    Parent Constituency
                                                </Form.Label>
                                                <Select
                                                    className="w-100"
                                                    options={ConstiOptions}
                                                    value={selectedConsti}
                                                    onChange={handleConsti}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                                <br/>
                                                <div style={{ color: "red",fontSize: 13  }}>{constituencyError}</div>
                                            </Col>
                                        </Form.Group>

                                        <div className="button_style m-0 text-center">
                                            {
                                                update === false ?
                                                    <Button
                                                        type="button"
                                                        variant='contained'
                                                        onClick={() => saveData()}
                                                    >
                                                        Submit
                                                    </Button>
                                                    :

                                                    <Button
                                                        type="button"
                                                       variant='contained'
                                                       color='success'
                                                        onClick={() => editData()}
                                                    >
                                                        Update
                                                    </Button>
                                            }
                                            {" "}{" "}
                                            <Button
                                                type="button"
                                                variant='contained'
                                                color='error'
                                                onClick={() => clearData()}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Card>
                                </Col>
                                <Col className='mt-4' md={12} >
                                <MaterialTable
                                    title=''
                                    data={data}
                                    columns={columns}
                                    actions={[
                                        {
                                            icon: "edit",
                                            tooltip: "Edit",
                                            onClick: (event, rowData) => {
                                                getData(rowData);
                                            },
                                        },
                                            {
                                                icon: "delete",
                                                tooltip: "Delete",
                                                onClick: (event, rowData) => {
                                                    trashData(rowData);
                                                },
                                            },
                                            
                                        ]}
                                        options={{
                                            search: true,
                                            pageSize: 10,
                                            showFirstLastPageButtons: false,
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                            pageSizeOptions: [10, 15, 20, 50],
                                        }}
                                    />
                                </Col>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
