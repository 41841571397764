import React, { useState, useEffect } from "react";
import "../../common/product/ProductDetails.css";
import ProductURL from "../../ServerCalls/ProductServer";
import { Container, Card, Row, Col, Button, Modal } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import Auth from "../../ServerCalls/Auth";
import { ProductListgetById } from "../../pages/Admin/ProductServiceAPI";
import CollapsibleExample from '../../common/NewHeader';
import Footer from '../../common/footer/Footer';
import axios from "axios";
const title = 'Product Detals';
const PhotoPath = ProductURL.photoURL + "ProductImages/";


const ProductDetails = () => {
    const { id } = useParams();
    let pID = Number(id)
    const productId = atob(id);
    console.log(productId,"p id");
    const [proName, setproName] = useState([]);
    const [proBrand, setproBrand] = useState([]);
    const [proCategory, setproCategory] = useState([]);
    const [proImage1, setproImage1] = useState([]);
    const [proImage2, setproImage2] = useState([]);
    const [proImage3, setproImage3] = useState([]);
    const [proImage4, setproImage4] = useState([]);
    const [proImage5, setproImage5] = useState([]);
    const [feedback, setFeedback] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categoryid, setCategoryid] = useState(0);
    const [parameters, setParameters] = useState([]);
    const [showB, setShowB] = useState(false);
    const [previewImg, setPreviewImg] = useState('');


    const navigate = useNavigate();
    const auth = Auth.getUserIdF();

    useEffect(() => {
        getProductDetails();
    }, [productId, categoryid]);

    //   const Allfeedback = () => {
    //     setLoading(true);
    //     feedbackOfProduct(productId).then((res) => {
    //       let data = res.data._response.data.productRecognitionproductgetbyId

    //       setFeedback(data);
    //       setLoading(false);


    //       //  setRatingval(data.brandRatings)

    //     }).catch((err) => {
    //       console.log(err, "err in get feddback")
    //       setLoading(false);

    //     })
    //   }

    const getProductDetails = () => {
        // ProductListgetById(productId)
        axios.get(`https://api.topbrandsranking.com/ProductManagement/ProductGetById/${pID}`)
        .then((res) => {
            console.log(res,"lllllllllllll")
            let data = res.data._response.data.productgetid;
            let data1 = res.data._response.data.productparameterdetails;
            let parametersArr = data1 && data1.map((x) => ({
                value: x.parametervalue,
                name: x.parametername
            }))
            setParameters(parametersArr);
            setproName(data[0].productname);
            setproBrand(data[0].brandname);
            setproCategory(data[0].categoryname);
            setproImage1(data[0].productimage1);
            setproImage2(data[0].productimage2);
            setproImage3(data[0].productimage3);
            setproImage4(data[0].productimage4);
            setproImage5(data[0].productimage5);
            setCategoryid(data[0].categoryid);

        }).catch((err) => {
            console.log(err, "err in product get")
        })
    };
    const openModal = (imgurl) => {
        setPreviewImg(imgurl)
        setShowB(true);

    };

    const handleClose = () => {
        setShowB(false);
        setPreviewImg('')

    };

    return (
        <>


            {
                !auth ?
                    <CollapsibleExample />
                    :
                    null

            }
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <br /><br />
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <h1 className="mb-0 text-center pt-4">PRODUCT INFORMATION</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <Container>
                    <Card className="spd-card">
                        <Card.Body>
                            <Row className="text-center">
                                <Col sm={6} className='border-end'>
                                    <div className="d-flex flex-column justify-content-center">
                                        <div className="main_image">
                                            <img
                                                src={PhotoPath + proImage1}
                                                id="main_product_image"
                                                width="400"
                                                alt="products"
                                               className="img-fluid"
                                            />
                                        </div>
                                        <div className="thumbnail_images">
                                            <ul id="thumbnail">
                                                <li>
                                                    <img
                                                        src={PhotoPath + proImage2}
                                                        width="70" height="70"
                                                        alt="products"
                                                        onClick={() => openModal(PhotoPath + proImage2)}
                                                        className="img-fluid"
                                                    />
                                                </li>
                                                <li>
                                                    <img
                                                        src={PhotoPath + proImage3}
                                                        width="70" height="70"
                                                        alt="products"
                                                        onClick={() => openModal(PhotoPath + proImage3)}
                                                        className="img-fluid"
                                                    />
                                                </li>
                                                <li>
                                                    <img
                                                        src={PhotoPath + proImage4}
                                                        width="70" height="70"
                                                        alt="products"
                                                        onClick={() => openModal(PhotoPath + proImage4)}
                                                        className="img-fluid"
                                                    />
                                                </li>
                                                <li>
                                                    <img
                                                        src={PhotoPath + proImage5}
                                                        width="70" height="70"
                                                        alt="products"
                                                        onClick={() => openModal(PhotoPath + proImage5)}
                                                        className="img-fluid"
                                                    />
                                                </li>
                                                {/* <li><img onclick="changeImage(this)" src="https://i.imgur.com/6ZufmNS.jpg" width="70" /></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} >
                                    <div className="text-start">
                                        <div className="pt-4">
                                            <h2 className="prod-heading text-start">{proName}</h2>
                                        
                                            <h4 className="text-muted text-start">{proBrand}</h4>
                                        </div>
                                        <br />
                                        <Row>
                                            <Col sm={12}>
                                                <Row>
                                                    <Col >
                                                       <div className="d-flex justify-content-start spd-info">
                                                       <div className="text-muted m-0 text-start" >
                                                            Category
                                                        </div>
                                                    
                                                        <div className="m-0 text-muted text-start">
                                                            <p >:</p>
                                                        </div>
                                                    
                                                        <div className="text-muted text-start">
                                                            {proCategory}
                                                        </div>
                                                       </div>
                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>
                                        {parameters && parameters.map((f) => {
                                            return (
                                                <>
                                                    {/* <h6 className="text-muted" style={{fontSize:'1.5rem'}}>{f.name} : {f.value}</h6> */}
                                                    <Row>

                                                        <Col sm={12}>

                                                            <Row>

                                                                <Col sm={4}>

                                                                    <div className="text-muted m-0 text-center" style={{ fontSize: '1.5rem' }}>

                                                                        {f.name}

                                                                    </div>

                                                                </Col>

                                                                <Col sm={4}>

                                                                    <div className="m-0 text-start">

                                                                        <p style={{ fontSize: '1.5rem' }}>:</p>

                                                                    </div>

                                                                </Col>

                                                                <Col sm={4}>

                                                                    <div className="text-muted m-0 text-start" style={{ fontSize: '1.5rem' }}>

                                                                        {f.value}

                                                                    </div>

                                                                </Col>

                                                            </Row>

                                                        </Col>

                                                    </Row>
                                                </>
                                            )
                                        }
                                        )}



                                        <div>
                                            {/* <Link
                                                className="btn btn-danger ml-2 mt-4"
                                                to='/'
                                            > */}

                                               <button
              className="btn btn-danger ml-2 mt-4"
              onClick={() => navigate(-1)}
            >
              <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;
              Back
            </button>
                                            {/* </Link> */}

                                            <Link className="btn btn-danger fd" title="Add to Favorites" to='/login'>
                                                <i className="fa fa-heart" aria-hidden="true"></i>
                                            </Link>

                                        </div>
                                        {/* <Row className="text-center">
                                            <Col sm={12} className='border-end'>
                                                <FacebookShareButton
                                                    url={`http://localhost:3000/product-details/${productId}`}
                                                    quote={title}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <FacebookIcon size={32} round />
                                                </FacebookShareButton>
                                            </Col>
                                        </Row> */}

                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Container>
               
            </div>

            <Modal
                show={showB}
                onHide={handleClose}
                size="lg"
                backdrop="static"
                centered
            >
                <Modal.Header className="headerBlue" closeButton>
                    <Modal.Title className="mx-auto">
                        Images
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {/* <Link to="https://web.whatsapp.com/"> */}
                    <Row className="text-center">
                        <Col>
                            <img
                                src={previewImg}
                                width="500" height="500"
                                alt="products"
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

          

        </>
    )
}

export default ProductDetails;
