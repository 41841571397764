import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import './Celebritygallery.css';
import ProductURL from "../../../ServerCalls/ProductServer";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FcInfo, FcFeedback } from "react-icons/fc";
import { BsShareFill } from 'react-icons/bs';
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import { Pagination } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@material-ui/core'
import Loader from "../../../common/Loader";
import NotFound from "../../../common/NotFound";
import Auth from "../../../ServerCalls/Auth";
import { Rating } from "react-simple-star-rating";
import { getCelebrityWithPagination, getCelebrityWithNoparent, getCelebritywithCategorywithPagination, postCelebrityRating } from '../../Admin/CelebrityPages/CelebrityServiceAPI';
import { FacebookIcon, FacebookShareButton, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon, } from 'react-share';
import { useDispatch,useSelector } from "react-redux";
import { celebritycategory } from "../../../../redux/action/userproductgalleryAction";
const title = 'Celebrity Details';


const CelebrityGallery = () => {
  const state = useSelector((state) => state.userproduct);

  const [productsList, setProductList] = useState([]);
  const [Ratingdata, setRatingdata] = useState([]);
  const [showB, setShowB] = useState(false);
  const [categorySelected, setcategorySelected] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const PhotoPath = ProductURL.photoURL + "CelebrityImages/";
  const [loading, setLoading] = useState(false);
  const [showReport, setshowReport] = useState(false);
  const [showRating, setshowRating] = useState(false);
  const [pageC, setPageC] = useState(8)
  const [pageNumber, setPageNumber] = useState(1);
  const dataPerPage = 8;
  const [rating, setRating] = useState(0);
  const [Celebrity, setCelebrity] = useState([]);
  const [celebrityID, setCelebrityID] = useState(0)
  const [CelebrityName, setCelebrityName] = useState([]);
  const [Ratingstar, setRatingstar] = useState([]);

  const dispatch = useDispatch();
  const auth = Auth.getUserId();


  const handleClose = () => {
    setShowB(false);
  };
  const handleClose2 = () => {

    setshowReport(false)

  };

  const handleClose3 = () => {

    setshowRating(false)

  };

  const handleRate = (rate) => {
    setRating(rate);
  };


  useEffect(() => {

    if(Object.keys(state.celebrityCategory).length !== 0  ){
      setcategorySelected(state.celebrityCategory);
    }
    getCategory();


  }, []);

  const products = (a, b) => {
    setLoading(true);
    // axios.get(`https://localhost:5002/api/ProductManagement/GetCelebrityWithPagination?memberid=${auth}&pageno=${a}&pagesize=${b}`)
    getCelebrityWithPagination(auth, a, b)
      .then((res) => {
        if (res.data._response.status === 1) {
          let product = res.data._response.data.getcelebritywithpagination;
          let count = res.data._response.data.count;
          let rating = product.map((a) => {
            return a.isRating


          })
          let APIpagecount = Math.ceil(count / dataPerPage);
          setProductList(product);
          setPageC(APIpagecount);
          setRatingdata(rating)
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "errr");
        setLoading(false)
      });
  };


  const getCategory = () => {
    // axios.get(`https://localhost:5002/api/ProductManagement/GetCelebrityCategoryWithoutNoParent`)
    getCelebrityWithNoparent()
      .then((res) => {
        const category = res.data._response.data.getcelcategorywithoutnoparent
        let categorydata = category.map((list) => ({
          value: list.celebcategoryid,
          label: list.celebcategoryName
        }))
        setCategoryOption(categorydata)
      }).catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    if (categorySelected.length !== 0) {
      getbyBrandandCategoryId(1);
    } else {
      setPageNumber(1)
      if(Object.keys(state.celebrityCategory).length === 0){
        products(1, 8);
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySelected])


  const getbyBrandandCategoryId = (pgno) => {
    setLoading(true);
    let categoryid = categorySelected.value === undefined ? 0 : categorySelected.value;   
    //  axios.get(`https://localhost:5002/api/ProductManagement/CelebrityGetByCombination?categoryid=${categoryid}&memberid=${auth}&PageNo=${pgno}&PageSize=${8}`)
     getCelebritywithCategorywithPagination(categoryid,auth,pgno)
      .then((res) => {
        const pdata = res.data._response.data.getcelebritycombination
        if (pdata.length <= 0) {
          setProductList([])
          setLoading(false);
        } else {
          let count = res.data._response.data.count;
          let APIpagecount = Math.ceil(count / dataPerPage)

          setProductList(pdata)
          setPageC(APIpagecount)
          setLoading(false);
        }

      }).catch((error) => {
        setLoading(false)
        Swal.fire({
          icon: "error",
          title: "Product not Found",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });

      })


  }

  const handleCategory = (selected) => {
    selected ? dispatch(celebritycategory(selected)):dispatch(celebritycategory([]))
    selected ? setcategorySelected(selected) : setcategorySelected([]);

  }

  const changePage = (e, a) => {
    setPageNumber(a);
    if (categorySelected.length !== 0) {
      getbyBrandandCategoryId(a, dataPerPage)
    } else {
      products(a, dataPerPage);

    }
  }



  const openModal = (e) => {
    setShowB(true);
    let id = btoa(e.celebrityid)
    setCelebrityID(id)
  };


  const openRating = (e) => {

    const celebrity = e.celebrityid;
    const celebrityname = e.realname;
    const celebrityrating = e.rating;
    setRatingstar(celebrityrating);
    setCelebrity(celebrity);
    setCelebrityName(celebrityname);
    if (e.isRating === 0) {
      setshowReport(true)
    } else if (e.isRating === 1) {
      setshowRating(true)
    }

  }


  const saveData = () => {
    if(rating!==0){
    const data = {
      celebrityid: Celebrity,
      memberid: auth,
      Rating: rating
    }
    // axios.post(`https://localhost:5002/api/ProductManagement/CelebrityRatingsData`, data)
    postCelebrityRating(data)
      .then(response => {
        if (response.data._response.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Rating Successfully Done",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          })
          changePage(1, pageNumber);
          setshowReport(false)
          setRating(0)

        }else{
          Swal.fire({
            icon: "warning",
            title: "Ratings are not Added ",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // cancelButtonColor: '#d33',
            confirmButtonText: "ok",
          })
        }


      })
      .catch((err) => {
        console.log(err)
      })
  }else{
    Swal.fire({
      icon: "warning",
      title: "Please select Star For Rating",
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
      // cancelButtonColor: '#d33',
      confirmButtonText: "ok",
    })

  }
}

  const renderCard = (e, index) => {
    return (

      <div className="col-md-3 col-sm-6 pb-5">
        <Card>
          
            <div className="product-grid m-0">
              <div className="text-center mx-auto  font-weight-bold h3 bname">{e.brandname}</div>
              <div className="product-image">

                <img className="pic-1" src={PhotoPath + e.celebrityimage2} alt=""  />
                <img className="pic-2" src={PhotoPath + e.celebrityimage2} alt=""  />

                <ul className="product-links">
                  <Link className="p" to={`/Celebrity-details/${e.celebrityid}`}><Tooltip title="More Info"><IconButton><FcInfo fontSize="35px" /></IconButton></Tooltip></Link>
                  &nbsp;<Link className="p" to=""><Tooltip title="Feedback"><IconButton><FcFeedback onClick={() => openRating(e)} fontSize="35px" /></IconButton></Tooltip> </Link>
                  &nbsp;<div className="p"><Tooltip title='Share'><IconButton><BsShareFill fontSize="25px" color="#45a147" onClick={() => openModal(e)} /></IconButton></Tooltip></div>
                </ul>
              </div>
               <Card.Body>
               <div className="text-center mx-auto mt-2 font-weight-bold h4  pname">{e.realname}</div>

               </Card.Body>
            </div>
          
        </Card>
        <br />
      </div>

    );
  };

  return (
    <>
      <div>
        <div className="">
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <br /><br />
                <div className="row mb-2">
                  <div className="col-sm-12">
                    <h1 className="m-0 text-center">CELEBRITY RANKING</h1>
                  </div>
                </div>
              </div>
            </div>

            <section className="content  mt-4 consti-section">
              <div className="container">
                <div className="row">
                  <Col md={12}>
                    <Card style={{ padding: "20px" }}>
                      <Form.Group
                        as={Row}
                        className="justify-content-center"
                        controlId="formHorizontalEmail"
                      >


                        <Col sm={12} className="mb-4">
                          <h5>
                            Select Category
                          </h5>
                          <Select
                            isClearable={categorySelected}
                            className="w-100"
                            options={categoryOption}
                            onChange={handleCategory}
                            value={categorySelected}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                        </Col>
                      </Form.Group>
                      {/* <Button className="detailsbtn">Clear</Button> */}
                    </Card>
                  </Col>
                </div>
              </div>
            </section>
            <Container>
              <card-group className="d-flex" style={{ marginTop: "36px" }}>
                <Col md={12} className="mb-2">
                  {loading ?
                    <Loader />
                    :
                    productsList.length !== 0 ?

                      <Row sm="5"> {productsList.map(renderCard)} </Row>
                      :
                      <NotFound name="Celebrity Not Found"/>
                  }
                </Col>
              </card-group>
            </Container>
            <br /><br />
            {/* <Pagination_comp/> */}
            {
              productsList.length !== 0 ?
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Pagination
                    count={pageC}
                    page={pageNumber}
                    onChange={changePage}
                    shape="rounded" color="primary" size="large"
                  />
                </Box>
                :
                null
            }

            <br /><br />
          </div>
        </div>
      </div>

      {/* sharing option */}



      <Modal
        show={showB}
        onHide={handleClose}
        size="sm"
        backdrop="static"
        centered
      >
        <Modal.Header className="headerBlue" closeButton>
          <Modal.Title className="mx-auto">
            <h5 style={{ fontWeight: '600' }}>
              <b>Share Celebrity</b>
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div>
            <Row>
              <Col>
                <FacebookShareButton
                  url={`https://topbrandsranking.com/ShareCelebrityDetails/${celebrityID}`}
                  quote={title}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </Col>

          
              <Col>

                <WhatsappShareButton
                  url={`https://topbrandsranking.com/ShareCelebrityDetails/${celebrityID}`}
                  quote={title}
                  className="Demo__some-network__share-button"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </Col>
          	
              <Col>
                <TwitterShareButton
                  url={`https://topbrandsranking.com/ShareCelebrityDetails/${celebrityID}`}
                  quote={title}
                  className="Demo__some-network__share-button"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </Col>

            </Row>
          </div>
        </Modal.Body>
      </Modal>



      <Modal show={showReport} className="mt-3 product-modal" onHide={handleClose2}  size="md" backdrop="static" centered>
        <Modal.Header className="headerBlue" closeButton >
          <Modal.Title><h5 className="mx-auto text-center" style={{ fontWeight: '600' }}>Rating Overview</h5></Modal.Title>


        </Modal.Header>

        <Modal.Body>




          <Form.Label className="m-0 text-center w-100 pb-1 text-muted" style={{ fontSize: '1.2rem', fontWeight: '600' }}>
            Kindly Rate the Celebrity:
          </Form.Label>

          <br />


          <h4 className="mx-auto text-center" style={{ fontWeight: '500', fontSize: '1.5rem' }}>{CelebrityName}</h4>


          <div className="m-0 text-center">
            <Rating
              onClick={(rate) => handleRate(rate)}
              ratingValue={rating}
              allowHalfIcon
            />
          </div>


          <div className="button_style m-0 pt-4 text-center">
            <Button
              type="button"
              // className="btn btn-pos2"
              onClick={() => saveData()}
            >
              Submit
            </Button>
          </div>

        </Modal.Body>
      </Modal>



      <Modal show={showRating} onHide={handleClose3} size="md" backdrop="static" centered>
        <Modal.Header className="headerBlue" closeButton >
          <Modal.Title><h5 className="mx-auto text-center" style={{ fontWeight: '600' }}>Rating Overview</h5></Modal.Title>


        </Modal.Header>

        <Modal.Body>


          <Form.Label className="m-0 text-center w-100 pb-1 text-muted" style={{ fontSize: '1.2rem', fontWeight: '600' }}>
            Already Rated
          </Form.Label>

          <br />
          <h4 className="mx-auto text-center" style={{ fontWeight: '500', fontSize: '1.5rem' }}>{CelebrityName}</h4>
          <div className="m-0 text-center">
            <Rating
              //  onClick={(rate) => handleRate(rate)}
              ratingValue={Ratingstar}
              readonly
            />
          </div>


          <div className="button_style m-0 pt-4 text-center">
            {/* <Button
              type="button"
              // className="btn btn-pos2"
              onClick={() => saveData()}
            >
              Submit
            </Button> */}
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default CelebrityGallery;
