
import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, Card, FormControl } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  occupationlist, Educationlist,NoOccupationlist,NoEducationlist
} from "../Admin/ProductServiceAPI";
import logo1 from "../../../assets/img/logo1.png";
import Select from "react-select";
import ProductURL from '../../ServerCalls/ProductServer';
import "./Registration.css";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import Button from '@mui/material/Button';
import {ClearForm,Addoccupation,Addeducation} from '../../../redux/action/registerAction';
import { useDispatch,useSelector } from 'react-redux';
import axios from "../../ServerCalls/AxiosInstance";
import Header from '../../common/header/Header';
import Auth from '../../ServerCalls/Auth';
import { store } from "../../../redux/store";
import CollapsibleExample from "../../common/NewHeader";

import IconButton from '@mui/material/IconButton';
import { AiFillHome } from 'react-icons/ai';



const EducationInformation = () => {
 
  const state = useSelector((state) => state.registeruser.userDetails);

 
  const [occupationSelected, setOccupationSelected] = useState([]);
  const [EducationSelected, setEducationSelected] = useState([]);
  const [occupationOption, setOccupationOption] = useState([]);
  const [educationoptions, setEducationoptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputRefrence1 = useRef(null);

  const navigate = useNavigate();

  const token = Auth.getToken();
 
  const dispatch = useDispatch()


  //error handlling


  const [OccupationErr, setOccupationErr] = useState("");
  const [EducationErr, setEducationErr] = useState("");

  const formValidate = () => {

    let redux_data = store.getState();
    let data = redux_data.registeruser.userDetails
   
    let EducationErr = "";
    let OccupationErr = "";
    let regex = /^[a-z]+$/i;
    


    if (Object.keys(data.education_Level).length=== 0) {
      EducationErr = "Please select Your Education";
    }


    if (Object.keys(data.occupation).length=== 0) {
      OccupationErr = "Please select Your Occupation";
    }
    if (
     
      OccupationErr ||
      EducationErr 
     
    ) {
      
      setEducationErr(EducationErr);
      setOccupationErr(OccupationErr);
      


      return false;
    }
    return true;
  };









 

  useEffect(() => {
   
    occupation();
    education();
  }, []);

 

 

  const occupation = () => {
    NoOccupationlist().then((res) => {
      if (res.data._response.status === 1) {
        let occupations = res.data._response.data.getoccupation
        let option = occupations.map((a) => ({
          value: a.occupationId,
          label: a.occupationDropdown
        }))
        setOccupationOption(option)
      }
    }).catch((err)=>{
      console.log(err,"err in get occupation");
  })
  }


  const education = () => {
    NoEducationlist().then((res) => {
      if (res.data._response.status === 1) {
        let educations = res.data._response.data.geteducation
        let option = educations.map((a) => ({
          value: a.educationId,
          label: a.educationDropdown
        }))
        setEducationoptions(option)
      }
    }).catch((err)=>{
      console.log(err,"err in get education");
  })
  }


  // const saveForm = async () => {
  //   const isValid = formValidate();
  //   if (isValid) {
  //     const data = {
  //       //  MemberId : auth,
  //       Occupation: parseInt(occupationSelected.value),
  //       EducationLevel: parseInt(EducationSelected.value),
  //       Role: 2,
  //     };

     
  //     setLoading(true);
  //       axios.post("https://api.topbrandsranking.com/Authorization/AddUserData", data)
  //       // axios.post("https://localhost:5001/api/Authorization/AddUserData", data)

  //       .then((response) => {
  //         setLoading(false);
  //         Swal.fire({
  //           icon: "success",
  //           title: "Registration Successful, Kindly verify your email by clicking on the verification link sent on your email address.",
  //           showConfirmButton: true,
  //           confirmButtonColor: "#3085d6",
  //           // cancelButtonColor: '#d33',
  //           confirmButtonText: "ok",
  //         });
  //         navigate('/login')
  //         handleReset()
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         console.log(err);
  //       });
  //   }
  // };

  const handleReset = () => {
    inputRefrence1.current.value = null;
    setOccupationErr("");
    setEducationErr("");
    setOccupationSelected("");
    setEducationSelected("");
   
  };

  const handleOccupation = (selected) => {
    setOccupationSelected(selected)
    dispatch(Addoccupation(selected))
    setOccupationErr("")
  }

  const handleEducation = (selected) => {
    setEducationSelected(selected)
    dispatch(Addeducation(selected))
    setEducationErr("")
  }


  const SubmitForm = () => {
    let isValid = formValidate();
    if (isValid){
      let redux_data = store.getState();
      let data = redux_data.registeruser.userDetails
      let registerData = { 
        Age :Number(data.age.value),
        confirmPassword: data.confirmPassword,
        ConstituencyId: Number(data.constituency.value),
        ContactNo1: data.contactNumber,
        CountyId: Number(data.county.value),
        EducationLevel: Number(data.education_Level.value),
        EmailAddress: data.email,
        firstName: data.firstName,
        Gender: data.gender.label,
        Occupation: data.occupation.value,
        password:data.password,
        PhotoFileName:data.userImage.name,
        userImagePath:data.userImagePath,
        WardId:Number(data.ward.value), 
        Role: 2,
        RegistrationMethod: 0,
      }
      
      axios.post("https://api.topbrandsranking.com/Authorization/AddUserData", registerData)
      //  axios.post("https://localhost:5001/api/Authorization/AddUserData", registerData)

      .then((response) => {
        setLoading(false);
        if(response.data._response.status === 1){
        Swal.fire({
          icon: "success",
          title: "Registration Successful, Kindly verify your email by clicking on the verification link sent on your email address.",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
        dispatch(ClearForm())
        navigate('/login')
        handleReset()
      
      }else if(response.data._response.status === 2){
        Swal.fire({
          icon: "warning",
          title: response.data._response.message,
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: '#d33',
          confirmButtonText: "ok",
        });
        navigate('/register')
      }
    
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    }
  }
 

  return (
    <>
       {/* {
    !token ?
      <CollapsibleExample />
      :
      null

  } */}
      <div className="regiform">
        <div>
          <section>
            <div className="container">
              <br /><br /><br />
              <div className="row ">
                <Col md={12} className="">
                  <Card className="register-card bg-white">
                  <div className='text-end'>
                      <Link to="/"><IconButton size='large'><AiFillHome color='#CD0404' size="35px" /></IconButton></Link>
                    </div>
                    <div className=" m-0 text-center">
                      <img
                        className="img-fluid "
                        src={logo1}
                        alt="logo-topbrands"
                        height="80px"
                        width="120px"
                      />
                      <h1 className="m-0 text-center register-heading">
                        Sign Up!
                      </h1>
                    </div>


                    <div>
                  

                   

 <Form.Group
                        as={Row}
                        className="mb-3 justify-content-center"
                        controlId="formHorizontalEmail"
                      >
                        <Col sm={12}>
                          <Form.Label className="w-100">
                            Occupation
                          </Form.Label>

                          <Select
                            className="w-100"
                            options={occupationOption}
                            onChange={handleOccupation}
                            value={state.occupation}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                          />
                          <br/>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {OccupationErr}
                          </div>
                        </Col>
                        </Form.Group>
                        <Form.Group
                         as={Row}
                         className="mb-3 justify-content-center"
                         controlId="formHorizontalEmail">
                        <Col sm={12}>
                          <Form.Label className="w-100">Education Level</Form.Label>

                          <Select
                            className="w-100"
                            options={educationoptions}
                            onChange={handleEducation}
                            value={state.education_Level}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                          />
                          <br/>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {EducationErr}
                          </div>
                        </Col>
                      </Form.Group>
 

                   
                      <div className="button_style m-0 text-center">

                         <Link to="/Other-Info"><button
                          type="button"
                          variant="contained"
                          color="error"
                          className="button-85 "
                          // onClick={() => dispatch(ClearForm())}
                        >
                          Back
                        </button></Link>
                        &nbsp;&nbsp;
                   <button
                          variant="contained"
                          className="button-85 "
                          onClick={SubmitForm}
                        >Submit</button>


                       
                         </div>




                
{/* 
                      <div className="button_style m-0 text-center">
                        <Button
                          variant="contained"

                          onClick={saveForm}
                        >
                          {
                    loading === true ?
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>


                      :
                      null
                  }
                  &nbsp;
                  <span>Submit</span>
                        </Button>{" "}
                        <Button
                          type="button"
                          variant="contained"
                          color="error"
                          onClick={() => handleReset()}
                        >
                          Cancel
                        </Button>
                      </div> */}
                      <br />
                      <div className="regi-a">
                        <p className="fgt-psw">
                          Already have an Account?{" "}
                          <Link className="fgt-psw" to='/login'> Login Here !</Link>
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
              </div>
              <br /> <br />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default EducationInformation;
