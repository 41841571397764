import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Card } from "react-bootstrap";
import Button from '@mui/material/Button';
import MaterialTable from "material-table";
import { getreleaseCategorylist, addreleaseCategory, editReleaseCategory,deleteReleaseCategory } from './CelebrityServiceAPI';
import Select from 'react-select';
import Swal from "sweetalert2";
import axios from "../../../ServerCalls/AxiosInstance";


export default function ReleaseCategory() {

    const [category, setCategory] = useState('');
    const [formError, setFormError] = useState('');
    const [allList, setAllList] = useState([]);
    const [parentcategory, setParentcategory] = useState([]);
    const [CategoryError, setCategoryError] = useState('');
    const [parentOptions, setParentOptions] = useState([]);
    const [update, setupdate] = useState(false);
    const [categoryID, setcategoryID] = useState('');

    const columns = [
        { title: " Celebrity Category Name ", field: "mediacategoryname", align: "center" },
        { title: "Parent Celebrity Category Name ", field: "parentmediabcategoryname", align: "center" },
    ];

    const handleCategory = (e) => {

        setCategory(e.target.value.trimStart().replace(/[^\a-zA-Z0-9_@" "]/ig, ''))
        setFormError('');

    }

    const handleParentcategory = (selected) => {

        setParentcategory(selected)
        setCategoryError('');

    }

    useEffect(() => {

        allReleasecategories();
        allOptionlist();

    }, [])


    const allReleasecategories = () => {

        getreleaseCategorylist()
            .then((res) => {
                if (res.data._response.status === 1) {
                    setAllList(res.data._response.data.getmediacategory)
                }
            })
            .catch((err) => {

                console.log(err, "error in get release categorylist");

            })
    }


    const allOptionlist = () => {
        getreleaseCategorylist()
            .then((res) => {
                let data = res.data._response.data.getmediacategory;
                if (res.data._response.status === 1) {

                    const options = data.map((a) => ({
                        value: a.mediacategoryid,
                        label: a.mediacategoryname,
                    }))
                    setParentOptions(options);

                }
            })
            .catch((err) => {
                console.log(err, "err in get parentlist");
            })
    }


    const validate = () => {

        let regex = /^[A-Za-z0-9 ]+$/;

        let formError = '';
        let CategoryError = '';

        if (category === '') {

            formError = "Please fill the Details";

        } else if (regex.test(category) === false) {

            formError = 'Please do not use special Character'
        }else if(category.length > 200){
            formError = "Maximum 200 characters are allowed";
        }


        if (parentcategory.length < 1) {

            CategoryError = "Please Select Category";

        }

        if (formError || CategoryError) {

            setFormError(formError);
            setCategoryError(CategoryError)
            return false;
        }
        return true;

    }

    const saveData = () => {

        const isValid = validate();

        if (isValid) {

            const data = {
                mediareleasecategoryname: category,
                mediaparentcategoryid: parentcategory.value
            }
            addreleaseCategory(data)
                .then((res) => {

                    if (res.data._response.status === 1) {

                        Swal.fire({
                            icon: "success",
                            title: "Category Added Successfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        allReleasecategories();
                        clearData();

                    }
                })
                .catch((err) => {
                    console.log(err, "err in post releasecategory");
                })

        }
    }

    const getdata = (id) => {
        setupdate(true);
        setcategoryID(id.mediacategoryid)
        setCategory(id.mediacategoryname)
        setParentcategory({ value: id.parentmediacategoryid, label: id.parentmediabcategoryname })
    }

    const editdata = () => {

        const isValid = validate();
        if (isValid) {

            const data = {
                mediareleasecategoryid: categoryID,
                mediareleasecategoryname: category,
                mediaparentcategoryid: parentcategory.value,

            }

            editReleaseCategory(data)
                .then((res) => {
                    if (res.data._response.status === 1) {

                        Swal.fire({
                            icon: "success",
                            title: "Category Updated Sucessfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: '#d33',
                            confirmButtonText: "ok",
                        });
                        allReleasecategories();
                        clearData();

                    }
                })
                .catch((err)=>{
                    console.log(err,"err in edit releasecategory");
                })
        }
    }


    const trashCategory = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {

              deleteReleaseCategory(id)
                // axios.delete(`https://localhost:5002/api/ProductManagement/RemoveMediaReleaseCategory/${id}`)
                    .then((res) => {
                        if (res.data._response.status === 1) {
                            Swal.fire({
                                icon: "success",
                                title: "Category Deleted Sucessfully",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });
                            allReleasecategories();

                        }
                        else if(res.data._response.status === 2){
               
                            Swal.fire({
                              icon: "warning",
                              title: res.data._response.message,
                              showConfirmButton: true,
                              confirmButtonColor: "#3085d6",
                              // cancelButtonColor: '#d33',
                              confirmButtonText: "ok",
                          });
              
              
                      }
              
                  })
                 
              }
              }).catch((err)=>{
            console.log(err,'err in result');
        })

    }

    const clearData = () => {

        setCategory('');
        setFormError('');
        setParentcategory([]);
        setupdate(false)
        setCategoryError("")
    }


    return (
        <>

            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <br /><br />
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    {! update?(
                                    <h1 className="m-0 text-center">ADD CELEBRITY RELEASE CATEGORY </h1>
                                    ):
                                    <h1 className="m-0 text-center">EDIT CELEBRITY RELEASE CATEGORY </h1>
                                }

                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content consti-section">
                        <div className="container">
                            <div className="row">
                                <Col md={12} >
                                    <Card style={{ padding: "20px" }}>
                                        <>
                                            <Form.Group
                                                as={Row}
                                                className="justify-content-center"
                                                controlId="formHorizontalEmail"
                                            >
                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">
                                                        Release Category
                                                    </Form.Label>
                                                    <input
                                                        className="w-100 form-control"
                                                        type="text"
                                                        name="categoryValue"
                                                        placeholder="Enter Release category name"
                                                        value={category}
                                                        onChange={handleCategory}
                                                    />

                                                    <div style={{ color: "red" }}>
                                                        {formError}
                                                    </div>
                                                </Col>

                                                <Col sm={6} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">
                                                        Parent Category Title
                                                    </Form.Label>
                                                    <Select
                                                        className="w-100"
                                                        options={parentOptions}
                                                        name='selectedCategory'
                                                        onChange={handleParentcategory}
                                                        value={parentcategory}
                                                        menuPortalTarget={document.body}
                                                        styles={{
                                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                        }}
                                                    />
                                                    <br/>

                                                    <div style={{ color: "red" }}>
                                                        {CategoryError}
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                            <div className="button_style m-0 text-center">

                                                {update === false ? (
                                                    <Button
                                                        type="button"
                                                        variant='contained'
                                                        onClick={() => saveData()}
                                                    >
                                                        Submit
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant='contained'
                                                        color='success'
                                                    onClick={() => editdata()}
                                                    >
                                                        Update
                                                    </Button>
                                                )} {" "}

                                                <Button
                                                    type="button"
                                                    variant='contained'
                                                    color="error"
                                                    onClick={() => clearData()}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </>
                                    </Card>
                                </Col>

                                <Col className='mt-4' md={12} >
                                    <MaterialTable
                                        title="Celebrity Category"
                                        data={allList}
                                        columns={columns}
                                        actions={[
                                            {
                                                icon: "edit",
                                                tooltip: "Edit",
                                                onClick: (event, rowData) => {
                                                    getdata(rowData);
                                                },
                                            },

                                            {
                                                icon: "delete",
                                                tooltip: "Delete",
                                                onClick: (event, rowData) => {
                                                    trashCategory(rowData.mediacategoryid);
                                                },
                                            },
                                        ]}
                                        options={{
                                            search: true,
                                            pageSize: 10,
                                            showFirstLastPageButtons: false,
                                            actionsColumnIndex: -1,
                                            addRowPosition: "first",
                                            pageSizeOptions: [10, 15, 20, 50],
                                        }}
                                    />
                                </Col>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>

    )
}
