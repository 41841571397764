import React, { useEffect, useState } from 'react';
import { userAllfeedback, Categorywithnoparent, Allbrands, feedbackWithPagination } from '../Admin/ProductServiceAPI';
import Auth from '../../ServerCalls/Auth';
import ProductComments from './ProductComments';
import { Col, Card, Row, Form } from 'react-bootstrap';
import Select from 'react-select';
import Swal from "sweetalert2";
import Loader from '../../common/Loader';
import { Box } from '@material-ui/core'
import { Pagination } from "@mui/material";
import forbidden from '../../../assets/img/forbidden.png';

export default function FeedbackHistory() {

    const auth = Auth.getUserIdF();
    const [allfeedback, setAllfeedback] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brandlist, setBrandlist] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageC, setPageC] = useState(8)
    const [pageNumber, setPageNumber] = useState(1);
    const dataPerPage = 2;

    useEffect(() => {
        // allFeedback(1,2);
        getCategory();
        getBrand();
        // eslint-disable-next-line
    }, [])


    // useEffect(() => {
    //     const pageCount = Math.ceil(pageNo / dataPerPage)
    //     setPageC(pageCount)
    //   }, [pageNo])

    const allFeedback = (a, b) => {
        setLoading(true);
        userAllfeedback(auth, a, b).then((res) => {
            if (res.data._response.status === 1) {
                let count = res.data._response.data.count;
                let APIpagecount = Math.ceil(count / dataPerPage);
                setAllfeedback(res.data._response.data.productrecognitionGetByAllFeedbackbyuserIdwithpagination)
                setPageC(APIpagecount)
                setLoading(false);
            }

        }).catch((err) => {
            console.log(err, "error in get all feedback of user");
            setLoading(false);
        })

    }

    useEffect(() => {
        if (selectedBrand.length !== 0 || selectedCategory.length !== 0) {
            feedbackwithBrandCategoryID(1);
            
        } else {
            setPageNumber(1);
            allFeedback(1, 2);
        }
    }, [selectedBrand, selectedCategory])

    const feedbackwithBrandCategoryID = (pgno) => {
        setLoading(true);

        let brandid = selectedBrand.value === undefined ? 0 : selectedBrand.value;
        let categoryid = selectedCategory.value === undefined ? 0 : selectedCategory.value;

        feedbackWithPagination(brandid, auth, categoryid, pgno).then((res) => {
            if (res.data._response.status === 1) {
                let data = res.data._response.data.feedbackgetBycombination
                if (data.length <= 0) {
                    setAllfeedback([])
                    setLoading(false)
                } else {
                    let count = res.data._response.data.count;
                    let APIpagecount = Math.ceil(count / dataPerPage)
                    setPageC(APIpagecount)
                    setAllfeedback(data)
                    setLoading(false)

                }
            }
        }).catch((err) => {
            setLoading(false)
            Swal.fire({
                icon: "error",
                title: "No Feedback",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: '#d33',
                confirmButtonText: "ok",
            });

        })

    }

    const handleCategory = (selected) => {

        selected ? setSelectedCategory(selected) : setSelectedCategory([])

    }

    const handleBrand = (selected) => {

        selected ? setSelectedBrand(selected) : setSelectedBrand([])
    }

    const getCategory = () => {
        Categorywithnoparent().then((res) => {
            if (res.data._response.status === 1) {
                let data = res.data._response.data.getprocategory
                let categorydata = data.map((list) => ({
                    value: list.categoryid,
                    label: list.categoryName
                }))
                setCategories(categorydata)
            }

        }).catch((err) => {
            console.log(err);
        })
    }

    const changePage = (e, a) => {
        setPageNumber(a);
        if (selectedBrand.length !== 0 || selectedCategory.length !== 0) {
            feedbackwithBrandCategoryID(a, dataPerPage)
        } else {
            allFeedback(a, dataPerPage);

        }
    }

    const getBrand = () => {
        Allbrands()
            .then((res) => {
                if (res.data._response.status === 1) {
                    let data1 = res.data._response.data.getbrand;
                    let options = data1.map((d) => ({
                        value: d.brandId,
                        label: d.brandName,
                    }));

                    setBrandlist(options);
                }
            }).catch((err)=>{
                console.log(err,"err in get brands");
            })

    }

    return (
        <>
            <div>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <br/><br/>
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <h1 className="m-0 text-center">FEEDBACK HISTORY</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className="content mt-4  consti-section">
                        <div className="container">
                            <div className="row">
                                <Col md={12}>
                                    <Card style={{ padding: "20px" }}>
                                        <Form.Group
                                            as={Row}
                                            className="justify-content-center"
                                            controlId="formHorizontalEmail"
                                        >

                                            <Col sm={6} className="mb-4">

                                                <h5>
                                                    Select Brand
                                                </h5>
                                                <Select
                                                    isClearable={selectedBrand}
                                                    className="w-100"
                                                    options={brandlist}
                                                    onChange={handleBrand}
                                                    value={selectedBrand}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                />
                                            </Col>

                                            <Col sm={6} className="mb-4">
                                                <h5>
                                                    Select Category
                                                </h5>
                                                <Select
                                                    isClearable={selectedCategory}
                                                    className="w-100"
                                                    options={categories}
                                                    onChange={handleCategory}
                                                    value={selectedCategory}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                        {/* <Button className="detailsbtn">Clear</Button> */}
                                    </Card>
                                </Col>
                            </div>
                        </div>
                    </section>

                    {
                        loading ?
                            <Loader />
                            :
                            allfeedback.length !== 0 ?
                                allfeedback && allfeedback.map((data) => (
                                    <>
                                        <ProductComments
                                            productcomm={data}
                                        />
                                    </>
                                ))
                                :
                                <>
            <div className="container mb-5 mt-5">
                <div className="feedback-card">
                {/* <div className='m-0 text-center'>
                    <img className='img-fluid' src={forbidden} />
                </div> */}
                <h2 className='m-0 text-center' style={{fontWeight:'600', color:'grey'}}>No Comments Yet</h2>
                </div>
            </div>

              </>
                    }
                    <br />
                    <br />
                    {
                          allfeedback.length !== 0 ?
                          <Box display="flex" alignItems="center" justifyContent="center">
                          <Pagination
                              count={pageC}
                              page={pageNumber}
                              onChange={changePage}
                              shape="rounded" color="primary" size="large"
                          />
                      </Box>
                      :
                      null

                    }

                    <br /><br />
                </div>

            </div>
        </>

    )
}
