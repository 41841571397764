import React,{useState,useEffect} from 'react';
import MaterialTable, { MTableEditField } from "material-table";
import axios from 'axios';


const ContactUsInfo = () => {

    const [List, setList] = useState([])


    const columns = [
        { title: " Name ", field: "name", align: "center" },
        { title: " Email ", field: "email", align: "center" },
       { title: " Contact Number ", field: "contactNo", align: "center" },
        { title: "Message", field: "message", align: "center" },
        // { title: "Pincode ", field: "pincode", align: "center" },
    
      ];


      useEffect(()=>{

        axios.get("https://api.topbrandsranking.com/ProductManagement/GetContactsUs")
        .then((res)=>{
            console.log(res,"got list")
            const data = res.data._response.data.getcontactus
            setList(data)
        }).catch((err)=>{
            console.log(err)
        })

      },[])





  return (
    <div>
    <div className="content-header">
      <div className="container-fluid">
      
      </div>
      <br />

      <div className="container">
        <MaterialTable
          title={false}
          data={List}
          columns={columns}

          options={{
            search: true,
            pageSize: 10,
            showFirstLastPageButtons: false,
            actionsColumnIndex: -1,
            addRowPosition: "first",
            pageSizeOptions: [10, 15, 20, 50],
          }}
        />
      </div>
    </div>
  </div>
  )
}

export default ContactUsInfo