import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Card } from 'react-bootstrap'
import Select from 'react-select'
import MaterialTable,{MTableEditField} from 'material-table';
import Swal from "sweetalert2";
import { productList, Postparameter, getProductparameters, trashProductparameters } from './ProductServiceAPI'



const ProductParameters = () => {


    const [productID, setproductID] = useState([]);
    const [ProductOptions, setProductOptions] = useState([])
    const [data, setdata] = useState([]);

    const [productIdError, setproductIdError] = useState('');



    const columns = [

        { title: "Paramter Name", field: "parametername", align: "center", editable: "never" },
        { title: "Parameter Value Type", field: "parametervaluename", align: "center", editable: "never" },
        { title: "parameter value", field: "parametervalue", align: "center",editComponent: ({ ...props }) => {

                return <MTableEditField {...props} />;
             }, }

    ];


    useEffect(() => {
        productlist();

    }, []);

    useEffect(() => {
        GetParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [productID])

    const GetParameters = () => {
        getProductparameters(productID.value).then((res) => {
            let parametersData = res.data._response.data.productparametersgetid
            let data = [];
            parametersData && parametersData.map((x) => {

                if (x.productid === productID.value) {
                    data.push(parametersData);
                }

            })
            setdata(parametersData);

        }).catch((err) => {
            console.log(err, "err in get")
        })
    }
    const handleProductID = (selected) => {
        setproductID(selected)
        setproductIdError('');
        // handleColumn();
    }

    const productlist = () => {

        productList()
            .then((res) => {
                if (res.data._response.status === 1) {

                    const data = res.data._response.data.getproduct
                    const options = data.map((a) => ({
                        label: a.productname,
                        value: a.productid
                    }))
                    setProductOptions(options);

                }
            })
            .catch((err)=>{
                console.log(err,"err in get  productlist");
            })

        // handleColumn();
    }

    const handleRowupdate = (newData, resolve, reject) => {
        let regexnum = /^\d*\.?\d*$/;
        let regexalpha = /^[a-zA-Z ]*$/;
        let valid = true;
        newData && Object.entries(newData).map(([k, v]) => {
            // if (v.newData.parametervaluename === "Numaric") {
            //     let test = regexnum.test(v.newData.parametervalue);
            //     if (test === false) {
            //         valid = false
            //     }
            // }
            // if (v.newData.parametervaluename === "Alphabetical") {
            //     let test = regexalpha.test(v.newData.parametervalue);
            //     if (test === false) {
            //         valid = false
            //     }
            // }
        });

        if (newData) {


            let mapdata = [];
            // let regx = /^[A-Za-z]+$/;

            newData && Object.entries(newData).map(([k, v]) => {

                mapdata.push(v.newData);

            })           

                var resultdata = mapdata.map(function (ob) {

                    ob.productId = productID.value;
                    return ob;
                })
                Postparameter(resultdata)
                    .then((res) => {
                        if (res.data._response.status === 1) {
                            GetParameters();
                            Swal.fire({
                                icon: "success",
                                title: "Data Added Sucessfully",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: '#d33',
                                confirmButtonText: "ok",
                            });
                            resolve();

                        }

                        // ClearData();
                    }).catch((err) => {
                        reject();
                        console.log(err, "err in submit")
                    })

            
            resolve();
        } else {
            Swal.fire({
                icon: "warning",
                title: "Enter valid Parameter Value",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
            });
            reject();

        }

    }

   



    // const handleColumn = () => {
    //     $('th:nth-child(1)').html('')
    // }

    const handleDelete = (oldData, resolve, reject) => {

        trashProductparameters(oldData.productparameterid)
            .then((res) => {
                resolve();
                GetParameters();
            })
            .catch((err) => {
                console.log(err);
                reject();
            })


    }

    

    return (


        <>
            <div>
                {/* <Header />
                <Menu /> */}
                <div>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                                <br/><br/>
                                <div className="row mb-2">
                                    <div className="col-sm-12">
                                        <h1 className="m-0 text-center">Product Parameters</h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="content consti-section">
                            <div className="container">
                                <div className="row">
                                    <Col md={12}>
                                        <Card className='mt-4' style={{ padding: "20px" }}>
                                            <Form.Group as={Row} className="justify-content-center" controlId="formHorizontalEmail">
                                                <Col sm={12} className="mb-4">
                                                    <Form.Label className="w-100 pb-1">Product Name</Form.Label>
                                                    {/* <input className="w-100 form-control" type="text" placeholder='Paramater name' value={parameterID} onChange={handleParameterID} /> */}
                                                    <Select
                                                        className="w-100"
                                                        options={ProductOptions}
                                                        onChange={handleProductID}
                                                        selected={productID}
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    />
                                                    <div style={{ fontSize: 14, color: "red" }}>{productIdError}</div>
                                                </Col>
                                            </Form.Group>
                                        </Card>
                                    </Col>
                                    <Col className='mt-4' md={12}>

                                        <MaterialTable
                                            title={false}
                                            columns={columns}
                                            data={data}
                                            options={{
                                                search:false
                                            }}
                                            editable={{
                                                onBulkUpdate: newData =>
                                                    new Promise((resolve, reject) => {
                                                 handleRowupdate(newData, resolve, reject);
                                                    }),
                                                onRowDelete: oldData =>
                                                    new Promise((resolve, reject) => {

                                                        handleDelete(oldData, resolve, reject)
                                                    }),
                                            }}
                                        />
                                    </Col>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        </>

    )
}

export default ProductParameters