class Auth {
    constructor() {
        this.user_token = JSON.parse(localStorage.getItem('UserDetails')) || null
    }
    getToken() {
        if ((this.user_token !== null)) {
            return this.user_token.token
        }
    }
    getUserIdF() {
        if ((this.user_token !== null)) {

            return this.user_token.id
        }
    }
    getUserId() {
        if ((this.user_token !== null)) {

            return this.user_token.memberIdBrandId
        }
    }
    getUserRole() {
        if ((this.user_token !== null)) {

            return this.user_token.role
        }
    }
    getUserFirstName() {
        if ((this.user_token !== null)) {

            return this.user_token.firstName
        }
    }
    getUserLastName() {
        if ((this.user_token !== null)) {

            return this.user_token.lastName
        }
    }
    getUserProfilePic(){
        if ((this.user_token !== null)) {

            return this.user_token.images
        }
    }
    setUserToken(refreshToken) {
        this.user_token = refreshToken
        localStorage.setItem('UserDetails', JSON.stringify(refreshToken))
    }
    logout() {
        localStorage.clear('UserDetails');
        window.location.href = "/";
    }
}
export default new Auth()

